import type { NumberLiteral as NumberLiteralType } from '@wirechunk/schemas/expressions/expression';
import { InputNumber } from 'primereact/inputnumber';
import { Fragment, FunctionComponent } from 'react';
import type { BuilderProps } from './shared.js';

export const NumberLiteral: FunctionComponent<BuilderProps<NumberLiteralType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div className="input-field mb-0">
        <label htmlFor={`${id}-numberLiteral`}>Number literal value</label>
        <InputNumber
          inputId={`${id}-numberLiteral`}
          value={expression.value ?? null}
          onChange={(e) => {
            setExpression({
              ...expression,
              value: e.value,
            });
          }}
        />
      </div>
    </Fragment>
  );
};
