import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { AppPageTilesComponent } from '@wirechunk/lib/mixer/types/components.js';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentSitePages } from '../../../hooks/useCurrentSitePages/useCurrentSitePages.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { Spinner } from '../../Spinner.js';
import styles from './AppPageTiles.module.css';

export const defaultFeaturedImageUrl =
  'https://storage.googleapis.com/wirechunk-core-public/blue-gradient-800x450.png';

export const AppPageTiles: FunctionComponent<AppPageTilesComponent> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { pages, isLoading } = useCurrentSitePages({
    pathPrefix: props.pathPrefix || undefined,
    onError,
  });

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.cardsWrapper}>
          {pages.map(({ caption, featuredImageUrl, path, title }) => (
            // The overflow-hidden class is needed so that the top corners of the card are rounded.
            <div key={path} className="border-round border-1 overflow-hidden">
              {featuredImageUrl && (
                <Link to={`/${path}`} className="flex">
                  <img
                    className="w-full"
                    alt={title}
                    src={featuredImageUrl || defaultFeaturedImageUrl}
                  />
                </Link>
              )}
              <div className="p-4">
                <div className="line-height-3">
                  <Link to={`/${path}`} className="text-xl font-bold">
                    {title}
                  </Link>
                </div>
                {caption}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
