import { breakpoints } from '@wirechunk/lib/mixer/breakpoints.js';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { WidthContainerComponent, Width } from '@wirechunk/lib/mixer/types/components.js';
import type { FunctionComponent } from 'react';
import { RenderMixerChildren } from '../../RenderMixerChildren.js';

const widthToBreakpoint = (width: WidthContainerComponent['width']): number => {
  switch (width) {
    case Width.Medium:
      return breakpoints.md;
    case Width.Large:
      return breakpoints.lg;
    case Width.XLarge:
      return breakpoints.xl;
    case Width.XXLarge:
      return breakpoints.xxl;
    default:
      return breakpoints.lg;
  }
};

export const WidthContainer: FunctionComponent<WidthContainerComponent> = ({
  width = Width.Large,
  ...props
}) => (
  <div
    className={componentClassName(props)}
    style={{
      // We take 1px less than the corresponding breakpoint so that we can easily contain the children to less
      // than the next breakpoint.
      maxWidth: `${widthToBreakpoint(width) - 1}px`,
    }}
  >
    <RenderMixerChildren>{props.children}</RenderMixerChildren>
  </div>
);
