import { FetchResult, useMutation } from '@apollo/client';
import type { CreateComponentInput } from '@wirechunk/lib/api.js';
import { useCallback } from 'react';
import type { ErrorHandler } from '../useErrorHandler.js';
import { CreateComponentDocument, CreateComponentMutation } from './mutations.generated.js';

type CreateComponent = {
  createComponent: (input: CreateComponentInput) => Promise<FetchResult<CreateComponentMutation>>;
  loading: boolean;
};

export const useCreateComponent = (
  onError: ErrorHandler['onError'],
  onCompleted?: (createdComponent: { __typename: 'Component'; id: string }) => void,
): CreateComponent => {
  const [create, { loading }] = useMutation(CreateComponentDocument, {
    onError,
    onCompleted: (data) => {
      if (data.createComponent.__typename === 'CreateComponentSuccessResult') {
        onCompleted?.(data.createComponent.component);
      } else {
        onError(data.createComponent.message);
      }
    },
  });

  const createComponent = useCallback<CreateComponent['createComponent']>(
    (input) => {
      return create({
        variables: {
          input,
        },
      });
    },
    [create],
  );

  return {
    createComponent,
    loading,
  };
};
