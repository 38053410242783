import { FunctionComponent } from 'react';
import { useOptionalCurrentUser } from '../contexts/CurrentUserContext/CurrentUserContext.js';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../contexts/SessionStatusContext/SessionStatusContext.js';
import { AuthenticatedBody as AdminAuthenticatedBody } from './admin/AuthenticatedBody/AuthenticatedBody.js';
import { AgreementsGuard } from './AgreementsGuard/AgreementsGuard.js';
import { AuthForm } from './AuthForm/AuthForm.js';
import { DashboardLayout } from './DashboardLayout/DashboardLayout.js';
import { Page } from './Page/Page.js';
import { SidebarInner, SidebarInnerFooter } from './SidebarInner.js';

type AuthenticatedLayoutProps = {
  adminSite: boolean | undefined;
};

export const AuthenticatedLayout: FunctionComponent<AuthenticatedLayoutProps> = ({ adminSite }) => {
  const { user, loadingUser } = useOptionalCurrentUser();
  const sessionStatus = useSessionStatusContext();

  return user && sessionStatus.status === SessionStatus.Active ? (
    <AgreementsGuard>
      {adminSite ? (
        <AdminAuthenticatedBody />
      ) : (
        <DashboardLayout SidebarInner={SidebarInner} SidebarInnerFooter={SidebarInnerFooter}>
          <Page />
        </DashboardLayout>
      )}
    </AgreementsGuard>
  ) : (
    <AuthForm loadingUser={loadingUser} />
  );
};
