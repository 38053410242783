import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import { AdminUser, useAdminUsers } from '../../../../hooks/useAdminUsers/useAdminUsers.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { AddAdminUser } from './AddAdminUser/AddAdminUser.js';
import { UserPlatformPermissionsCell } from './UserPlatformPermissionsCell.js';

export const AdminUsers: FunctionComponent = () => {
  const { toastSuccess } = useToast();
  const { id: platformId } = usePlatformContext();
  const { user } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const [showCreate, setShowCreate] = useState(false);
  const {
    adminUsers,
    isLoading: loadingAdminUsers,
    refetch,
  } = useAdminUsers(user.organization.id, onError);

  return (
    <PageContainer title="Admin Users">
      <ErrorMessage />
      {showCreate ? (
        <AddAdminUser
          onAdded={() => {
            toastSuccess('Admin user created.');
            void refetch();
            setShowCreate(false);
          }}
          onCancel={() => {
            setShowCreate(false);
          }}
        />
      ) : (
        <Button
          label="Add admin user"
          onClick={() => {
            setShowCreate(true);
          }}
        />
      )}
      <DataTable dataKey="id" className="mt-3" value={adminUsers} loading={loadingAdminUsers}>
        <Column header="Name" body={(row: AdminUser) => row.displayName} />
        <Column header="Email" body={(row: AdminUser) => row.email} />
        <Column
          header="Permissions"
          body={(row: AdminUser) => (
            <UserPlatformPermissionsCell
              user={row}
              platformId={platformId}
              // TODO: Pass down true when another property of the user is being saved.
              showPendingState={false}
              onError={onError}
            />
          )}
        />
        <Column header="Status" body={(row: AdminUser) => row.status} />
      </DataTable>
    </PageContainer>
  );
};
