import { useQuery } from '@apollo/client';
import { Fragment, FunctionComponent, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useErrorCollector } from '../../contexts/error-collector-context.js';
import { SiteContextProvider } from '../../contexts/SiteContext/SiteContext.js';
import { googleTagManagerScript, metaPixelScript } from '../../util/analyticsScripts.js';
import { siteVars } from '../../util/siteVars.js';
import { Spinner } from '../Spinner.js';
import { CurrentSiteDocument } from './queries.generated.js';

export const CurrentSiteProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onError } = useErrorCollector();
  const { data, loading } = useQuery(CurrentSiteDocument, {
    onError,
  });

  const site = data?.currentSite;

  return (
    <Fragment>
      {site && (
        <Helmet>
          <title>{site.name}</title>
          <meta name="description" content={site.name} />
          <style>{siteVars(site)}</style>
          {site.globalCss ? <style>{site.globalCss}</style> : null}
          {site.googleTagManagerId && (
            <script>{googleTagManagerScript(site.googleTagManagerId)}</script>
          )}
          {site.metaPixelId && <script>{metaPixelScript(site.metaPixelId)}</script>}
          {site.logoIconUrl && <link rel="icon" type="image/svg+xml" href={site.logoIconUrl} />}
          {site.zendeskChatKey && (
            <script
              id="ze-snippet"
              src={`https://static.zdassets.com/ekr/snippet.js?key=${site.zendeskChatKey}`}
            />
          )}
        </Helmet>
      )}
      {loading ? (
        <Spinner />
      ) : site ? (
        <SiteContextProvider value={site}>{children}</SiteContextProvider>
      ) : (
        // This div has mt-6 so that this message is visible even if a global error is shown at the top.
        <div className="mt-6 px-3 font-bold">No website was found here!</div>
      )}
    </Fragment>
  );
};
