import type { BooleanValue as BooleanValueType } from '@wirechunk/schemas/expressions/expression';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent } from 'react';
import type { BuilderProps } from './shared.js';

export const BooleanValue: FunctionComponent<BuilderProps<BooleanValueType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <div className="input-field mb-0">
      <label htmlFor={`${id}-fieldKey`}>Boolean input component name</label>
      <InputText
        id={`${id}-fieldKey`}
        keyfilter="alphanum"
        value={expression.model?.fieldKey || ''}
        onChange={(e) => {
          // Replace this expression with an inputDataBoolean expression.
          setExpression({
            operator: 'inputDataBoolean',
            fieldKey: e.target.value,
          });
        }}
      />
    </div>
  );
};
