import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { PrimeIcons } from 'primereact/api';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const BackButton: FunctionComponent<Component> = (props) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={pathname.substring(0, pathname.lastIndexOf('/')) || '/'}
      className={`${componentClassName(props)} flex align-items-center gap-1`}
    >
      <i className={PrimeIcons.ARROW_LEFT} />
      <span className="font-medium text-sm">Back</span>
    </Link>
  );
};
