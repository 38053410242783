import { ViewPlanStagesButtonComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { defaultDialogHeader } from '../../../mixer/ViewPlanStagesButton/ViewPlanStagesButton.js';
import { EditComponentContentProps } from '../shared/types.js';

export const EditViewPlanStagesButton: FunctionComponent<
  EditComponentContentProps<ViewPlanStagesButtonComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editViewPlanStagesButtonComponentDialogHeader">Dialog header</label>
      <InputText
        id="editViewPlanStagesButtonComponentDialogHeader"
        className="w-full"
        value={component.dialogHeader || ''}
        placeholder={defaultDialogHeader}
        onChange={(e) => {
          setComponent(
            (c): ViewPlanStagesButtonComponent => ({ ...c, dialogHeader: e.target.value }),
          );
        }}
      />
    </div>
    <div className="input-field">
      <label htmlFor="editViewPlanStagesButtonComponentDialogStageNameHeader">
        Stage name table header
      </label>
      <InputText
        id="editViewPlanStagesButtonComponentDialogStageNameHeader"
        className="w-full"
        value={component.stageNameTableHeader || ''}
        onChange={(e) => {
          setComponent(
            (c): ViewPlanStagesButtonComponent => ({ ...c, stageNameTableHeader: e.target.value }),
          );
        }}
      />
    </div>
  </Fragment>
);
