import { Component, ComponentType } from '@wirechunk/lib/mixer/types/components.js';

export const defaultConfirmationMessageComponents: Component[] = [
  {
    id: 'default-form-confirmation-message',
    type: ComponentType.Box,
    tag: 'p',
    styles: {
      'text-align': 'center',
    },
    children: [
      {
        id: 'default-form-confirmation-message-text',
        type: ComponentType.PlainText,
        text: 'Form submitted successfully! Thank you!',
      },
    ],
  },
];
