import { PrimeIcons } from 'primereact/api';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent, useLayoutEffect, useRef } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  PlatformContextProvider,
  usePlatformContext,
} from '../../../contexts/admin/platform-context/platform-context.js';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { BasicIconButton } from '../../BasicIconButton/BasicIconButton.js';
import { DashboardLayout } from '../../DashboardLayout/DashboardLayout.js';
import { NotFound } from '../../NotFound/NotFound.js';
import { Page as DynamicPage } from '../../Page/Page.js';
import { AdminUsers } from '../pages/AdminUsers/AdminUsers.js';
import { ApiTokens } from '../pages/api-tokens/api-tokens.js';
import { AuditLogs } from '../pages/AuditLogs/AuditLogs.js';
import { Component } from '../pages/components/component/component.js';
import { Components } from '../pages/components/components.js';
import { Course } from '../pages/content/courses/course/course.js';
import { Courses } from '../pages/content/courses/courses.js';
import { Document } from '../pages/content/documents/Document/Document.js';
import { Documents } from '../pages/content/documents/documents.js';
import { DownloadPrompts } from '../pages/content/documents/download-prompts/download-prompts.js';
import { Sequence } from '../pages/content/sequences/sequence/sequence.js';
import { Sequences } from '../pages/content/sequences/sequences.js';
import { HelpTickets } from '../pages/HelpTickets/HelpTickets.js';
import { Home } from '../pages/Home/Home.js';
import { Organization } from '../pages/orgs/Organization/Organization.js';
import { Organizations } from '../pages/orgs/Organizations.js';
import { ScoreMyCall } from '../pages/ScoreMyCall/ScoreMyCall.js';
import { Settings } from '../pages/Settings/Settings.js';
import { ConfirmationAction as FormTemplateConfirmationAction } from '../pages/Sites/FormTemplates/form-template/confirmation-action/confirmation-action.js';
import { DataFormatting as FormTemplateDataFormatting } from '../pages/Sites/FormTemplates/form-template/data-formatting/data-formatting.js';
import { Design as FormTemplateDesign } from '../pages/Sites/FormTemplates/form-template/design/design.js';
import { FormTemplate } from '../pages/Sites/FormTemplates/form-template/form-template.js';
import { NotificationEmail as FormTemplateNotificationEmail } from '../pages/Sites/FormTemplates/form-template/notification-email/notification-email.js';
import { Step as FormTemplateStep } from '../pages/Sites/FormTemplates/form-template/steps/step.js';
import { Steps as FormTemplateSteps } from '../pages/Sites/FormTemplates/form-template/steps/steps.js';
import { FormTemplates } from '../pages/Sites/FormTemplates/FormTemplates.js';
import { PagePurposes } from '../pages/Sites/page-purposes/page-purposes.js';
import { PageTemplate } from '../pages/Sites/PageTemplates/PageTemplate/PageTemplate.js';
import { PageTemplates } from '../pages/Sites/PageTemplates/PageTemplates.js';
import { FormEntries as SiteAllFormEntries } from '../pages/Sites/Site/form-entries/form-entries.js';
import { ConfirmationAction as FormConfirmationAction } from '../pages/Sites/Site/forms/form/confirmation-action.js';
import { Design as FormDesign } from '../pages/Sites/Site/forms/form/design.js';
import { Entries as FormEntries } from '../pages/Sites/Site/forms/form/entries/entries.js';
import { Form } from '../pages/Sites/Site/forms/form/form.js';
import { Notifications as FormNotifications } from '../pages/Sites/Site/forms/form/notifications/notifications.js';
import { Step as FormStep } from '../pages/Sites/Site/forms/form/steps/step.js';
import { Steps as FormSteps } from '../pages/Sites/Site/forms/form/steps/steps.js';
import { Forms } from '../pages/Sites/Site/forms/forms.js';
import { PageDesign } from '../pages/Sites/Site/Pages/page/design/design.js';
import { PageHistory } from '../pages/Sites/Site/Pages/page/history/history.js';
import { PageMeta } from '../pages/Sites/Site/Pages/page/meta/meta.js';
import { Page } from '../pages/Sites/Site/Pages/page/page.js';
import { Pages } from '../pages/Sites/Site/Pages/pages.js';
import { Settings as SiteSettings } from '../pages/Sites/Site/Settings/Settings.js';
import { Site } from '../pages/Sites/Site/Site.js';
import { Sites } from '../pages/Sites/Sites.js';
import { Products } from '../pages/SubscriptionPlans/Products.js';
import { Subscriptions } from '../pages/Subscriptions/Subscriptions.js';
import { Users } from '../pages/Users/Users.js';
import { StageBlueprint } from '../stage-blueprints/stage-blueprint/StageBlueprint.js';
import { SidebarInner } from './SidebarInner.js';

const ToolbarLeftElements: FunctionComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { id: currentPlatformId, name } = usePlatformContext();
  const menu = useRef<Menu>(null);

  if (user.platformPermissions.length > 1) {
    return (
      <Fragment>
        <div className="border-left-2 border-500 pl-3 pr-2">
          <span className="font-medium">{name}</span>
        </div>
        <BasicIconButton
          icon={PrimeIcons.SITEMAP}
          onClick={(event) => {
            menu.current?.show(event);
          }}
        />
        <Menu
          ref={menu}
          model={user.platformPermissions.map(({ platform: { id, handle, name } }) => ({
            id,
            label: name,
            style: id === currentPlatformId ? { fontWeight: '700' } : undefined,
            command: () => {
              const path = pathname.replace(/^\/dashboard\/[^/]+/, '');
              navigate(`/dashboard/${handle}${path}`);
            },
          }))}
          popup
        />
      </Fragment>
    );
  }

  return null;
};

const DefaultPlatformRedirect: FunctionComponent = () => {
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  useLayoutEffect(() => {
    const [userPermission] = user.platformPermissions;
    if (userPermission) {
      navigate(`/dashboard/${userPermission.platform.handle}`);
    }
  }, [navigate, user.platformPermissions]);

  return <div>It appears that you do not have admin permissions on any platform.</div>;
};

export const AuthenticatedBody: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<DefaultPlatformRedirect />} />
      <Route
        path="dashboard/:platformHandle"
        element={
          <PlatformContextProvider>
            <DashboardLayout SidebarInner={SidebarInner} ToolbarLeftElements={ToolbarLeftElements}>
              <Outlet />
            </DashboardLayout>
          </PlatformContextProvider>
        }
      >
        <Route index element={<Home />} />
        <Route path="admin-users" element={<AdminUsers />} />
        <Route path="api-tokens" element={<ApiTokens />} />
        <Route path="components">
          <Route index element={<Components />} />
          <Route path=":componentId" element={<Component />} />
        </Route>
        <Route path="organizations">
          <Route index element={<Organizations />} />
          <Route path=":organizationId" element={<Organization />} />
        </Route>
        <Route path="products" element={<Products />} />
        <Route path="score-my-call" element={<ScoreMyCall />} />
        <Route path="settings" element={<Settings />} />
        <Route path="sites">
          <Route index element={<Sites />} />
          <Route path="page-purposes" element={<PagePurposes />} />
          <Route path="page-templates">
            <Route index element={<PageTemplates />} />
            <Route path=":pageTemplateId" element={<PageTemplate />} />
          </Route>
          <Route path="form-templates">
            <Route index element={<FormTemplates />} />
            <Route path=":formTemplateId" element={<FormTemplate />}>
              <Route index element={<FormTemplateDesign />} />
              <Route path="confirmation-action" element={<FormTemplateConfirmationAction />} />
              <Route path="data-formatting" element={<FormTemplateDataFormatting />} />
              <Route path="notification-email" element={<FormTemplateNotificationEmail />} />
              <Route path="steps">
                <Route index element={<FormTemplateSteps />} />
                <Route path=":formTemplateStepId" element={<FormTemplateStep />} />
              </Route>
            </Route>
          </Route>
          <Route path=":siteId" element={<Site />}>
            <Route path="forms">
              <Route index element={<Forms />} />
              <Route path=":formId" element={<Form />}>
                <Route index element={<FormDesign />} />
                <Route path="confirmation-action" element={<FormConfirmationAction />} />
                <Route path="steps">
                  <Route index element={<FormSteps />} />
                  <Route path=":formStepId" element={<FormStep />} />
                </Route>
                <Route path="notifications" element={<FormNotifications />} />
                <Route path="entries" element={<FormEntries />} />
              </Route>
            </Route>
            <Route path="form-entries" element={<SiteAllFormEntries />} />
            <Route path="pages">
              <Route index element={<Pages />} />
              <Route path=":pageId" element={<Page />}>
                <Route index element={<PageMeta />} />
                <Route path="design" element={<PageDesign />} />
                <Route path="history" element={<PageHistory />} />
              </Route>
            </Route>
            <Route path="settings" element={<SiteSettings />} />
          </Route>
        </Route>
        <Route path="subscriptions" element={<Subscriptions />} />
        <Route path="content">
          <Route path="courses">
            <Route index element={<Courses />} />
            <Route path=":courseId">
              <Route index element={<Course />} />
              <Route
                path="stage-blueprints/:stageBlueprintId"
                element={<StageBlueprint title="Lesson" />}
              />
            </Route>
          </Route>
          <Route path="documents">
            <Route index element={<Documents />} />
            <Route path=":documentId" element={<Document />} />
          </Route>
          <Route path="document-download-prompts" element={<DownloadPrompts />} />
          <Route path="sequences">
            <Route index element={<Sequences />} />
            <Route path=":sequenceId">
              <Route index element={<Sequence />} />
              <Route
                path="stage-blueprints/:stageBlueprintId"
                element={<StageBlueprint title="Stage" />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="users" element={<Users />} />
        <Route path="help-tickets" element={<HelpTickets />} />
        <Route path="audit-logs" element={<AuditLogs />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<DynamicPage />} />
    </Routes>
  );
};
