import { evaluateBooleanExpression } from '@wirechunk/lib/expression-builder/evaluator.js';
import {
  ConditionalLogicComponent,
  ConditionalLogicSlotKey,
} from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useMixerContextData } from '../../../hooks/use-mixer-context-data.js';
import { RenderMixerChildren } from '../../RenderMixerChildren.js';

export const ConditionalLogic: FunctionComponent<ConditionalLogicComponent> = (props) => {
  const contextData = useMixerContextData();

  const result = props.condition ? evaluateBooleanExpression(props.condition, contextData) : false;

  if (result) {
    return (
      <RenderMixerChildren>
        {props.children?.filter((child) => child.slot === ConditionalLogicSlotKey.True)}
      </RenderMixerChildren>
    );
  }

  return (
    <RenderMixerChildren>
      {props.children?.filter((child) => child.slot === ConditionalLogicSlotKey.False)}
    </RenderMixerChildren>
  );
};
