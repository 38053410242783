/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type CreateTlsCertificateMutationVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
}>;

export type CreateTlsCertificateMutation = {
  createTlsCertificate: {
    __typename: 'TlsCertificate';
    id: string;
    name: string;
    domain: string;
    state: Types.GCloudCertificateState;
    mapEntryState?: Types.GCloudCertificateMapEntryState | null;
  };
};

export type CreateTlsCertificateMapEntryMutationVariables = Types.Exact<{
  certificateName: Types.Scalars['String']['input'];
  domain: Types.Scalars['String']['input'];
}>;

export type CreateTlsCertificateMapEntryMutation = {
  createTlsCertificateMapEntry: {
    __typename: 'TlsCertificate';
    id: string;
    name: string;
    domain: string;
    state: Types.GCloudCertificateState;
    mapEntryState?: Types.GCloudCertificateMapEntryState | null;
  };
};

export type DeleteTlsCertificateMutationVariables = Types.Exact<{
  certificateName: Types.Scalars['String']['input'];
}>;

export type DeleteTlsCertificateMutation = { deleteTlsCertificate: boolean };

export type EditSiteDomainMutationVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
  domain: Types.Scalars['String']['input'];
}>;

export type EditSiteDomainMutation = {
  editSiteDomain: { __typename: 'Site'; id: string; domain: string };
};

export const CreateTlsCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTlsCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTlsCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapEntryState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTlsCertificateMutation, CreateTlsCertificateMutationVariables>;
export const CreateTlsCertificateMapEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTlsCertificateMapEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTlsCertificateMapEntry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapEntryState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTlsCertificateMapEntryMutation,
  CreateTlsCertificateMapEntryMutationVariables
>;
export const DeleteTlsCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTlsCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'certificateName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTlsCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'certificateName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'certificateName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTlsCertificateMutation, DeleteTlsCertificateMutationVariables>;
export const EditSiteDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditSiteDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editSiteDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditSiteDomainMutation, EditSiteDomainMutationVariables>;
