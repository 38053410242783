import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import { exhaustive } from '@wirechunk/lib/exhaustive.js';
import {
  ExpressionType,
  IncompleteExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import { MixerEventType, Trigger } from '@wirechunk/lib/mixer/types/components.js';
import { Expression } from '@wirechunk/schemas/expressions/expression';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import type { SetState } from '../../../hooks/use-state-ref.js';
import type { SelectItem } from '../../../types.js';
import { isMixerEventType } from '../../../util/mixer/events';
import { ExpressionBuilder } from '../../ExpressionBuilder/ExpressionBuilder.js';

const allTypes = Object.values(MixerEventType).sort();

const typeHumanReadable = (type: MixerEventType): string => {
  switch (type) {
    case MixerEventType.Click:
      return 'Click';
    case MixerEventType.InputChange:
      return 'Input change';
    default:
      return exhaustive(type);
  }
};

const typesToOptions = (types: MixerEventType[]): Array<SelectItem<MixerEventType>> =>
  types.map((type) => ({ label: typeHumanReadable(type), value: type }));

export type WorkingTrigger = Omit<Trigger, 'condition'> & {
  condition?: IncompleteExpression<Expression> | null;
};

export type EditTriggersProps = {
  triggers: WorkingTrigger[];
  setTriggers: SetState<WorkingTrigger[]>;
  types?: MixerEventType[];
};

export const EditTriggers: FunctionComponent<EditTriggersProps> = ({
  triggers,
  setTriggers,
  types = allTypes,
}) => {
  const options = typesToOptions(types);
  return (
    <Fragment>
      {triggers.length ? (
        <div className="flex flex-column gap-3">
          {triggers.map((trigger, i) => (
            <div key={trigger.id} className="border-1 border-round p-3">
              <div className="flex align-items-start justify-content-between">
                <div className="font-medium mb-2">{typeHumanReadable(trigger.type)} event</div>
                <Button
                  text
                  plain
                  icon={PrimeIcons.TRASH}
                  className="p-2 w-max h-max line-height-1"
                  onClick={() => {
                    setTriggers((triggers) => triggers.filter((t) => t.id !== trigger.id));
                  }}
                />
              </div>
              <label htmlFor={`editTriggers-${trigger.id}-condition`}>Condition (optional)</label>
              <ExpressionBuilder
                id={`editTriggers-${trigger.id}-condition`}
                expression={trigger.condition ?? null}
                setExpression={(expr) => {
                  setTriggers((triggers) =>
                    triggers.with(i, {
                      ...trigger,
                      condition: expr,
                    }),
                  );
                }}
                onDelete={() => {
                  setTriggers((triggers) =>
                    triggers.with(i, {
                      ...trigger,
                      condition: null,
                    }),
                  );
                }}
                validationMessages={[]}
                types={ExpressionType.Boolean}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-500">No triggers</div>
      )}
      <Dropdown
        placeholder="Add trigger"
        className="mt-3"
        options={options}
        onChange={({ value }) => {
          if (isMixerEventType(value)) {
            setTriggers((triggers) => [
              ...triggers,
              {
                id: cleanSmallId(),
                type: value,
              },
            ]);
          }
        }}
      />
    </Fragment>
  );
};
