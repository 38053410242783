import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useState } from 'react';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext';
import { useToast } from '../../../../contexts/ToastContext';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../hooks/useMenuSetup';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../Spinner.js';
import { ThreeDotMenuButton } from '../../../ThreeDotMenuButton/ThreeDotMenuButton';
import { CreateApiToken } from './create-api-token.js';
import { CreateApiTokenDocument, InvalidateApiTokenDocument } from './mutations.generated.js';
import { ApiTokensDocument, ApiTokensQuery, ApiTokensQueryVariables } from './queries.generated.js';

type QueryToken = NonNullable<ApiTokensQuery['apiTokens']['apiTokens']>[number];

export const ApiTokens: FunctionComponent = () => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const [showCreate, setShowCreate] = useState(false);
  const { data, loading } = useQuery(ApiTokensDocument, {
    onError,
  });
  const [createApiToken, { loading: creating }] = useMutation(CreateApiTokenDocument, {
    onError,
    onCompleted: ({ createApiToken: { success, userErrors, token } }) => {
      if (success) {
        setShowCreate(false);
        if (token) {
          dialog({
            content: (
              <div>
                <div className="font-medium mb-1">
                  Here is your token. Please copy it. You will not be able to see the token again.
                </div>
                <div
                  className="wrap-overflow-text"
                  onClick={() => {
                    void (async () => {
                      await window.navigator.clipboard.writeText(token);
                      toastSuccess('Token copied to clipboard.');
                    })();
                  }}
                >
                  {token}
                </div>
              </div>
            ),
            props: {
              header: 'Token created',
            },
          });
        }
      }
      if (userErrors) {
        const [firstError] = userErrors;
        if (firstError) {
          onError(firstError.message);
        }
      }
    },
    update: (cache, { data }) => {
      const result = data?.createApiToken;
      if (result?.success && result.apiToken) {
        const currentApiTokensResult = cache.readQuery<ApiTokensQuery, ApiTokensQueryVariables>({
          query: ApiTokensDocument,
        });
        if (currentApiTokensResult) {
          cache.writeQuery<ApiTokensQuery, ApiTokensQueryVariables>({
            query: ApiTokensDocument,
            data: {
              apiTokens: {
                ...currentApiTokensResult.apiTokens,
                totalCount: (currentApiTokensResult.apiTokens.totalCount || 0) + 1,
                apiTokens: [result.apiToken, ...currentApiTokensResult.apiTokens.apiTokens],
              },
            },
          });
        }
      }
    },
  });
  const [invalidateToken] = useMutation(InvalidateApiTokenDocument, {
    onError,
  });
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<QueryToken>();

  return (
    <PageContainer title="API Tokens">
      <ErrorMessage />
      {showCreate ? (
        <CreateApiToken
          className="mt-1 mb-4"
          creating={creating}
          onCreate={(input) => {
            void createApiToken({ variables: { input } });
          }}
          onCancel={() => {
            setShowCreate(false);
          }}
        />
      ) : (
        <Button
          label="Create token"
          className="mb-3"
          disabled={loading}
          onClick={() => {
            setShowCreate(true);
          }}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <DataTable<QueryToken[]> dataKey="id" value={data?.apiTokens.apiTokens || []}>
          <Column field="name" header="Name" />
          <Column
            header="Created"
            field="createdAt"
            body={(row: QueryToken) => new Date(row.createdAt).toLocaleString()}
          />
          <Column
            header="Status"
            body={(row: QueryToken) =>
              row.invalidatedAt
                ? `Invalidated on ${new Date(row.invalidatedAt).toLocaleString()}`
                : 'Active'
            }
          />
          <Column
            header=""
            align="right"
            body={(row: QueryToken) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
          />
        </DataTable>
      )}
      <Menu
        ref={menu}
        model={[
          {
            label: 'Deactivate',
            command: () => {
              if (menuRow) {
                dialog({
                  confirm: 'Are you sure you want to permanently deactivate this token?',
                  props: {
                    header: 'Confirm',
                    onAccept: () => {
                      void invalidateToken({
                        variables: { id: menuRow.id },
                      });
                    },
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
