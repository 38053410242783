import {
  ExpressionType,
  IncompleteExpression,
  isBooleanExpression,
  isCompleteExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import {
  ComponentType,
  OrderFormPromoCodeType,
  OrderPageCheckoutOption,
  OrderPageComponent,
} from '@wirechunk/lib/mixer/types/components.js';
import type { Expression } from '@wirechunk/schemas/expressions/expression';
import { isEmpty, isString } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useSubscriptionPlanNames } from '../../../../hooks/use-subscription-plan-names/use-subscription-plan-names.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { SelectItem } from '../../../../types.js';
import { BasicIconButton } from '../../../BasicIconButton/BasicIconButton';
import { CheckboxWithLabel } from '../../../checkbox-with-label/checkbox-with-label.js';
import { ExpressionBuilder } from '../../../ExpressionBuilder/ExpressionBuilder.js';
import { Spinner } from '../../../Spinner.js';
import type { ComponentEditorListener, EditComponentContentProps } from '../shared/types.js';

const noneValue = 'None';

const enablePromoCodeOptions: Array<SelectItem<OrderFormPromoCodeType | typeof noneValue>> = [
  { label: 'None', value: noneValue },
  { label: 'Stripe promo codes', value: OrderFormPromoCodeType.Stripe },
  { label: 'Custom promo codes', value: OrderFormPromoCodeType.Custom },
];

const isOrderFormPromoCodeType = (value: unknown): value is OrderFormPromoCodeType =>
  value === OrderFormPromoCodeType.Stripe || value === OrderFormPromoCodeType.Custom;

type WorkingCheckoutOption = {
  subscriptionPlanId: string;
  enabled: boolean;
  condition: IncompleteExpression<Expression> | null | undefined;
};

export const EditOrderPage: FunctionComponent<EditComponentContentProps<OrderPageComponent>> = ({
  component,
  setComponent,
  eventHooks,
}) => {
  const platform = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { subscriptionPlans, loading } = useSubscriptionPlanNames(platform.id, onError);

  const [workingCheckoutOptions, setWorkingCheckoutOptions] = useState<WorkingCheckoutOption[]>(
    () =>
      component.checkoutOptions?.map<WorkingCheckoutOption>(
        ({ subscriptionPlanId, condition }) => ({
          subscriptionPlanId,
          enabled: !!condition,
          condition,
        }),
      ) ?? [],
  );
  const workingCheckoutOptionsRef = useRef(workingCheckoutOptions);
  useEffect(() => {
    workingCheckoutOptionsRef.current = workingCheckoutOptions;
  }, [workingCheckoutOptions]);

  useEffect(() => {
    const updateCheckoutOptions: ComponentEditorListener = (component) => {
      // This type check is just to satisfy TypeScript.
      if (component.type === ComponentType.OrderPage) {
        const checkoutOptions: OrderPageCheckoutOption[] = [];
        // A mapping from subscription plan IDs to validation error message.
        const validationErrors: Record<string, string> = {};

        for (const {
          subscriptionPlanId,
          enabled,
          condition,
        } of workingCheckoutOptionsRef.current) {
          if (enabled) {
            if (condition && isCompleteExpression(condition)) {
              if (isBooleanExpression(condition)) {
                checkoutOptions.push({
                  subscriptionPlanId,
                  condition,
                });
              } else {
                validationErrors[subscriptionPlanId] = 'The condition must be a boolean expression';
              }
            } else {
              validationErrors[subscriptionPlanId] =
                'The condition must be completed if it is enabled';
            }
          } else {
            checkoutOptions.push({
              subscriptionPlanId,
            });
          }
        }

        if (isEmpty(validationErrors)) {
          return {
            action: 'continue',
            component: {
              ...component,
              checkoutOptions,
            } satisfies OrderPageComponent,
          };
        }

        return {
          action: 'prevent',
          errorMessage: `Please correct the following errors: ${Object.values(
            validationErrors,
          ).join(', ')}`,
        };
      }
      return { action: 'continue' };
    };

    eventHooks.onBeforeLeave(updateCheckoutOptions);

    return () => {
      eventHooks.offBeforeLeave(updateCheckoutOptions);
    };
  }, [eventHooks]);

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="editOrderFormEnablePromoCode">Enable promo code</label>
        <Dropdown
          inputId="editOrderFormEnablePromoCode"
          className="w-full"
          value={component.enablePromoCode ?? noneValue}
          options={enablePromoCodeOptions}
          onChange={({ value }) => {
            if (value === noneValue) {
              setComponent(
                (component): OrderPageComponent => ({
                  ...component,
                  enablePromoCode: null,
                }),
              );
            } else if (isOrderFormPromoCodeType(value)) {
              setComponent(
                (component): OrderPageComponent => ({
                  ...component,
                  enablePromoCode: value,
                }),
              );
            }
          }}
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="input-field">
            <label htmlFor="editOrderPageAddSubscriptionPlan">Select products</label>
            <Dropdown
              inputId="editOrderPageAddSubscriptionPlan"
              className="w-full"
              placeholder="Add plan"
              options={
                subscriptionPlans?.products.filter(
                  ({ id }) =>
                    !workingCheckoutOptions.some(
                      (workingCondition) => workingCondition.subscriptionPlanId === id,
                    ),
                ) ?? []
              }
              optionLabel="name"
              optionValue="id"
              onChange={({ value }) => {
                if (isString(value)) {
                  setWorkingCheckoutOptions((workingCheckoutOptions) =>
                    workingCheckoutOptions.some(
                      ({ subscriptionPlanId }) => subscriptionPlanId === value,
                    )
                      ? workingCheckoutOptions
                      : [
                          ...workingCheckoutOptions,
                          {
                            subscriptionPlanId: value,
                            enabled: false,
                            condition: null,
                          },
                        ],
                  );
                }
              }}
            />
          </div>
          {workingCheckoutOptions.map(({ subscriptionPlanId }) => (
            <div key={subscriptionPlanId}>
              <div className="flex align-items-center gap-2 mb-1">
                <div className="font-bold">
                  {subscriptionPlans?.products.find(({ id }) => id === subscriptionPlanId)?.name ??
                    '(Unknown subscription plan)'}
                </div>
                <BasicIconButton
                  onClick={() => {
                    setWorkingCheckoutOptions((workingCheckoutOptions) =>
                      workingCheckoutOptions.filter(
                        (workingCondition) =>
                          workingCondition.subscriptionPlanId !== subscriptionPlanId,
                      ),
                    );
                  }}
                  icon={PrimeIcons.TRASH}
                />
              </div>
              <CheckboxWithLabel
                id={`editOrderPageSubscriptionPlanEnableCondition${subscriptionPlanId}`}
                label="Enable condition"
                checked={workingCheckoutOptions.some(
                  (workingCondition) =>
                    workingCondition.subscriptionPlanId === subscriptionPlanId &&
                    workingCondition.enabled,
                )}
                onChange={() => {
                  setWorkingCheckoutOptions((workingCheckoutOptions) =>
                    workingCheckoutOptions.map((workingCondition) =>
                      workingCondition.subscriptionPlanId === subscriptionPlanId
                        ? {
                            ...workingCondition,
                            enabled: !workingCondition.enabled,
                          }
                        : workingCondition,
                    ),
                  );
                }}
                inputNotice="This subscription plan will be shown as an option if you do not enable a condition, but if you do then the subscription plan will be shown only when the user satisfies the condition."
              />
              {workingCheckoutOptions.some(
                (workingCondition) =>
                  workingCondition.subscriptionPlanId === subscriptionPlanId &&
                  workingCondition.enabled,
              ) && (
                <ExpressionBuilder
                  id={`editOrderPageSubscriptionPlanCondition${subscriptionPlanId}`}
                  expression={
                    workingCheckoutOptions.find(
                      (workingCondition) =>
                        workingCondition.subscriptionPlanId === subscriptionPlanId,
                    )?.condition ?? null
                  }
                  setExpression={(expression) => {
                    setWorkingCheckoutOptions((workingCheckoutOptions) =>
                      workingCheckoutOptions.map((workingCondition) =>
                        workingCondition.subscriptionPlanId === subscriptionPlanId
                          ? {
                              ...workingCondition,
                              condition: expression,
                            }
                          : workingCondition,
                      ),
                    );
                  }}
                  validationMessages={[]}
                  types={ExpressionType.Boolean}
                />
              )}
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};
