/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type FormQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type FormQuery = {
  form: {
    __typename: 'Form';
    id: string;
    title: string;
    components: string;
    confirmationAction: Types.ConfirmationAction;
    confirmationMessageComponents?: string | null;
    confirmationRedirectUrl?: string | null;
    confirmationRedirectParameters: Array<{
      __typename: 'FormConfirmationRedirectUrlParameter';
      id: string;
      parameter: string;
      componentName: string;
    }>;
    steps: Array<{
      __typename: 'FormStep';
      id: string;
      name: string;
      position: string;
      components: string;
      enabled: boolean;
    }>;
  };
};

export const FormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Form' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationMessageComponents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationRedirectUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmationRedirectParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormQuery, FormQueryVariables>;
