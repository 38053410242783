import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo } from 'react';

const context = createContext<string>('');

export const LabelsNamespaceContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const namespace = useMemo(() => cleanSmallId(), []);

  return <context.Provider value={namespace}>{children}</context.Provider>;
};

export const useLabelsNamespaceContext = (): string => useContext(context);
