import { MapComponent, MapFocus } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { SelectItem } from '../../../types.js';
import {
  SelectGooglePlace,
  SelectGooglePlaceProps,
} from '../../SelectGooglePlace/SelectGooglePlace.js';
import { placeToDescription } from '../../SelectGooglePlace/utils.js';
import { EditComponentContentProps } from './shared/types.js';

const isMapFocusType = (value: unknown): value is MapFocus['type'] =>
  value === 'SiteGooglePlace' || value === 'CustomGooglePlace' || value === 'OrganizationPlace';

const focusTypeDropdownOptions: Array<SelectItem<MapFocus['type']>> = [
  { label: 'Global site setting for Google place', value: 'SiteGooglePlace' },
  { label: 'Custom place', value: 'CustomGooglePlace' },
];

export const EditMap: FunctionComponent<EditComponentContentProps<MapComponent>> = ({
  component,
  setComponent,
}) => {
  const [googlePlaceDescription, setGooglePlaceDescription] = useState('');

  const setGooglePlace = useCallback<SelectGooglePlaceProps['setPlace']>(
    (place) => {
      setComponent((c) => ({
        ...c,
        focus: {
          type: 'CustomGooglePlace',
          placeId: place.place_id,
        },
      }));
      setGooglePlaceDescription(placeToDescription(place));
    },
    [setComponent],
  );

  const { focus } = component;

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editMapFocusType">Focus location</label>
        <Dropdown
          className="w-full"
          inputId="editMapFocusType"
          options={focusTypeDropdownOptions}
          value={
            isMapFocusType(focus?.type)
              ? // Coerce 'OrganizationPlace' to 'SiteGooglePlace' for the dropdown.
                focus.type === 'OrganizationPlace'
                ? 'SiteGooglePlace'
                : focus.type
              : 'SiteGooglePlace'
          }
          onChange={({ value }) => {
            if (isMapFocusType(value)) {
              switch (value) {
                case 'SiteGooglePlace':
                case 'OrganizationPlace':
                  setComponent((c): MapComponent => ({ ...c, focus: { type: 'SiteGooglePlace' } }));
                  break;
                case 'CustomGooglePlace':
                  setComponent(
                    (c): MapComponent => ({
                      ...c,
                      focus: { type: 'CustomGooglePlace', placeId: null },
                    }),
                  );
                  break;
              }
            }
          }}
        />
      </div>
      {component.focus?.type === 'CustomGooglePlace' && (
        <Fragment>
          {googlePlaceDescription && (
            <div>
              <span className="font-medium">Selected:</span> {googlePlaceDescription}
            </div>
          )}
          {component.focus.placeId && (
            <Button
              className="p-button-outlined p-button-sm my-1"
              label="Clear"
              onClick={() => {
                setComponent(
                  (c): MapComponent => ({
                    ...c,
                    focus: { type: 'CustomGooglePlace', placeId: null },
                  }),
                );
                setGooglePlaceDescription('');
              }}
            />
          )}
          <SelectGooglePlace placeId={component.focus.placeId} setPlace={setGooglePlace} />
        </Fragment>
      )}
    </Fragment>
  );
};
