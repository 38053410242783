/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type SubscriptionPlanQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type SubscriptionPlanQuery = {
  subscriptionPlan: {
    __typename: 'SubscriptionPlan';
    id: string;
    name: string;
    displayName?: string | null;
    description?: string | null;
    items: Array<string>;
    endAt?: string | null;
    durationDays?: number | null;
    price: string;
    invoiceFrequency?: Types.InvoiceFrequency | null;
    stripePriceId?: string | null;
    alternativeStripePriceIds: Array<string>;
    promoCodes: Array<{
      __typename: 'PromoCode';
      id: string;
      code: string;
      successMessage?: { __typename: 'Delta'; delta: string } | null;
    }>;
  };
};

export type ProductItemOccurrencesQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  item: Types.Scalars['String']['input'];
}>;

export type ProductItemOccurrencesQuery = {
  productItemOccurrences: {
    __typename: 'ProductItemOccurrences';
    documents: {
      __typename: 'DocumentsList';
      totalCount: number;
      documents: Array<{ __typename: 'Document'; id: string; name: string }>;
    };
    pages: {
      __typename: 'PagesList';
      totalCount: number;
      pages: Array<{ __typename: 'Page'; id: string; title: string }>;
    };
  };
};

export const SubscriptionPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubscriptionPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'durationDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceFrequency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promoCodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'successMessage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delta' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stripePriceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alternativeStripePriceIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>;
export const ProductItemOccurrencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductItemOccurrences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'item' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productItemOccurrences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'item' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'item' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductItemOccurrencesQuery, ProductItemOccurrencesQueryVariables>;
