import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Fragment, FunctionComponent, useState } from 'react';
import { useProductAnalytics } from '../../../contexts/ProductAnalyticsContext/ProductAnalyticsContext.js';
import styles from './MarketingCalculator.module.css';

type ScenarioInput = {
  monthlyPremiumGoal: number | null;
  averageHouseholdPremium: number | null;
  averageCostPerLead: number | null;
  closePercentage: number | null;
};

type Scenario = {
  monthlyPremiumGoal: number;
  averageHouseholdPremium: number;
  averageCostPerLead: number;
  closeRate: number;
  showCalculation: boolean;

  // Calculated values

  numberOfNewHouseholds: number;
  numberOfLeadsNeeded: number;
};

const monthlyPremiumGoalDefault = 100_000;
const averageHouseholdPremiumDefault = 3_000;
const averageCostPerLeadDefault = 20;
const closePercentageDefault = 0.1;

const calculateScenario = (s: ScenarioInput): Scenario => {
  const monthlyPremiumGoal = s.monthlyPremiumGoal ?? monthlyPremiumGoalDefault;
  const averageHouseholdPremium = s.averageHouseholdPremium ?? averageHouseholdPremiumDefault;
  const averageCostPerLead = s.averageCostPerLead ?? averageCostPerLeadDefault;
  const closeRate = s.closePercentage === null ? closePercentageDefault : s.closePercentage / 100;

  const numberOfNewHouseholdsExact = averageHouseholdPremium
    ? monthlyPremiumGoal / averageHouseholdPremium
    : 0;
  const numberOfNewHouseholds = Math.ceil(numberOfNewHouseholdsExact);
  const numberOfLeadsNeeded = closeRate ? Math.ceil(numberOfNewHouseholdsExact / closeRate) : 0;

  return {
    monthlyPremiumGoal,
    averageHouseholdPremium,
    averageCostPerLead,
    closeRate,
    showCalculation: false,
    numberOfNewHouseholds,
    numberOfLeadsNeeded,
  };
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

const currency = (value: number) => currencyFormat.format(value);

const percentageFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});

const defaultScenarioInput = (): ScenarioInput => ({
  monthlyPremiumGoal: null,
  averageHouseholdPremium: null,
  averageCostPerLead: null,
  closePercentage: null,
});

const calculationMethodDetails = (s: Scenario): Array<{ label: string; value: string }> => [
  {
    label: 'Your average premium per household',
    value: currency(s.averageHouseholdPremium),
  },
  {
    label: 'Your average cost per lead',
    value: currency(s.averageCostPerLead),
  },
  {
    label: 'Your close rate',
    value: percentageFormat.format(s.closeRate),
  },
  {
    label: 'Premium you need to quote monthly (Desired Monthly Premium / Close Rate)',
    value: currency(s.closeRate ? s.monthlyPremiumGoal / s.closeRate : 0),
  },
  {
    label: 'Monthly leads to buy (Monthly Premium Quoted / Premium Per Household)',
    value: s.numberOfLeadsNeeded.toString(),
  },
  {
    label: 'Monthly Households (Desired Monthly Premium / Premium Per Household)',
    value: s.numberOfNewHouseholds.toString(),
  },
];

export const MarketingCalculator: FunctionComponent<Component> = (props) => {
  const { track } = useProductAnalytics();
  const [isAddingScenario, setIsAddingScenario] = useState(true);
  const [scenarioState, setScenarioState] = useState<ScenarioInput>(defaultScenarioInput);
  const [computedScenarios, setComputedScenarios] = useState<Scenario[]>([]);

  return (
    <div className={`${componentClassName(props)} ${styles.container}`}>
      {computedScenarios.map((scenario, i) => (
        <div key={i} className={`${styles.scenario} mb-5 border-1 border-round-lg overflow-hidden`}>
          <div className="surface-ground p-3 flex flex-row align-items-center justify-content-between">
            <div className="font-bold">SCENARIO {i + 1} OUTPUT</div>
            <Button
              label="Delete"
              className="p-button-danger p-button-outlined p-button-sm background-white"
              onClick={() => {
                setComputedScenarios(computedScenarios.filter((_, j) => j !== i));
              }}
            />
          </div>
          <div>
            <div className="py-3 px-4">
              <div>
                <p className="mt-2">
                  If your leads cost <strong>{currency(scenario.averageCostPerLead)}</strong> per
                  lead and you’re closing{' '}
                  <strong>{percentageFormat.format(scenario.closeRate)}</strong> of those leads,
                  then consider the following:
                </p>
                <p className="mt-2">
                  To write <strong>{currency(scenario.monthlyPremiumGoal)}</strong> in premium a
                  month, you need to buy <strong>{scenario.numberOfLeadsNeeded}</strong> leads a
                  month (or <strong>{scenario.numberOfLeadsNeeded / 20}</strong> leads a day).
                </p>
                <p className="mt-2">
                  It’ll cost you{' '}
                  <strong>
                    {currency(scenario.numberOfLeadsNeeded * scenario.averageCostPerLead)}
                  </strong>{' '}
                  per month to buy <strong>{scenario.numberOfLeadsNeeded}</strong> leads.
                </p>
              </div>
              {scenario.showCalculation && (
                <div className="mt-3">
                  {calculationMethodDetails(scenario).map(({ label, value }) => (
                    <div key={label} className="flex justify-content-between mt-2">
                      <div className="pr-3">{label}</div>
                      <div className="font-bold">{value}</div>
                    </div>
                  ))}
                </div>
              )}
              <div className="text-center">
                <Button
                  label={(scenario.showCalculation ? 'Hide' : 'Show') + ' Calculation Method'}
                  className="w-fit mb-2 mt-4 p-button-outlined"
                  onClick={() => {
                    setComputedScenarios(
                      computedScenarios.map((s, j) => {
                        if (j === i) {
                          return {
                            ...s,
                            showCalculation: !s.showCalculation,
                          };
                        }
                        return s;
                      }),
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      {isAddingScenario ? (
        <Fragment>
          {/* This extra div for centered layout. */}
          <div className={`${styles.containedWidth} mb-1`}>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <label htmlFor="monthlyPremiumGoal">Monthly premium goal</label>
              <InputNumber
                inputId="monthlyPremiumGoal"
                className="w-full lg:w-13rem"
                mode="currency"
                currency="USD"
                maxFractionDigits={0}
                placeholder={currency(monthlyPremiumGoalDefault)}
                value={scenarioState.monthlyPremiumGoal}
                onChange={(e) => {
                  setScenarioState({
                    ...scenarioState,
                    monthlyPremiumGoal: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <label htmlFor="averageHouseholdPremium">Average household premium</label>
              <InputNumber
                inputId="averageHouseholdPremium"
                className="w-full lg:w-13rem"
                mode="currency"
                currency="USD"
                maxFractionDigits={0}
                placeholder={currency(averageHouseholdPremiumDefault)}
                value={scenarioState.averageHouseholdPremium}
                onChange={(e) => {
                  setScenarioState({
                    ...scenarioState,
                    averageHouseholdPremium: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <label htmlFor="averageCostPerLead">Cost per lead</label>
              <InputNumber
                inputId="averageCostPerLead"
                className="w-full lg:w-13rem"
                mode="currency"
                currency="USD"
                maxFractionDigits={0}
                placeholder={currency(averageCostPerLeadDefault)}
                value={scenarioState.averageCostPerLead}
                onChange={(e) => {
                  setScenarioState({
                    ...scenarioState,
                    averageCostPerLead: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <label htmlFor="closeRate">Close rate (%)</label>
              <InputNumber
                inputId="closeRate"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={0}
                placeholder={percentageFormat.format(closePercentageDefault)}
                value={scenarioState.closePercentage}
                onChange={(e) => {
                  setScenarioState({
                    ...scenarioState,
                    closePercentage: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.containedWidth}>
            <Button
              label="Calculate"
              className="w-full my-3"
              onClick={() => {
                track('Marketing Calculator scenario calculated', {
                  scenario: computedScenarios.length + 1,
                });

                setComputedScenarios((cs) => [...cs, calculateScenario(scenarioState)]);
                setIsAddingScenario(false);
              }}
            />
            <Button
              label="Reset"
              className="w-full p-button-outlined"
              onClick={() => {
                setScenarioState(defaultScenarioInput());
              }}
            />
          </div>
        </Fragment>
      ) : (
        <div className={styles.containedWidth}>
          <Button
            label="Add another scenario"
            className="w-full my-3"
            onClick={() => {
              track('Marketing Calculator scenario added');
              setIsAddingScenario(true);
            }}
          />
        </div>
      )}
    </div>
  );
};
