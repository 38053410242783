import { ComponentType, TextComponent } from '@wirechunk/lib/mixer/types/components.js';
import type Delta from 'quill-delta';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { QuillEditor, QuillEditorProps } from '../../quill-editor.js';
import type { ComponentEditorListener, EditComponentContentProps } from './shared/types.js';

const emptyDelta = JSON.stringify({ ops: [] });

export const EditText: FunctionComponent<EditComponentContentProps<TextComponent>> = ({
  component,
  eventHooks,
}) => {
  const value = useRef<Pick<Delta, 'ops'>>();

  const onChange = useCallback<QuillEditorProps['onChange']>(({ ops }) => {
    value.current = { ops };
  }, []);

  useEffect(() => {
    const updateEditorContents: ComponentEditorListener = (component) => {
      // This type check is just to satisfy TypeScript.
      if (component.type === ComponentType.Text) {
        const delta = value.current;
        if (delta) {
          return {
            action: 'continue',
            component: {
              ...component,
              content: { delta: JSON.stringify(delta) },
            } satisfies TextComponent,
          };
        }
      }
      return { action: 'continue' };
    };

    eventHooks.onBeforeLeave(updateEditorContents);

    return () => {
      eventHooks.offBeforeLeave(updateEditorContents);
    };
  }, [eventHooks]);

  return (
    <QuillEditor
      initialValue={component.content?.delta || emptyDelta}
      onChange={onChange}
      fullHeight
    />
  );
};
