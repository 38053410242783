import { useMutation, useQuery } from '@apollo/client';
import { PublishStatus } from '@wirechunk/lib/api.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import {
  parseComponents,
  parseOptionalComponents,
  parseStyles,
} from '@wirechunk/lib/mixer/utils.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { isString } from 'lodash-es';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../../../contexts/DialogContext/DialogContext.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../contexts/PageContext/PageContext.js';
import { PropsContextProvider } from '../../../../../../contexts/props-context.js';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import { usePagePurposes } from '../../../../../../hooks/use-page-purposes/use-page-purposes.js';
import { useStateRef } from '../../../../../../hooks/use-state-ref.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../hooks/useHasUnsavedChanges.js';
import { useSiteContextSelector } from '../../../../../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { SelectItem } from '../../../../../../types.js';
import { previewPageTemplateBasePath } from '../../../../../../util/page-templates.js';
import { publishStatusOptions } from '../../../../../../util/publishStatus.js';
import { sitePageUrl } from '../../../../../../util/site-page-url.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../Spinner.js';
import {
  PropSpecsOverview,
  PropSpecsOverviewProps,
} from '../../../../../VisualBuilder/prop-specs-overview.js';
import { VisualBuilder, VisualBuilderProps } from '../../../../../VisualBuilder/VisualBuilder.js';
import {
  EditPageTemplateDocument,
  SyncPageTemplateToPagesDocument,
} from './mutations.generated.js';
import styles from './PageTemplate.module.css';
import { PageTemplateDocument } from './queries.generated.js';

type EditView = 'components' | 'propsSetup';

const editViewOptions: Array<SelectItem<EditView>> = [
  { label: 'Page template design', value: 'components' },
  { label: 'Props setup', value: 'propsSetup' },
];

export const PageTemplate: FunctionComponent = () => {
  const { id: platformId } = usePlatformContext();
  const { pageTemplateId } = useParams<{ pageTemplateId: string }>();
  const dialog = useDialog();
  const { toastSuccess, toastError, toastWarn } = useToast();
  const { navigate } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();
  const { data, loading } = useQuery(PageTemplateDocument, {
    onError,
    variables: { id: pageTemplateId as string },
  });
  const { pagePurposes, loading: loadingPagePurposes } = usePagePurposes(platformId, onError);
  const [editPageTemplate, { loading: isSavingEdits }] = useMutation(EditPageTemplateDocument, {
    onError,
    onCompleted: (data) => {
      if (data.editPageTemplate.__typename === 'EditPageTemplateSuccessResult') {
        toastSuccess('Page template saved.');
        resetHasUnsavedChanges();
      } else {
        onError(data.editPageTemplate.message);
      }
    },
  });
  const [syncToPages, { loading: isSyncingToPages }] = useMutation(
    SyncPageTemplateToPagesDocument,
    {
      onError,
      onCompleted: (data) => {
        if (data.syncPageTemplateToPages.__typename === 'SyncPageTemplateToPagesSuccessResult') {
          const { syncedPagesCount, notSyncedPagesCount } = data.syncPageTemplateToPages;
          if (syncedPagesCount === 0 && notSyncedPagesCount === 0) {
            toastSuccess('No pages to sync.');
          } else if (syncedPagesCount > 0 && notSyncedPagesCount === 0) {
            toastSuccess(
              `Page template synced to ${syncedPagesCount} ${pluralize(syncedPagesCount, 'page')}.`,
            );
          } else if (syncedPagesCount > 0 && notSyncedPagesCount > 0) {
            toastSuccess(
              `Page template synced to ${syncedPagesCount} ${pluralize(syncedPagesCount, 'page')}. ${notSyncedPagesCount} ${pluralize(notSyncedPagesCount, 'page')} could not be synced.`,
            );
          } else {
            toastWarn('No pages were synced.');
          }
        } else {
          toastError('Failed to sync page template to pages.');
        }
      },
    },
  );

  const [title, setTitle] = useState(data?.pageTemplate.title || '');
  const [metaTitle, setMetaTitle] = useState(data?.pageTemplate.metaTitle || '');
  const [path, setPath] = useState(data?.pageTemplate.path || '');
  const [status, setStatus] = useState<PublishStatus>(
    data?.pageTemplate.status || PublishStatus.Draft,
  );
  const [pagePurposeId, setPagePurposeId] = useState<string | null>(
    data?.pageTemplate.purpose.id || null,
  );

  const componentsJSON = data?.pageTemplate.components || '[]';
  const bodyStylesJSON = data?.pageTemplate.bodyStyles || '{}';
  const propsSetupComponentsJSON = data?.pageTemplate.propsSetupComponents;

  const [components, setComponents] = useState<Component[]>(() => parseComponents(componentsJSON));
  const [bodyStyles, setBodyStyles] = useState(parseStyles(bodyStylesJSON));
  // A StateRef is used to keep track of the current props setup components so that we can check if they've changed
  // while fetching all input components below.
  const [propsSetupComponentsRef, setPropsSetupComponents] = useStateRef<Component[]>(
    () => parseOptionalComponents(propsSetupComponentsJSON) ?? [],
  );
  const [previewProps, setPreviewProps] = useState<ContextData | null>(
    data?.pageTemplate.previewProps
      ? (JSON.parse(data.pageTemplate.previewProps) as ContextData)
      : null,
  );

  const [previewImageUrl, setPreviewImageUrl] = useState(data?.pageTemplate.previewImageUrl || '');
  const [description, setDescription] = useState(data?.pageTemplate.description || '');

  useEffect(() => {
    setTitle(data?.pageTemplate.title || '');
    setMetaTitle(data?.pageTemplate.metaTitle || '');
    setPath(data?.pageTemplate.path || '');
    setStatus(data?.pageTemplate.status || PublishStatus.Draft);
    setPagePurposeId(data?.pageTemplate.purpose.id || null);
    setComponents(parseComponents(componentsJSON));
    setBodyStyles(parseStyles(bodyStylesJSON));
    setPropsSetupComponents(() => parseOptionalComponents(propsSetupComponentsJSON) ?? []);
    setPreviewProps(
      data?.pageTemplate.previewProps
        ? (JSON.parse(data.pageTemplate.previewProps) as ContextData)
        : null,
    );
    setPreviewImageUrl(data?.pageTemplate.previewImageUrl || '');
    setDescription(data?.pageTemplate.description || '');
  }, [
    data?.pageTemplate.title,
    data?.pageTemplate.metaTitle,
    data?.pageTemplate.path,
    data?.pageTemplate.status,
    data?.pageTemplate.purpose.id,
    componentsJSON,
    bodyStylesJSON,
    propsSetupComponentsJSON,
    data?.pageTemplate.previewProps,
    data?.pageTemplate.previewImageUrl,
    data?.pageTemplate.description,
    setPropsSetupComponents,
  ]);

  const setComponentsWrapped = useCallback<VisualBuilderProps['setComponents']>(
    (components) => {
      setComponents(components);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );
  const setPropsSetupComponentsWrapped = useCallback<VisualBuilderProps['setComponents']>(
    (updater) => {
      setPropsSetupComponents(updater);
      triggerHasUnsavedChanges();
    },
    [setPropsSetupComponents, triggerHasUnsavedChanges],
  );
  const setPreviewPropsWrapped = useCallback<PropSpecsOverviewProps['setProps']>(
    (props) => {
      setPreviewProps(props);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  const [editView, setEditView] = useState<EditView>('components');
  const siteContextSelector = useSiteContextSelector({ onError });
  const { siteContext } = siteContextSelector;

  const pageContext = useMemo<PageContext>(
    () => ({
      title,
      viewMode: ViewMode.Preview,
    }),
    [title],
  );

  const previewInSiteUrl =
    siteContext && pageTemplateId
      ? sitePageUrl(siteContext.domain, `${previewPageTemplateBasePath}/${pageTemplateId}`)
      : null;

  return (
    <PageContainer
      title="Page Template"
      backTo="/sites/page-templates"
      onLinkClick={(to) => {
        if (hasUnsavedChanges) {
          dialog({
            confirm: 'You have unsaved changes. Are you sure you want to leave?',
            props: {
              onAccept: () => {
                navigate(to);
              },
            },
          });
          return false;
        }
        return true;
      }}
    >
      <ErrorMessage />
      {loading && <Spinner />}
      {pageTemplateId && data && (
        <div className="flex gap-3 flex-column md:flex-row">
          <div
            className={`${styles.leftSidebar} flex flex-column gap-3 md:border-right-1 md:pr-3 w-full`}
          >
            <Button
              className="p-button-success w-full"
              label="Save Page Template"
              disabled={!hasUnsavedChanges || isSavingEdits || !pagePurposeId}
              onClick={() => {
                clearMessages();
                if (!pagePurposeId) {
                  toastError('Please select a purpose for the page template.');
                  return;
                }
                void editPageTemplate({
                  variables: {
                    input: {
                      id: pageTemplateId,
                      path,
                      title,
                      metaTitle,
                      status,
                      pagePurposeId,
                      components: { value: JSON.stringify(components) },
                      bodyStyles: JSON.stringify(bodyStyles),
                      previewImageUrl,
                      description,
                      propsSetupComponents: {
                        value: JSON.stringify(propsSetupComponentsRef.current),
                      },
                      previewProps: previewProps
                        ? { value: JSON.stringify(previewProps) }
                        : { clear: true },
                    },
                  },
                });
              }}
            />
            <Button
              className="p-button-outlined w-full"
              label="Sync to pages"
              disabled={hasUnsavedChanges || isSyncingToPages || isSavingEdits}
              onClick={() => {
                clearMessages();
                void syncToPages({
                  variables: {
                    pageTemplateId,
                  },
                });
              }}
            />
            <div className="input-field mb-1">
              <label htmlFor="pageTemplateTitle">Title</label>
              <InputText
                id="pageTemplateTitle"
                className="w-full"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  triggerHasUnsavedChanges();
                }}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pageTemplateMetaTitle">Meta title</label>
              <InputText
                id="pageTemplateMetaTitle"
                className="w-full"
                value={metaTitle}
                onChange={(e) => {
                  setMetaTitle(e.target.value);
                  triggerHasUnsavedChanges();
                }}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pageTemplatePath">URL path</label>
              <InputText
                id="pageTemplatePath"
                className="w-full"
                value={path}
                onChange={(e) => {
                  setPath(e.target.value);
                  triggerHasUnsavedChanges();
                }}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pagePublishStatus">Status</label>
              <Dropdown
                className="w-full"
                options={publishStatusOptions}
                value={status}
                onChange={(e) => {
                  setStatus(e.value as PublishStatus);
                  triggerHasUnsavedChanges();
                }}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pageTemplatePreviewImageUrl">Preview image URL</label>
              <InputText
                id="pageTemplatePreviewImageUrl"
                className="w-full"
                value={previewImageUrl}
                onChange={(e) => {
                  setPreviewImageUrl(e.target.value);
                  triggerHasUnsavedChanges();
                }}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pageTemplateDescription">Description</label>
              <InputTextarea
                id="pageTemplateDescription"
                className="w-full"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  triggerHasUnsavedChanges();
                }}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pageTemplatePurposeId">Purpose</label>
              <Dropdown
                className="w-full"
                loading={loadingPagePurposes}
                inputId="pageTemplatePurposeId"
                value={pagePurposeId}
                options={pagePurposes || []}
                optionLabel="name"
                optionValue="id"
                onChange={({ value }) => {
                  if (isString(value)) {
                    setPagePurposeId(value);
                    triggerHasUnsavedChanges();
                  }
                }}
                showClear={false}
              />
            </div>
            <div className="input-field mb-1">
              <label htmlFor="pageTemplateEditView">Edit design</label>
              <Dropdown
                inputId="pageTemplateEditView"
                className="w-full"
                options={editViewOptions}
                value={editView}
                onChange={(e) => {
                  setEditView(e.value as EditView);
                }}
              />
            </div>
          </div>
          <div className="flex-grow-1">
            {siteContext && propsSetupComponentsRef.current.length > 0 && (
              <PropSpecsOverview
                propsSetupComponentsRef={propsSetupComponentsRef}
                props={previewProps}
                setProps={setPreviewPropsWrapped}
                siteContext={siteContext}
                className="border-1 border-round mb-3"
                containerType="page template"
              />
            )}
            <VisualBuilder
              siteContext={siteContextSelector}
              components={editView === 'components' ? components : propsSetupComponentsRef.current}
              setComponents={
                editView === 'components' ? setComponentsWrapped : setPropsSetupComponentsWrapped
              }
              onPreview={(children) => (
                <PageContextProvider value={pageContext}>
                  <PropsContextProvider value={previewProps ?? {}}>{children}</PropsContextProvider>
                </PageContextProvider>
              )}
              previewInSiteUrl={editView === 'components' ? previewInSiteUrl : null}
            />
          </div>
        </div>
      )}
    </PageContainer>
  );
};
