import { useMutation } from '@apollo/client';
import { noop } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { RefreshSessionDocument } from './mutations.generated.js';

// Refresh every 8 hours.
const refreshIntervalMs = 8 * 60 * 60 * 1000;

// This hook gets you the current date in YYYY-MM-DD format and tries to keep it reasonably real-time.
export const useRefreshSession = (): void => {
  const [refreshSession] = useMutation(RefreshSessionDocument, {
    // We should think about the best thing to do in case of errors. Logging?
    onError: noop,
  });
  const lastRefreshAttemptAt = useRef(Date.now());

  useEffect(() => {
    // We'll refresh the user's session only if the browser tab is visible.
    // This function checks that the refresh interval has passed since the last refresh attempt, which
    // means that the interval on which this function is called should be a bit longer than the actual
    // refresh interval.
    const refresh = () => {
      if (
        document.visibilityState === 'visible' &&
        Date.now() - lastRefreshAttemptAt.current > refreshIntervalMs
      ) {
        lastRefreshAttemptAt.current = Date.now();
        void refreshSession();
      }
    };

    document.addEventListener('visibilitychange', refresh);

    // We make the refresh interval a bit longer than the period between refreshes to make sure that our
    // timeout check in the refresh function passes at every interval tick.
    const int = setInterval(refresh, refreshIntervalMs + 100);

    return () => {
      document.removeEventListener('visibilitychange', refresh);
      clearInterval(int);
    };
  }, [refreshSession]);
};
