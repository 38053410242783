import { useMutation } from '@apollo/client';
import { OrganizationPermission, ScoreMyCallEntryStatus } from '@wirechunk/lib/api.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useRef, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useProductAnalytics } from '../../../contexts/ProductAnalyticsContext/ProductAnalyticsContext.js';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import type { Entry } from '../../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.js';
import { useScoreMyCallEntries } from '../../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.js';
import { Play } from '../../icons/Play.js';
import { ScoreMyCallResultsDialog } from '../../ScoreMyCallResultsDialog/ScoreMyCallResultsDialog.js';
import { EditScoreMyCallEntryStatusDocument } from './mutations.generated.js';

export const ScoreMyCallDetails: FunctionComponent = () => {
  const { user } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const dialog = useDialog();
  // Here we get the platformId from the SiteContext, not from the ViewingPlatformContext, because this component
  // can be rendered in a customer site.
  const { platformId } = useSiteContext();
  const { track } = useProductAnalytics();

  const menu = useRef<Menu>(null);
  const [menuItem, setMenuItem] = useState<Entry | null>(null);

  const { entries, isLoading } = useScoreMyCallEntries({
    platformId,
    onError,
  });
  const [editScoreMyCallEntryStatus] = useMutation(EditScoreMyCallEntryStatusDocument, {
    onError,
  });

  const hasEditOrgPermission = user.organizationPermissions.includes(OrganizationPermission.Edit);

  const isReviewed = (row: Entry) => row.status === ScoreMyCallEntryStatus.Reviewed;

  const onChangeScoreMyCallStatus = (row: Entry, status: ScoreMyCallEntryStatus) => {
    void editScoreMyCallEntryStatus({
      variables: { id: row.id, status },
    });
  };

  const menuLabel = (row: Entry | null) => {
    if (row) {
      return isReviewed(row) ? 'Mark as not reviewed' : 'Mark as reviewed';
    }
    return '';
  };

  return (
    <div>
      <ErrorMessage />
      <DataTable value={entries} loading={isLoading}>
        {hasEditOrgPermission && (
          <Column
            className="table-left-action-column"
            body={(row: Entry) =>
              isReviewed(row) ? (
                <i
                  className={`${PrimeIcons.CHECK_CIRCLE} text-color-success text-lg cursor-pointer`}
                  role="button"
                  onClick={(e) => {
                    if (menu.current) {
                      setMenuItem(row);
                      menu.current.toggle(e);
                    }
                  }}
                />
              ) : (
                <i
                  className={`${PrimeIcons.CIRCLE} text-color-muted text-lg cursor-pointer`}
                  role="button"
                  onClick={(e) => {
                    if (menu.current) {
                      setMenuItem(row);
                      menu.current.toggle(e);
                    }
                  }}
                />
              )
            }
          />
        )}
        {hasEditOrgPermission && (
          <Column
            header="Team member"
            body={(row: Entry) => (
              <div>
                <div>{row.user.displayName}</div>
              </div>
            )}
          />
        )}
        <Column
          header="Prospect’s name"
          body={(row: Entry) =>
            row.prospectName ? (
              <div>{row.prospectName}</div>
            ) : (
              <div className="text-color-muted">Not provided</div>
            )
          }
        />
        <Column
          header="Date"
          body={(row: Entry) => <div>{row.createdAtDate.toLocaleDateString()}</div>}
        />
        <Column
          header="Score"
          body={(row: Entry) => `${row.questionsPassed}/${row.questionsTotal}`}
        />
        <Column
          header=""
          align="right"
          body={(row: Entry) => (
            <div className="flex gap-3 justify-content-end">
              <Button
                className="p-button-outlined"
                onClick={() => {
                  track('Score My Call results viewed');
                  dialog({
                    content: <ScoreMyCallResultsDialog entry={row} />,
                    props: {
                      className: 'dialog-width-xl',
                      header: `${row.user.displayName}: Call ${
                        row.prospectName ? `with ${row.prospectName} ` : ''
                      }on ${row.createdAtDate.toLocaleDateString()}`,
                    },
                  });
                }}
              >
                <div className="flex align-items-center gap-2">
                  <span>Review</span>
                  <Play width="9.6" height="10.8" pathClassName="fill-primary" />
                </div>
              </Button>
            </div>
          )}
        />
      </DataTable>
      <Menu
        ref={menu}
        popup
        className="py-0 overflow-hidden"
        model={[
          {
            label: menuLabel(menuItem),
            disabled: !menuItem,
            command: () => {
              if (menuItem) {
                if (isReviewed(menuItem)) {
                  onChangeScoreMyCallStatus(menuItem, ScoreMyCallEntryStatus.NotReviewed);
                } else {
                  onChangeScoreMyCallStatus(menuItem, ScoreMyCallEntryStatus.Reviewed);
                }
              }
            },
          },
        ]}
      />
    </div>
  );
};
