import { useMutation, useQuery } from '@apollo/client';
import { Permission, PublishStatus } from '@wirechunk/lib/api.js';
import { isPublishStatusArray } from '@wirechunk/lib/publish-status.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { MultiSelect } from 'primereact/multiselect';
import { FunctionComponent, JSX, useState } from 'react';
import { withEditSiteContext } from '../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../../../hooks/useMenuSetup.js';
import { clickableRowClassName } from '../../../../../../util/clickableRowClassName.js';
import { publishStatusOptions } from '../../../../../../util/publishStatus.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { ThreeDotMenuButton } from '../../../../../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { navItems } from '../../nav.js';
import { SiteTabs } from '../site-tabs.js';
import { CreatePage } from './CreatePage.js';
import { DuplicatePageDocument } from './mutations.generated.js';
import { PagesDocument, PagesQuery } from './queries.generated.js';

const AccessControlTag: FunctionComponent<{ text: string }> = ({ text }) => (
  <span className="text-sm w-max min-w-max px-1 surface-ground border-round-sm">{text}</span>
);

const initialStatus = [PublishStatus.Published, PublishStatus.Draft];

type Row = PagesQuery['site']['pages'][number];

export const Pages = withEditSiteContext(({ site }) => {
  const { permissions, handle, navigate } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { toastSuccess } = useToast();

  const [showCreatePage, setShowCreatePage] = useState(false);

  const [duplicatePage, { loading: isDuplicateLoading }] = useMutation(DuplicatePageDocument, {
    onError,
  });
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<Row>();

  const [status, setStatus] = useState<PublishStatus[]>(initialStatus);
  const [title, setTitle] = useState('');

  const { data, loading } = useQuery(PagesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      siteId: site.id,
      status,
      title,
    },
  });

  const canEditSite = permissions.includes(
    site.organizationId ? Permission.EditCustomerSite : Permission.EditPlatformSite,
  );

  return (
    <PageContainer
      title={`Site: ${site.name}`}
      pageMetaTitle={`Site: ${site.name} Pages`}
      nav={navItems(handle)}
    >
      <ErrorMessage />
      <SiteTabs siteId={site.id} activeTab="pages">
        <div className="surface-ground p-3">
          <div className="flex flex-column lg:flex-row align-items-start lg:align-items-center gap-2">
            <div className="flex flex-column lg:flex-row align-items-start lg:align-items-center gap-2 lg:flex-grow-1">
              <MultiSelect
                value={status}
                options={publishStatusOptions}
                onChange={({ value }) => {
                  if (isPublishStatusArray(value)) {
                    setStatus(value);
                  }
                }}
                display="chip"
                placeholder="Status"
              />
              <InputText
                placeholder="Search"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            {canEditSite && (
              <Button
                label="Create page"
                onClick={() => {
                  setShowCreatePage(true);
                }}
                disabled={showCreatePage}
              />
            )}
          </div>
          {showCreatePage && (
            <CreatePage
              siteId={site.id}
              onCreated={(page) => {
                toastSuccess('Page created.');
                navigate(`/sites/${site.id}/pages/${page.id}`);
              }}
              onCancel={() => {
                setShowCreatePage(false);
              }}
            />
          )}
        </div>
        <DataTable
          value={data?.site.pages || []}
          emptyMessage="No pages found"
          rowClassName={clickableRowClassName}
          loading={loading}
          loadingIcon="pi pi-spinner"
          onRowClick={(e) => {
            navigate(`/sites/${site.id}/pages/${(e.data as Row).id}`);
          }}
        >
          <Column field="title" header="Title" />
          <Column field="path" header="URL Path" body={(row: Row) => <span>/{row.path}</span>} />
          <Column field="status" header="Status" />
          <Column header="Purpose" field="purpose.name" />
          <Column
            header="Access control"
            body={(row: Row) => {
              const tags: JSX.Element[] = [];
              if (row.public) {
                tags.push(<AccessControlTag key="public" text="Public" />);
              } else {
                tags.push(<AccessControlTag key="public" text="Sign in" />);
                if (row.productItem) {
                  tags.push(<AccessControlTag key="product-item" text="Product item" />);
                }
                if (row.roles.length) {
                  tags.push(<AccessControlTag key="role" text="Role" />);
                }
              }
              return <div className="flex gap-1">{tags}</div>;
            }}
          />
          <Column
            header=""
            align="right"
            body={(row: Row) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
          />
        </DataTable>
      </SiteTabs>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            disabled: !canEditSite || isDuplicateLoading,
            command: () => {
              if (menuRow) {
                void duplicatePage({
                  variables: { pageId: menuRow.id },
                  onCompleted: (data) => {
                    toastSuccess('Page duplicated.');
                    navigate(`/sites/${site.id}/pages/${data.duplicatePage.id}`);
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
});
