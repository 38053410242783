import { ComponentType } from './types/components.js';

export const componentTypeHumanReadable = (componentType: ComponentType): string => {
  switch (componentType) {
    case ComponentType.BodyContainer:
      return 'Page Body Container';
    case ComponentType.BooleanInput:
      return 'Checkbox with Label';
    case ComponentType.CollapsiblePanel:
      return 'Collapsible Panel';
    case ComponentType.CompensationCalculator:
      return 'Compensation Calculator';
    case ComponentType.CompletableVideoInput:
      return 'Completable Video Input';
    case ComponentType.ConditionalLogic:
      return 'Conditional Logic';
    case ComponentType.CourseSubSection:
      return 'Course Sub-lesson';
    case ComponentType.CurrentYear:
      return 'Current Year';
    case ComponentType.DataInputTable:
      return 'Data Input Table';
    case ComponentType.InputValidationErrorMessage:
      return 'Input Validation Error Message';
    case ComponentType.MarketingCalculator:
      return 'Marketing Calculator';
    case ComponentType.MultipleChoiceInput:
      return 'Multiple Choice Question Input';
    case ComponentType.MultiSelectInput:
      return 'Multi Select Question Input';
    case ComponentType.OrderPage:
      return 'Order Form';
    case ComponentType.OrganizationAgencyAddressLine1:
      return 'Agency Address Line 1';
    case ComponentType.OrganizationAgencyAddressLine2:
      return 'Agency Address Line 2';
    case ComponentType.OrganizationAgencyAddressCity:
      return 'Agency Address City';
    case ComponentType.OrganizationAgencyAddressState:
      return 'Agency Address State';
    case ComponentType.OrganizationAgencyAddressZip:
      return 'Agency Address Zip Code';
    case ComponentType.OrganizationAgencyPrimaryPhone:
      return 'Agency Primary Phone';
    case ComponentType.OrganizationAgentFirstName:
      return 'Agent First Name';
    case ComponentType.OrganizationAgentFullName:
      return 'Agent Full Name';
    case ComponentType.OrganizationAgentLastName:
      return 'Agent Last Name';
    case ComponentType.OrganizationLogo:
      return 'Site Logo (deprecated)';
    case ComponentType.OrganizationName:
      return 'Site Name (deprecated)';
    case ComponentType.OrganizationTermsOfUse:
      return 'Site Terms of Use (deprecated)';
    case ComponentType.OrganizationPrivacyPolicy:
      return 'Site Privacy Policy (deprecated)';
    case ComponentType.PhoneLink:
      return 'Phone Link';
    case ComponentType.PlainText:
      return 'Plain Text';
    case ComponentType.ProvideProps:
      return 'Provide Props';
    case ComponentType.RadioGroupInput:
      return 'Radio Group Input';
    case ComponentType.RadioGroupInputButton:
      return 'Radio Group Input Button';
    case ComponentType.ScoreMyCall:
      return 'Score My Call';
    case ComponentType.SetUpSiteGuard:
      return 'Site Setup Guard';
    case ComponentType.SiteLandingPages:
      return 'Site Landing Pages';
    case ComponentType.SiteLogo:
      return 'Site Logo';
    case ComponentType.SiteName:
      return 'Site Name';
    case ComponentType.SitePrivacyPolicy:
      return 'Site Privacy Policy';
    case ComponentType.SiteTermsOfUse:
      return 'Site Terms of Use';
    case ComponentType.Stage:
      return 'Sequence';
    case ComponentType.StageBody:
      return 'Sequence Stage Body';
    case ComponentType.StageFileInput:
      // TODO: Think more about whether this component is Stage-specific.
      return 'File Upload Input';
    case ComponentType.StageName:
      return 'Sequence Stage Name';
    case ComponentType.SubscriptionTagGuard:
      return 'Product Item Guard';
    case ComponentType.TextInput:
      return 'Text Input with Label';
    case ComponentType.TextareaInput:
      return 'Big Text Input with Label';
    case ComponentType.DateInput:
      return 'Date Input with Label';
    case ComponentType.DropdownInput:
      return 'Dropdown Input with Label';
    case ComponentType.NumberInput:
      return 'Number Input with Label';
    case ComponentType.WidthContainer:
      return 'Width Container';
    default:
      return componentType;
  }
};
