import { useQuery } from '@apollo/client';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { Spinner } from '../Spinner.js';
import { UserPlanProgressDocument } from './queries.generated.js';
import styles from './UserTrainingDetails.module.css';

const percentageFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});

type UserTrainingDetailsProps = {
  userId: string;
  contentPlanIdsToShowProgress: string[];
};

export const UserTrainingDetails: FunctionComponent<UserTrainingDetailsProps> = ({
  userId,
  contentPlanIdsToShowProgress,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(UserPlanProgressDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { userId, contentPlanIds: contentPlanIdsToShowProgress },
  });
  const [expandedItemIds, setExpandedItemIds] = useState<string[]>([]);

  type ItemDetail = {
    id: string;
    name: string;
    started: boolean;
    completed: boolean;
  };

  type TrainingProgressDetails = {
    id: string;
    title: string;
    percentage: string;
    details?: ItemDetail[];
  };

  const trainingProgressDetails = useMemo<TrainingProgressDetails[]>(
    () =>
      data?.user.planProgress.map(
        ({
          planId,
          plan,
          totalStageBlueprints,
          startedStageBlueprintIds,
          completedStageBlueprintIds,
        }): TrainingProgressDetails => ({
          id: planId,
          title: plan.name,
          percentage:
            totalStageBlueprints > 0
              ? percentageFormat.format(completedStageBlueprintIds.length / totalStageBlueprints)
              : '0%',
          details: plan.stageBlueprints.map(({ id, name }) => ({
            id,
            name,
            started: startedStageBlueprintIds.includes(id),
            completed: completedStageBlueprintIds.includes(id),
          })),
        }),
      ) || [],
    [data],
  );

  return (
    <div>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        data && (
          <div className="flex flex-column gap-2">
            <div className="border-1 border-round overflow-hidden">
              <div className="font-medium px-3 py-2 border-bottom-1 surface-ground">
                Training progress
              </div>
              {trainingProgressDetails.map(({ percentage, id, title, details }, index) => (
                <Fragment key={id}>
                  <div
                    className={clsx(
                      'flex align-items-center px-3 py-2',
                      expandedItemIds.includes(id)
                        ? styles.expandedItemRow
                        : index < trainingProgressDetails.length - 1 && 'border-bottom-1',
                    )}
                  >
                    <div className={`${styles.percentage} font-medium`}>{percentage}</div>
                    <div className="flex-grow-1">{title}</div>
                    <Button
                      className={clsx(
                        'p-button-secondary p-button-sm',
                        // We set visibility to hidden rather than rendering conditionally so that that row heights are consistent.
                        !details && 'visibility-hidden',
                      )}
                      icon={
                        expandedItemIds.includes(id)
                          ? PrimeIcons.ANGLE_DOWN
                          : PrimeIcons.ANGLE_RIGHT
                      }
                      label="Details"
                      onClick={() => {
                        setExpandedItemIds((expandedItemIds) => {
                          if (expandedItemIds.includes(id)) {
                            return expandedItemIds.filter(
                              (expandedItemId) => expandedItemId !== id,
                            );
                          }
                          return [...expandedItemIds, id];
                        });
                      }}
                    />
                  </div>
                  {expandedItemIds.includes(id) && (
                    <div
                      className={clsx(
                        'px-3 py-2',
                        index < trainingProgressDetails.length - 1 && 'border-bottom-1',
                      )}
                    >
                      {details?.map(({ id, name, started, completed }, i) => (
                        <div key={id} className={clsx('flex py-2', i > 0 && 'border-top-1')}>
                          <div className={styles.itemDetailsStatus}>
                            {completed ? (
                              <div className="text-color-success-dark font-medium">Completed</div>
                            ) : started ? (
                              <div className="text-color-muted font-medium">Started</div>
                            ) : (
                              <div className="text-color-muted font-medium">Not started</div>
                            )}
                          </div>
                          <div>{name}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};
