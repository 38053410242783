import { pluralize } from '@wirechunk/lib/pluralize.js';
import { Fragment, FunctionComponent } from 'react';
import { Subscription } from './types.js';

type SubscriptionOrganizationCellProps = {
  subscriptionOrganizations: Array<{
    id: string;
  }>;
  managerOrganization: Subscription['managerOrganization'];
};

export const SubscriptionOrganizationCell: FunctionComponent<SubscriptionOrganizationCellProps> = ({
  subscriptionOrganizations,
  managerOrganization,
}) => {
  return (
    <div className="text-sm">
      <div>
        {subscriptionOrganizations.length
          ? `In use by ${subscriptionOrganizations.length} ${pluralize(
              subscriptionOrganizations.length,
              'organization',
            )}`
          : 'Not in use'}
      </div>
      {managerOrganization ? (
        <div>
          Managed by{' '}
          {managerOrganization.primaryUser ? (
            <Fragment>
              {managerOrganization.primaryUser.displayName}
              <div className="text-color-muted">{managerOrganization.primaryUser.email}</div>
            </Fragment>
          ) : managerOrganization.name ? (
            <div>{managerOrganization.name}</div>
          ) : (
            <div className="text-color-muted">No primary organization user</div>
          )}
        </div>
      ) : (
        <div className="text-color-muted">No managing organization</div>
      )}
    </div>
  );
};
