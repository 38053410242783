import { FunctionComponent, PropsWithChildren } from 'react';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { Breadcrumbs } from '../../../../../breadcrumbs.js';
import { NavTabs, NavTabsProps } from '../../../../../nav-tabs.js';

const tabs = ['', 'steps', 'confirmation-action', 'data-formatting', 'notification-email'] as const;

export type FormTemplateTab = (typeof tabs)[number];

export const activeTab = (formId: string, pathname: string): FormTemplateTab => {
  if (pathname.includes(`/${formId}/confirmation-action`)) {
    return 'confirmation-action';
  }
  if (pathname.includes(`/${formId}/data-formatting`)) {
    return 'data-formatting';
  }
  if (pathname.includes(`/${formId}/notification-email`)) {
    return 'notification-email';
  }
  if (pathname.includes(`/${formId}/steps`)) {
    return 'steps';
  }
  return '';
};

const tabHeader = (tab: FormTemplateTab) => {
  switch (tab) {
    case '':
      return 'Design';
    case 'confirmation-action':
      return 'Confirmation action';
    case 'data-formatting':
      return 'Data formatting';
    case 'notification-email':
      return 'Notification email';
    case 'steps':
      return 'Steps';
  }
};

type FormTabsProps = PropsWithChildren<
  Pick<NavTabsProps<FormTemplateTab>, 'panelClassName'> & {
    formTemplateId: string;
    activeTab: FormTemplateTab;
  }
>;

export const FormTemplateTabs: FunctionComponent<FormTabsProps> = ({
  formTemplateId,
  activeTab,
  children,
}) => {
  const { handle } = usePlatformContext();

  return (
    <NavTabs
      rootPath={`/dashboard/${handle}/sites/form-templates/${formTemplateId}`}
      tabs={tabs}
      activeTab={activeTab}
      tabHeader={tabHeader}
    >
      {children}
    </NavTabs>
  );
};

type FormTemplateBreadcrumbsProps = {
  className?: string;
  formTemplate:
    | {
        title: string;
      }
    | undefined;
};

export const FormTemplateBreadcrumbs: FunctionComponent<FormTemplateBreadcrumbsProps> = ({
  className,
  formTemplate,
}) => {
  const { handle } = usePlatformContext();

  return (
    <Breadcrumbs
      className={className}
      items={[
        {
          label: 'Sites',
          path: `/dashboard/${handle}/sites`,
        },
        {
          label: 'Form Templates',
          path: `/dashboard/${handle}/sites/form-templates`,
        },
        ...(formTemplate
          ? [
              {
                label: formTemplate.title,
              },
            ]
          : []),
      ]}
    />
  );
};
