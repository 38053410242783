/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditPlatformOptionsMutationVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  options: Array<Types.OptionInput> | Types.OptionInput;
}>;

export type EditPlatformOptionsMutation = {
  editPlatformOptions: {
    __typename: 'Platform';
    id: string;
    activeCampaignApiToken?: string | null;
    activeCampaignApiUrl?: string | null;
    emailReplyToAddress?: string | null;
    emailSendFromAddress: string;
    mixpanelToken?: string | null;
    sendGridApiKey?: string | null;
    stripePublishableKey?: string | null;
    stripeSecretKey?: string | null;
    stripeWebhookSigningSecret?: string | null;
    webhookHelpTicketCreatedUrl?: string | null;
    webhookOrganizationPeerUserCreatedUrl?: string | null;
    webhookRegisterUserSecret?: string | null;
    webhookSubscriptionActivatedUrl?: string | null;
    zendeskApiToken?: string | null;
    zendeskHelpTicketTags?: Array<string> | null;
    zendeskSubdomain?: string | null;
    zendeskUserAuthJwtKey?: string | null;
    zendeskUserAuthJwtKeyId?: string | null;
    zendeskUsername?: string | null;
    zendeskWebhookSigningSecret?: string | null;
    activeCampaignTagsSyncConfig?: Array<{
      __typename: 'ActiveCampaignTagsSyncConfig';
      id: string;
      activeCampaignTagId: string;
      subscriptionPlanIds: Array<string>;
    }> | null;
  };
};

export type EditPlatformMutationVariables = Types.Exact<{
  input: Types.EditPlatformInput;
}>;

export type EditPlatformMutation = {
  editPlatform:
    | {
        __typename: 'EditPlatformSuccessResult';
        platform: {
          __typename: 'Platform';
          id: string;
          emailReplyToAddress?: string | null;
          emailSendFromAddress: string;
          sendGridApiKey?: string | null;
        };
      }
    | { __typename: 'GenericUserError'; message: string };
};

export const EditPlatformOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPlatformOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'OptionInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPlatformOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCampaignApiToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCampaignApiUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCampaignTagsSyncConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeCampaignTagId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subscriptionPlanIds' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailReplyToAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSendFromAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mixpanelToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendGridApiKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripePublishableKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeSecretKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeWebhookSigningSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookHelpTicketCreatedUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webhookOrganizationPeerUserCreatedUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookRegisterUserSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookSubscriptionActivatedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskApiToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskHelpTicketTags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskSubdomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskUserAuthJwtKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskUserAuthJwtKeyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskWebhookSigningSecret' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPlatformOptionsMutation, EditPlatformOptionsMutationVariables>;
export const EditPlatformDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPlatform' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditPlatformInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPlatform' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditPlatformSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'platform' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailReplyToAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailSendFromAddress' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendGridApiKey' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPlatformMutation, EditPlatformMutationVariables>;
