import { ApolloClient, OnDataOptions, useApolloClient, useSubscription } from '@apollo/client';
import { getUserTimezone } from '@wirechunk/lib/dates.js';
import type { DataValue, ContextData } from '@wirechunk/schemas/context-data/context-data';
import { debounce, DebouncedFunc, isEqual, isError } from 'lodash-es';
import { FunctionComponent, PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import {
  InputData,
  InputDataContextProvider,
  useInputDataContextValue,
} from '../../contexts/InputDataContext.js';
import { StageContext, StageContextProvider } from '../../contexts/StageContext/StageContext.js';
import { useStagesRegistry } from '../../contexts/StagesRegistryContext/stages-registry-context.js';
import { SaveStatus } from '../../contexts/StagesRegistryContext/types.js';
import {
  Stage as CreateStageResult,
  useAutoCreateStage,
} from '../../hooks/use-auto-create-stage/use-auto-create-stage.js';
import type { ErrorHandler } from '../../hooks/useErrorHandler.js';
import { useInterval } from '../../hooks/useInterval.js';
import { parseWebErrorMessage } from '../../util/errors.js';
import { tryParseObject } from '../../util/json.js';
import { RenderComponentsStyled } from '../RenderComponentsStyled.js';
import { Spinner } from '../Spinner.js';
import { EditStageStateDocument } from './mutations.generated.js';
import { StageUpdatedDocument, StageUpdatedSubscription } from './subscriptions.generated.js';

// Note that the fact that the editStageState mutation resolves without an error doesn't mean that a particular
// property has been saved. The mutation is architected to expect the client to keep trying to save each property
// until it's finally saved.
const createSaveFn =
  (
    stageId: string,
    property: string,
    unsavedData: Map<string, DataValue>,
    apolloClient: ApolloClient<object>,
    onError: ErrorHandler['onError'],
    abortControllerByProperty: Map<string, AbortController>,
  ) =>
  async () => {
    const unsavedValue = unsavedData.get(property);
    // Here we check both that unsavedData has the property and that the value is not undefined so that we don't
    // try to save an undefined value.
    if (unsavedValue === undefined) {
      return;
    }
    const pendingAC = abortControllerByProperty.get(property);
    if (pendingAC) {
      pendingAC.abort();
    }
    const newAbortController = new AbortController();
    const fetchOptions = {
      signal: newAbortController.signal,
    };
    abortControllerByProperty.set(property, newAbortController);
    try {
      await apolloClient.mutate({
        mutation: EditStageStateDocument,
        variables: {
          id: stageId,
          userTimeZone: getUserTimezone(),
          property,
          value: JSON.stringify(unsavedValue),
        },
        context: {
          fetchOptions,
        },
        // Setting no-cache here helps us avoid doing some needless work by Apollo Client.
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      onError(isError(error) ? error : parseWebErrorMessage(error));
    } finally {
      if (abortControllerByProperty.get(property) === newAbortController) {
        abortControllerByProperty.delete(property);
      }
    }
  };

type StageBodyProps = PropsWithChildren<{
  stage: CreateStageResult;
  stageBlueprint: StageContext['stageBlueprint'];
  userPlan: StageContext['userPlan'];
  onError: ErrorHandler['onError'];
}>;

// This component relies on stage.id remaining constant for the lifetime of the component.
const StageBody: FunctionComponent<StageBodyProps> = ({
  stage: originalStage,
  stageBlueprint,
  userPlan,
  onError,
  children,
}) => {
  const { id, userId, date } = originalStage;
  const apolloClient = useApolloClient();
  const { setSaveStatus } = useStagesRegistry(id);

  // A map input name to the current, unsaved value of the input, for auto-save purposes.
  const unsavedData = useRef(new Map<string, DataValue>()).current;
  const lastAttemptedSaveByProperty = useRef(new Map<string, number>()).current;

  // This map holds the current AbortController per property for pending requests.
  const abortControllerByProperty = useRef<Map<string, AbortController>>(new Map()).current;

  // Each property to which we make a change has its own debounced save function.
  const debouncedSaveByProperty = useRef<Map<string, DebouncedFunc<() => void>>>(new Map()).current;

  // The maximum number of milliseconds the debounced save function can be delayed.
  const maxWait = 1_000;

  const intervalFn = useCallback(
    () => {
      const now = Date.now();
      for (const [property, lastAttemptedSave] of lastAttemptedSaveByProperty) {
        // We add 1 second to maxWait to account for roughly the P99 latency for saving state.
        // If we decreased the wait time, we'd be cancelling more requests that are in flight and
        // would have succeeded.
        if (unsavedData.has(property) && now - lastAttemptedSave > maxWait + 1_000) {
          debouncedSaveByProperty.get(property)?.();
          lastAttemptedSaveByProperty.set(property, now);
        }
      }
    },
    /* eslint-disable-line react-hooks/exhaustive-deps */ [],
  );

  // This is a backup method to retry saving in case a property save fails.
  // This specific interval of 2 * maxWait is proportional to how frequently we save each property as it
  // is being edited. We don't want this to get too long, because then saving would appear to take a while
  // if an editStageState request fails.
  useInterval(intervalFn, 2 * maxWait);

  const onData = useCallback<(options: OnDataOptions<StageUpdatedSubscription>) => void>(
    ({ data: { data } }) => {
      if (data?.stageUpdated) {
        const parsedState = tryParseObject(data.stageUpdated.state);
        Object.entries(parsedState).forEach(([property, value]) => {
          if (unsavedData.has(property)) {
            const unsavedValue = unsavedData.get(property);
            // Most types of values can be compared with simple equality, but some (like dates) cannot. Try both,
            // starting with the more efficient equality check.
            if (unsavedValue === value || isEqual(unsavedValue, value)) {
              unsavedData.delete(property);
            }
          }
        });
        if (!unsavedData.size) {
          setSaveStatus((status) =>
            status === SaveStatus.NoChanges ? SaveStatus.NoChanges : SaveStatus.Saved,
          );
        }
      }
    },
    [setSaveStatus, unsavedData],
  );

  const { data: stageUpdatedData } = useSubscription(StageUpdatedDocument, {
    onError,
    variables: { id },
    onData,
  });

  const baseInputDataContext = useInputDataContextValue(
    tryParseObject(originalStage.state) as ContextData,
  );

  const inputDataContextValue = useMemo<InputData>(
    () => ({
      ...baseInputDataContext,
      getValue: ({ name }) => {
        if (unsavedData.has(name)) {
          return unsavedData.get(name) ?? null;
        }
        return baseInputDataContext.getValue({ name });
      },
      setValue: (component, value) => {
        baseInputDataContext.setValue(component, value);
        const { name } = component;
        unsavedData.set(name, value);
        if (!debouncedSaveByProperty.has(name)) {
          debouncedSaveByProperty.set(
            name,
            debounce(
              createSaveFn(id, name, unsavedData, apolloClient, onError, abortControllerByProperty),
              600,
              {
                maxWait,
              },
            ),
          );
        }
        setSaveStatus(() => SaveStatus.Saving);
        lastAttemptedSaveByProperty.set(name, Date.now());
        debouncedSaveByProperty.get(name)?.();
      },
    }),
    [
      abortControllerByProperty,
      apolloClient,
      baseInputDataContext,
      debouncedSaveByProperty,
      id,
      lastAttemptedSaveByProperty,
      onError,
      setSaveStatus,
      unsavedData,
    ],
  );

  const { isCompleted, files } = stageUpdatedData?.stageUpdated
    ? stageUpdatedData.stageUpdated
    : originalStage;

  const stageContext = useMemo<StageContext>(
    () => ({
      id,
      userId,
      date,
      stageBlueprint,
      userPlan,
      completed: isCompleted,
      files,
    }),
    [id, userId, date, isCompleted, files, stageBlueprint, userPlan],
  );

  // We pass in a key to force the component to remount when the stage changes. The component assumes
  // that the parent context's stage ID will never change (for performance reasons).
  return (
    <InputDataContextProvider key={id} value={inputDataContextValue}>
      <StageContextProvider value={stageContext}>
        {children || <RenderComponentsStyled components={stageBlueprint.components} />}
      </StageContextProvider>
    </InputDataContextProvider>
  );
};

// Mixer children can be passed in to override what's rendered within the stage context.
export type StageProps = PropsWithChildren<{
  date: string;
  userPlan: StageContext['userPlan'];
  stageBlueprint: StageContext['stageBlueprint'];
  onError: ErrorHandler['onError'];
}>;

export const Stage: FunctionComponent<StageProps> = ({
  date,
  userPlan,
  stageBlueprint,
  onError,
  children,
}) => {
  const { stage, loading } = useAutoCreateStage(date, stageBlueprint.id, onError);

  if (loading) {
    return <Spinner />;
  }

  return stage ? (
    <StageBody
      // We must pass in key to force all state to be reset when the stage being viewed changes.
      key={stage.id}
      stage={stage}
      stageBlueprint={stageBlueprint}
      userPlan={userPlan}
      onError={onError}
    >
      {children}
    </StageBody>
  ) : null;
};
