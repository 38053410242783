import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import type { EditComponent } from '../../editComponentByType.js';

export const NameTab: EditComponent = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editNameTabName">Name</label>
        <InputText
          id="editNameTabName"
          className="w-full"
          keyfilter={/^[a-zA-Z0-9_-]{0,79}$/}
          value={component.name || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              name: e.target.value,
            }));
          }}
        />
      </div>
    </Fragment>
  );
};
