import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import {
  AddressStateTransform,
  OrganizationAgencyAddressStateComponent,
} from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';
import { stateAbbreviationToFull } from '../../util/states.js';

export const OrganizationAgencyAddressState: FunctionComponent<
  OrganizationAgencyAddressStateComponent
> = (props) => {
  const { addressState } = useSiteContext();

  let state = addressState || '';
  if (props.transform === AddressStateTransform.Full) {
    state = stateAbbreviationToFull(state);
  }

  return <span className={componentClassName(props)}>{state}</span>;
};
