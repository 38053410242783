import { Breakpoint } from '@wirechunk/lib/mixer/breakpoints.js';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import {
  BoxComponent,
  ThreeColumnWidths,
  TwoColumnWidths,
} from '@wirechunk/lib/mixer/types/components.js';
import { clsx } from 'clsx';
import type { FunctionComponent, JSX } from 'react';
import { RenderMixerChildren } from '../RenderMixerChildren.js';

export const boxDefaultTag: keyof JSX.IntrinsicElements = 'div';

const columnClassNames = {
  [TwoColumnWidths.OneHalfOneHalf]: ['child-1-w-6', 'child-2-w-6'],
  [TwoColumnWidths.OneQuarterThreeQuarters]: ['child-1-w-3', 'child-2-w-9'],
  [TwoColumnWidths.OneThirdTwoThirds]: ['child-1-w-4', 'child-2-w-8'],
  [TwoColumnWidths.ThreeQuartersOneQuarter]: ['child-1-w-9', 'child-2-w-3'],
  [TwoColumnWidths.TwoThirdsOneThird]: ['child-1-w-8', 'child-2-w-4'],
  [TwoColumnWidths.FullFull]: ['child-1-w-12', 'child-2-w-12'],

  [ThreeColumnWidths.OneHalfOneQuarterOneQuarter]: ['child-1-w-6', 'child-2-w-3', 'child-3-w-3'],
  [ThreeColumnWidths.OneQuarterOneHalfOneQuarter]: ['child-1-w-3', 'child-2-w-6', 'child-3-w-3'],
  [ThreeColumnWidths.OneQuarterOneQuarterOneHalf]: ['child-1-w-3', 'child-2-w-3', 'child-3-w-6'],
  [ThreeColumnWidths.OneThirdOneThirdOneThird]: ['child-1-w-4', 'child-2-w-4', 'child-3-w-4'],
  [ThreeColumnWidths.FullFullFull]: ['child-1-w-12', 'child-2-w-12', 'child-3-w-12'],
};

const applyPrefix =
  (prefix: Breakpoint) =>
  (className: string): string =>
    `${prefix}:${className}`;

export const Box: FunctionComponent<BoxComponent> = (props) => {
  const Tag = (props.tag || boxDefaultTag) as keyof JSX.IntrinsicElements;

  return (
    <Tag
      id={props.elementId || undefined}
      className={clsx(
        componentClassName(props),
        props.columnWidths && columnClassNames[props.columnWidths].join(' '),
        props.smColumnWidths &&
          columnClassNames[props.smColumnWidths].map(applyPrefix('sm')).join(' '),
        props.mdColumnWidths &&
          columnClassNames[props.mdColumnWidths].map(applyPrefix('md')).join(' '),
        props.lgColumnWidths &&
          columnClassNames[props.lgColumnWidths].map(applyPrefix('lg')).join(' '),
      )}
    >
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </Tag>
  );
};
