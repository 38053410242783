import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import { isComponentWithName } from '@wirechunk/lib/mixer/types/categories.js';
import {
  Component,
  ComponentType,
  SubscriptionTagGuardAccessDeniedMode,
  SubscriptionTagGuardSlotKey,
} from '@wirechunk/lib/mixer/types/components.js';
import { boxDefaultTag } from '../../components/mixer/Box.js';
import { plainTextDefaultTag } from '../../components/mixer/PlainText.js';

export const useComponentDescriptiveHeader = (
  component: Component,
  customName: string | null | undefined,
  parent: Component | null,
): string => {
  if (customName) {
    return customName;
  }

  const labels: string[] = [];
  if (component.type === ComponentType.Box) {
    labels.push(component.tag || boxDefaultTag);
  }
  if (component.type === ComponentType.PlainText) {
    labels.push(component.tag || plainTextDefaultTag);
  }
  if (isComponentWithName(component)) {
    labels.push(`name: ${component.name || '<NONE>'}`);
  }
  if (parent?.type === ComponentType.ConditionalLogic) {
    labels.push(`slot: ${component.slot || '<NONE>'}`);
  }
  if (parent?.type === ComponentType.SubscriptionTagGuard) {
    if (
      parent.accessDeniedMode === SubscriptionTagGuardAccessDeniedMode.CustomMessage ||
      !component.slot ||
      component.slot === SubscriptionTagGuardSlotKey.AccessAllowed
    ) {
      labels.push(`slot: ${component.slot || '<NONE>'}`);
    } else {
      // If the parent SubscriptionTagGuard is not configured to display a custom message when access is denied,
      // indicate that the slot is invalid here (it is set to AccessDenied).
      labels.push('slot: <INVALID>');
    }
  }

  const humanReadableType = componentTypeHumanReadable(component.type);
  if (labels.length) {
    return `${humanReadableType} ${labels.map((label) => `(${label})`).join(' ')}`;
  }
  return humanReadableType;
};
