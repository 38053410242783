/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditFormTemplateFormattedDataTemplateMutationVariables = Types.Exact<{
  input: Types.EditFormTemplateFormattedDataTemplateInput;
}>;

export type EditFormTemplateFormattedDataTemplateMutation = {
  editFormTemplateFormattedDataTemplate:
    | {
        __typename: 'EditFormTemplateFormattedDataTemplateSuccessResult';
        formTemplate: {
          __typename: 'FormTemplate';
          id: string;
          formattedDataTemplate?: string | null;
          useFormattedDataTemplate: boolean;
        };
      }
    | { __typename: 'GenericUserError'; message: string };
};

export const EditFormTemplateFormattedDataTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditFormTemplateFormattedDataTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditFormTemplateFormattedDataTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editFormTemplateFormattedDataTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'EditFormTemplateFormattedDataTemplateSuccessResult',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'formattedDataTemplate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'useFormattedDataTemplate' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditFormTemplateFormattedDataTemplateMutation,
  EditFormTemplateFormattedDataTemplateMutationVariables
>;
