/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditFormTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
  status: Types.PublishStatus;
  components: Types.Scalars['String']['input'];
}>;

export type EditFormTemplateMutation = {
  editFormTemplate: {
    __typename: 'FormTemplate';
    id: string;
    status: Types.PublishStatus;
    title: string;
    components: string;
    confirmationAction: Types.ConfirmationAction;
    confirmationMessageComponents?: string | null;
    confirmationRedirectUrl?: string | null;
    confirmationRedirectParameters: Array<{
      __typename: 'FormConfirmationRedirectUrlParameter';
      id: string;
      parameter: string;
      componentName: string;
    }>;
  };
};

export type SyncFormTemplateToFormsMutationVariables = Types.Exact<{
  formTemplateId: Types.Scalars['String']['input'];
}>;

export type SyncFormTemplateToFormsMutation = { syncFormTemplateToForms: boolean };

export const EditFormTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditFormTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PublishStatus' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'components' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editFormTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'components' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'components' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationMessageComponents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationRedirectUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmationRedirectParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditFormTemplateMutation, EditFormTemplateMutationVariables>;
export const SyncFormTemplateToFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncFormTemplateToForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncFormTemplateToForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formTemplateId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SyncFormTemplateToFormsMutation,
  SyncFormTemplateToFormsMutationVariables
>;
