import type { ApolloClient } from '@apollo/client';
import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.js';
import { useEffect, useRef, useState } from 'react';
import { extractValidInputComponents } from '../util/mixer/util.js';

type ValidInputComponents = {
  inputComponents: Map<string, ValidInputComponent>;
  loading: boolean;
};

export const useValidInputComponents = (
  components: Component[],
  apolloClient: ApolloClient<object>,
): ValidInputComponents => {
  const [inputComponents, setInputComponents] = useState<Map<string, ValidInputComponent>>(
    new Map(),
  );
  const [loading, setLoading] = useState(true);
  const abortController = useRef<AbortController | null>(null);

  useEffect(() => {
    void (async () => {
      try {
        setLoading(true);
        abortController.current?.abort();
        const newAbortController = new AbortController();
        abortController.current = newAbortController;
        setInputComponents(
          await extractValidInputComponents(components, apolloClient, newAbortController),
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [apolloClient, components]);

  return { inputComponents, loading };
};
