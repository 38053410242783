import { NumberInputComponent, NumberInputFormat } from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

const defaultFormat = NumberInputFormat.Number;

const formatOptions: Array<{ label: string; value: NumberInputFormat | typeof defaultFormat }> = [
  { label: 'Any number', value: defaultFormat },
  { label: 'Integer', value: NumberInputFormat.Integer },
  { label: 'Currency', value: NumberInputFormat.Currency },
];

export const EditNumberInput: FunctionComponent<
  EditComponentContentProps<NumberInputComponent>
> = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editNumberInputFormat">Format</label>
        <Dropdown
          inputId="editNumberInputFormat"
          className="w-full"
          options={formatOptions}
          value={component.format || defaultFormat}
          onChange={({ value }) => {
            if (Object.values(NumberInputFormat).includes(value as never)) {
              setComponent((c) => ({ ...c, format: value as NumberInputFormat }));
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="editNumberInputPlaceholder">Placeholder</label>
        <InputText
          id="editNumberInputPlaceholder"
          className="w-full"
          value={component.placeholder || ''}
          onChange={(e) => {
            setComponent((c) => ({ ...c, placeholder: e.target.value }));
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="editNumberInputMaxDecimalDigits">Maximum number of decimal digits</label>
        <InputNumber
          inputId="editNumberInputMaxDecimalDigits"
          className="w-full"
          value={component.maxDecimalDigits ?? null}
          onChange={(e) => {
            setComponent((c) => ({ ...c, maxDecimalDigits: e.value }));
          }}
        />
      </div>
    </Fragment>
  );
};
