import { evaluateBooleanExpression } from '@wirechunk/lib/expression-builder/evaluator.js';
import { conditionalStylesAttributeKey } from '@wirechunk/lib/mixer/styles.js';
import type { ConditionalStyles } from '@wirechunk/lib/mixer/types/components.js';
import { useMemo } from 'react';
import type { MixerContextData } from '../util/mixer/context-data.js';

export const useConditionalStylingAttributes = (
  component: ConditionalStyles,
  contextData: MixerContextData,
): Record<string, string> =>
  useMemo(() => {
    const attrs: Record<string, string> = {};
    if (component.conditionalStyles) {
      for (const { key, condition } of component.conditionalStyles) {
        if (evaluateBooleanExpression(condition, contextData)) {
          attrs[conditionalStylesAttributeKey(key)] = '';
        }
      }
    }
    return attrs;
  }, [component, contextData]);
