/// <reference path="../../window.d.ts" />
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Loader } from '../../util/googleMapsApiLoader.js';
import styles from './SelectGooglePlace.module.css';

type PlaceResultWithId = Omit<google.maps.places.PlaceResult, 'place_id'> & {
  place_id: NonNullable<google.maps.places.PlaceResult['place_id']>;
};

const isPlaceResultWithId = (place: google.maps.places.PlaceResult): place is PlaceResultWithId =>
  !!place.place_id;

const loader = new Loader({
  apiKey: window.wirechunk.gMapsKey,
  version: 'quarterly',
  libraries: ['places'],
});

const mapQueryFields = ['name', 'place_id', 'formatted_address', 'geometry', 'types'];

type MapSetup = {
  map: google.maps.Map;
  marker: google.maps.Marker;
  infoWindow: google.maps.InfoWindow;
};

export type SelectGooglePlaceProps = {
  placeId: string | null | undefined;
  setPlace: (place: PlaceResultWithId) => void;
  // If initialFindPlaceAddress is provided when this component is first rendered and placeId is not provided, it will
  // be used to search for a place that will be automatically selected.
  initialFindPlaceAddress?: string | null;
  // Optionally specify a Place type to restrict which types of Places can be auto-selected.
  // See https://developers.google.com/maps/documentation/places/web-service/supported_types for a list of types.
  initialFindPlaceValidTypes?: string | string[] | null;
  searchFieldContainerClassName?: string;
  mapClassName?: string;
};

export const SelectGooglePlace: FunctionComponent<SelectGooglePlaceProps> = ({
  initialFindPlaceAddress,
  initialFindPlaceValidTypes,
  mapClassName,
  placeId,
  searchFieldContainerClassName,
  setPlace,
}) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const mapWrapper = useRef<HTMLDivElement>(null);
  const infoWindowContent = useRef<HTMLDivElement>(null);
  const mapSetup = useRef<MapSetup | null>(null);
  const [suggestedPlace, setSuggestedPlace] = useState<PlaceResultWithId | null>(null);
  const [infoWindowPlaceName, setInfoWindowPlaceName] = useState<string | null>(null);
  const [infoWindowPlaceAddress, setInfoWindowPlaceAddress] = useState<string | null>(null);

  useEffect(() => {
    const handlePlaceResult = (
      place: google.maps.places.PlaceResult,
      previewOnly: boolean = false,
    ) => {
      setSuggestedPlace(null);
      if (!mapSetup.current || !isPlaceResultWithId(place)) {
        return;
      }
      const { map, marker, infoWindow } = mapSetup.current;

      if (place.geometry) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else if (place.geometry.location) {
          map.setCenter(place.geometry.location);
          map.setZoom(15);
        }

        setInfoWindowPlaceName(place.name || '');
        setInfoWindowPlaceAddress(place.formatted_address || '');

        // Set the position of the marker using the place ID and location.
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        infoWindow.open(
          {
            shouldFocus: false,
          },
          marker,
        );
      }

      if (!previewOnly) {
        setPlace(place);
      }
    };

    if (!mapSetup.current) {
      void (async () => {
        const google = await loader.load();
        // Another async invocation of this hook has already set up the map.
        if (
          !mapSetup.current &&
          mapWrapper.current &&
          searchInput.current &&
          infoWindowContent.current
        ) {
          const map = new google.maps.Map(mapWrapper.current, {
            // Dallas, TX.
            center: { lat: 32.7742, lng: -96.8179 },
            // Zoom out far enough that you can see all of the Continental US.
            zoom: 3,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent.current,
          });

          const marker = new google.maps.Marker({ map });

          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });

          mapSetup.current = { map, marker, infoWindow };

          const autocomplete = new google.maps.places.Autocomplete(searchInput.current, {
            fields: mapQueryFields,
          });

          autocomplete.bindTo('bounds', map);

          autocomplete.addListener('place_changed', () => {
            infoWindow.close();
            handlePlaceResult(autocomplete.getPlace());
          });

          const service = new google.maps.places.PlacesService(map);

          if (placeId) {
            service.getDetails(
              {
                placeId,
                fields: mapQueryFields,
              },
              (place, status) => {
                if (status == google.maps.places.PlacesServiceStatus.OK && place) {
                  handlePlaceResult(place);
                }
              },
            );
          } else if (initialFindPlaceAddress && initialFindPlaceAddress.length >= 5) {
            // Do not pre-fill the search input field with the address in case the user needs to search for their
            // business page, since Google may suggest a generic address.
            service.findPlaceFromQuery(
              {
                query: initialFindPlaceAddress,
                fields: mapQueryFields,
              },
              (places, status) => {
                if (
                  places?.length &&
                  mapSetup.current &&
                  status == google.maps.places.PlacesServiceStatus.OK
                ) {
                  const [place] = places as [google.maps.places.PlaceResult];
                  if (place.geometry?.location) {
                    map.setCenter(place.geometry.location);
                    map.setZoom(place.place_id ? 15 : 10);
                    if (isPlaceResultWithId(place)) {
                      if (initialFindPlaceValidTypes?.length) {
                        if (Array.isArray(initialFindPlaceValidTypes)) {
                          if (
                            initialFindPlaceValidTypes.some((type) => place.types?.includes(type))
                          ) {
                            handlePlaceResult(place);
                            setSuggestedPlace(place);
                          }
                        } else if (place.types?.includes(initialFindPlaceValidTypes)) {
                          handlePlaceResult(place);
                          setSuggestedPlace(place);
                        }
                      } else {
                        handlePlaceResult(place, true);
                        setSuggestedPlace(place);
                      }
                    }
                  }
                }
              },
            );
          }
        }
      })();
    }
  }, [placeId, setPlace, initialFindPlaceAddress, initialFindPlaceValidTypes]);

  return (
    <Fragment>
      <div className={clsx('input-field mb-3', searchFieldContainerClassName)}>
        <label htmlFor="selectGooglePlaceSearch">Search for your business&hellip;</label>
        <InputText id="selectGooglePlaceSearch" ref={searchInput} className="w-full" />
      </div>
      <div ref={mapWrapper} className={clsx(styles.map, mapClassName)} />
      {createPortal(
        // Note that this extra div wrapper is required so that un-mounting does not result in an error, since the
        // info window element gets moved in the DOM.
        <div>
          <div ref={infoWindowContent} className={styles.infoWindowContent}>
            {infoWindowPlaceName && <div className={styles.title}>{infoWindowPlaceName}</div>}
            {infoWindowPlaceAddress && <div>{infoWindowPlaceAddress}</div>}
            {suggestedPlace && (
              <div className="p-1 mt-2 text-center">
                <Button
                  label="Select this place"
                  className="p-button-sm"
                  onClick={() => {
                    if (isPlaceResultWithId(suggestedPlace)) {
                      setPlace(suggestedPlace);
                      setSuggestedPlace(null);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>,
        document.body,
      )}
    </Fragment>
  );
};
