import {
  AddressStateTransform,
  OrganizationAgencyAddressStateComponent,
} from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

const transformOptions: Array<{ label: string; value: AddressStateTransform }> = [
  { label: 'None (default)', value: AddressStateTransform.None },
  { label: 'Full', value: AddressStateTransform.Full },
];

export const EditOrganizationAgencyAddressState: FunctionComponent<
  EditComponentContentProps<OrganizationAgencyAddressStateComponent>
> = ({ component, setComponent }) => {
  const transformValue: AddressStateTransform = component.transform || AddressStateTransform.None;

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editOrgStateTransform">Transform</label>
        <Dropdown
          inputId="editOrgStateTransform"
          value={transformValue}
          options={transformOptions}
          onChange={(e) => {
            setComponent((c) => ({ ...c, transform: e.value as AddressStateTransform }));
          }}
        />
      </div>
    </Fragment>
  );
};
