/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditProductMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  displayName?: Types.InputMaybe<Types.OptionalStringUpdate>;
  description?: Types.InputMaybe<Types.OptionalStringUpdate>;
  items?: Types.InputMaybe<Types.StringArrayUpdate>;
  endAt?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  durationDays?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  price: Types.Scalars['String']['input'];
  invoiceFrequency?: Types.InputMaybe<Types.InvoiceFrequency>;
  promoCodes: Array<Types.PromoCodeInput> | Types.PromoCodeInput;
  stripePriceId?: Types.InputMaybe<Types.OptionalStringUpdate>;
  alternativeStripePriceIds:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
}>;

export type EditProductMutation = {
  editProduct:
    | {
        __typename: 'EditProductSuccessResult';
        product: {
          __typename: 'SubscriptionPlan';
          id: string;
          name: string;
          displayName?: string | null;
          description?: string | null;
          items: Array<string>;
          endAt?: string | null;
          durationDays?: number | null;
          price: string;
          invoiceFrequency?: Types.InvoiceFrequency | null;
          stripePriceId?: string | null;
          alternativeStripePriceIds: Array<string>;
          promoCodes: Array<{
            __typename: 'PromoCode';
            id: string;
            code: string;
            successMessage?: { __typename: 'Delta'; delta: string } | null;
          }>;
        };
      }
    | { __typename: 'GenericUserError'; message: string };
};

export const EditProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OptionalStringUpdate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OptionalStringUpdate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'items' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'StringArrayUpdate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'durationDays' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceFrequency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvoiceFrequency' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCodes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stripePriceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OptionalStringUpdate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'alternativeStripePriceIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'items' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'durationDays' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'durationDays' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'price' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'price' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceFrequency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceFrequency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoCodes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCodes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripePriceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stripePriceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alternativeStripePriceIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'alternativeStripePriceIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditProductSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'durationDays' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'invoiceFrequency' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'promoCodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'successMessage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'delta' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'stripePriceId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'alternativeStripePriceIds' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditProductMutation, EditProductMutationVariables>;
