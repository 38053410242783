import { noop } from 'lodash-es';
import { Button } from 'primereact/button';
import {
  createContext,
  Fragment,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ErrorHandler } from '../hooks/useErrorHandler.js';
import { offlineErrorHandler, parseWebErrorMessage } from '../util/errors.js';
import { useToast } from './ToastContext.js';

export type ErrorCollectorContext = Pick<ErrorHandler, 'onError'>;

const context = createContext<ErrorCollectorContext>({
  onError: noop,
});

export const ErrorCollectorContextProviderDirect = context.Provider;

export const ErrorCollectorContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { toastError } = useToast();
  const [messages, setMessages] = useState<string[]>([]);

  const onError = useCallback<ErrorCollectorContext['onError']>(
    (error) => {
      // If we get a non-empty value from offlineErrorHandler, then we still have an error to handle.
      const messageNotOfflineError = offlineErrorHandler(error, toastError);
      if (messageNotOfflineError) {
        const message = parseWebErrorMessage(messageNotOfflineError);
        setMessages((messages) => (messages.includes(message) ? messages : [...messages, message]));
      }
    },
    [toastError],
  );

  return (
    <Fragment>
      {messages.length > 0 && (
        <div className="py-2 px-3 bg-red-500 text-white flex flex-column gap-1 opacity-90 absolute top-0 w-full z-5">
          {messages.map((message) => (
            <div key={message} className="flex justify-content-between align-items-center">
              <div>{message}</div>
              <Button
                className="background-none border-none border-circle p-1"
                onClick={() => {
                  setMessages((messages) => messages.filter((m) => m !== message));
                }}
              >
                <span className="material-symbols-outlined text-lg">close</span>
              </Button>
            </div>
          ))}
        </div>
      )}
      <context.Provider value={{ onError }}>{children}</context.Provider>
    </Fragment>
  );
};

export const useErrorCollector = (): ErrorCollectorContext => useContext(context);
