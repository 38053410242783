import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { ComponentNamesDocument, ComponentNamesQuery } from './queries.generated.js';

export type ComponentName = ComponentNamesQuery['components']['components'][number];

type ComponentNames = {
  componentNames: ComponentName[] | undefined;
  loading: boolean;
};

/**
 * A hook for fetching Components with just an ID and name.
 */
export const useComponentNames = ({
  platformId,
  archived,
  onError,
  fetchPolicy,
}: {
  platformId: string;
  archived?: boolean | null;
  onError: ErrorHandler['onError'];
  fetchPolicy?: WatchQueryFetchPolicy;
}): ComponentNames => {
  const { data, loading } = useQuery(ComponentNamesDocument, {
    onError,
    variables: { platformId, archived },
    fetchPolicy,
  });

  return {
    componentNames: data?.components.components,
    loading,
  };
};
