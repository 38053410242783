import { useMutation } from '@apollo/client';
import { PagePurpose, Permission } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { PagePurposesDocument } from '../../../../../hooks/use-page-purposes/queries.generated.js';
import { usePagePurposes } from '../../../../../hooks/use-page-purposes/use-page-purposes.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useTableRowExpansion } from '../../../../../hooks/useTableRowExpansion.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { navItems } from '../nav.js';
import { CreatePagePurposeDocument, EditPagePurposeDocument } from './mutations.generated.js';

const removeNonLetters = (str: string) => str.replace(/[^a-zA-Z]/g, '');

type EditPagePurposeProps = {
  initialPagePurpose?: PagePurpose;
  onSave: (pagePurpose: { name: string }) => void;
  saving: boolean;
};

const EditPagePurpose: FunctionComponent<EditPagePurposeProps> = ({
  initialPagePurpose,
  onSave,
  saving,
}) => {
  const [name, setName] = useState(initialPagePurpose?.name || '');

  return (
    <div className="flex gap-2 align-items-end">
      <div className="input-field mb-0">
        <label htmlFor={`pagePurposeName${initialPagePurpose?.id || 'new'}`}>Name</label>
        <InputText
          id={`pagePurposeName${initialPagePurpose?.id || 'new'}`}
          value={name}
          onChange={(e) => {
            setName(removeNonLetters(e.target.value));
          }}
        />
      </div>
      <Button
        label={initialPagePurpose ? 'Save' : 'Create'}
        disabled={!name || saving}
        onClick={() => {
          onSave({ name });
        }}
      />
    </div>
  );
};

const RowExpansionEditPagePurpose: FunctionComponent<{ pagePurpose: PagePurpose }> = ({
  pagePurpose,
}) => {
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const [editPagePurpose, { loading: saving }] = useMutation(EditPagePurposeDocument, {
    onError,
    onCompleted: (data) => {
      if (data.editPagePurpose.__typename === 'EditPagePurposeSuccessResult') {
        toastSuccess('Page purpose saved.');
      } else {
        onError(data.editPagePurpose.message);
      }
    },
  });

  return (
    <div>
      <ErrorMessage />
      <EditPagePurpose
        initialPagePurpose={pagePurpose}
        onSave={({ name }) => {
          void editPagePurpose({ variables: { id: pagePurpose.id, name } });
        }}
        saving={saving}
      />
    </div>
  );
};

const rowExpansionTemplate = (pagePurpose: PagePurpose) => (
  <RowExpansionEditPagePurpose pagePurpose={pagePurpose} />
);

export const PagePurposes: FunctionComponent = () => {
  const { toastSuccess } = useToast();
  const { id: platformId, handle, permissions } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { pagePurposes, loading } = usePagePurposes(platformId, onError);
  const { expandedRows, onRowToggle } = useTableRowExpansion();
  const [showCreate, setShowCreate] = useState(false);
  const [createPagePurpose, { loading: creating }] = useMutation(CreatePagePurposeDocument, {
    onError,
    onCompleted: (data) => {
      if (data.createPagePurpose.__typename === 'CreatePagePurposeSuccessResult') {
        toastSuccess('Page purpose created.');
        setShowCreate(false);
      } else {
        onError(data.createPagePurpose.message);
      }
    },
    refetchQueries: [
      {
        query: PagePurposesDocument,
        variables: { platformId },
      },
    ],
  });

  const canEdit = permissions.includes(Permission.Edit);

  return (
    <PageContainer title="Page Purposes" nav={navItems(handle)}>
      <ErrorMessage />
      {showCreate ? (
        <div className="border-1 border-round p-3 mb-3">
          <EditPagePurpose
            onSave={({ name }) => {
              void createPagePurpose({
                variables: {
                  platformId,
                  name,
                },
              });
            }}
            saving={creating}
          />
        </div>
      ) : (
        canEdit && (
          <Button
            label="Create page purpose"
            className="mb-3"
            onClick={() => {
              setShowCreate(true);
            }}
            disabled={showCreate}
          />
        )
      )}
      <DataTable
        dataKey="id"
        loading={loading}
        value={pagePurposes || []}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander className="table-left-action-column" />
        <Column field="name" header="Name"></Column>
      </DataTable>
    </PageContainer>
  );
};
