/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type OrganizationSiteSettingsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['String']['input'];
}>;

export type OrganizationSiteSettingsQuery = {
  organization: {
    __typename: 'Organization';
    id: string;
    site?: {
      __typename: 'Site';
      id: string;
      name: string;
      domain: string;
      agentFirstName?: string | null;
      agentLastName?: string | null;
      agentPhotoUrl?: string | null;
      agencyPrimaryPhone?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressCity?: string | null;
      addressState?: string | null;
      addressZip?: string | null;
      googlePlaceId?: string | null;
      googleTagManagerId?: string | null;
      headJavaScriptCode?: string | null;
      logoUrl?: string | null;
      metaPixelId?: string | null;
      useCustomPrivacyPolicy: boolean;
      useCustomTermsOfUse: boolean;
      privacyPolicy?: { __typename: 'Delta'; delta: string } | null;
      termsOfUse?: { __typename: 'Delta'; delta: string } | null;
    } | null;
  };
};

export const OrganizationSiteSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrganizationSiteSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'site' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentPhotoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agencyPrimaryPhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressState' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressZip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'googleTagManagerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headJavaScriptCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metaPixelId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'privacyPolicy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delta' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'useCustomPrivacyPolicy' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termsOfUse' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delta' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'useCustomTermsOfUse' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationSiteSettingsQuery, OrganizationSiteSettingsQueryVariables>;
