import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { HTMLComponent } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';

export const HTML: FunctionComponent<HTMLComponent> = (props) => (
  <div
    className={componentClassName(props)}
    dangerouslySetInnerHTML={{ __html: props.html || '' }}
  />
);
