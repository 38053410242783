import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { UserDetailsQuery } from './queries.generated.js';
import { SetPlanStageBlueprint } from './SetPlanStageBlueprint.js';

type UserPlanPositionsProps = {
  user: UserDetailsQuery['user'];
  canEdit: boolean;
};

export const UserPlanPositions: FunctionComponent<UserPlanPositionsProps> = ({ user, canEdit }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [editingUserPlanIds, setEditingUserPlanIds] = useState<string[]>([]);

  return (
    <Fragment>
      <ErrorMessage />
      <div className="border-1 border-round overflow-hidden">
        <div className="surface-ground px-3 py-2 font-medium">Content plan positions</div>
        {user.plans.length > 0 ? (
          user.plans.map((userPlan) => (
            <div key={userPlan.id} className="border-top-1 px-3 py-2">
              <div className="flex align-items-center justify-content-between">
                <div>
                  <div>
                    Plan: <span className="font-medium">{userPlan.plan.name}</span>
                  </div>
                  {editingUserPlanIds.includes(userPlan.id) ? (
                    <SetPlanStageBlueprint
                      userPlan={userPlan}
                      onDone={() => {
                        setEditingUserPlanIds(
                          editingUserPlanIds.filter((id) => id !== userPlan.id),
                        );
                      }}
                      onError={onError}
                    />
                  ) : (
                    <div>
                      Stage: <span className="font-medium">{userPlan.stageBlueprint.name}</span>
                    </div>
                  )}
                </div>
                {canEdit && (
                  <Button
                    label="Edit"
                    // Disable, don't hide, so that the parent's height doesn't change.
                    disabled={editingUserPlanIds.includes(userPlan.id)}
                    className="p-button-secondary p-button-sm"
                    onClick={() => {
                      setEditingUserPlanIds([...editingUserPlanIds, userPlan.id]);
                    }}
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-color-muted px-3 py-2 border-top-1">
            No content plans for this user
          </div>
        )}
      </div>
    </Fragment>
  );
};
