/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type PlatformQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PlatformQuery = {
  platform: {
    __typename: 'Platform';
    id: string;
    name: string;
    activeCampaignApiToken?: string | null;
    activeCampaignApiUrl?: string | null;
    emailReplyToAddress?: string | null;
    emailSendFromAddress: string;
    mixpanelToken?: string | null;
    sendGridApiKey?: string | null;
    stripePublishableKey?: string | null;
    stripeSecretKey?: string | null;
    stripeWebhookSigningSecret?: string | null;
    webhookHelpTicketCreatedUrl?: string | null;
    webhookOrganizationPeerUserCreatedUrl?: string | null;
    webhookRegisterUserSecret?: string | null;
    webhookSubscriptionActivatedUrl?: string | null;
    zendeskApiToken?: string | null;
    zendeskHelpTicketTags?: Array<string> | null;
    zendeskSubdomain?: string | null;
    zendeskUserAuthJwtKey?: string | null;
    zendeskUserAuthJwtKeyId?: string | null;
    zendeskUsername?: string | null;
    zendeskWebhookSigningSecret?: string | null;
    activeCampaignTagsSyncConfig?: Array<{
      __typename: 'ActiveCampaignTagsSyncConfig';
      id: string;
      activeCampaignTagId: string;
      subscriptionPlanIds: Array<string>;
    }> | null;
  };
};

export type PlatformActiveCampaignApiTagsQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PlatformActiveCampaignApiTagsQuery = {
  platform: {
    __typename: 'Platform';
    id: string;
    activeCampaignApi?: {
      __typename: 'ActiveCampaignApi';
      tags: {
        __typename: 'ActiveCampaignApiTagsList';
        totalCount: number;
        tags: Array<{
          __typename: 'ActiveCampaignTag';
          id: string;
          tag: string;
          subscriberCount: number;
        }>;
      };
    } | null;
  };
};

export type PlatformActiveCampaignApiTagQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  tagId: Types.Scalars['String']['input'];
}>;

export type PlatformActiveCampaignApiTagQuery = {
  platform: {
    __typename: 'Platform';
    id: string;
    activeCampaignApi?: {
      __typename: 'ActiveCampaignApi';
      tag: { __typename: 'ActiveCampaignTag'; id: string; tag: string; subscriberCount: number };
    } | null;
  };
};

export const PlatformDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Platform' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCampaignApiToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCampaignApiUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCampaignTagsSyncConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeCampaignTagId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subscriptionPlanIds' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailReplyToAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSendFromAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mixpanelToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendGridApiKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripePublishableKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeSecretKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeWebhookSigningSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookHelpTicketCreatedUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webhookOrganizationPeerUserCreatedUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookRegisterUserSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookSubscriptionActivatedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskApiToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskHelpTicketTags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskSubdomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskUserAuthJwtKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskUserAuthJwtKeyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zendeskWebhookSigningSecret' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlatformQuery, PlatformQueryVariables>;
export const PlatformActiveCampaignApiTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlatformActiveCampaignApiTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCampaignApi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'search' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscriberCount' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PlatformActiveCampaignApiTagsQuery,
  PlatformActiveCampaignApiTagsQueryVariables
>;
export const PlatformActiveCampaignApiTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlatformActiveCampaignApiTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tagId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCampaignApi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'tagId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscriberCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PlatformActiveCampaignApiTagQuery,
  PlatformActiveCampaignApiTagQueryVariables
>;
