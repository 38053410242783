import { useLazyQuery } from '@apollo/client';
import { ActiveCampaignTagsSyncConfig } from '@wirechunk/lib/api.js';
import { isStringArray } from '@wirechunk/lib/arrays.js';
import { PlatformOptionKey } from '@wirechunk/lib/platform-options.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { clsx } from 'clsx';
import { intersection, isEqual, isString } from 'lodash-es';
import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Password } from 'primereact/password';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  SubscriptionPlanNames,
  useSubscriptionPlanNames,
} from '../../../../hooks/use-subscription-plan-names/use-subscription-plan-names.js';
import { ErrorHandler, useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { OptionsSection } from '../../../OptionsSection.js';
import { Spinner } from '../../../Spinner.js';
import {
  PlatformActiveCampaignApiTagDocument,
  PlatformActiveCampaignApiTagsDocument,
  PlatformActiveCampaignApiTagsQuery,
} from './queries.generated.js';
import type { PlatformSettingsOptionsSectionProps } from './types.js';

type ApiTag = NonNullable<
  PlatformActiveCampaignApiTagsQuery['platform']['activeCampaignApi']
>['tags']['tags'][number];

type ActiveCampaignTagSelectorProps = {
  platformId: string;
  tagId: string | null | undefined;
  setTagId: (tagId: string) => void;
  invalid: boolean;
  onError: ErrorHandler['onError'];
};

const ActiveCampaignTagSelector: FunctionComponent<ActiveCampaignTagSelectorProps> = ({
  platformId,
  tagId,
  setTagId,
  invalid,
  onError,
}) => {
  const [getTag, { loading, data: selectedTagData }] = useLazyQuery(
    PlatformActiveCampaignApiTagDocument,
    {
      onError,
    },
  );
  const [getActiveCampaignTags, { loading: loadingTags, data: tagsData }] = useLazyQuery(
    PlatformActiveCampaignApiTagsDocument,
    {
      onError,
    },
  );
  useEffect(() => {
    if (tagId) {
      void getTag({ variables: { platformId, tagId } });
    }
  }, [getTag, platformId, tagId]);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    void getActiveCampaignTags({ variables: { platformId, search: filter } });
  }, [getActiveCampaignTags, platformId, filter]);

  const selectedTag = selectedTagData?.platform.activeCampaignApi?.tag;

  const options = tagsData?.platform.activeCampaignApi?.tags.tags.slice() || [];
  if (selectedTag && !options.some((opt) => opt.id === selectedTag.id)) {
    options.push(selectedTag);
  }

  return (
    <Dropdown
      className={clsx('w-28rem max-w-full', invalid && 'p-invalid')}
      loading={loading || loadingTags}
      filter
      filterPlaceholder="Search tags"
      onFilter={(e) => {
        setFilter(e.filter);
      }}
      pt={{
        filterInput: {
          value: filter,
        },
      }}
      value={tagId || null}
      options={options}
      optionLabel="tag"
      optionValue="id"
      valueTemplate={(opt: ActiveCampaignTagsSyncConfig | null) => (
        <span>
          ActiveCampaign tag:{' '}
          <span className="font-medium">
            {opt
              ? selectedTag && opt.id === selectedTag.id
                ? selectedTag.tag
                : 'LOADING'
              : 'NONE'}
          </span>
        </span>
      )}
      itemTemplate={(opt: ApiTag) => (
        <span>
          {opt.tag}{' '}
          <span className={opt.id === tagId ? 'text-white' : 'text-color-muted'}>
            ({opt.subscriberCount} {pluralize(opt.subscriberCount, 'subscriber')})
          </span>
        </span>
      )}
      placeholder="Select an ActiveCampaign tag"
      onChange={({ value }) => {
        if (isString(value)) {
          setTagId(value);
        }
      }}
    />
  );
};

type TagSetupProps = {
  platformId: string;
  conf: PartialConfig;
  setConf: (conf: PartialConfig) => void;
  onRemove: () => void;
  hasDuplicate: boolean;
  subscriptionPlans: SubscriptionPlanNames['subscriptionPlans'];
  onError: ErrorHandler['onError'];
};

const TagSetup: FunctionComponent<TagSetupProps> = ({
  platformId,
  conf,
  setConf,
  onRemove,
  hasDuplicate,
  subscriptionPlans,
  onError,
}) => (
  <div className="flex items-center gap-3 flex-column md:flex-row md:align-items-center">
    <ActiveCampaignTagSelector
      platformId={platformId}
      tagId={conf.activeCampaignTagId}
      setTagId={(activeCampaignTagId) => {
        setConf({ ...conf, activeCampaignTagId });
      }}
      invalid={hasDuplicate}
      onError={onError}
    />
    <MultiSelect
      className="w-28rem max-w-full"
      filter
      filterBy="name"
      value={conf.subscriptionPlanIds}
      options={subscriptionPlans?.products || []}
      optionLabel="name"
      optionValue="id"
      onChange={({ value }) => {
        if (isStringArray(value)) {
          setConf({ ...conf, subscriptionPlanIds: value });
        }
      }}
    />
    <Button
      className="flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body"
      onClick={onRemove}
    >
      <span className="material-symbols-outlined text-lg">delete_forever</span>
    </Button>
  </div>
);

type PartialConfig = Pick<ActiveCampaignTagsSyncConfig, 'id' | 'subscriptionPlanIds'> &
  Partial<Pick<ActiveCampaignTagsSyncConfig, 'activeCampaignTagId'>>;

export const ActiveCampaignSection: FunctionComponent<PlatformSettingsOptionsSectionProps> = ({
  platform,
  savingKeys,
  saveOptions,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { subscriptionPlans, loading } = useSubscriptionPlanNames(platform.id, onError);
  const [activeCampaignApiToken, setActiveCampaignApiToken] = useState<string | null>(
    platform.activeCampaignApiToken || null,
  );
  const [activeCampaignApiUrl, setActiveCampaignApiUrl] = useState<string | null>(
    platform.activeCampaignApiUrl || null,
  );
  const [tagsSyncConfig, setTagsSyncConfig] = useState<PartialConfig[]>([]);

  useEffect(() => {
    setActiveCampaignApiToken(platform.activeCampaignApiToken || null);
    setActiveCampaignApiUrl(platform.activeCampaignApiUrl || null);
    setTagsSyncConfig(platform.activeCampaignTagsSyncConfig || []);
  }, [
    platform.activeCampaignApiToken,
    platform.activeCampaignApiUrl,
    platform.activeCampaignTagsSyncConfig,
  ]);

  const acTagIdsCounts: Record<string, number> = {};
  for (const { activeCampaignTagId } of tagsSyncConfig) {
    if (activeCampaignTagId) {
      acTagIdsCounts[activeCampaignTagId] = (acTagIdsCounts[activeCampaignTagId] || 0) + 1;
    }
  }

  const confValid =
    tagsSyncConfig.every(
      (conf) => conf.activeCampaignTagId && conf.subscriptionPlanIds.length > 0,
    ) && !Object.values(acTagIdsCounts).some((count) => count > 1);

  return (
    <OptionsSection
      title="ActiveCampaign"
      allowSave={
        confValid &&
        ((intersection(savingKeys, [
          PlatformOptionKey.ActiveCampaignApiToken,
          PlatformOptionKey.ActiveCampaignApiUrl,
          PlatformOptionKey.ActiveCampaignTagsSyncConfig,
        ]).length === 0 &&
          ((activeCampaignApiToken || null) !== (platform.activeCampaignApiToken || null) ||
            (activeCampaignApiUrl || null) !== (platform.activeCampaignApiUrl || null))) ||
          !isEqual(tagsSyncConfig, platform.activeCampaignTagsSyncConfig || []))
      }
      getOptions={() => [
        { key: PlatformOptionKey.ActiveCampaignApiToken, value: activeCampaignApiToken },
        { key: PlatformOptionKey.ActiveCampaignApiUrl, value: activeCampaignApiUrl },
        {
          key: PlatformOptionKey.ActiveCampaignTagsSyncConfig,
          value: tagsSyncConfig.length ? tagsSyncConfig : null,
        },
      ]}
      saveOptions={saveOptions}
    >
      <ErrorMessage />
      {loading && <Spinner />}
      <div className="input-field">
        <label htmlFor="platformActiveCampaignApiToken">ActiveCampaign API token</label>
        <Password
          inputId="platformActiveCampaignApiToken"
          className="w-full"
          inputClassName="w-full"
          value={activeCampaignApiToken || ''}
          onChange={(e) => {
            setActiveCampaignApiToken(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="platformActiveCampaignApiUrl">ActiveCampaign API URL</label>
        <InputText
          id="platformActiveCampaignApiUrl"
          className="w-full"
          value={activeCampaignApiUrl || ''}
          onChange={(e) => {
            setActiveCampaignApiUrl(e.target.value);
          }}
        />
      </div>
      <div className="text-lg font-medium my-3">Sync tags</div>
      <p>
        Configure ActiveCampaign tags to be automatically synced based on subscriptions in
        Wirechunk. The selected ActiveCampaign tag will be set only for the contacts who are a
        primary user of an org in Wirechunk that has an active subscription from the selected list
        of products. Missing contacts in ActiveCampaign will be created automatically based on email
        address.
      </p>
      <div className={clsx('flex flex-column gap-3', tagsSyncConfig.length > 0 && 'mb-3')}>
        {tagsSyncConfig.map((conf) => (
          <TagSetup
            key={conf.id}
            platformId={platform.id}
            conf={conf}
            setConf={(newConf) => {
              setTagsSyncConfig((tagsSyncConfig) =>
                tagsSyncConfig.map((c) => (c.id === conf.id ? newConf : c)),
              );
            }}
            onRemove={() => {
              setTagsSyncConfig((tagsSyncConfig) => tagsSyncConfig.filter((c) => c.id !== conf.id));
            }}
            hasDuplicate={
              !!conf.activeCampaignTagId && (acTagIdsCounts[conf.activeCampaignTagId] ?? 0) > 1
            }
            onError={onError}
            subscriptionPlans={subscriptionPlans}
          />
        ))}
      </div>
      <Button
        label="Add"
        onClick={() => {
          setTagsSyncConfig((tagsSyncConfig) => [
            ...tagsSyncConfig,
            { id: nanoid(), subscriptionPlanIds: [] },
          ]);
        }}
      />
    </OptionsSection>
  );
};
