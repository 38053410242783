import { DropdownInputComponent } from '@wirechunk/lib/mixer/types/components.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { BasicIconButton } from '../../BasicIconButton/BasicIconButton.js';
import type { EditComponentContentProps } from './shared/types.js';

const maxKey = (options: DropdownInputComponent['options']) =>
  options?.reduce((max, option) => Math.max(max, option.key), 0) || 0;

export const EditDropdownInput: FunctionComponent<
  EditComponentContentProps<DropdownInputComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editDropdownInputPlaceholder">Placeholder</label>
      <InputText
        id="editDropdownInputPlaceholder"
        className="w-full"
        value={component.placeholder || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, placeholder: e.target.value }));
        }}
      />
    </div>
    <div className="input-field flex align-items-center gap-3">
      <Checkbox
        inputId="editDropdownInputClearable"
        checked={component.clearable || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, clearable: e.checked }));
        }}
      />
      <label htmlFor="editDropdownInputClearable">Clearable</label>
    </div>
    <div>
      <div className="font-medium mb-2">Options</div>
      <div className="flex flex-column gap-3">
        {component.options?.map(({ key, value }) => (
          <div key={key} className="flex flex-row gap-2 align-items-center">
            <InputText
              id={`editDropdownInputOptionValue${key}`}
              className="w-full"
              value={value}
              onChange={(e) => {
                setComponent((c) => ({
                  ...c,
                  options: c.options?.map((opt) =>
                    opt.key === key ? { ...opt, value: e.target.value } : opt,
                  ),
                }));
              }}
            />
            <BasicIconButton
              icon={PrimeIcons.TRASH}
              onClick={() => {
                setComponent((c) => ({
                  ...c,
                  options: c.options?.filter((opt) => opt.key !== key),
                }));
              }}
            />
          </div>
        ))}
      </div>
      <Button
        label="Add option"
        className="mt-3"
        onClick={() => {
          setComponent((c) => ({
            ...c,
            options: [...(c.options || []), { key: maxKey(c.options) + 1, value: '' }],
          }));
        }}
      />
    </div>
  </Fragment>
);
