import { GCloudCertificateState } from '@wirechunk/lib/api.js';
import { Fragment, FunctionComponent } from 'react';

type CertificateStateViewProps = {
  state: GCloudCertificateState;
};

export const CertificateStateView: FunctionComponent<CertificateStateViewProps> = ({ state }) => (
  <Fragment>
    {state === GCloudCertificateState.Provisioning && (
      <div className="text-center">
        <i className="pi pi-spinner pi-spin text-sm" />
      </div>
    )}
    {state === GCloudCertificateState.Active && (
      <div className="text-center">
        <i className="pi pi-check text-sm text-color-success-dark" />
      </div>
    )}
    {state === GCloudCertificateState.Failed && (
      <div className="text-center">
        <i className="pi pi-times text-sm text-color-danger-dark" />
      </div>
    )}
    <div className="font-medium">{state}</div>
  </Fragment>
);
