import type { SiteContext } from '../contexts/SiteContext/SiteContext.js';

// Note: The --color-primary variable and other variables defined at the end here must be defined here at the root.
export const siteVars = (site: SiteContext): string => `:root{
--font-family:${site.fontFamily};
--input-field-font-family:${site.inputFieldFontFamily};
--primary-h:${site.colorPrimary.h};
--primary-s:${site.colorPrimary.s};
--primary-l:${site.colorPrimary.l};
--primary-dark-h:${site.colorPrimaryDark.h};
--primary-dark-s:${site.colorPrimaryDark.s};
--primary-dark-l:${site.colorPrimaryDark.l};
--primary-light-h:${site.colorPrimaryLight.h};
--primary-light-s:${site.colorPrimaryLight.s};
--primary-light-l:${site.colorPrimaryLight.l};
--text-h:${site.textColorPrimaryH};
--text-s:${site.textColorPrimaryS};
--text-l:${site.textColorPrimaryL};
}`;
