import { useMemo } from 'react';
import type { SelectItem } from '../../types.js';
import { ContentPlan, useContentPlans } from '../use-content-plans/use-content-plans.js';
import type { ErrorHandler } from '../useErrorHandler.js';

type PlanSelectorOptions = {
  options: Array<SelectItem<string>>;
  loading: boolean;
};

export const usePlanSelectorOptions = (
  platformId: string,
  filter: (plan: ContentPlan) => boolean,
  onError: ErrorHandler['onError'],
): PlanSelectorOptions => {
  const { contentPlans, loading } = useContentPlans(platformId, onError);

  const options = useMemo(
    () =>
      contentPlans?.filter(filter).map<SelectItem<string>>((plan) => ({
        label: plan.name,
        value: plan.id,
      })) ?? [],
    [filter, contentPlans],
  );

  return {
    options,
    loading,
  };
};
