import { useQuery } from '@apollo/client';
import type { ComponentType, FunctionComponent } from 'react';
import { useCurrentUser } from '../../contexts/CurrentUserContext/CurrentUserContext.js';
import type { SiteContext } from '../../contexts/SiteContext/SiteContext.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { Spinner } from '../Spinner.js';
import { OrganizationSiteContextDocument } from './queries.generated.js';

type OrganizationSite = {
  site: SiteContext;
};

export const withOrganizationSite =
  <P,>(WrappedComponent: ComponentType<P & OrganizationSite>): FunctionComponent<P> =>
  (props: P) => {
    const { onError, ErrorMessage } = useErrorHandler();
    const { user } = useCurrentUser();
    const { data, loading } = useQuery(OrganizationSiteContextDocument, {
      onError,
      fetchPolicy: 'cache-and-network',
      variables: {
        organizationId: user.organization.id,
      },
    });

    if (loading) {
      return <Spinner />;
    }

    const site = data?.organization.site;
    if (site) {
      return <WrappedComponent {...props} site={site} />;
    }

    return <ErrorMessage />;
  };
