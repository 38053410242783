import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { PlanStageBlueprintsDocument, PlanStageBlueprintsQuery } from './queries.generated.js';

export type StageBlueprints = {
  // stageBlueprints will include deleted StageBlueprints.
  stageBlueprints: PlanStageBlueprintsQuery['plan']['stageBlueprints'];
  loading: boolean;
};

export const useStageBlueprints = (
  planId: string,
  onError: ErrorHandler['onError'],
): StageBlueprints => {
  const { data, loading } = useQuery(PlanStageBlueprintsDocument, {
    onError,
    variables: { id: planId },
  });

  return {
    stageBlueprints: data?.plan.stageBlueprints ?? [],
    loading,
  };
};
