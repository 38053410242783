import { RadioCards } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { RadioGroupInputComponent } from '@wirechunk/lib/mixer/types/components.js';
import { ValidInputComponent } from '@wirechunk/lib/mixer/utils.js';
import { isString } from 'lodash-es';
import { FunctionComponent, useCallback } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.js';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.js';
import { RenderMixerChildren } from '../../RenderMixerChildren.js';
import { InputValidationErrorMessageProvider } from '../input-validation-error-message/input-validation-error-message-context.js';

const GuardedRadioGroupInput: FunctionComponent<ValidInputComponent<RadioGroupInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const onValueChange = useCallback<NonNullable<RadioCards.RootProps['onValueChange']>>(
    (value) => {
      setValue(props, value);
    },
    [props, setValue],
  );

  // Always coalesce null and undefined to a string to keep this a controlled component.
  const value = getValue(props) ?? '';
  if (!isString(value)) {
    return null;
  }

  return (
    <RadioCards.Root
      className={componentClassName(props)}
      name={props.name}
      value={value}
      onValueChange={onValueChange}
    >
      <InputValidationErrorMessageProvider value={getValidationError(props)}>
        <RenderMixerChildren>{props.children}</RenderMixerChildren>
      </InputValidationErrorMessageProvider>
    </RadioCards.Root>
  );
};

export const RadioGroupInput =
  withValidInputComponent<RadioGroupInputComponent>(GuardedRadioGroupInput);
