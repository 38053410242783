import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import { isEmpty } from 'lodash-es';
import { FunctionComponent, useEffect } from 'react';
import {
  InputDataContextProvider,
  useInputDataContextValue,
} from '../../../../contexts/InputDataContext.js';
import { ParseAndRenderComponents } from '../../../ParseAndRenderComponents.js';
import type {
  ComponentEditorListener,
  EditComponentContentProps,
} from '../../components/shared/types.js';
import { EditComponent } from '../../editComponentByType.js';

type ContentTabOwnProps = {
  dynamicComponentId: string;
  // A JSON stringified array of components.
  propsSetupComponents: string;
};

type ContentTabProps = EditComponentContentProps<Component> & ContentTabOwnProps;

export const ContentTab: FunctionComponent<ContentTabProps> = ({
  propsSetupComponents,
  component,
  eventHooks,
}) => {
  const inputDataContextValue = useInputDataContextValue(component.customProps ?? undefined);

  useEffect(() => {
    const handleBeforeLeave: ComponentEditorListener = (component) => {
      // This type check is just to satisfy TypeScript.
      const validationErrors = inputDataContextValue.validate();
      if (isEmpty(validationErrors)) {
        return {
          action: 'continue',
          component: {
            ...component,
            customProps: inputDataContextValue.data.visible,
          },
        };
      }

      return {
        action: 'prevent',
        errorMessage: 'Please fill in all of the required fields to save.',
      };
    };

    eventHooks.onBeforeLeave(handleBeforeLeave);

    return () => {
      eventHooks.offBeforeLeave(handleBeforeLeave);
    };
  }, [eventHooks, inputDataContextValue]);

  return (
    <InputDataContextProvider value={inputDataContextValue}>
      <ParseAndRenderComponents componentsJSON={propsSetupComponents} />
    </InputDataContextProvider>
  );
};

export const withContentTab =
  (ownProps: ContentTabOwnProps): EditComponent =>
  (props) => <ContentTab {...ownProps} {...props} />;
