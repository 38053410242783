import { isNumber, isString } from 'lodash-es';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { withEditSiteContext } from '../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useDisplayFormEntryDialog } from '../../../../../../hooks/use-display-form-entry-dialog/use-display-form-entry-dialog.js';
import { useFormEntries } from '../../../../../../hooks/use-form-entries/use-form-entries.js';
import { useFormsMinimal } from '../../../../../../hooks/use-forms-minimal/use-forms-minimal.js';
import {
  ParsedFormEntry,
  useParsedFormEntries,
} from '../../../../../../hooks/use-parsed-form-entries/use-parsed-form-entries.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import type { SelectItem } from '../../../../../../types.js';
import { clickableRowClassName } from '../../../../../../util/clickableRowClassName.js';
import { extractFieldsFromEntries } from '../../../../../../util/formEntries.js';
import { DownloadCsv } from '../../../../../DownloadCsv.js';
import { FormEntryDatesFilter } from '../../../../../FormEntryDatesFilter/FormEntryDatesFilter.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { DataTableWithPaginator } from '../../../Users/UserDetails/DataTableWithPaginator.js';
import { navItems } from '../../nav.js';

const formIdParamName = 'formId';

type Row = ParsedFormEntry;

export const FormEntries = withEditSiteContext(({ site }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { handle } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();

  const formIdParam = searchParams.get(formIdParamName);

  const [formId, setFormId] = useState<string | null>(formIdParam || null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [tableRowsLimit, setTableRowsLimit] = useState(20);

  const { entries, totalCount, loading } = useFormEntries(
    {
      siteId: site.id,
      formId,
      fromDate,
      toDate,
      page: tablePageIndex,
      limit: tableRowsLimit,
    },
    onError,
  );
  const { forms } = useFormsMinimal(site.id, onError);
  const rows = useParsedFormEntries(entries);
  const displayEntry = useDisplayFormEntryDialog();

  const extractedFields = useMemo(() => (rows ? extractFieldsFromEntries(rows) : []), [rows]);

  const formOptions = useMemo<Array<SelectItem<string>> | undefined>(
    () =>
      forms?.map((form) => ({
        label: form.title,
        value: form.id,
      })),
    [forms],
  );

  return (
    <PageContainer
      title={`Site: ${site.name}`}
      pageMetaTitle={`Site: ${site.name} Settings`}
      nav={navItems(handle)}
    >
      <ErrorMessage />
      <div className="flex flex-column md:flex-row gap-4 align-items-center mb-4">
        {formOptions && formOptions.length > 1 && (
          <Dropdown
            className="w-13rem md:w-15rem"
            placeholder="Filter by form"
            options={formOptions}
            value={formId}
            showClear
            onChange={({ value }) => {
              if (isString(value)) {
                setFormId(value);
                setSearchParams((searchParams) => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set(formIdParamName, value);
                  return newParams;
                });
              } else if (!value) {
                setFormId(null);
                setSearchParams((searchParams) => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete(formIdParamName);
                  return newParams;
                });
              }
            }}
          />
        )}
        <FormEntryDatesFilter
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        <DownloadCsv siteId={site.id} formId={formId} fromDate={fromDate} toDate={toDate} />
      </div>
      <div className="overflow-x-auto">
        <DataTableWithPaginator<Row[]>
          value={rows || []}
          loading={loading}
          rowClassName={clickableRowClassName}
          emptyMessage={
            formId || fromDate || toDate
              ? 'No entries found with these filters'
              : 'No entries found'
          }
          onRowClick={(e) => {
            displayEntry(e.data as Row);
          }}
          page={tablePageIndex}
          rows={tableRowsLimit}
          totalRecords={totalCount ?? 0}
          setPage={setTablePageIndex}
          setRows={setTableRowsLimit}
        >
          <Column field="createdAtTimestamp" header="Timestamp" />
          {extractedFields.map(({ name, label }) => (
            <Column
              key={name}
              field={`parsedFormData.${name}`}
              header={label}
              body={(row: Row) => {
                const value = row.parsedFormData[name];
                if (isString(value) || isNumber(value)) {
                  return value;
                }
                if (value === null) {
                  return '(None)';
                }
                return JSON.stringify(value);
              }}
            />
          ))}
        </DataTableWithPaginator>
      </div>
    </PageContainer>
  );
});
