import type {
  PropsArray,
  PropsBoolean,
  PropsNumber,
  PropsString,
} from '@wirechunk/schemas/expressions/expression';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { BuilderProps } from './shared.js';

type PropsFieldType = PropsArray | PropsBoolean | PropsNumber | PropsString;

export const PropsField: FunctionComponent<BuilderProps<PropsFieldType> & { label: string }> = ({
  id,
  label,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div className="input-field mb-0">
        <label htmlFor={`${id}-fieldKey`}>{label}</label>
        <InputText
          id={`${id}-fieldKey`}
          keyfilter="alphanum"
          value={expression.prop || ''}
          onChange={(e) => {
            setExpression({
              ...expression,
              prop: e.target.value,
            });
          }}
        />
      </div>
    </Fragment>
  );
};
