import { useMutation, useQuery } from '@apollo/client';
import { InvoiceFrequency, Permission, SubscriptionStatus } from '@wirechunk/lib/api.js';
import { formatDateTime, roundToDayEnd, roundToDayStart } from '@wirechunk/lib/dates.js';
import { invoiceFrequencyToHumanReadable } from '@wirechunk/lib/products.js';
import { isDate } from 'lodash-es';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../contexts/DialogContext/DialogContext.js';
import type { ErrorHandler } from '../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../hooks/useMenuSetup.js';
import {
  invoiceFrequencyOptions,
  subscriptionStatusOptions,
  subscriptionStatusToHumanReadable,
} from '../../util/subscriptions.js';
import { DataTableWithPaginator } from '../admin/pages/Users/UserDetails/DataTableWithPaginator.js';
import { InputNotice } from '../InputNotice/InputNotice.js';
import { NoneLabel } from '../NoneLabel/NoneLabel.js';
import { DeleteSubscriptionOrganization } from '../OrganizationDetails/SubscriptionOrganization/DeleteSubscriptionOrganization.js';
import { QuestionHelpTooltip } from '../QuestionHelpTooltip/QuestionHelpTooltip.js';
import { Spinner } from '../Spinner.js';
import { ThreeDotMenuButton } from '../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { CreateSubscriptionOrganization } from './CreateSubscriptionOrganization.js';
import { EditSubscriptionDocument } from './mutations.generated.js';
import {
  SubscriptionAuditLogsDocument,
  SubscriptionAuditLogsQuery,
  SubscriptionDetailsDocument,
  SubscriptionDetailsQuery,
} from './queries.generated.js';

type AuditLogRow = SubscriptionAuditLogsQuery['subscriptionAuditLogs']['logs'][number];

type Subscription = SubscriptionDetailsQuery['subscription'];

type SubscriptionOrg = Subscription['subscriptionOrganizations'][number];

enum Mode {
  View,
  Edit,
}

type SubscriptionDetailsProps = {
  subscriptionId: string;
  onError: ErrorHandler['onError'];
  clearMessages: ErrorHandler['clearMessages'];
};

export const SubscriptionDetails: FunctionComponent<SubscriptionDetailsProps> = ({
  subscriptionId,
  onError,
  clearMessages,
}) => {
  const { id: platformId, permissions } = usePlatformContext();
  const dialog = useDialog();
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(SubscriptionDetailsDocument, {
    onError,
    variables: { id: subscriptionId },
  });

  const [auditLogRows, setAuditLogRows] = useState(10);
  const [auditLogPage, setAuditLogPage] = useState(0);
  const { data: auditLogsData, loading: isLoadingAuditLogs } = useQuery(
    SubscriptionAuditLogsDocument,
    {
      onError,
      variables: {
        platformId,
        subscriptionId,
        page: auditLogPage,
        limit: auditLogRows,
      },
    },
  );
  const [editSubscription, { loading: isSavingEdits }] = useMutation(EditSubscriptionDocument, {
    onError,
  });

  const subscription = data?.subscription;

  const [stripeSubscriptionId, setStripeSubscriptionId] = useState(
    subscription?.stripeSubscriptionId,
  );
  const [startAt, setStartAt] = useState(
    subscription ? new Date(subscription.startAt) : new Date(),
  );
  const [endAt, setEndAt] = useState(subscription?.endAt ? new Date(subscription.endAt) : null);
  const [status, setStatus] = useState<SubscriptionStatus>(
    subscription?.status || SubscriptionStatus.Active,
  );
  const [price, setPrice] = useState<string>(subscription?.price || '');
  const [invoiceFrequency, setInvoiceFrequency] = useState<InvoiceFrequency | null>(
    subscription?.invoiceFrequency || null,
  );
  const [notes, setNotes] = useState('');
  const [isAddingOrg, setIsAddingOrg] = useState(false);
  // hasUnsavedChanges tracks all the fields except for notes, since you should not save just notes.
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [mode, setMode] = useState(Mode.View);

  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<SubscriptionOrg>();

  // resetState resets everything including the hasUnsavedChanges flag.
  const resetState = useCallback(() => {
    setMode(Mode.View);
    if (subscription) {
      setStripeSubscriptionId(subscription.stripeSubscriptionId);
      setStartAt(new Date(subscription.startAt));
      setEndAt(subscription.endAt ? new Date(subscription.endAt) : null);
      setStatus(subscription.status);
      setPrice(subscription.price);
      setInvoiceFrequency(subscription.invoiceFrequency || null);
      setNotes('');
      setHasUnsavedChanges(false);
    }
  }, [subscription]);

  useEffect(resetState, [resetState]);

  const userCanEditOrgSubscription = permissions.includes(Permission.EditSubscription);

  const onSave = () => {
    clearMessages();
    void editSubscription({
      variables: {
        id: subscriptionId,
        stripeSubscriptionId: stripeSubscriptionId || null,
        startAt: startAt,
        endAt: endAt || null,
        status,
        price,
        invoiceFrequency,
        notes,
      },
    });
  };

  return isLoading ? (
    <Spinner />
  ) : subscription ? (
    mode === Mode.View ? (
      <Fragment>
        <div className="flex flex-column gap-2">
          <div>
            Plan: <span className="font-medium">{subscription.plan.name}</span>
          </div>
          <div>
            Stripe subscription ID:{' '}
            {subscription.stripeSubscriptionId ? (
              <span className="font-medium">{subscription.stripeSubscriptionId}</span>
            ) : (
              <NoneLabel />
            )}
          </div>
          <div>
            Start date: <span className="font-medium">{formatDateTime(subscription.startAt)}</span>
          </div>
          <div>
            End date:{' '}
            <span className="font-medium">
              {subscription.endAt ? formatDateTime(subscription.endAt) : 'Never'}
            </span>
          </div>
          <div>
            Status:{' '}
            <span className="font-medium">
              {subscriptionStatusToHumanReadable(subscription.status)}
            </span>
          </div>
          <div>
            Price: <span className="font-medium">${subscription.price}</span>
          </div>
          {subscription.price !== '0.00' && (
            <div>
              Invoice frequency:{' '}
              <span className="font-medium">
                {subscription.invoiceFrequency
                  ? invoiceFrequencyToHumanReadable(subscription.invoiceFrequency)
                  : 'Unknown'}
              </span>
            </div>
          )}
          <div>
            Promo code:{' '}
            <span className="font-medium">
              {subscription.promoCode ? (
                <span className="font-medium">{subscription.promoCode}</span>
              ) : (
                <NoneLabel />
              )}
            </span>
          </div>
          {userCanEditOrgSubscription && (
            <Button
              label="Edit subscription"
              className="p-button-sm block w-max mt-3"
              onClick={() => {
                setMode(Mode.Edit);
              }}
            />
          )}
          <div className="border-1 border-round overflow-hidden mt-3">
            <div className="surface-ground px-3 py-2 font-bold">Notes</div>
            <div className="flex flex-column">
              {subscription.notes.length > 0 ? (
                subscription.notes.map((note) => (
                  <div key={note.id} className="p-3 background-white border-top-1">
                    <div className="white-space-pre-line">{note.notes}</div>
                    <div className="text-sm mt-1 text-right">
                      {note.byUser.displayName} on {formatDateTime(note.createdAt)}
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-color-muted p-3">No notes</div>
              )}
            </div>
          </div>
          <div className="border-1 border-round overflow-hidden mt-3">
            <div className="surface-ground px-3 py-2">
              <div className="flex align-items-center justify-content-between">
                <span className="font-bold">
                  <span className="mr-1">Organizations</span>
                  <QuestionHelpTooltip helpText="The orgs that are included in this subscription." />
                </span>
                <Button
                  className="p-button-sm p-button-text"
                  label="Add org"
                  onClick={() => {
                    setIsAddingOrg(true);
                  }}
                />
              </div>
              {isAddingOrg && (
                <CreateSubscriptionOrganization
                  className="mt-2 mb-1"
                  subscription={subscription}
                  onAdded={() => {
                    setIsAddingOrg(false);
                    void refetch();
                  }}
                  onCancel={() => {
                    setIsAddingOrg(false);
                  }}
                />
              )}
            </div>
            <DataTable
              dataKey="id"
              className="no-header-row"
              value={subscription.subscriptionOrganizations}
              emptyMessage="None"
            >
              <Column
                header="Primary user"
                body={({ organization }: SubscriptionOrg) => {
                  if (organization.primaryUser) {
                    return (
                      <div className="flex flex-column gap-1">
                        <span>{organization.primaryUser.displayName}</span>
                        <span className="text-sm text-color-muted">
                          {organization.primaryUser.email}
                        </span>
                      </div>
                    );
                  }
                  return '(No primary user)';
                }}
              />
              <Column
                header=""
                align="right"
                body={(subOrg: SubscriptionOrg) =>
                  subOrg.isSubscriptionManager ? null : (
                    <ThreeDotMenuButton onClick={onSelectMenuRow(subOrg)} />
                  )
                }
              />
            </DataTable>
          </div>
          <div className="border-1 border-round overflow-hidden mt-3">
            <div className="surface-ground px-3 py-2 font-bold">Audit logs</div>
            <DataTableWithPaginator
              value={auditLogsData?.subscriptionAuditLogs.logs || []}
              loading={isLoadingAuditLogs}
              page={auditLogPage}
              rows={auditLogRows}
              totalRecords={auditLogsData?.subscriptionAuditLogs.totalCount || 0}
              setPage={setAuditLogPage}
              setRows={setAuditLogRows}
            >
              <Column
                header="Timestamp"
                className="lg:w-13rem lg:max-w-13rem"
                body={(row: AuditLogRow) => <span>{formatDateTime(row.createdAt)}</span>}
              />
              <Column
                header="Acting User"
                body={({ actionByUser }: AuditLogRow) => (
                  <div>
                    <div>{actionByUser.displayName}</div>
                    <div className="text-sm">({actionByUser.email})</div>
                  </div>
                )}
              />
              <Column
                header="Organization"
                body={({ organization }: AuditLogRow) =>
                  organization ? (
                    <div>
                      <div>{organization.name}</div>
                      {organization.primaryUser ? (
                        <div>
                          <div>Primary user: {organization.primaryUser.displayName}</div>
                          <div className="text-sm">({organization.primaryUser.email})</div>
                        </div>
                      ) : (
                        <div className="text-color-muted">
                          Primary user: <NoneLabel />
                        </div>
                      )}
                    </div>
                  ) : null
                }
              />
              <Column header="Action" body={(row: AuditLogRow) => <span>{row.action}</span>} />
            </DataTableWithPaginator>
          </div>
        </div>
        <Menu
          ref={menu}
          model={[
            {
              label: 'Remove organization from subscription',
              command: () => {
                if (menuRow) {
                  dialog({
                    content: (
                      <DeleteSubscriptionOrganization
                        id={menuRow.id}
                        planName={subscription.plan.name}
                        organization={menuRow.organization}
                        onDeleted={() => {
                          void refetch();
                        }}
                      />
                    ),
                    props: {
                      header: 'Remove organization’s subscription',
                      className: 'dialog-width-lg',
                    },
                  });
                }
              },
            },
          ]}
          popup
        />
      </Fragment>
    ) : (
      <Fragment>
        <div className="input-field">
          <label htmlFor={`subscriptionStripeSubscriptionId-${subscription.id}`}>
            Stripe subscription ID
          </label>
          <InputText
            id={`subscriptionStripeSubscriptionId-${subscription.id}`}
            className="w-20rem"
            value={stripeSubscriptionId || ''}
            onChange={(e) => {
              setStripeSubscriptionId(e.target.value);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
        <div className="input-field">
          <label htmlFor={`subscriptionStartAt-${subscription.id}`}>Start date</label>
          <Calendar
            inputId={`subscriptionStartAt-${subscription.id}`}
            className="w-9rem"
            value={startAt}
            onChange={(e) => {
              if (isDate(e.value)) {
                setStartAt(e.value);
                setHasUnsavedChanges(true);
              }
            }}
          />
        </div>
        <div className="input-field">
          <label htmlFor={`subscriptionSetEndAt-${subscription.id}`}>Set an end date</label>
          <Checkbox
            inputId={`subscriptionSetEndAt-${subscription.id}`}
            checked={!!endAt}
            onChange={(e) => {
              if (e.checked) {
                setEndAt(roundToDayStart(new Date()));
              } else {
                setEndAt(null);
              }
              setHasUnsavedChanges(true);
            }}
          />
        </div>
        {endAt && (
          <div className="input-field">
            <label htmlFor={`subscriptionEndAt-${subscription.id}`}>End date (inclusive)</label>
            <Calendar
              inputId={`subscriptionEndAt-${subscription.id}`}
              className="w-9rem"
              value={endAt}
              onChange={(e) => {
                if (isDate(e.value)) {
                  setEndAt(roundToDayEnd(e.value));
                  setHasUnsavedChanges(true);
                }
              }}
            />
          </div>
        )}
        <div className="input-field">
          <label htmlFor={`subscriptionStatus-${subscription.id}`}>Status</label>
          <Dropdown
            inputId={`subscriptionStatus-${subscription.id}`}
            className="w-9rem"
            value={status}
            options={subscriptionStatusOptions}
            onChange={(e) => {
              setStatus(e.value as SubscriptionStatus);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
        <div className="input-field">
          <label htmlFor={`subscriptionPrice-${subscription.id}`}>Price</label>
          <InputText
            id={`subscriptionPrice-${subscription.id}`}
            className="w-8rem"
            placeholder="0"
            keyfilter="pnum"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
        <div className="input-field">
          <label htmlFor={`subscriptionInvoiceFrequency-${subscription.id}`}>
            Invoice frequency
          </label>
          <Dropdown
            inputId={`subscriptionInvoiceFrequency-${subscription.id}`}
            disabled={!price || !Number(price)}
            value={price && Number(price) > 0 ? invoiceFrequency : null}
            options={invoiceFrequencyOptions}
            onChange={(e) => {
              setInvoiceFrequency(e.value as InvoiceFrequency);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
        <div className="input-field">
          <label htmlFor={`subscriptionNotes-${subscription.id}`}>Notes</label>
          <InputTextarea
            id={`subscriptionNotes-${subscription.id}`}
            className="w-full"
            rows={5}
            value={notes}
            // Changing notes does not affect hasUnsavedChanges.
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
          <InputNotice>Explain the context of this change in detail.</InputNotice>
        </div>
        <div className="flex gap-3 mt-3">
          <Button
            label="Save"
            disabled={isSavingEdits || !hasUnsavedChanges || !notes}
            onClick={onSave}
          />
          <Button
            className="p-button-outlined"
            label="Cancel"
            disabled={isSavingEdits}
            onClick={() => {
              if (hasUnsavedChanges) {
                dialog({
                  confirm: 'You have unsaved changes. Cancel for sure?',
                  props: {
                    onAccept: resetState,
                  },
                });
              } else {
                resetState();
              }
            }}
          />
        </div>
      </Fragment>
    )
  ) : null;
};
