import { PlatformOptionKey } from '@wirechunk/lib/platform-options.js';
import { intersection } from 'lodash-es';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useEffect, useState } from 'react';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { OptionsSection } from '../../../OptionsSection.js';
import { PlatformSettingsOptionsSectionProps } from './types.js';

export const ProductAnalyticsSection: FunctionComponent<PlatformSettingsOptionsSectionProps> = ({
  platform,
  savingKeys,
  saveOptions,
}) => {
  const [mixpanelToken, setMixpanelToken] = useState<string | null>(platform.mixpanelToken || null);

  useEffect(() => {
    setMixpanelToken(platform.mixpanelToken || null);
  }, [platform.mixpanelToken]);

  return (
    <OptionsSection
      title="Product Analytics"
      allowSave={
        intersection(savingKeys, [PlatformOptionKey.MixpanelToken]).length === 0 &&
        (mixpanelToken || null) !== (platform.mixpanelToken || null)
      }
      getOptions={() => [{ key: PlatformOptionKey.MixpanelToken, value: mixpanelToken }]}
      saveOptions={saveOptions}
    >
      <div className="input-field mb-0">
        <label htmlFor="platformMixpanelToken">Mixpanel token</label>
        <InputText
          id="platformMixpanelToken"
          className="w-full"
          value={mixpanelToken || ''}
          onChange={(e) => {
            setMixpanelToken(e.target.value);
          }}
        />
        <InputNotice>The Mixpanel token to use on the server side for all sites.</InputNotice>
      </div>
    </OptionsSection>
  );
};
