import { HelpTicketType } from '@wirechunk/lib/help-tickets.js';
import { FunctionComponent, Fragment } from 'react';
import { HelpTicketsQuery } from './queries.generated.js';

const DisplayField: FunctionComponent<{ label: string; value: string | null | undefined }> = ({
  label,
  value,
}) =>
  value ? (
    <div>
      <div className="font-bold mb-1">{label}</div>
      <div className="w-full max-w-full white-space-pre-line border-1 border-round p-2">
        {value}
      </div>
    </div>
  ) : null;

type TicketRow = HelpTicketsQuery['helpTickets'][number];
type HelpTicketsDetailsProps = {
  row: TicketRow;
};

export const HelpTicketsDetails: FunctionComponent<HelpTicketsDetailsProps> = ({ row }) => {
  return (
    <div className="flex flex-column gap-3">
      {(row.type === HelpTicketType.Other.toString() ||
        row.type === HelpTicketType.Billing.toString() ||
        row.type === HelpTicketType.FeatureRequest.toString()) && (
        <DisplayField label="Message" value={row.message} />
      )}
      {row.type === HelpTicketType.SiteCustomDomain.toString() && (
        <Fragment>
          <DisplayField label="Custom domain" value={row.customDomain} />
          <DisplayField label="Additional comments (optional)" value={row.message} />
        </Fragment>
      )}
      {row.type === HelpTicketType.SiteCustomCode.toString() && (
        <Fragment>
          <DisplayField
            label="Header code (HTML <head> element)"
            value={row.customSiteHeaderCode}
          />
          <DisplayField
            label="Page body start code (after opening <body> tag)"
            value={row.customSiteBodyStartCode}
          />
          <DisplayField
            label="Page body end code (before closing </body> tag)"
            value={row.customSiteBodyEndCode}
          />
        </Fragment>
      )}
    </div>
  );
};
