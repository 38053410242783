import type { NavItem } from '../../../PageContainer/PageContainer.js';

export const navItems = (platformHandle: string): NavItem[] => [
  { path: `/dashboard/${platformHandle}/content/courses`, label: 'Courses' },
  { path: `/dashboard/${platformHandle}/content/sequences`, label: 'Sequences' },
  { path: `/dashboard/${platformHandle}/content/documents`, label: 'Documents' },
  {
    path: `/dashboard/${platformHandle}/content/document-download-prompts`,
    label: 'Download Prompts',
  },
];
