import { ReviewComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import {
  SelectGooglePlace,
  SelectGooglePlaceProps,
} from '../../SelectGooglePlace/SelectGooglePlace.js';
import { placeToDescription } from '../../SelectGooglePlace/utils.js';
import type { EditComponentContentProps } from './shared/types.js';

export const EditReview: FunctionComponent<EditComponentContentProps<ReviewComponent>> = ({
  component,
  setComponent,
}) => {
  const [googlePlaceDescription, setGooglePlaceDescription] = useState('');

  const setGooglePlace = useCallback<SelectGooglePlaceProps['setPlace']>(
    (place) => {
      setComponent(
        (c): ReviewComponent => ({
          ...c,
          googlePlaceId: place.place_id,
        }),
      );
      setGooglePlaceDescription(placeToDescription(place));
    },
    [setComponent],
  );

  return (
    <Fragment>
      <p>
        By default, the site’s Google Place setting will be used. Alternatively, you can configure a
        different Google Place here.
      </p>
      {googlePlaceDescription && (
        <div>
          <span className="font-medium">Selected:</span> {googlePlaceDescription}
        </div>
      )}
      {component.googlePlaceId && (
        <Button
          className="p-button-outlined p-button-sm my-1"
          label="Clear"
          onClick={() => {
            setComponent((c) => ({
              ...c,
              googlePlaceId: null,
            }));
            setGooglePlaceDescription('');
          }}
        />
      )}
      <SelectGooglePlace placeId={component.googlePlaceId} setPlace={setGooglePlace} />
    </Fragment>
  );
};
