import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import type { SubscriptionTagGuardComponent } from '@wirechunk/lib/mixer/types/components.js';
import {
  SubscriptionTagGuardAccessDeniedMode,
  SubscriptionTagGuardSlotKey,
} from '@wirechunk/lib/mixer/types/components.js';
import { PrimeIcons } from 'primereact/api';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { RenderMixerChildren } from '../../RenderMixerChildren.js';
import styles from './SubscriptionTagGuard.module.css';

// TODO: For the default message, check if the user has a past-due subscription
// that, if were it current, would include the tag. If so, render a message that says something to the
// effect of "Your subscription is past due. Please contact us to renew your subscription."

export const SubscriptionTagGuard: FunctionComponent<SubscriptionTagGuardComponent> = ({
  tag,
  accessDeniedMode,
  children,
  ...props
}) => {
  const { user } = useCurrentUser();

  if (!tag || user.productItems.includes(tag)) {
    return (
      <RenderMixerChildren>
        {children?.filter((c) => !c.slot || c.slot === SubscriptionTagGuardSlotKey.AccessAllowed)}
      </RenderMixerChildren>
    );
  }

  if (accessDeniedMode === SubscriptionTagGuardAccessDeniedMode.CustomMessage) {
    return (
      <RenderMixerChildren>
        {children?.filter((c) => c.slot === SubscriptionTagGuardSlotKey.AccessDenied)}
      </RenderMixerChildren>
    );
  }

  if (
    !accessDeniedMode ||
    accessDeniedMode === SubscriptionTagGuardAccessDeniedMode.DefaultMessage
  ) {
    // This div contains an extra div immediately inside so that the text is not centered.
    return (
      <div className={`${componentClassName(props)} ${styles.wrapper} my-4 mx-3`}>
        <div>
          <p className="flex align-items-center gap-3 md:gap-2">
            <i className={`${PrimeIcons.EXCLAMATION_TRIANGLE} pb-px text-color-warning text-lg`} />
            <span className="font-medium">
              You do not have an active subscription that includes this feature or content.
            </span>
          </p>
          <Link to="/order" className="p-button text-white font-medium flex w-max px-4 mb-4">
            Upgrade my subscription
          </Link>
          <Link to="/contact" className="p-button p-button-secondary px-4">
            Contact support
          </Link>
        </div>
      </div>
    );
  }

  return null;
};
