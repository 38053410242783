import { DataSource } from '@wirechunk/lib/mixer/types/components.js';
import { isDataSource } from '@wirechunk/lib/mixer/utils.js';
import { clsx } from 'clsx';
import { Dropdown } from 'primereact/dropdown';
import type { FunctionComponent } from 'react';
import { dataSourceOptions } from '../../../util/mixer/util.js';

type DataSourceDropdownProps = {
  className?: string;
  parentId: string;
  label?: string;
  type: DataSource;
  onChange: (type: DataSource) => void;
};

export const DataSourceDropdown: FunctionComponent<DataSourceDropdownProps> = ({
  className,
  parentId,
  label = 'Source',
  type,
  onChange,
}) => (
  <div className={clsx('input-field', className)}>
    <label htmlFor={`${parentId}-dataSource`}>{label}</label>
    <Dropdown
      inputId={`${parentId}-dataSource`}
      className="w-full"
      options={dataSourceOptions}
      value={type}
      onChange={({ value }) => {
        if (isDataSource(value)) {
          onChange(value);
        }
      }}
    />
  </div>
);
