import { StageBodyComponent } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useStageContext } from '../../../contexts/StageContext/StageContext.js';
import { RenderComponentsStyled } from '../../RenderComponentsStyled.js';

export const StageBody: FunctionComponent<StageBodyComponent> = () => {
  const stageContext = useStageContext();

  if (stageContext) {
    return <RenderComponentsStyled components={stageContext.stageBlueprint.components} />;
  }

  return null;
};
