import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { MapComponent } from '@wirechunk/lib/mixer/types/components.js';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';
import { GoogleMap } from '../GoogleMap/GoogleMap.js';

export const Map: FunctionComponent<MapComponent> = (props) => {
  const { googlePlaceId } = useSiteContext();

  let placeId: string | null | undefined;
  switch (props.focus?.type) {
    case 'CustomGooglePlace':
      placeId = props.focus.placeId;
      break;
    case 'SiteGooglePlace':
    case 'OrganizationPlace':
    default:
      placeId = googlePlaceId;
      break;
  }

  return <GoogleMap className={componentClassName(props)} googlePlaceId={placeId} />;
};
