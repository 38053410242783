import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const OrganizationAgencyAddressLine2: FunctionComponent<Component> = (props) => {
  const { addressLine2 } = useSiteContext();

  return <span className={componentClassName(props)}>{addressLine2 || ''}</span>;
};
