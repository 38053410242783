import { OrganizationPermission } from '@wirechunk/lib/api.js';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { isNumber, isString } from 'lodash-es';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDisplayFormEntryDialog } from '../../../hooks/use-display-form-entry-dialog/use-display-form-entry-dialog.js';
import { useFormEntries } from '../../../hooks/use-form-entries/use-form-entries.js';
import { useFormsMinimal } from '../../../hooks/use-forms-minimal/use-forms-minimal.js';
import {
  ParsedFormEntry,
  useParsedFormEntries,
} from '../../../hooks/use-parsed-form-entries/use-parsed-form-entries.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import type { SelectItem } from '../../../types.js';
import { clickableRowClassName } from '../../../util/clickableRowClassName.js';
import { extractFieldsFromEntries } from '../../../util/formEntries.js';
import { DataTableWithPaginator } from '../../admin/pages/Users/UserDetails/DataTableWithPaginator.js';
import { DownloadCsv } from '../../DownloadCsv.js';
import { FormEntryDatesFilter } from '../../FormEntryDatesFilter/FormEntryDatesFilter.js';
import { withOrganizationSite } from '../../with-organization-site/with-organization-site.js';

const formIdParamName = 'formId';
const entryIdParamName = 'entryId';

export const OrganizationSiteLeads: FunctionComponent<
  ComponentSpec<ComponentType.OrganizationSiteLeads>
> = withOrganizationSite(({ site, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();

  const formIdParam = searchParams.get(formIdParamName);
  const entryIdParam = searchParams.get(entryIdParamName);

  const [formId, setFormId] = useState<string | null>(formIdParam || null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [tableRowsLimit, setTableRowsLimit] = useState(20);

  const { entries, totalCount, loading } = useFormEntries(
    {
      siteId: site.id,
      formId,
      fromDate,
      toDate,
      page: tablePageIndex,
      limit: tableRowsLimit,
    },
    onError,
  );
  const { forms } = useFormsMinimal(site.id, onError);
  const rows = useParsedFormEntries(entries);

  const onBeforeHideEntry = useCallback(() => {
    setSearchParams((searchParams) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete(entryIdParamName);
      return newParams;
    });
  }, [setSearchParams]);
  const displayEntry = useDisplayFormEntryDialog(onBeforeHideEntry);
  const showedInitialEntryDialog = useRef<boolean>(false);

  useEffect(() => {
    if (entryIdParam && rows && !showedInitialEntryDialog.current) {
      const entry = rows.find((row) => row.id === entryIdParam);
      if (entry) {
        displayEntry(entry);
        showedInitialEntryDialog.current = true;
      }
    }
  }, [entryIdParam, displayEntry, rows]);

  const extractedFields = useMemo(() => (rows ? extractFieldsFromEntries(rows) : []), [rows]);

  const formOptions = useMemo<Array<SelectItem<string>> | undefined>(
    () =>
      forms?.map((form) => ({
        label: form.title,
        value: form.id,
      })),
    [forms],
  );

  const hasEditPermission = user.organizationPermissions.includes(OrganizationPermission.Edit);

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <p>
        This page lists all the leads (form submissions) you got from across all the forms on your
        landing pages.
      </p>
      {!rows?.length && !loading && !fromDate && !toDate && (
        <div className="mb-3">
          When you get a lead, it will show up here.
          {!hasEditPermission && ' Reach out to your agency owner to set up landing pages.'}
        </div>
      )}
      <div className="flex flex-column md:flex-row gap-4 align-items-center mb-4">
        {formOptions && formOptions.length > 1 && (
          <Dropdown
            className="w-13rem md:w-15rem"
            placeholder="Filter by form"
            options={formOptions}
            value={formId}
            showClear
            onChange={({ value }) => {
              if (isString(value)) {
                setFormId(value);
                setSearchParams((searchParams) => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set(formIdParamName, value);
                  return newParams;
                });
              } else if (!value) {
                setFormId(null);
                setSearchParams((searchParams) => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete(formIdParamName);
                  return newParams;
                });
              }
            }}
          />
        )}
        <FormEntryDatesFilter
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        <DownloadCsv siteId={site.id} formId={formId} fromDate={fromDate} toDate={toDate} />
      </div>
      <div className="overflow-x-auto">
        <DataTableWithPaginator<ParsedFormEntry[]>
          value={rows || []}
          loading={loading}
          rowClassName={clickableRowClassName}
          emptyMessage={!fromDate && !toDate ? 'No leads yet' : 'No leads in this date range'}
          onRowClick={({ data }) => {
            displayEntry(data as ParsedFormEntry);
          }}
          page={tablePageIndex}
          rows={tableRowsLimit}
          totalRecords={totalCount ?? 0}
          setPage={setTablePageIndex}
          setRows={setTableRowsLimit}
        >
          <Column field="createdAtTimestamp" header="Timestamp" />
          {extractedFields.map(({ name, label }) => (
            <Column
              key={name}
              field={`parsedFormData.${name}`}
              header={label}
              body={(row: ParsedFormEntry) => {
                const value = row.parsedFormData[name];
                if (isString(value) || isNumber(value)) {
                  return value;
                }
                if (value === null) {
                  return '(None)';
                }
                return JSON.stringify(value);
              }}
            />
          ))}
        </DataTableWithPaginator>
      </div>
    </div>
  );
});
