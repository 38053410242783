import { useMutation, useQuery } from '@apollo/client';
import {
  ConfirmationAction as ConfirmationActionType,
  FormConfirmationRedirectUrlParameterInput,
} from '@wirechunk/lib/api.js';
import { confirmationActions, isConfirmationAction } from '@wirechunk/lib/forms.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormContextProvider } from '../../../../../../../contexts/FormContext/form-context.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../contexts/PageContext/PageContext.js';
import { useToast } from '../../../../../../../contexts/ToastContext.js';
import { useCollectAllFormComponents } from '../../../../../../../hooks/use-collect-all-form-components.js';
import { useValidInputComponents } from '../../../../../../../hooks/use-valid-input-components.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../hooks/useHasUnsavedChanges.js';
import { useSiteContextSelector } from '../../../../../../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { InputNotice } from '../../../../../../InputNotice/InputNotice.js';
import { Spinner } from '../../../../../../Spinner.js';
import { VisualBuilder } from '../../../../../../VisualBuilder/VisualBuilder.js';
import { EditUrlParameters } from '../../../Site/forms/form/edit-url-parameters.js';
import { withEditFormTemplateContext } from '../edit-form-template-context.js';
import { EditFormTemplateConfirmationDocument } from './mutations.generated.js';
import { FormTemplateConfirmationConfigDocument } from './queries.generated.js';

const pageContext: PageContext = {
  title: '',
  viewMode: ViewMode.Preview,
};

export const ConfirmationAction = withEditFormTemplateContext(({ formTemplate, formContext }) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();

  const { data, loading } = useQuery(FormTemplateConfirmationConfigDocument, {
    fetchPolicy: 'cache-and-network',
    onError,
    variables: { id: formTemplate.id },
  });
  const [editFormTemplateConfirmation, { loading: saving, client: apolloClient }] = useMutation(
    EditFormTemplateConfirmationDocument,
    {
      onError,
      onCompleted: () => {
        toastSuccess('Form saved.');
        resetHasUnsavedChanges();
      },
    },
  );

  const [confirmationAction, setConfirmationAction] = useState<ConfirmationActionType>(
    ConfirmationActionType.Message,
  );
  const [confirmationRedirectUrl, setConfirmationRedirectUrl] = useState<string | null>(null);
  const [confirmationRedirectUrlParameters, setConfirmationRedirectUrlParameters] = useState<
    FormConfirmationRedirectUrlParameterInput[]
  >([]);
  const [confirmationMessageComponents, setConfirmationMessageComponents] = useState<Component[]>(
    [],
  );

  const components = useCollectAllFormComponents(formTemplate);
  const { inputComponents, loading: loadingInputComponents } = useValidInputComponents(
    components,
    apolloClient,
  );

  const setConfirmationMessageComponentsWrapped = useCallback<
    Dispatch<SetStateAction<Component[]>>
  >(
    (component) => {
      setConfirmationMessageComponents(component);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  useEffect(() => {
    setConfirmationAction(data?.formTemplate.confirmationAction || ConfirmationActionType.Message);
    setConfirmationRedirectUrl(data?.formTemplate.confirmationRedirectUrl || null);
    setConfirmationRedirectUrlParameters(
      data?.formTemplate.confirmationRedirectParameters.map(
        ({ __typename: _ignored, ...rest }) => rest,
      ) ?? [],
    );
    setConfirmationMessageComponents(
      data?.formTemplate.confirmationMessageComponents
        ? parseComponents(data.formTemplate.confirmationMessageComponents)
        : [],
    );
  }, [
    data?.formTemplate.confirmationAction,
    data?.formTemplate.confirmationRedirectUrl,
    data?.formTemplate.confirmationRedirectParameters,
    data?.formTemplate.confirmationMessageComponents,
  ]);

  const siteContextSelector = useSiteContextSelector({ onError });

  return (
    <Fragment>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-column gap-2 mt-3">
          <Button
            className="p-button-success w-max"
            label="Save"
            disabled={!hasUnsavedChanges || saving}
            onClick={() => {
              clearMessages();
              void editFormTemplateConfirmation({
                variables: {
                  id: formTemplate.id,
                  confirmationAction,
                  confirmationRedirectUrl,
                  confirmationRedirectUrlParameters,
                  confirmationMessageComponents: JSON.stringify(confirmationMessageComponents),
                },
              });
            }}
          />
          <div className="input-field mb-1">
            <label htmlFor="formTemplateConfirmationAction">Confirmation action</label>
            <Dropdown
              inputId="formTemplateConfirmationAction"
              className="w-20rem max-w-full"
              options={confirmationActions}
              value={confirmationAction}
              onChange={({ value }) => {
                if (isConfirmationAction(value)) {
                  setConfirmationAction(value);
                  triggerHasUnsavedChanges();
                }
              }}
            />
          </div>
          {confirmationAction === ConfirmationActionType.Redirect ? (
            <Fragment>
              <div className="input-field mb-1">
                <label htmlFor="formTemplateConfirmationRedirectUrl">
                  Confirmation redirect URL
                </label>
                <InputText
                  id="formTemplateConfirmationRedirectUrl"
                  className="w-30rem max-w-full"
                  value={confirmationRedirectUrl || ''}
                  onChange={(e) => {
                    setConfirmationRedirectUrl(e.target.value);
                    triggerHasUnsavedChanges();
                  }}
                />
                <InputNotice>
                  Note that, if the URL starts with a slash, the user will be redirected to a page
                  on the same site.
                </InputNotice>
              </div>
              <div>
                <div className="font-medium mb-1">Confirmation redirect URL query parameters</div>
                <InputNotice>
                  You can select input components whose value will be passed along with the
                  redirect.
                </InputNotice>
              </div>
              {confirmationRedirectUrlParameters.map((param) => (
                <EditUrlParameters
                  key={param.id}
                  param={param}
                  setConfirmationRedirectUrlParameters={setConfirmationRedirectUrlParameters}
                  inputComponents={inputComponents}
                  loadingInputComponentNames={loadingInputComponents}
                  triggerHasUnsavedChanges={triggerHasUnsavedChanges}
                />
              ))}
              <Button
                label="Add parameter"
                className="w-max"
                onClick={() => {
                  setConfirmationRedirectUrlParameters((params) => [
                    ...params,
                    {
                      // id must be a UUID.
                      id: window.crypto.randomUUID(),
                      parameter: '',
                      componentName: '',
                    },
                  ]);
                  triggerHasUnsavedChanges();
                }}
              />
            </Fragment>
          ) : (
            <VisualBuilder
              siteContext={siteContextSelector}
              components={confirmationMessageComponents}
              setComponents={setConfirmationMessageComponentsWrapped}
              onPreview={(children) => (
                <PageContextProvider value={pageContext}>
                  <FormContextProvider value={formContext}>{children}</FormContextProvider>
                </PageContextProvider>
              )}
            />
          )}
        </div>
      )}
    </Fragment>
  );
});
