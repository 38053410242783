import { CompletableVideoInputComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from '../shared/types.js';

export const EditCompletableVideoInput: FunctionComponent<
  EditComponentContentProps<CompletableVideoInputComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editCompletableVideoSrc">Source URL</label>
      <InputText
        id="editCompletableVideoSrc"
        className="w-full"
        value={component.src || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, src: e.target.value }));
        }}
      />
    </div>
    <div className="input-field flex align-items-center gap-3">
      <Checkbox
        inputId="editCompletableVideoCollapseCompleted"
        checked={component.collapseCompleted || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, collapseCompleted: e.checked }));
        }}
      />
      <label htmlFor="editCompletableVideoCollapseCompleted">
        Collapse when video is completed
      </label>
    </div>
    <div className="input-field flex align-items-center gap-3">
      <Checkbox
        inputId="editCompletableVideoInitiallyCollapsed"
        checked={component.initiallyCollapsed || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, initiallyCollapsed: e.checked }));
        }}
      />
      <label htmlFor="editCompletableVideoInitiallyCollapsed">Show initially as collapsed</label>
    </div>
    <div className="input-field flex align-items-center gap-3">
      <Checkbox
        inputId="editCompletableVideoShowHeader"
        checked={component.showHeader || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, showHeader: e.checked }));
        }}
      />
      <label htmlFor="editCompletableVideoShowHeader">Show header (when expanded)</label>
    </div>
  </Fragment>
);
