export enum SiteOptionKey {
  AgreementConsentsRequired = 'AgreementConsentsRequired',
  AuthenticatedMainNavItems = 'AuthenticatedMainNavItems',
  ColorPrimary = 'ColorPrimary',
  ColorPrimaryDark = 'ColorPrimaryDark',
  ColorPrimaryLight = 'ColorPrimaryLight',
  ColorPrimaryH = 'ColorPrimaryH',
  ColorPrimaryS = 'ColorPrimaryS',
  ColorPrimaryL = 'ColorPrimaryL',
  ColorPrimaryDarkH = 'ColorPrimaryDarkH',
  ColorPrimaryDarkS = 'ColorPrimaryDarkS',
  ColorPrimaryDarkL = 'ColorPrimaryDarkL',
  FontFamily = 'FontFamily',
  GoogleTagManagerId = 'GoogleTagManagerId',
  // TODO: Replace with headEndCode.
  HeadJavaScriptCode = 'HeadJavaScriptCode',
  InputFieldFontFamily = 'InputFieldFontFamily',
  LogoIconUrl = 'LogoIconUrl',
  LogoUrl = 'LogoUrl',
  MetaPixelId = 'MetaPixelId',
  MixpanelToken = 'MixpanelToken',
  Name = 'Name',
  PrivacyPolicy = 'PrivacyPolicy',
  PrivacyPolicyUrl = 'PrivacyPolicyUrl',
  TermsOfUse = 'TermsOfUse',
  TermsOfUseUrl = 'TermsOfUseUrl',
  SidebarExtraItems = 'SidebarExtraItems',
  StripePublishableKey = 'StripePublishableKey',
  TextColorPrimaryH = 'TextColorPrimaryH',
  TextColorPrimaryS = 'TextColorPrimaryS',
  TextColorPrimaryL = 'TextColorPrimaryL',
  ZendeskChatKey = 'ZendeskChatKey',
  // TODO: Refactor these to be dynamic fields (using site or page props).
  AddressCity = 'AddressCity',
  AddressLine1 = 'AddressLine1',
  AddressLine2 = 'AddressLine2',
  AddressState = 'AddressState',
  AddressZip = 'AddressZip',
  AgentFirstName = 'AgentFirstName',
  AgentLastName = 'AgentLastName',
  AgentPhotoUrl = 'AgentPhotoUrl',
  GooglePlaceId = 'GooglePlaceId',
  PrimaryPhone = 'PrimaryPhone',
  UseCustomPrivacyPolicy = 'UseCustomPrivacyPolicy',
  UseCustomTermsOfUse = 'UseCustomTermsOfUse',
}

export enum SiteOptionSitesKey {
  Domain = 'Domain',
}

// All versioned fields, including fields that are stored directly in Sites and fields stored in SiteOptions.
export type SiteOptionVersionKey = SiteOptionKey | SiteOptionSitesKey;

const siteOptions = Object.values(SiteOptionKey);

export const isSiteOptionKey = (value: unknown): value is SiteOptionKey =>
  siteOptions.includes(value as never);

export const defaultFontFamily = 'Poppins, "Helvetica Neue", Helvetica, "Noto Sans", sans-serif';
