import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Fragment, FunctionComponent, PropsWithChildren, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, NavLink } from 'react-router-dom';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import { useOnMount } from '../../hooks/useOnMount.js';
import styles from './PageContainer.module.css';

export type NavItem = {
  path: string;
  label: string;
};

type PageContainerProps = PropsWithChildren<{
  title?: string;
  pageMetaTitle?: string;
  backTo?: string;
  // If nav is null or undefined, no sidebar is rendered.
  nav?: NavItem[] | null;
  onLinkClick?: (to: string) => boolean;
}>;

export const PageContainer: FunctionComponent<PageContainerProps> = ({
  title,
  pageMetaTitle = title,
  backTo,
  nav,
  children,
  onLinkClick,
}) => {
  const { handle } = usePlatformContext();
  const container = useRef<HTMLDivElement>(null);
  const overflowElement = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    if (overflowElement.current) {
      overflowElement.current.style.display = 'none';
    }
  });

  return (
    <Fragment>
      <Helmet>
        <title>{pageMetaTitle}</title>
      </Helmet>
      <div ref={container} className="flex h-full flex-column md:flex-row">
        {!!nav?.length && (
          <div
            className={`${styles.subNav} relative border-bottom-1 md:border-bottom-none md:border-right-1 md:h-full background-white`}
          >
            <div className="p-3 md:pb-1">
              <div className="flex flex-row md:flex-column gap-1 overflow-y-auto">
                {nav.map((item) => (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    onMouseEnter={(event) => {
                      const self = event.target as HTMLAnchorElement;
                      const { current: containerElem } = container;
                      if (containerElem && overflowElement.current) {
                        const containerRect = containerElem.getBoundingClientRect();
                        const selfRect = self.getBoundingClientRect();
                        const nextTop = selfRect.top - containerRect.top;
                        if (
                          nextTop > 0
                          // TODO: Account for scrolling?
                          // nextTop <= (containerElem.clientHeight)
                        ) {
                          overflowElement.current.innerText = item.label;
                          overflowElement.current.style.top = `${nextTop}px`;
                          overflowElement.current.style.display = 'block';
                          overflowElement.current.style.minWidth = `${self.clientWidth}px`;
                        }
                      }
                    }}
                    onMouseLeave={() => {
                      if (overflowElement.current) {
                        overflowElement.current.style.display = 'none';
                      }
                    }}
                    className={clsx(
                      styles.menuItem,
                      'font-medium text-color-body px-3 border-round-md',
                    )}
                    onClick={(e) => {
                      if (onLinkClick && !onLinkClick(item.path)) {
                        e.preventDefault();
                      }
                      if (overflowElement.current) {
                        overflowElement.current.style.display = 'none';
                      }
                    }}
                    end
                  >
                    {item.label}
                  </NavLink>
                ))}
                <div
                  ref={overflowElement}
                  className={`${styles.absoluteText} font-medium px-3 border-round-md`}
                />
              </div>
            </div>
          </div>
        )}
        <main className="flex-grow-1 overflow-y-auto p-3 xl:p-4">
          {title && (
            <div className="flex align-items-center mb-3 gap-3">
              {backTo && (
                <Link
                  to={`/dashboard/${handle}${backTo}`}
                  className="flex align-items-center gap-1 mb-px"
                  onClick={(e) => {
                    if (onLinkClick && !onLinkClick(backTo)) {
                      e.preventDefault();
                    }
                  }}
                >
                  <i className={PrimeIcons.ARROW_LEFT} />
                  <span className="font-medium text-sm">Back</span>
                </Link>
              )}
              <h1 className={styles.pageTitle}>{title}</h1>
            </div>
          )}
          {children}
        </main>
      </div>
    </Fragment>
  );
};
