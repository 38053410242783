import type { ApolloClient } from '@apollo/client';
import {
  isComponentWithConditionalStyles,
  isComponentWithDefaultBooleanValue,
  isComponentWithDefaultStringValue,
  isComponentWithElementId,
  isComponentWithLabel,
  isComponentWithMaxLength,
  isComponentWithName,
  isComponentWithoutWrapper,
  isComponentWithRequired,
  isComponentWithSlot,
  isComponentWithStringValue,
} from '@wirechunk/lib/mixer/types/categories.js';
import { Component, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { EditComponent, editComponentByType } from '../editComponentByType.js';
import { ClassNameTab } from './tabs/class-name-tab.js';
import { ConditionalStylesTab } from './tabs/conditional-styles-tab.js';
import { DataInputTableColumnOptionsTab } from './tabs/data-input-table-column-options-tab.js';
import { DefaultBooleanValueTab } from './tabs/default-boolean-value-tab.js';
import { DefaultStringValueTab } from './tabs/default-string-value-tab.js';
import { ElementIdTab } from './tabs/element-id-tab.js';
import { HoverStylesTab } from './tabs/hover-styles-tab.js';
import { LabelTab } from './tabs/label-tab.js';
import { MaxLengthTab } from './tabs/max-length-tab.js';
import { NameTab } from './tabs/name-tab.js';
import { RequiredTab } from './tabs/required-tab.js';
import { SlotTab } from './tabs/slot-tab.js';
import { StringValueTab } from './tabs/string-value-tab.js';
import { StylesTab } from './tabs/styles-tab.js';

export type Tab = { label: string; component: EditComponent };

const stylesTab: Tab = {
  label: 'Styles',
  component: StylesTab,
};

const hoverStylesTab: Tab = {
  label: 'Hover styles',
  component: HoverStylesTab,
};

const conditionalStylesTab: Tab = {
  label: 'Conditional styles',
  component: ConditionalStylesTab,
};

const elementIdTab: Tab = {
  label: 'ID',
  component: ElementIdTab,
};

const classNameTab: Tab = {
  label: 'Classes',
  component: ClassNameTab,
};

const nameTab: Tab = {
  label: 'Name',
  component: NameTab,
};

const labelTab: Tab = {
  label: 'Label',
  component: LabelTab,
};

const stringValueTab: Tab = {
  label: 'Value',
  component: StringValueTab,
};

const defaultBooleanValueTab: Tab = {
  label: 'Default value',
  component: DefaultBooleanValueTab,
};

const defaultStringValueTab: Tab = {
  label: 'Default value',
  component: DefaultStringValueTab,
};

const requiredTab: Tab = {
  label: 'Require input',
  component: RequiredTab,
};

const maxLengthTab: Tab = {
  label: 'Maximum length',
  component: MaxLengthTab,
};

const dataInputTableColumnOptionsTab: Tab = {
  label: 'Table column options',
  component: DataInputTableColumnOptionsTab,
};

const slotTab: Tab = {
  label: 'Slot',
  component: SlotTab,
};

export const buildTabs = (
  component: Component,
  parent: Component | null,
  client: ApolloClient<object>,
): Tab[] => {
  const tabs: Tab[] = [];

  const Edit = editComponentByType(component, client);
  if (Edit) {
    tabs.push({
      label: 'Content',
      component: Edit,
    });
  }

  if (!isComponentWithoutWrapper(component)) {
    tabs.push(stylesTab, hoverStylesTab);
    if (isComponentWithConditionalStyles(component)) {
      tabs.push(conditionalStylesTab);
    }

    if (isComponentWithElementId(component)) {
      tabs.push(elementIdTab);
    }

    tabs.push(classNameTab);
  }

  if (isComponentWithName(component)) {
    tabs.push(nameTab);
  }

  if (isComponentWithLabel(component)) {
    tabs.push(labelTab);
  }

  if (isComponentWithStringValue(component)) {
    tabs.push(stringValueTab);
  }

  if (isComponentWithDefaultBooleanValue(component)) {
    tabs.push(defaultBooleanValueTab);
  }

  if (isComponentWithDefaultStringValue(component)) {
    tabs.push(defaultStringValueTab);
  }

  if (isComponentWithRequired(component)) {
    tabs.push(requiredTab);
  }

  if (isComponentWithMaxLength(component)) {
    tabs.push(maxLengthTab);
  }

  if (parent?.type === ComponentType.DataInputTable) {
    tabs.push(dataInputTableColumnOptionsTab);
  }

  if (parent && isComponentWithSlot(parent)) {
    tabs.push(slotTab);
  }

  return tabs;
};
