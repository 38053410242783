export const tagOptions = [
  'span',
  'div',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'label',
  'section',
  'ul',
  'ol',
  'li',
  'code',
];

type Tag = (typeof tagOptions)[number];

export const isTag = (value: unknown): value is Tag => tagOptions.includes(value as never);
