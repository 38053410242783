import { useMemo } from 'react';
import { SelectItem } from '../../types.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import { useStageBlueprints } from '../useStageBlueprints/useStageBlueprints.js';

type StageBlueprintOptions = {
  // options includes only non-deleted StageBlueprints.
  options: Array<SelectItem<string>>;
  loading: boolean;
};

export const useStageBlueprintOptions = (
  planId: string,
  onError: ErrorHandler['onError'],
): StageBlueprintOptions => {
  const { stageBlueprints, loading } = useStageBlueprints(planId, onError);

  const options = useMemo(
    () =>
      stageBlueprints
        .filter((stageBlueprint) => !stageBlueprint.deletedAt)
        .map<SelectItem<string>>((stageBlueprint) => ({
          label: stageBlueprint.name,
          value: stageBlueprint.id,
        })),
    [stageBlueprints],
  );

  return {
    options,
    loading,
  };
};
