import { useMutation } from '@apollo/client';
import {
  ConfirmationAction as ConfirmationActionType,
  FormConfirmationRedirectUrlParameterInput,
} from '@wirechunk/lib/api.js';
import { confirmationActions, isConfirmationAction } from '@wirechunk/lib/forms.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormContextProvider } from '../../../../../../../contexts/FormContext/form-context.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../contexts/PageContext/PageContext.js';
import { useToast } from '../../../../../../../contexts/ToastContext.js';
import { useValidInputComponents } from '../../../../../../../hooks/use-valid-input-components.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../hooks/useHasUnsavedChanges.js';
import { InputNotice } from '../../../../../../InputNotice/InputNotice.js';
import { VisualBuilder } from '../../../../../../VisualBuilder/VisualBuilder.js';
import { withEditFormContext } from './edit-form-context.js';
import { EditUrlParameters } from './edit-url-parameters.js';
import { EditFormDocument } from './mutations.generated.js';

const pageContext: PageContext = {
  title: '',
  viewMode: ViewMode.Preview,
};

export const ConfirmationAction = withEditFormContext(({ form, formContext, site: { site } }) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();

  const [editForm, { loading: saving, client: apolloClient }] = useMutation(EditFormDocument, {
    onError,
    onCompleted: () => {
      toastSuccess('Form saved.');
      resetHasUnsavedChanges();
    },
  });

  const components = useMemo(() => {
    const allComponents = parseComponents(form.components);
    for (const step of form.steps) {
      allComponents.push(...parseComponents(step.components));
    }
    return allComponents;
  }, [form.components, form.steps]);

  const [confirmationAction, setConfirmationAction] = useState<ConfirmationActionType>(
    ConfirmationActionType.Message,
  );
  const [confirmationRedirectUrl, setConfirmationRedirectUrl] = useState<string | null>(null);
  const [confirmationRedirectUrlParameters, setConfirmationRedirectUrlParameters] = useState<
    FormConfirmationRedirectUrlParameterInput[]
  >([]);
  const [confirmationMessageComponents, setConfirmationMessageComponents] = useState<Component[]>(
    [],
  );

  const { inputComponents, loading: loadingInputComponents } = useValidInputComponents(
    components,
    apolloClient,
  );

  const setConfirmationMessageComponentsWrapped = useCallback<
    Dispatch<SetStateAction<Component[]>>
  >(
    (component) => {
      setConfirmationMessageComponents(component);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  useEffect(() => {
    setConfirmationAction(form.confirmationAction);
    setConfirmationRedirectUrl(form.confirmationRedirectUrl || null);
    setConfirmationRedirectUrlParameters(
      form.confirmationRedirectParameters.map(({ __typename: _ignored, ...rest }) => rest),
    );
    setConfirmationMessageComponents(
      form.confirmationMessageComponents ? parseComponents(form.confirmationMessageComponents) : [],
    );
  }, [
    form.confirmationAction,
    form.confirmationRedirectUrl,
    form.confirmationRedirectParameters,
    form.confirmationMessageComponents,
  ]);

  return (
    <Fragment>
      <ErrorMessage />
      <div className="flex flex-column gap-2 mt-3">
        <Button
          className="p-button-success w-max"
          label="Save"
          disabled={!hasUnsavedChanges || saving}
          onClick={() => {
            clearMessages();
            void editForm({
              variables: {
                id: form.id,
                title: form.title,
                confirmationAction,
                confirmationRedirectUrl,
                components: form.components,
                confirmationMessageComponents: JSON.stringify(confirmationMessageComponents),
                confirmationRedirectUrlParameters,
              },
            });
          }}
        />
        <div className="input-field mb-1">
          <label htmlFor="formConfirmationAction">Confirmation action</label>
          <Dropdown
            inputId="formConfirmationAction"
            className="w-full"
            options={confirmationActions}
            value={confirmationAction}
            onChange={({ value }) => {
              if (isConfirmationAction(value)) {
                setConfirmationAction(value);
                triggerHasUnsavedChanges();
              }
            }}
          />
        </div>
        {confirmationAction === ConfirmationActionType.Redirect ? (
          <Fragment>
            <div className="input-field mb-1">
              <label htmlFor="formConfirmationRedirectUrl">Confirmation redirect URL</label>
              <InputText
                id="formConfirmationRedirectUrl"
                className="w-full"
                value={confirmationRedirectUrl || ''}
                onChange={(e) => {
                  setConfirmationRedirectUrl(e.target.value);
                  triggerHasUnsavedChanges();
                }}
              />
              <InputNotice>
                Note that, if the URL starts with a slash, the user will be redirected to a page on
                this same site.
              </InputNotice>
            </div>
            <div>
              <div className="font-medium mb-1">Confirmation redirect URL query parameters</div>
              <InputNotice>
                You can select input components whose value will be passed along with the redirect.
              </InputNotice>
            </div>
            {confirmationRedirectUrlParameters.map((param) => (
              <EditUrlParameters
                key={param.id}
                param={param}
                setConfirmationRedirectUrlParameters={setConfirmationRedirectUrlParameters}
                inputComponents={inputComponents}
                loadingInputComponentNames={loadingInputComponents}
                triggerHasUnsavedChanges={triggerHasUnsavedChanges}
              />
            ))}
            <Button
              label="Add parameter"
              className="w-max"
              onClick={() => {
                setConfirmationRedirectUrlParameters((params) => [
                  ...params,
                  {
                    // id must be a UUID.
                    id: window.crypto.randomUUID(),
                    parameter: '',
                    componentName: '',
                  },
                ]);
                triggerHasUnsavedChanges();
              }}
            />
          </Fragment>
        ) : (
          <div>
            <VisualBuilder
              siteContext={site}
              components={confirmationMessageComponents}
              setComponents={setConfirmationMessageComponentsWrapped}
              onPreview={(children) => (
                <PageContextProvider value={pageContext}>
                  <FormContextProvider value={formContext}>{children}</FormContextProvider>
                </PageContextProvider>
              )}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
});
