import { SiteOptionKey } from '@wirechunk/lib/site-options.js';
import { intersection, isEqual } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { OptionsSection } from '../../../../../OptionsSection.js';
import { NavDesign } from './nav-design/nav-design.js';
import type { NavLinkItem } from './nav-design/nav-item.js';
import type { SiteSettingsSectionProps } from './types.js';

export const SidebarExtrasSection: FunctionComponent<SiteSettingsSectionProps> = ({
  site,
  savingKeys,
  saveOptions,
}) => {
  const [items, setItems] = useState<NavLinkItem[]>(site.sidebarExtraItems || []);

  useEffect(() => {
    setItems(site.sidebarExtraItems || []);
  }, [site.sidebarExtraItems]);

  return (
    <OptionsSection
      title="Sidebar extras panel"
      allowSave={
        intersection(savingKeys, [SiteOptionKey.SidebarExtraItems]).length === 0 &&
        !isEqual(items, site.sidebarExtraItems || [])
      }
      getOptions={() => [{ key: SiteOptionKey.SidebarExtraItems, value: items }]}
      saveOptions={saveOptions}
    >
      <NavDesign platformId={site.platformId} items={items} setItems={setItems} />
    </OptionsSection>
  );
};
