import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { DocumentsDocument, DocumentsQuery } from './queries.generated.js';

type Documents = {
  documents: DocumentsQuery['documents'] | null;
  loading: boolean;
};

export const useDocuments = (platformId: string, onError: ErrorHandler['onError']): Documents => {
  const { data, loading } = useQuery(DocumentsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId },
  });

  return {
    documents: data?.documents || null,
    loading,
  };
};
