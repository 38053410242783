import { clsx } from 'clsx';
import { Checkbox } from 'primereact/checkbox';
import { FormTarget } from 'primereact/ts-helpers';
import type { FunctionComponent } from 'react';
import { InputNotice } from '../InputNotice/InputNotice.js';

type CheckboxWithLabelProps = {
  id: string;
  label: string;
  checked: boolean;
  onChange: (event: { checked: boolean; target: FormTarget }) => void;
  inputNotice?: string;
};

export const CheckboxWithLabel: FunctionComponent<CheckboxWithLabelProps> = ({
  id,
  label,
  checked,
  onChange,
  inputNotice,
}) => (
  <div className="input-field">
    <div className={clsx('flex align-items-center gap-3', inputNotice && 'mb-1')}>
      <Checkbox
        inputId={id}
        checked={checked}
        onChange={({ checked, target }) => {
          onChange({ checked: !!checked, target });
        }}
      />
      <label htmlFor={id} className="m-0">
        {label}
      </label>
    </div>
    {inputNotice && <InputNotice>{inputNotice}</InputNotice>}
  </div>
);
