import { useCallback, useMemo, useState } from 'react';

export type HasUnsavedChanges = {
  hasUnsavedChanges: boolean;
  triggerHasUnsavedChanges: () => void;
  resetHasUnsavedChanges: () => void;
};

export const useHasUnsavedChanges = (): HasUnsavedChanges => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const triggerHasUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(true);
  }, []);

  const resetHasUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(false);
  }, []);

  return useMemo<HasUnsavedChanges>(
    () => ({
      hasUnsavedChanges,
      triggerHasUnsavedChanges,
      resetHasUnsavedChanges,
    }),
    [hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges],
  );
};
