import { useMutation } from '@apollo/client';
import { clsx } from 'clsx';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { EditableMode } from '../../../EditableMode/EditableMode.js';
import { EditUserEmailDocument } from './mutations.generated.js';
import type { UserRow } from './types.js';

type UserEmailCellProps = {
  user: UserRow;
  isSavingUserEdits: boolean;
  onError: ErrorHandler['onError'];
};

export const UserEmailCell: FunctionComponent<UserEmailCellProps> = ({
  user,
  isSavingUserEdits,
  onError,
}) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const [editUserEmail, { loading: isSaving }] = useMutation(EditUserEmailDocument, {
    onError,
  });
  const [newEmail, setNewEmail] = useState(user.email);

  const onCancelEdit = useCallback(() => {
    setNewEmail(user.email);
  }, [user.email]);

  return (
    <EditableMode
      // This key prop forces the component to remount when the email address change is saved so that the state is reset
      // to view mode.
      key={user.email}
      editMode={() => (
        <InputText
          className="w-full"
          value={newEmail}
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
        />
      )}
      onSave={() => {
        if (newEmail && newEmail !== user.email) {
          dialog({
            confirm: (
              <Fragment>
                Are you sure you want to change {user.displayName}’s email address to{' '}
                <b>{newEmail}</b>?
              </Fragment>
            ),
            props: {
              onAccept: () => {
                void editUserEmail({
                  variables: {
                    id: user.id,
                    email: newEmail,
                  },
                  onCompleted: () => {
                    toastSuccess('Email updated.');
                  },
                });
              },
            },
          });
        }
      }}
      onCancel={onCancelEdit}
    >
      <div
        className={clsx('flex flex-column', (isSaving || isSavingUserEdits) && 'text-color-muted')}
      >
        <span
          className="text-overflow-ellipsis overflow-x-hidden cursor-pointer"
          onClick={() => {
            void navigator.clipboard.writeText(user.email);
            toastSuccess('Copied email address to clipboard.');
          }}
        >
          {user.email}
        </span>
        {!user.isEmailVerified && (
          <span className="text-xs font-medium" style={{ marginTop: '2px' }}>
            Email not verified
          </span>
        )}
      </div>
    </EditableMode>
  );
};
