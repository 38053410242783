import { FileTypeCategory } from '@wirechunk/lib/mixer/types/components.js';
import type { FocusEvent } from 'react';
import type { SelectItem } from '../types.js';

export const blur = (evt: FocusEvent<HTMLInputElement>): void => {
  evt.target.blur();
};

// Uploads get 7 minutes to complete. We add a skew in case timing any part of the process isn't precise.
export const fileUploadTimeoutMs = 7 * 60 * 1_000 + 2_000;

export const fileTypeCategoryOptions: Array<SelectItem<FileTypeCategory>> = [
  { label: FileTypeCategory.Audio, value: FileTypeCategory.Audio },
  { label: FileTypeCategory.Image, value: FileTypeCategory.Image },
  { label: FileTypeCategory.Video, value: FileTypeCategory.Video },
];

export const isFileTypeCategory = (value: unknown): value is FileTypeCategory =>
  Object.values(FileTypeCategory).includes(value as never);

export const inputAcceptProp = (accept: FileTypeCategory[]): string =>
  accept.map((a) => `${a.toLowerCase()}/*`).join(',');

export const mimeTypeToFileTypeCategory = (mimeType: string): FileTypeCategory | null => {
  const [type] = mimeType.split('/');
  switch (type) {
    case 'audio':
      return FileTypeCategory.Audio;
    case 'image':
      return FileTypeCategory.Image;
    case 'video':
      return FileTypeCategory.Video;
    default:
      return null;
  }
};

export const isAudioMimeType = (mimeType: string): boolean => mimeType.startsWith('audio/');

export const isVideoMimeType = (mimeType: string): boolean => mimeType.startsWith('video/');
