import type { DataInputTableComponent } from '@wirechunk/lib/mixer/types/components.js';
import { isDataTableRowArray, ValidInputComponent } from '@wirechunk/lib/mixer/utils.js';
import type { DataTableRow } from '@wirechunk/schemas/context-data/context-data';
import { FunctionComponent, PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import { InputData, InputDataContextProvider } from './InputDataContext.js';

type RepeatedFormFragmentContextProviderProps = PropsWithChildren<{
  id: string;
  // Whether this element is simply an empty placeholder that becomes a real record when edited.
  emptyPlaceholder: boolean;
  // The parent InputData context value.
  inputDataContext: InputData;
  parent: ValidInputComponent<DataInputTableComponent>;
}>;

// TODO: Validation
export const RepeatedFormFragmentContextProvider: FunctionComponent<
  RepeatedFormFragmentContextProviderProps
> = ({ id, emptyPlaceholder, inputDataContext, parent, children }) => {
  const inputComponents = useRef(new Map<string, ValidInputComponent>()).current;

  const getValue = useCallback<InputData['getValue']>(
    (component) => {
      const tableValue = inputDataContext.getValue(parent);
      if (isDataTableRowArray(tableValue)) {
        const dataRow = tableValue.find((r) => r.id === id);
        if (dataRow) {
          return dataRow.data[component.name] ?? null;
        }
      }
      return null;
    },
    [inputDataContext, id, parent],
  );

  const setValue = useCallback<InputData['setValue']>(
    (component, value) => {
      const tableValue = inputDataContext.getValue(parent);
      if (isDataTableRowArray(tableValue) || tableValue === null) {
        if (emptyPlaceholder) {
          inputDataContext.setValue(parent, [
            ...(tableValue || []),
            {
              id,
              data: { [component.name]: value },
            },
          ]);
        } else {
          inputDataContext.setValue(
            parent,
            (tableValue || []).map(
              (r): DataTableRow =>
                r.id === id ? { ...r, data: { ...r.data, [component.name]: value } } : r,
            ),
          );
        }
      }
    },
    [inputDataContext, emptyPlaceholder, id, parent],
  );

  const innerInputDataContextValue = useMemo<InputData>(
    () => ({
      ...inputDataContext,
      inputComponents,
      getValue,
      setValue,
    }),
    [inputDataContext, inputComponents, getValue, setValue],
  );

  return (
    <InputDataContextProvider value={innerInputDataContextValue}>
      {children}
    </InputDataContextProvider>
  );
};
