import { InputNumber } from 'primereact/inputnumber';
import { Fragment } from 'react';
import type { EditComponent } from '../../editComponentByType.js';

export const MaxLengthTab: EditComponent = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editMaxLengthTabMaxLength">Maximum length</label>
        <InputNumber
          inputId="editMaxLengthTabMaxLength"
          className="w-6rem"
          value={component.maxLength || null}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              maxLength: e.value,
            }));
          }}
        />
      </div>
    </Fragment>
  );
};
