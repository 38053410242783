import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { FormEntriesQuery, FormEntriesDocument } from './queries.generated.js';

export type FormEntry = FormEntriesQuery['formEntries']['entries'][number];

export type FormEntries = {
  entries: FormEntry[] | null;
  // The totalCount returned from the latest (current) query.
  totalCount: number | null;
  loading: boolean;
};

export const useFormEntries = (
  {
    siteId,
    formId,
    fromDate,
    toDate,
    page,
    limit,
  }: {
    siteId: string;
    formId: string | null;
    fromDate: Date | null;
    toDate: Date | null;
    page: number;
    limit: number;
  },
  onError: ErrorHandler['onError'],
): FormEntries => {
  const { data, loading } = useQuery(FormEntriesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      siteId,
      formId,
      fromDate,
      toDate,
      page,
      limit,
    },
  });

  return {
    entries: data?.formEntries.entries || null,
    totalCount: data?.formEntries.totalCount ?? null,
    loading,
  };
};
