import type { AppPageTilesComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent } from 'react';
import type { EditComponentContentProps } from '../shared/types.js';

export const EditAppPageTiles: FunctionComponent<
  EditComponentContentProps<AppPageTilesComponent>
> = ({ component, setComponent }) => (
  <div className="input-field">
    <label htmlFor="editAppPageTilesPathPrefix">Path prefix</label>
    <InputText
      id="editAppPageTilesId"
      className="w-full"
      value={component.pathPrefix || ''}
      onChange={(e) => {
        setComponent((c) => ({ ...c, pathPrefix: e.target.value }));
      }}
    />
  </div>
);
