import { useMutation } from '@apollo/client';
import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types/index.js';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { clsx } from 'clsx';
import { generateKeyBetween } from 'fractional-indexing-base-26';
import { last, sortBy } from 'lodash-es';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { usePlatformContext } from '../../../../../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../../../../../contexts/ToastContext.js';
import { ErrorHandler, useErrorHandler } from '../../../../../../../../hooks/useErrorHandler.js';
import { Sortable } from '../../../../../../../sortable/sortable.js';
import { EditFormContext, withEditFormContext } from '../edit-form-context.js';
import {
  CreateFormStepDocument,
  DeleteFormStepDocument,
  EditFormStepDocument,
  EditFormStepPositionDocument,
} from './mutations.generated.js';

type StepType = NonNullable<EditFormContext['form']['steps']>[number];

const dragModifiers = [restrictToVerticalAxis, restrictToParentElement];

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type ListStepProps = {
  step: StepType;
  formId: string;
  siteId: string;
  previousStepPosition: string | null;
  nextStepPosition: string | null;
  dragOverlay?: boolean;
  refetchForm: EditFormContext['refetchForm'];
  onError: ErrorHandler['onError'];
};

const ListStep: FunctionComponent<ListStepProps> = ({
  step: { id, name, position, components, enabled },
  formId,
  siteId,
  dragOverlay = false,
  refetchForm,
  onError,
}) => {
  const { toastSuccess } = useToast();
  const dialog = useDialog();
  const { handle } = usePlatformContext();
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
  });
  const [editFormStep] = useMutation(EditFormStepDocument, {
    onError,
  });
  const [deleteFormStep, { loading: deleting }] = useMutation(DeleteFormStepDocument, {
    onError,
  });

  return (
    <Sortable
      className={clsx(
        'background-white border-1 p-3 border-round flex align-items-center gap-3',
        deleting && 'opacity-60',
      )}
      setNodeRef={setNodeRef}
      transform={transform}
      transition={transition}
      dragging={isDragging}
      dragOverlay={dragOverlay}
    >
      <Button
        className="flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body touch-action-manipulation cursor-grab"
        {...attributes}
        {...listeners}
      >
        <span className="material-symbols-outlined text-lg">drag_indicator</span>
      </Button>
      <Checkbox
        checked={enabled}
        // A tooltip is not added to the overlay so that it's not triggered while dragging.
        tooltip={dragOverlay ? undefined : 'Enable this step'}
        onChange={(e) => {
          const enabled = !!e.checked;
          void editFormStep({
            variables: {
              id,
              name,
              position,
              components,
              enabled,
            },
            optimisticResponse: {
              editFormStep: {
                __typename: 'FormStep',
                id,
                name,
                position,
                components,
                enabled,
              },
            },
            onCompleted: () => {
              toastSuccess(`${name} step ${enabled ? 'enabled' : 'disabled'}`);
            },
          });
        }}
      />
      <Link
        to={`/dashboard/${handle}/sites/${siteId}/forms/${formId}/steps/${id}`}
        className="font-medium flex-grow-1"
      >
        {name}
      </Link>
      <Button
        className="flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body"
        tooltip="Delete this step"
        tooltipOptions={{
          position: 'top',
          showDelay: 150,
        }}
        onClick={() => {
          dialog({
            confirm: `Are you sure you want to delete this step?`,
            props: {
              onAccept: () => {
                void deleteFormStep({
                  variables: {
                    id,
                  },
                  onCompleted: () => {
                    void refetchForm();
                    toastSuccess(`${name} step deleted`);
                  },
                });
              },
            },
          });
        }}
      >
        <span className="material-symbols-outlined text-lg">delete_forever</span>
      </Button>
    </Sortable>
  );
};

export const Steps = withEditFormContext(({ form, refetchForm, site }) => {
  const [showAddStep, setShowAddStep] = useState<boolean>(false);
  const [newStepName, setNewStepName] = useState<string>('');
  const { onError, ErrorMessage } = useErrorHandler();
  const [createFormStep, { loading: creatingStep }] = useMutation(CreateFormStepDocument, {
    onError,
    onCompleted: () => {
      void refetchForm();
      setShowAddStep(false);
      setNewStepName('');
    },
  });
  const [editFormStepPosition] = useMutation(EditFormStepPositionDocument, {
    onError,
  });

  const sortedSteps = useMemo(() => sortBy<StepType>(form.steps, 'position'), [form.steps]);

  const [activeItem, setActiveItem] = useState<StepType | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback(
    ({ active }: DragEndEvent) => {
      setActiveItem(sortedSteps.find(({ id }) => id === active.id) ?? null);
    },
    [sortedSteps],
  );

  const clearActiveItem = useCallback(() => {
    setActiveItem(null);
  }, []);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      clearActiveItem();

      if (over) {
        const overStep = sortedSteps.find(({ id }) => id === over.id);
        const activeStep = sortedSteps.find(({ id }) => id === active.id);
        if (overStep && activeStep) {
          if (overStep.position > activeStep.position) {
            const position = generateKeyBetween(
              overStep.position,
              sortedSteps.find(({ position }) => position > overStep.position)?.position ?? null,
            );
            void editFormStepPosition({
              variables: {
                id: activeStep.id,
                position,
              },
              optimisticResponse: {
                editFormStepPosition: {
                  __typename: 'FormStep',
                  id: activeStep.id,
                  position,
                },
              },
            });
          } else if (overStep.position < activeStep.position) {
            const position = generateKeyBetween(
              sortedSteps.findLast(({ position }) => position < overStep.position)?.position ??
                null,
              overStep.position,
            );
            void editFormStepPosition({
              variables: {
                id: activeStep.id,
                position,
              },
              optimisticResponse: {
                editFormStepPosition: {
                  __typename: 'FormStep',
                  id: activeStep.id,
                  position,
                },
              },
            });
          }
        }
      }
    },
    [clearActiveItem, editFormStepPosition, sortedSteps],
  );

  return (
    <div className="mt-3 flex flex-column gap-3">
      <ErrorMessage />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={clearActiveItem}
        modifiers={dragModifiers}
      >
        <Fragment>
          <SortableContext items={sortedSteps} strategy={verticalListSortingStrategy}>
            {sortedSteps.map((step, i) => (
              <ListStep
                key={step.id}
                step={step}
                formId={form.id}
                siteId={site.site.id}
                previousStepPosition={sortedSteps[i - 1]?.position ?? null}
                nextStepPosition={sortedSteps[i + 1]?.position ?? null}
                refetchForm={refetchForm}
                onError={onError}
              />
            ))}
          </SortableContext>
          {createPortal(
            <DragOverlay dropAnimation={dropAnimationConfig}>
              {activeItem && (
                <ListStep
                  step={activeItem}
                  formId={form.id}
                  siteId={site.site.id}
                  previousStepPosition={null}
                  nextStepPosition={null}
                  dragOverlay
                  refetchForm={refetchForm}
                  onError={onError}
                />
              )}
            </DragOverlay>,
            document.body,
          )}
        </Fragment>
      </DndContext>
      {showAddStep ? (
        <div className="flex gap-3 justify-content-start align-items-end">
          <div className="input-field mb-0 mr-4">
            <label htmlFor="stepName">Name</label>
            <InputText
              id="stepName"
              className="w-30rem max-w-full"
              value={newStepName}
              onChange={(e) => {
                setNewStepName(e.target.value);
              }}
            />
          </div>
          <Button
            label="Cancel"
            className="p-button-secondary"
            disabled={creatingStep}
            onClick={() => {
              setShowAddStep(false);
              setNewStepName('');
            }}
          />
          <Button
            label="Add"
            disabled={creatingStep || !newStepName.trim()}
            onClick={() => {
              void createFormStep({
                variables: {
                  formId: form.id,
                  name: newStepName.trim(),
                  position: generateKeyBetween(last(form.steps)?.position ?? null, null),
                },
              });
            }}
          />
        </div>
      ) : (
        <Button
          className="w-max"
          label="Add step"
          onClick={() => {
            setShowAddStep(true);
          }}
        />
      )}
    </div>
  );
});
