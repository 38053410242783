import {
  FileTypeCategory,
  StageFileInputComponent,
} from '@wirechunk/lib/mixer/types/components.js';
import { MultiSelect } from 'primereact/multiselect';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { fileTypeCategoryOptions, isFileTypeCategory } from '../../../../util/inputs.js';
import type { EditComponentContentProps } from '../shared/types.js';

const isFileTypeCategoriesArray = (value: unknown): value is FileTypeCategory[] =>
  Array.isArray(value) && value.every(isFileTypeCategory);

export const EditStageFileInput: FunctionComponent<
  EditComponentContentProps<StageFileInputComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editStageFileInputAllowedTypes">Allowed types</label>
      <MultiSelect
        inputId="editStageFileInputAllowedTypes"
        className="w-full"
        value={component.allowedTypes}
        options={fileTypeCategoryOptions}
        onChange={({ value }) => {
          if (isFileTypeCategoriesArray(value)) {
            setComponent((c) => ({ ...c, allowedTypes: value }));
          }
        }}
      />
    </div>
  </Fragment>
);
