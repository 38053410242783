/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type FormSubmissionActionsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type FormSubmissionActionsQuery = {
  form: {
    __typename: 'Form';
    id: string;
    submissionActions: Array<{
      __typename: 'FormSubmissionAction';
      id: string;
      createdAt: string;
      type: Types.FormSubmissionActionType;
      emailAddress?: string | null;
      emailSubject?: string | null;
      webhookUrl?: string | null;
    }>;
  };
};

export const FormSubmissionActionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FormSubmissionActions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionActions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FormSubmissionActionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FormSubmissionActionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormSubmissionAction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailSubject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'webhookUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormSubmissionActionsQuery, FormSubmissionActionsQueryVariables>;
