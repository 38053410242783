import {
  allExpressionTypes,
  ExpressionType,
  isIncompleteArrayExpression,
  isIncompleteBooleanExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { Map as MapType } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent } from 'react';
import { ExpressionBuilder } from '../ExpressionBuilder.js';
import type { BuilderProps } from './shared.js';

const arrayExpressionTypes: ExpressionType[] = [ExpressionType.Array];

export const Map: FunctionComponent<BuilderProps<MapType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div>
        <div className="font-medium mb-1">Array expression</div>
        <ExpressionBuilder
          id={`${id}-array-expression`}
          expression={expression.arrayExpression}
          setExpression={(arrayExpression) => {
            if (isIncompleteArrayExpression(arrayExpression)) {
              setExpression({
                ...expression,
                arrayExpression,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={arrayExpressionTypes}
        />
      </div>
      <div>
        <div className="font-medium mb-1">Mapping function</div>
        <ExpressionBuilder
          id={`${id}-map`}
          expression={expression.mapExpression}
          setExpression={(mapExpression) => {
            if (isIncompleteBooleanExpression(mapExpression)) {
              setExpression({
                ...expression,
                mapExpression,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={allExpressionTypes}
        />
      </div>
    </Fragment>
  );
};
