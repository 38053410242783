import type { BooleanLiteral as BooleanLiteralType } from '@wirechunk/schemas/expressions/expression';
import { Checkbox } from 'primereact/checkbox';
import { Fragment, FunctionComponent } from 'react';
import type { BuilderProps } from './shared.js';

export const BooleanLiteral: FunctionComponent<BuilderProps<BooleanLiteralType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div className="input-field mb-0">
        <label htmlFor={`${id}-booleanLiteral`}>Boolean literal value</label>
        <Checkbox
          inputId={`${id}-booleanLiteral`}
          checked={expression.value ?? false}
          onChange={(e) => {
            setExpression({
              ...expression,
              value: !!e.checked,
            });
          }}
        />
      </div>
    </Fragment>
  );
};
