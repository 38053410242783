import type { EditComponent } from '../../editComponentByType.js';
import { StylesEditor } from '../../styles-editor.js';

export const HoverStylesTab: EditComponent = (props) => (
  <StylesEditor
    {...props}
    styles={props.component.hoverStyles || {}}
    setStyles={(setHoverStyles) => {
      props.setComponent((component) => ({
        ...component,
        hoverStyles: setHoverStyles(component.hoverStyles || {}),
      }));
    }}
  />
);
