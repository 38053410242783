import { useMutation, useQuery } from '@apollo/client';
import { currentDate } from '@wirechunk/lib/dates.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { Button } from 'primereact/button';
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  StageContext,
  StageContextProvider,
} from '../../../../../contexts/StageContext/StageContext.js';
import { useAutoCreateStage } from '../../../../../hooks/use-auto-create-stage/use-auto-create-stage.js';
import { useCurrentUserPlan } from '../../../../../hooks/useCurrentUserPlan/useCurrentUserPlan.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useSiteContextSelector } from '../../../../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { Spinner } from '../../../../Spinner.js';
import { VisualBuilder } from '../../../../VisualBuilder/VisualBuilder.js';
import { EditStageBlueprintComponentsDocument } from './mutations.generated.js';
import { StageBlueprintComponentsDocument } from './queries.generated.js';

type DayComponentsProps = {
  stageBlueprint: {
    id: string;
    planId: string;
    name: string;
  };
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
};

export const Components: FunctionComponent<DayComponentsProps> = ({
  stageBlueprint: { id, planId, name },
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { userPlan, loading: loadingCurrentUserPlan } = useCurrentUserPlan(planId, onError);
  const { stage, loading: loadingStage } = useAutoCreateStage(currentDate(), id, onError);
  const { data, loading } = useQuery(StageBlueprintComponentsDocument, {
    onError,
    variables: { id },
  });
  const [editStageBlueprintComponents, { loading: savingEdits }] = useMutation(
    EditStageBlueprintComponentsDocument,
    {
      onError,
    },
  );

  const stageBlueprint = data?.stageBlueprint;

  // We don't initialize the components to an empty array so that, if the query above fails, we don't display an
  // empty tree of components in the visual builder (instead we do not display the visual builder at all).
  const [components, setComponents] = useState<Component[] | null>(null);

  const setComponentsWrapped = useCallback<Dispatch<SetStateAction<Component[]>>>(
    (components) => {
      if (Array.isArray(components)) {
        setComponents(components);
      } else {
        setComponents((cs) => components(cs || []));
      }
      setHasUnsavedChanges(true);
    },
    [setHasUnsavedChanges],
  );

  useEffect(() => {
    if (stageBlueprint?.components) {
      setComponents(parseComponents(stageBlueprint.components));
    }
  }, [stageBlueprint?.components]);

  const siteContextSelector = useSiteContextSelector({
    onError,
  });

  const stageContext = useMemo<StageContext | null>(
    () =>
      stage && userPlan && components
        ? {
            id: stage.id,
            userId: stage.userId,
            date: stage.date,
            stageBlueprint: {
              id,
              planId,
              name,
              components,
            },
            userPlan,
            completed: stage.isCompleted,
            files: stage.files,
          }
        : null,
    [components, id, name, planId, stage, userPlan],
  );

  return (
    <Fragment>
      <ErrorMessage />
      {loading || loadingCurrentUserPlan || loadingStage ? (
        <Spinner />
      ) : (
        components &&
        stageContext && (
          <VisualBuilder
            siteContext={siteContextSelector}
            components={components}
            setComponents={setComponentsWrapped}
            onPreview={(children) => (
              <StageContextProvider value={stageContext}>{children}</StageContextProvider>
            )}
          />
        )
      )}
      <Button
        label="Save"
        className="mt-4"
        disabled={loading || savingEdits || !hasUnsavedChanges}
        onClick={() => {
          void editStageBlueprintComponents({
            variables: {
              id,
              components: JSON.stringify(components),
            },
            onCompleted: () => {
              setHasUnsavedChanges(false);
            },
          });
        }}
      />
    </Fragment>
  );
};
