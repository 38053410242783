import { useMutation } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { noop } from 'lodash-es';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { BasicIconButton } from '../../../../../BasicIconButton/BasicIconButton.js';
import { useDocumentDownloadPromptDialog } from '../../../../../mixer/document/use-document-download-prompt-dialog.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../Spinner.js';
import { navItems } from '../../nav.js';
import styles from './download-prompts.module.css';
import { EditPromptBody } from './edit-prompt-body.js';
import {
  CreateDocumentDownloadPromptDocument,
  EditDocumentDownloadPromptDocument,
} from './mutations.generated.js';
import { DocumentDownloadPromptsQuery } from './use-document-download-prompts/queries.generated.js';
import { useDocumentDownloadPrompts } from './use-document-download-prompts/use-document-download-prompts.js';

type CreatePromptProps = {
  platformId: string;
  onCreated: () => void;
  onCancel: () => void;
};

const CreatePrompt: FunctionComponent<CreatePromptProps> = ({
  platformId,
  onCreated,
  onCancel,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [createPrompt, { loading: creating }] = useMutation(CreateDocumentDownloadPromptDocument, {
    onError,
    onCompleted: () => {
      onCreated();
    },
  });

  return (
    <div className="border-1 border-round p-3">
      <ErrorMessage />
      <EditPromptBody
        loading={creating}
        onSave={(data) => {
          void createPrompt({
            variables: {
              platformId,
              ...data,
            },
          });
        }}
        onCancel={onCancel}
        onError={onError}
      />
    </div>
  );
};

type EditPromptProps = {
  prompt: DocumentDownloadPromptsQuery['documentDownloadPrompts'][number];
  onSaved: () => void;
  onCancel: () => void;
};

const EditPrompt: FunctionComponent<EditPromptProps> = ({ prompt, onSaved, onCancel }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [edit, { loading }] = useMutation(EditDocumentDownloadPromptDocument, {
    onError,
    onCompleted: () => {
      onSaved();
    },
  });

  return (
    <div>
      <ErrorMessage />
      <EditPromptBody
        prompt={prompt}
        loading={loading}
        onSave={(data) => {
          void edit({
            variables: {
              id: prompt.id,
              default: prompt.default,
              ...data,
            },
          });
        }}
        onCancel={onCancel}
        onError={onError}
      />
    </div>
  );
};

const defaultRowTooltipTargetClassName = 'default-prompt-label';

type RowProps = {
  prompt: DocumentDownloadPromptsQuery['documentDownloadPrompts'][number];
  canEdit: boolean;
  onEdited: () => void;
};

const Row: FunctionComponent<RowProps> = ({ prompt, canEdit, onEdited }) => {
  const { onErrorToast } = useErrorHandler();
  const tooltip = useRef<Tooltip>(null);
  const [showEdit, setShowEdit] = useState(false);
  const showPrompt = useDocumentDownloadPromptDialog(noop);
  const [edit, { loading }] = useMutation(EditDocumentDownloadPromptDocument, {
    onError: onErrorToast,
    onCompleted: () => {
      onEdited();
      tooltip.current?.updateTargetEvents(undefined);
    },
  });

  return (
    <div
      key={prompt.id}
      className={clsx(
        styles.row,
        canEdit && styles.canEdit,
        'border-bottom-1',
        showEdit ? 'py-3' : 'flex align-items-center py-2',
      )}
    >
      {showEdit ? (
        <EditPrompt
          prompt={prompt}
          onSaved={() => {
            onEdited();
            setShowEdit(false);
          }}
          onCancel={() => {
            setShowEdit(false);
          }}
        />
      ) : (
        <Fragment>
          <div className="flex align-items-center flex-grow-1">
            <span>{prompt.name}</span>
            {prompt.default ? (
              <div className="flex align-items-stretch">
                <span
                  className={`${defaultRowTooltipTargetClassName} ${styles.defaultPromptLabel} ml-3 flex align-items-center gap-1 bg-green-500 text-white py-1 px-2`}
                  data-pr-tooltip="This will automatically be set as the prompt for any new document"
                  data-pr-position="top"
                >
                  <span className="material-symbols-outlined text-lg">star</span>
                  <span className="text-sm font-medium">DEFAULT</span>
                </span>
                <Button
                  className={`${styles.clearDefaultPromptButton} button-not-styled p-1 background-danger-light hover:background-danger text-white`}
                  onClick={() => {
                    void edit({
                      variables: {
                        id: prompt.id,
                        default: false,
                        name: prompt.name,
                        promptHeader: prompt.promptHeader,
                        // We do this to avoid passing the __typename field to the server.
                        prompt: { delta: prompt.prompt.delta },
                        acceptLabel: prompt.acceptLabel,
                      },
                    });
                  }}
                  tooltip="Clear as default"
                  tooltipOptions={{ position: 'top' }}
                >
                  <span className="material-symbols-outlined">close</span>
                </Button>
              </div>
            ) : (
              <Button
                disabled={loading}
                className={`${styles.setDefaultPromptButton} ${defaultRowTooltipTargetClassName} button-not-styled p-1 ml-2 hover:text-green-500`}
                onClick={() => {
                  void edit({
                    variables: {
                      id: prompt.id,
                      default: true,
                      name: prompt.name,
                      promptHeader: prompt.promptHeader,
                      // We do this to avoid passing the __typename field to the server.
                      prompt: { delta: prompt.prompt.delta },
                      acceptLabel: prompt.acceptLabel,
                    },
                  });
                }}
                tooltip="Set as default"
                tooltipOptions={{ position: 'top' }}
              >
                <span className="material-symbols-outlined text-lg">star</span>
              </Button>
            )}
          </div>
          <BasicIconButton
            onClick={() => {
              showPrompt('', '', '', prompt);
            }}
          >
            <span className="material-symbols-outlined">preview</span>
          </BasicIconButton>
          {canEdit && (
            <BasicIconButton
              className="ml-2"
              onClick={() => {
                setShowEdit(true);
              }}
            >
              <span className="material-symbols-outlined">edit</span>
            </BasicIconButton>
          )}
        </Fragment>
      )}
      <Tooltip ref={tooltip} target={`.${defaultRowTooltipTargetClassName}`} />
    </div>
  );
};

export const DownloadPrompts: FunctionComponent = () => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { id: platformId, handle, permissions } = usePlatformContext();
  const { prompts, loading, refetch } = useDocumentDownloadPrompts(platformId, onError);
  const [showAdd, setShowAdd] = useState(false);
  const canEdit = permissions.includes(Permission.Edit);

  return (
    <PageContainer title="Document Download Prompts" nav={navItems(handle)}>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        prompts && (
          <Fragment>
            {showAdd ? (
              <CreatePrompt
                platformId={platformId}
                onCreated={() => {
                  setShowAdd(false);
                  void refetch();
                }}
                onCancel={() => {
                  setShowAdd(false);
                }}
              />
            ) : (
              canEdit && (
                <Button
                  label="Create download prompt"
                  onClick={() => {
                    setShowAdd(true);
                  }}
                />
              )
            )}
            {prompts.length ? (
              <div className="mt-3 border-top-1">
                {prompts.map((prompt) => (
                  <Row
                    key={prompt.id}
                    prompt={prompt}
                    canEdit={canEdit}
                    onEdited={() => {
                      void refetch();
                    }}
                  />
                ))}
              </div>
            ) : (
              <p className="mt-2">No document download prompts yet.</p>
            )}
          </Fragment>
        )
      )}
    </PageContainer>
  );
};
