import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Components, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.js';
import { useInputValidationErrorMessage } from './input-validation-error-message-context.js';

export const InputValidationErrorMessage: FunctionComponent<
  Components[ComponentType.InputValidationErrorMessage]
> = (props) => {
  const validationError = useInputValidationErrorMessage();
  return (
    validationError && (
      <InputNotice className={componentClassName(props)} severity={NoticeSeverity.Danger}>
        {validationError}
      </InputNotice>
    )
  );
};
