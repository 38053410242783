import { useQuery } from '@apollo/client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import type { MultiOrgViewComponent } from '@wirechunk/lib/mixer/types/components.js';
import { sortBy } from 'lodash-es';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { FunctionComponent, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { OrgDetails } from './org-details.js';
import { MultiOrgViewDocument, MultiOrgViewQuery } from './queries.generated.js';

type Agency = MultiOrgViewQuery['multiOrgView'][number];

export const MultiOrgView: FunctionComponent<MultiOrgViewComponent> = (props) => {
  const { user } = useCurrentUser();
  const organizationId = user.organization.id;
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(MultiOrgViewDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | any[]>({});

  const agencies = useMemo<Agency[]>(() => {
    if (data) {
      // The tilde '~' with the localeCompare will place agencies with no primary user at the end.
      return sortBy(data.multiOrgView, (org) => org.primaryUser?.displayName || '~').filter(
        (org) => org.id !== organizationId,
      );
    }
    return [];
  }, [data, organizationId]);

  const contentPlanIdsToShowProgress =
    props.showUserTrainingProgress && !!props.userTrainingProgressContentPlanIds?.length
      ? props.userTrainingProgressContentPlanIds
      : null;

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <DataTable
        dataKey="id"
        value={agencies}
        loading={loading}
        expandedRows={expandedRows}
        rowExpansionTemplate={(agency) => (
          <OrgDetails agency={agency} contentPlanIdsToShowProgress={contentPlanIdsToShowProgress} />
        )}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        emptyMessage="No data found"
        lazy
      >
        {contentPlanIdsToShowProgress && <Column expander className="table-left-action-column" />}
        <Column
          header="Agent"
          body={(agency: Agency) => {
            if (agency.primaryUser) {
              return (
                <div className="flex flex-column gap-1">
                  <span>{agency.primaryUser.displayName}</span>
                  <span className="text-sm text-color-muted">{agency.primaryUser.email}</span>
                </div>
              );
            }
            return '(No primary user)';
          }}
        />
        <Column header="Active members" body={(agency: Agency) => agency.members.length} />
      </DataTable>
    </div>
  );
};
