import { allExpressionTypes } from '@wirechunk/lib/expression-builder/evaluator.js';
import type { Exists as ExistsType } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent } from 'react';
import { ExpressionBuilder } from '../ExpressionBuilder.js';
import type { BuilderProps } from './shared.js';

export const Exists: FunctionComponent<BuilderProps<ExistsType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div>
        <div className="font-medium mb-1">Expression</div>
        <ExpressionBuilder
          id={`${id}-expression`}
          expression={expression.expression}
          setExpression={(expr) => {
            setExpression({
              ...expression,
              expression: expr,
            });
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={allExpressionTypes}
        />
      </div>
    </Fragment>
  );
};
