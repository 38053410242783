import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import {
  CalendlyEmbedComponent,
  CalendlyEmbedQueryParameter,
  CalendlyEmbedUrlQueryParameterValueSource,
  CalendlyEmbedUrlSource,
  DataSource,
} from '@wirechunk/lib/mixer/types/components.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { BasicIconButton } from '../../../BasicIconButton/BasicIconButton.js';
import { DataSourceDropdown } from '../data-source-dropdown.js';
import { EditComponentContentProps } from '../shared/types.js';

export const EditCalendlyEmbed: FunctionComponent<
  EditComponentContentProps<CalendlyEmbedComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <DataSourceDropdown
      parentId="editCalendlyEmbed"
      label="Calendly URL source"
      type={component.url?.type || DataSource.Direct}
      onChange={(type) => {
        setComponent(
          (c): CalendlyEmbedComponent => ({
            ...c,
            url:
              type === DataSource.Direct
                ? ({ type, url: '' } satisfies CalendlyEmbedUrlSource)
                : ({ type, name: '' } satisfies CalendlyEmbedUrlSource),
          }),
        );
      }}
    />
    {!component.url || component.url.type === DataSource.Direct ? (
      <div className="input-field">
        <label htmlFor="editCalendlyEmbedUrl">Calendly URL</label>
        <InputText
          id="editCalendlyEmbedUrl"
          className="w-full"
          value={component.url?.url || ''}
          onChange={(e) => {
            setComponent(
              (c): CalendlyEmbedComponent => ({
                ...c,
                url: { ...c.url, type: DataSource.Direct, url: e.target.value },
              }),
            );
          }}
        />
      </div>
    ) : (
      <div className="input-field">
        <label htmlFor="editCalendlyEmbedPropName">Prop name</label>
        <InputText
          id="editCalendlyEmbedPropName"
          className="w-full"
          value={component.url.name || ''}
          onChange={(e) => {
            setComponent(
              (c): CalendlyEmbedComponent => ({
                ...c,
                url: { ...c.url, type: DataSource.Prop, name: e.target.value },
              }),
            );
          }}
        />
      </div>
    )}
    <div className="flex flex-column gap-2">
      {component.queryParameters?.map((qp) => (
        <div key={qp.id} className="border-1 border-round p-2">
          <div className="flex align-items-start gap-2">
            <div className="input-field flex-grow-1">
              <label htmlFor={`editCalendlyEmbedQueryParameterName${qp.id}`}>Parameter name</label>
              <InputText
                id={`editCalendlyEmbedQueryParameterName${qp.id}`}
                value={qp.parameter}
                onChange={(e) => {
                  setComponent((c) => ({
                    ...c,
                    queryParameters: c.queryParameters?.map((p) =>
                      p.id === qp.id ? { ...p, parameter: e.target.value } : p,
                    ),
                  }));
                }}
              />
            </div>
            <BasicIconButton
              onClick={() => {
                setComponent((c) => ({
                  ...c,
                  queryParameters: c.queryParameters?.filter(({ id }) => id !== qp.id),
                }));
              }}
              icon={PrimeIcons.TRASH}
            />
          </div>
          <div className="flex gap-2">
            <DataSourceDropdown
              className="w-14rem"
              parentId={`editCalendlyEmbedQueryParameterType${qp.id}`}
              label="Value source"
              type={qp.value.type}
              onChange={(type) => {
                setComponent((c) => ({
                  ...c,
                  queryParameters: c.queryParameters?.map((p) =>
                    p.id === qp.id
                      ? ({
                          ...p,
                          value:
                            type === DataSource.Direct
                              ? ({
                                  ...p.value,
                                  type,
                                } satisfies CalendlyEmbedUrlQueryParameterValueSource)
                              : ({
                                  name: '',
                                  ...p.value,
                                  type,
                                } satisfies CalendlyEmbedUrlQueryParameterValueSource),
                        } satisfies CalendlyEmbedQueryParameter)
                      : p,
                  ),
                }));
              }}
            />
            {qp.value.type === DataSource.Direct ? (
              <div className="input-field flex-grow-1">
                <label htmlFor={`editCalendlyEmbedQueryParameterValueDirect${qp.id}`}>Value</label>
                <InputText
                  id={`editCalendlyEmbedQueryParameterValueDirect${qp.id}`}
                  className="w-full"
                  value={qp.value.value || ''}
                  onChange={(e) => {
                    setComponent(
                      (c): CalendlyEmbedComponent => ({
                        ...c,
                        queryParameters: c.queryParameters?.map((p) =>
                          p.id === qp.id
                            ? ({
                                ...p,
                                value: {
                                  ...p.value,
                                  type: DataSource.Direct,
                                  value: e.target.value,
                                },
                              } satisfies CalendlyEmbedQueryParameter)
                            : p,
                        ),
                      }),
                    );
                  }}
                />
              </div>
            ) : (
              <div className="input-field">
                <label htmlFor={`editCalendlyEmbedQueryParameterValuePropName${qp.id}`}>
                  Prop name
                </label>
                <InputText
                  id={`editCalendlyEmbedQueryParameterValuePropName${qp.id}`}
                  value={qp.value.name}
                  onChange={(e) => {
                    setComponent(
                      (c): CalendlyEmbedComponent => ({
                        ...c,
                        queryParameters: c.queryParameters?.map((p) =>
                          p.id === qp.id
                            ? ({
                                ...p,
                                value: { ...p.value, type: DataSource.Prop, name: e.target.value },
                              } satisfies CalendlyEmbedQueryParameter)
                            : p,
                        ),
                      }),
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      <Button
        label="Add query parameter"
        className="w-max"
        onClick={() => {
          setComponent(
            (c): CalendlyEmbedComponent => ({
              ...c,
              queryParameters: [
                ...(c.queryParameters || []),
                {
                  id: cleanSmallId(),
                  parameter: '',
                  value: { type: DataSource.Direct, value: '' },
                } satisfies CalendlyEmbedQueryParameter,
              ],
            }),
          );
        }}
      />
    </div>
  </Fragment>
);
