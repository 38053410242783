import { useSortable } from '@dnd-kit/sortable';
import { Role } from '@wirechunk/lib/api.js';
import { isString } from 'lodash-es';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FunctionComponent } from 'react';
import { SiteContext } from '../../../../../../../contexts/SiteContext/SiteContext.js';
import { roleOptions } from '../../../../../../../util/roles.js';
import { InputNotice } from '../../../../../../InputNotice/InputNotice.js';
import { Sortable } from '../../../../../../sortable/sortable.js';

export type NavLinkItem = Omit<NonNullable<SiteContext['sidebarExtraItems']>[number], '__typename'>;

type NavItemProps = {
  item: NavLinkItem;
  setItem: (item: NavLinkItem) => void;
  removeItem: () => void;
  duplicateItem: () => void;
  productItemOptions: string[];
  dragOverlay?: boolean;
};

export const NavItem: FunctionComponent<NavItemProps> = ({
  item,
  setItem,
  removeItem,
  duplicateItem,
  productItemOptions,
  dragOverlay = false,
}) => {
  const { id, label, url, external, featureTag, roles } = item;

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
  });

  return (
    <Sortable
      className="w-full border-1 border-round overflow-hidden background-white relative"
      setNodeRef={setNodeRef}
      transform={transform}
      transition={transition}
      dragging={isDragging}
      dragOverlay={dragOverlay}
    >
      <div className="flex align-items-center justify-content-end gap-2 py-2 px-3 surface-ground">
        <div className="flex-grow-1">
          <Button
            className="flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body touch-action-manipulation cursor-grab"
            {...attributes}
            {...listeners}
          >
            <span className="material-symbols-outlined text-lg">drag_indicator</span>
          </Button>
        </div>
        <Button
          className="flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body"
          onClick={duplicateItem}
        >
          <span className="material-symbols-outlined text-lg">content_copy</span>
        </Button>
        <Button
          className="flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body"
          onClick={removeItem}
        >
          <span className="material-symbols-outlined text-lg">delete_forever</span>
        </Button>
      </div>
      <div className="p-3">
        <div className="input-field">
          <label htmlFor={`itemLabel${id}`}>Label</label>
          <InputText
            id={`itemLabel${id}`}
            className="w-full"
            value={label}
            onChange={(e) => {
              setItem({ ...item, label: e.target.value });
            }}
          />
        </div>
        <div className="input-field">
          <label htmlFor={`itemUrl${id}`}>URL or path</label>
          <InputText
            id={`itemUrl${id}`}
            className="w-full"
            value={url}
            onChange={(e) => {
              setItem({ ...item, url: e.target.value });
            }}
          />
        </div>
        <div className="input-field flex align-items-center gap-3">
          <Checkbox
            inputId={`itemExternal${id}`}
            checked={external}
            onChange={(e) => {
              setItem({ ...item, external: !!e.checked });
            }}
          />
          <label htmlFor={`itemExternal${id}`} className="mb-0">
            External
          </label>
        </div>
        <div className="input-field">
          <label htmlFor={`itemProductItem${id}`}>Product item</label>
          <Dropdown
            inputId={`itemProductItem${id}`}
            className="w-full"
            value={featureTag || null}
            options={productItemOptions}
            showClear
            placeholder="No product item required"
            onChange={({ value }) => {
              if (isString(value)) {
                setItem({ ...item, featureTag: value });
              } else {
                setItem({ ...item, featureTag: null });
              }
            }}
          />
          <InputNotice>
            If you select a feature tag, only users who have the tag will see this item.
          </InputNotice>
        </div>
        <div className="input-field mb-0">
          <label htmlFor={`itemRoles${id}`}>Roles with access</label>
          <MultiSelect
            inputId={`itemRoles${id}`}
            className="w-full"
            value={roles || ''}
            options={roleOptions}
            placeholder="No role required"
            onChange={({ value }) => {
              if (Array.isArray(value)) {
                setItem({ ...item, roles: value as Role[] });
              }
            }}
          />
          <InputNotice>
            If you select any roles, only users with one of the selected roles will have access.
          </InputNotice>
        </div>
      </div>
    </Sortable>
  );
};
