import { useQuery } from '@apollo/client';
import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, useMemo, useRef } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {
  EditPageContext,
  EditPageContextProvider,
} from '../../../../../../../contexts/admin/edit-page-context/edit-page-context.js';
import { withEditSiteContext } from '../../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../../Spinner.js';
import { SiteHeader } from '../../site-header.js';
import { activeTab, PageTabs } from './page-tabs.js';
import { PageDocument } from './queries.generated.js';

const pageEditedLabelClassName = 'page-edited-label';

const PageEditedLabel: FunctionComponent = () => {
  const editedManuallyTooltip = useRef<Tooltip>(null);

  return (
    <Fragment>
      - <span className={`${pageEditedLabelClassName} font-bold`}>Edited</span>
      <Tooltip
        ref={editedManuallyTooltip}
        content="This page has been edited manually"
        target={`.${pageEditedLabelClassName}`}
      />
    </Fragment>
  );
};

type GuardedPageOwnProps = {
  id: string;
};

const GuardedPage = withEditSiteContext<GuardedPageOwnProps>(({ id, site, refetchSite }) => {
  const { pathname } = useLocation();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading, refetch } = useQuery(PageDocument, {
    onError,
    variables: { id },
  });
  const editPageContext = useMemo<EditPageContext | null>(
    () =>
      data
        ? {
            page: data.page,
            site: {
              site,
              refetchSite,
            },
            refetchPage: refetch,
          }
        : null,
    [data, refetch, site, refetchSite],
  );

  return (
    <Fragment>
      <SiteHeader domain={site.domain} name={site.name} />
      <PageContainer
        title={data ? `Page: ${data.page.title}` : 'Page'}
        // TODO: Replace with breadcrumbs
        backTo={`/sites/${site.id}/pages`}
      >
        <ErrorMessage />
        {data ? (
          <EditPageContextProvider value={editPageContext}>
            {data.page.template && (
              <div className="text-sm mb-2">
                Created from the template “{data.page.template.title}”
                {data.page.edited && (
                  <Fragment>
                    {' '}
                    <PageEditedLabel />
                  </Fragment>
                )}
              </div>
            )}
            <PageTabs siteId={site.id} pageId={id} activeTab={activeTab(id, pathname)}>
              <Outlet />
            </PageTabs>
          </EditPageContextProvider>
        ) : (
          loading && <Spinner />
        )}
      </PageContainer>
    </Fragment>
  );
});

export const Page: FunctionComponent = () => {
  const { pageId } = useParams<{ pageId: string }>();

  return pageId ? <GuardedPage id={pageId} /> : <p>Invalid page: missing pageId parameter.</p>;
};
