import { FormNotifications } from '../../../../../../../form-notifications/form-notifications.js';
import { withEditFormContext } from '../edit-form-context.js';

export const Notifications = withEditFormContext(({ form }) => {
  return (
    <div className="mt-3">
      <FormNotifications form={form} />
    </div>
  );
});
