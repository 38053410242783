import { noop, unionBy } from 'lodash-es';
import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SaveStatus } from './types.js';

type RegistryStage = {
  id: string;
  saveStatus: SaveStatus;
};

export type StagesRegistryContext = {
  setStages: Dispatch<SetStateAction<RegistryStage[]>>;
  isSaving: boolean;
  // allChangesSaved can possibly be true only if some change has been made. Initially it's false.
  allChangesSaved: boolean;
};

const context = createContext<StagesRegistryContext | null>(null);

export const StagesRegistryContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [stages, setStages] = useState<RegistryStage[]>([]);

  const isSaving = stages.some((s) => s.saveStatus === SaveStatus.Saving);

  const allChangesSaved = !isSaving && stages.some((s) => s.saveStatus === SaveStatus.Saved);

  const value = useMemo<StagesRegistryContext>(
    () => ({
      setStages,
      isSaving,
      allChangesSaved,
    }),
    [allChangesSaved, isSaving],
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useStagesRegistryContext = (): StagesRegistryContext | null => useContext(context);

type StagesRegistry = {
  setSaveStatus: (setState: (saveStatus: SaveStatus) => SaveStatus) => void;
  isSaving: boolean;
  // allChangesSaved can possibly be true only if some change has been made. Initially it's false.
  allChangesSaved: boolean;
};

const defaultStagesRegistry: StagesRegistry = {
  setSaveStatus: noop,
  isSaving: false,
  allChangesSaved: false,
};

export const useStagesRegistry = (stageId: string): StagesRegistry => {
  const stagesRegistry = useStagesRegistryContext();

  const setStages = stagesRegistry?.setStages;

  useEffect(() => {
    if (setStages) {
      setStages((stages) =>
        unionBy(stages, [{ id: stageId, saveStatus: SaveStatus.NoChanges }], (s) => s.id),
      );
      return () => {
        setStages((stages) => stages.filter((s) => s.id !== stageId));
      };
    }
    return noop;
  }, [stageId, setStages]);

  // Components downstream rely on this function remaining stable. Unless of course its dependencies change.
  const setSaveStatus = useCallback<StagesRegistry['setSaveStatus']>(
    (setState) => {
      setStages?.((stages) =>
        stages.map((stage) => ({
          ...stage,
          saveStatus: stage.id === stageId ? setState(stage.saveStatus) : stage.saveStatus,
        })),
      );
    },
    [setStages, stageId],
  );

  if (!stagesRegistry) {
    return defaultStagesRegistry;
  }

  return {
    setSaveStatus,
    isSaving: stagesRegistry.isSaving,
    allChangesSaved: stagesRegistry.allChangesSaved,
  };
};
