import { cleanSmallId } from '../clean-small-id.js';
import type { Component } from './types/components.js';
import { componentHasChildren } from './utils.js';

export const remapComponentIds = (component: Component): Component =>
  componentHasChildren(component)
    ? {
        ...component,
        id: cleanSmallId(),
        children: remapComponentsIds(component.children),
      }
    : {
        ...component,
        id: cleanSmallId(),
      };

// remapComponentsIds takes an array of Components and returns a copy of the array with all the IDs of the
// components deeply remapped to new random IDs. This is useful when duplicating something.
export const remapComponentsIds = (components: Component[]): Component[] =>
  components.map(remapComponentIds);
