import { useMutation, useQuery } from '@apollo/client';
import { Text, Tooltip } from '@radix-ui/themes';
import { Permission, SubscriptionPlan } from '@wirechunk/lib/api.js';
import { joinListTruncate } from '@wirechunk/lib/arrays.js';
import { formatDateTime } from '@wirechunk/lib/dates.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { invoiceFrequencyToHumanReadable } from '@wirechunk/lib/products.js';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { NoneLabel } from '../../../../NoneLabel/NoneLabel.js';
import { Spinner } from '../../../../Spinner.js';
import { EditProduct } from '../edit-product.js';
import { EditProductDocument } from './mutations.generated.js';
import {
  ProductItemOccurrencesDocument,
  ProductItemOccurrencesQuery,
  SubscriptionPlanDocument,
} from './queries.generated.js';

const itemOccurrencesMessage = (
  occurrences: ProductItemOccurrencesQuery['productItemOccurrences'],
) =>
  `Used by ${occurrences.pages.totalCount} ${pluralize(occurrences.pages.totalCount, 'page')}${
    occurrences.pages.totalCount > 0
      ? ` (${joinListTruncate(
          occurrences.pages.pages.map((p) => p.title),
          5,
        )})`
      : ''
  }, ${occurrences.documents.totalCount} ${pluralize(occurrences.documents.totalCount, 'document')}${
    occurrences.documents.totalCount > 0
      ? ` (${joinListTruncate(
          occurrences.documents.documents.map((d) => d.name),
          5,
        )})`
      : ''
  }`;

type ItemOccurrencesTooltipProps = {
  platformId: string;
  item: string;
};

const ItemOccurrencesTooltip: FunctionComponent<ItemOccurrencesTooltipProps> = ({
  platformId,
  item,
}) => {
  const { onErrorToast } = useErrorHandler();
  const { data, loading } = useQuery(ProductItemOccurrencesDocument, {
    onError: onErrorToast,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      item,
    },
  });
  return loading ? 'Loading...' : data && itemOccurrencesMessage(data.productItemOccurrences);
};

enum Mode {
  View,
  Edit,
}

type SubscriptionPlanDetailsProps = {
  subscriptionPlan: Pick<SubscriptionPlan, 'id'>;
  enableEdit: boolean;
};

export const ProductDetails: FunctionComponent<SubscriptionPlanDetailsProps> = ({
  subscriptionPlan: plan,
  enableEdit,
}) => {
  const { id: platformId, permissions } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const setModeView = useCallback(() => {
    setMode(Mode.View);
  }, []);

  const { data, loading } = useQuery(SubscriptionPlanDocument, {
    fetchPolicy: 'cache-and-network',
    onError,
    variables: { id: plan.id },
  });
  const [editProduct, { loading: isSavingEdits }] = useMutation(EditProductDocument, {
    onError,
    onCompleted: ({ editProduct: result }) => {
      if (result.__typename === 'EditProductSuccessResult') {
        setModeView();
      } else {
        onError(result.message);
      }
    },
  });
  const [mode, setMode] = useState<Mode>(Mode.View);

  const userCanEdit = permissions.includes(Permission.Edit);

  return (
    <Fragment>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        data &&
        (mode === Mode.View ? (
          <div className="flex flex-column gap-2">
            <div>
              Name: <Text weight="medium">{data.subscriptionPlan.name}</Text>
            </div>
            {data.subscriptionPlan.displayName && (
              <div>
                Display name: <Text weight="medium">{data.subscriptionPlan.displayName}</Text>
              </div>
            )}
            <div>
              Description: <Text weight="medium">{data.subscriptionPlan.description}</Text>
            </div>
            <div>
              Items:{' '}
              {data.subscriptionPlan.items.toSorted().map((item, i) => (
                <Fragment key={item}>
                  {i > 0 && ', '}
                  <Tooltip content={<ItemOccurrencesTooltip platformId={platformId} item={item} />}>
                    <Text weight="medium">{item}</Text>
                  </Tooltip>
                </Fragment>
              ))}
            </div>
            <div>
              Subscription end:{' '}
              {data.subscriptionPlan.endAt ? (
                <Fragment>
                  on <Text weight="medium">{formatDateTime(data.subscriptionPlan.endAt)}</Text>
                </Fragment>
              ) : data.subscriptionPlan.durationDays ? (
                <Fragment>
                  <Text weight="medium">
                    {data.subscriptionPlan.durationDays}{' '}
                    {pluralize(data.subscriptionPlan.durationDays, 'day')}
                  </Text>{' '}
                  after start
                </Fragment>
              ) : (
                <Text weight="medium">Never</Text>
              )}
            </div>
            <div>
              Price: <Text weight="medium">${data.subscriptionPlan.price}</Text>
            </div>
            {data.subscriptionPlan.price && Number(data.subscriptionPlan.price) > 0 && (
              <div>
                Invoice frequency:{' '}
                {data.subscriptionPlan.invoiceFrequency ? (
                  <Text weight="medium">
                    {invoiceFrequencyToHumanReadable(data.subscriptionPlan.invoiceFrequency)}
                  </Text>
                ) : (
                  <NoneLabel />
                )}
              </div>
            )}
            <div>
              Promo codes:{' '}
              {data.subscriptionPlan.promoCodes.length ? (
                <Text weight="medium">
                  {data.subscriptionPlan.promoCodes.map(({ code }) => code).join(', ')}
                </Text>
              ) : (
                <NoneLabel />
              )}
            </div>
            <div>
              Stripe Price ID:{' '}
              {data.subscriptionPlan.stripePriceId ? (
                <Text weight="medium">{data.subscriptionPlan.stripePriceId}</Text>
              ) : (
                <NoneLabel />
              )}
            </div>
            {data.subscriptionPlan.alternativeStripePriceIds.length > 0 && (
              <div>
                Alternative Stripe Price IDs:{' '}
                <Text weight="medium">
                  {data.subscriptionPlan.alternativeStripePriceIds.join(', ')}
                </Text>
              </div>
            )}
            {enableEdit && userCanEdit && (
              <Button
                label="Edit"
                className="align-self-start mt-2"
                onClick={() => {
                  setMode(Mode.Edit);
                }}
              />
            )}
          </div>
        ) : (
          <EditProduct
            initialProduct={data.subscriptionPlan}
            saveButtonLabel="Save"
            isPending={isSavingEdits}
            onError={onError}
            onSave={(data) => {
              clearMessages();
              void editProduct({
                variables: {
                  id: plan.id,
                  ...data,
                  displayName: data.displayName
                    ? {
                        value: data.displayName,
                      }
                    : { clear: true },
                  description: data.description
                    ? {
                        value: data.description,
                      }
                    : { clear: true },
                  items: {
                    value: data.items,
                  },
                  stripePriceId: data.stripePriceId
                    ? {
                        value: data.stripePriceId,
                      }
                    : { clear: true },
                },
              });
            }}
            onCancel={setModeView}
          />
        ))
      )}
    </Fragment>
  );
};
