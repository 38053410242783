import { roundToDayEnd, roundToDayStart } from '@wirechunk/lib/dates.js';
import {
  DateInputComponent,
  DateInputComponentDateSpec,
  DateInputComponentStyle,
} from '@wirechunk/lib/mixer/types/components.js';
import { isDate } from 'lodash-es';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

const dateSpecOptions: Array<{ label: string; value: DateInputComponentDateSpec['type'] }> = [
  { label: 'Custom date', value: 'date' },
  { label: 'Current date for user', value: 'today' },
];

const dateStyleOptions: Array<{ label: string; value: DateInputComponentStyle }> = [
  { label: '3 fields', value: DateInputComponentStyle.ThreeFields },
  { label: 'Calendar', value: DateInputComponentStyle.Calendar },
];

export const EditDateInput: FunctionComponent<EditComponentContentProps<DateInputComponent>> = ({
  component,
  setComponent,
}) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editDateInputStyle">Style</label>
        <Dropdown
          inputId="editDateInputStyle"
          value={component.style || DateInputComponentStyle.ThreeFields}
          options={dateStyleOptions}
          onChange={(e) => {
            setComponent((c) => ({ ...c, style: e.value as DateInputComponentStyle }));
          }}
        />
      </div>
      {(component.style || DateInputComponentStyle.ThreeFields) ===
      DateInputComponentStyle.ThreeFields ? (
        <Fragment>
          <div className="input-field">
            <label htmlFor="editDateInputMonthPlaceholder">Month placeholder</label>
            <InputText
              id="editDateInputMonthPlaceholder"
              className="w-full"
              value={component.monthPlaceholder || ''}
              onChange={(e) => {
                setComponent((c) => ({ ...c, monthPlaceholder: e.target.value }));
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="editDateInputDayPlaceholder">Day placeholder</label>
            <InputText
              id="editDateInputDayPlaceholder"
              className="w-full"
              value={component.dayPlaceholder || ''}
              onChange={(e) => {
                setComponent((c) => ({ ...c, dayPlaceholder: e.target.value }));
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="editDateInputYearPlaceholder">Year placeholder</label>
            <InputText
              id="editDateInputYearPlaceholder"
              className="w-full"
              value={component.yearPlaceholder || ''}
              onChange={(e) => {
                setComponent((c) => ({ ...c, yearPlaceholder: e.target.value }));
              }}
            />
          </div>
        </Fragment>
      ) : (
        <div className="input-field">
          <label htmlFor="editDateInputPlaceholder">Placeholder</label>
          <InputText
            id="editDateInputPlaceholder"
            className="w-full"
            value={component.placeholder || ''}
            onChange={(e) => {
              setComponent((c) => ({ ...c, placeholder: e.target.value }));
            }}
          />
        </div>
      )}
      <div className="input-field">
        <label htmlFor="editDateInputMinimumDate">Minimum date type</label>
        <Dropdown
          inputId="editDateInputMinimumDate"
          className="w-full"
          options={dateSpecOptions}
          value={component.minimumDate?.type || null}
          showClear
          onChange={(e) => {
            switch (e.value as DateInputComponentDateSpec['type']) {
              case 'today':
                setComponent((c) => ({ ...c, minimumDate: { type: 'today' } }));
                break;
              case 'date':
                setComponent((c) => ({
                  ...c,
                  minimumDate: { type: 'date', value: roundToDayStart(new Date()).toISOString() },
                }));
                break;
              default:
                setComponent((c) => ({ ...c, minimumDate: null }));
            }
          }}
        />
      </div>
      {component.minimumDate?.type === 'date' && (
        <div className="input-field">
          <label htmlFor="editDateInputMinimumDateValue">Minimum date</label>
          <Calendar
            inputId="editDateInputMinimumDateValue"
            className="w-full"
            value={component.minimumDate.value ? new Date(component.minimumDate.value) : undefined}
            onChange={(e) => {
              setComponent((c) =>
                isDate(e.value) && c.minimumDate
                  ? {
                      ...c,
                      minimumDate: {
                        ...c.minimumDate,
                        value: e.value.toISOString(),
                      },
                    }
                  : c,
              );
            }}
          />
        </div>
      )}
      <div className="input-field">
        <label htmlFor="editDateInputMaximumDate">Maximum date type</label>
        <Dropdown
          inputId="editDateInputMaximumDate"
          className="w-full"
          options={dateSpecOptions}
          value={component.maximumDate?.type || null}
          showClear
          onChange={(e) => {
            switch (e.value as DateInputComponentDateSpec['type']) {
              case 'today':
                setComponent((c) => ({ ...c, maximumDate: { type: 'today' } }));
                break;
              case 'date':
                setComponent((c) => ({
                  ...c,
                  maximumDate: { type: 'date', value: roundToDayEnd(new Date()).toISOString() },
                }));
                break;
              default:
                setComponent((c) => ({ ...c, maximumDate: null }));
            }
          }}
        />
      </div>
      {component.maximumDate?.type === 'date' && (
        <div className="input-field">
          <label htmlFor="editDateInputMaximumDateValue">Maximum date</label>
          <Calendar
            inputId="editDateInputMaximumDateValue"
            className="w-full"
            onChange={(e) => {
              setComponent((c) =>
                isDate(e.value) && c.maximumDate
                  ? {
                      ...c,
                      maximumDate: {
                        ...c.maximumDate,
                        value: e.value.toISOString(),
                      },
                    }
                  : c,
              );
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
