import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import { FunctionComponent, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../Spinner.js';
import {
  EditFormTemplateContext,
  EditFormTemplateContextProvider,
} from './edit-form-template-context.js';
import { activeTab, FormTemplateBreadcrumbs, FormTemplateTabs } from './form-template-tabs.js';
import { SyncFormTemplateToFormsDocument } from './mutations.generated.js';
import { FormTemplateDocument } from './queries.generated.js';

type GuardedFormTemplateProps = {
  formTemplateId: string;
};

const GuardedFormTemplate: FunctionComponent<GuardedFormTemplateProps> = ({ formTemplateId }) => {
  const { pathname } = useLocation();
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading, refetch } = useQuery(FormTemplateDocument, {
    onError,
    variables: { id: formTemplateId },
  });
  const [syncToForms, { loading: syncingToForms }] = useMutation(SyncFormTemplateToFormsDocument, {
    onError,
  });

  const editFormTemplateContext = useMemo<EditFormTemplateContext | null>(
    () =>
      data
        ? {
            formTemplate: data.formTemplate,
            formContext: {
              id: formTemplateId,
              currentStep: null,
              confirmationMessageComponents: null,
              submitting: false,
              submitted: false,
            },
            refetchFormTemplate: refetch,
          }
        : null,
    [data, formTemplateId, refetch],
  );

  return (
    <PageContainer
      pageMetaTitle={data ? `Form Template: ${data.formTemplate.title}` : 'Form Template'}
    >
      <div className="flex justify-content-between align-items-start">
        <FormTemplateBreadcrumbs className="flex-grow-1 mb-2" formTemplate={data?.formTemplate} />
        {data && (
          <Button
            className="p-button-secondary w-max"
            label="Sync to forms"
            disabled={syncingToForms} // TODO: hasUnsavedChanges || saving
            onClick={() => {
              clearMessages();
              void syncToForms({
                variables: {
                  formTemplateId: data.formTemplate.id,
                },
                onCompleted: () => {
                  toastSuccess('Form template synced to forms.');
                },
              });
            }}
          />
        )}
      </div>
      <ErrorMessage />
      {editFormTemplateContext ? (
        <EditFormTemplateContextProvider value={editFormTemplateContext}>
          <FormTemplateTabs
            formTemplateId={formTemplateId}
            activeTab={activeTab(formTemplateId, pathname)}
          />
          <Outlet />
        </EditFormTemplateContextProvider>
      ) : (
        loading && <Spinner />
      )}
    </PageContainer>
  );
};

export const FormTemplate: FunctionComponent = () => {
  const { formTemplateId } = useParams<{ formTemplateId: string }>();

  if (formTemplateId) {
    return <GuardedFormTemplate formTemplateId={formTemplateId} />;
  }

  return null;
};
