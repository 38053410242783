import { useMutation } from '@apollo/client';
import { clsx } from 'clsx';
import { FunctionComponent } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { EditProduct } from '../edit-product.js';
import { CreateProductDocument } from './mutations.generated.js';

type CreateSubscriptionPlanProps = {
  className?: string;
  onCreated: (id: string) => void;
  onCancel: () => void;
};

export const CreateSubscriptionPlan: FunctionComponent<CreateSubscriptionPlanProps> = ({
  className,
  onCreated,
  onCancel,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [createProduct, { loading: isCreating }] = useMutation(CreateProductDocument, {
    onError,
    onCompleted: (data) => {
      if (data.createProduct.__typename === 'CreateProductSuccessResult') {
        onCreated(data.createProduct.product.id);
      } else {
        onError(data.createProduct.message);
      }
    },
  });

  return (
    <div className={clsx('border-1 border-round p-3', className)}>
      <h3 className="mt-1">Create a product</h3>
      <ErrorMessage />
      <EditProduct
        saveButtonLabel="Create"
        isPending={isCreating}
        onError={onError}
        onSave={(data) => {
          clearMessages();
          void createProduct({
            variables: {
              platformId,
              ...data,
            },
          });
        }}
        onCancel={onCancel}
      />
    </div>
  );
};
