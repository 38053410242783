import { useMutation, useQuery } from '@apollo/client';
import { Permission, Role, UserStatus } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import { useContentPlans } from '../../../../hooks/use-content-plans/use-content-plans.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../hooks/useMenuSetup.js';
import { useTableRowExpansion } from '../../../../hooks/useTableRowExpansion.js';
import { roleOptions, roleToHumanReadable } from '../../../../util/roles.js';
import { userStatusOptions } from '../../../../util/userStatuses.js';
import { MoveUserToOrg } from '../../../move-user-to-org/move-user-to-org.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { SplitUserIntoNewOrg } from '../../../split-user-into-new-org/split-user-into-new-org.js';
import { ThreeDotMenuButton } from '../../../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { AddUser } from './AddUser.js';
import { EditUserRoleDocument, EditUserStatusDocument } from './mutations.generated.js';
import { OrganizationCell } from './OrganizationCell.js';
import { UsersDocument } from './queries.generated.js';
import type { UserRow } from './types.js';
import { UserDetails } from './UserDetails/UserDetails.js';
import { UserEmailCell } from './UserEmailCell.js';
import { UserFirstNameCell } from './UserFirstNameCell.js';
import { UserLastNameCell } from './UserLastNameCell.js';
import styles from './Users.module.css';

// The server always applies a limit of 10 to queries.
const rowLimit = 10;

export const Users: FunctionComponent = () => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { id: platformId, permissions } = usePlatformContext();
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { contentPlans, loading: contentPlansLoading } = useContentPlans(platformId, onError);

  const [search, setSearch] = useState('');
  const [role, setRole] = useState<Role | null>(null);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [page, setPage] = useState(0);

  const searchInput = useRef<HTMLInputElement>(null);
  const lastSearchValue = useRef<string>(search);

  const { expandedRows, onRowToggle } = useTableRowExpansion();
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<UserRow>();

  // Update the search state variable on an interval.
  useEffect(() => {
    const interval = setInterval(() => {
      const { current: input } = searchInput;
      if (input && input.value !== lastSearchValue.current) {
        // If the value is just spaces, clear search.
        const newValue = input.value.trim() ? input.value : '';
        setSearch(newValue);
        lastSearchValue.current = newValue;
        setPage(0);
      }
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const {
    data,
    loading: usersLoading,
    refetch: refetchUsers,
  } = useQuery(UsersDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      search,
      role,
      status,
      page,
    },
  });
  const [showAddUser, setShowAddUser] = useState(false);
  const [editingUserId, setEditingUserId] = useState<string | null>(null);

  const [editUserRole] = useMutation(EditUserRoleDocument, {
    onError,
  });

  const [editUserStatus] = useMutation(EditUserStatusDocument, {
    onError,
  });

  const onChangeUserRole = (user: UserRow, role: Role) => {
    dialog({
      confirm: `Are you sure you want to change ${user.displayName}’s role to ${roleToHumanReadable(
        role,
      )}?`,
      props: {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAccept: async () => {
          setEditingUserId(user.id);
          try {
            await editUserRole({
              variables: { userId: user.id, role },
            });
            void refetchUsers();
            toastSuccess('Role changed.');
          } finally {
            setEditingUserId(null);
          }
        },
      },
    });
  };

  const onChangeUserStatus = (user: UserRow, status: UserStatus) => {
    dialog({
      confirm: `Are you sure you want to change ${user.displayName}’s status to ${status}?${
        status === UserStatus.Deactivated
          ? ' Doing this will only prevent this user from signing in.'
          : ''
      }`,
      props: {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAccept: async () => {
          setEditingUserId(user.id);
          try {
            await editUserStatus({
              variables: { userId: user.id, status },
            });
            void refetchUsers();
            toastSuccess('Status changed.');
          } finally {
            setEditingUserId(null);
          }
        },
      },
    });
  };

  const hasEditStatusPermission = permissions.includes(Permission.EditUserStatus);
  const hasEditRolePermission = permissions.includes(Permission.EditUserRole);
  const hasEditUserOrgPermission = permissions.includes(Permission.EditUserOrg);

  const contentPlanIdsToShowProgress = contentPlans?.map((plan) => plan.id);

  return (
    <PageContainer title="Users">
      <ErrorMessage />
      <div className="surface-ground p-3">
        <div className="flex flex-column lg:flex-row lg:justify-content-start lg:align-items-center gap-3">
          <input
            type="text"
            className="p-inputtext p-component w-full lg:w-20rem"
            placeholder="Search&hellip;"
            ref={searchInput}
          />
          <Dropdown
            className="w-14rem"
            value={role}
            options={roleOptions}
            placeholder="Search by role"
            onChange={(e) => {
              setRole(e.value as Role | null);
              setPage(0);
            }}
            showClear
          />
          <Dropdown
            className="w-14rem"
            value={status}
            options={userStatusOptions}
            placeholder="Search by status"
            onChange={(e) => {
              setStatus(e.value as UserStatus | null);
              setPage(0);
            }}
            showClear
          />
          {permissions.includes(Permission.CreateUser) && (
            <div className="lg:flex-grow-1 lg:flex justify-content-end">
              <Button
                label="Add user"
                className="min-w-max"
                onClick={() => {
                  setShowAddUser(true);
                }}
                disabled={showAddUser}
              />
            </div>
          )}
        </div>
        {showAddUser && (
          <AddUser
            className="mt-4 mb-1 background-white"
            onAdded={() => {
              void refetchUsers();
              setShowAddUser(false);
              toastSuccess('User added.');
            }}
            onCancel={() => {
              setShowAddUser(false);
            }}
          />
        )}
      </div>
      <DataTable
        dataKey="id"
        value={data?.users.users || []}
        emptyMessage="No matching users found"
        loading={usersLoading || contentPlansLoading}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        rowExpansionTemplate={
          contentPlanIdsToShowProgress
            ? (row) => (
                <UserDetails
                  user={row}
                  contentPlanIdsToShowProgress={contentPlanIdsToShowProgress}
                />
              )
            : undefined
        }
        rows={rowLimit}
        first={page * rowLimit}
        totalRecords={data?.users.totalCount || 0}
        onPage={(e) => {
          setPage(e.page || 0);
        }}
        paginator
        lazy
      >
        {contentPlanIdsToShowProgress && <Column expander className="table-left-action-column" />}
        <Column
          header="First name"
          body={(row: UserRow) => (
            <UserFirstNameCell
              user={row}
              isSavingUserEdits={row.id === editingUserId}
              onError={onError}
            />
          )}
          bodyClassName={styles.width14}
        />
        <Column
          header="Last name"
          body={(row: UserRow) => (
            <UserLastNameCell
              user={row}
              isSavingUserEdits={row.id === editingUserId}
              onError={onError}
            />
          )}
          bodyClassName={styles.width14}
        />
        <Column
          header="Email"
          body={(row: UserRow) => (
            <UserEmailCell
              user={row}
              isSavingUserEdits={row.id === editingUserId}
              onError={onError}
            />
          )}
          bodyClassName={styles.width17}
        />
        <Column
          header="Role"
          body={(row: UserRow) =>
            hasEditRolePermission ? (
              <Dropdown
                value={row.role}
                disabled={row.id === editingUserId}
                options={roleOptions}
                onChange={(e) => {
                  onChangeUserRole(row, e.value as Role);
                }}
              />
            ) : (
              <span className={row.id === editingUserId ? 'text-color-muted' : undefined}>
                {roleToHumanReadable(row.role)}
              </span>
            )
          }
          bodyClassName={styles.width17}
        />
        <Column
          header="Status"
          body={(row: UserRow) =>
            hasEditStatusPermission ? (
              <Dropdown
                value={row.status}
                disabled={row.id === editingUserId}
                options={userStatusOptions}
                onChange={(e) => {
                  onChangeUserStatus(row, e.value as UserStatus);
                }}
              />
            ) : (
              row.status
            )
          }
          bodyClassName={styles.width14}
        />
        <Column
          header="Org"
          body={(row: UserRow) => <OrganizationCell org={row.organization} />}
          bodyClassName={styles.width17}
        />
        <Column
          header=""
          align="right"
          body={(row: UserRow) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Split out into a new org',
            disabled: !hasEditUserOrgPermission,
            command: () => {
              if (menuRow) {
                dialog({
                  content: (
                    <SplitUserIntoNewOrg
                      user={menuRow}
                      onCompleted={() => {
                        dialog(null);
                        toastSuccess('User split into a new org.');
                      }}
                      onCancel={() => {
                        dialog(null);
                      }}
                    />
                  ),
                  props: {
                    header: 'Split out into a new org',
                    className: 'dialog-width-lg',
                  },
                });
              }
            },
          },
          {
            label: 'Move to a different org',
            disabled: !hasEditUserOrgPermission,
            command: () => {
              if (menuRow) {
                dialog({
                  content: (
                    <MoveUserToOrg
                      platformId={platformId}
                      user={menuRow}
                      onCompleted={() => {
                        dialog(null);
                        toastSuccess('User moved to a different org.');
                      }}
                      onCancel={() => {
                        dialog(null);
                      }}
                    />
                  ),
                  props: {
                    header: 'Move to a different org',
                    className: 'dialog-width-lg',
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
