import { isFunction, isPlainObject } from 'lodash-es';

export const googleTagManagerScript = (containerId: string): string =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${containerId}');`;

export const metaPixelScript = (pixelId: string): string =>
  `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${pixelId}');fbq('track', 'PageView');`;

type GoogleTagManagerData =
  // The typical event.
  | { event: string; [key: string]: unknown }
  // A dataLayer variable that our Tag Manager will recognize and use as the user ID.
  | { user_id: string };

type GoogleTagManager = (data: GoogleTagManagerData) => void;

type TikTokPixel = {
  track: (eventName: string, data: { content_id?: string } & Record<string, unknown>) => void;
};

const isTikTokPixel = (pixel: unknown): pixel is TikTokPixel =>
  isPlainObject(pixel) && isFunction((pixel as Record<string, unknown>).track);

type FacebookPixel = (
  track: 'track' | 'trackCustom',
  eventName: string,
  data: { content_ids?: string[] } & Record<string, unknown>,
) => void;

declare global {
  interface Window {
    ttq?: TikTokPixel;
    fbq?: FacebookPixel;
    dataLayer?: unknown[];
  }
}

export const triggerGoogleTagManagerEvent: GoogleTagManager = (data) => {
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(data);
  }
};

export const triggerTikTokEvent: TikTokPixel['track'] = (eventName, data) => {
  const { ttq } = window;
  if (isTikTokPixel(ttq)) {
    ttq.track(eventName, data);
  }
};

export const triggerFacebookEvent: FacebookPixel = (track, eventName, data) => {
  if (isFunction(window.fbq)) {
    window.fbq(track, eventName, data);
  }
};
