import {
  isBooleanExpression,
  isCompleteExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import { ComponentType, PopoverComponent, Trigger } from '@wirechunk/lib/mixer/types/components.js';
import { isEmpty } from 'lodash-es';
import { Fragment, FunctionComponent, useEffect } from 'react';
import { useStateRef } from '../../../hooks/use-state-ref.js';
import { EditTriggers, WorkingTrigger } from '../EditComponentDialog/edit-triggers.js';
import { ComponentEditorListener, EditComponentContentProps } from './shared/types.js';

export const EditPopover: FunctionComponent<EditComponentContentProps<PopoverComponent>> = ({
  component,
  eventHooks,
}) => {
  const [workingShowTriggersRef, setWorkingShowTriggers] = useStateRef<WorkingTrigger[]>(
    () => component.showTriggers ?? [],
  );
  const [workingHideTriggersRef, setWorkingHideTriggers] = useStateRef<WorkingTrigger[]>(
    () => component.hideTriggers ?? [],
  );

  useEffect(() => {
    const updateTriggers: ComponentEditorListener = (component) => {
      // A mapping from trigger ID to validation error message.
      const validationErrors: Record<string, string> = {};

      // This type check is just to satisfy TypeScript.
      if (component.type === ComponentType.Popover) {
        const newShowTriggers: Trigger[] = [];
        for (const { id, type, condition } of workingShowTriggersRef.current) {
          if (condition) {
            if (!isCompleteExpression(condition)) {
              validationErrors[id] = 'The condition must be completed if it is enabled.';
            } else if (!isBooleanExpression(condition)) {
              validationErrors[id] = 'The condition must be a boolean expression.';
            } else {
              newShowTriggers.push({
                id,
                type,
                condition,
              });
            }
          } else {
            newShowTriggers.push({
              id,
              type,
            });
          }
        }

        const newHideTriggers: Trigger[] = [];
        for (const { id, type, condition } of workingHideTriggersRef.current) {
          if (condition) {
            if (!isCompleteExpression(condition)) {
              validationErrors[id] = 'The condition must be completed if it is enabled.';
            } else if (!isBooleanExpression(condition)) {
              validationErrors[id] = 'The condition must be a boolean expression.';
            } else {
              newHideTriggers.push({
                id,
                type,
                condition,
              });
            }
          } else {
            newHideTriggers.push({
              id,
              type,
            });
          }
        }

        if (isEmpty(validationErrors)) {
          return {
            action: 'continue',
            component: {
              ...component,
              showTriggers: newShowTriggers,
              hideTriggers: newHideTriggers,
            } satisfies PopoverComponent,
          };
        }

        return {
          action: 'prevent',
          errorMessage: `Please correct the following errors: ${Object.values(
            validationErrors,
          ).join(', ')}`,
        };
      }
      return { action: 'continue' };
    };

    eventHooks.onBeforeLeave(updateTriggers);

    return () => {
      eventHooks.offBeforeLeave(updateTriggers);
    };
  }, [eventHooks, workingShowTriggersRef, workingHideTriggersRef]);

  return (
    <Fragment>
      <fieldset className="border-none p-0">
        <legend className="font-medium mb-1">Triggers for showing the popover</legend>
        <EditTriggers
          triggers={workingShowTriggersRef.current}
          setTriggers={setWorkingShowTriggers}
        />
      </fieldset>
      <fieldset className="border-none p-0 mt-3">
        <legend className="font-medium mb-1">Triggers for hiding the popover</legend>
        <EditTriggers
          triggers={workingHideTriggersRef.current}
          setTriggers={setWorkingHideTriggers}
        />
      </fieldset>
    </Fragment>
  );
};
