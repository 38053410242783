import { IconComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import { iconOptions } from './shared/icons.js';
import type { EditComponentContentProps } from './shared/types.js';

export const EditIcon: FunctionComponent<EditComponentContentProps<IconComponent>> = ({
  component,
  setComponent,
}) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editIconIcon">Icon</label>
      <Dropdown
        inputId="editIconIcon"
        className="w-full"
        options={iconOptions}
        value={component.icon}
        onChange={(e) => {
          setComponent((c) => ({ ...c, icon: e.value as string }));
        }}
      />
    </div>
  </Fragment>
);
