import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import {
  DataSource,
  ProvideProp,
  ProvidePropsComponent,
  ProvidePropValueSource,
  ProvidePropValueSourceDirectType,
  ProvidePropValueSourceDirectValue,
  ProvidePropValueSourceQueryParameter,
} from '@wirechunk/lib/mixer/types/components.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent } from 'react';
import { SelectItem } from '../../../../types.js';
import { BasicIconButton } from '../../../BasicIconButton/BasicIconButton.js';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import type { EditComponentContentProps } from '../shared/types.js';

const sourceTypeOptions: Array<SelectItem<ProvidePropValueSource['type']>> = [
  { label: 'Direct', value: DataSource.Direct },
  { label: 'Query parameter', value: 'QueryParameter' },
];

const isSourceType = (value: unknown): value is ProvidePropValueSource['type'] =>
  value === DataSource.Direct ||
  value === ('QueryParameter' satisfies ProvidePropValueSource['type']);

export const EditProvideProps: FunctionComponent<
  EditComponentContentProps<ProvidePropsComponent>
> = ({ component, setComponent }) => {
  return (
    <div className="flex flex-column gap-2">
      {component.props?.map((prop) => (
        <div key={prop.id} className="border-1 border-round p-2">
          <div className="flex align-items-start gap-2">
            <div className="input-field flex-grow-1">
              <label htmlFor={`editProvidePropsPropName${prop.id}`}>Name</label>
              <InputText
                id={`editProvidePropsPropName${prop.id}`}
                value={prop.name}
                onChange={(e) => {
                  const name = e.target.value.trim();
                  setComponent((c) => ({
                    ...c,
                    props: c.props?.map((p) => (p.id === prop.id ? { ...p, name } : p)),
                  }));
                }}
              />
              <InputNotice>
                This is the name under which the prop you provide will be available to children
                components.
              </InputNotice>
            </div>
            <BasicIconButton
              onClick={() => {
                setComponent((c) => ({
                  ...c,
                  props: c.props?.filter(({ id }) => id !== prop.id),
                }));
              }}
              icon={PrimeIcons.TRASH}
            />
          </div>
          <div className="input-field flex-grow-1">
            <label htmlFor={`editProvidePropsPropSourceType${prop.id}`}>Value source</label>
            <Dropdown
              inputId={`editProvidePropsPropSourceType${prop.id}`}
              className="w-13rem"
              options={sourceTypeOptions}
              value={prop.source.type}
              onChange={(e) => {
                const type: unknown = e.target.value;
                if (isSourceType(type)) {
                  setComponent((c) => ({
                    ...c,
                    props: c.props?.map((p) =>
                      p.id === prop.id
                        ? ({
                            ...p,
                            source:
                              type === DataSource.Direct
                                ? ({
                                    type,
                                    value: {
                                      type: ProvidePropValueSourceDirectType.String,
                                      value: '',
                                    },
                                  } satisfies ProvidePropValueSource)
                                : ({
                                    type,
                                    parameter: '',
                                  } satisfies ProvidePropValueSourceQueryParameter),
                          } satisfies ProvideProp)
                        : p,
                    ),
                  }));
                }
              }}
            />
          </div>
          {prop.source.type === DataSource.Direct ? (
            !prop.source.value ||
            (prop.source.value.type === ProvidePropValueSourceDirectType.String && (
              <div className="input-field">
                <label htmlFor={`editProvidePropsValueString${prop.id}`}>Value</label>
                <InputText
                  id={`editProvidePropsValueString${prop.id}`}
                  value={prop.source.value.value || ''}
                  onChange={(e) => {
                    setComponent((c) => ({
                      ...c,
                      props: c.props?.map((p) =>
                        p.id === prop.id
                          ? {
                              ...p,
                              source: {
                                ...p.source,
                                value: {
                                  type: ProvidePropValueSourceDirectType.String,
                                  value: e.target.value,
                                } satisfies ProvidePropValueSourceDirectValue,
                              },
                            }
                          : p,
                      ),
                    }));
                  }}
                />
                <InputNotice>Enter a value for the prop directly.</InputNotice>
              </div>
            ))
          ) : (
            <div className="input-field">
              <label htmlFor={`editProvidePropsValueQueryParameter${prop.id}`}>
                Parameter name
              </label>
              <InputText
                id={`editProvidePropsValueQueryParameter${prop.id}`}
                value={prop.source.parameter}
                onChange={(e) => {
                  setComponent((c) => ({
                    ...c,
                    props: c.props?.map((p) =>
                      p.id === prop.id
                        ? {
                            ...p,
                            source: {
                              ...p.source,
                              type: 'QueryParameter' satisfies ProvidePropValueSource['type'],
                              parameter: e.target.value,
                            } satisfies ProvidePropValueSourceQueryParameter,
                          }
                        : p,
                    ),
                  }));
                }}
              />
              <InputNotice>Enter the name of a query parameter to copy.</InputNotice>
            </div>
          )}
        </div>
      ))}
      <Button
        label="Add prop"
        className="w-max"
        onClick={() => {
          setComponent((c) => ({
            ...c,
            props: [
              ...(c.props || []),
              {
                id: cleanSmallId(),
                name: '',
                source: {
                  type: DataSource.Direct,
                  value: {
                    type: ProvidePropValueSourceDirectType.String,
                    value: '',
                  } satisfies ProvidePropValueSourceDirectValue,
                },
              } satisfies ProvideProp,
            ],
          }));
        }}
      />
    </div>
  );
};
