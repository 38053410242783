import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import type { MoveUserPlanComponent } from '@wirechunk/lib/mixer/types/components.js';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { FunctionComponent } from 'react';
import { useStageContext } from '../../../contexts/StageContext/StageContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { useEditUserPlanLive } from './useEditUserPlanLive/useEditUserPlanLive.js';

export const defaultPreviousLabel = 'Previous lesson';
export const defaultNextLabel = 'Next lesson';

export const MoveUserPlan: FunctionComponent<MoveUserPlanComponent> = (props) => {
  const { onErrorToast } = useErrorHandler();
  const stageContext = useStageContext();
  const { editUserPlan, saving } = useEditUserPlanLive(onErrorToast);

  if (!stageContext) {
    return null;
  }

  const { previousStageBlueprintId, nextStageBlueprintId } = stageContext.userPlan;
  if (!previousStageBlueprintId && !nextStageBlueprintId) {
    return null;
  }

  return (
    <div className={`${componentClassName(props)} p-button-group`}>
      {previousStageBlueprintId && (
        <Button
          className={clsx('pr-3', !nextStageBlueprintId && 'border-round')}
          label={props.previousButtonLabel || defaultPreviousLabel}
          icon={PrimeIcons.ARROW_LEFT}
          disabled={saving}
          onClick={() =>
            void editUserPlan(stageContext.stageBlueprint.planId, previousStageBlueprintId)
          }
        />
      )}
      {nextStageBlueprintId && (
        <Button
          className={clsx('pl-3', !previousStageBlueprintId && 'border-round')}
          label={props.nextButtonLabel || defaultNextLabel}
          icon={PrimeIcons.ARROW_RIGHT}
          iconPos="right"
          disabled={saving}
          onClick={() =>
            void editUserPlan(stageContext.stageBlueprint.planId, nextStageBlueprintId)
          }
        />
      )}
    </div>
  );
};
