import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { OrganizationDetails } from '../../../../OrganizationDetails/OrganizationDetails.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';

type GuardedOrganizationProps = {
  organizationId: string;
};

const GuardedOrganization: FunctionComponent<GuardedOrganizationProps> = ({ organizationId }) => (
  <PageContainer title="Organization Details">
    <OrganizationDetails organizationId={organizationId} />
  </PageContainer>
);

export const Organization: FunctionComponent = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  if (!organizationId) {
    return null;
  }

  return <GuardedOrganization organizationId={organizationId} />;
};
