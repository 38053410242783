import { PrimeIcons } from 'primereact/api';
import { Fragment, FunctionComponent } from 'react';
import styles from './HeadingAndSubHeading.module.css';

type HeadingAndSubHeadingProps = {
  heading?: string;
  subHeading?: string;
};

export const HeadingAndSubHeading: FunctionComponent<HeadingAndSubHeadingProps> = ({
  heading,
  subHeading,
}) => {
  if (heading) {
    return (
      <h2 className={styles.heading}>
        <span>{heading}</span>
        {subHeading && (
          <Fragment>
            <i className={`${PrimeIcons.ANGLE_RIGHT} text-base`} />
            <span>{subHeading}</span>
          </Fragment>
        )}
      </h2>
    );
  }

  return null;
};
