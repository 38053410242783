import { useApolloClient, useQuery } from '@apollo/client';
import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import { remapComponentIds, remapComponentsIds } from '@wirechunk/lib/mixer/remap-component-ids.js';
import {
  componentChildrenEnabled,
  isComponentWithChildren,
} from '@wirechunk/lib/mixer/types/categories.js';
import { Component, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import {
  insertAfterComponent,
  insertManyAfterComponent,
  parseComponents,
  removeComponentById,
} from '@wirechunk/lib/mixer/utils.js';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  PlatformContextProviderDirect,
  usePlatformContext,
} from '../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { usePageContext } from '../../../contexts/PageContext/PageContext.js';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import { useToast } from '../../../contexts/ToastContext.js';
import { useComponentDescriptiveHeader } from '../../../hooks/use-component-descriptive-header/use-component-descriptive-header.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { DragItem, dragItemType } from '../dnd.js';
import { DropZone } from '../drop-zone/drop-zone.js';
import { EditComponentDialog } from '../EditComponentDialog/EditComponentDialog.js';
import { buildTabs } from '../EditComponentDialog/tabs.js';
import { Tree, TreeProps } from '../tree.js';
import { CreateComponentDialog } from './create-component-dialog.js';
import { ComponentForTreeDocument } from './queries.generated.js';
import styles from './tree-component.module.css';

const actionButtonClassName = `${styles.componentActionButton} flex align-items-center justify-content-center p-1 h-max w-max line-height-1 background-none border-none text-color-light hover:text-color-body`;

type TreeComponentProps = Pick<TreeProps, 'findComponent' | 'moveComponent'> & {
  component: Component;
  setComponent: (component: Component) => void;
  setComponents: (updater: (components: Component[]) => Component[]) => void;
  parent: Component | null;
  previousSiblingId: string | null;
};

export const TreeComponent: FunctionComponent<TreeComponentProps> = ({
  component,
  setComponent,
  setComponents,
  findComponent,
  moveComponent,
  parent,
  previousSiblingId,
}) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const platformContext = usePlatformContext();
  const hideDialog = useCallback(() => {
    dialog(null);
  }, [dialog]);
  const { onError, ErrorMessage } = useErrorHandler();
  const dynamicComponentId =
    component.type === ComponentType.Design && (component.customComponentId || component.designId);
  const apolloClient = useApolloClient();
  const { data: componentForTreeData } = useQuery(
    ComponentForTreeDocument,
    dynamicComponentId
      ? {
          onError,
          variables: { id: dynamicComponentId },
        }
      : { skip: true },
  );
  const header = useComponentDescriptiveHeader(
    component,
    componentForTreeData?.component.name,
    parent,
  );

  const siteContext = useSiteContext();
  const { viewMode } = usePageContext();

  const [{ isDragging }, drag] = useDrag<
    DragItem,
    unknown,
    {
      isDragging: boolean;
    }
  >(
    () => ({
      type: dragItemType,
      item: (): DragItem => ({
        id: component.id,
        children: component.children || null,
        previousSiblingId,
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [component.id, component.children, previousSiblingId],
  );

  // This is only for dropping the component back in place.
  const [{ isOver }, drop] = useDrop(
    {
      accept: dragItemType,
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
    [],
  );

  const tabs = buildTabs(component, parent, apolloClient);

  return (
    <Fragment>
      {isDragging && (
        <div
          ref={drop}
          className={clsx(
            'border-round',
            styles.pickedUpComponentPlaceholder,
            isOver && 'bg-green-400 border-none',
          )}
        />
      )}
      <div
        ref={drag}
        className={clsx(
          'cursor-move surface-ground border-1 border-round overflow-hidden',
          isDragging && 'hidden',
        )}
        role="handle"
      >
        <ErrorMessage />
        <div
          className={clsx(
            styles.componentHeader,
            styles.componentHeight,
            'py-1 pl-3 pr-2 flex align-items-center justify-content-between gap-2',
          )}
        >
          <span className="flex-grow-1 text-sm line-height-1">{header}</span>
          <Button
            className={actionButtonClassName}
            onClick={(e) => {
              e.stopPropagation();
              // We don't want the descriptive header with all its details.
              const componentName =
                component.type === ComponentType.Design && componentForTreeData
                  ? componentForTreeData.component.name
                  : componentTypeHumanReadable(component.type);
              dialog({
                confirm: `Are you sure you want to remove this ${componentName}?`,
                props: {
                  onAccept: () => {
                    setComponents((components) => removeComponentById(components, component.id));
                  },
                  rejectLabel: 'Cancel',
                  rejectClassName: 'p-button-text text-color-body',
                  acceptLabel: 'Remove',
                  acceptClassName: 'p-button-danger',
                },
              });
            }}
          >
            <span className="material-symbols-outlined text-lg">delete_forever</span>
          </Button>
          <Button
            className={actionButtonClassName}
            onClick={(e) => {
              e.stopPropagation();
              setComponents((components) =>
                insertAfterComponent(components, component.id, remapComponentIds(component)),
              );
            }}
          >
            <span className="material-symbols-outlined text-lg">content_copy</span>
          </Button>
          {component.type === ComponentType.Design ? (
            <Button
              className={actionButtonClassName}
              tooltip="Inline the component’s contents"
              tooltipOptions={{ position: 'top', showOnDisabled: true }}
              disabled={!dynamicComponentId || !componentForTreeData?.component}
              onClick={(e) => {
                e.stopPropagation();
                if (componentForTreeData?.component) {
                  dialog({
                    confirm: 'Are you sure you want to replace this component with its contents?',
                    props: {
                      onAccept: () => {
                        const parsedComponents = remapComponentsIds(
                          parseComponents(componentForTreeData.component.components),
                        );
                        setComponents((components) => {
                          const withInsertedComponents = insertManyAfterComponent(
                            components,
                            component.id,
                            parsedComponents,
                          );
                          return removeComponentById(withInsertedComponents, component.id);
                        });
                      },
                    },
                  });
                }
              }}
            >
              <span className="material-symbols-outlined text-lg">restart_alt</span>
            </Button>
          ) : (
            <Button
              className={actionButtonClassName}
              tooltip="Save as a component"
              tooltipOptions={{ position: 'top', showOnDisabled: true }}
              onClick={(e) => {
                e.stopPropagation();
                dialog({
                  content: (
                    <CreateComponentDialog
                      platformId={platformContext.id}
                      component={component}
                      setComponent={setComponent}
                      onCreated={() => {
                        hideDialog();
                        toastSuccess('Component created.');
                      }}
                      onCancel={hideDialog}
                    />
                  ),
                  props: {
                    header: 'Save as a custom component',
                    className: 'dialog-width-lg',
                  },
                });
              }}
            >
              <span className="material-symbols-outlined text-lg">bolt</span>
            </Button>
          )}
          {tabs.length > 0 && (
            <Button
              className={actionButtonClassName}
              onClick={(e) => {
                e.stopPropagation();
                dialog({
                  content: (
                    <PlatformContextProviderDirect value={platformContext}>
                      <EditComponentDialog
                        component={component}
                        parent={parent}
                        tabs={tabs}
                        siteContext={siteContext}
                        viewMode={viewMode}
                        setComponent={setComponent}
                        onHide={hideDialog}
                      />
                    </PlatformContextProviderDirect>
                  ),
                });
              }}
            >
              <span className="material-symbols-outlined text-lg">edit</span>
            </Button>
          )}
        </div>
        {isComponentWithChildren(component) &&
          // Display existing children for a component where children are not enabled so that they can be removed.
          (!!component.children?.length || componentChildrenEnabled(component)) && (
            <div className="border-1 border-round p-2 mx-3 mt-1 mb-3 background-white">
              <Tree
                parent={component}
                components={component.children || null}
                setComponent={setComponent}
                setComponents={setComponents}
                findComponent={findComponent}
                moveComponent={moveComponent}
              />
            </div>
          )}
      </div>
      <DropZone
        setComponents={setComponents}
        parentComponentId={parent?.id}
        afterComponentId={component.id}
        showHelpText={false}
      />
    </Fragment>
  );
};
