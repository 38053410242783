import { useMutation, useQuery } from '@apollo/client';
import { isNumber, maxBy } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import { ErrorHandler, useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../Spinner.js';
import {
  CreateStageBlueprintDocument,
  DeleteStageBlueprintDocument,
  DuplicateStageBlueprintDocument,
} from '../../../../stage-blueprints/mutations.generated.js';
import { EditStageBlueprintMetaDataDocument } from '../../../../stage-blueprints/stage-blueprint/mutations.generated.js';
import { EditSequenceDocument } from './mutations.generated.js';
import { SequenceDocument, SequenceQuery } from './queries.generated.js';

const deletedLabelClassName = 'plan-stage-blueprint-deleted-label';

const DuplicateStageBlueprint: FunctionComponent<{
  onError: ErrorHandler['onError'];
  sequenceId: string;
  stageBlueprint: SequenceQuery['sequence']['stageBlueprints'][number];
  defaultPosition: number;
}> = ({ onError, sequenceId, stageBlueprint, defaultPosition }) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const [name, setName] = useState(`${stageBlueprint.name} (copy)`);
  const [position, setPosition] = useState<number | null>(defaultPosition);

  const [duplicateStageBlueprint, { loading: isDuplicateLoading }] = useMutation(
    DuplicateStageBlueprintDocument,
    {
      onError,
      onCompleted: () => {
        toastSuccess('Stage blueprint duplicated.');
        dialog(null);
      },
      refetchQueries: [{ query: SequenceDocument, variables: { id: sequenceId } }],
    },
  );

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="duplicateStageName">New stage blueprint name</label>
        <InputText
          id="duplicateStageName"
          className="w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="duplicateStagePosition">New stage blueprint position</label>
        <InputNumber
          inputId="duplicateStagePosition"
          inputClassName="w-5rem"
          value={position}
          onChange={({ value }) => {
            if (isNumber(value)) {
              setPosition(value);
            }
          }}
        />
      </div>
      <div className="flex gap-3 justify-content-end">
        <Button
          label="Cancel"
          className="p-button-secondary"
          disabled={isDuplicateLoading}
          onClick={() => {
            dialog(null);
          }}
        />
        <Button
          label="Duplicate"
          disabled={!position || isDuplicateLoading}
          onClick={() => {
            if (position) {
              void duplicateStageBlueprint({
                variables: {
                  id: stageBlueprint.id,
                  name,
                  position,
                },
              });
            }
          }}
        />
      </div>
    </Fragment>
  );
};

const StageBlueprintOverview: FunctionComponent<{
  sequenceId: string;
  stageBlueprint: SequenceQuery['sequence']['stageBlueprints'][number];
  allStageBlueprints: SequenceQuery['sequence']['stageBlueprints'];
}> = ({ sequenceId, stageBlueprint, allStageBlueprints }) => {
  const { handle } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const [isEditing, setIsEditing] = useState(false);
  const [editStageBlueprintMeta, { loading: isSavingEdits }] = useMutation(
    EditStageBlueprintMetaDataDocument,
    {
      onError,
      onCompleted: () => {
        setIsEditing(false);
      },
    },
  );
  const [deleteStageBlueprint] = useMutation(DeleteStageBlueprintDocument, {
    onError,
    refetchQueries: [{ query: SequenceDocument, variables: { id: sequenceId } }],
  });
  const dialog = useDialog();
  const [name, setName] = useState(stageBlueprint.name);
  const [position, setPosition] = useState<number | null>(stageBlueprint.position);

  useEffect(() => {
    setName(stageBlueprint.name);
    setPosition(stageBlueprint.position);
  }, [stageBlueprint.name, stageBlueprint.position]);

  return (
    <div className="surface-ground border-1 border-round p-3">
      <ErrorMessage />
      <div className="flex gap-3 justify-content-between align-items-center">
        <div className="flex-grow-1">
          <Link
            to={`/dashboard/${handle}/content/sequences/${sequenceId}/stage-blueprints/${stageBlueprint.id}`}
          >
            <span className="font-bold">
              {stageBlueprint.deletedAt && (
                <span
                  data-pr-tooltip={`Deleted on ${new Date(
                    stageBlueprint.deletedAt,
                  ).toLocaleDateString()}`}
                  className={`${deletedLabelClassName} pr-2`}
                >
                  [DELETED]
                </span>
              )}
              {stageBlueprint.name}
            </span>{' '}
            ({stageBlueprint.position})
          </Link>
        </div>
        <Button
          className="p-button-secondary"
          aria-label="Edit stage"
          disabled={isEditing}
          icon={PrimeIcons.PENCIL}
          onClick={() => {
            setIsEditing(true);
          }}
        />
        <Button
          className="p-button-secondary"
          aria-label="Duplicate stage"
          icon={PrimeIcons.COPY}
          onClick={() => {
            dialog({
              content: (
                <DuplicateStageBlueprint
                  onError={onError}
                  sequenceId={sequenceId}
                  stageBlueprint={stageBlueprint}
                  defaultPosition={
                    (maxBy(allStageBlueprints, (sb) => (sb.deletedAt ? 0 : sb.position))
                      ?.position || 0) + 1
                  }
                />
              ),
              props: {
                header: 'Duplicate stage',
              },
            });
          }}
        />
        <Button
          className="p-button-secondary"
          aria-label="Delete stage"
          tooltip={stageBlueprint.deletedAt ? 'Restore' : 'Delete'}
          tooltipOptions={{ position: 'left', showDelay: 150 }}
          icon={stageBlueprint.deletedAt ? PrimeIcons.REPLAY : PrimeIcons.TRASH}
          onClick={() => {
            if (stageBlueprint.deletedAt) {
              dialog({
                confirm: `Are you sure you want to restore this stage (${stageBlueprint.name})?`,
                props: {
                  onAccept: () => {
                    void editStageBlueprintMeta({
                      variables: {
                        id: stageBlueprint.id,
                        name: stageBlueprint.name,
                        position: stageBlueprint.position,
                        restoreDeleted: true,
                      },
                    });
                  },
                  rejectLabel: 'Cancel',
                  rejectClassName: 'p-button-text text-color-body',
                  acceptLabel: 'Restore',
                },
              });
            } else {
              dialog({
                confirm: `Are you sure you want to delete this stage (${stageBlueprint.name})?`,
                props: {
                  onAccept: () => {
                    void deleteStageBlueprint({
                      variables: {
                        id: stageBlueprint.id,
                      },
                    });
                  },
                  rejectLabel: 'Cancel',
                  rejectClassName: 'p-button-text text-color-body',
                  acceptLabel: 'Delete',
                  acceptClassName: 'p-button-danger',
                },
              });
            }
          }}
        />
      </div>
      {isEditing && (
        <Fragment>
          <div className="input-field">
            <label htmlFor={`stagePosition${stageBlueprint.id}`} className="block text-sm">
              Position
            </label>
            <InputNumber
              inputId={`stagePosition${stageBlueprint.id}`}
              inputClassName="w-5rem"
              value={position}
              onChange={({ value }) => {
                if (isNumber(value)) {
                  setPosition(value);
                } else {
                  setPosition(null);
                }
              }}
            />
          </div>
          <div className="flex gap-3 align-items-end">
            <Button
              label="Cancel"
              className="p-button-secondary"
              disabled={isSavingEdits}
              onClick={() => {
                setIsEditing(false);
              }}
            />
            <Button
              label="Save"
              disabled={
                (name === stageBlueprint.name && position === stageBlueprint.position) ||
                isSavingEdits
              }
              onClick={() => {
                if (position) {
                  void editStageBlueprintMeta({
                    variables: {
                      id: stageBlueprint.id,
                      name,
                      position,
                      restoreDeleted: false,
                    },
                  });
                }
              }}
            />
          </div>
        </Fragment>
      )}
      <Tooltip target={`.${deletedLabelClassName}`} />
    </div>
  );
};

const SequenceBody: FunctionComponent<{ id: string }> = ({ id }) => {
  const { navigate } = usePlatformContext();
  const dialog = useDialog();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showAddStage, setShowAddStage] = useState(false);
  const [newStageBlueprintName, setNewStageBlueprintName] = useState<string>('');
  const [newStageBlueprintPosition, setNewStageBlueprintPosition] = useState<number | null>(null);

  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading: isLoading } = useQuery(SequenceDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });
  const [editSequence, { loading: isSavingEdits }] = useMutation(EditSequenceDocument, {
    onError,
    onCompleted: (data) => {
      if (data.editSequence.__typename === 'EditSequenceSuccessResult') {
        setHasUnsavedChanges(false);
      } else {
        onError(data.editSequence.message);
      }
    },
  });
  const [addStage, { loading: isAddingStage }] = useMutation(CreateStageBlueprintDocument, {
    onError,
    refetchQueries: [{ query: SequenceDocument, variables: { id } }],
    onCompleted: () => {
      setShowAddStage(false);
      setNewStageBlueprintName('');
      setNewStageBlueprintPosition(null);
    },
  });

  const [name, setName] = useState<string | null>();
  const [loopStages, setLoopStages] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setName(data.sequence.name);
      setLoopStages(data.sequence.loopStages);
    }
  }, [data]);

  // Sort by position, placing all deleted stage blueprints last, also sorted.
  const stageBlueprints =
    data?.sequence.stageBlueprints.toSorted((a, b) => {
      if (a.deletedAt) {
        if (b.deletedAt) {
          return a.position - b.position;
        }
        return 1;
      }
      if (b.deletedAt) {
        return -1;
      }
      return a.position - b.position;
    }) || [];

  const nameTrimmed = name?.trim();

  return (
    <PageContainer
      title="Sequence"
      onLinkClick={(to) => {
        if (hasUnsavedChanges) {
          dialog({
            confirm: 'You have unsaved changes. Are you sure you want to leave?',
            props: {
              onAccept: () => {
                navigate(to);
              },
            },
          });
          return false;
        }
        return true;
      }}
    >
      <ErrorMessage />
      {isLoading ? (
        <Spinner />
      ) : (
        data && (
          <Fragment>
            <div className="flex mt-1 md:mt-0 mb-3 gap-2 md:gap-3 flex-column">
              <div className="input-field mb-0">
                <label htmlFor="sequenceName">Name</label>
                <InputText
                  id="sequenceName"
                  className="w-25rem max-w-full"
                  value={name || ''}
                  onChange={(e) => {
                    setName(e.target.value);
                    setHasUnsavedChanges(true);
                  }}
                />
              </div>
              <div className="flex align-items-center gap-2">
                <Checkbox
                  inputId="sequenceLoopStages"
                  checked={loopStages}
                  onChange={(e) => {
                    setLoopStages(!!e.checked);
                    setHasUnsavedChanges(true);
                  }}
                />
                <label htmlFor="sequenceLoopStages">Loop stages</label>
              </div>
              <Button
                label="Save"
                className="w-7rem"
                disabled={isSavingEdits || !nameTrimmed || !hasUnsavedChanges}
                onClick={() => {
                  if (!nameTrimmed) {
                    return;
                  }
                  void editSequence({
                    variables: {
                      id,
                      name: nameTrimmed,
                      loopStages,
                    },
                  });
                }}
              />
            </div>
            {showAddStage ? (
              <div className="p-3 border-1 border-round">
                <div className="input-field">
                  <label htmlFor="newStageName">Name</label>
                  <InputText
                    id="newStageName"
                    className="w-full"
                    value={newStageBlueprintName}
                    onChange={(e) => {
                      setNewStageBlueprintName(e.target.value);
                    }}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="newStagePosition">Position</label>
                  <InputNumber
                    inputId="newStagePosition"
                    inputClassName="w-5rem"
                    value={newStageBlueprintPosition}
                    onChange={(e) => {
                      setNewStageBlueprintPosition(e.value);
                    }}
                  />
                </div>
                <div className="flex gap-3 mt-3">
                  <Button
                    label="Add"
                    disabled={
                      !newStageBlueprintName.trim() || !newStageBlueprintPosition || isAddingStage
                    }
                    onClick={() => {
                      if (newStageBlueprintName && newStageBlueprintPosition) {
                        void addStage({
                          variables: {
                            planId: id,
                            name: newStageBlueprintName,
                            position: newStageBlueprintPosition,
                          },
                        });
                      }
                    }}
                  />
                  <Button
                    label="Cancel"
                    className="p-button-secondary"
                    disabled={isAddingStage}
                    onClick={() => {
                      setShowAddStage(false);
                      setNewStageBlueprintName('');
                      setNewStageBlueprintPosition(null);
                    }}
                  />
                </div>
              </div>
            ) : (
              <Button
                label="Add stage"
                onClick={() => {
                  setShowAddStage(true);
                }}
              />
            )}
            <div className="flex flex-column gap-3 mt-4">
              {stageBlueprints.map((sb) => (
                <StageBlueprintOverview
                  key={sb.id}
                  sequenceId={data.sequence.id}
                  stageBlueprint={sb}
                  allStageBlueprints={stageBlueprints}
                />
              ))}
            </div>
          </Fragment>
        )
      )}
    </PageContainer>
  );
};

export const Sequence: FunctionComponent = () => {
  const { sequenceId } = useParams<{ sequenceId: string }>();

  if (sequenceId) {
    return <SequenceBody id={sequenceId} />;
  }

  return null;
};
