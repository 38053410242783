import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../../hooks/useMenuSetup.js';
import { clickableRowClassName } from '../../../../../util/clickableRowClassName.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../Spinner.js';
import { ThreeDotMenuButton } from '../../../../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { navItems } from '../nav.js';
import { CreateCourse } from './create-course/create-course.js';
import { DuplicateCourseDocument } from './mutations.generated.js';
import { CoursesDocument, CoursesQuery } from './queries.generated.js';

type Course = CoursesQuery['courses']['courses'][number];

export const Courses: FunctionComponent = () => {
  const { id: platformId, handle, navigate } = usePlatformContext();
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(CoursesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
    },
  });
  const [duplicateCourse, { loading: isDuplicateLoading }] = useMutation(DuplicateCourseDocument, {
    onError,
    onCompleted: (data) => {
      if (data.duplicateCourse.__typename === 'DuplicateCourseSuccessResult') {
        toastSuccess('Course duplicated.');
        navigate(`/content/courses/${data.duplicateCourse.course.id}`);
      } else {
        onError(data.duplicateCourse.message);
      }
    },
  });
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<Course>();
  const [showCreate, setShowCreate] = useState(false);

  return (
    <PageContainer title="Courses" nav={navItems(handle)}>
      <ErrorMessage />
      {showCreate ? (
        <CreateCourse
          className="mt-1 mb-4"
          onCreated={({ id }) => {
            navigate(`/content/courses/${id}`);
          }}
          onCancel={() => {
            setShowCreate(false);
          }}
        />
      ) : (
        <Button
          label="Create course"
          className="mb-3"
          onClick={() => {
            setShowCreate(true);
          }}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <DataTable
          value={data?.courses.courses ?? []}
          loading={loading}
          rowClassName={clickableRowClassName}
          onRowClick={(e) => {
            navigate(`/content/courses/${(e.data as Course).id}`);
          }}
        >
          <Column header="Name" field="name" />
          <Column
            header=""
            align="right"
            body={(row: Course) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
          />
        </DataTable>
      )}
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            disabled: isDuplicateLoading,
            command: () => {
              if (menuRow) {
                void duplicateCourse({
                  variables: { id: menuRow.id },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
