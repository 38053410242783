import { useEffect } from 'react';

export const useOnMount = (fn: () => void | Promise<void>): void => {
  useEffect(
    () => {
      void fn();
    },
    [] /* eslint-disable-line react-hooks/exhaustive-deps -- Only the value of fn on mount matters. */,
  );
};
