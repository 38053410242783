import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../../hooks/useMenuSetup.js';
import { clickableRowClassName } from '../../../../../util/clickableRowClassName.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../Spinner.js';
import { ThreeDotMenuButton } from '../../../../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { navItems } from '../nav.js';
import { CreateSequence } from './create-sequence/create-sequence.js';
import { DuplicateSequenceDocument } from './mutations.generated.js';
import { SequencesDocument, SequencesQuery } from './queries.generated.js';

type Sequence = SequencesQuery['sequences']['sequences'][number];

export const Sequences: FunctionComponent = () => {
  const { id: platformId, handle, navigate } = usePlatformContext();
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(SequencesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
    },
  });
  const [duplicateSequence, { loading: isDuplicateLoading }] = useMutation(
    DuplicateSequenceDocument,
    {
      onError,
      onCompleted: (data) => {
        if (data.duplicateSequence.__typename === 'DuplicateSequenceSuccessResult') {
          toastSuccess('Sequence duplicated.');
          navigate(`/content/sequences/${data.duplicateSequence.sequence.id}`);
        } else {
          onError(data.duplicateSequence.message);
        }
      },
    },
  );
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<Sequence>();
  const [showCreate, setShowCreate] = useState(false);

  return (
    <PageContainer title="Sequences" nav={navItems(handle)}>
      <ErrorMessage />
      {showCreate ? (
        <CreateSequence
          className="mt-1 mb-4"
          onCreated={(id) => {
            navigate(`/content/sequences/${id}`);
          }}
          onCancel={() => {
            setShowCreate(false);
          }}
        />
      ) : (
        <Button
          label="Create sequence"
          className="mb-3"
          onClick={() => {
            setShowCreate(true);
          }}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <DataTable<Sequence[]>
          value={data?.sequences.sequences ?? []}
          loading={loading}
          rowClassName={clickableRowClassName}
          onRowClick={(e) => {
            navigate(`/content/sequences/${(e.data as Sequence).id}`);
          }}
          emptyMessage="No sequences found"
        >
          <Column header="Name" field="name" />
          <Column
            header=""
            align="right"
            body={(row: Sequence) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
          />
        </DataTable>
      )}
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            disabled: isDuplicateLoading,
            command: () => {
              if (menuRow) {
                void duplicateSequence({
                  variables: { id: menuRow.id },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
