import { Permission, Platform } from '@wirechunk/lib/api.js';
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentUser } from '../../CurrentUserContext/CurrentUserContext.js';

export type PlatformContext = {
  id: string;
  name: string;
  handle: string;
  // The current user's permissions on the platform.
  permissions: Permission[];
  mainSiteId: string | null | undefined;
  navigate: (path: string) => void;
};

// The default value is a Proxy that throws an error if any property is accessed.
const defaultValue = new Proxy<PlatformContext>({} as never, {
  get: () => {
    throw new Error('PlatformContext is not initialized.');
  },
});

const context = createContext<PlatformContext>(defaultValue);

export const PlatformContextProviderDirect = context.Provider;

export const PlatformContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { platformHandle } = useParams<{ platformHandle: string }>();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const platform = useMemo<Pick<Platform, 'id' | 'name' | 'handle' | 'mainSiteId'> | null>(() => {
    const userPermission = user.platformPermissions.find(
      ({ platform }) => platform.handle === platformHandle,
    );
    if (userPermission) {
      return userPermission.platform;
    }
    return null;
  }, [user.platformPermissions, platformHandle]);

  const navigateProvided = useCallback<PlatformContext['navigate']>(
    (path) => {
      if (platform?.handle) {
        navigate(`/dashboard/${platform.handle}${path}`);
      }
    },
    [navigate, platform?.handle],
  );

  const value = useMemo<PlatformContext | null>(() => {
    if (platform) {
      return {
        id: platform.id,
        name: platform.name,
        handle: platform.handle,
        permissions:
          user.platformPermissions.find(({ platformId }) => platformId === platform.id)
            ?.permissions || [],
        mainSiteId: platform.mainSiteId,
        navigate: navigateProvided,
      };
    }
    return null;
  }, [platform, user.platformPermissions, navigateProvided]);

  if (value) {
    return <context.Provider value={value}>{children}</context.Provider>;
  }

  return <div>It appears that you do not have admin permissions on this platform.</div>;
};

export const usePlatformContext = (): PlatformContext => useContext(context);
