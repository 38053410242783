import { CourseComponent } from '@wirechunk/lib/mixer/types/components.js';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import type { ContentPlan } from '../../../../hooks/use-content-plans/use-content-plans.js';
import { usePlanSelectorOptions } from '../../../../hooks/use-plan-selector-options/use-plan-selector-options.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { CheckboxWithLabel } from '../../../checkbox-with-label/checkbox-with-label.js';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { defaultEmptyLessonNotes } from '../../../mixer/course/course.js';
import { Spinner } from '../../../Spinner.js';
import type { EditComponentContentProps } from '../shared/types.js';

const isCourse = (plan: ContentPlan): boolean => plan.__typename === 'Course';

export const EditCourse: FunctionComponent<EditComponentContentProps<CourseComponent>> = ({
  component,
  setComponent,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { options, loading: loadingPlanOptions } = usePlanSelectorOptions(
    platformId,
    isCourse,
    onError,
  );

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="editCoursePlanId">Course</label>
        {loadingPlanOptions ? (
          <Spinner />
        ) : (
          <Dropdown
            inputId="editCoursePlanId"
            className="w-full"
            options={options}
            filter
            value={component.planId || null}
            onChange={({ value }) => {
              if (isString(value)) {
                setComponent(
                  (c): CourseComponent => ({
                    ...c,
                    planId: value,
                  }),
                );
              }
            }}
          />
        )}
      </div>
      <CheckboxWithLabel
        id="editCourseEnableNotes"
        label="Enable notes"
        checked={component.enableNotes ?? false}
        onChange={({ checked }) => {
          setComponent((c) => ({ ...c, enableNotes: checked }));
        }}
      />
      {component.enableNotes && (
        <div className="input-field">
          <label htmlFor="editCourseEmptyNotesMessage">Empty notes message</label>
          <InputText
            id="editCourseEmptyNotesMessage"
            className="w-full"
            placeholder={defaultEmptyLessonNotes}
            value={component.emptyNotesMessage || ''}
            onChange={(e) => {
              setComponent((c) => ({ ...c, emptyNotesMessage: e.target.value }));
            }}
          />
          <InputNotice>
            This message will be displayed to users who do not have any notes.
          </InputNotice>
        </div>
      )}
    </Fragment>
  );
};
