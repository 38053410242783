import type { TimeLiteral as TimeLiteralType } from '@wirechunk/schemas/expressions/number-expression';
import { isDate } from 'lodash-es';
import { Calendar } from 'primereact/calendar';
import { Fragment, FunctionComponent } from 'react';
import type { BuilderProps } from './shared.js';

export const TimeLiteral: FunctionComponent<BuilderProps<TimeLiteralType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div className="input-field mb-0">
        <label htmlFor={`${id}-timeLiteral`}>Time literal value</label>
        <Calendar
          inputId={`${id}-timeLiteral`}
          value={expression.value ? new Date(expression.value) : null}
          onChange={({ value }) => {
            if (isDate(value)) {
              setExpression({
                ...expression,
                value: value.getTime(),
              });
            }
          }}
          showTime
          hourFormat="12"
        />
      </div>
    </Fragment>
  );
};
