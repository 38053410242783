import { DataSource, StringValueSource } from '@wirechunk/lib/mixer/types/components.js';
import { stringOrDefaultEmpty } from '@wirechunk/lib/strings.js';
import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import { DataSourceDropdown } from '../../components/data-source-dropdown.js';
import type { EditComponent } from '../../editComponentByType.js';

export const DefaultStringValueTab: EditComponent = ({
  component: { defaultValue },
  setComponent,
}) => {
  return (
    <Fragment>
      <DataSourceDropdown
        parentId="defaultStringValueTab"
        label="Value source"
        type={defaultValue?.type || DataSource.Direct}
        onChange={(type) => {
          setComponent((c) => ({
            ...c,
            defaultValue:
              type === DataSource.Direct
                ? ({ type, value: '' } satisfies StringValueSource)
                : ({ type, name: '' } satisfies StringValueSource),
          }));
        }}
      />
      {!defaultValue || defaultValue.type === DataSource.Direct ? (
        <div className="input-field">
          <label htmlFor="defaultStringValueTabValue">Default value</label>
          <InputText
            id="defaultStringValueTabValue"
            className="w-full"
            value={stringOrDefaultEmpty(defaultValue?.value)}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                defaultValue: {
                  ...c.defaultValue,
                  type: DataSource.Direct,
                  value: e.target.value,
                } satisfies StringValueSource,
              }));
            }}
          />
        </div>
      ) : (
        <div className="input-field">
          <label htmlFor="defaultStringValueTabPropName">Prop name</label>
          <InputText
            id="defaultStringValueTabPropName"
            className="w-full"
            value={defaultValue.name || ''}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                defaultValue: {
                  ...c.defaultValue,
                  type: DataSource.Prop,
                  name: e.target.value,
                } satisfies StringValueSource,
              }));
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
