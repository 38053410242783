import { useLazyQuery } from '@apollo/client';
import { RemapPropertiesToUnknown } from '@wirechunk/lib/util-types.js';
import { isPlainObject } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useEffect, useState } from 'react';
import { ErrorHandler } from '../../hooks/useErrorHandler.js';
import { NoneLabel } from '../NoneLabel/NoneLabel.js';
import { OrganizationsDocument, OrganizationsQuery } from './queries.generated.js';

type Org = OrganizationsQuery['organizations']['organizations'][number];

const isOrg = (value: unknown): value is Org =>
  isPlainObject(value) && !!(value as RemapPropertiesToUnknown<Org>).id;

type OrganizationSelectorProps = {
  platformId: string;
  inputId?: string;
  className?: string;
  showClear?: boolean;
  organizationId: string | null;
  excludeOrganizationIds?: Set<string>;
  setOrganizationId: (organizationId: string | null) => void;
  onError: ErrorHandler['onError'];
};

export const OrganizationSelector: FunctionComponent<OrganizationSelectorProps> = ({
  platformId,
  inputId,
  className,
  showClear,
  organizationId,
  excludeOrganizationIds,
  setOrganizationId,
  onError,
}) => {
  const [organizationsSearch, setOrganizationsSearch] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState<Org[]>([]);

  const [fetchOrganizations, { loading: isLoadingOrganizations }] = useLazyQuery(
    OrganizationsDocument,
    {
      onError,
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    void fetchOrganizations({
      variables: {
        platformId,
        search: organizationsSearch,
      },
      onCompleted: (data) => {
        setOrganizationOptions(
          excludeOrganizationIds
            ? data.organizations.organizations.filter((org) => !excludeOrganizationIds.has(org.id))
            : data.organizations.organizations,
        );
      },
    });
  }, [excludeOrganizationIds, fetchOrganizations, organizationsSearch, platformId]);

  const org = organizationOptions.find((org) => org.id === organizationId) || null;

  return (
    <Dropdown
      inputId={inputId}
      className={className}
      placeholder="Select an org"
      value={org}
      showClear={showClear}
      disabled={isLoadingOrganizations}
      options={organizationOptions}
      optionLabel="id"
      onChange={({ value }) => {
        if (!value) {
          setOrganizationId(null);
        } else if (isOrg(value)) {
          setOrganizationId(value.id);
        }
      }}
      itemTemplate={(org: Org) => (
        <div>
          <div>
            {org.primaryUser ? (
              org.primaryUser.displayName
            ) : (
              <span className="text-color-muted">No primary user</span>
            )}
          </div>
          {org.primaryUser && (
            <div className="text-sm text-color-muted">{org.primaryUser.email}</div>
          )}
          <div className="text-sm text-color-muted">Org name: {org.name || <NoneLabel />}</div>
        </div>
      )}
      valueTemplate={(org: Org | null) =>
        org
          ? org.primaryUser
            ? `${org.primaryUser.displayName} (${org.primaryUser.email})`
            : `<No primary user> ${org.name || ''}`
          : 'Select an org'
      }
      filter
      filterTemplate={() => (
        <InputText
          className="w-full"
          value={organizationsSearch}
          onChange={(e) => {
            setOrganizationsSearch(e.target.value);
          }}
        />
      )}
    />
  );
};
