/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type SiteSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type SiteSettingsQuery = {
  site: {
    __typename: 'Site';
    headJavaScriptCode?: string | null;
    headEndCode?: string | null;
    stripePublishableKey?: string | null;
    id: string;
    platformId: string;
    organizationId?: string | null;
    name: string;
    domain: string;
    agreementConsentsRequired: Array<Types.AgreementType>;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    agentPhotoUrl?: string | null;
    agencyPrimaryPhone?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    addressCity?: string | null;
    addressState?: string | null;
    addressZip?: string | null;
    radixThemeColorName?: string | null;
    globalCss?: string | null;
    fontFamily: string;
    googlePlaceId?: string | null;
    logoIconUrl?: string | null;
    logoUrl?: string | null;
    inputFieldFontFamily: string;
    textColorPrimaryH: string;
    textColorPrimaryS: string;
    textColorPrimaryL: string;
    privacyPolicyUrl?: string | null;
    termsOfUseUrl?: string | null;
    googleTagManagerId?: string | null;
    metaPixelId?: string | null;
    mixpanelToken?: string | null;
    zendeskChatKey?: string | null;
    colorPrimary: { __typename: 'ColorHsl'; h: string; s: string; l: string };
    colorPrimaryDark: { __typename: 'ColorHsl'; h: string; s: string; l: string };
    colorPrimaryLight: { __typename: 'ColorHsl'; h: string; s: string; l: string };
    authenticatedMainNavItems?: Array<{
      __typename: 'NavLinkItem';
      id: string;
      label: string;
      url: string;
      external: boolean;
      featureTag?: string | null;
      roles?: Array<Types.Role> | null;
    }> | null;
    sidebarExtraItems?: Array<{
      __typename: 'NavLinkItem';
      id: string;
      label: string;
      url: string;
      external: boolean;
      featureTag?: string | null;
      roles?: Array<Types.Role> | null;
    }> | null;
  };
};

export type SiteTlsCertificatesQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type SiteTlsCertificatesQuery = {
  site: {
    __typename: 'Site';
    id: string;
    tlsCertificates: Array<{
      __typename: 'TlsCertificate';
      id: string;
      name: string;
      domain: string;
      state: Types.GCloudCertificateState;
      provisioningIssueDetails?: string | null;
      mapEntryState?: Types.GCloudCertificateMapEntryState | null;
    }>;
  };
};

export const SiteSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SiteSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SiteContext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headJavaScriptCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headEndCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripePublishableKey' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agreementConsentsRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentPhotoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyPrimaryPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressZip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'radixThemeColorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalCss' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorPrimary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'h' } },
                { kind: 'Field', name: { kind: 'Name', value: 's' } },
                { kind: 'Field', name: { kind: 'Name', value: 'l' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorPrimaryDark' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'h' } },
                { kind: 'Field', name: { kind: 'Name', value: 's' } },
                { kind: 'Field', name: { kind: 'Name', value: 'l' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorPrimaryLight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'h' } },
                { kind: 'Field', name: { kind: 'Name', value: 's' } },
                { kind: 'Field', name: { kind: 'Name', value: 'l' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoIconUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inputFieldFontFamily' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorPrimaryH' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorPrimaryS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textColorPrimaryL' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticatedMainNavItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'external' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featureTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sidebarExtraItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'external' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featureTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'termsOfUseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleTagManagerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaPixelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mixpanelToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zendeskChatKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteSettingsQuery, SiteSettingsQueryVariables>;
export const SiteTlsCertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SiteTlsCertificates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'site' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tlsCertificates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'provisioningIssueDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mapEntryState' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteTlsCertificatesQuery, SiteTlsCertificatesQueryVariables>;
