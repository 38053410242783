import { useMutation } from '@apollo/client';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormContextProvider } from '../../../../../../../contexts/FormContext/form-context.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../contexts/PageContext/PageContext.js';
import { useToast } from '../../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../hooks/useHasUnsavedChanges.js';
import { VisualBuilder } from '../../../../../../VisualBuilder/VisualBuilder.js';
import { withEditFormContext } from './edit-form-context.js';
import { EditFormDocument } from './mutations.generated.js';

const pageContext: PageContext = {
  title: '',
  viewMode: ViewMode.Preview,
};

export const Design = withEditFormContext(({ form, formContext, site: { site } }) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();

  const [editForm, { loading: saving }] = useMutation(EditFormDocument, {
    onError,
    onCompleted: () => {
      toastSuccess('Form saved.');
      resetHasUnsavedChanges();
    },
  });

  const [title, setTitle] = useState('');
  const [components, setComponents] = useState<Component[]>([]);

  useEffect(() => {
    setComponents(parseComponents(form.components));
  }, [form.components]);

  const setComponentsWrapped = useCallback<Dispatch<SetStateAction<Component[]>>>(
    (components) => {
      setComponents(components);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  useEffect(() => {
    setTitle(form.title);
  }, [form.title]);

  return (
    <div className="mt-3">
      <ErrorMessage />
      <Button
        className="mb-2"
        label="Save"
        disabled={!hasUnsavedChanges || saving}
        onClick={() => {
          clearMessages();
          void editForm({
            variables: {
              id: form.id,
              title,
              confirmationAction: form.confirmationAction,
              confirmationRedirectUrl: form.confirmationRedirectUrl,
              components: JSON.stringify(components),
              confirmationMessageComponents: form.confirmationMessageComponents,
              confirmationRedirectUrlParameters: form.confirmationRedirectParameters.map(
                ({ __typename: _ignored, ...rest }) => rest,
              ),
            },
          });
        }}
      />
      <div className="input-field">
        <label htmlFor="formTitle">Title</label>
        <InputText
          id="formTitle"
          className="w-30rem max-w-full"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <VisualBuilder
        siteContext={site}
        components={components}
        setComponents={setComponentsWrapped}
        onPreview={(children) => (
          <PageContextProvider value={pageContext}>
            <FormContextProvider value={formContext}>{children}</FormContextProvider>
          </PageContextProvider>
        )}
      />
    </div>
  );
});
