import { useQuery } from '@apollo/client';
import { QueryOptions } from '@apollo/client/core/watchQueryOptions.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import type { FormSubmissionActionFieldsFragment } from './fragments.generated.js';
import {
  FormSubmissionActionsDocument,
  FormSubmissionActionsQuery,
  FormSubmissionActionsQueryVariables,
} from './queries.generated.js';

type FormSubmissionActions = {
  formSubmissionActions: FormSubmissionActionFieldsFragment[] | undefined;
  loadingFormSubmissionActions: boolean;
  formSubmissionActionsQuery: QueryOptions<
    FormSubmissionActionsQueryVariables,
    FormSubmissionActionsQuery
  >;
};

export const useFormSubmissionActions = (
  formId: string,
  onError: ErrorHandler['onError'],
): FormSubmissionActions => {
  const formSubmissionActionsQuery: FormSubmissionActions['formSubmissionActionsQuery'] = {
    query: FormSubmissionActionsDocument,
    variables: { id: formId },
  };
  const { data, loading } = useQuery(FormSubmissionActionsDocument, {
    ...formSubmissionActionsQuery,
    onError,
    fetchPolicy: 'cache-and-network',
  });

  return {
    formSubmissionActions: data?.form.submissionActions,
    loadingFormSubmissionActions: loading,
    formSubmissionActionsQuery,
  };
};
