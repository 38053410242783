import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { ViewPlanStagesButtonComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { FunctionComponent } from 'react';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useStageContext } from '../../../contexts/StageContext/StageContext.js';
import { PlanStages } from './PlanStages.js';

const defaultButtonLabel = 'View all lessons';
export const defaultDialogHeader = 'All lessons';

export const ViewPlanStagesButton: FunctionComponent<ViewPlanStagesButtonComponent> = (props) => {
  const stageContext = useStageContext();
  const dialog = useDialog();
  const { user, loadingUser } = useCurrentUser();

  if (!stageContext) {
    return null;
  }

  return (
    <Button
      className={componentClassName(props)}
      label={props.label || defaultButtonLabel}
      onClick={() => {
        dialog({
          content: (
            <CurrentUserProvider user={user} loadingUser={loadingUser}>
              <PlanStages
                planId={stageContext.stageBlueprint.planId}
                stageNameTableHeader={props.stageNameTableHeader}
              />
            </CurrentUserProvider>
          ),
          props: {
            header: props.dialogHeader || defaultDialogHeader,
            className: 'dialog-width-xl',
            contentClassName: 'flex align-items-stretch p-0',
            dismissableMask: true,
          },
        });
      }}
    />
  );
};
