import { roundToDayEnd, roundToDayStart } from '@wirechunk/lib/dates.js';
import { Button } from 'primereact/button';
import { FunctionComponent } from 'react';

type DownloadCsvProps = {
  siteId: string;
  formId?: string | null;
  fromDate: Date | null;
  toDate: Date | null;
};

export const DownloadCsv: FunctionComponent<DownloadCsvProps> = ({
  siteId,
  formId,
  fromDate,
  toDate,
}) => (
  <Button
    label="Download CSV"
    onClick={() => {
      const url = new URL('/api/form-entries-csv', window.location.origin);
      const timeZone = new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
      url.searchParams.set('siteId', siteId);
      if (formId) {
        url.searchParams.set('formId', formId);
      }
      url.searchParams.set('formatTimeZone', timeZone);
      if (fromDate) {
        const fromTime = roundToDayStart(fromDate);
        url.searchParams.set('fromTime', fromTime.toISOString());
      }
      if (toDate) {
        const toTime = roundToDayEnd(toDate);
        url.searchParams.set('toTime', toTime.toISOString());
      }
      window.open(url.toString(), '_blank');
    }}
  />
);
