import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types/index.js';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { insertAtIndex } from '@wirechunk/lib/arrays.js';
import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import { noop } from 'lodash-es';
import { Button } from 'primereact/button';
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useProductItemPicklist } from '../../../../../../../hooks/use-product-item-picklist/use-product-item-picklist.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { Spinner } from '../../../../../../Spinner.js';
import { NavItem, NavLinkItem } from './nav-item.js';

const dragModifiers = [restrictToVerticalAxis, restrictToParentElement];

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type NavDesignProps = {
  platformId: string;
  items: NavLinkItem[];
  setItems: Dispatch<SetStateAction<NavLinkItem[]>>;
};

export const NavDesign: FunctionComponent<NavDesignProps> = ({ platformId, items, setItems }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { productItems, loading: loadingTags } = useProductItemPicklist(platformId, onError);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [activeItem, setActiveItem] = useState<NavLinkItem | null>(null);

  const handleDragStart = useCallback(
    ({ active }: DragEndEvent) => {
      setActiveItem(items.find(({ id }) => id === active.id) ?? null);
    },
    [items],
  );

  const clearActiveItem = useCallback(() => {
    setActiveItem(null);
  }, []);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      clearActiveItem();

      if (over) {
        setItems((items) => {
          const overIndex = items.findIndex(({ id }) => id === over.id);
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          if (activeIndex !== overIndex) {
            return arrayMove(items, activeIndex, overIndex);
          }
          return items;
        });
      }
    },
    [clearActiveItem, setItems],
  );

  return (
    <div>
      <ErrorMessage />
      {loadingTags ? (
        <Spinner />
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={clearActiveItem}
          modifiers={dragModifiers}
        >
          <Fragment>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              <div className="flex flex-column gap-3">
                {items.map((item, index) => (
                  <NavItem
                    key={item.id}
                    item={item}
                    setItem={(item) => {
                      setItems((items) => items.map((i) => (i.id === item.id ? item : i)));
                    }}
                    removeItem={() => {
                      setItems((items) => items.filter(({ id }) => id !== item.id));
                    }}
                    duplicateItem={() => {
                      setItems((items) =>
                        insertAtIndex(items, index, { ...item, id: cleanSmallId() }),
                      );
                    }}
                    productItemOptions={productItems}
                  />
                ))}
              </div>
            </SortableContext>
            {createPortal(
              <DragOverlay dropAnimation={dropAnimationConfig}>
                {activeItem && (
                  <NavItem
                    item={activeItem}
                    setItem={noop}
                    removeItem={noop}
                    duplicateItem={noop}
                    productItemOptions={[]}
                    dragOverlay
                  />
                )}
              </DragOverlay>,
              document.body,
            )}
          </Fragment>
        </DndContext>
      )}
      <Button
        label="Add item"
        className={items.length ? 'mt-3' : undefined}
        onClick={() => {
          setItems((items) => [
            ...items,
            {
              id: cleanSmallId(),
              label: '',
              url: '',
              external: false,
            },
          ]);
        }}
      />
    </div>
  );
};
