import { PlatformOptionKey } from '@wirechunk/lib/platform-options.js';
import { intersection, isEqual } from 'lodash-es';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FunctionComponent, useEffect, useState } from 'react';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { OptionsSection } from '../../../OptionsSection.js';
import { PlatformSettingsOptionsSectionProps } from './types.js';

export const ZendeskSection: FunctionComponent<PlatformSettingsOptionsSectionProps> = ({
  platform,
  savingKeys,
  saveOptions,
}) => {
  const [zendeskSubdomain, setZendeskSubdomain] = useState<string | null>(
    platform.zendeskSubdomain || null,
  );
  const [zendeskApiToken, setZendeskApiToken] = useState<string | null>(
    platform.zendeskApiToken || null,
  );
  const [zendeskUserAuthJwtKey, setZendeskUserAuthJwtKey] = useState<string | null>(
    platform.zendeskUserAuthJwtKey || null,
  );
  const [zendeskUserAuthJwtKeyId, setZendeskUserAuthJwtKeyId] = useState<string | null>(
    platform.zendeskUserAuthJwtKeyId || null,
  );
  const [zendeskUsername, setZendeskUsername] = useState<string | null>(
    platform.zendeskUsername || null,
  );
  const [zendeskWebhookSigningSecret, setZendeskWebhookSigningSecret] = useState<string | null>(
    platform.zendeskWebhookSigningSecret || null,
  );
  const platformZendeskHelpTicketTagsJoined = platform.zendeskHelpTicketTags?.join(',') || '';
  const [zendeskHelpTicketTags, setZendeskHelpTicketTags] = useState<string>(
    platformZendeskHelpTicketTagsJoined,
  );

  useEffect(() => {
    setZendeskSubdomain(platform.zendeskSubdomain || null);
    setZendeskApiToken(platform.zendeskApiToken || null);
    setZendeskUserAuthJwtKey(platform.zendeskUserAuthJwtKey || null);
    setZendeskUserAuthJwtKeyId(platform.zendeskUserAuthJwtKeyId || null);
    setZendeskUsername(platform.zendeskUsername || null);
    setZendeskWebhookSigningSecret(platform.zendeskWebhookSigningSecret || null);
    setZendeskHelpTicketTags(platformZendeskHelpTicketTagsJoined);
  }, [
    platform.zendeskSubdomain,
    platform.zendeskApiToken,
    platform.zendeskUserAuthJwtKey,
    platform.zendeskUserAuthJwtKeyId,
    platform.zendeskUsername,
    platform.zendeskWebhookSigningSecret,
    platformZendeskHelpTicketTagsJoined,
  ]);

  return (
    <OptionsSection
      title="Zendesk"
      allowSave={
        intersection(savingKeys, [
          PlatformOptionKey.ZendeskSubdomain,
          PlatformOptionKey.ZendeskApiToken,
          PlatformOptionKey.ZendeskUserAuthJwtKey,
          PlatformOptionKey.ZendeskUserAuthJwtKeyId,
          PlatformOptionKey.ZendeskUsername,
          PlatformOptionKey.ZendeskWebhookSigningSecret,
        ]).length === 0 &&
        ((zendeskSubdomain || null) !== (platform.zendeskSubdomain || null) ||
          (zendeskApiToken || null) !== (platform.zendeskApiToken || null) ||
          (zendeskUserAuthJwtKey || null) !== (platform.zendeskUserAuthJwtKey || null) ||
          (zendeskUserAuthJwtKeyId || null) !== (platform.zendeskUserAuthJwtKeyId || null) ||
          (zendeskUsername || null) !== (platform.zendeskUsername || null) ||
          (zendeskWebhookSigningSecret || null) !==
            (platform.zendeskWebhookSigningSecret || null) ||
          !isEqual(zendeskHelpTicketTags.split(','), platform.zendeskHelpTicketTags || []))
      }
      getOptions={() => [
        { key: PlatformOptionKey.ZendeskSubdomain, value: zendeskSubdomain },
        { key: PlatformOptionKey.ZendeskApiToken, value: zendeskApiToken },
        { key: PlatformOptionKey.ZendeskUserAuthJwtKey, value: zendeskUserAuthJwtKey },
        { key: PlatformOptionKey.ZendeskUserAuthJwtKeyId, value: zendeskUserAuthJwtKeyId },
        { key: PlatformOptionKey.ZendeskUsername, value: zendeskUsername },
        { key: PlatformOptionKey.ZendeskWebhookSigningSecret, value: zendeskWebhookSigningSecret },
        {
          key: PlatformOptionKey.ZendeskHelpTicketTags,
          // Split on commas, trim whitespace around each tag, and keep only non-empty strings.
          value: zendeskHelpTicketTags
            .split(',')
            .map((tag) => tag.trim())
            .filter(Boolean),
        },
      ]}
      saveOptions={saveOptions}
    >
      <div className="input-field">
        <label htmlFor="platformZendeskSubdomain">Zendesk subdomain</label>
        <InputText
          id="platformZendeskSubdomain"
          className="w-full"
          value={zendeskSubdomain || ''}
          onChange={(e) => {
            setZendeskSubdomain(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformZendeskApiToken">Zendesk API token</label>
        <Password
          inputId="platformZendeskApiToken"
          className="w-full"
          inputClassName="w-full"
          value={zendeskApiToken || ''}
          onChange={(e) => {
            setZendeskApiToken(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformZendeskUserAuthJwtKey">Zendesk user auth JWT key</label>
        <Password
          inputId="platformZendeskUserAuthJwtKey"
          className="w-full"
          inputClassName="w-full"
          value={zendeskUserAuthJwtKey || ''}
          onChange={(e) => {
            setZendeskUserAuthJwtKey(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformZendeskUserAuthJwtKeyId">Zendesk user auth JWT key ID</label>
        <InputText
          id="platformZendeskUserAuthJwtKeyId"
          className="w-full"
          value={zendeskUserAuthJwtKeyId || ''}
          onChange={(e) => {
            setZendeskUserAuthJwtKeyId(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformZendeskUsername">Zendesk username</label>
        <InputText
          id="platformZendeskUsername"
          className="w-full"
          value={zendeskUsername || ''}
          onChange={(e) => {
            setZendeskUsername(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformZendeskWebhookSigningSecret">Zendesk webhook signing secret</label>
        <Password
          inputId="platformZendeskWebhookSigningSecret"
          className="w-full"
          inputClassName="w-full"
          value={zendeskWebhookSigningSecret || ''}
          onChange={(e) => {
            setZendeskWebhookSigningSecret(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="platformZendeskHelpTicketTags">Zendesk help ticket tags</label>
        <InputText
          id="platformZendeskHelpTicketTags"
          className="w-full"
          value={zendeskHelpTicketTags}
          onChange={(e) => {
            setZendeskHelpTicketTags(e.target.value);
          }}
        />
        <InputNotice>An optional, comma-separated list of Zendesk ticket tags.</InputNotice>
      </div>
    </OptionsSection>
  );
};
