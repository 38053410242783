import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { ResetPasswordComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { Logo } from '../../Logo.js';
import { ResetPasswordDocument } from './mutations.generated.js';
import { PasswordResetInfoDocument } from './queries.generated.js';

export const ResetPassword: FunctionComponent<ResetPasswordComponent> = (props) => {
  const { name: siteName } = useSiteContext();
  const [params] = useSearchParams();
  const key = params.get('key');

  const mode = props.mode || 'reset';

  const navigate = useNavigate();
  const apiClient = useApolloClient();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data: resetInfoData, loading } = useQuery(PasswordResetInfoDocument, {
    onError,
    variables: { key: key || '' },
  });
  const [resetPassword, { loading: isResetting }] = useMutation(ResetPasswordDocument, { onError });
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const onSubmit = () => {
    clearMessages();
    if (password !== password2) {
      onError('The passwords you entered do not match.');
      return;
    }
    if (key) {
      void resetPassword({
        variables: { key, password },
        onCompleted: () => {
          navigate('/');
          // Make sure we've moved off to a different page before we reset the Apollo store.
          setTimeout(() => {
            void apiClient.resetStore();
          }, 1);
        },
      });
    }
  };

  return (
    <div className="h-screen flex justify-content-center align-items-center surface-ground">
      <div className="w-22rem max-body-width-contained bg-white p-4 border-1 border-round mb-2">
        <div className="flex align-items-center justify-content-center gap-2 mb-4">
          <Logo />
        </div>
        <ErrorMessage />
        {isResetting || loading ? (
          <div className="text-center">
            {isResetting && (
              <div className="font-medium mb-3">Setting your password now&hellip;</div>
            )}
            <i className="pi pi-spinner pi-spin text-xl text-color-primary" />
          </div>
        ) : resetInfoData ? (
          <Fragment>
            <p>
              <span className="font-medium">{resetInfoData.passwordResetInfo.name}</span>, you can{' '}
              {mode === 'create' ? 'create' : 'change'} your password now&hellip;
            </p>
            <div className="input-field">
              <label htmlFor="newPassword1" className="text-left">
                Your new password
              </label>
              <InputText
                id="newPassword1"
                className="w-full"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="input-field">
              <label htmlFor="newPassword2" className="text-left">
                Confirm your new password
              </label>
              <InputText
                id="newPassword2"
                className="w-full"
                type="password"
                value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </div>
            <Button
              className="mt-2 w-full"
              label={mode === 'create' ? 'Set password' : 'Reset password'}
              disabled={!password || isResetting}
              onClick={onSubmit}
            />
          </Fragment>
        ) : (
          <div className="text-center">
            <div className="flex flex-column gap-3 text-center mt-3">
              <Button
                label="Reset password"
                onClick={() => {
                  navigate('/reset-password/request');
                }}
              />
              <Button
                label={`Go to ${siteName}`}
                onClick={() => {
                  navigate('/');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
