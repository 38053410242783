import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { CourseSubSectionComponent } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { RenderComponent } from '../../RenderComponent.js';

export const CourseSubSection: FunctionComponent<CourseSubSectionComponent> = (props) => (
  <div className={componentClassName(props)}>
    {props.children?.map((child) => <RenderComponent key={child.id} {...child} />)}
  </div>
);
