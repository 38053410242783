import { isStringArray } from '@wirechunk/lib/arrays.js';
import { returnTrue } from '@wirechunk/lib/const-fns.js';
import { OrgTeamComponent } from '@wirechunk/lib/mixer/types/components.js';
import { MultiSelect } from 'primereact/multiselect';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../contexts/admin/platform-context/platform-context.js';
import { usePlanSelectorOptions } from '../../../hooks/use-plan-selector-options/use-plan-selector-options.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label.js';
import { Spinner } from '../../Spinner.js';
import { EditComponentContentProps } from './shared/types.js';

export const EditOrgTeam: FunctionComponent<EditComponentContentProps<OrgTeamComponent>> = ({
  component,
  setComponent,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { options: contentPlanOptions, loading: contentPlanOptionsLoading } =
    usePlanSelectorOptions(platformId, returnTrue, onError);

  return (
    <Fragment>
      <ErrorMessage />
      <CheckboxWithLabel
        id="editOrgTeamShowUserTrainingProgress"
        label="Show user training progress"
        checked={component.showUserTrainingProgress ?? false}
        onChange={({ checked }) => {
          setComponent((c) => ({ ...c, showUserTrainingProgress: checked }));
        }}
      />
      {component.showUserTrainingProgress &&
        (contentPlanOptionsLoading ? (
          <Spinner />
        ) : (
          <div className="input-field">
            <label htmlFor="editOrgTeamUserTrainingProgressContentPlanIds">
              Courses and sequences to show
            </label>
            <MultiSelect
              inputId="editOrgTeamUserTrainingProgressContentPlanIds"
              className="w-full"
              value={component.userTrainingProgressContentPlanIds ?? []}
              options={contentPlanOptions}
              onChange={({ value }) => {
                if (isStringArray(value)) {
                  setComponent((c) => ({ ...c, userTrainingProgressContentPlanIds: value }));
                }
              }}
            />
          </div>
        ))}
    </Fragment>
  );
};
