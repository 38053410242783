import { useMutation } from '@apollo/client';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent } from 'react';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import {
  type SiteContext,
  SiteContextProvider,
} from '../../../contexts/SiteContext/SiteContext.js';
import { ErrorHandler } from '../../../hooks/useErrorHandler.js';
import { useParsedMixerComponents } from '../../../hooks/useParsedMixerComponents.js';
import { previewPageTemplateBasePath } from '../../../util/page-templates.js';
import { sitePageUrl } from '../../../util/site-page-url.js';
import { EditProps } from '../../edit-props.js';
import { defaultLandingPageImage } from './defaultLandingPageImage.js';
import { CreatePageFromTemplateDocument } from './mutations.generated.js';
import { PageTemplate } from './usePageTemplatesData.js';

type TemplateRowProps = {
  pageTemplate: PageTemplate;
  site: SiteContext;
  onError: ErrorHandler['onError'];
  onActivated: () => void;
};

export const TemplateRow: FunctionComponent<TemplateRowProps> = ({
  pageTemplate,
  site,
  onError,
  onActivated,
}) => {
  const dialog = useDialog();
  const { user } = useCurrentUser();
  const [createPageFromTemplate, { loading: createPageLoading }] = useMutation(
    CreatePageFromTemplateDocument,
    {
      onError,
      onCompleted: () => {
        onActivated();
      },
    },
  );
  const propsSetupComponents = useParsedMixerComponents(pageTemplate.propsSetupComponents);

  const activatedPages = pageTemplate.activatedAsPages.length;

  return (
    <div className="flex flex-column md:flex-row align-items-center md:align-items-start gap-4">
      <img
        className="w-12rem block shadow-2 border-round"
        src={pageTemplate.previewImageUrl || defaultLandingPageImage}
        alt="Page Preview"
      />
      <div className="flex flex-column md:flex-row justify-content-between align-items-start">
        <div className="flex-column">
          <div className="flex w-full justify-content-between font-medium mb-1">
            {pageTemplate.title}
          </div>
          {!!activatedPages && (
            <p className="font-medium mb-1 text-color-muted text-sm">
              Used by {activatedPages} {pluralize(activatedPages, 'page')}
            </p>
          )}
          {pageTemplate.description && <p>{pageTemplate.description}</p>}
          <div className="flex gap-3">
            <Fragment>
              <a
                className="p-button p-button-secondary p-button-sm align-items-center gap-2"
                href={sitePageUrl(site.domain, `${previewPageTemplateBasePath}/${pageTemplate.id}`)}
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-color-on-secondary">Preview</span>
                <i className={`${PrimeIcons.EXTERNAL_LINK} text-color-on-secondary`} />
              </a>
              <Button
                label="Activate"
                className="p-button-success p-button-sm"
                disabled={createPageLoading}
                loading={createPageLoading}
                onClick={() => {
                  if (propsSetupComponents.length) {
                    dialog({
                      content: (
                        <SiteContextProvider value={site}>
                          <CurrentUserProvider user={user} loadingUser={false}>
                            <EditProps
                              setupComponents={propsSetupComponents}
                              submitButtonLabel="Activate"
                              onSubmit={(props) => {
                                void createPageFromTemplate({
                                  variables: {
                                    siteId: site.id,
                                    templateId: pageTemplate.id,
                                    props: JSON.stringify(props),
                                  },
                                });
                              }}
                            />
                          </CurrentUserProvider>
                        </SiteContextProvider>
                      ),
                      props: {
                        header: 'Page customizations',
                        className: 'dialog-width-lg',
                      },
                    });
                    return;
                  }
                  void createPageFromTemplate({
                    variables: {
                      siteId: site.id,
                      templateId: pageTemplate.id,
                    },
                  });
                }}
              />
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};
