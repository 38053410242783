import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { DataSource, ImageComponent } from '@wirechunk/lib/mixer/types/components.js';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.js';
import type { FunctionComponent } from 'react';
import { usePropsContext } from '../../contexts/props-context.js';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const Image: FunctionComponent<ImageComponent> = (props) => {
  const { logoUrl, agentPhotoUrl } = useSiteContext();
  const propsContext = usePropsContext();

  let src: string | null | undefined;
  switch (props.src?.type) {
    case DataSource.Direct:
    case 'URL':
      src = props.src.url;
      break;
    case DataSource.Prop:
      if (props.src.name) {
        src = stringOrDefaultNull(propsContext[props.src.name]);
      }
      break;
    case 'OrganizationLogoUrl':
      src = logoUrl;
      break;
    case 'OrganizationAgentPhotoUrl':
      src = agentPhotoUrl;
      break;
    default:
  }

  if (!src) {
    return null;
  }

  return <img src={src} alt={props.alt || ''} className={componentClassName(props)} />;
};
