import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FunctionComponent, useEffect, useState } from 'react';
import { ErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useStageBlueprintOptions } from '../../../../../hooks/useStageBlueprintOptions/useStageBlueprintOptions.js';
import { Spinner } from '../../../../Spinner.js';
import { EditUserPlanDocument } from './mutations.generated.js';
import { UserDetailsQuery } from './queries.generated.js';

type SetPlanStageBlueprintProps = {
  userPlan: UserDetailsQuery['user']['plans'][number];
  onDone: () => void;
  onError: ErrorHandler['onError'];
};

export const SetPlanStageBlueprint: FunctionComponent<SetPlanStageBlueprintProps> = ({
  userPlan,
  onDone,
  onError,
}) => {
  const [editUserPlan, { loading: isSavingPlanStageBlueprintEdits }] = useMutation(
    EditUserPlanDocument,
    { onError },
  );

  const { options, loading } = useStageBlueprintOptions(userPlan.plan.id, onError);

  const [newStageBlueprintId, setNewStageBlueprintId] = useState<string | null>(null);

  useEffect(() => {
    setNewStageBlueprintId(userPlan.stageBlueprint.id);
  }, [userPlan.stageBlueprint.id]);

  return (
    <div className="w-23rem max-w-full">
      <div className="input-field">
        <label htmlFor={`userPlan-${userPlan.id}`}>New stage</label>
        {loading ? (
          <Spinner />
        ) : (
          <Dropdown
            inputId={`userPlan-${userPlan.id}`}
            className="w-full"
            options={options}
            value={newStageBlueprintId}
            onChange={(e) => {
              setNewStageBlueprintId(e.value as string);
            }}
            filter
            optionLabel="label"
          />
        )}
      </div>
      <div className="flex gap-3 justify-content-end">
        <Button label="Cancel" className="p-button-secondary" onClick={onDone} />
        <Button
          label="Save"
          disabled={isSavingPlanStageBlueprintEdits || !newStageBlueprintId}
          onClick={() => {
            if (newStageBlueprintId) {
              void editUserPlan({
                variables: {
                  userId: userPlan.userId,
                  planId: userPlan.plan.id,
                  stageBlueprintId: newStageBlueprintId,
                },
                onCompleted: onDone,
              });
            }
          }}
        />
      </div>
    </div>
  );
};
