import { useQuery } from '@apollo/client';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
  EditSiteContext,
  EditSiteContextProvider,
} from '../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { SiteDocument } from '../../../../../hooks/useSiteContextSelector/queries.generated.js';
import { Spinner } from '../../../../Spinner.js';

type GuardedSiteProps = {
  siteId: string;
};

const GuardedSite: FunctionComponent<GuardedSiteProps> = ({ siteId }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, refetch, loading } = useQuery(SiteDocument, {
    onError,
    variables: {
      id: siteId,
    },
  });

  const editSiteContext = useMemo<EditSiteContext | null>(
    () =>
      data
        ? {
            site: data.site,
            refetchSite: () => void refetch(),
          }
        : null,
    [data, refetch],
  );

  return (
    <Fragment>
      <ErrorMessage />
      {editSiteContext ? (
        <EditSiteContextProvider value={editSiteContext}>
          <Outlet />
        </EditSiteContextProvider>
      ) : loading ? (
        <Spinner />
      ) : null}
    </Fragment>
  );
};

export const Site: FunctionComponent = () => {
  const { siteId } = useParams<{ siteId: string }>();

  if (siteId) {
    return <GuardedSite siteId={siteId} />;
  }

  return null;
};
