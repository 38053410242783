import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import type { ErrorHandler } from '../useErrorHandler.js';
import { FileGetUrlDocument } from './queries.generated.js';

type FileGetUrl = {
  getUrl: (id: string, download: boolean) => Promise<string>;
  isLoading: boolean;
};

// useFileGetUrl returns a function that can fetch the URL to GET a file.
export const useFileGetUrl = (onError: ErrorHandler['onError']): FileGetUrl => {
  const [get, { loading: isLoading }] = useLazyQuery(FileGetUrlDocument, {
    onError,
    // We must not cache the URL because it is a signed URL that expires after a short time.
    fetchPolicy: 'no-cache',
  });

  const getUrl = useCallback<FileGetUrl['getUrl']>(
    async (id, download) => {
      const { data } = await get({
        variables: {
          id,
          download,
        },
      });
      if (!data) {
        throw new Error('No data returned from query');
      }
      return data.file.getUrl;
    },
    [get],
  );

  return { getUrl, isLoading };
};
