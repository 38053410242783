import { ApolloQueryResult, useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { ProductNamesDocument, ProductNamesQuery } from './queries.generated.js';

export type SubscriptionPlanNames = {
  subscriptionPlans: ProductNamesQuery['products'] | null;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<ProductNamesQuery>>;
};

export const useSubscriptionPlanNames = (
  platformId: string,
  onError: ErrorHandler['onError'],
): SubscriptionPlanNames => {
  const { data, refetch, loading } = useQuery(ProductNamesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId },
  });

  return {
    subscriptionPlans: data?.products || null,
    loading,
    refetch,
  };
};
