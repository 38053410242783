import { useQuery } from '@apollo/client';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import type { Entry } from '../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.js';
import { RenderQuestionExplanation } from '../mixer/ScoreMyCall/Questions.js';
import { ScoreMyCallEntryFileUrlDocument } from './queries.generated.js';

type ScoreMyCallResultsDialogProps = {
  entry: Entry;
};

export const ScoreMyCallResultsDialog: FunctionComponent<ScoreMyCallResultsDialogProps> = ({
  entry,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();

  const [isGettingFileUrl, setIsGettingFileUrl] = useState<boolean>(false);
  const {
    data,
    loading: isLoading,
    refetch: refetchEntryFileUrl,
  } = useQuery(ScoreMyCallEntryFileUrlDocument, {
    fetchPolicy: 'no-cache',
    onError,
    variables: {
      entryId: entry.id,
      download: false,
    },
  });

  return (
    <div className="flex flex-column gap-3">
      <ErrorMessage />
      <div className="flex">
        {isLoading ? (
          <div className="py-3 text-center flex-grow-1">
            <i className="pi pi-spinner pi-spin text-lg" />
          </div>
        ) : (
          data && (
            <audio
              controls
              controlsList="nodownload noplaybackrate"
              src={data.scoreMyCallEntryFileUrl}
              className="flex-grow-1"
            />
          )
        )}
        <Button
          className="flex ml-2 p-button-rounded align-self-center"
          icon={PrimeIcons.DOWNLOAD}
          disabled={isGettingFileUrl}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => {
            setIsGettingFileUrl(true);
            try {
              const { data } = await refetchEntryFileUrl({ entryId: entry.id, download: true });
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO: See if data is actually always defined, even in the presence of errors.
              if (data) {
                window.open(data.scoreMyCallEntryFileUrl, '_blank');
              }
            } finally {
              setIsGettingFileUrl(false);
            }
          }}
        />
      </div>
      <div className="font-bold">
        Score: {entry.questionsPassed}/{entry.questionsTotal}
      </div>
      {entry.parsedFormData.questions.map((question) =>
        'name' in question ? (
          <div key={question.name}>
            <div className="font-medium mb-1">{question.label}</div>
            {question.explanation && (
              <div className="mt-2 mb-0">
                <RenderQuestionExplanation explanation={question.explanation} />
              </div>
            )}
            {entry.parsedFormData.values[question.name] ? 'Yes' : 'No'}
          </div>
        ) : (
          <h2 key={question.heading} className="text-2xl font-medium mt-2 mb-1">
            {question.heading}
          </h2>
        ),
      )}
    </div>
  );
};
