import { ApolloError } from '@apollo/client';
import { ApiErrorCode } from '@wirechunk/lib/api-error-code.js';
import { genericErrorMessage, parseErrorMessage } from '@wirechunk/lib/errors.js';
import type { GraphQLFormattedError } from 'graphql';
import {
  SessionStatus,
  SessionStatusContext,
} from '../contexts/SessionStatusContext/SessionStatusContext.js';

// apolloErrorsMessage handles an array of GraphQL errors, not network errors.
const apolloErrorsMessage = (errors: readonly GraphQLFormattedError[]): string =>
  errors.map((err) => err.message).join('\n');

// Parses an error message, including checking if it's an ApolloError, and returns a string.
export const parseWebErrorMessage = (err: unknown): string => {
  const message = parseErrorMessage(err);
  if (message === genericErrorMessage) {
    if (err instanceof ApolloError) {
      if (err.graphQLErrors.length) {
        const message = apolloErrorsMessage(err.graphQLErrors);
        if (message) {
          return message;
        }
      }
      if (err.networkError?.message) {
        return err.networkError.message;
      }
      if (err.message) {
        return err.message;
      }
    }
  }

  return message;
};

// isOfflineError returns true if and only if the error we got signals that we are offline. Regardless of
// the error, this function will return true only if the user is currently offline.
const isOfflineError = (error: ApolloError | Error | string): boolean => {
  if (import.meta.env.SSR || window.navigator.onLine) {
    return false;
  }
  if (error instanceof ApolloError && error.networkError) {
    return true;
  }
  return error instanceof Error && error.message === 'Failed to fetch';
};

const isUnauthenticatedError = (error: ApolloError | Error | string): boolean => {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.some((e) => e.extensions?.code === ApiErrorCode.Unauthenticated);
  }
  return false;
};

// Split offlineErrorHandler into a handler for network errors and a handler for unauthenticated errors.
export const offlineErrorHandler = (
  error: ApolloError | Error | string,
  showErrorToast: (message: string, heading?: string) => void,
): ApolloError | Error | string | null => {
  if (!error) {
    return genericErrorMessage;
  }
  if (isOfflineError(error)) {
    showErrorToast('It appears that you are offline.', 'Network error');
    return null;
  }
  return error;
};

export const unauthenticatedErrorHandler = (
  error: ApolloError | Error | string,
  sessionContext: SessionStatusContext,
): ApolloError | Error | string | null => {
  if (!error) {
    return genericErrorMessage;
  }
  if (isUnauthenticatedError(error)) {
    if (sessionContext.status === SessionStatus.Active) {
      sessionContext.setStatus(SessionStatus.Expired);
      sessionContext.setErrorMessage('Your session has expired. Please sign in again.');
    } else {
      let message: string = '';
      // Try to parse out the precise error message returned by the server.
      if (error instanceof ApolloError) {
        if (error.graphQLErrors.length) {
          message = apolloErrorsMessage(error.graphQLErrors);
        } else {
          message = error.message;
        }
      }
      message ||= 'You need to sign in to continue.';
      sessionContext.setStatus(SessionStatus.SignedOut);
      sessionContext.setErrorMessage(message);
    }
    return null;
  }
  return error;
};
