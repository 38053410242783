import { breakpoints } from '@wirechunk/lib/mixer/breakpoints.js';
import { WidthContainerComponent, Width } from '@wirechunk/lib/mixer/types/components.js';
import { isWidth } from '@wirechunk/lib/mixer/utils.js';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import type { SelectItem } from '../../../../types.js';
import { EditComponentContentProps } from '../shared/types.js';

const bodyContainerWidthOptions: Array<SelectItem<Width>> = [
  { label: `Medium (${breakpoints.md - 1}px)`, value: Width.Medium },
  { label: `Large (${breakpoints.lg - 1}px)`, value: Width.Large },
  { label: `Extra-large (${breakpoints.xl - 1}px)`, value: Width.XLarge },
  { label: `Extra-extra-large (${breakpoints.xxl - 1}px)`, value: Width.XXLarge },
];

export const EditWidthContainer: FunctionComponent<
  EditComponentContentProps<WidthContainerComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editWidth">Width</label>
      <Dropdown
        inputId="editWidth"
        className="w-full"
        options={bodyContainerWidthOptions}
        value={component.width || null}
        onChange={({ value }) => {
          if (isWidth(value)) {
            setComponent((c) => ({
              ...c,
              width: value,
            }));
          }
        }}
      />
    </div>
  </Fragment>
);
