import type { InputComponent } from '@wirechunk/lib/mixer/types/categories.js';
import { isValidInputComponent, ValidInputComponent } from '@wirechunk/lib/mixer/utils.js';
import type { ComponentType, FunctionComponent } from 'react';

export const withValidInputComponent =
  <IC extends InputComponent>(
    Component: ComponentType<ValidInputComponent<IC>>,
  ): FunctionComponent<IC> =>
  (componentProps) =>
    isValidInputComponent(componentProps) ? (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component {...componentProps} />
    ) : null;
