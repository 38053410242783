import { VideoComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

export const EditVideo: FunctionComponent<EditComponentContentProps<VideoComponent>> = ({
  component,
  setComponent,
}) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editVideoSrc">Source URL</label>
        <InputText
          id="editVideoSrc"
          className="w-full"
          value={component.src || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              src: e.target.value,
            }));
          }}
        />
      </div>
    </Fragment>
  );
};
