import { useQuery } from '@apollo/client';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { previewPageTemplateBasePath } from '../../util/page-templates.js';
import { Page } from '../Page/Page.js';
import { PreviewPageTemplate } from '../PreviewPageTemplate/preview-page-template.js';
import { Spinner } from '../Spinner.js';
import { CurrentSitePublicPublishedPagesDocument } from './queries.generated.js';

export const PublicRoutesProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onErrorToast } = useErrorHandler();
  const { data, loading } = useQuery(CurrentSitePublicPublishedPagesDocument, {
    onError: onErrorToast,
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <Routes>
      {data?.currentSite.pages.map(({ id, path }) => (
        <Route key={id} path={path} element={<Page />} />
      ))}
      <Route
        // Page template previews are unauthenticated because users are not authenticated under site domains.
        // TODO: Use a temporary auth token in the URL.
        path={`${previewPageTemplateBasePath}/:templateId`}
        element={<PreviewPageTemplate />}
      />
      <Route path="*" element={children} />
    </Routes>
  );
};
