import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { useSiteContext } from '../../../../../contexts/SiteContext/SiteContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { CreateAdminUserDocument } from './mutations.generated.js';

type AddAdminUserProps = {
  onAdded: () => void;
  onCancel: () => void;
};

export const AddAdminUser: FunctionComponent<AddAdminUserProps> = ({ onAdded, onCancel }) => {
  // We use the platformId from SiteContext because we are adding a user to the host platform.
  const { platformId } = useSiteContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [createAdminUser, { loading: creatingUser }] = useMutation(CreateAdminUserDocument, {
    onError,
  });

  return (
    <div className="w-25rem max-w-full p-3 border-1 border-round">
      <h3 className="mt-1">Add an Admin User</h3>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="adminUserFirstName">First name</label>
        <InputText
          id="adminUserFirstName"
          className="w-full"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="adminUserLastName">Last name</label>
        <InputText
          id="adminUserLastName"
          className="w-full"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="adminUserEmail">Email</label>
        <InputText
          id="adminUserEmail"
          className="w-full"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-3 justify-content-end">
        <Button
          label="Cancel"
          className="p-button-secondary"
          disabled={creatingUser}
          onClick={onCancel}
        />
        <Button
          label="Add"
          disabled={creatingUser}
          onClick={() => {
            clearMessages();
            void createAdminUser({
              variables: {
                platformId,
                email,
                firstName,
                lastName,
              },
              onCompleted: onAdded,
            });
          }}
        />
      </div>
    </div>
  );
};
