import { DataSource, StringValueSource } from '@wirechunk/lib/mixer/types/components.js';
import { stringOrDefaultEmpty } from '@wirechunk/lib/strings.js';
import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import { DataSourceDropdown } from '../../components/data-source-dropdown.js';
import type { EditComponent } from '../../editComponentByType.js';

export const StringValueTab: EditComponent = ({ component: { value }, setComponent }) => {
  return (
    <Fragment>
      <DataSourceDropdown
        parentId="stringValueTab"
        label="Value source"
        type={value?.type || DataSource.Direct}
        onChange={(type) => {
          setComponent((c) => ({
            ...c,
            value:
              type === DataSource.Direct
                ? ({ type, value: '' } satisfies StringValueSource)
                : ({ type, name: '' } satisfies StringValueSource),
          }));
        }}
      />
      {!value || value.type === DataSource.Direct ? (
        <div className="input-field">
          <label htmlFor="stringValueTabValue">Value</label>
          <InputText
            id="stringValueTabValue"
            className="w-full"
            value={stringOrDefaultEmpty(value?.value)}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                value: {
                  ...c.value,
                  type: DataSource.Direct,
                  value: e.target.value,
                } satisfies StringValueSource,
              }));
            }}
          />
        </div>
      ) : (
        <div className="input-field">
          <label htmlFor="stringValueTabPropName">Prop name</label>
          <InputText
            id="stringValueTabPropName"
            className="w-full"
            value={value.name || ''}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                value: {
                  ...c.value,
                  type: DataSource.Prop,
                  name: e.target.value,
                } satisfies StringValueSource,
              }));
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
