import { ApolloQueryResult, useQuery } from '@apollo/client';
import type { SelectItem } from '../../types.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import { PlatformAdminsDocument, PlatformAdminsQuery } from './queries.generated.js';

export type AdminUsersList = PlatformAdminsQuery['organization']['members'];

export type AdminUser = AdminUsersList[number];

type AdminUsers = {
  adminUsers: AdminUsersList;
  selectItems: Array<SelectItem<string>>;
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<PlatformAdminsQuery>>;
};

export const useAdminUsers = (
  organizationId: string,
  onError: ErrorHandler['onError'],
): AdminUsers => {
  const { data, loading, refetch } = useQuery(PlatformAdminsDocument, {
    onError,
    variables: { organizationId },
  });

  return {
    adminUsers: data?.organization.members ?? [],
    selectItems:
      data?.organization.members
        .map((adminUser) => ({
          label: `${adminUser.displayName} (${adminUser.email})`,
          value: adminUser.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    isLoading: loading,
    refetch,
  };
};
