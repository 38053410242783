import { InvoiceFrequency, MutationCreateProductArgs, PromoCodeInput } from '@wirechunk/lib/api.js';
import { roundToDayEnd } from '@wirechunk/lib/dates.js';
import { normalizePromoCode } from '@wirechunk/lib/promo-codes.js';
import { difference, isDate, union } from 'lodash-es';
import { nanoid } from 'nanoid';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Fragment, FunctionComponent, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useProductItemPicklist } from '../../../../hooks/use-product-item-picklist/use-product-item-picklist.js';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { DeltaData, tryParseDelta } from '../../../../util/delta.js';
import { invoiceFrequencyOptions } from '../../../../util/subscriptions.js';
import { BasicIconButton } from '../../../BasicIconButton/BasicIconButton.js';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { QuillEditor } from '../../../quill-editor.js';
import type { SubscriptionPlanQuery } from './product-details/queries.generated.js';

enum EndType {
  Never,
  Date,
  DurationDays,
}

type WorkingPromoCodeInput = PromoCodeInput & {
  workingSuccessMessage: {
    delta: DeltaData;
  } | null;
};

type EditSubscriptionPlanProps = {
  initialProduct?: SubscriptionPlanQuery['subscriptionPlan'];
  saveButtonLabel: string;
  isPending: boolean;
  onError: ErrorHandler['onError'];
  // If onCancel is not defined, the "Cancel" button will not be shown.
  onCancel?: () => void;
  onSave: (subscriptionPlan: Omit<MutationCreateProductArgs, 'platformId'>) => void;
};

export const EditProduct: FunctionComponent<EditSubscriptionPlanProps> = ({
  initialProduct,
  saveButtonLabel,
  isPending,
  onError,
  onCancel,
  onSave,
}) => {
  const { id: platformId } = usePlatformContext();
  const { productItems, loading: loadingProductItems } = useProductItemPicklist(
    platformId,
    onError,
  );

  const addItemPopover = useRef<HTMLDivElement>(null);
  const [addingItemIdentifier, setAddingItemIdentifier] = useState<string>('');

  const [name, setName] = useState(initialProduct?.name || '');
  const [displayName, setDisplayName] = useState(initialProduct?.displayName || '');
  const [description, setDescription] = useState(initialProduct?.description || '');
  const [items, setItems] = useState<string[]>(initialProduct?.items || []);
  const [endAt, setEndAt] = useState<Date | null>(
    initialProduct?.endAt ? new Date(initialProduct.endAt) : null,
  );
  const [durationDays, setDurationDays] = useState<number | null>(
    initialProduct?.durationDays || null,
  );
  const [price, setPrice] = useState<string>(initialProduct?.price || '');
  const [invoiceFrequency, setInvoiceFrequency] = useState<InvoiceFrequency | null>(
    initialProduct?.invoiceFrequency || null,
  );
  const [promoCodes, setPromoCodes] = useState<WorkingPromoCodeInput[]>(
    initialProduct?.promoCodes
      // Exclude __typename.
      .map(({ id, code, successMessage }) => ({
        id,
        code,
        workingSuccessMessage: successMessage?.delta
          ? {
              delta: {
                ops: tryParseDelta(successMessage.delta),
              },
            }
          : null,
      })) || [],
  );
  const [stripePriceId, setStripePriceId] = useState<string | null>(
    initialProduct?.stripePriceId || null,
  );
  // Hold an array of objects where workingId is used just for form management and value holds a Stripe Price ID.
  const [alternativeStripePriceIds, setAlternativeStripePriceIds] = useState<
    Array<{ workingId: string; value: string }>
  >(
    initialProduct?.alternativeStripePriceIds.map((value) => ({
      workingId: nanoid(),
      value,
    })) || [],
  );

  const [endType, setEndType] = useState<EndType>(
    initialProduct?.endAt ? EndType.Date : EndType.Never,
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const filteredProductItemOptions = difference(productItems, items);

  const productId = initialProduct?.id || 'new';

  return (
    <Fragment>
      <Helmet>
        <style>{`
          #addItemButton${productId} { anchor-name: --add-item-button-${productId} }
          #addItemPopover${productId} { position-anchor: --add-item-button-${productId} }
        `}</style>
      </Helmet>
      <div className="input-field">
        <label htmlFor={`planName-${productId}`}>Name</label>
        <InputText
          id={`planName-${productId}`}
          className="w-full"
          value={name}
          onChange={(e) => {
            if (!isPending) {
              setName(e.target.value);
              setHasUnsavedChanges(true);
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`planDisplayName-${productId}`}>Display name</label>
        <InputText
          id={`planDisplayName-${productId}`}
          className="w-full"
          value={displayName}
          onChange={(e) => {
            if (!isPending) {
              setDisplayName(e.target.value);
              setHasUnsavedChanges(true);
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`planDescription-${productId}`}>Description</label>
        <InputText
          id={`planDescription-${productId}`}
          className="w-full"
          value={description}
          onChange={(e) => {
            if (!isPending) {
              setDescription(e.target.value);
              setHasUnsavedChanges(true);
            }
          }}
        />
      </div>
      <div className="input-field">
        <div className="mb-1 font-medium">Items</div>
        <InputNotice>
          You can list specific items (i.e., item identifiers) that this product includes to
          reference when configuring access across pages and other places.
        </InputNotice>
        {items.length > 0 && (
          <div className="flex gap-1 flex-wrap mt-1">
            {items.map((item) => (
              <Chip
                key={item}
                label={item}
                onRemove={() => {
                  setItems((prevItems) => prevItems.filter((it) => it !== item));
                  setHasUnsavedChanges(true);
                }}
                removable
              />
            ))}
          </div>
        )}
        <Button
          id={`addItemButton${productId}`}
          className="mt-2"
          size="small"
          label="Add item"
          disabled={loadingProductItems}
          // @ts-expect-error -- popovertarget
          popovertarget={`addItemPopover${productId}`}
          popovertargetaction="show"
          pt={{
            root: {
              // This doesn't work yet.
              // style: {
              //   anchorName: `addItemButton${productId}`,
              // },
            },
          }}
        />
      </div>
      <div className="input-field">
        <label>Subscription end</label>
        <div className="flex flex-column gap-2">
          <div className="flex gap-2">
            <RadioButton
              inputId={`planEndTypeNever-${productId}`}
              name={`planEndType-${productId}`}
              checked={endType === EndType.Never}
              onChange={() => {
                setEndType(EndType.Never);
                setHasUnsavedChanges(true);
              }}
            />
            <label htmlFor={`planEndTypeNever-${productId}`} className="font-medium">
              Never
            </label>
          </div>
          <div className="flex gap-2">
            <RadioButton
              inputId={`planEndTypeDate-${productId}`}
              name={`planEndType-${productId}`}
              checked={endType === EndType.Date}
              onChange={() => {
                setEndType(EndType.Date);
                setHasUnsavedChanges(true);
              }}
            />
            <div className="flex flex-column gap-1">
              <label htmlFor={`planEndTypeDate-${productId}`} className="font-medium">
                On date
              </label>
              {endType === EndType.Date && (
                <Calendar
                  className="w-9rem"
                  value={endAt || undefined}
                  onChange={({ value }) => {
                    if (isDate(value)) {
                      setEndAt(roundToDayEnd(value));
                      setHasUnsavedChanges(true);
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <RadioButton
              inputId={`planEndTypeDurationDays-${productId}`}
              name={`planEndType-${productId}`}
              checked={endType === EndType.DurationDays}
              onChange={() => {
                setEndType(EndType.DurationDays);
                setHasUnsavedChanges(true);
              }}
            />
            <div className="flex flex-column gap-1">
              <label htmlFor={`planEndTypeDurationDays-${productId}`} className="font-medium">
                After a duration (days)
              </label>
              {endType === EndType.DurationDays && (
                <InputNumber
                  inputId={`planDurationDays-${productId}`}
                  className="w-6rem"
                  inputClassName="w-6rem"
                  value={durationDays}
                  maxFractionDigits={0}
                  onChange={(e) => {
                    setDurationDays(e.value);
                    setHasUnsavedChanges(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="input-field">
        <label htmlFor={`planPrice-${productId}`}>Price</label>
        <InputText
          id={`planPrice-${productId}`}
          className="w-8rem"
          value={price}
          placeholder="0"
          keyfilter="pnum"
          onChange={(e) => {
            setPrice(e.target.value);
            setHasUnsavedChanges(true);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`planInvoiceFrequency-${productId}`}>Invoice frequency</label>
        <Dropdown
          inputId={`planInvoiceFrequency-${productId}`}
          className="w-full"
          disabled={!price || !Number(price)}
          value={price && Number(price) > 0 ? invoiceFrequency : null}
          options={invoiceFrequencyOptions}
          onChange={(e) => {
            setInvoiceFrequency(e.value as InvoiceFrequency);
            setHasUnsavedChanges(true);
          }}
        />
      </div>
      <div className="input-field">
        <label>Promo codes</label>
        <InputNotice className="mb-1">
          You can add custom promo codes that are not connected to Stripe in any way.
        </InputNotice>
        <div className="flex flex-column gap-2 w-full">
          {promoCodes.map(({ id, code, workingSuccessMessage }) => (
            <div key={id} className="flex flex-column gap-2 p-2 border-1 border-round">
              <div className="flex gap-2">
                <InputText
                  className="flex-grow-1"
                  placeholder="Promo code"
                  value={code}
                  onChange={(e) => {
                    const newCode = normalizePromoCode(e.target.value);
                    setPromoCodes((prev) =>
                      prev.map((item) => (item.id === id ? { ...item, code: newCode } : item)),
                    );
                    setHasUnsavedChanges(true);
                  }}
                />
                <BasicIconButton
                  onClick={() => {
                    setPromoCodes((prev) => prev.filter((item) => item.id !== id));
                    setHasUnsavedChanges(true);
                  }}
                  icon={PrimeIcons.TRASH}
                />
              </div>
              <label>Success message</label>
              <QuillEditor
                initialValue={workingSuccessMessage?.delta}
                onChange={(delta) => {
                  setPromoCodes((prev) =>
                    prev.map((item) =>
                      item.id === id ? { id, code, workingSuccessMessage: { delta } } : item,
                    ),
                  );
                  setHasUnsavedChanges(true);
                }}
              />
              <InputNotice>
                The message to display next to the promo code input field when this code is applied.
              </InputNotice>
            </div>
          ))}
          <Button
            label="Add Promo Code"
            className="p-button-secondary p-button-sm w-max mt-1"
            onClick={() => {
              setPromoCodes((prev) => [
                ...prev,
                { id: window.crypto.randomUUID(), code: '', workingSuccessMessage: null },
              ]);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
      </div>
      <div className="input-field">
        <label htmlFor={`planInvoiceFrequency-${productId}`}>Stripe Price ID</label>
        <InputText
          id={`planStripePriceId-${productId}`}
          className="w-full"
          value={stripePriceId || ''}
          onChange={(e) => {
            setStripePriceId(e.target.value.trim() || null);
            setHasUnsavedChanges(true);
          }}
        />
      </div>
      <div className="input-field">
        <label>Alternative Stripe Price IDs</label>
        <InputNotice>
          You can list additional Prices for Stripe subscriptions that will be synced with
          subscriptions created under this plan.
        </InputNotice>
        <div className="flex flex-column gap-2">
          {alternativeStripePriceIds.map(({ workingId, value }) => (
            <div key={workingId} className="flex gap-2 w-15rem max-w-full">
              <InputText
                className="flex-grow-1"
                value={value}
                onChange={(e) => {
                  const newValue = e.target.value.trim();
                  setAlternativeStripePriceIds((prev) =>
                    prev.map((item) =>
                      item.workingId === workingId ? { workingId, value: newValue } : item,
                    ),
                  );
                  setHasUnsavedChanges(true);
                }}
              />
              <BasicIconButton
                onClick={() => {
                  setAlternativeStripePriceIds((prev) =>
                    prev.filter((item) => item.workingId !== workingId),
                  );
                  setHasUnsavedChanges(true);
                }}
                icon={PrimeIcons.TRASH}
              />
            </div>
          ))}
          <Button
            label="Add Price ID"
            className="p-button-secondary p-button-sm w-max mt-1"
            onClick={() => {
              setAlternativeStripePriceIds((prev) => [...prev, { workingId: nanoid(), value: '' }]);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
      </div>
      <div className="flex gap-3 mt-2">
        <Button
          label={saveButtonLabel}
          disabled={
            !name.trim() ||
            (price && Number(price) && !invoiceFrequency) ||
            !hasUnsavedChanges ||
            isPending
          }
          onClick={() => {
            onSave({
              name,
              displayName,
              description,
              items,
              endAt: endType === EndType.Date ? endAt : null,
              durationDays: endType === EndType.DurationDays ? durationDays : null,
              price: price || '0',
              invoiceFrequency: price && price !== '0' ? invoiceFrequency : null,
              promoCodes: promoCodes.map(({ id, code, workingSuccessMessage }) => ({
                id,
                code,
                successMessage: workingSuccessMessage
                  ? { delta: JSON.stringify(workingSuccessMessage.delta) }
                  : null,
              })),
              stripePriceId,
              alternativeStripePriceIds: alternativeStripePriceIds.map(({ value }) => value),
            });
          }}
        />
        {onCancel && (
          <Button
            className="p-button-outlined"
            label="Cancel"
            disabled={isPending}
            onClick={onCancel}
          />
        )}
      </div>
      <div
        ref={addItemPopover}
        // @ts-expect-error -- popover
        popover="auto"
        className="border-round"
        id={`addItemPopover${productId}`}
        style={{
          // This (position-anchor) doesn't work yet.
          // positionAnchor: `addItemButton${productId}`,
          top: 'anchor(bottom)',
          left: 'anchor(left)',
          margin: '3px 0 0 0',
        }}
      >
        <div className="input-field">
          <label htmlFor={`addItemIdentifier-${productId}`}>Item</label>
          <div className="flex align-items-center gap-2">
            <InputText
              id={`addItemIdentifier-${productId}`}
              className="w-14rem"
              value={addingItemIdentifier}
              onChange={(e) => {
                setAddingItemIdentifier(e.target.value);
              }}
              autoFocus
            />
            <Button
              rounded
              icon={PrimeIcons.CHECK}
              onClick={() => {
                const item = addingItemIdentifier.trim();
                if (item) {
                  setItems((prevItems) => union(prevItems, [item]));
                  setHasUnsavedChanges(true);
                }
                setAddingItemIdentifier('');
                addItemPopover.current?.hidePopover();
              }}
            />
          </div>
        </div>
        {filteredProductItemOptions.length > 0 && (
          <div className="flex gap-1 flex-wrap">
            {filteredProductItemOptions.map((item) => (
              <Chip
                key={item}
                className="cursor-pointer"
                label={item}
                onClick={() => {
                  setItems((prevItems) => union(prevItems, [item]));
                  setHasUnsavedChanges(true);
                  addItemPopover.current?.hidePopover();
                }}
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};
