import { useQuery } from '@apollo/client';
import type { PagePurpose } from '@wirechunk/lib/api.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import { PagePurposesDocument } from './queries.generated.js';

type PagePurposes = {
  pagePurposes: PagePurpose[] | undefined;
  totalCount: number | undefined;
  limit: number | undefined;
  loading: boolean;
};

export const usePagePurposes = (
  platformId: string,
  onError: ErrorHandler['onError'],
): PagePurposes => {
  const { data, loading } = useQuery(PagePurposesDocument, {
    onError,
    variables: { platformId },
  });
  return {
    pagePurposes: data?.pagePurposes.pagePurposes,
    totalCount: data?.pagePurposes.totalCount,
    limit: data?.pagePurposes.limit,
    loading,
  };
};
