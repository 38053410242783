import { useQuery } from '@apollo/client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { DocumentComponent } from '@wirechunk/lib/mixer/types/components.js';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { useFileDownload } from '../../../hooks/useFileDownload.js';
import { Spinner } from '../../Spinner.js';
import { DocumentDocument } from './queries.generated.js';
import { useDocumentDownloadPromptDialog } from './use-document-download-prompt-dialog.js';
import { documentDownloadUrl } from './util.js';

type DocumentBodyProps = Omit<DocumentComponent, 'documentId'> & {
  documentId: string;
};

const DocumentBody: FunctionComponent<DocumentBodyProps> = (props) => {
  const { onErrorToast } = useErrorHandler();
  const { data, loading } = useQuery(DocumentDocument, {
    // TODO: Handle users who are not authorized to view a document better (e.g., with a missing feature tag or role).
    onError: onErrorToast,
    variables: { id: props.documentId },
  });
  const { download, downloadingFileIds } = useFileDownload();
  const showPrompt = useDocumentDownloadPromptDialog(download);

  const doc = data?.document;
  const currentVersion = doc?.currentVersion;

  if (doc && currentVersion) {
    return (
      <div
        className={clsx(
          componentClassName(props),
          // Display this component's native styling only when a document is found.
          'flex-column gap-1 md:flex-row md:gap-2 p-3 border-1 border-round flex justify-content-between align-items-center',
        )}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="flex align-items-center gap-3">
              <i className={PrimeIcons.FILE} />
              <span className="font-medium">{doc.name}</span>
            </div>
            <Button
              icon={PrimeIcons.DOWNLOAD}
              label="Download"
              disabled={downloadingFileIds.includes(doc.id)}
              onClick={() => {
                if (doc.downloadPrompt) {
                  showPrompt(
                    doc.id,
                    documentDownloadUrl(doc.id),
                    currentVersion.downloadName,
                    doc.downloadPrompt,
                  );
                } else {
                  download(doc.id, documentDownloadUrl(doc.id), currentVersion.downloadName);
                }
              }}
            />
          </Fragment>
        )}
      </div>
    );
  }

  return null;
};

export const Document: FunctionComponent<DocumentComponent> = (props) => {
  const id = props.documentId || props.resourceId;
  return id ? <DocumentBody {...props} documentId={id} /> : null;
};
