import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent, useRef } from 'react';
import { usePlatformContext } from '../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { SubscriptionDetails } from '../../SubscriptionDetails/SubscriptionDetails.js';
import type { OrganizationQuery } from '../queries.generated.js';
import { DeleteSubscriptionOrganization } from './DeleteSubscriptionOrganization.js';
import { EditSubscriptionOrganizationDocument } from './mutations.generated.js';

type Organization = OrganizationQuery['organization'];

type SubscriptionOrg = Organization['subscriptionOrganizations'][number];

type SubscriptionOrganizationProps = {
  subscriptionOrganization: SubscriptionOrg;
  organization: Organization;
  refetchOrganization: () => Promise<ApolloQueryResult<OrganizationQuery>>;
};

export const SubscriptionOrganization: FunctionComponent<SubscriptionOrganizationProps> = ({
  subscriptionOrganization,
  organization,
  refetchOrganization,
}) => {
  const { subscription } = subscriptionOrganization;
  const { permissions } = usePlatformContext();
  const dialog = useDialog();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [editSubscriptionOrganization, { loading: isSavingSubscriptionOrganizationEdits }] =
    useMutation(EditSubscriptionOrganizationDocument, { onError });

  const menu = useRef<Menu>(null);

  const userCanEditOrgSubscription = permissions.includes(Permission.EditSubscription);

  return (
    <Fragment>
      <ErrorMessage />
      <div className="flex justify-content-between align-items-start">
        <h3 className="text-lg mt-0">{subscription.plan.name} subscription details</h3>
        {userCanEditOrgSubscription && !subscriptionOrganization.isSubscriptionManager && (
          <Button
            label="Advanced options"
            className="p-button-sm p-button-secondary"
            onClick={(e) => menu.current?.show(e)}
          />
        )}
      </div>
      {subscriptionOrganization.isSubscriptionManager && (
        <div className="mb-3 font-italic text-sm">This org manages this subscription.</div>
      )}
      <SubscriptionDetails
        subscriptionId={subscription.id}
        onError={onError}
        clearMessages={clearMessages}
      />
      <Menu
        ref={menu}
        popup
        aria-disabled={isSavingSubscriptionOrganizationEdits}
        model={[
          {
            label: 'Set organization as subscription manager',
            command: () => {
              if (!isSavingSubscriptionOrganizationEdits) {
                dialog({
                  confirm:
                    'Are you sure you want to set this organization as the manager for this subscription?',
                  props: {
                    onAccept: () => {
                      void editSubscriptionOrganization({
                        variables: {
                          id: subscriptionOrganization.id,
                          isSubscriptionManager: true,
                        },
                      });
                    },
                  },
                });
              }
            },
          },
          {
            label: 'Remove organization from subscription',
            command: () => {
              if (!isSavingSubscriptionOrganizationEdits) {
                dialog({
                  content: (
                    <DeleteSubscriptionOrganization
                      id={subscriptionOrganization.id}
                      planName={subscription.plan.name}
                      organization={organization}
                      onDeleted={() => {
                        void refetchOrganization();
                      }}
                    />
                  ),
                  props: {
                    header: 'Remove organization’s subscription',
                    className: 'dialog-width-lg',
                  },
                });
              }
            },
          },
        ]}
      />
    </Fragment>
  );
};
