import { CollapsiblePanelComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

export const EditCollapsiblePanel: FunctionComponent<
  EditComponentContentProps<CollapsiblePanelComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editCollapsiblePanelHeader">Element header</label>
      <InputText
        id="editCollapsiblePanelHeader"
        className="w-full"
        value={component.header || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, header: e.target.value }));
        }}
      />
    </div>
  </Fragment>
);
