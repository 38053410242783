import { DataSource, ProvidePropsComponent } from '@wirechunk/lib/mixer/types/components.js';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { FunctionComponent, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PropsContextProvider, usePropsContext } from '../../../contexts/props-context.js';
import { RenderMixerChildren } from '../../RenderMixerChildren.js';

export const ProvideProps: FunctionComponent<ProvidePropsComponent> = ({ props, children }) => {
  const [searchParams] = useSearchParams();
  const propsContext = usePropsContext();

  const overridePropsContext = useMemo(() => {
    if (!props) {
      return propsContext;
    }
    const newProps: ContextData = {};
    for (const { name, ...prop } of props) {
      if (name) {
        switch (prop.source.type) {
          case DataSource.Direct:
            if (prop.source.value) {
              const { value } = prop.source.value;
              newProps[name] = value;
            }
            break;
          case 'QueryParameter':
            if (prop.source.parameter) {
              newProps[name] = searchParams.get(prop.source.parameter);
            }
        }
      }
    }
    return { ...propsContext, ...newProps };
  }, [props, propsContext, searchParams]);

  return (
    <PropsContextProvider value={overridePropsContext}>
      <RenderMixerChildren>{children}</RenderMixerChildren>
    </PropsContextProvider>
  );
};
