import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren } from 'react';
import styles from './InputNotice.module.css';

export enum NoticeSeverity {
  Danger,
}

type InputNoticeProps = PropsWithChildren<{
  className?: string | null;
  // The default style is normal text.
  severity?: NoticeSeverity;
}>;

export const InputNotice: FunctionComponent<InputNoticeProps> = ({
  className,
  children,
  severity,
}) => (
  <small
    className={clsx(
      'block text-sm',
      className,
      styles.inputNotice,
      severity === NoticeSeverity.Danger && 'p-error',
    )}
  >
    {children}
  </small>
);
