import { clsx } from 'clsx';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import type {
  SiteContextSelector,
  SiteOption,
} from '../hooks/useSiteContextSelector/useSiteContextSelector.js';

const siteLabel = ({ domain, name }: SiteOption) => (
  <span>
    {name} <span className="opacity-80">({domain})</span>
  </span>
);

export const previewSiteIdParam = 'previewSiteId';

const valueTemplate = (opt: SiteOption | null) => (opt ? siteLabel(opt) : null);

type SiteSelectorProps = {
  className?: string | null;
  siteContextSelector: SiteContextSelector;
  inputId?: string;
};

export const SiteSelector: FunctionComponent<SiteSelectorProps> = ({
  className,
  siteContextSelector: { siteContext, siteOptions, loading, selectSite, searchTerm, setSearchTerm },
  inputId,
}) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <Dropdown
      inputId={inputId}
      className={clsx('dropdown-label-color-primary w-25rem max-w-full', className)}
      itemTemplate={siteLabel}
      filter
      filterTemplate={() => (
        <InputText
          className="w-full"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      )}
      emptyMessage={searchTerm ? 'No sites found' : 'Start typing...'}
      disabled={loading}
      value={siteContext?.id || null}
      placeholder="Select a site"
      optionDisabled={'optionDisabled' satisfies keyof SiteOption}
      optionValue={'id' satisfies keyof SiteOption}
      // This is needed so that we can use custom objects as options.
      optionLabel={'id' satisfies keyof SiteOption}
      valueTemplate={siteContext ? valueTemplate : undefined}
      options={siteOptions}
      onChange={({ value }) => {
        // Do not allow clearing the value.
        if (value && isString(value)) {
          selectSite(value);
          setSearchTerm('');
          // Append or replace a previewSiteId query param.
          setSearchParams((params) => {
            const newParams = new URLSearchParams(params);
            newParams.set(previewSiteIdParam, value);
            return newParams;
          });
        }
      }}
    />
  );
};
