import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import type Delta from 'quill-delta';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../hooks/useHasUnsavedChanges.js';
import { QuillEditor } from '../../../../../quill-editor.js';
import { CreateDocumentDownloadPromptMutationVariables } from './mutations.generated.js';

type EditPromptBodyProps = {
  prompt?: {
    name: string;
    promptHeader: string;
    acceptLabel: string;
    prompt: { __typename: 'Delta'; delta: string };
  };
  loading: boolean;
  onSave: (data: Omit<CreateDocumentDownloadPromptMutationVariables, 'platformId'>) => void;
  onCancel: () => void;
  onError: ErrorHandler['onError'];
};

export const EditPromptBody: FunctionComponent<EditPromptBodyProps> = ({
  prompt,
  loading,
  onSave,
  onCancel,
  onError,
}) => {
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();
  const [name, setName] = useState('');
  const [promptHeader, setPromptHeader] = useState<string>('');
  const [promptText, setPromptText] = useState<Delta | null>(null);
  const [acceptLabel, setAcceptLabel] = useState<string>('');

  useEffect(() => {
    if (prompt) {
      setName(prompt.name);
      setPromptHeader(prompt.promptHeader);
      // The QuillEditor isn't a controlled component. Here we reset the local state so that, if the prompt is saved
      // without the prompt text being edited, we use the existing prompt text.
      setPromptText(null);
      setAcceptLabel(prompt.acceptLabel);
      resetHasUnsavedChanges();
    }
  }, [prompt, resetHasUnsavedChanges]);

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="promptName">Name (internal only)</label>
        <InputText
          id="promptName"
          className="w-18rem"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="promptDialogHeader">Dialog header</label>
        <InputText
          id="promptDialogHeader"
          className="w-18rem"
          value={promptHeader}
          onChange={(e) => {
            setPromptHeader(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label>Text</label>
        <QuillEditor
          // Force reset of editor when the saved prompt changes.
          key={prompt?.prompt.delta || ''}
          initialValue={prompt?.prompt.delta}
          onChange={(delta) => {
            setPromptText(delta);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="promptAcceptLabel">Accept button label</label>
        <InputText
          id="promptAcceptLabel"
          className="w-10rem"
          value={acceptLabel}
          placeholder="I Accept"
          onChange={(e) => {
            setAcceptLabel(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="flex gap-3 justify-content-end">
        <Button label="Cancel" className="p-button-secondary" onClick={onCancel} />
        <Button
          label="Save"
          disabled={!hasUnsavedChanges || loading || !name || !promptHeader || !acceptLabel}
          onClick={() => {
            if (name && promptHeader && acceptLabel) {
              const promptDelta = promptText ? JSON.stringify(promptText) : prompt?.prompt.delta;
              if (!promptDelta) {
                onError('Prompt text is required');
                return;
              }
              onSave({
                name,
                promptHeader,
                prompt: { delta: promptDelta },
                acceptLabel,
              });
            }
          }}
        />
      </div>
    </Fragment>
  );
};
