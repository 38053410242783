import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { SiteLogoComponent } from '@wirechunk/lib/mixer/types/components.js';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const SiteLogo: FunctionComponent<SiteLogoComponent> = (props) => {
  const { name, logoUrl } = useSiteContext();

  return logoUrl ? (
    <img className={componentClassName(props)} src={logoUrl} alt={props.alt || ''} />
  ) : props.defaultAgencyName || props.fallbackSiteName ? (
    <div className={componentClassName(props)}>{name}</div>
  ) : null;
};
