import { OptionInput } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { FunctionComponent, PropsWithChildren } from 'react';

type OptionsSectionProps = PropsWithChildren<
  {
    title: string;
    allowSave: boolean;
    // Defaults to true.
    showSaveButton?: boolean;
  } & (
    | { onSave: () => void }
    | {
        getOptions: () => Array<{ key: string; value: unknown }>;
        saveOptions: (options: OptionInput[]) => void;
      }
  )
>;

export const OptionsSection: FunctionComponent<OptionsSectionProps> = ({
  title,
  allowSave,
  showSaveButton = true,
  children,
  ...restProps
}) => (
  <div className="pb-4 border-bottom-1">
    <div className="flex align-items-center justify-content-between mb-3">
      <h4 className="my-0">{title}</h4>
      {showSaveButton && (
        <Button
          label="Save"
          disabled={!allowSave}
          onClick={() => {
            if ('onSave' in restProps) {
              restProps.onSave();
              return;
            }
            restProps.saveOptions(
              restProps.getOptions().map(({ key, value }) => ({
                key,
                value: JSON.stringify(value),
              })),
            );
          }}
        />
      )}
    </div>
    {children}
  </div>
);
