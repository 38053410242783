import { SiteLogoComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Checkbox } from 'primereact/checkbox';
import { Fragment, FunctionComponent } from 'react';
import { EditComponentContentProps } from './shared/types.js';

export const EditSiteLogo: FunctionComponent<EditComponentContentProps<SiteLogoComponent>> = ({
  component,
  setComponent,
}) => (
  <Fragment>
    <div className="input-field">
      <Checkbox
        inputId="editSiteLogoDefaultSiteName"
        checked={component.defaultAgencyName || component.fallbackSiteName || false}
        onChange={(e) => {
          setComponent(
            (c): SiteLogoComponent => ({
              ...c,
              defaultAgencyName: undefined,
              fallbackSiteName: e.checked,
            }),
          );
        }}
      />
      <label htmlFor="editSiteLogoDefaultAgencyName">Use the site name as fallback</label>
    </div>
  </Fragment>
);
