import { useMutation } from '@apollo/client';
import { Permission, Role, UserStatus } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { roleOptions, roleToHumanReadable } from '../../../../util/roles.js';
import { userStatusOptions } from '../../../../util/userStatuses.js';
import { OrganizationSelector } from '../../../OrganizationSelector/OrganizationSelector.js';
import { CreateUserDocument } from './mutations.generated.js';

type AddUserProps = {
  className?: string;
  onAdded: () => void;
  onCancel: () => void;
};

export const AddUser: FunctionComponent<AddUserProps> = ({ className, onAdded, onCancel }) => {
  const dialog = useDialog();
  const { id: platformId, permissions } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [orgType, setOrgType] = useState<'existing' | 'create'>('existing');
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<Role | null>(null);
  const [status, setStatus] = useState<UserStatus>(UserStatus.Active);
  const [createUser, { loading: isSubmitting }] = useMutation(CreateUserDocument, {
    onError,
  });

  const confirmedAddUser = () => {
    if (orgType === 'existing' && !organizationId) {
      return;
    }
    void createUser({
      variables: {
        platformId,
        ...(orgType === 'existing' ? { organizationId } : {}),
        email,
        firstName,
        lastName,
        role,
        status,
      },
      onCompleted: onAdded,
    });
  };

  const onAdd = () => {
    clearMessages();
    if (orgType === 'create' && role && role !== Role.OrganizationOwner) {
      dialog({
        confirm: (
          <span>
            Are you sure you want to create a new organization with this first user having the role{' '}
            <span className="font-bold">{roleToHumanReadable(role)}</span>?
          </span>
        ),
        props: {
          onAccept: confirmedAddUser,
        },
      });
    } else {
      confirmedAddUser();
    }
  };

  const hasEditPermission = permissions.includes(Permission.Edit);
  const hasEditStatusPermission = permissions.includes(Permission.EditUserStatus);
  const hasEditRolePermission = permissions.includes(Permission.EditUserRole);

  return (
    <div className={clsx('border-1 border-round p-4', className)}>
      <h3 className="mt-1">Add User</h3>
      <ErrorMessage />
      <div className="md:flex flex-column md:flex-row gap-4">
        <div className="input-field md:w-6">
          <label htmlFor="organizationType">Organization Type</label>
          <Dropdown
            id="organizationType"
            className="w-full"
            value={orgType}
            options={[
              { label: 'Existing', value: 'existing' },
              { label: 'Create New', value: 'create' },
            ]}
            onChange={(e) => {
              setOrgType(e.value as 'existing' | 'create');
            }}
          />
        </div>
        <div className="input-field md:w-6">
          {orgType === 'existing' && (
            <Fragment>
              <label htmlFor="newUserOrganization">Organization</label>
              <OrganizationSelector
                platformId={platformId}
                inputId="newUserOrganization"
                className="w-full"
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                onError={onError}
              />
            </Fragment>
          )}
        </div>
      </div>
      <div className="md:flex flex-column md:flex-row gap-4">
        <div className="input-field md:w-6">
          <label htmlFor="firstName">First name</label>
          <InputText
            id="firstName"
            className="w-full"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div className="input-field md:w-6">
          <label htmlFor="lastName">Last name</label>
          <InputText
            id="lastName"
            className="w-full"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="md:flex flex-column md:flex-row gap-4">
        <div className={clsx('input-field', hasEditPermission && 'md:w-6')}>
          <label htmlFor="email">Email address</label>
          <InputText
            id="email"
            className="w-full"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        {hasEditStatusPermission ? (
          <div className="input-field md:w-6">
            <label htmlFor="status">Status</label>
            <Dropdown
              className="w-full"
              options={userStatusOptions}
              value={status}
              onChange={(e) => {
                setStatus(e.value as UserStatus);
              }}
            />
          </div>
        ) : (
          <div className="md:w-6" />
        )}
      </div>
      {hasEditRolePermission && (
        <div className="md:flex flex-column md:flex-row gap-4">
          <div className="input-field md:w-6">
            <label htmlFor="role">Role</label>
            <Dropdown
              className="w-full"
              options={roleOptions}
              value={role}
              onChange={(e) => {
                setRole(e.value as Role);
              }}
            />
          </div>
          <div className="md:w-6" />
        </div>
      )}
      <div className="flex gap-3 mt-3">
        <Button
          label="Add"
          disabled={
            (orgType === 'existing' && !organizationId) ||
            !firstName.trim() ||
            !lastName.trim() ||
            !email.trim() ||
            isSubmitting
          }
          onClick={onAdd}
        />
        <Button
          className="p-button-outlined"
          label="Cancel"
          disabled={isSubmitting}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
