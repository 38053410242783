import { InvoiceFrequency } from './api.js';

// Returns a string like 'year', 'month', etc. to display to a person.
export const invoiceFrequencyHumanInterval = (frequency: InvoiceFrequency): string => {
  switch (frequency) {
    case InvoiceFrequency.Annually:
      return 'year';
    case InvoiceFrequency.Monthly:
      return 'month';
    case InvoiceFrequency.Quarterly:
      return 'quarter';
    case InvoiceFrequency.SemiAnnually:
      return '6 months';
  }
};

// Returns a string like 'a year', '2 years', etc. to display to a person. The special thing about this function is
// that it would say 'a year' rather than '1 year' to make it more human-friendly.
export const invoiceFrequencyPluralHumanIntervals = (
  frequency: InvoiceFrequency,
  repeat: number,
): string => {
  switch (frequency) {
    case InvoiceFrequency.Annually:
      return repeat === 1 ? 'a year' : `${repeat} years`;
    case InvoiceFrequency.Monthly:
      return repeat === 1 ? 'a month' : `${repeat} months`;
    case InvoiceFrequency.Quarterly:
      return repeat === 1 ? 'a quarter' : `${repeat} quarters`;
    case InvoiceFrequency.SemiAnnually:
      return repeat === 1 ? '6 months' : `${repeat * 6} months`;
  }
};
