import {
  ExpressionType,
  IncompleteExpression,
  isBooleanExpression,
  isCompleteExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import { ComponentType, ConditionalLogicComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Expression } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent, useEffect } from 'react';
import { useStateRef } from '../../../../hooks/use-state-ref.js';
import { ExpressionBuilder } from '../../../ExpressionBuilder/ExpressionBuilder.js';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { type ComponentEditorListener, EditComponentContentProps } from '../shared/types.js';

const topLevelTypes: ExpressionType[] = [ExpressionType.Boolean];

export const EditConditionalLogic: FunctionComponent<
  EditComponentContentProps<ConditionalLogicComponent>
> = ({ component, eventHooks }) => {
  const [expression, setExpression] = useStateRef<IncompleteExpression<Expression> | null>(
    () => component.condition || null,
  );

  const setExpressionWrapped = (expression: IncompleteExpression<Expression>) => {
    setExpression(() => expression);
    // TODO: Update validation state.
  };

  useEffect(() => {
    const validateExpression: ComponentEditorListener = (component) => {
      // This type check is just to satisfy TypeScript.
      if (component.type === ComponentType.ConditionalLogic) {
        if (!expression.current || !isCompleteExpression(expression.current)) {
          const errorMessage = 'Please complete the expression to save this component.';
          return {
            action: 'prevent',
            errorMessage,
          };
        }
        if (!isBooleanExpression(expression.current)) {
          const errorMessage = 'This expression must be configured to evaluate to a boolean value.';
          return {
            action: 'prevent',
            errorMessage,
          };
        }
        return {
          action: 'continue',
          component: {
            ...component,
            condition: expression.current,
          } satisfies ConditionalLogicComponent,
        };
      }
      return { action: 'continue' };
    };

    eventHooks.onBeforeLeave(validateExpression);

    return () => {
      eventHooks.offBeforeLeave(validateExpression);
    };
  }, [eventHooks, expression]);

  return (
    <Fragment>
      <label>Condition</label>
      <ExpressionBuilder
        className="mb-2"
        id={component.id}
        expression={expression.current}
        setExpression={setExpressionWrapped}
        validationMessages={[]}
        types={topLevelTypes}
      />
      <InputNotice>
        Note that this condition needs to evaluate to either{' '}
        <span className="font-medium">true</span> or <span className="font-medium">false</span>.
      </InputNotice>
    </Fragment>
  );
};
