/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type FormTemplateConfirmationConfigQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type FormTemplateConfirmationConfigQuery = {
  formTemplate: {
    __typename: 'FormTemplate';
    id: string;
    confirmationAction: Types.ConfirmationAction;
    confirmationMessageComponents?: string | null;
    confirmationRedirectUrl?: string | null;
    components: string;
    confirmationRedirectParameters: Array<{
      __typename: 'FormConfirmationRedirectUrlParameter';
      id: string;
      parameter: string;
      componentName: string;
    }>;
    steps: Array<{ __typename: 'FormTemplateStep'; id: string; components: string }>;
  };
};

export const FormTemplateConfirmationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FormTemplateConfirmationConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationMessageComponents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationRedirectUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmationRedirectParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FormTemplateConfirmationConfigQuery,
  FormTemplateConfirmationConfigQueryVariables
>;
