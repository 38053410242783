import { useMutation, useQuery } from '@apollo/client';
import { isStringArray } from '@wirechunk/lib/arrays.js';
import { formatDateTime } from '@wirechunk/lib/dates.js';
import { isPageOptionVersionKey, PageOptionVersionKey } from '@wirechunk/lib/page-options.js';
import { clsx } from 'clsx';
import { compact, isBoolean } from 'lodash-es';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { withEditPageContext } from '../../../../../../../../contexts/admin/edit-page-context/edit-page-context.js';
import {
  CurrentUser,
  CurrentUserProvider,
  useCurrentUser,
} from '../../../../../../../../contexts/CurrentUserContext/CurrentUserContext.js';
import {
  DialogContext,
  useDialog,
} from '../../../../../../../../contexts/DialogContext/DialogContext.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../../contexts/PageContext/PageContext.js';
import {
  SiteContext,
  SiteContextProvider,
} from '../../../../../../../../contexts/SiteContext/SiteContext.js';
import { useToast } from '../../../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../../../hooks/useErrorHandler.js';
import { NoneLabel } from '../../../../../../../NoneLabel/NoneLabel.js';
import { ParseAndRenderComponents } from '../../../../../../../ParseAndRenderComponents.js';
import { DataTableWithPaginator } from '../../../../../Users/UserDetails/DataTableWithPaginator.js';
import { RestorePageOptionVersionDocument } from './mutations.generated.js';
import { PageVersionsDocument, PageVersionsQuery } from './queries.generated.js';

type Row = PageVersionsQuery['pageVersions']['versions'][number];

const optionVersionKeyHumanReadable = (key: PageOptionVersionKey) => {
  switch (key) {
    case PageOptionVersionKey.BodyStyles:
      return 'Body styles';
    case PageOptionVersionKey.Components:
      return 'Design components';
    case PageOptionVersionKey.FeatureTag:
    case PageOptionVersionKey.ProductItem:
      return 'Product item';
    case PageOptionVersionKey.Path:
      return 'Path';
    case PageOptionVersionKey.Public:
      return 'Require sign in';
    case PageOptionVersionKey.Roles:
      return 'Roles';
    case PageOptionVersionKey.SpecialPurpose:
      return 'Special purpose';
    case PageOptionVersionKey.Title:
      return 'Title';
    case PageOptionVersionKey.Caption:
      return 'Caption';
    case PageOptionVersionKey.FeaturedImageUrl:
      return 'Featured image URL';
    case PageOptionVersionKey.MetaDescription:
      return 'Meta description';
    case PageOptionVersionKey.MetaRobots:
      return 'Meta robots';
    case PageOptionVersionKey.MetaTitle:
      return 'Meta title';
  }
};

type ParsedOptionVersion = {
  id: string;
  key: PageOptionVersionKey;
  displayValue: ReactNode;
};

const toParsedOptionVersion = (
  { id, key, value }: Row['optionVersions'][number],
  user: CurrentUser,
  siteContext: SiteContext,
  pageContext: PageContext,
  showDialog: DialogContext,
): ParsedOptionVersion | null => {
  if (!isPageOptionVersionKey(key)) {
    return null;
  }
  let displayValue: ReactNode;
  switch (key) {
    case PageOptionVersionKey.BodyStyles:
      displayValue = '(No preview available)';
      break;
    case PageOptionVersionKey.Caption:
    case PageOptionVersionKey.FeaturedImageUrl:
    case PageOptionVersionKey.FeatureTag:
    case PageOptionVersionKey.ProductItem:
    case PageOptionVersionKey.MetaDescription:
    case PageOptionVersionKey.MetaTitle:
    case PageOptionVersionKey.Path:
    case PageOptionVersionKey.SpecialPurpose:
    case PageOptionVersionKey.Title:
      displayValue = value;
      break;
    case PageOptionVersionKey.Components:
      displayValue = (
        <Button
          className="p-button-secondary p-button-sm"
          onClick={() => {
            showDialog({
              content: (
                <SiteContextProvider value={siteContext}>
                  <PageContextProvider value={pageContext}>
                    <CurrentUserProvider user={user} loadingUser={false}>
                      <ParseAndRenderComponents componentsJSON={value} />
                    </CurrentUserProvider>
                  </PageContextProvider>
                </SiteContextProvider>
              ),
              props: {
                header: 'Design components',
                className: 'dialog-width-xl',
              },
            });
          }}
          label="View"
        />
      );
      break;
    case PageOptionVersionKey.MetaRobots:
    case PageOptionVersionKey.Roles:
      try {
        const parsedValue = JSON.parse(value) as unknown;
        if (isStringArray(parsedValue)) {
          if (parsedValue.length) {
            displayValue = parsedValue.join(', ');
          } else {
            displayValue = <NoneLabel />;
          }
        } else {
          displayValue = '(Invalid value)';
        }
      } catch (error) {
        displayValue = '(Invalid value)';
      }
      break;
    case PageOptionVersionKey.Public:
      try {
        const parsedValue = JSON.parse(value) as unknown;
        if (isBoolean(parsedValue)) {
          // Note that "public" is rendered as "Require sign in" in the UI, which inverts the value.
          displayValue = parsedValue ? 'No' : 'Yes';
        } else {
          displayValue = 'No';
        }
      } catch (error) {
        displayValue = '(Invalid value)';
      }
  }
  return {
    id,
    key,
    displayValue,
  };
};

type OptionVersionsCellProps = {
  row: Row;
  user: CurrentUser;
  site: SiteContext;
  pageContext: PageContext;
  restoreOptionVersion: (id: string) => void;
};

const OptionVersionsCell: FunctionComponent<OptionVersionsCellProps> = ({
  row,
  user,
  site,
  pageContext,
  restoreOptionVersion,
}) => {
  const dialog = useDialog();

  return (
    <div className="flex flex-column gap-2">
      {compact(
        row.optionVersions.map((v) => toParsedOptionVersion(v, user, site, pageContext, dialog)),
      ).map(({ id, key, displayValue }, index) => (
        <div
          key={id}
          className={clsx(index > 0 && 'border-top-1 pt-2', 'flex gap-2 align-items-center')}
        >
          <div className="font-medium w-max min-w-max">{optionVersionKeyHumanReadable(key)}:</div>
          <div className="flex-grow-1">{displayValue}</div>
          <Button
            className="p-button-sm w-max min-w-max"
            label="Restore"
            onClick={() => {
              dialog({
                confirm: `Are you sure you want to restore this version of the page’s ${optionVersionKeyHumanReadable(
                  key,
                )}?`,
                props: {
                  onAccept: () => {
                    restoreOptionVersion(id);
                  },
                },
              });
            }}
          />
        </div>
      ))}
    </div>
  );
};

const byUserTooltipTargetClassName = 'by-user-tooltip-target';

export const PageHistory = withEditPageContext(({ page, refetchPage, site }) => {
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const { user } = useCurrentUser();
  const byUserTooltip = useRef<Tooltip>(null);
  const [limit, setLimit] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const { data, loading, refetch } = useQuery(PageVersionsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      pageId: page.id,
      page: pageIndex,
      limit,
    },
  });
  const [restoreOptionVersion] = useMutation(RestorePageOptionVersionDocument, {
    onError,
    onCompleted: () => {
      toastSuccess('Page option version restored.');
      void refetchPage();
      void refetch();
    },
  });
  const pageContext = useMemo<PageContext>(
    () => ({
      id: page.id,
      title: page.title,
      viewMode: ViewMode.Live,
    }),
    [page.id, page.title],
  );

  useEffect(() => {
    byUserTooltip.current?.updateTargetEvents(undefined);
  }, [data?.pageVersions.versions]);

  return (
    <div>
      <ErrorMessage />
      <DataTableWithPaginator
        value={data?.pageVersions.versions || []}
        loading={loading}
        page={pageIndex}
        rows={limit}
        totalRecords={data?.pageVersions.totalCount || 0}
        setPage={setPageIndex}
        setRows={setLimit}
      >
        <Column
          header="Timestamp"
          className="lg:w-13rem lg:max-w-13rem"
          body={(row: Row) => <span>{formatDateTime(row.createdAt)}</span>}
        />
        <Column
          header="User"
          className="lg:w-13rem lg:max-w-13rem"
          body={(row: Row) => (
            <div
              className={`${byUserTooltipTargetClassName} max-w-full overflow-x-hidden text-overflow-ellipsis white-space-nowrap`}
              data-pr-tooltip={row.byUser.email}
              data-pr-position="top"
              data-pr-showdelay={100}
            >
              {row.byUser.displayName}
            </div>
          )}
        />
        <Column
          header="Changes"
          body={(row: Row) => (
            <OptionVersionsCell
              row={row}
              user={user}
              site={site.site}
              pageContext={pageContext}
              restoreOptionVersion={(id) => {
                void restoreOptionVersion({
                  variables: {
                    id,
                  },
                });
              }}
            />
          )}
        />
      </DataTableWithPaginator>
      <Tooltip ref={byUserTooltip} target={`.${byUserTooltipTargetClassName}`} />
    </div>
  );
});
