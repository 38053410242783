import { useMutation } from '@apollo/client';
import { clsx } from 'clsx';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { EditableMode } from '../../../EditableMode/EditableMode.js';
import { EditUserNameDocument } from './mutations.generated.js';
import type { UserRow } from './types.js';

type UserLastNameCellProps = {
  user: UserRow;
  isSavingUserEdits: boolean;
  onError: ErrorHandler['onError'];
};

export const UserLastNameCell: FunctionComponent<UserLastNameCellProps> = ({
  user,
  isSavingUserEdits,
  onError,
}) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const [editUserLastName, { loading: isSaving }] = useMutation(EditUserNameDocument, {
    onError,
  });
  const [newLastName, setNewLastName] = useState(user.lastName);

  const onCancelEdit = useCallback(() => {
    setNewLastName(user.lastName);
  }, [user.lastName]);

  return (
    <EditableMode
      // This key prop forces the component to remount when the name change is saved so that the state is reset
      // to view mode.
      key={user.lastName}
      editMode={() => (
        <InputText
          className="w-full"
          value={newLastName}
          onChange={(e) => {
            setNewLastName(e.target.value);
          }}
        />
      )}
      onSave={() => {
        if (newLastName && newLastName !== user.lastName) {
          dialog({
            confirm: (
              <Fragment>
                Are you sure you want to change {user.displayName}’s last name to{' '}
                <b>{newLastName}</b>?
              </Fragment>
            ),
            props: {
              onAccept: () => {
                void editUserLastName({
                  variables: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: newLastName,
                  },
                  onCompleted: () => {
                    toastSuccess('Last name updated.');
                  },
                });
              },
            },
          });
        }
      }}
      onCancel={onCancelEdit}
    >
      <div
        className={clsx('flex flex-column', (isSaving || isSavingUserEdits) && 'text-color-muted')}
      >
        <span className="text-overflow-ellipsis overflow-x-hidden">{user.lastName}</span>
      </div>
    </EditableMode>
  );
};
