import { TabPanel, TabView } from 'primereact/tabview';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { SubscriptionAuditLogs } from './SubscriptionAuditLogs.js';
import { UserAuditLogs } from './UserAuditLogs.js';

export const AuditLogs: FunctionComponent = () => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { id: platformId } = usePlatformContext();

  return (
    <PageContainer title="Audit Logs">
      <ErrorMessage />
      <TabView
        activeIndex={activeTabIndex}
        onTabChange={(e) => {
          setActiveTabIndex(e.index);
        }}
        panelContainerClassName="p-0"
      >
        <TabPanel header="Users">
          <UserAuditLogs platformId={platformId} onError={onError} />
        </TabPanel>
        <TabPanel header="Subscriptions">
          <SubscriptionAuditLogs platformId={platformId} onError={onError} />
        </TabPanel>
      </TabView>
    </PageContainer>
  );
};
