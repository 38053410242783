import { useLazyQuery } from '@apollo/client';
import { clsx } from 'clsx';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SelectItem } from 'primereact/selectitem';
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { ErrorHandler } from '../../hooks/useErrorHandler.js';
import { UsersDocument } from './queries.generated.js';

type UserSelectorProps = {
  platformId: string;
  // Optionally limit the query to a specific org.
  orgId?: string | null;
  onError: ErrorHandler['onError'];
  inputId?: string;
  className?: string;
  placeholder?: string;
  userId: string | null;
  setUserId: Dispatch<SetStateAction<string | null>>;
  // Resets the page index to 0 in the parent view if the parent view has pagination and this component is
  // used to filter data.
  setPage?: Dispatch<SetStateAction<number>>;
};

export const UserSelector: FunctionComponent<UserSelectorProps> = ({
  platformId,
  orgId,
  onError,
  inputId,
  className,
  placeholder,
  userId,
  setUserId,
  setPage,
}) => {
  const [searchText, setSearchText] = useState('');
  const [userOptions, setUserOptions] = useState<SelectItem[]>([]);

  const [fetchUsers] = useLazyQuery(UsersDocument, {
    onError,
  });

  useEffect(() => {
    if (!searchText) {
      setUserOptions([]);
      return;
    }
    void fetchUsers({
      variables: {
        platformId,
        orgId,
        search: searchText,
      },
      onCompleted: (data) => {
        setUserOptions(
          data.users.users.map((user) => ({
            label: `${user.displayName} (${user.email})`,
            value: user.id,
          })),
        );
      },
    });
  }, [fetchUsers, orgId, platformId, searchText]);

  return (
    <Dropdown
      inputId={inputId}
      placeholder={placeholder ?? 'Search for a user'}
      className={clsx('w-24rem max-w-full', className)}
      value={userId}
      options={userOptions}
      filter
      filterTemplate={() => (
        <InputText
          className="w-full"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      )}
      onChange={(e) => {
        setUserId(e.value as string | null);
        if (setPage) {
          setPage(0);
        }
      }}
      emptyMessage={searchText ? 'No users found' : 'Search for a user'}
      showClear
    />
  );
};
