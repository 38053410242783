import { useMutation } from '@apollo/client';
import { OptionalStringUpdate, PublishStatus, Role } from '@wirechunk/lib/api.js';
import {
  isMetaRobotsOption,
  MetaRobotsOption,
  metaRobotsOptions,
} from '@wirechunk/lib/page-options.js';
import { isString } from 'lodash-es';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Fragment, useEffect, useState } from 'react';
import { withEditPageContext } from '../../../../../../../../contexts/admin/edit-page-context/edit-page-context.js';
import { usePlatformContext } from '../../../../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../../../../contexts/ToastContext.js';
import { useProductItemPicklist } from '../../../../../../../../hooks/use-product-item-picklist/use-product-item-picklist.js';
import { useErrorHandler } from '../../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../../hooks/useHasUnsavedChanges.js';
import { publishStatusOptions } from '../../../../../../../../util/publishStatus.js';
import { roleOptions } from '../../../../../../../../util/roles.js';
import { InputNotice } from '../../../../../../../InputNotice/InputNotice.js';
import { EditPageDocument } from '../mutations.generated.js';

export const PageMeta = withEditPageContext(({ page }) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [editPage, { loading: savingEdits }] = useMutation(EditPageDocument, {
    onError,
  });

  const { toastSuccess } = useToast();
  const { id: platformId } = usePlatformContext();

  const { productItems, loading: loadingTags } = useProductItemPicklist(platformId, onError);

  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();
  const [title, setTitle] = useState('');
  const [metaTitle, setMetaTitle] = useState<string | null>(null);
  const [metaDescription, setMetaDescription] = useState<string | null>(null);
  const [metaRobots, setMetaRobots] = useState<MetaRobotsOption[] | null>(null);
  const [path, setPath] = useState('');
  const [status, setStatus] = useState<PublishStatus>(PublishStatus.Draft);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [productItem, setProductItem] = useState<string | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [featuredImageUrl, setFeaturedImageUrl] = useState<string | null>(null);
  const [caption, setCaption] = useState<string | null>(null);

  const [productItemRequired, setProductItemRequired] = useState(false);
  const [roleRequired, setRoleRequired] = useState(false);

  useEffect(() => {
    setTitle(page.title);
    setMetaTitle(page.metaTitle || null);
    setMetaDescription(page.metaDescription || null);
    setMetaRobots(page.metaRobots?.filter(isMetaRobotsOption) || null);
    setPath(page.path);
    setStatus(page.status);
    setIsPublic(page.public);
    setFeaturedImageUrl(page.featuredImageUrl || null);
    setCaption(page.caption || null);

    if (!page.public && page.productItem) {
      setProductItemRequired(true);
      setProductItem(page.productItem);
    } else {
      setProductItemRequired(false);
      setProductItem(null);
    }
    if (!page.public && page.roles.length) {
      setRoleRequired(true);
      setRoles(page.roles);
    } else {
      setRoleRequired(false);
      setRoles([]);
    }
  }, [page]);

  return (
    <div>
      <ErrorMessage />
      <div className="flex justify-content-end py-3">
        <Button
          className="p-button-success"
          label="Save page"
          disabled={!hasUnsavedChanges || savingEdits}
          onClick={() => {
            clearMessages();
            let productItemUpdate: OptionalStringUpdate;
            if (productItemRequired) {
              if (!productItem) {
                onError('You must select a product item if an item is required.');
                return;
              }
              productItemUpdate = { value: productItem };
            } else {
              productItemUpdate = { clear: true };
            }
            if (roleRequired && !roles.length) {
              onError('You must select at least one role if a role is required.');
              return;
            }

            const rolesToSave = roleRequired ? roles : [];

            void editPage({
              variables: {
                pageId: page.id,
                path,
                title,
                metaTitle,
                metaDescription,
                metaRobots,
                status,
                pagePurposeId: page.purpose.id,
                components: page.components,
                bodyStyles: page.bodyStyles,
                public: isPublic,
                productItem: productItemUpdate,
                roles: rolesToSave,
                featuredImageUrl,
                caption,
              },
              onCompleted: () => {
                toastSuccess('Page saved.');
                resetHasUnsavedChanges();
              },
            });
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageTitle">Title</label>
        <InputText
          id="pageTitle"
          className="w-full"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageMetaTitle">Meta title</label>
        <InputText
          id="pageMetaTitle"
          className="w-full"
          value={metaTitle || ''}
          onChange={(e) => {
            setMetaTitle(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageMetaDescription">Meta description</label>
        <InputTextarea
          id="pageMetaDescription"
          className="w-full"
          value={metaDescription || ''}
          onChange={(e) => {
            setMetaDescription(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageMetaRobots">Meta robots</label>
        <MultiSelect
          inputId="pageMetaRobots"
          className="w-full"
          value={metaRobots}
          options={metaRobotsOptions}
          onChange={({ value }) => {
            if (Array.isArray(value)) {
              // Always set the values sorted alphabetically. This keeps the diff cleaner for audit logging.
              setMetaRobots(value.filter(isMetaRobotsOption).sort());
              triggerHasUnsavedChanges();
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pagePath">URL path</label>
        <InputText
          id="pagePath"
          className="w-full"
          value={path}
          onChange={(e) => {
            setPath(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageStatus">Status</label>
        <Dropdown
          inputId="pageStatus"
          className="w-full"
          options={publishStatusOptions}
          value={status}
          onChange={(e) => {
            setStatus(e.value as PublishStatus);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field flex align-items-center gap-3">
        <Checkbox
          inputId="pagePublic"
          checked={!isPublic}
          onChange={(e) => {
            setIsPublic(!e.checked);
            triggerHasUnsavedChanges();
          }}
        />
        <label htmlFor="pagePublic">Require sign in</label>
      </div>
      {!isPublic && (
        <Fragment>
          <div className="input-field flex align-items-center gap-3">
            <Checkbox
              inputId="pageTagRequired"
              checked={productItemRequired}
              onChange={(e) => {
                setProductItemRequired(!!e.checked);
                triggerHasUnsavedChanges();
              }}
            />
            <label htmlFor="pageTagRequired">Require a product item</label>
          </div>
          {productItemRequired && (
            <div className="input-field">
              <label htmlFor="pageProductItem">Product item</label>
              <Dropdown
                inputId="pageProductItem"
                className="w-full"
                filter
                value={productItem}
                loading={loadingTags}
                options={productItems}
                disabled={loadingTags}
                onChange={({ value }) => {
                  if (!savingEdits && isString(value)) {
                    setProductItem(value);
                    triggerHasUnsavedChanges();
                  }
                }}
              />
              <InputNotice>Only users with the selected feature tag will have access.</InputNotice>
            </div>
          )}
          <div className="input-field flex align-items-center gap-3">
            <Checkbox
              inputId="pageRoleRequired"
              checked={roleRequired}
              onChange={(e) => {
                setRoleRequired(!!e.checked);
                triggerHasUnsavedChanges();
              }}
            />
            <label htmlFor="pageRoleRequired">Require a role</label>
          </div>
          {roleRequired && (
            <div className="input-field">
              <label htmlFor="pageRoles">Roles with access</label>
              <MultiSelect
                inputId="pageRoles"
                className="w-full"
                value={roles}
                options={roleOptions}
                onChange={({ value }) => {
                  if (Array.isArray(value)) {
                    setRoles((value as Role[]).sort());
                    triggerHasUnsavedChanges();
                  }
                }}
              />
              <InputNotice>Only users with one of the selected roles will have access.</InputNotice>
            </div>
          )}
        </Fragment>
      )}
      <div className="input-field">
        <label htmlFor="pageFeaturedImageUrl">Featured Image URL</label>
        <InputText
          id="pageFeaturedImageUrl"
          className="w-full"
          value={featuredImageUrl || ''}
          onChange={(e) => {
            setFeaturedImageUrl(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageCaption">Caption</label>
        <InputTextarea
          id="pageCaption"
          className="w-full"
          value={caption || ''}
          onChange={(e) => {
            setCaption(e.target.value);
            triggerHasUnsavedChanges();
          }}
        />
      </div>
    </div>
  );
});
