import type { ApolloQueryResult } from '@apollo/client';
import { ComponentType, createContext, FunctionComponent, useContext } from 'react';
import type { FormContext } from '../../../../../../contexts/FormContext/form-context.js';
import type { FormTemplateQuery } from './queries.generated.js';

export type EditFormTemplateContext = {
  formTemplate: FormTemplateQuery['formTemplate'];
  formContext: FormContext;
  refetchFormTemplate: () => Promise<ApolloQueryResult<FormTemplateQuery>>;
};

const context = createContext<EditFormTemplateContext | null>(null);

export const EditFormTemplateContextProvider = context.Provider;

export const useEditFormTemplateContext = (): EditFormTemplateContext | null => useContext(context);

export const withEditFormTemplateContext =
  <P,>(Component: ComponentType<P & EditFormTemplateContext>): FunctionComponent<P> =>
  (props: P) => {
    const value = useEditFormTemplateContext();
    if (!value) {
      throw new Error('No EditFormTemplateContext value is available');
    }
    return <Component {...props} {...value} />;
  };
