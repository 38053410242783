import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, useRef } from 'react';
import { Link } from 'react-router-dom';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { NoneLabel } from '../../../NoneLabel/NoneLabel.js';
import type { UserRow } from './types.js';

type OrganizationCellProps = {
  org: UserRow['organization'];
};

export const OrganizationCell: FunctionComponent<OrganizationCellProps> = ({ org }) => {
  const { handle } = usePlatformContext();
  const primaryUser = useRef<HTMLDivElement>(null);

  return (
    <Link to={`/dashboard/${handle}/organizations/${org.id}`} className="text-color-body">
      <div>
        <div>{org.name || <span className="text-color-muted">(No name)</span>}</div>
        <div ref={primaryUser} className="w-max text-sm">
          <span className="mr-1">Primary user:</span>
          {org.primaryUser ? <span>{org.primaryUser.displayName}</span> : <NoneLabel />}
        </div>
      </div>
      <Tooltip target={primaryUser.current || undefined} position="top" showDelay={100}>
        {org.primaryUser ? (
          <Fragment>
            <div className="font-bold">Primary user:</div>
            <div>{org.primaryUser.displayName || ''}</div>
            <div>{org.primaryUser.email || ''}</div>
          </Fragment>
        ) : (
          <span>This organization does not have a primary user</span>
        )}
      </Tooltip>
    </Link>
  );
};
