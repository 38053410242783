import { useMutation, useQuery } from '@apollo/client';
import { formatDateTime } from '@wirechunk/lib/dates.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { invoiceFrequencyToHumanReadable } from '@wirechunk/lib/products.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { CreateSubscriptionPlan } from './CreateSubscriptionPlan/CreateSubscriptionPlan.js';
import { DeleteSubscriptionPlanDocument } from './mutations.generated.js';
import { ProductDetails } from './product-details/product-details.js';
import { ProductsTableDocument, ProductsTableQuery } from './queries.generated.js';

type TableProduct = ProductsTableQuery['products']['products'][number];

const rowExpansionTemplate = (sp: TableProduct) => (
  <div className="mt-1 mb-2">
    <h3 className="text-lg mt-0">{sp.name}</h3>
    <ProductDetails subscriptionPlan={sp} enableEdit />
  </div>
);

export const Products: FunctionComponent = () => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const {
    data,
    loading,
    refetch: refetchPlans,
  } = useQuery(ProductsTableDocument, {
    onError,
    variables: { platformId },
  });
  const [deleteSubscriptionPlan, { loading: isDeleting }] = useMutation(
    DeleteSubscriptionPlanDocument,
    {
      onError,
      onCompleted: () => {
        void refetchPlans();
        toastSuccess('Product deleted.');
      },
    },
  );
  const [menuPlan, setMenuPlan] = useState<TableProduct | null>(null);
  const menu = useRef<Menu>(null);
  const [showCreate, setShowCreate] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | any[]>({});

  return (
    <PageContainer title="Products">
      <ErrorMessage />
      {showCreate ? (
        <CreateSubscriptionPlan
          className="mt-1 mb-4"
          onCreated={() => {
            setShowCreate(false);
            void refetchPlans();
          }}
          onCancel={() => {
            setShowCreate(false);
          }}
        />
      ) : (
        <Button
          label="Create product"
          className="mb-3"
          onClick={() => {
            setShowCreate(true);
          }}
        />
      )}
      <DataTable
        dataKey="id"
        value={data?.products.products || []}
        loading={loading}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        lazy
      >
        <Column expander className="table-left-action-column" />
        <Column header="Name" field="name" />
        <Column
          header="Subscription end"
          body={(sp: TableProduct) =>
            sp.endAt
              ? formatDateTime(sp.endAt)
              : sp.durationDays
                ? `${sp.durationDays} ${pluralize(sp.durationDays, 'day')} after start`
                : 'Never'
          }
        />
        <Column header="Price" field="price" body={(sp: TableProduct) => `$${sp.price}`} />
        <Column
          header="Invoice frequency"
          field="invoiceFrequency"
          body={(sp: TableProduct) =>
            sp.invoiceFrequency ? invoiceFrequencyToHumanReadable(sp.invoiceFrequency) : 'N/A'
          }
        />
        <Column
          header=""
          align="right"
          body={(sp: TableProduct) => (
            <button
              className="button-not-styled p-1"
              onClick={(event) => {
                setMenuPlan(sp);
                menu.current?.show(event);
              }}
            >
              <i className="pi pi-ellipsis-v" />
            </button>
          )}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Copy ID',
            command: () => {
              if (menuPlan) {
                void navigator.clipboard.writeText(menuPlan.id);
                toastSuccess(`${menuPlan.name} product ID copied to clipboard.`);
              }
            },
          },
          {
            label: 'Delete',
            disabled: isDeleting,
            command: () => {
              if (menuPlan) {
                dialog({
                  confirm: `Are you sure you want to delete the “${menuPlan.name}” product? This cannot be undone.`,
                  props: {
                    onAccept: () => {
                      void deleteSubscriptionPlan({
                        variables: {
                          id: menuPlan.id,
                        },
                      });
                    },
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
