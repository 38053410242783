import { useMutation } from '@apollo/client';
import { FileUploadFeature } from '@wirechunk/lib/api.js';
import { useCallback, useEffect, useRef } from 'react';
import { fileUploadTimeoutMs } from '../../util/inputs.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import { usePollFileStatus } from '../usePollFileStatus/usePollFileStatus.js';
import { UploadFileDocument } from './mutations.generated.js';

type UploadFileDocumentVariables = {
  feature: typeof FileUploadFeature.Document;
  documentId: string;
};

type UploadFileOrgAgentPhotoUrlVariables = {
  feature: typeof FileUploadFeature.SiteAgentPhotoUrl;
  siteId: string;
};

type UploadFileOrgLogoUrlVariables = {
  feature: typeof FileUploadFeature.SiteLogoUrl;
  siteId: string;
};

type UploadFileStageStateVariables = {
  feature: typeof FileUploadFeature.StageState;
  stageId: string;
  stageStateProperty: string;
};

export type UploadVariables =
  | UploadFileDocumentVariables
  | UploadFileOrgAgentPhotoUrlVariables
  | UploadFileOrgLogoUrlVariables
  | UploadFileStageStateVariables;

type UploadFile = {
  // upload takes a File and the variables that will be passed to the mutation that gets the signed URL
  // to which to PUT the file, gets a signed URL, and uploads the file. While uploading or polling for
  // the file's status, calling the upload function will not do anything.
  upload: (
    file: File,
    uploadVariables: UploadVariables,
    // onUploaded is called after the file is uploaded, at which point the File's status may still be Uploading.
    onUploaded?: () => void,
    // onDonePollingUploaded is called after the file is uploaded and the polling loop is done, which could
    // be because the file was uploaded successfully or that the upload timed out or was canceled.
    onDonePollingUploaded?: () => void,
  ) => Promise<void>;
  isUploading: boolean;
  cancel: () => void;
};

export const useUploadFile = (onError: ErrorHandler['onError']): UploadFile => {
  const [getUploadUrl, { loading: isGettingUploadUrl }] = useMutation(UploadFileDocument, {
    onError,
  });
  const cancelController = useRef<AbortController | null>(null);
  const { startPollingFile, stopPollingFile, isPolling } = usePollFileStatus(
    onError,
    fileUploadTimeoutMs,
  );

  useEffect(() => {
    // On un-mount, cancel any pending fetches.
    return () => {
      const { current: cc } = cancelController;
      if (cc) {
        cc.abort();
      }
    };
  }, []);

  const isUploading = isGettingUploadUrl || isPolling;

  const upload = useCallback<UploadFile['upload']>(
    async (file, variables, onUploaded, onDonePollingUploaded) => {
      if (isUploading) {
        return;
      }
      try {
        const { data } = await getUploadUrl({
          variables: {
            ...variables,
            mimeType: file.type,
            fileName: file.name,
          },
        });
        if (data) {
          const fileId = data.uploadFile.id;
          startPollingFile(fileId, onDonePollingUploaded);

          const cc = new AbortController();
          cancelController.current = cc;
          const res = await fetch(data.uploadFile.signedUrl, {
            method: 'PUT',
            body: file,
            signal: cc.signal,
            headers: {
              'Content-Type': file.type,
            },
          });
          if (res.ok) {
            // In this case, the polling continues until our async job marks the File as Uploaded.
            onUploaded?.();
          } else {
            onError(`Could not upload the file ${file.name}. Please try again in a moment.`);
            stopPollingFile();
          }
        }
      } catch (e) {
        // TODO: Log the error.
        onError(`Could not upload the file ${file.name}. Please try again.`);
        stopPollingFile();
      }
    },
    [startPollingFile, getUploadUrl, onError, stopPollingFile, isUploading],
  );

  const cancel = useCallback(() => {
    const { current: cc } = cancelController;
    if (cc) {
      cc.abort();
    }
    stopPollingFile();
  }, [stopPollingFile]);

  return {
    upload,
    isUploading,
    cancel,
  };
};
