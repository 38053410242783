import { ApolloQueryResult, useQuery } from '@apollo/client';
import { PublishStatus } from '@wirechunk/lib/api.js';
import { useMemo } from 'react';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.js';
import { PagesDocument, PagesQuery } from './queries.generated.js';

export type Page = PagesQuery['site']['pages'][number];

export type PagesData = {
  pages: Page[];
  loading: boolean;
  refetchPages: () => Promise<ApolloQueryResult<PagesQuery>>;
};

export const usePagesData = (siteId: string, onError: ErrorHandler['onError']): PagesData => {
  const {
    data,
    loading,
    refetch: refetchPages,
  } = useQuery(PagesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { siteId },
  });

  return useMemo(
    () => ({
      pages: (data?.site.pages || []).filter((p) => p.status !== PublishStatus.Deleted),
      loading,
      refetchPages,
    }),
    [data?.site.pages, loading, refetchPages],
  );
};
