import type { Transform } from '@dnd-kit/utilities';
import { clsx } from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';
import styles from './sortable.module.css';

type SortableProps = PropsWithChildren<{
  className?: string;
  setNodeRef: (element: HTMLElement | null) => void;
  transform: Transform | null;
  transition: string | undefined;
  dragging: boolean;
  dragOverlay: boolean;
}>;

export const Sortable: FunctionComponent<SortableProps> = ({
  className,
  setNodeRef,
  transform,
  transition,
  dragging,
  dragOverlay,
  children,
}) => (
  <div
    ref={setNodeRef}
    style={{
      transition,
      transform: transform
        ? `translate(${Math.round(transform.x)}px, ${Math.round(transform.y)}px) scale(${
            transform.scaleX
          }, ${transform.scaleY})`
        : undefined,
    }}
    className={clsx(
      styles.item,
      dragging && 'opacity-60',
      dragOverlay && styles.dragOverlay,
      className,
    )}
  >
    {children}
  </div>
);
