import type { InputDataArray as InputDataArrayType } from '@wirechunk/schemas/expressions/expression';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { BuilderProps } from './shared.js';

export const InputDataArray: FunctionComponent<BuilderProps<InputDataArrayType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div className="input-field mb-0">
        <label htmlFor={`${id}-fieldKey`}>Array input component name</label>
        <InputText
          id={`${id}-fieldKey`}
          keyfilter="alphanum"
          value={expression.fieldKey || ''}
          onChange={(e) => {
            setExpression({
              ...expression,
              fieldKey: e.target.value,
            });
          }}
        />
      </div>
    </Fragment>
  );
};
