import { Role } from '@wirechunk/lib/api.js';

export const roleToHumanReadable = (role: Role): string => {
  switch (role) {
    case Role.OrganizationOwner:
      return 'Organization Owner';
    case Role.ReserveAgent:
      return 'Reserve Agent';
    case Role.Protege:
      return 'Protege';
    case Role.TeamMember:
      return 'Team Member';
  }
};

export const roleOptions: Array<{
  label: string;
  value: Role;
}> = Object.values(Role).map((r) => ({
  label: roleToHumanReadable(r),
  value: r,
}));
