import {
  allExpressionTypes,
  newExpressionForOperator,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { ArrayLiteral as ArrayLiteralType } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent } from 'react';
import { ExpressionBuilder } from '../ExpressionBuilder.js';
import { SelectExpressionButton } from '../SelectExpressionButton.js';
import type { BuilderProps } from './shared.js';

export const ArrayLiteral: FunctionComponent<BuilderProps<ArrayLiteralType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      {expression.value?.map((item, index) => (
        <div key={index}>
          <ExpressionBuilder
            id={`${id}-value-${index}`}
            expression={item}
            setExpression={(newItem) => {
              setExpression({
                ...expression,
                value: expression.value?.map((item, i) => (i === index ? newItem : item)) || null,
              });
            }}
            // TODO: Local validation.
            validationMessages={[]}
            types={allExpressionTypes}
            onDelete={() => {
              setExpression({
                ...expression,
                value: expression.value?.filter((_item, i) => i !== index) || null,
              });
            }}
          />
        </div>
      ))}
      <SelectExpressionButton
        types={allExpressionTypes}
        onAdd={(op) => {
          setExpression({
            ...expression,
            value: [...(expression.value || []), newExpressionForOperator(op)],
          });
        }}
      />
    </Fragment>
  );
};
