import { PrimeIcons } from 'primereact/api';
import { FunctionComponent } from 'react';
import { sitePageUrl } from '../../../../../util/site-page-url.js';

type SiteHeaderProps = {
  domain: string;
  name: string;
};

export const SiteHeader: FunctionComponent<SiteHeaderProps> = ({ domain, name }) => (
  <div className="py-2 px-3 xl:px-4 border-bottom-1 flex gap-2 align-items-center">
    Site:{' '}
    <a
      href={sitePageUrl(domain)}
      className="flex gap-2 align-items-center"
      target="_blank"
      rel="noreferrer"
    >
      <span className="font-bold">{name}</span> <i className={PrimeIcons.EXTERNAL_LINK} />
    </a>
  </div>
);
