import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { defaultDialogProps } from '../../../contexts/DialogContext/DialogContext.js';
import type { ViewMode } from '../../../contexts/PageContext/PageContext.js';
import type { SiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import type { ComponentEditorListener, EventHooks } from '../components/shared/types.js';
import styles from './EditComponentDialog.module.css';
import { Tab } from './tabs.js';

type EditComponentDialogProps = {
  component: Component;
  parent: Component | null;
  tabs: Tab[];
  siteContext: SiteContext;
  viewMode: ViewMode;
  setComponent: (component: Component) => void;
  onHide: () => void;
};

export const EditComponentDialog: FunctionComponent<EditComponentDialogProps> = ({
  component,
  parent,
  tabs,
  siteContext,
  viewMode,
  setComponent,
  onHide,
}) => {
  const [localComponent, setLocalComponent] = useState<Component>(component);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const beforeLeaveListeners = useRef<ComponentEditorListener[]>([]);

  const eventHooks = useMemo<EventHooks>(
    () => ({
      onBeforeLeave: (listener: ComponentEditorListener) => {
        beforeLeaveListeners.current.push(listener);
      },
      offBeforeLeave: (listener: ComponentEditorListener) => {
        beforeLeaveListeners.current = beforeLeaveListeners.current.filter((l) => l !== listener);
      },
    }),
    [],
  );

  const [initialTab] = tabs;
  const [currentTabLabel, setCurrentTabLabel] = useState(initialTab?.label ?? null);

  const CurrentTab = currentTabLabel
    ? tabs.find((tab) => tab.label === currentTabLabel)?.component
    : null;

  return (
    <Dialog
      {...defaultDialogProps}
      className={`dialog-width-xl border-none ${styles.editComponentDialog}`}
      contentClassName="flex align-items-stretch p-0"
      header={`Edit ${componentTypeHumanReadable(localComponent.type)}`}
      closable={false}
      onHide={onHide}
      footer={
        <div className="flex gap-3 justify-content-end">
          <Button
            label="Cancel"
            className="p-button-danger"
            icon={PrimeIcons.TIMES}
            onClick={onHide}
          />
          <Button
            label="Save"
            className="p-button-success"
            icon={PrimeIcons.CHECK}
            onClick={() => {
              let finalComponent = localComponent;
              for (const listener of beforeLeaveListeners.current) {
                const result = listener(finalComponent);
                switch (result.action) {
                  case 'continue':
                    if (result.component) {
                      finalComponent = result.component;
                    }
                    break;
                  case 'prevent':
                    setValidationMessage(result.errorMessage);
                    return;
                }
              }
              setComponent(finalComponent);
              onHide();
            }}
          />
        </div>
      }
    >
      {tabs.length > 1 && (
        <div className={`${styles.tabs} flex flex-column surface-ground gap-1 border-right-1`}>
          {tabs.map((tab) => (
            <button
              key={tab.label}
              className={clsx(
                'button-not-styled py-2 px-3 font-medium text-left',
                tab.label === currentTabLabel && styles.activeTabButton,
              )}
              type="button"
              onClick={() => {
                for (const listener of beforeLeaveListeners.current) {
                  const result = listener(component);
                  switch (result.action) {
                    case 'continue':
                      if (result.component) {
                        setLocalComponent(result.component);
                      }
                      break;
                    case 'prevent':
                      setValidationMessage(result.errorMessage);
                      return;
                  }
                }
                setCurrentTabLabel(tab.label);
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
      )}
      <div className="max-h-full w-full align-items-stretch overflow-y-auto p-3">
        {CurrentTab && (
          <CurrentTab
            component={localComponent}
            setComponent={setLocalComponent}
            parent={parent}
            siteContext={siteContext}
            viewMode={viewMode}
            eventHooks={eventHooks}
          />
        )}
        {validationMessage && (
          <Message className="mt-3" severity="error" content={validationMessage} />
        )}
      </div>
    </Dialog>
  );
};
