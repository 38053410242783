import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbsItem = {
  label: string;
  // If path is not defined, the label is rendered as text, not a link.
  path?: string;
};

type BreadcrumbsProps = {
  className?: string;
  // The items to display. Each must have a unique label.
  items: BreadcrumbsItem[];
};

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ className, items }) => (
  <div className={clsx('flex align-items-center gap-2', className)}>
    {items.map(({ label, path }, index) => (
      <Fragment key={label}>
        {index > 0 && <i className={PrimeIcons.ANGLE_RIGHT} />}
        {path ? (
          <Link className="text-color hover:text-primary font-medium" to={path}>
            {label}
          </Link>
        ) : (
          <span className="font-medium">{label}</span>
        )}
      </Fragment>
    ))}
  </div>
);
