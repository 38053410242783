import { useMutation } from '@apollo/client';
import { User } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fragment, FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { InputNotice } from '../InputNotice/InputNotice.js';
import type { OrganizationQuery } from '../OrganizationDetails/queries.generated.js';
import { EditOrgPrimaryUserDocument } from './mutations.generated.js';

type QueryOrg = OrganizationQuery['organization'];

type EditOrgPrimaryUserProps = {
  org: QueryOrg;
  user: Pick<User, 'id' | 'displayName'>;
  onCompleted?: () => void;
  onCancel?: () => void;
};

export const EditOrgPrimaryUser: FunctionComponent<EditOrgPrimaryUserProps> = ({
  org,
  user,
  onCompleted,
  onCancel,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [editOrgPrimaryUser, { loading }] = useMutation(EditOrgPrimaryUserDocument, {
    onError,
    onCompleted: (data) => {
      if (data.editOrgPrimaryUser.__typename === 'EditOrgPrimaryUserSuccessResult') {
        onCompleted?.();
      } else {
        onError(data.editOrgPrimaryUser.message);
      }
    },
  });
  const [notes, setNotes] = useState('');

  return (
    <Fragment>
      <ErrorMessage />
      <p>
        You are setting <span className="font-medium">{user.displayName}</span> as the primary user
        in this org. Please confirm.
      </p>
      <div className="input-field">
        <label htmlFor="moveUserToOrgNotes">Notes (optional)</label>
        <InputTextarea
          id="moveUserToOrgNotes"
          className="block w-full max-w-full"
          rows={5}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
        <InputNotice>Explain the context of this change.</InputNotice>
      </div>
      <div className="flex justify-content-end gap-3 mt-4">
        <Button label="Cancel" className="p-button-secondary" onClick={onCancel} />
        <Button
          label="Confirm"
          className="p-button-danger"
          disabled={loading}
          onClick={() => {
            void editOrgPrimaryUser({
              variables: {
                orgId: org.id,
                userId: user.id,
                notes,
              },
            });
          }}
        />
      </div>
    </Fragment>
  );
};
