import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { parseErrorMessage } from '@wirechunk/lib/errors.js';
import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import { defaultFormattedDataTemplate } from '@wirechunk/lib/mixer/form-formatting-templates.js';
import Handlebars from 'handlebars';
import { isBoolean, isNil, last } from 'lodash-es';
import { Button } from 'primereact/button';
import { useDebounce } from 'primereact/hooks';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import {
  FormEventHandler,
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormContext,
  FormContextProvider,
} from '../../../../../../../contexts/FormContext/form-context.js';
import {
  InputDataContextProvider,
  useInputDataContextValue,
} from '../../../../../../../contexts/InputDataContext.js';
import { useToast } from '../../../../../../../contexts/ToastContext.js';
import { useCollectAllFormComponents } from '../../../../../../../hooks/use-collect-all-form-components.js';
import { useValidInputComponents } from '../../../../../../../hooks/use-valid-input-components.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../hooks/useHasUnsavedChanges.js';
import { ParseAndRenderComponents } from '../../../../../../ParseAndRenderComponents.js';
import { Spinner } from '../../../../../../Spinner.js';
import { withEditFormTemplateContext } from '../edit-form-template-context.js';
import { EditFormTemplateFormattedDataTemplateDocument } from './mutations.generated.js';
import { FormTemplateDataFormattingConfigDocument } from './queries.generated.js';

export const DataFormatting: FunctionComponent = withEditFormTemplateContext(
  ({ formTemplate, formContext }) => {
    const { toastSuccess } = useToast();
    const { onError, clearMessages, ErrorMessage } = useErrorHandler();
    const apolloClient = useApolloClient();

    const { data, loading } = useQuery(FormTemplateDataFormattingConfigDocument, {
      fetchPolicy: 'cache-and-network',
      onError,
      variables: { id: formTemplate.id },
    });
    const [editFormTemplate, { loading: saving }] = useMutation(
      EditFormTemplateFormattedDataTemplateDocument,
      {
        onError,
        onCompleted: (data) => {
          if (data.editFormTemplateFormattedDataTemplate.__typename === 'GenericUserError') {
            onError(data.editFormTemplateFormattedDataTemplate.message);
          } else {
            toastSuccess('Form template saved.');
            resetHasUnsavedChanges();
          }
        },
      },
    );
    const components = useCollectAllFormComponents(formTemplate);
    const { inputComponents: allInputComponents, loading: loadingInputComponents } =
      useValidInputComponents(components, apolloClient);
    const allInputComponentsSorted = useMemo(
      () => Array.from(allInputComponents.values()).sort((a, b) => a.name.localeCompare(b.name)),
      [allInputComponents],
    );

    const [formattedDataTemplate, setFormattedDataTemplate] = useState<string | null>(
      data?.formTemplate.formattedDataTemplate || null,
    );
    const [useFormattedDataTemplate, setUseFormattedDataTemplate] = useState<boolean | undefined>(
      data?.formTemplate.useFormattedDataTemplate,
    );

    // We need to manually keep the debounced string in sync.
    const [, debouncedFormattedDataTemplate, setDebouncedFormattedDataTemplate] = useDebounce(
      formattedDataTemplate,
      700,
    );

    useEffect(() => {
      setFormattedDataTemplate(data?.formTemplate.formattedDataTemplate || null);
      setDebouncedFormattedDataTemplate(data?.formTemplate.formattedDataTemplate || null);
    }, [data?.formTemplate.formattedDataTemplate, setDebouncedFormattedDataTemplate]);

    useEffect(() => {
      setUseFormattedDataTemplate(data?.formTemplate.useFormattedDataTemplate);
    }, [data?.formTemplate.useFormattedDataTemplate]);

    const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
      useHasUnsavedChanges();

    const [currentStep, setCurrentStep] = useState(formTemplate.steps[0] || null);
    const previewFormContext = useMemo<FormContext>(
      () => ({
        ...formContext,
        currentStep,
      }),
      [formContext, currentStep],
    );
    const submitPreviewForm = useCallback<FormEventHandler>(
      (evt) => {
        evt.preventDefault();
        if (
          currentStep &&
          formTemplate.steps.length &&
          last(formTemplate.steps)?.id !== currentStep.id
        ) {
          setCurrentStep(
            (currentStep) =>
              formTemplate.steps[
                formTemplate.steps.findIndex(({ id }) => currentStep && id === currentStep.id) + 1
              ] || null,
          );
        }
      },
      [currentStep, formTemplate.steps],
    );
    const previewInputDataContextValue = useInputDataContextValue();

    const {
      data: { visible: previewData },
    } = previewInputDataContextValue;

    const defaultFormattedData = useMemo(() => {
      try {
        return Handlebars.compile(defaultFormattedDataTemplate)(previewData);
      } catch {
        // TODO
        return '';
      }
    }, [previewData]);

    const { formattedText, handlebarsError } = useMemo<{
      formattedText?: string;
      handlebarsError?: string;
    }>(() => {
      if (debouncedFormattedDataTemplate === null) {
        return {};
      }
      try {
        return {
          formattedText: Handlebars.compile(debouncedFormattedDataTemplate)(previewData),
        };
      } catch (e) {
        return {
          handlebarsError: parseErrorMessage(e),
        };
      }
    }, [debouncedFormattedDataTemplate, previewData]);

    return (
      <div className="mt-3">
        <ErrorMessage />
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Button
              className="p-button-success w-max"
              label="Save"
              disabled={!hasUnsavedChanges || saving || loadingInputComponents}
              onClick={() => {
                clearMessages();
                if (isNil(useFormattedDataTemplate)) {
                  // The form has not loaded yet.
                  return;
                }
                void editFormTemplate({
                  variables: {
                    input: {
                      formTemplateId: formTemplate.id,
                      formattedDataTemplate: {
                        value: formattedDataTemplate,
                      },
                      useFormattedDataTemplate,
                    },
                  },
                });
              }}
            />
            <div className="mt-3 flex gap-3">
              <div className="input-field flex gap-2 align-items-center mb-0">
                <RadioButton
                  inputId="formTemplateUseFormattedDataTemplateFalse"
                  value={false}
                  checked={useFormattedDataTemplate === false}
                  onChange={({ value }) => {
                    if (isBoolean(value)) {
                      setUseFormattedDataTemplate(value);
                      triggerHasUnsavedChanges();
                    }
                  }}
                />
                <label htmlFor="formTemplateUseFormattedDataTemplateFalse" className="mb-0">
                  Use default template
                </label>
              </div>
              <div className="input-field flex gap-2 align-items-center mb-0">
                <RadioButton
                  inputId="formTemplateUseFormattedDataTemplateTrue"
                  value
                  checked={useFormattedDataTemplate === true}
                  onChange={({ value }) => {
                    if (isBoolean(value)) {
                      setUseFormattedDataTemplate(value);
                      triggerHasUnsavedChanges();
                    }
                  }}
                />
                <label htmlFor="formTemplateUseFormattedDataTemplateTrue" className="mb-0">
                  Use custom template
                </label>
              </div>
            </div>
            <div className="flex gap-3 mt-3 justify-content-between">
              <div
                style={{
                  width: 'calc(50% - 12px)',
                }}
              >
                <label
                  htmlFor="formTemplateFormattedDataTemplate"
                  className="block mb-1 font-medium"
                >
                  Formatted data template
                </label>
                <InputTextarea
                  id="formTemplateFormattedDataTemplate"
                  className="w-full"
                  rows={5}
                  autoResize
                  disabled={!useFormattedDataTemplate}
                  value={
                    useFormattedDataTemplate
                      ? formattedDataTemplate || ''
                      : defaultFormattedDataTemplate
                  }
                  onChange={(e) => {
                    const { value } = e.target;
                    setFormattedDataTemplate(value);
                    setDebouncedFormattedDataTemplate(value);
                    triggerHasUnsavedChanges();
                  }}
                />
                {handlebarsError && (
                  <small className="text-color-danger block mt-1 font-medium">
                    {handlebarsError}
                  </small>
                )}
                <div className="mt-1 flex gap-2 flex-wrap">
                  {allInputComponentsSorted.map(({ type, name }) => (
                    <Button
                      key={name}
                      className="border-none p-button-secondary p-button-xs min-w-max w-max"
                      tooltip="Copy"
                      tooltipOptions={{
                        showDelay: 300,
                      }}
                      onClick={() => {
                        void window.navigator.clipboard.writeText(`{{${name}}}`);
                      }}
                    >
                      <span className="font-bold pr-2">{name} </span>
                      <span className="font-normal">{componentTypeHumanReadable(type)}</span>
                    </Button>
                  ))}
                </div>
              </div>
              <div
                style={{
                  width: 'calc(50% - 12px)',
                }}
              >
                <div className="mb-1 font-medium">Preview data</div>
                <form onSubmit={submitPreviewForm}>
                  <FormContextProvider value={previewFormContext}>
                    <InputDataContextProvider value={previewInputDataContextValue}>
                      <ParseAndRenderComponents componentsJSON={formTemplate.components} />
                    </InputDataContextProvider>
                  </FormContextProvider>
                </form>
              </div>
            </div>
            <div className="font-medium mt-2 mb-1">Output preview</div>
            {useFormattedDataTemplate ? (
              handlebarsError ? null : formattedDataTemplate ? (
                <div className="border-1 border-round p-2 wrap-overflow-text">{formattedText}</div>
              ) : (
                <div className="text-color-muted">No template</div>
              )
            ) : (
              <div className="border-1 border-round p-2 wrap-overflow-text">
                {defaultFormattedData}
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  },
);
