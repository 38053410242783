import type { ApolloQueryResult } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { dateIsInThePast, formatDateTime } from '@wirechunk/lib/dates.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fragment, FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { useTableRowExpansion } from '../../hooks/useTableRowExpansion.js';
import { subscriptionStatusToHumanReadable } from '../../util/subscriptions.js';
import { CreateSubscription } from './CreateSubscription/CreateSubscription.js';
import styles from './organization-details.module.css';
import type { OrganizationQuery } from './queries.generated.js';
import { SubscriptionOrganization } from './SubscriptionOrganization/SubscriptionOrganization.js';

type SubscriptionOrgRow = OrganizationQuery['organization']['subscriptionOrganizations'][number];

type SubscriptionsProps = {
  organization: OrganizationQuery['organization'];
  refetchOrganization: () => Promise<ApolloQueryResult<OrganizationQuery>>;
};

export const Subscriptions: FunctionComponent<SubscriptionsProps> = ({
  organization,
  refetchOrganization,
}) => {
  const { permissions } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const [showAddSubscriptionPlan, setShowAddSubscriptionPlan] = useState(false);
  const { expandedRows, onRowToggle } = useTableRowExpansion();

  const userCanEditOrgSubscription = permissions.includes(Permission.EditSubscription);

  return (
    <Fragment>
      <div className="flex gap-4 align-items-end pb-1">
        <h3 className="text-lg mb-2">Subscriptions</h3>
        {userCanEditOrgSubscription && !showAddSubscriptionPlan && (
          <Button
            className="p-button-sm"
            label="Add subscription"
            onClick={() => {
              setShowAddSubscriptionPlan(true);
            }}
          />
        )}
      </div>
      <ErrorMessage />
      {showAddSubscriptionPlan && (
        <CreateSubscription
          className="mb-3"
          organization={organization}
          onError={onError}
          onAdded={() => {
            setShowAddSubscriptionPlan(false);
          }}
          onCancel={() => {
            setShowAddSubscriptionPlan(false);
          }}
        />
      )}
      <DataTable
        dataKey="id"
        className={styles.innerTable}
        emptyMessage="No subscriptions found"
        value={organization.subscriptionOrganizations}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        rowExpansionTemplate={(subOrg: SubscriptionOrgRow) => (
          <SubscriptionOrganization
            organization={organization}
            subscriptionOrganization={subOrg}
            refetchOrganization={refetchOrganization}
          />
        )}
      >
        <Column expander className="table-left-action-column" />
        <Column
          header="Plan name"
          body={(subOrg: SubscriptionOrgRow) => (
            <div>
              {subOrg.subscription.plan.name}
              {subOrg.isSubscriptionManager && (
                <span className="ml-3 py-1 px-2 border-round border-1 text-sm">
                  Subscription manager
                </span>
              )}
            </div>
          )}
        />
        <Column
          header="Status"
          body={(subOrg: SubscriptionOrgRow) =>
            subscriptionStatusToHumanReadable(subOrg.subscription.status)
          }
        />
        <Column
          header="Start date"
          body={(subOrg: SubscriptionOrgRow) => formatDateTime(subOrg.subscription.startAt)}
        />
        <Column
          header="End date"
          body={(subOrg: SubscriptionOrgRow) => (
            <div>
              {subOrg.subscription.endAt ? (
                <Fragment>
                  {formatDateTime(subOrg.subscription.endAt)}
                  {dateIsInThePast(new Date(subOrg.subscription.endAt)) && (
                    <span className="ml-3 py-1 px-2 border-round border-1 text-sm">Ended</span>
                  )}
                </Fragment>
              ) : (
                'Never'
              )}
            </div>
          )}
        />
      </DataTable>
    </Fragment>
  );
};
