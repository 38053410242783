import { useMutation } from '@apollo/client';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormContextProvider } from '../../../../../../../contexts/FormContext/form-context.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../contexts/PageContext/PageContext.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { useSiteContextSelector } from '../../../../../../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { VisualBuilder } from '../../../../../../VisualBuilder/VisualBuilder.js';
import { withEditFormTemplateContext } from '../edit-form-template-context.js';
import { EditFormTemplateStepDocument } from './mutations.generated.js';

const pageContext: PageContext = {
  title: '',
  viewMode: ViewMode.Preview,
};

type GuardedStepOwnProps = {
  formTemplateStepId: string;
};

const GuardedStep = withEditFormTemplateContext<GuardedStepOwnProps>(
  ({ formTemplateStepId, formTemplate, formContext }) => {
    const step = formTemplate.steps.find((step) => step.id === formTemplateStepId);
    const [name, setName] = useState<string>('');
    const [components, setComponents] = useState<Component[]>([]);
    const { onError, ErrorMessage } = useErrorHandler();
    const [editFormTemplateStep, { loading: saving }] = useMutation(EditFormTemplateStepDocument, {
      onError,
    });

    useEffect(() => {
      if (step) {
        setName(step.name);
        setComponents(parseComponents(step.components));
      }
    }, [step]);

    const siteContextSelector = useSiteContextSelector({ onError });

    if (!step) {
      return <Fragment>Step not found.</Fragment>;
    }

    return (
      <div className="mt-2">
        <ErrorMessage />
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-start">
          <div className="w-30rem">
            <div className="md:mb-3">Editing step: {step.name}</div>
            <div className="input-field">
              <label htmlFor="stepName">Name</label>
              <InputText
                id="stepName"
                className="w-full"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <Button
            className="w-max"
            label="Save"
            disabled={saving}
            onClick={() => {
              void editFormTemplateStep({
                variables: {
                  id: formTemplateStepId,
                  name,
                  position: step.position,
                  components: JSON.stringify(components),
                  enabled: step.enabled,
                },
              });
            }}
          />
        </div>
        <VisualBuilder
          siteContext={siteContextSelector}
          components={components}
          setComponents={setComponents}
          onPreview={(children) => (
            <PageContextProvider value={pageContext}>
              <FormContextProvider value={formContext}>{children}</FormContextProvider>
            </PageContextProvider>
          )}
        />
      </div>
    );
  },
);

export const Step: FunctionComponent = () => {
  const { formTemplateStepId } = useParams<{ formTemplateStepId: string }>();

  return formTemplateStepId ? (
    <GuardedStep formTemplateStepId={formTemplateStepId} />
  ) : (
    <p>Invalid page: missing formTemplateStepId parameter.</p>
  );
};
