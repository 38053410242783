import {
  DataSource,
  PlainTextComponent,
  PlainTextSource,
} from '@wirechunk/lib/mixer/types/components.js';
import { isDataSource } from '@wirechunk/lib/mixer/utils.js';
import type { RemapPropertiesToUnknown } from '@wirechunk/lib/util-types.js';
import { isPlainObject, isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fragment, FunctionComponent } from 'react';
import { SelectItem } from '../../../types.js';
import { dataSourceOptions } from '../../../util/mixer/util.js';
import { isTag, tagOptions } from '../tag-options.js';
import type { EditComponentContentProps } from './shared/types.js';

const plainTextDataSourceOptions: Array<SelectItem<PlainTextSource['type']>> = [
  ...dataSourceOptions,
  { label: 'Page title', value: 'PageTitle' },
];

// This omits the legacy direct text property.
const isPlainTextSource = (value: unknown): value is PlainTextSource =>
  isPlainObject(value) &&
  (isDataSource((value as RemapPropertiesToUnknown<PlainTextSource>).type) ||
    (value as RemapPropertiesToUnknown<PlainTextSource>).type ===
      ('PageTitle' satisfies PlainTextSource['type']));

const isPlainTextSourceType = (type: unknown): type is PlainTextSource['type'] =>
  isDataSource(type) || type === 'PageTitle';

export const EditPlainText: FunctionComponent<EditComponentContentProps<PlainTextComponent>> = ({
  component,
  setComponent,
}) => {
  const { text } = component;

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editPlainTextSource">Text source</label>
        <Dropdown
          inputId="editPlainTextSource"
          className="w-full"
          options={plainTextDataSourceOptions}
          value={isPlainTextSource(text) ? text.type : DataSource.Direct}
          onChange={({ value }) => {
            if (isPlainTextSourceType(value)) {
              setComponent(
                (c): PlainTextComponent => ({
                  ...c,
                  text:
                    value === DataSource.Direct
                      ? { type: value, text: '' }
                      : { type: value, name: '' },
                }),
              );
            }
          }}
        />
      </div>
      {!text || isString(text) || (isPlainTextSource(text) && text.type === DataSource.Direct) ? (
        <div className="input-field">
          <label className="editPlainTextText">Text</label>
          <InputTextarea
            id="editPlainTextText"
            className="w-full"
            value={isString(text) ? text : text?.text || ''}
            onChange={(e) => {
              setComponent(
                (c): PlainTextComponent => ({
                  ...c,
                  text: {
                    type: DataSource.Direct,
                    text: e.target.value,
                  } satisfies PlainTextSource,
                }),
              );
            }}
          />
        </div>
      ) : (
        isPlainTextSource(text) &&
        text.type === DataSource.Prop && (
          <div className="input-field">
            <label htmlFor="editPlainTextPropName">Prop name</label>
            <InputText
              id="editPlainTextPropName"
              className="w-full"
              value={text.name}
              onChange={(e) => {
                const name = e.target.value.trim();
                setComponent((c): PlainTextComponent => {
                  if (isPlainTextSource(c.text)) {
                    return {
                      ...c,
                      // Keep the direct text value around in case a user toggles the different source types.
                      text: {
                        ...c.text,
                        type: DataSource.Prop,
                        name,
                      } satisfies PlainTextSource,
                    };
                  }
                  return {
                    ...c,
                    text: { type: DataSource.Prop, name } satisfies PlainTextSource,
                  };
                });
              }}
            />
          </div>
        )
      )}
      <div className="input-field">
        <label htmlFor="editPlainTextTag">HTML element (tag)</label>
        <Dropdown
          inputId="editPlainTextTag"
          className="w-10rem"
          value={component.tag || ''}
          options={tagOptions}
          placeholder="span"
          onChange={({ value }) => {
            if (isTag(value)) {
              setComponent((c): PlainTextComponent => ({ ...c, tag: value }));
            }
          }}
        />
      </div>
    </Fragment>
  );
};
