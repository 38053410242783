import { PublishStatus } from '@wirechunk/lib/api.js';
import { DocumentComponent } from '@wirechunk/lib/mixer/types/components.js';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../contexts/admin/platform-context/platform-context.js';
import { useDocuments } from '../../../hooks/use-documents/use-documents.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { Spinner } from '../../Spinner.js';
import { EditComponentContentProps } from './shared/types.js';

export const EditDocument: FunctionComponent<EditComponentContentProps<DocumentComponent>> = ({
  component,
  setComponent,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { documents, loading } = useDocuments(platformId, onError);

  const filteredDocuments = documents?.filter(
    (d) => d.status === PublishStatus.Published && d.currentVersion,
  );

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="editDocumentDocumentId">Document</label>
        {loading ? (
          <Spinner />
        ) : (
          <Dropdown
            inputId="editDocumentDocumentId"
            className="w-full"
            options={filteredDocuments}
            optionLabel="name"
            optionValue="id"
            value={component.documentId || component.resourceId || ''}
            onChange={({ value }) => {
              if (isString(value)) {
                setComponent((c) => ({ ...c, documentId: value }));
              }
            }}
          />
        )}
      </div>
    </Fragment>
  );
};
