import { clsx } from 'clsx';
import type { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';
import styles from './basic-icon-button.module.css';

// Pass in either icon or children.
type BasicIconButtonProps = PropsWithChildren<{
  icon?: string;
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}>;

export const BasicIconButton: FunctionComponent<BasicIconButtonProps> = ({
  icon,
  className,
  onClick,
  disabled,
  children,
}) => (
  <button
    type="button"
    className={clsx('button-not-styled flex align-items-center', styles.basicIconButton, className)}
    onClick={onClick}
    disabled={disabled || false}
  >
    {icon ? <i className={icon} /> : children}
  </button>
);
