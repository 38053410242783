import { useMutation } from '@apollo/client';
import { Organization, User } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fragment, FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { InputNotice } from '../InputNotice/InputNotice.js';
import { OrganizationSelector } from '../OrganizationSelector/OrganizationSelector.js';
import { MoveUserToOrgDocument } from './mutations.generated.js';

type MoveUserToOrgProps = {
  platformId: string;
  user: Pick<User, 'id' | 'displayName'> & {
    organization: Pick<Organization, 'id'>;
  };
  onCompleted?: () => void;
  onCancel?: () => void;
};

export const MoveUserToOrg: FunctionComponent<MoveUserToOrgProps> = ({
  platformId,
  user,
  onCompleted,
  onCancel,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [moveUser, { loading }] = useMutation(MoveUserToOrgDocument, {
    onError,
    onCompleted: (data) => {
      if (data.moveUserToOrg.__typename === 'MoveUserToOrgSuccessResult') {
        onCompleted?.();
      } else {
        onError(data.moveUserToOrg.message);
      }
    },
  });
  const [orgId, setOrgId] = useState<string | null>(null);
  const [notes, setNotes] = useState('');
  const excludeOrganizationIds = new Set([user.organization.id]);

  return (
    <Fragment>
      <ErrorMessage />
      <p>
        You are moving <span className="font-medium">{user.displayName}</span> to a new org.
      </p>
      <div className="input-field">
        <label htmlFor="moveUserToOrgOrgId">Select the new org</label>
        <OrganizationSelector
          platformId={platformId}
          inputId="moveUserToOrgOrgId"
          className="w-full"
          organizationId={orgId}
          setOrganizationId={setOrgId}
          excludeOrganizationIds={excludeOrganizationIds}
          onError={onError}
        />
      </div>
      <div className="input-field">
        <label htmlFor="moveUserToOrgNotes">Notes (optional)</label>
        <InputTextarea
          id="moveUserToOrgNotes"
          className="block w-full max-w-full"
          rows={5}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
        <InputNotice>Explain the context of this change.</InputNotice>
      </div>
      <div className="flex justify-content-end gap-3 mt-4">
        <Button label="Cancel" className="p-button-secondary" onClick={onCancel} />
        <Button
          label="Confirm"
          className="p-button-danger"
          disabled={loading || !orgId}
          onClick={() => {
            if (orgId) {
              void moveUser({
                variables: {
                  userId: user.id,
                  orgId,
                  notes,
                },
              });
            }
          }}
        />
      </div>
    </Fragment>
  );
};
