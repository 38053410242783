import { ApolloQueryResult, useQuery } from '@apollo/client';
import type { ErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import {
  DocumentDownloadPromptsDocument,
  DocumentDownloadPromptsQuery,
} from './queries.generated.js';

type DocumentDownloadPrompts = {
  prompts: DocumentDownloadPromptsQuery['documentDownloadPrompts'] | null;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<DocumentDownloadPromptsQuery>>;
};

export const useDocumentDownloadPrompts = (
  platformId: string,
  onError: ErrorHandler['onError'],
): DocumentDownloadPrompts => {
  const { data, loading, refetch } = useQuery(DocumentDownloadPromptsDocument, {
    onError,
    variables: { platformId },
  });

  return {
    prompts: data?.documentDownloadPrompts || null,
    loading,
    refetch,
  };
};
