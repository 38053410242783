import { useMutation, useQuery } from '@apollo/client';
import { FileUploadFeature, PublishStatus, Role } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { isString, noop } from 'lodash-es';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useProductItemPicklist } from '../../../../../../hooks/use-product-item-picklist/use-product-item-picklist.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { publishStatusOptions } from '../../../../../../util/publishStatus.js';
import { roleOptions } from '../../../../../../util/roles.js';
import { FileUpload } from '../../../../../FileUpload/FileUpload.js';
import { InputNotice } from '../../../../../InputNotice/InputNotice.js';
import { useDocumentDownloadPromptDialog } from '../../../../../mixer/document/use-document-download-prompt-dialog.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { useDocumentDownloadPrompts } from '../download-prompts/use-document-download-prompts/use-document-download-prompts.js';
import { DocumentVersion } from './DocumentVersion.js';
import { EditDocumentDocument } from './mutations.generated.js';
import { DocumentDocument } from './queries.generated.js';

export const DocumentBody: FunctionComponent<{ documentId: string }> = ({ documentId }) => {
  const { id: platformId } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading, refetch } = useQuery(DocumentDocument, {
    onError,
    variables: { id: documentId },
  });
  const { prompts, loading: loadingDownloadPrompts } = useDocumentDownloadPrompts(
    platformId,
    onError,
  );
  const { productItems, loading: loadingProductItems } = useProductItemPicklist(
    platformId,
    onError,
  );
  const [editDocument, { loading: isSavingEdits }] = useMutation(EditDocumentDocument, { onError });

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const doc = data?.document;

  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<PublishStatus>(PublishStatus.Draft);
  const [featureTag, setFeatureTag] = useState<string | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [stamp, setStamp] = useState<boolean>(false);
  const [downloadPromptId, setDownloadPromptId] = useState<string | null>(null);

  const [featureTagRequired, setFeatureTagRequired] = useState(false);
  const [roleRequired, setRoleRequired] = useState(false);

  const [showAddNewVersion, setShowAddNewVersion] = useState(false);

  useEffect(() => {
    if (doc) {
      setName(doc.name);
      setStatus(doc.status);
      setFeatureTag(doc.featureTag || null);
      setRoles(doc.roles);
      setStamp(doc.stamp || false);
      setDownloadPromptId(doc.downloadPrompt?.id || null);

      setFeatureTagRequired(!!doc.featureTag);
      setRoleRequired(!!doc.roles.length);
    }
  }, [doc]);

  const showPrompt = useDocumentDownloadPromptDialog(noop);

  return (
    <PageContainer title="Document">
      <ErrorMessage />
      <div className="flex gap-3">
        <Button
          label="Save"
          className="mb-2"
          disabled={!doc || loading || !hasUnsavedChanges || isSavingEdits}
          onClick={() => {
            clearMessages();
            if (doc) {
              if (featureTagRequired && !featureTag) {
                onError('You must select a product item if a product item is required.');
                return;
              }
              if (roleRequired && !roles.length) {
                onError('You must select at least one role if a role is required.');
                return;
              }

              void editDocument({
                variables: {
                  id: doc.id,
                  name,
                  status,
                  featureTag: featureTagRequired ? featureTag : null,
                  roles: roleRequired ? roles : [],
                  stamp,
                  downloadPromptId,
                },
                onCompleted: () => {
                  setHasUnsavedChanges(false);
                },
              });
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="documentName">Name</label>
        <InputText
          id="documentName"
          className="w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setHasUnsavedChanges(true);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="documentStatus">Status</label>
        <Dropdown
          inputId="documentStatus"
          className="w-full"
          options={publishStatusOptions}
          value={status}
          onChange={(e) => {
            setStatus(e.value as PublishStatus);
            setHasUnsavedChanges(true);
          }}
        />
      </div>
      <div className="input-field flex align-items-center gap-3 mb-1">
        <Checkbox
          inputId="documentFeatureTagRequired"
          checked={featureTagRequired}
          onChange={(e) => {
            setFeatureTagRequired(!!e.checked);
            setHasUnsavedChanges(true);
          }}
        />
        <label htmlFor="documentFeatureTagRequired">Require a product item</label>
      </div>
      {featureTagRequired && (
        <div className="input-field">
          <label htmlFor="featureTag">Product item</label>
          <Dropdown
            inputId="featureTag"
            className="w-20rem"
            value={featureTag}
            options={productItems}
            disabled={loadingProductItems}
            onChange={({ value }) => {
              if (!isSavingEdits && isString(value)) {
                setFeatureTag(value);
                setHasUnsavedChanges(true);
              }
            }}
            filter
          />
          <InputNotice>Only users with the selected product item will have access.</InputNotice>
        </div>
      )}
      <div className="input-field flex align-items-center gap-3 mb-1">
        <Checkbox
          inputId="documentRoleRequired"
          checked={roleRequired}
          onChange={(e) => {
            setRoleRequired(!!e.checked);
            setHasUnsavedChanges(true);
          }}
        />
        <label htmlFor="documentRoleRequired">Require a role</label>
      </div>
      {roleRequired && (
        <div className="input-field">
          <label htmlFor="documentRoles">Roles with access</label>
          <MultiSelect
            inputId="documentRoles"
            className="w-20rem"
            value={roles}
            options={roleOptions}
            onChange={(e) => {
              setRoles(e.value as Role[]);
              setHasUnsavedChanges(true);
            }}
          />
          <InputNotice>Only users with one of the selected roles will have access.</InputNotice>
        </div>
      )}
      <div className="input-field flex align-items-center gap-3">
        <Checkbox
          inputId="documentStamp"
          checked={stamp}
          onChange={(e) => {
            setStamp(!!e.checked);
            setHasUnsavedChanges(true);
          }}
        />
        <label htmlFor="documentStamp">Stamp the file (PDF documents only)</label>
      </div>
      <div className="input-field">
        <label htmlFor="documentDownloadPromptId">Download prompt</label>
        <div className="flex align-items-center gap-3">
          <Dropdown
            inputId="documentDownloadPromptId"
            className="w-20rem"
            disabled={loadingDownloadPrompts}
            value={downloadPromptId}
            onChange={({ value }) => {
              if (isString(value)) {
                setDownloadPromptId(value);
              } else {
                setDownloadPromptId(null);
              }
              setHasUnsavedChanges(true);
            }}
            options={prompts || []}
            optionLabel="name"
            optionValue="id"
            showClear
          />
          <Button
            className="p-1 button-not-styled"
            disabled={!downloadPromptId}
            tooltip="Preview"
            onClick={() => {
              if (downloadPromptId) {
                const prompt = prompts?.find((p) => p.id === downloadPromptId);
                if (prompt) {
                  showPrompt('', '', '', prompt);
                }
              }
            }}
          >
            <span className="material-symbols-outlined">preview</span>
          </Button>
        </div>
        <InputNotice>
          If a prompt is enabled, it will be shown in a pop-up before a user is allowed to download
          the file.
        </InputNotice>
      </div>
      <div className="flex justify-content-between align-items-center">
        <h3>Versions</h3>
        <Button
          label="Add new version"
          onClick={() => {
            setShowAddNewVersion(true);
          }}
          disabled={showAddNewVersion}
        />
      </div>
      {showAddNewVersion && (
        <div className="border-1 border-round p-3 mb-3">
          <FileUpload
            uploadVariables={{
              feature: FileUploadFeature.Document,
              documentId,
            }}
            showClose={false}
            onUploaded={() => {
              setShowAddNewVersion(false);
              void refetch();
            }}
          />
        </div>
      )}
      {doc?.versions.length ? (
        doc.versions.map((version, i) => (
          <DocumentVersion
            key={version.id}
            className={clsx('border-bottom-1 p-3', i === 0 && 'border-top-1')}
            documentId={documentId}
            version={version}
            current={doc.currentVersion?.id === version.id}
          />
        ))
      ) : (
        <p>No versions.</p>
      )}
    </PageContainer>
  );
};

export const Document: FunctionComponent = () => {
  const { documentId } = useParams<{ documentId: string }>();

  if (documentId) {
    return <DocumentBody documentId={documentId} />;
  }

  return (
    <PageContainer>
      <p>Invalid page.</p>
    </PageContainer>
  );
};
