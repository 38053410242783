import {
  ExpressionType,
  isIncompleteBooleanExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { Either as EitherType } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent } from 'react';
import { ExpressionBuilder } from '../ExpressionBuilder.js';
import type { BuilderProps } from './shared.js';

export const Either: FunctionComponent<BuilderProps<EitherType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div>
        <div className="font-medium mb-1">Left operand</div>
        <ExpressionBuilder
          id={`${id}-left`}
          expression={expression.left}
          setExpression={(left) => {
            if (isIncompleteBooleanExpression(left)) {
              setExpression({
                ...expression,
                left,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={ExpressionType.Boolean}
        />
      </div>
      <div>
        <div className="font-medium mb-1">Right operand</div>
        <ExpressionBuilder
          id={`${id}-right`}
          expression={expression.right}
          setExpression={(right) => {
            if (isIncompleteBooleanExpression(right)) {
              setExpression({
                ...expression,
                right,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={ExpressionType.Boolean}
        />
      </div>
    </Fragment>
  );
};
