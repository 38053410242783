import { SubscriptionResult, useSubscription, OnDataOptions } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import {
  CompletedStagesChangedDocument,
  CompletedStagesChangedSubscription,
  CompletedStagesChangedSubscriptionVariables,
} from './subscriptions.generated.js';

export const useCompletedStagesChanged = (
  userId: string,
  onData: (options: OnDataOptions<CompletedStagesChangedSubscription>) => void,
  onError: ErrorHandler['onError'],
): SubscriptionResult<
  CompletedStagesChangedSubscription,
  CompletedStagesChangedSubscriptionVariables
> =>
  useSubscription(CompletedStagesChangedDocument, {
    onError,
    variables: { userId },
    onData,
  });
