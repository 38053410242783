import { useLazyQuery } from '@apollo/client';
import { FileStatus } from '@wirechunk/lib/api.js';
import { useCallback, useEffect, useState } from 'react';
import type { ErrorHandler } from '../useErrorHandler.js';
import { useInterval } from '../useInterval.js';
import { FileStatusDocument } from './queries.generated.js';

type PollFileStatus = {
  // startPollingFile starts polling for the file's status. If there is already a polling loop running,
  // calling this function with the same file ID will not do anything, but calling the function with a
  // different file ID will stop the existing polling loop and start a new one.
  startPollingFile: (id: string, onDone?: (status: FileStatus) => void) => void;
  stopPollingFile: () => void;
  isPolling: boolean;
};

export const usePollFileStatus = (
  onError: ErrorHandler['onError'],
  timeoutMs: number,
): PollFileStatus => {
  const [getFile] = useLazyQuery(FileStatusDocument, { onError });

  type Poll = {
    fn: () => Promise<void>;
    fileId: string;
    startedAtMs: number;
  };
  const [poll, setPoll] = useState<Poll | null>(null);
  useInterval(poll?.fn || null, 400);

  const stopPollingFile = useCallback(() => {
    setPoll(null);
  }, []);

  const startPollingFile = useCallback<PollFileStatus['startPollingFile']>(
    (id, onDone) => {
      setPoll((poll) => {
        if (poll && poll.fileId === id) {
          return poll;
        }
        const startedAtMs = Date.now();
        return {
          fn: async () => {
            try {
              await getFile({
                onError,
                variables: { id },
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                  if (
                    data.file.status === FileStatus.Uploaded ||
                    data.file.status === FileStatus.Canceled
                  ) {
                    stopPollingFile();
                    onDone?.(data.file.status);
                  }
                },
              });
            } finally {
              // If we've been polling for at least timeoutMs, stop polling.
              if (Date.now() - startedAtMs >= timeoutMs) {
                stopPollingFile();
                // The upload timed out. We represent this state as Canceled.
                onDone?.(FileStatus.Canceled);
              }
            }
          },
          fileId: id,
          startedAtMs,
        };
      });
    },
    [getFile, onError, stopPollingFile, timeoutMs],
  );

  useEffect(() => {
    if (poll && Date.now() - poll.startedAtMs >= timeoutMs) {
      stopPollingFile();
    }
  }, [poll, stopPollingFile, timeoutMs]);

  return {
    startPollingFile,
    stopPollingFile,
    isPolling: !!poll,
  };
};
