import * as Sentry from '@sentry/react';
import { ErrorBoundary as SentryErrorBoundary, FallbackRender } from '@sentry/react';
import { FunctionComponent, ReactNode } from 'react';
import { environment } from '../../env.js';
import { PaddedContent } from '../PaddedContent/PaddedContent.js';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      // Sentry doesn't do a good job of capturing and nesting spans. Only the first few fetch requests are
      // captured, and they're placed under the "pageload" transaction.
      traceFetch: false,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment,
  release: import.meta.env.VITE_APP_VERSION,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

const Fallback: FallbackRender = () => (
  <PaddedContent className="m-3">
    <h1 className="text-lg mt-1 mb-3">Error</h1>
    <p className="font-bold mb-1">Sorry, some unhandled error occurred.</p>
  </PaddedContent>
);

type RootErrorBoundaryProps = {
  children: ReactNode;
};

export const RootErrorBoundary: FunctionComponent<RootErrorBoundaryProps> = ({ children }) => (
  <SentryErrorBoundary fallback={Fallback} showDialog>
    {children}
  </SentryErrorBoundary>
);
