import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent, ReactNode, useRef, useState } from 'react';
import styles from './EditableMode.module.css';

enum Mode {
  View,
  Edit,
}

type EditableModeProps = {
  children: ReactNode;
  editMode: () => ReactNode;
  onSave: () => void;
  onCancel?: () => void;
};

export const EditableMode: FunctionComponent<EditableModeProps> = ({
  children,
  editMode,
  onSave,
  onCancel,
}) => {
  const [mode, setMode] = useState(Mode.View);
  const confirmMenu = useRef<Menu>(null);

  return mode === Mode.View ? (
    <div className={`${styles.wrapper} relative`}>
      {children}
      <div className={`${styles.controls} absolute`}>
        <Button
          icon={PrimeIcons.PENCIL}
          onClick={() => {
            setMode(Mode.Edit);
          }}
        />
      </div>
    </div>
  ) : (
    <Fragment>
      <div className={`${styles.wrapper} ${styles.editMode} relative`}>
        {editMode()}
        <div className={`${styles.controls} gap-1 absolute`}>
          <Button
            icon={PrimeIcons.TIMES_CIRCLE}
            onClick={(event) => {
              confirmMenu.current?.show(event);
            }}
          />
          <Button className={styles.saveButton} icon={PrimeIcons.CHECK_CIRCLE} onClick={onSave} />
        </div>
      </div>
      <Menu
        ref={confirmMenu}
        // overflow-hidden because of rounded corners and gray background on hover.
        className="py-0 overflow-hidden"
        popup
        model={[
          {
            label: 'Confirm cancel',
            command: () => {
              setMode(Mode.View);
              onCancel?.();
            },
          },
        ]}
      />
    </Fragment>
  );
};
