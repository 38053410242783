import { useCallback } from 'react';
import { DisplayFormEntry } from '../../components/DisplayFormEntry/DisplayFormEntry.js';
import { useDialog } from '../../contexts/DialogContext/DialogContext.js';
import type { ParsedFormEntry } from '../use-parsed-form-entries/use-parsed-form-entries.js';

type DisplayFormEntryDialog = (entry: ParsedFormEntry) => void;

export const useDisplayFormEntryDialog = (onBeforeHide?: () => void): DisplayFormEntryDialog => {
  const dialog = useDialog();

  return useCallback(
    (entry: ParsedFormEntry) => {
      dialog({
        content: <DisplayFormEntry entry={entry} />,
        onBeforeHide,
        props: {
          header: `Form entry from ${entry.createdAtTimestamp}`,
          className: 'dialog-width-lg',
        },
      });
    },
    [dialog, onBeforeHide],
  );
};
