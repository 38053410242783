import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { DropZone } from './drop-zone/drop-zone.js';
import { TreeComponent } from './tree-component/tree-component.js';

export type TreeProps = {
  parent: Component | null;
  components: Component[] | null;
  setComponent: (component: Component) => void;
  setComponents: (updater: (components: Component[]) => Component[]) => void;
  findComponent: (id: string) => Component | null;
  moveComponent: (id: string, destinationParentId: string | null, afterId: string | null) => void;
  alwaysShowAddComponentButton?: boolean;
};

export const Tree: FunctionComponent<TreeProps> = ({
  parent,
  components,
  setComponent,
  setComponents,
  findComponent,
  moveComponent,
  alwaysShowAddComponentButton = false,
}) => {
  return (
    <div className="flex flex-column gap-1">
      <DropZone
        setComponents={setComponents}
        parentComponentId={parent?.id}
        excludeDropComponentId={components?.[0]?.id}
        showHelpText={!!parent && !components?.length}
        alwaysShowAddComponentButton={alwaysShowAddComponentButton}
      />
      {components
        ?.reduce<Array<[Component | null, Component]>>((acc, component) => {
          const previousComponent = acc[acc.length - 1]?.[1] || null;
          acc.push([previousComponent, component]);
          return acc;
        }, [])
        .map(([previousComponent, component]) => {
          return (
            <TreeComponent
              key={component.id}
              component={component}
              setComponent={setComponent}
              setComponents={setComponents}
              findComponent={findComponent}
              moveComponent={moveComponent}
              parent={parent}
              previousSiblingId={previousComponent?.id || null}
            />
          );
        })}
    </div>
  );
};
