import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import type { EditComponent } from '../../editComponentByType.js';
import { StylesEditor } from '../../styles-editor.js';

export const LabelTab: EditComponent = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editLabelTabLabel">Label</label>
        <InputText
          id="editLabelTabLabel"
          className="w-full"
          value={component.label || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              label: e.target.value,
            }));
          }}
        />
      </div>
      <StylesEditor
        styles={component.labelStyles || {}}
        setStyles={(s) => {
          setComponent((c) => ({ ...c, labelStyles: s(c.labelStyles || {}) }));
        }}
      />
    </Fragment>
  );
};
