/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditPageMutationVariables = Types.Exact<{
  pageId: Types.Scalars['String']['input'];
  path: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
  metaTitle?: Types.InputMaybe<Types.Scalars['String']['input']>;
  metaDescription?: Types.InputMaybe<Types.Scalars['String']['input']>;
  metaRobots?: Types.InputMaybe<
    Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']
  >;
  status: Types.PublishStatus;
  pagePurposeId: Types.Scalars['String']['input'];
  components: Types.Scalars['String']['input'];
  bodyStyles: Types.Scalars['String']['input'];
  public: Types.Scalars['Boolean']['input'];
  productItem?: Types.InputMaybe<Types.OptionalStringUpdate>;
  roles: Array<Types.Role> | Types.Role;
  featuredImageUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
  caption?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type EditPageMutation = {
  editPage: {
    __typename: 'Page';
    id: string;
    path: string;
    title: string;
    metaTitle?: string | null;
    metaDescription?: string | null;
    metaRobots?: Array<string> | null;
    status: Types.PublishStatus;
    components: string;
    bodyStyles: string;
    public: boolean;
    productItem?: string | null;
    roles: Array<Types.Role>;
    featuredImageUrl?: string | null;
    caption?: string | null;
    edited: boolean;
    purpose: { __typename: 'PagePurpose'; id: string; name: string };
  };
};

export const EditPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metaTitle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metaDescription' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metaRobots' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PublishStatus' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagePurposeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'components' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bodyStyles' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productItem' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OptionalStringUpdate' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'featuredImageUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'caption' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metaTitle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metaTitle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metaDescription' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metaDescription' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metaRobots' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metaRobots' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagePurposeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagePurposeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'components' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'components' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bodyStyles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bodyStyles' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productItem' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productItem' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'featuredImageUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'featuredImageUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'caption' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'caption' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaRobots' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purpose' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bodyStyles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productItem' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edited' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPageMutation, EditPageMutationVariables>;
