import { clsx } from 'clsx';
import { isString, noop } from 'lodash-es';
import { TabPanel, TabView } from 'primereact/tabview';
import type { PropsWithChildren, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type Tab = {
  toString: () => string;
};

export type NavTabsProps<T extends Tab> = PropsWithChildren<{
  // The URL path common to all tabs, without a trailing slash.
  rootPath: string;
  tabs: readonly T[];
  activeTab: T;
  tabHeader: (tab: T) => string;
  panelContainerClassName?: string;
  panelClassName?: string;
}>;

export const NavTabs = <T extends Tab>({
  rootPath,
  tabs,
  activeTab,
  tabHeader,
  panelContainerClassName,
  panelClassName,
  children,
}: NavTabsProps<T>): ReactElement => {
  const navigate = useNavigate();

  return (
    <TabView
      activeIndex={Math.max(tabs.indexOf(activeTab), 0)}
      onBeforeTabChange={({ index }) => {
        const path = tabs[index]?.toString();
        if (isString(path)) {
          // path may be the empty string. In that case, navigate to the root path without a trailing slash.
          navigate(path ? `${rootPath}/${path}` : rootPath);
        }
      }}
      // This is needed to make this TabView a controlled component.
      onTabChange={noop}
      panelContainerClassName={clsx('p-0', panelContainerClassName)}
    >
      {tabs.map((t) => (
        <TabPanel key={t.toString()} header={tabHeader(t)} className={panelClassName}>
          {children}
        </TabPanel>
      ))}
    </TabView>
  );
};
