import { noop } from 'lodash-es';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export enum SessionStatus {
  Active,
  Expired,
  SignedOut,
}

export type SessionStatusContext = {
  status: SessionStatus;
  setStatus: Dispatch<SetStateAction<SessionStatus>>;
  errorMessage: string | null;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
};

const Context = createContext<SessionStatusContext>({
  status: SessionStatus.SignedOut,
  setStatus: noop,
  errorMessage: null,
  setErrorMessage: noop,
});

export const SessionStatusContextProvider = Context.Provider;

export const useSessionStatusContext = (): SessionStatusContext => useContext(Context);
