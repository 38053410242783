import { useQuery } from '@apollo/client';
import { Fragment, FunctionComponent } from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { Spinner } from '../Spinner.js';
import { Members } from './Members.js';
import { OrganizationDocument } from './queries.generated.js';
import { Subscriptions } from './Subscriptions.js';

type OrganizationDetailsProps = {
  organizationId: string;
};

export const OrganizationDetails: FunctionComponent<OrganizationDetailsProps> = ({
  organizationId,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();

  const { data, loading, refetch } = useQuery(OrganizationDocument, {
    onError,
    variables: { id: organizationId },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Fragment>
      <ErrorMessage />
      {loading && <Spinner />}
      {data && (
        <Fragment>
          <div className="line-height-3">
            <span className="font-medium">Name:</span> {data.organization.name}
          </div>
          <Members organization={data.organization} />
          <Subscriptions organization={data.organization} refetchOrganization={refetch} />
        </Fragment>
      )}
    </Fragment>
  );
};
