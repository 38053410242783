import { useMutation } from '@apollo/client';
import { Permission, UserPlatformPermissions } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { isEqual } from 'lodash-es';
import { MultiSelect } from 'primereact/multiselect';
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import type { AdminUser } from '../../../../hooks/useAdminUsers/useAdminUsers.js';
import { ErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { EditableMode } from '../../../EditableMode/EditableMode.js';
import { NoneLabel } from '../../../NoneLabel/NoneLabel.js';
import { EditUserPlatformPermissionsDocument } from './mutations.generated.js';

const findPlatformPermissions = (
  platformPermissions: Array<
    Pick<UserPlatformPermissions, 'userId' | 'platformId' | 'permissions'>
  >,
  platformId: string,
) => platformPermissions.find((p) => p.platformId === platformId)?.permissions || [];

const permissionOptions = Object.values(Permission).sort();

type UserPlatformPermissionsCellProps = {
  user: AdminUser;
  platformId: string;
  showPendingState: boolean;
  onError: ErrorHandler['onError'];
};

export const UserPlatformPermissionsCell: FunctionComponent<UserPlatformPermissionsCellProps> = ({
  user,
  platformId,
  showPendingState,
  onError,
}) => {
  const { toastSuccess } = useToast();
  const dialog = useDialog();
  const [editPermissions, { loading: saving }] = useMutation(EditUserPlatformPermissionsDocument, {
    onError,
  });
  const [permissions, setPermissions] = useState(() =>
    findPlatformPermissions(user.platformPermissions, platformId).toSorted(),
  );
  const [instanceKey, setInstanceKey] = useState(permissions.join());

  // Anytime the user's platform permissions change, reset the instance key to a value derived from the user's new
  // platformPermissions. This will reset this components state if and only if the permissions have changed.
  useEffect(() => {
    setInstanceKey(findPlatformPermissions(user.platformPermissions, platformId).join());
  }, [platformId, user.platformPermissions]);

  const onCancelEdit = useCallback(() => {
    setPermissions(findPlatformPermissions(user.platformPermissions, platformId));
  }, [platformId, user.platformPermissions]);

  return (
    <EditableMode
      // This key prop forces the component to remount when changes are saved so that the state is reset to view mode.
      key={instanceKey}
      editMode={() => (
        <MultiSelect
          className="w-22rem max-w-full"
          value={permissions}
          options={permissionOptions}
          onChange={(e) => {
            setPermissions(e.target.value as Permission[]);
          }}
        />
      )}
      onSave={() => {
        const currentPermissions = findPlatformPermissions(
          user.platformPermissions,
          platformId,
        ).toSorted();
        const newPermissions = permissions.toSorted();
        if (isEqual(currentPermissions, newPermissions)) {
          // Allow toggling out of edit mode without saving. Using a random string makes it possible to keep toggling
          // edit mode on and off without making edits.
          setInstanceKey(Math.random().toString());
          return;
        }

        dialog({
          confirm: (
            <Fragment>Are you sure you want to change {user.displayName}’s permissions?</Fragment>
          ),
          props: {
            onAccept: () => {
              void editPermissions({
                variables: {
                  id: user.id,
                  platformId,
                  permissions,
                },
                onCompleted: () => {
                  toastSuccess('Permissions updated.');
                },
              });
            },
          },
        });
      }}
      onCancel={onCancelEdit}
    >
      <div
        className={clsx('w-22rem max-w-full', (saving || showPendingState) && 'text-color-muted')}
      >
        {permissions.length ? permissions.toSorted().join(', ') : <NoneLabel />}
      </div>
    </EditableMode>
  );
};
