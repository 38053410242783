import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { CurrentSitePagesDocument, CurrentSitePagesQuery } from './queries.generated.js';

export type CurrentSitePage = NonNullable<CurrentSitePagesQuery['currentSite']>['pages'][number];

type AppPages = {
  pages: CurrentSitePage[];
  isLoading: boolean;
};

export const useCurrentSitePages = ({
  pathPrefix,
  onError,
}: {
  pathPrefix?: string;
  onError: ErrorHandler['onError'];
}): AppPages => {
  const { data, loading: isLoading } = useQuery(CurrentSitePagesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      pathPrefix,
    },
  });

  return {
    pages: data?.currentSite.pages || [],
    isLoading,
  };
};
