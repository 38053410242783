import { isPlainObject, isString } from 'lodash-es';
import type { ActiveCampaignTagsSyncConfig } from './api.js';
import { isStringArray } from './arrays.js';
import type { RemapPropertiesToUnknown } from './util-types.js';

export enum PlatformOptionKey {
  ActiveCampaignApiToken = 'ActiveCampaignApiToken',
  ActiveCampaignApiUrl = 'ActiveCampaignApiUrl',
  ActiveCampaignTagsSyncConfig = 'ActiveCampaignTagsSyncConfig',
  MainSiteId = 'MainSiteId',
  MixpanelToken = 'MixpanelToken',
  SendGridApiKey = 'SendGridApiKey',
  SitesRootDomain = 'SitesRootDomain',
  // This should be site-specific.
  StripePublishableKey = 'StripePublishableKey',
  StripeSecretKey = 'StripeSecretKey',
  StripeWebhookSigningSecret = 'StripeWebhookSigningSecret',
  WebhookHelpTicketCreatedUrl = 'WebhookHelpTicketCreatedUrl',
  WebhookOrganizationPeerUserCreatedUrl = 'WebhookOrganizationPeerUserCreatedUrl',
  WebhookRegisterUserSecret = 'WebhookRegisterUserSecret',
  WebhookSubscriptionActivatedUrl = 'WebhookSubscriptionActivatedUrl',
  ZendeskApiToken = 'ZendeskApiToken',
  ZendeskHelpTicketTags = 'ZendeskHelpTicketTags',
  ZendeskSubdomain = 'ZendeskSubdomain',
  ZendeskUserAuthJwtKey = 'ZendeskUserAuthJwtKey',
  ZendeskUserAuthJwtKeyId = 'ZendeskUserAuthJwtKeyId',
  ZendeskUsername = 'ZendeskUsername',
  ZendeskWebhookSigningSecret = 'ZendeskWebhookSigningSecret',
}

const platformOptions = Object.values(PlatformOptionKey);

export const isPlatformOption = (value: unknown): value is PlatformOptionKey =>
  platformOptions.includes(value as never);

type UnknownActiveCampaignTagsSyncConfig = RemapPropertiesToUnknown<ActiveCampaignTagsSyncConfig>;

export const isActiveCampaignTagsSyncConfig = (
  value: unknown,
): value is ActiveCampaignTagsSyncConfig =>
  isPlainObject(value) &&
  isString((value as UnknownActiveCampaignTagsSyncConfig).activeCampaignTagId) &&
  !!(value as UnknownActiveCampaignTagsSyncConfig).activeCampaignTagId &&
  isStringArray((value as UnknownActiveCampaignTagsSyncConfig).subscriptionPlanIds);

export const isActiveCampaignTagsSyncConfigArray = (
  value: unknown,
): value is ActiveCampaignTagsSyncConfig[] =>
  Array.isArray(value) && value.every(isActiveCampaignTagsSyncConfig);
