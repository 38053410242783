import { LinkComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

export const EditLink: FunctionComponent<EditComponentContentProps<LinkComponent>> = ({
  component,
  setComponent,
}) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor={`editLinkUrl-${component.id}`}>Link URL</label>
      <InputText
        id={`editLinkUrl-${component.id}`}
        className="w-full"
        value={component.to || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, to: e.target.value }));
        }}
      />
    </div>
    <div className="input-field flex align-items-center gap-3">
      <Checkbox
        inputId="editLinkIsExternal"
        checked={component.isExternal || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, isExternal: e.checked }));
        }}
      />
      <label htmlFor="editLinkIsExternal">Link is external</label>
    </div>
    <div className="input-field flex align-items-center gap-3">
      <Checkbox
        inputId="editLinkOpenInNewTab"
        checked={component.openInNewTab || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, openInNewTab: e.checked }));
        }}
      />
      <label htmlFor="editLinkOpenInNewTab">Open in new tab</label>
    </div>
  </Fragment>
);
