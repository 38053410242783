import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import type { EditComponent } from '../../editComponentByType.js';

export const DataInputTableColumnOptionsTab: EditComponent = ({
  component: { dataInputTableColumnOptions },
  setComponent,
}) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editDataInputTableColumnOptionsTabHeader">Column header</label>
        <InputText
          id="editDataInputTableColumnOptionsTabHeader"
          className="w-full"
          value={dataInputTableColumnOptions?.header || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              dataInputTableColumnOptions: {
                ...(c.dataInputTableColumnOptions || {}),
                header: e.target.value,
              },
            }));
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="editDataInputTableColumnOptionsTabWidth">Column width</label>
        <InputText
          id="editDataInputTableColumnOptionsTabWidth"
          className="w-full"
          value={dataInputTableColumnOptions?.width || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              dataInputTableColumnOptions: {
                ...(c.dataInputTableColumnOptions || {}),
                width: e.target.value,
              },
            }));
          }}
        />
        <InputNotice>
          Specify a valid CSS width value for the column, such as “
          <span className="font-medium">100px</span>” or “
          <span className="font-medium">minmax(100px, 1fr)</span>”.
        </InputNotice>
      </div>
      <div className="input-field">
        <label htmlFor="editDataInputTableColumnOptionsTabClassName">Column classes</label>
        <InputText
          id="editDataInputTableColumnOptionsTabClassName"
          className="w-full"
          value={dataInputTableColumnOptions?.className || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              dataInputTableColumnOptions: {
                ...(c.dataInputTableColumnOptions || {}),
                className: e.target.value,
              },
            }));
          }}
        />
        <InputNotice>
          The classes you list here will be applied to every cell in the column including the header
          cell.
        </InputNotice>
      </div>
    </Fragment>
  );
};
