import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@radix-ui/themes';
import { Permission } from '@wirechunk/lib/api.js';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { clickableRowClassName } from '../../../../../util/clickableRowClassName.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { navItems } from '../nav.js';
import { CreateFormTemplate } from './CreateFormTemplate.js';
import { DuplicateFormTemplateDocument } from './mutations.generated.js';
import { FormTemplatesDocument, FormTemplatesQuery } from './queries.generated.js';

type Row = FormTemplatesQuery['formTemplates'][number];

export const FormTemplates: FunctionComponent = () => {
  const { id: platformId, handle, permissions, navigate } = usePlatformContext();
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading, refetch } = useQuery(FormTemplatesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId },
  });
  const [duplicateTemplate, { loading: duplicating }] = useMutation(DuplicateFormTemplateDocument, {
    onError,
    onCompleted: (data) => {
      toastSuccess('Form template duplicated.');
      navigate(`/sites/form-templates/${data.duplicateFormTemplate.id}`);
    },
  });

  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const menu = useRef<Menu>(null);
  const [menuFormTemplate, setMenuFormTemplate] = useState<Row | null>(null);

  const hasCreateTemplatePermission = permissions.includes(Permission.CreateTemplate);

  const templates = useMemo<Row[]>(() => {
    if (data?.formTemplates) {
      return data.formTemplates;
    }
    return [];
  }, [data?.formTemplates]);

  return (
    <PageContainer title="Form Templates" nav={navItems(handle)}>
      <ErrorMessage />
      {hasCreateTemplatePermission && (
        <Button
          className="mb-3"
          onClick={() => {
            setShowCreateTemplate(true);
          }}
          disabled={showCreateTemplate}
        >
          Create form template
        </Button>
      )}
      {showCreateTemplate && (
        <CreateFormTemplate
          platformId={platformId}
          onCreated={(id) => {
            void refetch();
            setShowCreateTemplate(false);
            toastSuccess('Form template created.');
            navigate(`/sites/form-templates/${id}`);
          }}
          onCancel={() => {
            setShowCreateTemplate(false);
          }}
        />
      )}
      <DataTable
        loading={loading}
        value={templates}
        rowClassName={clickableRowClassName}
        onRowClick={(e) => {
          navigate(`/sites/form-templates/${(e.data as Row).id}`);
        }}
      >
        <Column header="Title" field="title" />
        <Column header="Status" field="status" />
        <Column
          header=""
          align="right"
          body={(row: Row) => (
            <button
              className="button-not-styled p-1"
              onClick={(event) => {
                setMenuFormTemplate(row);
                menu.current?.show(event);
              }}
            >
              <i className="pi pi-ellipsis-v" />
            </button>
          )}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            disabled: duplicating,
            command: () => {
              if (menuFormTemplate) {
                void duplicateTemplate({
                  variables: { templateId: menuFormTemplate.id },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
