/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditPageTemplateMutationVariables = Types.Exact<{
  input: Types.EditPageTemplateInput;
}>;

export type EditPageTemplateMutation = {
  editPageTemplate:
    | {
        __typename: 'EditPageTemplateSuccessResult';
        pageTemplate: {
          __typename: 'PageTemplate';
          previewImageUrl?: string | null;
          description?: string | null;
          propsSetupComponents?: string | null;
          previewProps?: string | null;
          id: string;
          status: Types.PublishStatus;
          path: string;
          title: string;
          metaTitle?: string | null;
          components: string;
          bodyStyles: string;
          purpose: { __typename: 'PagePurpose'; id: string; name: string };
        };
      }
    | { __typename: 'GenericUserError'; message: string };
};

export type SyncPageTemplateToPagesMutationVariables = Types.Exact<{
  pageTemplateId: Types.Scalars['String']['input'];
}>;

export type SyncPageTemplateToPagesMutation = {
  syncPageTemplateToPages:
    | { __typename: 'GenericUserError'; message: string }
    | {
        __typename: 'SyncPageTemplateToPagesSuccessResult';
        syncedPagesCount: number;
        notSyncedPagesCount: number;
      };
};

export const EditPageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditPageTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EditPageTemplateSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PageTemplateInfo' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'previewImageUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'propsSetupComponents' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'previewProps' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTemplateInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'components' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bodyStyles' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPageTemplateMutation, EditPageTemplateMutationVariables>;
export const SyncPageTemplateToPagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncPageTemplateToPages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncPageTemplateToPages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SyncPageTemplateToPagesSuccessResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'syncedPagesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notSyncedPagesCount' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SyncPageTemplateToPagesMutation,
  SyncPageTemplateToPagesMutationVariables
>;
