import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import {
  componentIsDeepChildOf,
  insertAfterComponent,
  insertChildIntoComponentStart,
  pullComponentById,
} from '@wirechunk/lib/mixer/utils.js';
import { clsx } from 'clsx';
import { FunctionComponent } from 'react';
import { useDrop } from 'react-dnd';
import { AddComponentButton } from '../AddComponentButton.js';
import { DragItem, dragItemType } from '../dnd.js';
import treeComponentStyles from '../tree-component/tree-component.module.css';
import styles from './drop-zone.module.css';

type DropHookData = {
  isOver: boolean;
  canDrop: boolean;
};

type DropZoneProps = {
  // parentComponentId is set unless we're dropping into the root.
  parentComponentId?: string | null;
  afterComponentId?: string | null;
  excludeDropComponentId?: string;
  setComponents: (updater: (components: Component[]) => Component[]) => void;
  showHelpText: boolean;
  alwaysShowAddComponentButton?: boolean;
};

export const DropZone: FunctionComponent<DropZoneProps> = ({
  parentComponentId,
  afterComponentId,
  excludeDropComponentId,
  setComponents,
  showHelpText,
  alwaysShowAddComponentButton = false,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop<DragItem, unknown, DropHookData>(
    {
      accept: dragItemType,
      collect: (monitor): DropHookData => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
      drop: (item: DragItem, monitor) => {
        if (!monitor.didDrop()) {
          setComponents((components) => {
            const [newComponents, extractedComponent] = pullComponentById(components, item.id);
            if (extractedComponent) {
              if (afterComponentId) {
                return insertAfterComponent(newComponents, afterComponentId, extractedComponent);
              }
              if (parentComponentId) {
                return insertChildIntoComponentStart(
                  newComponents,
                  parentComponentId,
                  extractedComponent,
                );
              }
              return [extractedComponent, ...newComponents];
            }
            return components;
          });
        }
      },
      canDrop: (item): boolean =>
        item.id !== afterComponentId &&
        item.id !== parentComponentId &&
        item.id !== excludeDropComponentId &&
        item.previousSiblingId !== afterComponentId &&
        // This check below prevents dropping a component into one of its children.
        (!item.children ||
          !parentComponentId ||
          !componentIsDeepChildOf(item.children, item.id, parentComponentId)),
    },
    [afterComponentId, parentComponentId, excludeDropComponentId],
  );

  return (
    <div
      ref={drop}
      className={clsx(
        'align-items-center border-round',
        styles.dropZone,
        treeComponentStyles.emptyComponentHeight,
        isOver && canDrop && [treeComponentStyles.componentHeight, 'bg-green-400'],
      )}
    >
      {!isOver && (
        <AddComponentButton
          onSelect={(newComponent) => {
            setComponents((cs): Component[] => {
              if (afterComponentId) {
                return insertAfterComponent(cs, afterComponentId, newComponent);
              }
              if (parentComponentId) {
                return insertChildIntoComponentStart(cs, parentComponentId, newComponent);
              }
              return [newComponent, ...cs];
            });
          }}
          className={clsx(
            styles.addComponentButton,
            alwaysShowAddComponentButton && styles.forceVisible,
          )}
        />
      )}
      {showHelpText && <div className="text-sm">Drop components here</div>}
    </div>
  );
};
