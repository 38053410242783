import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { FunctionComponent, useMemo, useState } from 'react';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { OrganizationSelector } from '../OrganizationSelector/OrganizationSelector.js';
import { CreateSubscriptionOrganizationDocument } from './mutations.generated.js';
import type { SubscriptionDetailsQuery } from './queries.generated.js';

type CreateSubscriptionOrganizationProps = {
  className?: string;
  subscription: SubscriptionDetailsQuery['subscription'];
  onAdded: () => void;
  onCancel: () => void;
};

export const CreateSubscriptionOrganization: FunctionComponent<
  CreateSubscriptionOrganizationProps
> = ({ className, subscription, onAdded, onCancel }) => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const [createSubOrg, { loading: isCreating }] = useMutation(
    CreateSubscriptionOrganizationDocument,
    {
      onError,
      onCompleted: onAdded,
    },
  );

  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const currentSubOrgIds = useMemo<Set<string>>(
    () => new Set(subscription.subscriptionOrganizations.map((subOrg) => subOrg.organization.id)),
    [subscription.subscriptionOrganizations],
  );

  return (
    <div className={className}>
      <ErrorMessage />
      <div className="flex flex-row gap-3 align-items-end">
        <OrganizationSelector
          platformId={platformId}
          className="md:w-24rem w-16rem"
          excludeOrganizationIds={currentSubOrgIds}
          organizationId={organizationId}
          setOrganizationId={setOrganizationId}
          onError={onError}
        />
        <Button
          label="Add"
          disabled={!organizationId || isCreating}
          onClick={() => {
            if (organizationId) {
              void createSubOrg({
                variables: {
                  subscriptionId: subscription.id,
                  organizationId,
                },
              });
            }
          }}
        />
        <Button label="Cancel" className="p-button-secondary" onClick={onCancel} />
      </div>
    </div>
  );
};
