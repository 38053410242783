import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { useMemo } from 'react';

export const useCollectAllFormComponents = (formOrTemplate: {
  components: string;
  steps: { components: string }[];
}): Component[] =>
  useMemo<Component[]>(() => {
    const allComponents = parseComponents(formOrTemplate.components);
    for (const step of formOrTemplate.steps) {
      allComponents.push(...parseComponents(step.components));
    }
    return allComponents;
  }, [formOrTemplate.components, formOrTemplate.steps]);
