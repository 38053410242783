import { useMutation } from '@apollo/client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { ReviewComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fragment, FunctionComponent, useState } from 'react';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { SubmitReviewDocument } from './mutations.generated.js';

const writeGoogleReviewUrl = (placeId: string) =>
  `https://search.google.com/local/writereview?placeid=${placeId}`;

const defaultStarColor = '#80868b';
const filledStarColor = '#fabb05';

const Star: FunctionComponent<{
  filled: boolean;
  onClick: () => void;
}> = ({ filled, onClick }) => (
  <i
    className={`pi ${filled ? 'pi-star-fill' : 'pi-star'} text-4xl cursor-pointer`}
    style={{ color: filled ? filledStarColor : defaultStarColor }}
    role="radio"
    onClick={onClick}
  />
);

export const Review: FunctionComponent<ReviewComponent> = (props) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const siteContext = useSiteContext();
  const [name, setName] = useState('');
  const [starRating, setStarRating] = useState(0);
  const [mode, setMode] = useState<
    'selectStars' | 'writeReview' | 'redirectingToGoogle' | 'submitted'
  >('selectStars');
  const [review, setReview] = useState('');
  const [submitReview, { loading: isSubmitting }] = useMutation(SubmitReviewDocument, {
    onError,
  });

  const googlePlaceId = props.googlePlaceId || siteContext.googlePlaceId;

  const onSelectStars = (rating: 1 | 2 | 3 | 4 | 5) => () => {
    setStarRating(rating);
    if (rating === 5 && googlePlaceId) {
      void submitReview({
        variables: {
          starRating: rating,
          submitterName: null,
          review: '',
        },
      });
      setMode('redirectingToGoogle');
      setTimeout(() => {
        window.location.href = writeGoogleReviewUrl(googlePlaceId);
      }, 5_000);
      return;
    }
    if (mode === 'selectStars') {
      setMode('writeReview');
    }
  };

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <h1 className="font-medium mt-0 mb-3 text-center text-lg uppercase">
        Review {siteContext.name}
      </h1>
      {mode !== 'submitted' && (
        <Fragment>
          <p className="text-lg text-center mb-1">
            Your opinion matters to us! How would you rate our agency?
          </p>
          <div className="flex flex-row justify-content-center gap-3 py-3" role="radiogroup">
            <Star filled={starRating >= 1} onClick={onSelectStars(1)} />
            <Star filled={starRating >= 2} onClick={onSelectStars(2)} />
            <Star filled={starRating >= 3} onClick={onSelectStars(3)} />
            <Star filled={starRating >= 4} onClick={onSelectStars(4)} />
            <Star filled={starRating >= 5} onClick={onSelectStars(5)} />
          </div>
        </Fragment>
      )}
      {mode === 'writeReview' ? (
        <Fragment>
          <div className="input-field">
            <InputText
              className="w-full"
              value={name}
              placeholder="Your name (optional)"
              maxLength={80}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <InputTextarea
            value={review}
            className="w-full max-w-full"
            placeholder="Share details of your own experience with our agency"
            maxLength={5_000}
            onChange={(e) => {
              setReview(e.target.value);
            }}
          />
          <Button
            label="Submit"
            className="mt-3 font-bold"
            disabled={!starRating || !review || isSubmitting}
            onClick={() => {
              void submitReview({
                variables: {
                  starRating,
                  submitterName: name,
                  review,
                },
                onCompleted: () => {
                  setMode('submitted');
                },
              });
            }}
          />
        </Fragment>
      ) : mode === 'redirectingToGoogle' ? (
        <div className="text-center">
          <p className="text-lg">
            Thank you for the love! ❤️ You are being redirected to{' '}
            <span className="font-medium">Google</span>
            —please leave us a review there&hellip;
          </p>
          <a href={writeGoogleReviewUrl(googlePlaceId as string)}>
            Click here if you are not redirected
          </a>
        </div>
      ) : (
        mode === 'submitted' && (
          <div className="text-center">
            <p className="mb-5 mt-4 text-lg">
              Thank you for your review. We are glad to do business with you.
            </p>
          </div>
        )
      )}
    </div>
  );
};
