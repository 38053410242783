import { useMutation, useQuery } from '@apollo/client';
import { HelpTicketStatus } from '@wirechunk/lib/api.js';
import { omit } from 'lodash-es';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { FunctionComponent, useMemo, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import {
  helpTicketStatusOptions,
  helpTicketTypeToHumanReadable,
} from '../../../../util/helpTickets.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { HelpTicketsDetails } from './HelpTicketsDetails.js';
import { EditHelpTicketStatusDocument } from './mutations.generated.js';
import { HelpTicketsDocument, HelpTicketsQuery } from './queries.generated.js';

type TicketRow = HelpTicketsQuery['helpTickets'][number];

export const HelpTickets: FunctionComponent = () => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | any[]>({});
  const { data, loading: isLoadingTickets } = useQuery(HelpTicketsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId },
  });
  const [editHelpTicketStatus] = useMutation(EditHelpTicketStatusDocument, {
    onError,
  });

  const tickets = useMemo<TicketRow[]>(() => {
    // Place deactivated users last but otherwise keep the sort order.
    if (data?.helpTickets) {
      return [
        ...data.helpTickets.filter((ticket) => ticket.status !== HelpTicketStatus.Closed),
        ...data.helpTickets.filter((ticket) => ticket.status === HelpTicketStatus.Closed),
      ];
    }
    return [];
  }, [data]);

  const rowExpansionTemplate = (row: TicketRow) => {
    return <HelpTicketsDetails row={row} />;
  };

  const rowClassName = (row: TicketRow) => {
    if (row.status === HelpTicketStatus.Closed) {
      return 'surface-ground';
    }
    return '';
  };

  return (
    <PageContainer title="Help Tickets">
      <ErrorMessage />
      {isLoadingTickets ? (
        <div className="py-3 text-center">
          <i className="pi pi-spinner pi-spin text-lg" />
        </div>
      ) : (
        <DataTable
          dataKey="id"
          className="mt-3"
          emptyMessage="No tickets yet"
          value={tickets}
          expandedRows={expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
          rowClassName={rowClassName}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
        >
          <Column expander className="table-left-action-column" />
          <Column
            header="User"
            body={(row: TicketRow) => (
              <div>
                <div>{row.user?.displayName}</div>
                <div className="text-sm">{row.user?.email}</div>
              </div>
            )}
          />
          <Column
            header="Ticket type"
            body={(row: TicketRow) => {
              return helpTicketTypeToHumanReadable(row.type);
            }}
          />
          <Column
            header="Created"
            field="createdAt"
            body={(row: TicketRow) => new Date(row.createdAt).toLocaleString()}
          />
          <Column
            header="Status"
            body={(row: TicketRow) => (
              <Dropdown
                value={row.status}
                options={helpTicketStatusOptions}
                onChange={(e) => {
                  void editHelpTicketStatus({
                    variables: { id: row.id, status: e.value as HelpTicketStatus },
                    onCompleted: (data) => {
                      if (
                        data.editHelpTicketStatus.status === HelpTicketStatus.Closed &&
                        (expandedRows as DataTableExpandedRows)[row.id]
                      ) {
                        setExpandedRows(omit(expandedRows as DataTableExpandedRows, row.id));
                      }
                    },
                  });
                }}
              />
            )}
          />
        </DataTable>
      )}
    </PageContainer>
  );
};
