import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const OrganizationAgencyAddressZip: FunctionComponent<Component> = (props) => {
  const { addressZip } = useSiteContext();

  return <span className={componentClassName(props)}>{addressZip || ''}</span>;
};
