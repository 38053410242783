import { OrganizationLogoComponent } from '@wirechunk/lib/mixer/types/components.js';
import { Checkbox } from 'primereact/checkbox';
import { Fragment, FunctionComponent } from 'react';
import { EditComponentContentProps } from './shared/types.js';

export const EditOrganizationLogo: FunctionComponent<
  EditComponentContentProps<OrganizationLogoComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editOrganizationLogoDefaultAgencyName">Default to agency name</label>
      <Checkbox
        inputId="editOrganizationLogoDefaultAgencyName"
        checked={component.defaultAgencyName || false}
        onChange={(e) => {
          setComponent((c) => ({ ...c, defaultAgencyName: e.checked }));
        }}
      />
    </div>
  </Fragment>
);
