import { PrimeIcons } from 'primereact/api';
import { FunctionComponent, MouseEvent } from 'react';

type ThreeDotMenuButtonProps = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const ThreeDotMenuButton: FunctionComponent<ThreeDotMenuButtonProps> = ({ onClick }) => (
  <button className="button-not-styled p-1" onClick={onClick}>
    <i className={PrimeIcons.ELLIPSIS_V} />
  </button>
);
