import { TextareaInputComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

export const EditTextareaInput: FunctionComponent<
  EditComponentContentProps<TextareaInputComponent>
> = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editTextareaInputPlaceholder">Placeholder</label>
        <InputText
          id="editTextareaInputPlaceholder"
          className="w-full"
          value={component.placeholder || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              placeholder: e.target.value,
            }));
          }}
        />
      </div>
    </Fragment>
  );
};
