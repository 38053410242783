import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { EditPageMetadataDocument } from './mutations.generated.js';
import type { PagesQuery } from './queries.generated.js';

type EditPageTitleProps = {
  page: PagesQuery['site']['pages'][number];
  onEdited: () => void;
  onCancel: () => void;
};

export const EditPageTitle: FunctionComponent<EditPageTitleProps> = ({
  page,
  onEdited,
  onCancel,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [editPageMeta, { loading }] = useMutation(EditPageMetadataDocument, {
    onError,
  });
  const [title, setTitle] = useState(page.title);
  const [metaTitle, setMetaTitle] = useState(page.metaTitle || '');

  const onEdit = () => {
    clearMessages();
    if (title.trim()) {
      void editPageMeta({
        variables: {
          pageId: page.id,
          title,
          metaTitle,
          path: page.path,
        },
        onCompleted: onEdited,
      });
    }
  };

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="pageTitle">Title</label>
        <InputText
          id="pageTitle"
          className="w-full"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="pageMetaTitle">Title displayed in browser tab (optional)</label>
        <InputText
          id="pageMetaTitle"
          className="w-full"
          placeholder={title}
          value={metaTitle}
          onChange={(e) => {
            setMetaTitle(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-3 justify-content-end mt-5">
        <Button label="Cancel" className="p-button-secondary" onClick={onCancel} />
        <Button label="Save" onClick={onEdit} disabled={loading || !title.trim()} />
      </div>
    </Fragment>
  );
};
