import type { SelectItem } from '../types.js';

export const stateAbbreviationToFull = (abbr: string): string => {
  switch (abbr.toUpperCase()) {
    case 'AL':
      return 'Alabama';
    case 'AK':
      return 'Alaska';
    case 'AZ':
      return 'Arizona';
    case 'AR':
      return 'Arkansas';
    case 'CA':
      return 'California';
    case 'CO':
      return 'Colorado';
    case 'CT':
      return 'Connecticut';
    case 'DE':
      return 'Delaware';
    case 'DC':
      return 'District Of Columbia';
    case 'FL':
      return 'Florida';
    case 'GA':
      return 'Georgia';
    case 'HI':
      return 'Hawaii';
    case 'ID':
      return 'Idaho';
    case 'IL':
      return 'Illinois';
    case 'IN':
      return 'Indiana';
    case 'IA':
      return 'Iowa';
    case 'KS':
      return 'Kansas';
    case 'KY':
      return 'Kentucky';
    case 'LA':
      return 'Louisiana';
    case 'ME':
      return 'Maine';
    case 'MD':
      return 'Maryland';
    case 'MA':
      return 'Massachusetts';
    case 'MI':
      return 'Michigan';
    case 'MN':
      return 'Minnesota';
    case 'MS':
      return 'Mississippi';
    case 'MO':
      return 'Missouri';
    case 'MT':
      return 'Montana';
    case 'NE':
      return 'Nebraska';
    case 'NV':
      return 'Nevada';
    case 'NH':
      return 'New Hampshire';
    case 'NJ':
      return 'New Jersey';
    case 'NM':
      return 'New Mexico';
    case 'NY':
      return 'New York';
    case 'NC':
      return 'North Carolina';
    case 'ND':
      return 'North Dakota';
    case 'OH':
      return 'Ohio';
    case 'OK':
      return 'Oklahoma';
    case 'OR':
      return 'Oregon';
    case 'PA':
      return 'Pennsylvania';
    case 'RI':
      return 'Rhode Island';
    case 'SC':
      return 'South Carolina';
    case 'SD':
      return 'South Dakota';
    case 'TN':
      return 'Tennessee';
    case 'TX':
      return 'Texas';
    case 'UT':
      return 'Utah';
    case 'VT':
      return 'Vermont';
    case 'VA':
      return 'Virginia';
    case 'WA':
      return 'Washington';
    case 'WV':
      return 'West Virginia';
    case 'WI':
      return 'Wisconsin';
    case 'WY':
      return 'Wyoming';
    default:
      return abbr;
  }
};

export const stateOptions: Array<SelectItem<string>> = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];
