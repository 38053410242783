import {
  ExpressionType,
  isIncompleteArrayExpression,
  isIncompleteBooleanExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { Some as SomeType } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent } from 'react';
import { ExpressionBuilder } from '../ExpressionBuilder.js';
import type { BuilderProps } from './shared.js';

const arrayExpressionTypes: ExpressionType[] = [ExpressionType.Array];
const conditionExpressionTypes: ExpressionType[] = [ExpressionType.Boolean];

export const Some: FunctionComponent<BuilderProps<SomeType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div>
        <div className="font-medium mb-1">Array expression</div>
        <ExpressionBuilder
          id={`${id}-array-expression`}
          expression={expression.arrayExpression}
          setExpression={(arrayExpression) => {
            if (isIncompleteArrayExpression(arrayExpression)) {
              setExpression({
                ...expression,
                arrayExpression,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={arrayExpressionTypes}
        />
      </div>
      <div>
        <div className="font-medium mb-1">Condition</div>
        <ExpressionBuilder
          id={`${id}-condition`}
          expression={expression.condition}
          setExpression={(condition) => {
            if (isIncompleteBooleanExpression(condition)) {
              setExpression({
                ...expression,
                condition,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={conditionExpressionTypes}
        />
      </div>
    </Fragment>
  );
};
