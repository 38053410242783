import { Elements } from '@stripe/react-stripe-js';
import { BaseStripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { FunctionComponent, PropsWithChildren } from 'react';

const { stripePK } = window.wirechunk;
const stripe = stripePK ? loadStripe(stripePK) : null;

const elementsOptions: BaseStripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap',
    },
  ],
};

export const StripeElementsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Elements stripe={stripe} options={elementsOptions}>
      {children}
    </Elements>
  );
};
