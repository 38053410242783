import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import {
  ExpressionType,
  IncompleteExpression,
  isBooleanExpression,
  isCompleteExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import { isComponentWithConditionalStyles } from '@wirechunk/lib/mixer/types/categories.js';
import type {
  ConditionalStyles,
  ConditionAndStyles,
} from '@wirechunk/lib/mixer/types/components.js';
import type { Expression } from '@wirechunk/schemas/expressions/expression';
import { isEmpty } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useEffect } from 'react';
import { SetState, useStateRef } from '../../../../hooks/use-state-ref.js';
import { BasicIconButton } from '../../../BasicIconButton/BasicIconButton.js';
import { ExpressionBuilder } from '../../../ExpressionBuilder/ExpressionBuilder.js';
import type { ComponentEditorListener } from '../../components/shared/types.js';
import type { EditComponent } from '../../editComponentByType.js';
import { StylesEditor } from '../../styles-editor.js';

type WorkingConditionAndStyles = Omit<ConditionAndStyles, 'condition'> & {
  condition: IncompleteExpression<Expression> | null | undefined;
};

type EditWorkingConditionAndStylesProps = {
  element: WorkingConditionAndStyles;
  setWorkingElements: SetState<WorkingConditionAndStyles[]>;
};

const EditWorkingConditionAndStyles: FunctionComponent<EditWorkingConditionAndStylesProps> = ({
  element: { key, condition, styles },
  setWorkingElements,
}) => {
  return (
    <div className="flex flex-column gap-3">
      <BasicIconButton
        icon={PrimeIcons.TRASH}
        onClick={() => {
          setWorkingElements((workingElements) => workingElements.filter((wcs) => wcs.key !== key));
        }}
      />
      <ExpressionBuilder
        id={`editConditionalStylesTab${key}`}
        expression={condition ?? null}
        setExpression={(expression) => {
          setWorkingElements((workingElements) =>
            workingElements.map((wcs) =>
              wcs.key === key
                ? {
                    ...wcs,
                    condition: expression,
                  }
                : wcs,
            ),
          );
        }}
        validationMessages={[]}
        types={ExpressionType.Boolean}
      />
      <StylesEditor
        styles={styles}
        setStyles={(s) => {
          setWorkingElements((workingElements) =>
            workingElements.map((wcs) =>
              wcs.key === key
                ? {
                    ...wcs,
                    styles: s(wcs.styles),
                  }
                : wcs,
            ),
          );
        }}
      />
    </div>
  );
};

export const ConditionalStylesTab: EditComponent = ({ component, eventHooks }) => {
  const [workingElementsRef, setWorkingElements] = useStateRef<WorkingConditionAndStyles[]>(
    () => component.conditionalStyles ?? [],
  );

  useEffect(() => {
    const updateCheckoutOptions: ComponentEditorListener = (component) => {
      // This type check is just to satisfy TypeScript.
      if (isComponentWithConditionalStyles(component)) {
        const newConditionalStyles: ConditionAndStyles[] = [];
        // A mapping from element key to validation error message.
        const validationErrors: Record<string, string> = {};

        for (const { key, condition, styles } of workingElementsRef.current) {
          if (condition && isCompleteExpression(condition)) {
            if (isBooleanExpression(condition)) {
              newConditionalStyles.push({
                key,
                condition,
                styles,
              });
            } else {
              validationErrors[key] = 'The condition must be a boolean expression';
            }
          } else {
            validationErrors[key] = 'The condition must be completed if it is enabled';
          }
        }

        if (isEmpty(validationErrors)) {
          return {
            action: 'continue',
            component: {
              ...component,
              conditionalStyles: newConditionalStyles,
            } satisfies ConditionalStyles,
          };
        }

        return {
          action: 'prevent',
          errorMessage: `Please correct the following errors: ${Object.values(
            validationErrors,
          ).join(', ')}`,
        };
      }
      return { action: 'continue' };
    };

    eventHooks.onBeforeLeave(updateCheckoutOptions);

    return () => {
      eventHooks.offBeforeLeave(updateCheckoutOptions);
    };
  }, [eventHooks, workingElementsRef]);

  return (
    <Fragment>
      <div className="flex flex-column gap-3">
        {workingElementsRef.current.map((element) => (
          <EditWorkingConditionAndStyles
            key={element.key}
            element={element}
            setWorkingElements={setWorkingElements}
          />
        ))}
        <Button
          label="Add conditional styling"
          className="w-max"
          onClick={() => {
            setWorkingElements((workingElements) => [
              ...workingElements,
              {
                // Clean keys so that they can be used in HTML attributes.
                key: cleanSmallId(),
                condition: null,
                styles: {},
              },
            ]);
          }}
        />
      </div>
    </Fragment>
  );
};
