import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../contexts/ToastContext.js';
import { ComponentNamesDocument } from '../../../../hooks/use-component-names/queries.generated.js';
import {
  ComponentName,
  useComponentNames,
} from '../../../../hooks/use-component-names/use-component-names.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../hooks/useMenuSetup.js';
import { clickableRowClassName } from '../../../../util/clickableRowClassName.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { ThreeDotMenuButton } from '../../../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { CreateComponent } from './create-component/create-component.js';
import { DuplicateComponent } from './duplicate-component/duplicate-component.js';
import { ArchiveComponentDocument } from './mutations.generated.js';

export const Components: FunctionComponent = () => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { id: platformId, navigate } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { componentNames, loading } = useComponentNames({
    platformId,
    archived: false,
    onError,
    fetchPolicy: 'cache-and-network',
  });
  const [archiveComponent, { loading: archiveLoading }] = useMutation(ArchiveComponentDocument, {
    onError,
    onCompleted: (data) => {
      if (data.archiveComponent.__typename === 'ArchiveComponentSuccessResult') {
        toastSuccess('Component archived.');
      } else {
        onError(data.archiveComponent.message);
      }
    },
    refetchQueries: [ComponentNamesDocument],
    optimisticResponse: (variables) => {
      return {
        archiveComponent: {
          __typename: 'ArchiveComponentSuccessResult',
          component: {
            __typename: 'Component',
            id: variables.id,
            archived: true,
          },
        },
      } as const;
    },
  });
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<ComponentName>();
  const [showCreate, setShowCreate] = useState(false);

  return (
    <PageContainer title="Components">
      <ErrorMessage />
      {showCreate ? (
        <CreateComponent
          className="mt-1 mb-4"
          onCancel={() => {
            setShowCreate(false);
          }}
        />
      ) : (
        <Button
          label="Create component"
          className="mb-3"
          onClick={() => {
            setShowCreate(true);
          }}
        />
      )}
      <DataTable
        value={componentNames ?? []}
        loading={loading}
        rowClassName={clickableRowClassName}
        onRowClick={(e) => {
          navigate(`/components/${(e.data as ComponentName).id}`);
        }}
      >
        <Column field="name" header="Name" />
        <Column
          header=""
          align="right"
          body={(row: ComponentName) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            command: () => {
              if (menuRow) {
                dialog({
                  content: (
                    <DuplicateComponent
                      id={menuRow.id}
                      onDuplicated={(duplicateComponent) => {
                        dialog(null);
                        toastSuccess('Component duplicated.');
                        navigate(`/components/${duplicateComponent.id}`);
                      }}
                    />
                  ),
                  props: {
                    header: `Duplicate the “${menuRow.name}” component`,
                  },
                });
              }
            },
          },
          {
            label: 'Archive',
            disabled: archiveLoading,
            command: () => {
              if (menuRow) {
                dialog({
                  confirm: `Are you sure you want to archive the “${menuRow.name}” component?`,
                  props: {
                    onAccept: () => {
                      void archiveComponent({
                        variables: { id: menuRow.id },
                      });
                    },
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
