/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type EditPageMetadataMutationVariables = Types.Exact<{
  pageId: Types.Scalars['String']['input'];
  path: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
  metaTitle?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type EditPageMetadataMutation = {
  editPageMetadata: {
    __typename: 'Page';
    id: string;
    path: string;
    title: string;
    metaTitle?: string | null;
  };
};

export type EditPagePropsMutationVariables = Types.Exact<{
  pageId: Types.Scalars['String']['input'];
  props: Types.Scalars['String']['input'];
}>;

export type EditPagePropsMutation = {
  editPageProps: { __typename: 'Page'; id: string; props?: string | null };
};

export type EditPageStatusMutationVariables = Types.Exact<{
  pageId: Types.Scalars['String']['input'];
  status: Types.PublishStatus;
}>;

export type EditPageStatusMutation = {
  editPageStatus: { __typename: 'Page'; id: string; status: Types.PublishStatus };
};

export type CreatePageFromTemplateMutationVariables = Types.Exact<{
  siteId: Types.Scalars['String']['input'];
  templateId: Types.Scalars['String']['input'];
  props?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreatePageFromTemplateMutation = {
  createPageFromTemplate: {
    __typename: 'Page';
    id: string;
    path: string;
    title: string;
    status: Types.PublishStatus;
    props?: string | null;
  };
};

export const EditPageMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPageMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metaTitle' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPageMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metaTitle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metaTitle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPageMetadataMutation, EditPageMetadataMutationVariables>;
export const EditPagePropsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPageProps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'props' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPageProps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'props' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'props' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'props' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPagePropsMutation, EditPagePropsMutationVariables>;
export const EditPageStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditPageStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PublishStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPageStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPageStatusMutation, EditPageStatusMutationVariables>;
export const CreatePageFromTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePageFromTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'props' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPageFromTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'props' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'props' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'props' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePageFromTemplateMutation,
  CreatePageFromTemplateMutationVariables
>;
