import { useApolloClient } from '@apollo/client';
import {
  Component,
  ComponentType,
  TextInputComponentFormat,
} from '@wirechunk/lib/mixer/types/components.js';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.js';
import { compact } from 'lodash-es';
import { MutableRefObject, useEffect, useState } from 'react';
import { extractValidInputComponents } from '../../../../../../util/mixer/util.js';

export enum PropType {
  Boolean = 'Boolean',
  Float = 'Float',
  String = 'String',
  Url = 'Url',
}

export type PropSpec = {
  name: string;
  type: PropType;
};

const inputComponentToPropSpecInput = (component: ValidInputComponent): PropSpec | null => {
  const { name } = component;
  switch (component.type) {
    case ComponentType.BooleanInput:
      return {
        name,
        type: PropType.Boolean,
      };
    case ComponentType.NumberInput:
      return {
        name,
        type: PropType.Float,
      };
    case ComponentType.TextInput:
      return {
        name,
        type: component.format === TextInputComponentFormat.Url ? PropType.Url : PropType.String,
      };
    case ComponentType.DropdownInput:
    case ComponentType.TextareaInput:
      return {
        name,
        type: PropType.String,
      };
    default:
      return null;
  }
};

type PropSpecs = PropSpec[];

export const usePropSpecs = (propsSetupComponentsRef: MutableRefObject<Component[]>): PropSpecs => {
  const apolloClient = useApolloClient();
  const [propSpecs, setPropSpecs] = useState<PropSpec[]>([]);

  // Save a local constant with the propsSetupComponents array that we're going to use to extract input components.
  const propsSetupComponents = propsSetupComponentsRef.current;
  useEffect(() => {
    void (async () => {
      const inputComponents = await extractValidInputComponents(propsSetupComponents, apolloClient);
      // Now that we've awaited the call above (and the props setup components may have changed), check if we are still
      // dealing with the same props setup components so that our input components are valid.
      if (propsSetupComponentsRef.current === propsSetupComponents) {
        setPropSpecs(
          compact([...inputComponents.values()].map(inputComponentToPropSpecInput)).sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
        );
      }
    })();
  }, [apolloClient, propsSetupComponents, propsSetupComponentsRef]);

  return propSpecs;
};
