import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.js';
import { BooleanValueSource, DataSource } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import { CheckboxWithLabel } from '../../../checkbox-with-label/checkbox-with-label.js';
import { DataSourceDropdown } from '../../components/data-source-dropdown.js';
import type { EditComponent } from '../../editComponentByType.js';

export const DefaultBooleanValueTab: EditComponent = ({
  component: { defaultValue },
  setComponent,
}) => {
  return (
    <Fragment>
      <DataSourceDropdown
        parentId="defaultBooleanValueTab"
        label="Value source"
        type={defaultValue?.type || DataSource.Direct}
        onChange={(type) => {
          setComponent((c) => ({
            ...c,
            defaultValue:
              type === DataSource.Direct
                ? ({ type, value: false } satisfies BooleanValueSource)
                : ({ type, name: '' } satisfies BooleanValueSource),
          }));
        }}
      />
      {!defaultValue || defaultValue.type === DataSource.Direct ? (
        <CheckboxWithLabel
          id="defaultBooleanValueTabValue"
          label="Default value"
          checked={booleanOrDefaultFalse(defaultValue?.value)}
          onChange={({ checked }) => {
            setComponent((c) => ({
              ...c,
              defaultValue: {
                ...c.defaultValue,
                type: DataSource.Direct,
                value: checked,
              } satisfies BooleanValueSource,
            }));
          }}
        />
      ) : (
        <div className="input-field">
          <label htmlFor="defaultBooleanValueTabPropName">Prop name</label>
          <InputText
            id="defaultBooleanValueTabPropName"
            className="w-full"
            value={defaultValue.name || ''}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                defaultValue: {
                  ...c.defaultValue,
                  type: DataSource.Prop,
                  name: e.target.value,
                } satisfies BooleanValueSource,
              }));
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
