import { cleanSmallId } from '@wirechunk/lib/clean-small-id';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { DataInputTableComponent } from '@wirechunk/lib/mixer/types/components.js';
import {
  dataTableRowArrayOrDefaultEmpty,
  ValidInputComponent,
} from '@wirechunk/lib/mixer/utils.js';
import type { DataTableRow } from '@wirechunk/schemas/context-data/context-data';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.js';
import { RepeatedFormFragmentContextProvider } from '../../../contexts/repeated-form-fragment-context.js';
import { useMenuSetup } from '../../../hooks/useMenuSetup.js';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.js';
import { RenderComponent } from '../../RenderComponent.js';
import styles from './DataInputTable.module.css';

const deleteButtonColumnWidth = '25px';

const GuardedDataInputTable: FunctionComponent<ValidInputComponent<DataInputTableComponent>> = (
  props,
) => {
  const inputDataContext = useInputDataContext(props);
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<DataTableRow>();

  const { getValue, setValue } = inputDataContext;

  const rawValue = getValue(props);

  const { inputValue, inputValueWithPlaceholder, placeholderRowId } = useMemo<{
    inputValue: DataTableRow[];
    inputValueWithPlaceholder: DataTableRow[];
    placeholderRowId: string;
  }>(() => {
    const inputValue = dataTableRowArrayOrDefaultEmpty(rawValue);
    const placeholderRowId = cleanSmallId();
    return {
      inputValue,
      inputValueWithPlaceholder: [...inputValue, { id: placeholderRowId, data: {} }],
      placeholderRowId,
    };
  }, [rawValue]);

  const columnWidths =
    props.children?.map((c) => c.dataInputTableColumnOptions?.width || '1fr') || [];
  columnWidths.push(deleteButtonColumnWidth);

  const gridTemplateColumns = columnWidths.join(' ');

  return (
    <Fragment>
      <div className={componentClassName(props)}>
        <div className={styles.row} style={{ gridTemplateColumns }}>
          {props.children?.map((component, i) => (
            <div
              key={component.id}
              className={clsx(
                component.dataInputTableColumnOptions?.className,
                'flex justify-content-center align-items-center border-top-1 border-right-1 border-bottom-2 font-medium',
                i === 0 && 'border-left-1',
              )}
            >
              {component.dataInputTableColumnOptions?.header}
            </div>
          ))}
          <div />
        </div>
        {inputValueWithPlaceholder.map((row) => (
          <RepeatedFormFragmentContextProvider
            key={row.id}
            id={row.id}
            emptyPlaceholder={row.id === placeholderRowId}
            inputDataContext={inputDataContext}
            parent={props}
          >
            <div className={styles.row} style={{ gridTemplateColumns }}>
              {props.children?.map((component, i) => (
                <div
                  key={component.id}
                  className={clsx(
                    component.dataInputTableColumnOptions?.className,
                    'border-bottom-1 border-right-1',
                    i === 0 && 'border-left-1',
                  )}
                >
                  <RenderComponent {...component} />
                </div>
              ))}
              <div className="flex align-items-center">
                {!props.readonly && row.id !== placeholderRowId && (
                  <button
                    type="button"
                    className={`${styles.onlyOnRowHover} button-not-styled p-1 text-color-muted hover:text-color-body`}
                    onClick={onSelectMenuRow(row)}
                  >
                    <i className={PrimeIcons.TRASH} />
                  </button>
                )}
              </div>
            </div>
          </RepeatedFormFragmentContextProvider>
        ))}
      </div>
      <Menu
        ref={menu}
        // overflow-hidden because of rounded corners and gray background on hover.
        className="py-0 overflow-hidden"
        popup
        model={[
          {
            label: 'Confirm delete',
            command: () => {
              if (menuRow) {
                setValue(
                  props,
                  inputValue.filter((row) => row.id !== menuRow.id),
                );
              }
            },
          },
        ]}
      />
    </Fragment>
  );
};

export const DataInputTable =
  withValidInputComponent<DataInputTableComponent>(GuardedDataInputTable);
