import { useMutation, useQuery } from '@apollo/client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useState } from 'react';
import { MeDocument } from '../../../contexts/CurrentUserContext/queries.generated.js';
import { useToast } from '../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { ChangePasswordDocument } from './mutations.generated.js';

export const UserProfile: FunctionComponent<ComponentSpec<ComponentType.UserProfile>> = (props) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(MeDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
  });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [changePassword, { loading: isChangingPassword }] = useMutation(ChangePasswordDocument, {
    onError,
  });

  const onSubmit = () => {
    clearMessages();
    if (newPassword1 !== newPassword2) {
      onError('The new passwords you entered do not match.');
      return;
    }
    void changePassword({
      variables: {
        currentPassword,
        newPassword: newPassword1,
      },
      onCompleted: () => {
        toastSuccess('Your password has been changed.');
        setCurrentPassword('');
        setNewPassword1('');
        setNewPassword2('');
      },
    });
  };

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {loading || isChangingPassword ? (
        <div className="pt-8 pb-2 text-center">
          <i className="pi pi-spinner pi-spin text-lg" />
        </div>
      ) : (
        data?.me && (
          <Fragment>
            <p>{data.me.firstName}, here is where you can change your password.</p>
            <div className="input-field">
              <label htmlFor="profileCurrentPassword">Current password</label>
              <InputText
                id="profileCurrentPassword"
                className="w-full max-w-24rem"
                type="password"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                }}
              />
            </div>
            <div className="input-field">
              <label htmlFor="profileNewPassword1">New password</label>
              <InputText
                id="profileNewPassword1"
                className="w-full max-w-24rem"
                type="password"
                value={newPassword1}
                onChange={(e) => {
                  setNewPassword1(e.target.value);
                }}
              />
            </div>
            <div className="input-field">
              <label htmlFor="profileNewPassword2">Confirm new password</label>
              <InputText
                id="profileNewPassword2"
                className="w-full max-w-24rem"
                type="password"
                value={newPassword2}
                onChange={(e) => {
                  setNewPassword2(e.target.value);
                }}
              />
            </div>
            <Button
              label="Change password"
              className="mt-4"
              disabled={isChangingPassword}
              onClick={onSubmit}
            />
          </Fragment>
        )
      )}
    </div>
  );
};
