import { FunctionComponent, PropsWithChildren } from 'react';
import { usePlatformContext } from '../../../../../../../contexts/admin/platform-context/platform-context.js';
import { Breadcrumbs } from '../../../../../../breadcrumbs.js';
import { NavTabs, NavTabsProps } from '../../../../../../nav-tabs.js';

const tabs = ['', 'steps', 'confirmation-action', 'notifications', 'entries'] as const;

export type FormTab = (typeof tabs)[number];

export const activeTab = (formId: string, pathname: string): FormTab => {
  if (pathname.includes(`/${formId}/confirmation-action`)) {
    return 'confirmation-action';
  }
  if (pathname.includes(`/${formId}/notifications`)) {
    return 'notifications';
  }
  if (pathname.includes(`/${formId}/steps`)) {
    return 'steps';
  }
  if (pathname.includes(`/${formId}/entries`)) {
    return 'entries';
  }
  return '';
};

const tabHeader = (tab: FormTab) => {
  switch (tab) {
    case '':
      return 'Design';
    case 'confirmation-action':
      return 'Confirmation action';
    case 'steps':
      return 'Steps';
    case 'notifications':
      return 'Notifications';
    case 'entries':
      return 'Entries';
  }
};

type FormTabsProps = PropsWithChildren<
  Pick<NavTabsProps<FormTab>, 'panelClassName'> & {
    siteId: string;
    formId: string;
    activeTab: FormTab;
  }
>;

export const FormTabs: FunctionComponent<FormTabsProps> = ({
  siteId,
  formId,
  activeTab,
  children,
}) => {
  const { handle } = usePlatformContext();

  return (
    <NavTabs
      rootPath={`/dashboard/${handle}/sites/${siteId}/forms/${formId}`}
      tabs={tabs}
      activeTab={activeTab}
      tabHeader={tabHeader}
    >
      {children}
    </NavTabs>
  );
};

type FormBreadcrumbsProps = {
  className?: string;
  site: { id: string; name: string };
  form:
    | {
        title: string;
      }
    | undefined;
};

export const FormBreadcrumbs: FunctionComponent<FormBreadcrumbsProps> = ({
  className,
  site,
  form,
}) => {
  const { handle } = usePlatformContext();

  return (
    <Breadcrumbs
      className={className}
      items={[
        {
          label: 'Sites',
          path: `/dashboard/${handle}/sites`,
        },
        {
          label: site.name,
          // TODO: Add a pages at .../:siteId to display the site's overview.
          path: `/dashboard/${handle}/sites/${site.id}/pages`,
        },
        {
          label: 'Forms',
          path: `/dashboard/${handle}/sites/${site.id}/forms`,
        },
        ...(form
          ? [
              {
                label: form.title,
              },
            ]
          : []),
      ]}
    />
  );
};
