import { useMutation } from '@apollo/client';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { CreateFormTemplateDocument } from './mutations.generated.js';

type CreateFormTemplateProps = {
  platformId: string;
  className?: string;
  onCreated: (id: string) => void;
  onCancel: () => void;
};

export const CreateFormTemplate: FunctionComponent<CreateFormTemplateProps> = ({
  platformId,
  className,
  onCreated,
  onCancel,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [title, setTitle] = useState('');
  const [createFormTemplate, { loading: creating }] = useMutation(CreateFormTemplateDocument, {
    onError,
    onCompleted: (data) => {
      onCreated(data.createFormTemplate.id);
    },
  });

  return (
    <div className={clsx('border-1 border-round p-4', className)}>
      <h3 className="mt-1">Create Form Template</h3>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          className="w-18rem"
          autoFocus
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-3 mt-3">
        <Button
          label="Create"
          disabled={creating || !title.trim()}
          onClick={() => {
            clearMessages();
            void createFormTemplate({
              variables: {
                platformId,
                title,
              },
            });
          }}
        />
        <Button
          className="p-button-secondary"
          label="Cancel"
          disabled={creating}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
