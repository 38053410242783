import { isString, noop } from 'lodash-es';
import { StyleAttributor } from 'parchment';
import Quill, { Parchment, QuillOptions } from 'quill';
import type Delta from 'quill-delta';
import { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';
import { DeltaData, tryParseDelta } from '../util/delta.js';

const fontSizes = [10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 30, 36, 42, 54, 72, 84];
const fontSizesPx = fontSizes.map((size) => `${size}px`);

const SizeStyle = new StyleAttributor('size', 'font-size', {
  scope: Parchment.Scope.INLINE,
  whitelist: fontSizesPx,
});

Quill.register(SizeStyle, true);

const modules: QuillOptions['modules'] = {
  toolbar: [
    [
      { header: [1, 2, 3, 4, false] },
      { size: [false, ...fontSizesPx] },
      { align: [false, 'right', 'center', 'justify'] },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['blockquote', 'code-block'],
    ['bold', 'italic', 'underline', 'strike', 'code', 'link'],
    ['clean'],
  ],
};

const formats: QuillOptions['formats'] = [
  'align',
  'blockquote',
  'bold',
  'code',
  'code-block',
  'header',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'underline',
];

const fullHeightStyle: CSSProperties = {
  // The Quill toolbar is 42px tall.
  height: 'calc(100% - 42px)',
};

export type QuillEditorProps = {
  maxLength?: number;
  initialValue?: string | DeltaData;
  onChange: (delta: Delta) => void;
  fullHeight?: boolean;
};

export const QuillEditor: FunctionComponent<QuillEditorProps> = ({
  maxLength,
  initialValue,
  onChange,
  fullHeight = false,
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const editor = useRef<Quill | null>(null);
  const [length, setLength] = useState<number>(0);
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const { current: wrapperDiv } = wrapper;
    if (wrapperDiv && !editor.current) {
      const ed = new Quill(wrapperDiv, {
        theme: 'snow',
        formats,
        modules,
      });
      if (initialValue) {
        if (isString(initialValue)) {
          ed.setContents(tryParseDelta(initialValue));
        } else {
          ed.setContents(initialValue.ops);
        }
      }
      const innerOnChange = () => {
        setLength(ed.getLength() - 1);
        onChangeRef.current(ed.getContents());
      };
      ed.on('text-change', innerOnChange);
      editor.current = ed;
    }
    return noop;
  }, [initialValue]);

  return (
    <div className="relative" style={fullHeight ? fullHeightStyle : undefined}>
      <div ref={wrapper} />
      {!!maxLength && (
        <div
          className="text-xs"
          style={{
            position: 'absolute',
            right: '6px',
            bottom: '2px',
            color: length <= maxLength ? undefined : 'var(--red-600)',
          }}
        >
          Characters: {length}/{maxLength}
        </div>
      )}
    </div>
  );
};
