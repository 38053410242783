import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { useTableRowExpansion } from '../../../../hooks/useTableRowExpansion.js';
import { OrganizationDetails } from '../../../OrganizationDetails/OrganizationDetails.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { OrganizationsDocument, OrganizationsQuery } from './queries.generated.js';

// The server always applies a limit of 10 to queries.
const rowLimit = 10;

type OrgRow = OrganizationsQuery['organizations']['organizations'][number];

const rowExpansionTemplate = (row: OrgRow) => (
  <Fragment>
    <h3 className="text-lg mb-2 mt-1">Org details</h3>
    <OrganizationDetails organizationId={row.id} />
  </Fragment>
);

export const Organizations: FunctionComponent = () => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { id: platformId } = usePlatformContext();

  const { expandedRows, onRowToggle } = useTableRowExpansion();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const searchInput = useRef<HTMLInputElement>(null);
  const lastSearchValue = useRef<string>(search);

  // Update the search state variable on an interval.
  useEffect(() => {
    const interval = setInterval(() => {
      const { current: input } = searchInput;
      if (input && input.value !== lastSearchValue.current) {
        // If the value is just spaces, clear search.
        const newValue = input.value.trim() ? input.value : '';
        setSearch(newValue);
        lastSearchValue.current = newValue;
        setPage(0);
      }
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { data, loading: isLoading } = useQuery(OrganizationsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      search,
      limit: rowLimit,
      page,
    },
  });

  return (
    <PageContainer title="Orgs">
      <ErrorMessage />
      <div className="surface-ground p-3 flex flex-column lg:flex-row lg:justify-content-start lg:align-items-center gap-3">
        <input
          type="text"
          className="p-inputtext p-component w-full lg:w-20rem"
          placeholder="Search&hellip;"
          ref={searchInput}
        />
      </div>
      <DataTable
        dataKey="id"
        value={data?.organizations.organizations || []}
        loading={isLoading}
        rows={rowLimit}
        first={page * rowLimit}
        totalRecords={data?.organizations.totalCount || 0}
        onPage={(event) => {
          setPage(event.page || 0);
        }}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={onRowToggle}
        paginator
        lazy
      >
        <Column expander className="table-left-action-column" />
        <Column
          header="Primary User"
          body={(row: OrgRow) => (
            <span>{row.primaryUser ? row.primaryUser.displayName : '(No primary user)'}</span>
          )}
        />
        <Column header="Total members" body={(row: OrgRow) => row.members.length} />
      </DataTable>
    </PageContainer>
  );
};
