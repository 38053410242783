import { FormConfirmationRedirectUrlParameterInput } from '@wirechunk/lib/api.js';
import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import { ValidInputComponent } from '@wirechunk/lib/mixer/utils.js';
import { isString } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import type { SelectItem } from '../../../../../../../types.js';
import { BasicIconButton } from '../../../../../../BasicIconButton/BasicIconButton.js';

type EditUrlParametersProps = {
  param: FormConfirmationRedirectUrlParameterInput;
  setConfirmationRedirectUrlParameters: Dispatch<
    SetStateAction<FormConfirmationRedirectUrlParameterInput[]>
  >;
  inputComponents: Map<string, ValidInputComponent>;
  loadingInputComponentNames: boolean;
  triggerHasUnsavedChanges: () => void;
};

export const EditUrlParameters: FunctionComponent<EditUrlParametersProps> = ({
  param: { id, parameter, componentName },
  setConfirmationRedirectUrlParameters,
  inputComponents,
  loadingInputComponentNames,
  triggerHasUnsavedChanges,
}) => {
  const options = useMemo<Array<SelectItem<string>>>(
    () =>
      Array.from(inputComponents.entries())
        .map(([name, { type }]) => ({
          label: `${name} (${componentTypeHumanReadable(type)})`,
          value: name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [inputComponents],
  );

  return (
    <div className="flex align-items-center gap-3">
      <div className="input-field w-30rem max-w-full">
        <label htmlFor={`formConfirmationRedirectUrlParametersParam${id}`}>Parameter</label>
        <InputText
          id={`formConfirmationRedirectUrlParametersParam${id}`}
          className="w-full"
          value={parameter}
          onChange={({ target }) => {
            setConfirmationRedirectUrlParameters((params) =>
              params.map((param) =>
                param.id === id
                  ? {
                      ...param,
                      parameter: target.value,
                    }
                  : param,
              ),
            );
            triggerHasUnsavedChanges();
          }}
        />
      </div>
      <div className="input-field w-30rem max-w-full">
        <label htmlFor={`formConfirmationRedirectUrlParametersComponentName${id}`}>Component</label>
        <Dropdown
          filter
          inputId={`formConfirmationRedirectUrlParametersComponentName${id}`}
          className="w-full"
          disabled={loadingInputComponentNames}
          value={componentName}
          options={options}
          onChange={({ value }) => {
            if (isString(value)) {
              setConfirmationRedirectUrlParameters((params) =>
                params.map((param) =>
                  param.id === id
                    ? {
                        ...param,
                        componentName: value,
                      }
                    : param,
                ),
              );
              triggerHasUnsavedChanges();
            }
          }}
        />
      </div>
      <BasicIconButton
        onClick={() => {
          setConfirmationRedirectUrlParameters((params) =>
            params.filter((param) => param.id !== id),
          );
          triggerHasUnsavedChanges();
        }}
        icon={PrimeIcons.TRASH}
      />
    </div>
  );
};
