import { HTMLComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

export const EditHTML: FunctionComponent<EditComponentContentProps<HTMLComponent>> = ({
  component,
  setComponent,
}) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editHTMLHTML">HTML</label>
      <InputTextarea
        id="editHTMLHTML"
        className="w-full h-30rem"
        value={component.html || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, html: e.target.value }));
        }}
      />
    </div>
  </Fragment>
);
