/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type CreateHelpTicketMutationVariables = Types.Exact<{
  type: Types.Scalars['String']['input'];
  message: Types.Scalars['String']['input'];
  customDomain?: Types.InputMaybe<Types.Scalars['String']['input']>;
  customSiteHeaderCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  customSiteBodyStartCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  customSiteBodyEndCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateHelpTicketMutation = {
  createHelpTicket: {
    __typename: 'HelpTicket';
    id: string;
    type: string;
    status: Types.HelpTicketStatus;
    message: string;
    customDomain?: string | null;
    customSiteHeaderCode?: string | null;
    customSiteBodyStartCode?: string | null;
    customSiteBodyEndCode?: string | null;
    createdAt: string;
  };
};

export const CreateHelpTicketDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHelpTicket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customDomain' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customSiteHeaderCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customSiteBodyStartCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customSiteBodyEndCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHelpTicket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customDomain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customDomain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customSiteHeaderCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customSiteHeaderCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customSiteBodyStartCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customSiteBodyStartCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customSiteBodyEndCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customSiteBodyEndCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customDomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customSiteHeaderCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customSiteBodyStartCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customSiteBodyEndCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateHelpTicketMutation, CreateHelpTicketMutationVariables>;
