import { noop } from 'lodash-es';
import type { Dict, Mixpanel } from 'mixpanel-browser';
import { init as initMixpanel } from 'mixpanel-browser';
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { environment } from '../../env.js';
import { useOnMount } from '../../hooks/useOnMount.js';
import { useOptionalCurrentUser } from '../CurrentUserContext/CurrentUserContext.js';
import { useSiteContext } from '../SiteContext/SiteContext.js';

const mixpanelInstanceName = 'site';

type ProductAnalyticsContext = {
  track: (event: string, properties?: Dict | null, sendImmediately?: boolean) => void;
  reset: () => void;
};

const context = createContext<ProductAnalyticsContext>({
  track: noop,
  reset: noop,
});

export const ProductAnalyticsContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { mixpanelToken } = useSiteContext();
  const { user } = useOptionalCurrentUser();
  const mixpanelInstance = useRef<Mixpanel>();

  const organizationId = user?.organization.id || null;

  const track = useCallback<ProductAnalyticsContext['track']>(
    (event, properties = {}, sendImmediately) => {
      mixpanelInstance.current?.track(
        event,
        {
          organizationId,
          ...(properties || {}),
        },
        sendImmediately ? { send_immediately: true } : undefined,
      );
    },
    [organizationId],
  );

  const value = useMemo<ProductAnalyticsContext>(
    () => ({
      track,
      reset: () => mixpanelInstance.current?.reset(),
    }),
    [track],
  );

  // We don't have a useEffect with mixpanelToken listed as a dependency because the token should not change.
  useOnMount(() => {
    if (mixpanelToken) {
      mixpanelInstance.current = initMixpanel(
        mixpanelToken,
        {
          ...(environment === 'production' ? {} : { debug: true }),
        },
        mixpanelInstanceName,
      );
    }
  });

  useEffect(() => {
    if (user) {
      mixpanelInstance.current?.identify(user.id);
    }
  }, [user]);

  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useProductAnalytics = (): ProductAnalyticsContext => useContext(context);
