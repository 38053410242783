import type { ApolloQueryResult } from '@apollo/client';
import { ComponentType, createContext, FunctionComponent, useContext } from 'react';
import type { EditSiteContext } from '../../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import type { FormContext } from '../../../../../../../contexts/FormContext/form-context.js';
import type { FormQuery } from './queries.generated.js';

export type EditFormContext = {
  form: FormQuery['form'];
  formContext: FormContext;
  site: EditSiteContext;
  refetchForm: () => Promise<ApolloQueryResult<FormQuery>>;
};

const context = createContext<EditFormContext | null>(null);

export const EditFormContextProvider = context.Provider;

export const useEditFormContext = (): EditFormContext | null => useContext(context);

export const withEditFormContext =
  <P,>(Component: ComponentType<P & EditFormContext>): FunctionComponent<P> =>
  (props: P) => {
    const value = useEditFormContext();
    if (!value) {
      throw new Error('No EditFormContext value is available');
    }
    return <Component {...props} {...value} />;
  };
