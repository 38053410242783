/// <reference path="./window.d.ts" />
import { ApolloProvider } from '@apollo/client';
import { Theme } from '@radix-ui/themes';
import type { FunctionComponent } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { apolloClient } from './apollo-client.js';
import { AuthenticatedLayout } from './components/AuthenticatedLayout.js';
import { CurrentSiteProvider } from './components/CurrentSiteProvider/CurrentSiteProvider.js';
import { PublicRoutesProvider } from './components/PublicRoutesProvider/PublicRoutesProvider.js';
import { RootErrorBoundary } from './components/RootErrorBoundary/RootErrorBoundary.js';
import { SessionUserProvider } from './components/SessionUserProvider.js';
import { StripeElementsProvider } from './components/StripeElementsProvider.js';
import { DialogProvider } from './contexts/DialogContext/DialogContext.js';
import { ErrorCollectorContextProvider } from './contexts/error-collector-context.js';
import { ProductAnalyticsContextProvider } from './contexts/ProductAnalyticsContext/ProductAnalyticsContext.js';
import { ToastProvider } from './contexts/ToastContext.js';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'quill/dist/quill.snow.css';
import '@radix-ui/themes/styles.css';
import './css/theme.css';
import './css/index.css';

const { admin, themeColor } = window.wirechunk;

export const App: FunctionComponent = () => (
  <RootErrorBoundary>
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <Theme accentColor={themeColor}>
            <ToastProvider>
              <DialogProvider>
                <ErrorCollectorContextProvider>
                  <CurrentSiteProvider>
                    <StripeElementsProvider>
                      <SessionUserProvider>
                        <ProductAnalyticsContextProvider>
                          <PublicRoutesProvider>
                            <AuthenticatedLayout adminSite={admin} />
                          </PublicRoutesProvider>
                        </ProductAnalyticsContextProvider>
                      </SessionUserProvider>
                    </StripeElementsProvider>
                  </CurrentSiteProvider>
                </ErrorCollectorContextProvider>
              </DialogProvider>
            </ToastProvider>
          </Theme>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  </RootErrorBoundary>
);
