import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const OrganizationAgentFullName: FunctionComponent<Component> = (props) => {
  const { agentFirstName, agentLastName } = useSiteContext();

  let name = agentFirstName || '';
  if (agentLastName) {
    if (name) {
      name += ' ';
    }
    name += agentLastName;
  }

  return <span className={componentClassName(props)}>{name}</span>;
};
