import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { isNumber, isString } from 'lodash-es';
import { isComponentWithRequired } from './types/categories.js';
import { ComponentType, NumberInputFormat } from './types/components.js';
import type { ValidInputComponent } from './utils.js';

const requiredMessage = 'This field is required.';

export type ValidationErrors = Record<string, string>;

export const validateData = (
  inputComponents: Map<string, ValidInputComponent>,
  data: ContextData,
): ValidationErrors =>
  [...inputComponents.values()].reduce<ValidationErrors>((acc, component) => {
    if (!isComponentWithRequired(component) || !component.required) {
      return acc;
    }

    const value = data[component.name];

    let msg: string | null = null;
    switch (component.type) {
      case ComponentType.TextInput:
      case ComponentType.TextareaInput:
        if (!isString(value) || !value.trim()) {
          msg = requiredMessage;
        }
        break;
      case ComponentType.DateInput:
        if (!value) {
          msg = requiredMessage;
        } else if (!isString(value)) {
          // This should not happen.
          msg = 'This field must be a date.';
        } else {
          // This is an if-else-if because we want to display one message for the field at a time.
          const valueDateUnix = new Date(value).getTime();
          if (component.minimumDate) {
            if (component.minimumDate.type === 'today') {
              const now = new Date().getTime();
              if (now > valueDateUnix) {
                msg = 'This date must be today or later.';
              }
            } else {
              const minimumDate = new Date(component.minimumDate.value).getTime();
              if (!isNaN(minimumDate) && minimumDate > valueDateUnix) {
                msg = `This date must be on or after ${component.minimumDate.value}.`;
              }
            }
          } else if (component.maximumDate) {
            if (component.maximumDate.type === 'today') {
              const now = new Date().getTime();
              if (now < valueDateUnix) {
                msg = 'This date must be today or earlier.';
              }
            } else {
              const maximumDate = new Date(component.maximumDate.value).getTime();
              if (!isNaN(maximumDate) && maximumDate < valueDateUnix) {
                msg = `This date must be on or before ${component.maximumDate.value}.`;
              }
            }
          }
        }
        break;
      case ComponentType.NumberInput:
        // Possible states: number | null | '-'
        if (value === null) {
          msg = requiredMessage;
        } else if (component.format === NumberInputFormat.Integer) {
          if (!isNumber(value) || !Number.isInteger(value)) {
            msg = 'This field must be an integer.';
          }
        } else if (!isNumber(value)) {
          msg = 'This field must be a number.';
        }
        break;
      case ComponentType.BooleanInput:
      case ComponentType.CompletableVideoInput:
      case ComponentType.DropdownInput:
        if (!value) {
          msg = requiredMessage;
        }
        break;
      case ComponentType.RadioGroupInput:
        if (!isString(value)) {
          msg = requiredMessage;
        }
        break;
    }

    if (msg) {
      return { ...acc, [component.name]: msg };
    }
    return acc;
  }, {});
