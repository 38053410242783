import { useMutation } from '@apollo/client';
import { PublishStatus } from '@wirechunk/lib/api.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { isPublishStatus } from '@wirechunk/lib/publish-status.js';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormContextProvider } from '../../../../../../../contexts/FormContext/form-context.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../contexts/PageContext/PageContext.js';
import { useToast } from '../../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../hooks/useHasUnsavedChanges.js';
import { useSiteContextSelector } from '../../../../../../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { SelectItem } from '../../../../../../../types.js';
import { publishStatusOptions } from '../../../../../../../util/publishStatus.js';
import { VisualBuilder } from '../../../../../../VisualBuilder/VisualBuilder.js';
import { withEditFormTemplateContext } from '../edit-form-template-context.js';
import { EditFormTemplateDocument } from '../mutations.generated.js';

const pageContext: PageContext = {
  title: '',
  viewMode: ViewMode.Preview,
};

export const Design = withEditFormTemplateContext(({ formTemplate, formContext }) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();

  const [editFormTemplate, { loading: saving }] = useMutation(EditFormTemplateDocument, {
    onError,
    onCompleted: () => {
      toastSuccess('Form template saved.');
      resetHasUnsavedChanges();
    },
  });

  const [title, setTitle] = useState('');
  const [status, setStatus] = useState<PublishStatus>(PublishStatus.Draft);
  const [components, setComponents] = useState<Component[]>([]);

  useEffect(() => {
    setComponents(parseComponents(formTemplate.components));
  }, [formTemplate.components]);

  const setComponentsWrapped = useCallback<Dispatch<SetStateAction<Component[]>>>(
    (components) => {
      setComponents(components);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  useEffect(() => {
    setTitle(formTemplate.title);
    setStatus(formTemplate.status);
  }, [formTemplate.title, formTemplate.status]);

  const siteContextSelector = useSiteContextSelector({ onError });

  return (
    <div className="mt-2">
      <ErrorMessage />
      <div className="flex flex-column gap-2 mb-2 md:flex-row md:justify-content-between md:align-items-start">
        <div>
          <div className="input-field">
            <label htmlFor="formTemplateTitle">Title</label>
            <InputText
              id="formTemplateTitle"
              className="w-30rem max-w-full"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                triggerHasUnsavedChanges();
              }}
            />
          </div>
          <div className="input-field mb-0">
            <Dropdown
              value={status}
              valueTemplate={(option: SelectItem<PublishStatus>) => (
                <span>
                  Status: <span className="font-medium">{option.value}</span>
                </span>
              )}
              options={publishStatusOptions}
              onChange={({ value }) => {
                if (isPublishStatus(value)) {
                  setStatus(value);
                  triggerHasUnsavedChanges();
                }
              }}
            />
          </div>
        </div>
        <Button
          className="p-button-success w-max"
          label="Save"
          disabled={!hasUnsavedChanges || saving}
          onClick={() => {
            clearMessages();
            void editFormTemplate({
              variables: {
                id: formTemplate.id,
                title,
                status,
                components: JSON.stringify(components),
              },
            });
          }}
        />
      </div>
      <VisualBuilder
        siteContext={siteContextSelector}
        components={components}
        setComponents={setComponentsWrapped}
        onPreview={(children) => (
          <PageContextProvider value={pageContext}>
            <FormContextProvider value={formContext}>{children}</FormContextProvider>
          </PageContextProvider>
        )}
      />
    </div>
  );
});
