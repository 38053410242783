import { useMutation, useQuery } from '@apollo/client';
import {
  ExpressionType,
  IncompleteExpression,
  isCompleteExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { BooleanExpression } from '@wirechunk/schemas/expressions/boolean-expression';
import type { Expression } from '@wirechunk/schemas/expressions/expression';
import { Button } from 'primereact/button';
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { ExpressionBuilder } from '../../../../ExpressionBuilder/ExpressionBuilder.js';
import { Spinner } from '../../../../Spinner.js';
import { EditStageBlueprintCompletionSpecDocument } from './mutations.generated.js';
import { StageBlueprintCompletionSpecDocument } from './queries.generated.js';

type CompletionSpecProps = {
  stageBlueprintId: string;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
};

export const CompletionSpec: FunctionComponent<CompletionSpecProps> = ({
  stageBlueprintId,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(StageBlueprintCompletionSpecDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { id: stageBlueprintId },
  });
  const [editCompletionSpec, { loading: isSavingEdits }] = useMutation(
    EditStageBlueprintCompletionSpecDocument,
    {
      onError,
    },
  );

  const [expression, setExpression] = useState<IncompleteExpression<Expression> | null>(null);

  useEffect(() => {
    if (data?.stageBlueprint.completionSpec) {
      setExpression(JSON.parse(data.stageBlueprint.completionSpec) as BooleanExpression);
    }
  }, [data?.stageBlueprint.completionSpec]);

  const [validationMessages, setValidationMessages] = useState<string[]>([]);

  const setExpressionWrapped = (expression: IncompleteExpression<Expression>) => {
    setExpression(expression);
    setHasUnsavedChanges(true);
  };

  const onSaveClick = () => {
    if (!expression || !isCompleteExpression(expression)) {
      setValidationMessages(['Please complete the expression to save it.']);
      return;
    }
    void editCompletionSpec({
      variables: {
        id: stageBlueprintId,
        completionSpec: JSON.stringify(expression),
      },
      onCompleted: () => {
        setHasUnsavedChanges(false);
      },
    });
  };

  return (
    <Fragment>
      <ErrorMessage />
      {data ? (
        <Fragment>
          <ExpressionBuilder
            id="completion-definition"
            className="mb-4"
            expression={expression}
            setExpression={setExpressionWrapped}
            validationMessages={validationMessages}
            types={ExpressionType.Boolean}
          />
          <Button
            label="Save"
            disabled={!hasUnsavedChanges || isSavingEdits}
            onClick={onSaveClick}
          />
        </Fragment>
      ) : (
        loading && <Spinner />
      )}
    </Fragment>
  );
};
