import { useQuery } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { isBoolean } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { SelectItem } from '../../../../types.js';
import { clickableRowClassName } from '../../../../util/clickableRowClassName.js';
import { BasicIconButton } from '../../../BasicIconButton/BasicIconButton.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { navItems } from './nav.js';
import { SitesDocument, SitesQuery } from './queries.generated.js';

const rowLimit = 10;

const siteTypeOptions: Array<SelectItem<boolean>> = [
  {
    label: 'My platform’s sites',
    value: true,
  },
  {
    label: 'Customer sites',
    value: false,
  },
];

type Site = SitesQuery['sites']['sites'][number];

export const Sites: FunctionComponent = () => {
  const { id: platformId, handle, permissions, navigate } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const [search, setSearch] = useState('');
  const canViewCustomerSites = permissions.includes(Permission.ViewCustomerSite);
  const canViewPlatformSites = permissions.includes(Permission.ViewPlatformSite);
  const [platformSites, setPlatformSites] = useState(canViewPlatformSites);
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery(SitesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      search,
      platformSites,
      page,
    },
  });

  return (
    <PageContainer title="Sites" nav={navItems(handle)}>
      <ErrorMessage />
      <div className="surface-ground p-3 flex flex-column lg:flex-row lg:justify-content-start lg:align-items-center gap-4">
        <InputText
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setPage(0);
          }}
          placeholder="Search"
        />
        <div className="flex align-items-center gap-2">
          <Dropdown
            className="w-18rem"
            options={siteTypeOptions}
            value={platformSites}
            disabled={!canViewCustomerSites || !canViewPlatformSites}
            valueTemplate={({ label }: SelectItem<boolean>) => (
              <span>
                Type: <span className="font-medium">{label}</span>
              </span>
            )}
            onChange={({ value }) => {
              if (isBoolean(value)) {
                setPlatformSites(value);
                setPage(0);
              }
            }}
          />
          <label htmlFor="onlyPlatformSites"></label>
        </div>
      </div>
      <DataTable
        dataKey="id"
        loading={loading}
        value={data?.sites.sites || []}
        rowClassName={clickableRowClassName}
        onRowClick={(e) => {
          navigate(`/sites/${(e.data as Site).id}/pages`);
        }}
        rows={rowLimit}
        first={page * rowLimit}
        totalRecords={data?.sites.totalCount || 0}
        onPage={(e) => {
          setPage(e.page || 0);
        }}
        paginator={!!data && data.sites.totalCount > rowLimit}
        lazy
      >
        <Column field="name" header="Name" />
        <Column field="domain" header="Domain" />
        <Column
          header=""
          align="right"
          bodyClassName="w-2rem pr-1"
          body={(row: Site) => (
            <div className="flex justify-content-end">
              <BasicIconButton
                icon={PrimeIcons.EXTERNAL_LINK}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`${window.location.protocol}//${row.domain}`, '_blank');
                }}
              />
            </div>
          )}
        />
      </DataTable>
    </PageContainer>
  );
};
