import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { RadioCards } from '@radix-ui/themes';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { StripeElementStyle } from '@stripe/stripe-js';
import { PromoCodeType, StripeCouponDuration } from '@wirechunk/lib/api.js';
import { evaluateBooleanExpression } from '@wirechunk/lib/expression-builder/evaluator.js';
import { invoiceFrequencyHumanInterval } from '@wirechunk/lib/invoices.js';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import {
  OrderFormPromoCodeType,
  OrderPageComponent,
} from '@wirechunk/lib/mixer/types/components.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { normalizePromoCode } from '@wirechunk/lib/promo-codes.js';
import { trimTrailingZeroes } from '@wirechunk/lib/strings.js';
import { clsx } from 'clsx';
import { isEmpty, isNil, isString } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOptionalCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import type { MeQuery } from '../../../contexts/CurrentUserContext/queries.generated.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { useInterval } from '../../../hooks/useInterval.js';
import { triggerGoogleTagManagerEvent } from '../../../util/analyticsScripts.js';
import { stateOptions } from '../../../util/states.js';
import { ParseAndRenderQuillDelta } from '../../RenderQuillDelta/parse-and-render-quill-delta.js';
import { Spinner } from '../../Spinner.js';
import { buildContextData, OrderFormContextData } from './context-data.js';
import {
  FindOrCreateStripeCustomerDocument,
  FindOrCreateStripeSubscriptionDocument,
} from './mutations.generated.js';
import styles from './order-form.module.css';
import {
  ApplyCustomPromoCodeDocument,
  MeProductItemsDocument,
  PreviewStripeUpcomingInvoiceDocument,
  SubscriptionCheckoutOptionsDocument,
} from './queries.generated.js';
import type { MembershipPlan } from './types.js';

const stripeElementEmptyClassName = 'StripeElement--empty';

const stripeElementsStyle: StripeElementStyle = {
  base: {
    color: '#495057',
    fontSize: '15px',
    fontFamily: '"IBM Plex Sans", Poppins, "Helvetica Neue", sans-serif',
    lineHeight: '30.5px',
  },
};

export const cardNumberFieldId = 'paymentCardNumber';
export const cardExpiryFieldId = 'paymentCardExpiry';
export const cardCvcFieldId = 'paymentCardCvc';

export enum Field {
  Plan,
  FirstName,
  LastName,
  EmailAddress,
  Phone,
  CardNumber,
  CardExpiry,
  CardCvc,
  AddressLine1,
  AddressCity,
  AddressState,
  AddressZipCode,
  AgreeToTerms,
}

const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

export const isValidZipCode = (value: string): boolean => zipCodeRegex.test(value);

const incompleteZipCodeRegexWithDash = /^\d{0,5}-?$/;
const incompleteZipCodeRegexWithDashAndMore = /^\d{0,5}-\d{0,4}$/;

const isValidIncompleteZipCode = (value: string) =>
  isValidZipCode(value) ||
  incompleteZipCodeRegexWithDash.test(value) ||
  incompleteZipCodeRegexWithDashAndMore.test(value);

const FieldError: FunctionComponent<{ error: string }> = ({ error }) => (
  <div className={`${styles.fieldError} text-red-500 font-medium`}>{error}</div>
);

type ConfirmationProps = {
  className?: string;
  firstName: string;
  emailAddress: string;
  me: MeQuery['me'];
};

const Confirmation: FunctionComponent<ConfirmationProps> = ({
  className,
  firstName,
  emailAddress,
  me,
}) => (
  <div className={className}>
    <h2>Congratulations, {firstName}!</h2>
    <p className="mb-4">Your new membership is now active!</p>
    {me ? (
      <Fragment>
        <Link
          to="/"
          className="flex align-items-center gap-2 w-max mb-4 p-button text-color-on-primary line-height-1 px-4 py-3"
        >
          Go to app <i className={PrimeIcons.ANGLE_RIGHT} />
        </Link>
        <p className="lg:pr-5">
          Please note that it may take a moment until your account is provisioned with your new
          access.
          <br />
          Simply refresh your browser tab if you don’t see your new content and features
          immediately.
        </p>
      </Fragment>
    ) : (
      <Fragment>
        <p className="mb-4">
          Please check your email ({emailAddress}) for a link that will let you create your password
          and sign in.
        </p>
        <p className="mb-4">
          You’ll be able to sign in at <a href="/">{window.location.host}</a>
        </p>
        <p>We’re excited to have you as a member!</p>
      </Fragment>
    )}
  </div>
);

type ApplyCustomPromoCodeSuccessResult = {
  __typename: 'ApplyCustomPromoCodeSuccessResult';
  subscriptionPlanId: string;
  promoCode: string;
  valid: boolean;
  successMessage?: { __typename: 'Delta'; delta: string } | null;
};

type AppliedPromo = ApplyCustomPromoCodeSuccessResult | PreviewStripePromoCode;

type PreviewStripePromoCode = {
  __typename: 'PreviewStripePromoCode';
  valid: boolean;
  coupon: {
    __typename: 'PreviewStripeCoupon';
    duration: StripeCouponDuration;
    durationMonths?: number | null;
    amountOff?: string | null;
    percentOff?: number | null;
    valid: boolean;
  };
};

export const OrderPage: FunctionComponent<OrderPageComponent> = (props) => {
  const { user, loadingUser } = useOptionalCurrentUser();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const stripe = useStripe();
  const elements = useElements();
  const dialog = useDialog();

  const [contextData, setContextData] = useState<OrderFormContextData>(() =>
    buildContextData({
      user,
    }),
  );
  const refreshContextData = useCallback(() => {
    setContextData(
      buildContextData({
        user,
      }),
    );
  }, [user]);
  useInterval(refreshContextData, 30_000);

  const subscriptionPlanIds = useMemo<string[]>(
    () =>
      props.checkoutOptions
        ?.filter(({ condition }) => !condition || evaluateBooleanExpression(condition, contextData))
        .map(({ subscriptionPlanId }) => subscriptionPlanId) ?? [],
    [props.checkoutOptions, contextData],
  );

  const { data: checkoutOptionsData, loading: loadingCheckoutOptions } = useQuery(
    SubscriptionCheckoutOptionsDocument,
    {
      onError,
      variables: {
        subscriptionPlanIds,
      },
    },
  );

  const [totalDueToday, setTotalDueToday] = useState<string | null>(null);
  const [appliedPromo, setAppliedPromo] = useState<AppliedPromo | null>(null);
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState<string | null>(null);

  const [selectedPlan, setSelectedPlan] = useState<MembershipPlan | null>(null);

  const [applyPromoCode, { loading: applyingPromoCode }] = useLazyQuery(
    ApplyCustomPromoCodeDocument,
    {
      fetchPolicy: 'network-only',
      onError,
      onCompleted: (data) => {
        const result = data.applyCustomPromoCode;
        if (result.__typename === 'ApplyCustomPromoCodeSuccessResult') {
          setAppliedPromo(
            // Make sure that the current state of the form is consistent with the query's inputs, which are echoed back
            // in the response.
            result.subscriptionPlanId === selectedPlan?.id &&
              result.promoCode === normalizePromoCode(promoCode)
              ? result
              : null,
          );
        } else {
          setPromoCodeErrorMessage(result.message);
        }
      },
    },
  );
  const [
    previewUpcomingInvoice,
    { loading: loadingPreviewUpcomingInvoice, data: previewUpcomingInvoiceData },
  ] = useLazyQuery(PreviewStripeUpcomingInvoiceDocument, {
    fetchPolicy: 'network-only',
    onError,
    onCompleted: (data) => {
      const result = data.previewStripeUpcomingInvoice;
      if (result.__typename === 'PreviewStripeUpcomingInvoiceSuccessResult') {
        setTotalDueToday(result.total);
        if (props.enablePromoCode === OrderFormPromoCodeType.Stripe) {
          setAppliedPromo(result.promoCode ?? null);
        }
      } else {
        setPromoCodeErrorMessage(result.message);
      }
    },
  });
  // This lazy query is set up to poll to check for new feature tags as soon as the payment is processed.
  // This is to get the new tags after Stripe's webhook is received.
  const [getMeProductItems] = useLazyQuery(MeProductItemsDocument, {
    onError,
    pollInterval: 350,
  });
  const [findOrCreateStripeCustomer, { loading: creatingStripeCustomer }] = useMutation(
    FindOrCreateStripeCustomerDocument,
    {
      onError,
    },
  );
  const [findOrCreateStripeSubscription] = useMutation(FindOrCreateStripeSubscriptionDocument, {
    fetchPolicy: 'no-cache',
    onError,
  });

  const [showPromoCodeField, setShowPromoCodeField] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [addressCity, setAddressCity] = useState<string>('');
  const [addressState, setAddressState] = useState<string | null>(null);
  const [addressZip, setAddressZip] = useState<string>('');
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmailAddress(user.email);

      void findOrCreateStripeCustomer({
        onCompleted: ({ findOrCreateStripeCustomer: customer }) => {
          if (customer.phoneNumber) {
            if (customer.phoneNumber.length === 10) {
              setPhoneNumber(customer.phoneNumber);
            } else if (customer.phoneNumber.length === 11 && customer.phoneNumber.startsWith('1')) {
              setPhoneNumber(customer.phoneNumber.slice(1));
            }
          }
          if (customer.address) {
            setAddressLine1(customer.address.line1 || '');
            setAddressLine2(customer.address.line2 || '');
            setAddressCity(customer.address.city || '');
            setAddressState(customer.address.state || null);
            setAddressZip(customer.address.postalCode || '');
          }
        },
      });
    }
  }, [findOrCreateStripeCustomer, user]);

  const [inputErrors, setInputErrors] = useState<Partial<Record<Field, string>>>({});
  const [globalValidationMessage, setGlobalValidationMessage] = useState<string | null>(null);
  const [newSubscriptionCreated, setNewSubscriptionCreated] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const submit = () => {
    if (!stripe || !elements || submitting) {
      return;
    }

    void (async () => {
      try {
        setSubmitting(true);
        clearMessages();

        const errors: Partial<Record<Field, string>> = {
          ...(inputErrors[Field.CardNumber]
            ? {
                [Field.CardNumber]: inputErrors[Field.CardNumber],
              }
            : {}),
          ...(inputErrors[Field.CardExpiry]
            ? {
                [Field.CardExpiry]: inputErrors[Field.CardExpiry],
              }
            : {}),
          ...(inputErrors[Field.CardCvc] ? { [Field.CardCvc]: inputErrors[Field.CardCvc] } : {}),
        };
        if (!selectedPlan) {
          errors[Field.Plan] = 'Please select a plan';
        }
        const firstNameTrimmed = firstName.trim();
        if (!firstNameTrimmed) {
          errors[Field.FirstName] = 'First name is required';
        }
        const lastNameTrimmed = lastName.trim();
        if (!lastName) {
          errors[Field.LastName] = 'Last name is required';
        }
        const emailAddressTrimmed = emailAddress.trim();
        if (!emailAddress.trim()) {
          errors[Field.EmailAddress] = 'Email address is required';
        }
        const phoneNumberTrimmed = phoneNumber.trim();
        if (!phoneNumberTrimmed) {
          errors[Field.Phone] = 'Phone number is required';
        }
        const addressLine1Trimmed = addressLine1.trim();
        if (!addressLine1Trimmed) {
          errors[Field.AddressLine1] = 'Street address is required';
        }
        const addressLine2Trimmed = addressLine2.trim() || undefined;
        const addressCityTrimmed = addressCity.trim();
        if (!addressCityTrimmed) {
          errors[Field.AddressCity] = 'City is required';
        }
        if (!addressState) {
          errors[Field.AddressState] = 'State is required';
        }
        const addressZipTrimmed = addressZip.trim();
        if (!isValidZipCode(addressZipTrimmed)) {
          errors[Field.AddressZipCode] = 'Zip code is required';
        }
        if (!agreeToTerms) {
          errors[Field.AgreeToTerms] = 'Please agree to the terms';
        }

        const cardNumberElement = document.getElementById(cardNumberFieldId);
        const cardExpiryElement = document.getElementById(cardExpiryFieldId);
        const cardCvcElement = document.getElementById(cardCvcFieldId);

        if (
          !cardNumberElement ||
          cardNumberElement.classList.contains(stripeElementEmptyClassName)
        ) {
          if (!errors[Field.CardNumber]) {
            errors[Field.CardNumber] = 'Card number is required';
          }
        }
        if (
          !cardExpiryElement ||
          cardExpiryElement.classList.contains(stripeElementEmptyClassName)
        ) {
          if (!errors[Field.CardExpiry]) {
            errors[Field.CardExpiry] = 'Card expiration date is required';
          }
        }
        if (!cardCvcElement || cardCvcElement.classList.contains(stripeElementEmptyClassName)) {
          if (!errors[Field.CardCvc]) {
            errors[Field.CardCvc] = 'Card CVC is required';
          }
        }

        setInputErrors(errors);

        if (!selectedPlan || !addressState || !isEmpty(errors)) {
          setGlobalValidationMessage('Please correct the errors in your submission above');
          return;
        }

        setGlobalValidationMessage(null);

        const { data } = await findOrCreateStripeSubscription({
          variables: {
            subscriptionPlanId: selectedPlan.id,
            firstName: firstNameTrimmed,
            lastName: lastNameTrimmed,
            email: emailAddressTrimmed,
            phone: phoneNumberTrimmed,
            address: {
              line1: addressLine1Trimmed,
              line2: addressLine2Trimmed || null,
              city: addressCityTrimmed,
              state: addressState,
              postalCode: addressZipTrimmed,
              country: null,
            },
            // We know appliedPromo applies to the selected subscription plan because we disable the promo code input
            // field when a promo is applied, and we clear out appliedPromo when the promo code input is changed.
            promoCode: appliedPromo?.valid && promoCode ? promoCode : undefined,
            promoCodeType:
              props.enablePromoCode === OrderFormPromoCodeType.Custom
                ? PromoCodeType.Custom
                : PromoCodeType.Stripe,
          },
        });
        if (data) {
          const result = data.findOrCreateStripeSubscription;
          if (result.__typename === 'FindOrCreateStripeSubscriptionErrorResult') {
            onError(result.message);
          } else if (result.__typename === 'FindOrCreateStripeSubscriptionActiveResult') {
            dialog({
              content: (
                <p>
                  You already have an active subscription.{' '}
                  <a href="/" className="font-medium">
                    Go to app
                  </a>
                </p>
              ),
              props: {
                header: 'Active subscription exists',
              },
            });
          } else if (result.__typename === 'FindOrCreateStripeSubscriptionUpdatedResult') {
            dialog({
              content: (
                <p>
                  Congratulations! Your subscription has been updated!{' '}
                  <a href="/" className="font-medium">
                    Go to app
                  </a>
                </p>
              ),
              props: {
                header: 'Subscription updated',
              },
            });
          } else {
            const cardNumberElement = elements.getElement(CardNumberElement);
            if (!cardNumberElement) {
              onError('Internal error: Unable to find card number element');
              return;
            }

            const paymentResult = await stripe.confirmCardPayment(
              result.paymentIntentClientSecret,
              {
                setup_future_usage: 'off_session',
                receipt_email: emailAddressTrimmed,
                payment_method: {
                  card: cardNumberElement,
                  billing_details: {
                    name: `${firstNameTrimmed} ${lastNameTrimmed}`,
                    email: emailAddressTrimmed,
                    phone: phoneNumberTrimmed,
                    address: {
                      line1: addressLine1Trimmed,
                      line2: addressLine2Trimmed,
                      city: addressCityTrimmed,
                      state: addressState,
                      postal_code: addressZipTrimmed,
                    },
                  },
                },
              },
            );

            if (paymentResult.error) {
              onError(
                paymentResult.error.message ||
                  `An error occurred processing your payment (${paymentResult.error.type})`,
              );
            } else {
              triggerGoogleTagManagerEvent({
                event: 'membership_purchase',
                transaction_value: Number(selectedPlan.price),
              });
              if (user) {
                void getMeProductItems();
              }
              setNewSubscriptionCreated(true);
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const checkoutOptions = checkoutOptionsData?.subscriptionCheckoutOptions;

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {loadingCheckoutOptions || loadingUser || !stripe || creatingStripeCustomer ? (
        <Spinner />
      ) : newSubscriptionCreated ? (
        <Confirmation firstName={firstName} emailAddress={emailAddress} me={user} />
      ) : checkoutOptions?.length ? (
        <form
          className="w-full"
          onSubmit={(event) => {
            event.preventDefault();
            submit();
          }}
        >
          <h1 className="text-2xl mt-0 mb-4 font-bold">Start Your Membership</h1>
          {user && (
            // TODO: Make all this editable in Mixer.
            <Fragment>
              <div className="font-medium text-lg mb-2">
                Hi, <span className="uppercase">{user.firstName}</span>
              </div>
              <p className="font-italic text-sm mb-3">You’re signed in as {user.email}</p>
            </Fragment>
          )}
          <div className="surface-ground border-round-xl p-3">
            <h2 className="text-base mt-0 mb-3 text-center uppercase font-medium">
              Membership Plan
            </h2>
            <RadioCards.Root
              name={`order-form-plan-${props.id}`}
              className="flex flex-column gap-3 align-items-stretch"
              color="grass"
              required
              // Always pass in a string to keep this a controlled component.
              value={selectedPlan?.id || ''}
              onValueChange={(value) => {
                if (
                  !loadingPreviewUpcomingInvoice &&
                  !applyingPromoCode &&
                  (!selectedPlan || selectedPlan.id !== value)
                ) {
                  const checkoutOption = checkoutOptions.find(
                    (checkoutOption) => checkoutOption.id === value,
                  );
                  if (checkoutOption) {
                    setSelectedPlan(checkoutOption);
                    setTotalDueToday(null);
                    setAppliedPromo(null);
                    setPromoCodeErrorMessage(null);
                    void previewUpcomingInvoice({
                      // Here we should not pass in the promo code because the user should have to re-apply it.
                      variables: {
                        subscriptionPlanId: checkoutOption.id,
                      },
                    });
                    setInputErrors((prev) => ({
                      ...prev,
                      [Field.Plan]: undefined,
                    }));
                  }
                }
              }}
            >
              {checkoutOptions.map((option) => (
                <RadioCards.Item
                  key={option.id}
                  value={option.id}
                  className={clsx('flex gap-3 align-items-center justify-content-start')}
                  aria-label={`${option.displayName} $${option.price}${option.invoiceFrequency ? ` /${invoiceFrequencyHumanInterval(option.invoiceFrequency)}` : ''}`}
                >
                  <i
                    className={
                      selectedPlan?.id === option.id ? PrimeIcons.CHECK_CIRCLE : PrimeIcons.CIRCLE
                    }
                  />
                  <span>
                    <label className="block text-lg font-bold line-height-1 mt-0 mb-1">
                      {option.displayName}
                    </label>
                    <span className="block font-medium">
                      <span className="font-bold">${trimTrailingZeroes(option.price)}</span>
                      {option.invoiceFrequency && (
                        <span className={styles.priceFrequency}>
                          /{invoiceFrequencyHumanInterval(option.invoiceFrequency)}
                        </span>
                      )}
                    </span>
                  </span>
                </RadioCards.Item>
              ))}
            </RadioCards.Root>
          </div>
          {inputErrors[Field.Plan] && <FieldError error={inputErrors[Field.Plan]} />}
          {props.enablePromoCode && (
            <Fragment>
              {showPromoCodeField ? (
                <div className="flex align-items-end gap-2 mt-3">
                  <div className="input-field mb-0 flex-grow-1">
                    <label htmlFor="orderPromoCode">Promo code</label>
                    <IconField iconPosition="right" className="w-full">
                      {appliedPromo?.valid && (
                        <InputIcon className={`${PrimeIcons.CHECK} text-color-success-dark`} />
                      )}
                      <InputText
                        id="orderPromoCode"
                        className="w-full"
                        autoFocus
                        readOnly={
                          !!appliedPromo?.valid ||
                          applyingPromoCode ||
                          loadingPreviewUpcomingInvoice
                        }
                        value={promoCode}
                        onChange={(e) => {
                          setPromoCode(e.target.value);
                          setAppliedPromo(null);
                          setPromoCodeErrorMessage(null);
                        }}
                        onBlur={() => {
                          const trimmedPromoCode = promoCode.trim();
                          if (trimmedPromoCode) {
                            if (trimmedPromoCode !== promoCode) {
                              setPromoCode(trimmedPromoCode);
                            }
                          } else {
                            setShowPromoCodeField(false);
                          }
                        }}
                      />
                    </IconField>
                  </div>
                  {appliedPromo?.valid ? (
                    <Button
                      label="Remove"
                      className="w-max p-button-text"
                      onClick={(event) => {
                        // Don't submit the form.
                        event.preventDefault();
                        setPromoCode('');
                        setTotalDueToday(null);
                        setAppliedPromo(null);
                        setPromoCodeErrorMessage(null);
                        if (selectedPlan) {
                          void previewUpcomingInvoice({
                            // At this moment we do not apply the promo code.
                            variables: {
                              subscriptionPlanId: selectedPlan.id,
                            },
                          });
                        }
                      }}
                    />
                  ) : (
                    <Button
                      label="Apply"
                      className="w-max"
                      disabled={!selectedPlan || applyingPromoCode || loadingPreviewUpcomingInvoice}
                      tooltipOptions={{ showOnDisabled: true, position: 'bottom' }}
                      tooltip={
                        selectedPlan
                          ? undefined
                          : 'Please select a plan before applying your promo code'
                      }
                      onClick={(event) => {
                        // Don't submit the form.
                        event.preventDefault();
                        setAppliedPromo(null);
                        setPromoCodeErrorMessage(null);
                        if (selectedPlan) {
                          // Always make the request to preview the upcoming invoice. Pass in promoCode only when the promo codes
                          // mode is set to Stripe.
                          void previewUpcomingInvoice({
                            variables: {
                              subscriptionPlanId: selectedPlan.id,
                              promoCode:
                                props.enablePromoCode === OrderFormPromoCodeType.Stripe
                                  ? promoCode
                                  : undefined,
                            },
                          });
                          if (props.enablePromoCode === OrderFormPromoCodeType.Custom) {
                            void applyPromoCode({
                              variables: {
                                subscriptionPlanId: selectedPlan.id,
                                promoCode,
                              },
                            });
                          }
                        }
                      }}
                    />
                  )}
                </div>
              ) : (
                <Button
                  className="p-button-text mt-3 pl-0 pr-1 py-1 background-none"
                  onClick={() => {
                    setShowPromoCodeField(true);
                  }}
                >
                  Add promo code
                </Button>
              )}
              {appliedPromo?.valid && appliedPromo.__typename === 'PreviewStripePromoCode' && (
                <p className="mt-1 text-color-muted text-sm">
                  {appliedPromo.coupon.amountOff
                    ? appliedPromo.coupon.amountOff
                    : appliedPromo.coupon.percentOff
                      ? `${appliedPromo.coupon.percentOff}%`
                      : null}
                  {appliedPromo.coupon.duration === StripeCouponDuration.Forever
                    ? ' off forever'
                    : appliedPromo.coupon.duration === StripeCouponDuration.Once
                      ? ' off once'
                      : appliedPromo.coupon.durationMonths
                        ? ` off for ${appliedPromo.coupon.durationMonths} ${pluralize(
                            appliedPromo.coupon.durationMonths,
                            'month',
                          )}`
                        : ''}
                </p>
              )}
              {promoCodeErrorMessage || (appliedPromo && !appliedPromo.valid) ? (
                <p className="mt-1 text-color-danger font-medium text-sm">
                  {promoCodeErrorMessage || 'Invalid promo code.'}
                </p>
              ) : (
                appliedPromo?.__typename === 'ApplyCustomPromoCodeSuccessResult' &&
                appliedPromo.successMessage && (
                  <div className="mt-2">
                    <ParseAndRenderQuillDelta delta={appliedPromo.successMessage.delta} />
                  </div>
                )
              )}
            </Fragment>
          )}
          <div className="flex align-items-center gap-1 my-3">
            <span>Total due today: </span>
            {applyingPromoCode || loadingPreviewUpcomingInvoice ? (
              // We don't want to standard Spinner here.
              <i className={`${PrimeIcons.SPINNER} pi-spin text-sm`} />
            ) : isNil(totalDueToday) ? (
              <span className="text-color-muted">–</span>
            ) : (
              <span className="font-medium">{totalDueToday}</span>
            )}
          </div>
          {!user && (
            <Fragment>
              <div className="flex gap-4 align-items-start w-23rem max-w-full">
                <div className="input-field">
                  <label htmlFor="firstName">First name</label>
                  <InputText
                    id="firstName"
                    className="w-full"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setInputErrors((prev) => ({
                        ...prev,
                        [Field.FirstName]: undefined,
                      }));
                    }}
                  />
                  {inputErrors[Field.FirstName] && (
                    <FieldError error={inputErrors[Field.FirstName]} />
                  )}
                </div>
                <div className="input-field">
                  <label htmlFor="lastName">Last name</label>
                  <InputText
                    id="lastName"
                    className="w-full"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setInputErrors((prev) => ({
                        ...prev,
                        [Field.LastName]: undefined,
                      }));
                    }}
                  />
                  {inputErrors[Field.LastName] && (
                    <FieldError error={inputErrors[Field.LastName]} />
                  )}
                </div>
              </div>
              <div className="input-field">
                <label htmlFor="emailAddress">Email address</label>
                <InputText
                  id="emailAddress"
                  className="w-23rem max-w-full"
                  autoComplete="email"
                  type="email"
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                    setInputErrors((prev) => ({
                      ...prev,
                      [Field.EmailAddress]: undefined,
                    }));
                  }}
                />
                {inputErrors[Field.EmailAddress] && (
                  <FieldError error={inputErrors[Field.EmailAddress]} />
                )}
              </div>
            </Fragment>
          )}
          <div className="input-field">
            <label>Phone number</label>
            <InputMask
              className="w-10rem"
              id="phone"
              mask="(999) 999-9999"
              placeholder=""
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value;
                if (isString(phoneNumber)) {
                  setPhoneNumber(phoneNumber);
                  setInputErrors((prev) => ({
                    ...prev,
                    [Field.Phone]: undefined,
                  }));
                }
              }}
            />
            {inputErrors[Field.Phone] && <FieldError error={inputErrors[Field.Phone]} />}
          </div>
          <h2 className="text-lg mt-3">Payment method</h2>
          <div className="input-field">
            <label>Card number</label>
            <CardNumberElement
              id={cardNumberFieldId}
              className="w-23rem max-w-full"
              options={{
                classes: {},
                placeholder: '',
                style: stripeElementsStyle,
              }}
              onChange={({ error }) => {
                setInputErrors((prev) => ({
                  ...prev,
                  [Field.CardNumber]: error?.message || undefined,
                }));
              }}
            />
            {inputErrors[Field.CardNumber] && <FieldError error={inputErrors[Field.CardNumber]} />}
          </div>
          <div className="flex gap-4">
            <div className="input-field w-8rem">
              <label>Expiration date</label>
              <CardExpiryElement
                id={cardExpiryFieldId}
                className="w-full"
                options={{
                  style: stripeElementsStyle,
                }}
                onChange={({ error }) => {
                  setInputErrors((prev) => ({
                    ...prev,
                    [Field.CardExpiry]: error?.message || undefined,
                  }));
                }}
              />
              {inputErrors[Field.CardExpiry] && (
                <FieldError error={inputErrors[Field.CardExpiry]} />
              )}
            </div>
            <div className="input-field">
              <label>CVC</label>
              <CardCvcElement
                id={cardCvcFieldId}
                className="w-4rem"
                options={{
                  placeholder: '',
                  style: stripeElementsStyle,
                }}
                onChange={({ error }) => {
                  setInputErrors((prev) => ({
                    ...prev,
                    [Field.CardCvc]: error?.message || undefined,
                  }));
                }}
              />
              {inputErrors[Field.CardCvc] && <FieldError error={inputErrors[Field.CardCvc]} />}
            </div>
          </div>
          <div className="input-field">
            <label>Street address</label>
            <InputText
              className="w-23rem max-w-full"
              autoComplete="street-address"
              value={addressLine1}
              onChange={(e) => {
                setAddressLine1(e.target.value);
                setInputErrors((prev) => ({
                  ...prev,
                  [Field.AddressLine1]: undefined,
                }));
              }}
            />
            {inputErrors[Field.AddressLine1] && (
              <FieldError error={inputErrors[Field.AddressLine1]} />
            )}
          </div>
          <div className="input-field">
            <label>
              Apt., suite, etc. <span className="font-normal">(Optional)</span>
            </label>
            <InputText
              className="w-23rem max-w-full"
              autoComplete="address-line2"
              value={addressLine2}
              onChange={(e) => {
                setAddressLine2(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label>City</label>
            <InputText
              className="w-23rem max-w-full"
              autoComplete="address-level2"
              value={addressCity}
              onChange={(e) => {
                setAddressCity(e.target.value);
                setInputErrors((prev) => ({
                  ...prev,
                  [Field.AddressCity]: undefined,
                }));
              }}
            />
            {inputErrors[Field.AddressCity] && (
              <FieldError error={inputErrors[Field.AddressCity]} />
            )}
          </div>
          <div className="flex gap-4 w-23rem max-w-full">
            <div className="input-field flex-grow-1">
              <label>State</label>
              <Dropdown
                className="w-full"
                autoComplete="address-level1"
                value={addressState}
                options={stateOptions}
                onChange={(e) => {
                  if (isString(e.value)) {
                    setAddressState(e.value);
                    setInputErrors((prev) => ({
                      ...prev,
                      [Field.AddressState]: undefined,
                    }));
                  }
                }}
              />
              {inputErrors[Field.AddressState] && (
                <FieldError error={inputErrors[Field.AddressState]} />
              )}
            </div>
            <div className="input-field">
              <label>Zip code</label>
              <InputText
                className="w-8rem"
                autoComplete="postal-code"
                value={addressZip}
                onChange={(e) => {
                  if (isValidIncompleteZipCode(e.target.value)) {
                    setAddressZip(e.target.value);
                    setInputErrors((prev) => ({
                      ...prev,
                      [Field.AddressZipCode]: undefined,
                    }));
                  }
                }}
              />
              {inputErrors[Field.AddressZipCode] && (
                <FieldError error={inputErrors[Field.AddressZipCode]} />
              )}
            </div>
          </div>
          <div className="flex gap-2 align-items-center mt-3">
            <Checkbox
              inputId="agreeToTerms"
              checked={agreeToTerms}
              onChange={(e) => {
                setAgreeToTerms(!!e.checked);
                setInputErrors((prev) => ({
                  ...prev,
                  [Field.AgreeToTerms]: undefined,
                }));
              }}
            />
            <label htmlFor="agreeToTerms">I agree to the Terms of Use and the Privacy Policy</label>
          </div>
          {inputErrors[Field.AgreeToTerms] && (
            <FieldError error={inputErrors[Field.AgreeToTerms]} />
          )}
          <Button
            className="mt-4 w-23rem flex max-w-full justify-content-center"
            label={submitting ? 'Processing...' : 'Start membership'}
            disabled={
              submitting ||
              !previewUpcomingInvoiceData ||
              previewUpcomingInvoiceData.previewStripeUpcomingInvoice.__typename !==
                'PreviewStripeUpcomingInvoiceSuccessResult'
            }
            onClick={submit}
          />
          {globalValidationMessage && (
            <p className="text-red-500 font-medium mt-2">{globalValidationMessage}</p>
          )}
        </form>
      ) : (
        <div>There are no products available.</div>
      )}
    </div>
  );
};
