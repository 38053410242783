import { useQuery } from '@apollo/client';
import { ErrorHandler } from '../useErrorHandler.js';
import { ContentPlansDocument, ContentPlansQuery } from './queries.generated.js';

export type ContentPlan = ContentPlansQuery['contentPlans']['contentPlans'][number];

type ContentPlans = {
  contentPlans: ContentPlan[] | undefined;
  loading: boolean;
};

export const useContentPlans = (
  platformId: string,
  onError: ErrorHandler['onError'],
): ContentPlans => {
  const { data, loading } = useQuery(ContentPlansDocument, {
    onError,
    variables: { platformId },
    fetchPolicy: 'cache-and-network',
  });

  return {
    contentPlans: data?.contentPlans.contentPlans,
    loading,
  };
};
