import { FileTypeCategory } from '@wirechunk/lib/mixer/types/components.js';
import { clsx } from 'clsx';
import { noop } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import {
  ChangeEvent,
  DragEventHandler,
  Fragment,
  FunctionComponent,
  useRef,
  useState,
} from 'react';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { UploadVariables, useUploadFile } from '../../hooks/useUploadFile/useUploadFile.js';
import { inputAcceptProp } from '../../util/inputs.js';

type FileItemProps = {
  file: File;
  isUploading: boolean;
  onRemove: () => void;
};

const FileItem: FunctionComponent<FileItemProps> = ({ file, isUploading, onRemove }) => {
  return (
    <div className="border border-round p-2">
      <div className="flex align-items-center justify-content-between mb-2 md:mb-0">
        <span className="font-medium">
          {file.name}
          {isUploading && <span className="font-medium font-italic ml-2">Uploading&hellip;</span>}
        </span>
        <Button
          className="p-button-danger p-button-outlined p-button-sm flex-shrink-0"
          icon={PrimeIcons.TIMES}
          label={isUploading ? 'Cancel' : 'Remove'}
          disabled={isUploading}
          onClick={(evt) => {
            evt.stopPropagation();
            onRemove();
          }}
        />
      </div>
    </div>
  );
};

type FileUploadProps = {
  className?: string;
  uploadVariables: UploadVariables;
  types?: FileTypeCategory[];
  showClose: boolean;
  // onClose is called if the user clicks the close button.
  onClose?: () => void;
  onUploaded?: () => void;
};

export const FileUpload: FunctionComponent<FileUploadProps> = ({
  className,
  uploadVariables,
  types,
  showClose,
  onClose,
  onUploaded,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [file, setFile] = useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const { upload, isUploading, cancel } = useUploadFile(onError);

  // TODO: Styling for dragover event.
  // const dragOverHandler = (e: DragEvent) => {
  //   e.preventDefault();
  // };

  const onFilesSelected = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] ?? null);
  };

  const onFileDrop: DragEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const firstFile = e.dataTransfer.files[0];
    setFile(firstFile ?? null);
  };

  const onUpload = () => {
    if (!file) {
      return;
    }
    void upload(file, uploadVariables, noop, () => {
      onUploaded?.();
      const { current: input } = fileInput;
      if (input) {
        input.value = '';
      }
      setFile(null);
    });
  };

  return (
    <Fragment>
      <ErrorMessage />
      <div className={clsx(className, 'border-1 border-round')}>
        <div className="flex flex-column md:flex-row gap-3 surface-ground p-3 border-bottom">
          <div className="flex-grow-1">
            <Button
              label="Choose a file"
              icon={PrimeIcons.PLUS}
              className="mr-3"
              disabled={!!file}
              onClick={() => {
                fileInput.current?.click();
              }}
            />
            <Button
              label="Upload"
              icon={PrimeIcons.UPLOAD}
              disabled={!file || isUploading}
              onClick={onUpload}
            />
          </div>
          {showClose && (
            <Button
              className="p-button-danger p-button-outlined max-w-max"
              icon={PrimeIcons.TIMES}
              label="Cancel"
              onClick={onClose}
            />
          )}
        </div>
        {file ? (
          <div className="flex flex-column p-3 gap-3">
            <FileItem
              file={file}
              isUploading={isUploading}
              onRemove={() => {
                setFile(null);
                if (fileInput.current) {
                  fileInput.current.value = '';
                }
                cancel();
              }}
            />
          </div>
        ) : (
          <div
            className="flex align-items-center flex-column py-5 cursor-pointer"
            onDragOver={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
            onDrop={onFileDrop}
            onClick={() => {
              fileInput.current?.click();
            }}
          >
            <i
              className="pi pi-file p-4 border-circle"
              style={{
                fontSize: '3em',
                backgroundColor: 'var(--surface-ground)',
                color: 'var(--surface-400)',
              }}
            />
            <span className="mt-5 uppercase text-color-muted">Drop a file here</span>
          </div>
        )}
      </div>
      <input
        ref={fileInput}
        type="file"
        className="hidden"
        accept={types?.length ? inputAcceptProp(types) : undefined}
        disabled={isUploading}
        onChange={onFilesSelected}
      />
    </Fragment>
  );
};
