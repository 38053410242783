import { Permission } from '@wirechunk/lib/api.js';
import { PrimeIcons } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../contexts/ToastContext.js';
import { useMenuSetup } from '../../hooks/useMenuSetup.js';
import { roleToHumanReadable } from '../../util/roles.js';
import { EditOrgPrimaryUser } from '../edit-org-primary-user/edit-org-primary-user.js';
import { ThreeDotMenuButton } from '../ThreeDotMenuButton/ThreeDotMenuButton.js';
import styles from './organization-details.module.css';
import type { OrganizationQuery } from './queries.generated.js';

type MemberRow = OrganizationQuery['organization']['members'][number];

type MembersProps = {
  organization: OrganizationQuery['organization'];
};

export const Members: FunctionComponent<MembersProps> = ({ organization }) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { permissions } = usePlatformContext();
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<MemberRow>();

  const hasEditUserOrgPermission = permissions.includes(Permission.EditUserOrg);

  return (
    <Fragment>
      <h3 className="text-lg mb-2">Members</h3>
      <DataTable
        dataKey="id"
        className={styles.innerTable}
        value={organization.members}
        emptyMessage="No users found"
      >
        <Column
          header="Name"
          body={(member: MemberRow) => (
            <span className="flex">
              {member.displayName}
              {organization.primaryUser?.id === member.id && (
                <i className={`${PrimeIcons.STAR_FILL} pl-1 text-xs`} />
              )}
            </span>
          )}
        />
        <Column
          header="Email"
          body={(member: MemberRow) => (
            <span
              className="cursor-pointer"
              onClick={() => {
                void navigator.clipboard.writeText(member.email);
                toastSuccess('Copied email address to clipboard.');
              }}
            >
              {member.email}
            </span>
          )}
        />
        <Column
          header="Role"
          body={(member: MemberRow) => <span>{roleToHumanReadable(member.role)}</span>}
        />
        <Column header="Status" body={(member: MemberRow) => <span>{member.status}</span>} />
        <Column
          header=""
          align="right"
          body={(row: MemberRow) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Set as primary',
            disabled:
              !hasEditUserOrgPermission ||
              (!!menuRow &&
                !!organization.primaryUser &&
                menuRow.id === organization.primaryUser.id),
            command: () => {
              if (menuRow) {
                dialog({
                  content: (
                    <EditOrgPrimaryUser
                      org={organization}
                      user={menuRow}
                      onCompleted={() => {
                        dialog(null);
                      }}
                      onCancel={() => {
                        dialog(null);
                      }}
                    />
                  ),
                  props: {
                    header: 'Set user as primary',
                    className: 'dialog-width-lg',
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </Fragment>
  );
};
