import { MutableRefObject, useCallback, useRef, useState } from 'react';

export type SetState<State> = (setState: (state: State) => State) => void;

export type StateRef<State> = [MutableRefObject<State>, SetState<State>];

export const useStateRef = <State>(initialState: () => State): StateRef<State> => {
  const [state, setState] = useState<State>(initialState);
  const ref = useRef<State>(state);

  const dispatch = useCallback<SetState<State>>((set) => {
    ref.current = set(ref.current);
    setState(ref.current);
  }, []);

  return [ref, dispatch];
};
