import type { FeatureTag as FeatureTagType } from '@wirechunk/schemas/expressions/expression';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../contexts/admin/platform-context/platform-context.js';
import { useProductItemPicklist } from '../../../hooks/use-product-item-picklist/use-product-item-picklist.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { BuilderProps } from './shared.js';

export const FeatureTag: FunctionComponent<BuilderProps<FeatureTagType>> = ({
  id,
  expression,
  setExpression,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { productItems, loading } = useProductItemPicklist(platformId, onError);

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field mb-0">
        <label htmlFor={`${id}-item`}>Product item</label>
        <Dropdown
          inputId={`${id}-item`}
          className="w-20rem"
          filter
          loading={loading}
          options={productItems}
          value={expression.tag}
          onChange={({ value }) => {
            if (isString(value)) {
              setExpression({
                ...expression,
                tag: value,
              });
            }
          }}
        />
      </div>
    </Fragment>
  );
};
