import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../Spinner.js';
import { CompletionSpec } from './CompletionSpec/CompletionSpec.js';
import { Components } from './Components/Components.js';
import { EditStageBlueprintMetaDataDocument } from './mutations.generated.js';
import { StageBlueprintDocument } from './queries.generated.js';

const StageBlueprintBody: FunctionComponent<{ id: string; title: string }> = ({ id, title }) => {
  const { navigate } = usePlatformContext();
  const dialog = useDialog();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading: isLoading } = useQuery(StageBlueprintDocument, {
    onError,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const [editStageBlueprintMeta, { loading: isSavingEdits }] = useMutation(
    EditStageBlueprintMetaDataDocument,
    {
      onError,
    },
  );

  // hasUnsavedChanges is used only for keeping track of whether something has changed within the tabview, not for the
  // name change, since changing tabs does not affect the state of the working name change.
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [name, setName] = useState(data?.stageBlueprint.name || '');

  useEffect(() => {
    if (data) {
      setName(data.stageBlueprint.name);
    }
  }, [data]);

  const hasUnsavedMetaDataChanges = data && name !== data.stageBlueprint.name;

  // TODO: Breadcrumbs
  return (
    <PageContainer
      title={title}
      onLinkClick={(to) => {
        if (hasUnsavedMetaDataChanges || hasUnsavedChanges) {
          dialog({
            confirm: 'You have unsaved changes. Are you sure you want to leave?',
            props: {
              onAccept: () => {
                navigate(to);
              },
            },
          });
          return false;
        }
        return true;
      }}
    >
      <ErrorMessage />
      {isLoading ? (
        <Spinner />
      ) : (
        data && (
          <Fragment>
            <div className="flex mt-1 md:mt-0 mb-3 gap-2 md:gap-4 flex-column md:flex-row">
              <InputText
                aria-label="Stage blueprint name"
                className="w-30rem max-w-full"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Button
                label="Save"
                disabled={isSavingEdits || !name.trim() || name === data.stageBlueprint.name}
                onClick={() => {
                  void editStageBlueprintMeta({
                    variables: {
                      id,
                      name,
                      position: data.stageBlueprint.position,
                      restoreDeleted: false,
                    },
                  });
                }}
              />
            </div>
            <TabView
              panelContainerClassName="px-0"
              activeIndex={activeTabIndex}
              onTabChange={({ index }) => {
                if (hasUnsavedChanges) {
                  dialog({
                    confirm: 'You have unsaved changes. Are you sure you want to leave?',
                    props: {
                      onAccept: () => {
                        setActiveTabIndex(index);
                        setHasUnsavedChanges(false);
                      },
                    },
                  });
                } else {
                  setActiveTabIndex(index);
                }
              }}
            >
              <TabPanel header="Design">
                <Components
                  stageBlueprint={data.stageBlueprint}
                  hasUnsavedChanges={hasUnsavedChanges}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </TabPanel>
              <TabPanel header="Completion spec">
                <CompletionSpec
                  stageBlueprintId={id}
                  hasUnsavedChanges={hasUnsavedChanges}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              </TabPanel>
            </TabView>
          </Fragment>
        )
      )}
    </PageContainer>
  );
};

type StageBlueprintProps = {
  title: string;
};

export const StageBlueprint: FunctionComponent<StageBlueprintProps> = ({ title }) => {
  const { stageBlueprintId } = useParams<{ stageBlueprintId: string }>();

  if (stageBlueprintId) {
    return <StageBlueprintBody id={stageBlueprintId} title={title} />;
  }

  return null;
};
