import type { ApolloQueryResult } from '@apollo/client';
import { ComponentType, createContext, FunctionComponent, useContext } from 'react';
import type { PageQuery } from '../../../components/admin/pages/Sites/Site/Pages/page/queries.generated.js';
import type { EditSiteContext } from '../edit-site-context/edit-site-context.js';

export type EditPageContext = {
  page: PageQuery['page'];
  site: EditSiteContext;
  refetchPage: () => Promise<ApolloQueryResult<PageQuery>>;
};

const context = createContext<EditPageContext | null>(null);

export const EditPageContextProvider = context.Provider;

export const useEditPageContext = (): EditPageContext | null => useContext(context);

export const withEditPageContext =
  <P,>(Component: ComponentType<P & EditPageContext>): FunctionComponent<P> =>
  (props: P) => {
    const value = useEditPageContext();
    if (!value) {
      throw new Error('No EditPageContext value is available');
    }
    return <Component {...props} {...value} />;
  };
