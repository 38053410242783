import { useLazyQuery, useQuery } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { previewSiteIdParam } from '../../components/SiteSelector.js';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import type { SiteContext } from '../../contexts/SiteContext/SiteContext.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import { SiteDocument, SitesDocument } from './queries.generated.js';

export type SiteOption = SiteContext & {
  optionDisabled?: boolean;
};

export type SiteContextSelector = {
  siteContext: SiteOption | null;
  siteOptions: SiteOption[];
  loading: boolean;
  selectSite: (siteId: string) => void;
  searchTerm: string;
  setSearchTerm: (search: string) => void;
};

type SiteContextSelectorOptions = {
  onError: ErrorHandler['onError'];
};

export const useSiteContextSelector = ({
  onError,
}: SiteContextSelectorOptions): SiteContextSelector => {
  const { id: platformId, permissions, mainSiteId } = usePlatformContext();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [siteContextId, setSiteContextId] = useState<string | null>(null);
  const [siteOptions, setSiteOptions] = useState<SiteOption[]>([]);

  const canViewCustomerSites = permissions.includes(Permission.ViewCustomerSite);
  const canViewPlatformSites = permissions.includes(Permission.ViewPlatformSite);

  const { data, loading: loadingSites } = useQuery(SitesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      search: searchTerm,
      platformSites: canViewCustomerSites && canViewPlatformSites ? null : canViewPlatformSites,
    },
    skip: !searchTerm,
  });

  const [getSite, { data: defaultSiteData, loading: loadingDefaultSite }] = useLazyQuery(
    SiteDocument,
    {
      onError,
    },
  );

  const selectedSite = useRef<SiteOption | null>(null);

  const sites = data?.sites.sites;

  useEffect(() => {
    const sitesToShow: SiteOption[] = [];
    if (sites && searchTerm) {
      sitesToShow.push(...sites);
    }

    if (
      siteContextId &&
      !sitesToShow.some((site) => site.id === siteContextId) &&
      selectedSite.current?.id === siteContextId
    ) {
      sitesToShow.push({
        ...selectedSite.current,
        optionDisabled: sitesToShow.length > 0,
      });
    }

    let setDefaultSiteId: string | null = null;
    if (
      defaultSiteData &&
      (!siteContextId || siteContextId === defaultSiteData.site.id) &&
      !sitesToShow.some((site) => site.id === defaultSiteData.site.id)
    ) {
      sitesToShow.push({
        ...defaultSiteData.site,
        optionDisabled: sitesToShow.length > 0,
      });
      setDefaultSiteId = defaultSiteData.site.id;
    }

    setSiteOptions(sitesToShow);

    if (setDefaultSiteId) {
      setSiteContextId(setDefaultSiteId);
    }
  }, [defaultSiteData, searchTerm, siteContextId, sites]);

  const initialSiteId =
    searchParams.get(previewSiteIdParam) || (canViewPlatformSites && mainSiteId);

  useEffect(() => {
    if (initialSiteId) {
      void getSite({
        variables: {
          id: initialSiteId,
        },
      });
    }
  }, [initialSiteId, getSite]);

  const selectSite = useCallback(
    (siteId: string) => {
      const site = siteOptions.find((s) => s.id === siteId);
      if (site) {
        setSiteContextId(siteId);
        selectedSite.current = site;
      }
    },
    [siteOptions],
  );

  return {
    siteContext: siteContextId
      ? siteOptions.find((site) => site.id === siteContextId) || null
      : null,
    siteOptions,
    loading: loadingSites || loadingDefaultSite,
    selectSite,
    searchTerm,
    setSearchTerm,
  };
};
