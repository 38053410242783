/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type FormTemplateEmailTemplateConfigQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type FormTemplateEmailTemplateConfigQuery = {
  formTemplate: {
    __typename: 'FormTemplate';
    id: string;
    emailBodyTemplate?: string | null;
    useEmailBodyTemplate: boolean;
    formattedDataTemplate?: string | null;
    useFormattedDataTemplate: boolean;
  };
};

export type PlatformFormTemplateDefaultsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PlatformFormTemplateDefaultsQuery = {
  platform: {
    __typename: 'Platform';
    id: string;
    defaultFormFormattedDataTemplate: string;
    defaultFormNotificationEmailBodyTemplate: string;
  };
};

export const FormTemplateEmailTemplateConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FormTemplateEmailTemplateConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailBodyTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useEmailBodyTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formattedDataTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useFormattedDataTemplate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FormTemplateEmailTemplateConfigQuery,
  FormTemplateEmailTemplateConfigQueryVariables
>;
export const PlatformFormTemplateDefaultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlatformFormTemplateDefaults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultFormFormattedDataTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultFormNotificationEmailBodyTemplate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PlatformFormTemplateDefaultsQuery,
  PlatformFormTemplateDefaultsQueryVariables
>;
