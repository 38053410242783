import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './MenuItem.module.css';

const className = `${styles.menuItem} font-medium text-color-body px-3 border-round-md flex align-items-center justify-content-start gap-3`;

type MenuItemProps = {
  title: string;
  to: string;
  external?: boolean;
  icon?: string;
  onHide: () => void;
  end?: boolean;
};

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  title,
  to,
  external,
  icon,
  onHide,
  end,
}) =>
  external ? (
    <a href={to} className={className} onClick={onHide}>
      {icon && <i className={`ml-1 ${icon}`} />}
      <span>{title}</span>
    </a>
  ) : (
    <NavLink to={to} className={className} onClick={onHide} end={end}>
      {icon && <i className={`ml-1 ${icon}`} />}
      <span>{title}</span>
    </NavLink>
  );
