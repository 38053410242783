import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import {
  findComponentById,
  insertAfterComponent,
  insertChildIntoComponent,
  pullComponentById,
  replaceComponentById,
} from '@wirechunk/lib/mixer/utils.js';
import { identity } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, ReactNode, useCallback, useId } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDialog } from '../../contexts/DialogContext/DialogContext.js';
import { SiteContext, SiteContextProvider } from '../../contexts/SiteContext/SiteContext.js';
import type { SiteContextSelector } from '../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { RenderComponentsStyled } from '../RenderComponentsStyled.js';
import { SiteSelector } from '../SiteSelector.js';
import { Spinner } from '../Spinner.js';
import { StripeElementsProvider } from '../StripeElementsProvider';
import { Tree, TreeProps } from './tree.js';

export type VisualBuilderProps = {
  siteContext: SiteContext | SiteContextSelector;
  components: Component[];
  setComponents: (updater: (components: Component[]) => Component[]) => void;
  // Return the components to render in a preview.
  // The components rendered get wrapped in a SiteContextProvider and in a CurrentUserProvider.
  onPreview?: (children: ReactNode) => ReactNode;
  previewInSiteUrl?: string | null;
};

export const VisualBuilder: FunctionComponent<VisualBuilderProps> = ({
  siteContext,
  components,
  setComponents,
  onPreview = identity,
  previewInSiteUrl,
}) => {
  const dialog = useDialog();
  const { user } = useCurrentUser();
  const siteSelectorId = useId();
  const setComponent = useCallback<(newComponent: Component) => void>(
    (newComponent) => {
      setComponents((components) => replaceComponentById(components, newComponent));
    },
    [setComponents],
  );

  const findComponent = useCallback<TreeProps['findComponent']>(
    (id) => findComponentById(components, id),
    [components],
  );

  const moveComponent = useCallback<TreeProps['moveComponent']>(
    (id, destinationParentId, afterId) => {
      if (id === afterId) {
        return;
      }
      setComponents((components) => {
        const [newComponents, extractedComponent] = pullComponentById(components, id);
        if (extractedComponent) {
          if (destinationParentId) {
            return insertChildIntoComponent(newComponents, destinationParentId, extractedComponent);
          }
          if (afterId) {
            return insertAfterComponent(newComponents, afterId, extractedComponent);
          }
        }
        return components;
      });
    },
    [setComponents],
  );

  const sc = '__typename' in siteContext ? siteContext : siteContext.siteContext;

  return (
    <Fragment>
      <div className="flex align-items-end justify-content-between mb-3 pb-3 border-bottom-1">
        <div className="flex-grow-1">
          {!('__typename' in siteContext) && (
            <div className="input-field mb-0">
              <label htmlFor={`visualBuilder${siteSelectorId}`}>Site to preview</label>
              <SiteSelector
                siteContextSelector={siteContext}
                inputId={`visualBuilder${siteSelectorId}`}
              />
            </div>
          )}
        </div>
        <div className="flex gap-3 align-items-end">
          <Button
            label="Preview"
            className="p-button-outlined p-button-sm"
            disabled={!sc}
            onClick={() => {
              if (sc) {
                dialog({
                  // TODO: Add support for impersonating a user.
                  content: (
                    <SiteContextProvider value={sc}>
                      <StripeElementsProvider>
                        <CurrentUserProvider user={user} loadingUser={false}>
                          {onPreview(<RenderComponentsStyled components={components} />)}
                        </CurrentUserProvider>
                      </StripeElementsProvider>
                    </SiteContextProvider>
                  ),
                  props: {
                    header: 'Preview',
                    className: 'dialog-width-xl',
                  },
                });
              }
            }}
          />
          {previewInSiteUrl && (
            <a
              href={previewInSiteUrl}
              target="_blank noopener"
              className="p-button p-button-sm text-white w-max flex gap-2 align-items-center"
            >
              <span>Preview in site</span>
              <i className={PrimeIcons.EXTERNAL_LINK} />
            </a>
          )}
        </div>
      </div>
      {sc ? (
        <SiteContextProvider value={sc}>
          <DndProvider backend={HTML5Backend}>
            <Tree
              parent={null}
              components={components}
              setComponent={setComponent}
              setComponents={setComponents}
              findComponent={findComponent}
              moveComponent={moveComponent}
              alwaysShowAddComponentButton={!components.length}
            />
          </DndProvider>
        </SiteContextProvider>
      ) : 'loading' in siteContext && siteContext.loading ? (
        <Spinner />
      ) : (
        <div className="font-bold">Select a site to see the visual builder</div>
      )}
    </Fragment>
  );
};
