import { useQuery } from '@apollo/client';
import { OptionInput } from '@wirechunk/lib/api.js';
import { difference } from 'lodash-es';
import { useCallback, useState } from 'react';
import { withEditSiteContext } from '../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useEditSiteOptions } from '../../../../../../hooks/use-edit-site-options/use-edit-site-options.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../Spinner.js';
import { navItems } from '../../nav.js';
import { SiteTabs } from '../site-tabs.js';
import { AuthenticatedMainNavSection } from './AuthenticatedMainNavSection.js';
import { BrandingSection } from './BrandingSection.js';
import { GeneralSection } from './GeneralSection.js';
import { SiteSettingsDocument } from './queries.generated.js';
import { SidebarExtrasSection } from './SidebarExtrasSection.js';
import { TlsCertificatesSection } from './TlsCertificatesSection.js';

export const Settings = withEditSiteContext(({ site: { id: siteId, name: siteName } }) => {
  const { handle } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(SiteSettingsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { id: siteId },
  });
  const { editOptions } = useEditSiteOptions(siteId, onError);

  const [savingKeys, setSavingKeys] = useState<string[]>([]);

  const saveOptions = useCallback(
    (options: OptionInput[]) => {
      clearMessages();
      const optionsKeys = options.map((opt) => opt.key);
      setSavingKeys((keys) => [...keys, ...optionsKeys]);
      void (async () => {
        try {
          await editOptions(options);
        } finally {
          setSavingKeys((keys) => difference(keys, optionsKeys));
        }
      })();
    },
    [clearMessages, editOptions],
  );

  if (loading) {
    return <Spinner />;
  }

  const site = data?.site;

  return (
    <PageContainer
      title={`Site: ${siteName}`}
      pageMetaTitle={`Site: ${siteName} Settings`}
      nav={navItems(handle)}
    >
      <ErrorMessage />
      {site && (
        <SiteTabs siteId={siteId} activeTab="settings">
          <GeneralSection site={site} savingKeys={savingKeys} saveOptions={saveOptions} />
          <BrandingSection site={site} savingKeys={savingKeys} saveOptions={saveOptions} />
          <AuthenticatedMainNavSection
            site={site}
            savingKeys={savingKeys}
            saveOptions={saveOptions}
          />
          <SidebarExtrasSection site={site} savingKeys={savingKeys} saveOptions={saveOptions} />
          <TlsCertificatesSection site={site} savingKeys={savingKeys} saveOptions={saveOptions} />
        </SiteTabs>
      )}
    </PageContainer>
  );
});
