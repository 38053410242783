import { useMutation } from '@apollo/client';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { CreateSequenceDocument } from './mutations.generated.js';

type CreateSequenceProps = {
  className?: string;
  onCreated: (id: string) => void;
  onCancel: () => void;
};

export const CreateSequence: FunctionComponent<CreateSequenceProps> = ({
  className,
  onCreated,
  onCancel,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [createSequence, { loading: isCreating }] = useMutation(CreateSequenceDocument, {
    onError,
    onCompleted: (data) => {
      if (data.createSequence.__typename === 'CreateSequenceSuccessResult') {
        onCreated(data.createSequence.sequence.id);
      } else {
        onError(data.createSequence.message);
      }
    },
  });
  const [name, setName] = useState('');

  const onCreate = () => {
    clearMessages();
    void createSequence({
      variables: {
        platformId,
        name,
        loopStages: false,
      },
    });
  };

  return (
    <div className={clsx('border-1 border-round p-3', className)}>
      <h3 className="mt-1">Create Sequence</h3>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="newSequenceName">Name</label>
        <InputText
          id="newSequenceName"
          className="w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-3 mt-2">
        <Button label="Add" disabled={!name.trim() || isCreating} onClick={onCreate} />
        <Button
          className="p-button-outlined"
          label="Cancel"
          disabled={isCreating}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
