import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useCreateComponent } from '../../../../../hooks/use-create-custom-component/use-create-component.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';

type CreateComponentProps = {
  className?: string;
  onCancel: () => void;
};

export const CreateComponent: FunctionComponent<CreateComponentProps> = ({
  className,
  onCancel,
}) => {
  const { id: platformId, navigate } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [name, setName] = useState('');
  const { createComponent, loading: createLoading } = useCreateComponent(onError, ({ id }) => {
    navigate(`/components/${id}`);
  });

  const onCreate = () => {
    clearMessages();
    void createComponent({
      platformId,
      name,
    });
  };

  return (
    <div className={clsx('border-1 border-round p-3', className)}>
      <h3 className="mt-1">Create a Component</h3>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="newDesignName">Name</label>
        <InputText
          id="newDesignName"
          className="w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-3 mt-2">
        <Button
          className="p-button-secondary"
          label="Cancel"
          disabled={createLoading}
          onClick={onCancel}
        />
        <Button label="Add" disabled={!name.trim() || createLoading} onClick={onCreate} />
      </div>
    </div>
  );
};
