import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import type { FunctionComponent } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import {
  Entry,
  useScoreMyCallEntries,
} from '../../../../hooks/useScoreMyCallEntries/useScoreMyCallEntries.js';
import { Play } from '../../../icons/Play.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { ScoreMyCallResultsDialog } from '../../../ScoreMyCallResultsDialog/ScoreMyCallResultsDialog.js';

export const ScoreMyCall: FunctionComponent = () => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { entries, isLoading } = useScoreMyCallEntries({
    platformId,
    shareRecording: true,
    onError,
  });
  const dialog = useDialog();

  return (
    <PageContainer title="Score My Call Entries">
      <ErrorMessage />
      <DataTable value={entries} loading={isLoading}>
        <Column
          header="User"
          body={(row: Entry) => (
            <div>
              <div>{row.user.displayName}</div>
              <div className="text-sm">({row.user.email})</div>
            </div>
          )}
        />
        <Column
          header="Prospect’s name"
          body={(row: Entry) =>
            row.prospectName ? (
              <div>{row.prospectName}</div>
            ) : (
              <div className="text-color-muted">Not provided</div>
            )
          }
        />
        <Column
          header="Date"
          body={(row: Entry) => <div>{row.createdAtDate.toLocaleDateString()}</div>}
        />
        <Column
          header="Score"
          body={(row: Entry) => `${row.questionsPassed}/${row.questionsTotal}`}
        />
        <Column
          header=""
          align="right"
          body={(row: Entry) => (
            <div className="flex gap-3 justify-content-end">
              <Button
                className="p-button-outlined"
                onClick={() => {
                  dialog({
                    content: <ScoreMyCallResultsDialog entry={row} />,
                    props: {
                      className: 'dialog-width-xl',
                      header: `${row.user.displayName}: Call ${
                        row.prospectName ? `with ${row.prospectName} ` : ''
                      }on ${row.createdAtDate.toLocaleDateString()}`,
                    },
                  });
                }}
              >
                <div className="flex align-items-center gap-2">
                  <span>Review</span>
                  <Play width="9.6" height="10.8" pathClassName="fill-primary" />
                </div>
              </Button>
            </div>
          )}
        />
      </DataTable>
    </PageContainer>
  );
};
