export enum PageOptionKey {
  Caption = 'Caption',
  FeaturedImageUrl = 'FeaturedImageUrl',
  MetaDescription = 'MetaDescription',
  MetaRobots = 'MetaRobots',
  MetaTitle = 'MetaTitle',
}

// All versioned fields, including fields that are stored directly in Pages and fields stored in PageOptions.
export enum PageOptionVersionKey {
  BodyStyles = 'BodyStyles',
  Caption = PageOptionKey.Caption,
  Components = 'Components',
  FeaturedImageUrl = PageOptionKey.FeaturedImageUrl,
  FeatureTag = 'FeatureTag',
  MetaDescription = PageOptionKey.MetaDescription,
  MetaRobots = PageOptionKey.MetaRobots,
  MetaTitle = PageOptionKey.MetaTitle,
  Path = 'Path',
  ProductItem = 'ProductItem',
  Public = 'Public',
  Roles = 'Roles',
  SpecialPurpose = 'SpecialPurpose',
  Title = 'Title',
}

const pageOptionVersionKeys: PageOptionVersionKey[] = Object.values(PageOptionVersionKey);

export const isPageOptionVersionKey = (value: unknown): value is PageOptionVersionKey =>
  pageOptionVersionKeys.includes(value as never);

export enum MetaRobotsOption {
  NoArchive = 'noarchive',
  NoFollow = 'nofollow',
  NoIndex = 'noindex',
}

export const metaRobotsOptions: MetaRobotsOption[] = [
  MetaRobotsOption.NoArchive,
  MetaRobotsOption.NoFollow,
  MetaRobotsOption.NoIndex,
];

export const isMetaRobotsOption = (value: unknown): value is MetaRobotsOption =>
  metaRobotsOptions.includes(value as never);
