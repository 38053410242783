import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@radix-ui/themes';
import { Permission } from '@wirechunk/lib/api.js';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { clickableRowClassName } from '../../../../../util/clickableRowClassName.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { navItems } from '../nav.js';
import { CreatePageTemplate } from './CreatePageTemplate.js';
import { DuplicatePageTemplateDocument } from './mutations.generated.js';
import { PageTemplatesDocument, PageTemplatesQuery } from './queries.generated.js';

type Row = PageTemplatesQuery['pageTemplates'][number];

export const PageTemplates: FunctionComponent = () => {
  const { toastSuccess } = useToast();
  const { id: platformId, handle, permissions, navigate } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data } = useQuery(PageTemplatesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId },
  });
  const [duplicateTemplate, { loading: isDuplicateLoading }] = useMutation(
    DuplicatePageTemplateDocument,
    {
      onError,
    },
  );

  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const menu = useRef<Menu>(null);
  const [menuPageTemplate, setMenuPageTemplate] = useState<Row | null>(null);

  const hasCreateTemplatePermission = permissions.includes(Permission.CreateTemplate);

  const templates = useMemo<Row[]>(() => {
    if (data?.pageTemplates) {
      return data.pageTemplates;
    }
    return [];
  }, [data?.pageTemplates]);

  return (
    <PageContainer title="Page Templates" nav={navItems(handle)}>
      <ErrorMessage />
      {hasCreateTemplatePermission && (
        <Button
          className="mb-3"
          onClick={() => {
            setShowCreateTemplate(true);
          }}
          disabled={showCreateTemplate}
        >
          Create page template
        </Button>
      )}
      {showCreateTemplate && (
        <CreatePageTemplate
          onCreated={(pageTemplate) => {
            toastSuccess('Page template created.');
            navigate(`/sites/page-templates/${pageTemplate.id}`);
          }}
          onCancel={() => {
            setShowCreateTemplate(false);
          }}
        />
      )}
      <DataTable
        value={templates}
        rowClassName={clickableRowClassName}
        onRowClick={(e) => {
          navigate(`/sites/page-templates/${(e.data as Row).id}`);
        }}
      >
        <Column header="Title" field="title" />
        <Column header="URL path" field="path" body={(row: Row) => <span>/{row.path}</span>} />
        <Column header="Status" field="status" />
        <Column header="Purpose" field="purpose.name" />
        <Column
          header=""
          align="right"
          body={(row: Row) => (
            <button
              className="button-not-styled p-1"
              onClick={(event) => {
                setMenuPageTemplate(row);
                menu.current?.show(event);
              }}
            >
              <i className="pi pi-ellipsis-v" />
            </button>
          )}
        />
      </DataTable>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            disabled: isDuplicateLoading,
            command: () => {
              if (menuPageTemplate) {
                void duplicateTemplate({
                  variables: { templateId: menuPageTemplate.id },
                  onCompleted: (data) => {
                    toastSuccess('Page template duplicated.');
                    navigate(`/sites/page-templates/${data.duplicatePageTemplate.id}`);
                  },
                  update: (cache, { data }) => {
                    if (data) {
                      const tmpls = cache.readQuery({ query: PageTemplatesDocument });
                      if (tmpls?.pageTemplates) {
                        cache.writeQuery({
                          query: PageTemplatesDocument,
                          data: {
                            pageTemplates: [
                              ...tmpls.pageTemplates,
                              data.duplicatePageTemplate,
                            ].sort((a, b) => a.title.localeCompare(b.title)),
                          },
                        });
                      }
                    }
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
};
