import { useQuery } from '@apollo/client';
import { PublishStatus } from '@wirechunk/lib/api.js';
import { FormComponent } from '@wirechunk/lib/mixer/types/components.js';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { ViewMode } from '../../../../contexts/PageContext/PageContext.js';
import { useFormsMinimal } from '../../../../hooks/use-forms-minimal/use-forms-minimal.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import type { SelectItem } from '../../../../types.js';
import { Spinner } from '../../../Spinner.js';
import type { EditComponentContentProps } from '../shared/types.js';
import { FormTemplatesDocument } from './queries.generated.js';

export const EditForm: FunctionComponent<EditComponentContentProps<FormComponent>> = ({
  component,
  setComponent,
  siteContext,
  viewMode,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { id: platformId } = usePlatformContext();
  const { forms, loading: loadingForms } = useFormsMinimal(siteContext.id, onError);
  const { data: formTemplatesData, loading: loadingFormTemplates } = useQuery(
    FormTemplatesDocument,
    {
      onError,
      fetchPolicy: 'cache-and-network',
      variables: { platformId },
    },
  );

  const formsOptions = useMemo<Array<SelectItem<string>> | undefined>(
    () =>
      viewMode === ViewMode.Live
        ? forms?.map((form) => ({ label: form.title, value: form.id }))
        : formTemplatesData?.formTemplates.map((form) => ({
            label:
              form.title + (form.status !== PublishStatus.Published ? ` (${form.status})` : ''),
            value: form.id,
          })),
    [viewMode, forms, formTemplatesData],
  );

  const loading = viewMode === ViewMode.Live ? loadingForms : loadingFormTemplates;

  return (
    <Fragment>
      <ErrorMessage />
      {loading && <Spinner />}
      <div className="input-field">
        <label htmlFor="editFormId">Form</label>
        {formsOptions?.length ? (
          <Dropdown
            inputId="editFormId"
            className="w-full"
            value={component.formId}
            options={formsOptions}
            onChange={({ value }) => {
              if (isString(value)) {
                setComponent((c) => ({ ...c, formId: value }));
              }
            }}
          />
        ) : (
          !loading && <p className="font-italic">No forms are available.</p>
        )}
      </div>
    </Fragment>
  );
};
