type CssProperty =
  | BackgroundProperty
  | BorderProperty
  | BoxProperty
  | FlexContainerProperty
  | FlexItemProperty
  | GapProperty
  | SizeProperty
  | TextProperty;

export type CssProperties = {
  [key in CssProperty]?: string | null;
};

export const backgroundProperties = [
  'background',
  'background-attachment',
  'background-color',
  'background-image',
  'background-position',
  'background-repeat',
  'background-size',
] as const;

type BackgroundProperty = (typeof backgroundProperties)[number];

export const borderProperties = [
  'border',
  'border-color',
  'border-radius',
  'border-style',
  'border-width',
] as const;

type BorderProperty = (typeof borderProperties)[number];

export const boxProperties = [
  'box-shadow',
  'cursor',
  'display',
  'grid-column',
  'grid-row',
  'grid-template-columns',
  'grid-template-rows',
  'margin',
  'margin-top',
  'margin-right',
  'margin-bottom',
  'margin-left',
  'object-fit',
  'object-position',
  'overflow',
  'overflow-x',
  'overflow-y',
  'padding',
  'padding-top',
  'padding-right',
  'padding-bottom',
  'padding-left',
  'transform',
  'transition',
  'visibility',
  'z-index',
] as const;

type BoxProperty = (typeof boxProperties)[number];

export const flexContainerProperties = [
  'align-content',
  'align-items',
  'flex-direction',
  'flex-flow',
  'flex-wrap',
  'justify-content',
  'justify-items',
] as const;

type FlexContainerProperty = (typeof flexContainerProperties)[number];

const flexItemProperties = [
  'align-self',
  'flex-basis',
  'flex-grow',
  'flex-shrink',
  'justify-self',
  'order',
] as const;

type FlexItemProperty = (typeof flexItemProperties)[number];

export const gapProperties = ['column-gap', 'gap', 'row-gap'] as const;

type GapProperty = (typeof gapProperties)[number];

export const sizeProperties = [
  'height',
  'max-height',
  'max-width',
  'min-height',
  'min-width',
  'width',
] as const;

type SizeProperty = (typeof sizeProperties)[number];

export const textProperties = [
  'color',
  'font-family',
  'font-size',
  'font-style',
  'font-weight',
  'line-height',
  'text-align',
  'text-transform',
  'text-decoration',
  'white-space',
  'word-wrap',
] as const;

type TextProperty = (typeof textProperties)[number];

type PropertyGroup = {
  name: string;
  properties: readonly CssProperty[];
  dropdownOptions: ReadonlyArray<keyof CssProperties>;
};

export const propertyGroups: PropertyGroup[] = [
  {
    name: 'Background',
    properties: backgroundProperties,
    dropdownOptions: backgroundProperties,
  },
  {
    name: 'Border',
    properties: borderProperties,
    dropdownOptions: borderProperties,
  },
  {
    name: 'Box',
    properties: boxProperties,
    dropdownOptions: boxProperties,
  },
  {
    name: 'Flex Container',
    properties: flexContainerProperties,
    dropdownOptions: flexContainerProperties,
  },
  {
    name: 'Flex Item',
    properties: flexItemProperties,
    dropdownOptions: flexItemProperties,
  },
  {
    name: 'Gap',
    properties: gapProperties,
    dropdownOptions: gapProperties,
  },
  {
    name: 'Size',
    properties: sizeProperties,
    dropdownOptions: sizeProperties,
  },
  {
    name: 'Text',
    properties: textProperties,
    dropdownOptions: textProperties,
  },
];
