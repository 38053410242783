import {
  OrganizationAgencyPrimaryPhoneComponent,
  PhoneNumberFormat,
} from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import type { EditComponentContentProps } from './shared/types.js';

const formatOptions: Array<{ label: string; value: PhoneNumberFormat }> = [
  { label: 'None (default)', value: PhoneNumberFormat.None },
  { label: 'Verbose', value: PhoneNumberFormat.Verbose },
  { label: 'Dashes', value: PhoneNumberFormat.Dashes },
  { label: 'Spaces', value: PhoneNumberFormat.Spaces },
];

export const EditOrganizationAgencyPrimaryPhone: FunctionComponent<
  EditComponentContentProps<OrganizationAgencyPrimaryPhoneComponent>
> = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editOrganizationAgencyPrimaryPhoneFormat">Format</label>
        <Dropdown
          inputId="editOrganizationAgencyPrimaryPhoneFormat"
          value={component.format}
          options={formatOptions}
          onChange={({ value }) => {
            setComponent((c) => ({ ...c, format: value as PhoneNumberFormat }));
          }}
        />
      </div>
    </Fragment>
  );
};
