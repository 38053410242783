import { StageComponent } from '@wirechunk/lib/mixer/types/components.js';
import { isString } from 'lodash-es';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { ContentPlan } from '../../../../hooks/use-content-plans/use-content-plans.js';
import { usePlanSelectorOptions } from '../../../../hooks/use-plan-selector-options/use-plan-selector-options.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { Spinner } from '../../../Spinner.js';
import type { EditComponentContentProps } from '../shared/types.js';

const isContentSequence = (plan: ContentPlan): boolean => plan.__typename === 'Sequence';

export const EditStage: FunctionComponent<EditComponentContentProps<StageComponent>> = ({
  component,
  setComponent,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { options, loading: loadingPlanOptions } = usePlanSelectorOptions(
    platformId,
    isContentSequence,
    onError,
  );

  return (
    <Fragment>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="editStagePlanId">Content sequence</label>
        {loadingPlanOptions ? (
          <Spinner />
        ) : (
          <Dropdown
            inputId="editStagePlanId"
            className="w-full"
            options={options}
            filter
            value={component.planId || null}
            onChange={({ value }) => {
              if (isString(value)) {
                setComponent(
                  (c): StageComponent => ({
                    ...c,
                    planId: value,
                  }),
                );
              }
            }}
          />
        )}
      </div>
      <div className="input-field flex align-items-center gap-3">
        <Checkbox
          inputId="editStageEnableWrappedStageBody"
          checked={!!component.enableWrappedStageBody}
          onChange={(e) => {
            setComponent(
              (c): StageComponent => ({
                ...c,
                enableWrappedStageBody: e.checked,
              }),
            );
          }}
        />
        <label htmlFor="editStageEnableWrappedStageBody" className="mb-0">
          Enable wrapped stage body
        </label>
      </div>
    </Fragment>
  );
};
