import { InvoiceFrequency } from './api.js';

export const invoiceFrequencyToHumanReadable = (freq: InvoiceFrequency): string => {
  switch (freq) {
    case InvoiceFrequency.SemiAnnually:
      return 'Semi-annually';
    default:
      return freq;
  }
};
