import { FunctionComponent, PropsWithChildren } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { NavTabs, NavTabsProps } from '../../../../nav-tabs.js';

const tabs = ['pages', 'forms', 'settings', 'form-entries'] as const;

type SitesTab = (typeof tabs)[number];

const tabHeader = (tab: SitesTab) => {
  switch (tab) {
    case 'pages':
      return 'Pages';
    case 'forms':
      return 'Forms';
    case 'settings':
      return 'Settings';
    case 'form-entries':
      return 'Form entries';
  }
};

type SitesTabsProps = PropsWithChildren<
  Pick<NavTabsProps<SitesTab>, 'panelClassName'> & {
    siteId: string;
    activeTab: SitesTab;
  }
>;

export const SiteTabs: FunctionComponent<SitesTabsProps> = ({
  siteId,
  activeTab,
  panelClassName,
  children,
}) => {
  const { handle } = usePlatformContext();

  return (
    <NavTabs
      rootPath={`/dashboard/${handle}/sites/${siteId}`}
      tabs={tabs}
      activeTab={activeTab}
      tabHeader={tabHeader}
      panelClassName={panelClassName}
    >
      {children}
    </NavTabs>
  );
};
