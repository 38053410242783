import { useQuery } from '@apollo/client';
import { DesignComponent } from '@wirechunk/lib/mixer/types/components.js';
import { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { FunctionComponent, useMemo } from 'react';
import { useErrorCollector } from '../../../contexts/error-collector-context.js';
import { usePageContext, ViewMode } from '../../../contexts/PageContext/PageContext.js';
import { PropsContextProvider } from '../../../contexts/props-context.js';
import { ErrorHandler } from '../../../hooks/useErrorHandler.js';
import { ParseAndRenderComponents } from '../../ParseAndRenderComponents.js';
import { Spinner } from '../../Spinner.js';
import { CustomComponentDocument, CustomComponentPreviewDocument } from './queries.generated.js';

// This constant is declared here to stay referentially consistent.
const defaultCustomProps: ContextData = {};

type DesignComponentWithId = DesignComponent & {
  dynamicComponentId: string;
  onError: ErrorHandler['onError'];
};

const DesignBody: FunctionComponent<DesignComponentWithId> = ({
  onError,
  dynamicComponentId,
  ...props
}) => {
  const { data, loading } = useQuery(CustomComponentDocument, {
    onError,
    variables: { id: dynamicComponentId },
  });

  // TODO: Replace with Suspense.
  return loading ? (
    <Spinner />
  ) : (
    <PropsContextProvider value={props.customProps ?? defaultCustomProps}>
      <ParseAndRenderComponents componentsJSON={data?.component.components} />
    </PropsContextProvider>
  );
};

const DesignPreviewBody: FunctionComponent<DesignComponentWithId> = ({
  onError,
  dynamicComponentId,
}) => {
  const { data, loading } = useQuery(CustomComponentPreviewDocument, {
    onError,
    variables: { id: dynamicComponentId },
  });
  const previewProps = useMemo(
    () =>
      data?.component.previewProps ? (JSON.parse(data.component.previewProps) as ContextData) : {},
    [data],
  );

  // TODO: Replace with Suspense.
  return loading ? (
    <Spinner />
  ) : (
    <PropsContextProvider value={previewProps}>
      <ParseAndRenderComponents componentsJSON={data?.component.components} />
    </PropsContextProvider>
  );
};

export const Design: FunctionComponent<DesignComponent> = (props) => {
  const { viewMode } = usePageContext();
  // Consider replacing the error collector with an error boundary.
  const { onError } = useErrorCollector();

  const dynamicComponentId = props.customComponentId || props.designId;
  if (dynamicComponentId) {
    return viewMode === ViewMode.Live ? (
      <DesignBody {...props} dynamicComponentId={dynamicComponentId} onError={onError} />
    ) : (
      <DesignPreviewBody {...props} dynamicComponentId={dynamicComponentId} onError={onError} />
    );
  }

  return null;
};
