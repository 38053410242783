import {
  arrayOperators,
  booleanOperators,
  ExpressionOperator,
  expressionOperatorToHumanReadable,
  ExpressionType,
  isExpressionOperator,
  numberOperators,
  stringOperators,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { ArrayExpression } from '@wirechunk/schemas/expressions/array-expression';
import type { BooleanExpression } from '@wirechunk/schemas/expressions/boolean-expression';
import type { NumberExpression } from '@wirechunk/schemas/expressions/number-expression';
import type { StringExpression } from '@wirechunk/schemas/expressions/string-expression';
import { Dropdown } from 'primereact/dropdown';
import { ReactNode, useMemo } from 'react';
import type { OptionGroup, SelectItem } from '../../types.js';

const operatorToSelectItem = <Op extends ExpressionOperator>(operator: Op): SelectItem<Op> => ({
  label: expressionOperatorToHumanReadable(operator),
  value: operator,
});

export const arrayExpressionsGroup: OptionGroup<ArrayExpression['operator']> = {
  label: 'Array Expressions',
  items: arrayOperators.map(operatorToSelectItem),
};

export const booleanExpressionsGroup: OptionGroup<BooleanExpression['operator']> = {
  label: 'Boolean Expressions',
  items: booleanOperators.map(operatorToSelectItem),
};

export const numberExpressionsGroup: OptionGroup<NumberExpression['operator']> = {
  label: 'Number Expressions',
  items: numberOperators.map(operatorToSelectItem),
};

export const stringExpressionsGroup: OptionGroup<StringExpression['operator']> = {
  label: 'String Expressions',
  items: stringOperators.map(operatorToSelectItem),
};

type SelectExpressionButtonProps<T extends ExpressionType> = {
  autoFocus?: boolean;
  types: T | readonly T[];
  value?: ExpressionOperator | null | undefined;
  onAdd: (type: ExpressionOperator) => void;
};

export const SelectExpressionButton = <T extends ExpressionType>({
  autoFocus,
  types,
  value,
  onAdd,
}: SelectExpressionButtonProps<T>): ReactNode => {
  // If there's only one expression type allowed, the dropdown options don't need to be grouped.
  // With more than one type, we group the options by type.
  type Option = OptionGroup<ExpressionOperator> | SelectItem<ExpressionOperator>;

  const topLevelAddExpressionOptions = useMemo<Option[]>(
    () =>
      (Array.isArray(types) ? types : [types]).reduce<Option[]>((acc, type) => {
        switch (type) {
          case ExpressionType.Array:
            return Array.isArray(types) && types.length > 1
              ? [...acc, arrayExpressionsGroup]
              : arrayExpressionsGroup.items;
          case ExpressionType.Boolean:
            return Array.isArray(types) && types.length > 1
              ? [...acc, booleanExpressionsGroup]
              : booleanExpressionsGroup.items;
          case ExpressionType.Number:
            return Array.isArray(types) && types.length > 1
              ? [...acc, numberExpressionsGroup]
              : numberExpressionsGroup.items;
          case ExpressionType.String:
            return Array.isArray(types) && types.length > 1
              ? [...acc, stringExpressionsGroup]
              : stringExpressionsGroup.items;
          default:
            return acc;
        }
      }, []),
    [types],
  );

  const groupedItemTemplate = (option: OptionGroup<BooleanExpression['operator']>) => (
    <div>{option.label}</div>
  );

  return (
    <Dropdown
      placeholder="Add expression"
      className="w-16rem"
      autoFocus={autoFocus}
      value={value || null}
      filter
      options={topLevelAddExpressionOptions}
      optionLabel="label"
      optionGroupLabel={Array.isArray(types) && types.length > 1 ? 'label' : undefined}
      optionGroupChildren={Array.isArray(types) && types.length > 1 ? 'items' : undefined}
      optionGroupTemplate={
        Array.isArray(types) && types.length > 1 ? groupedItemTemplate : undefined
      }
      onChange={(e) => {
        if (isExpressionOperator(e.value)) {
          onAdd(e.value);
        }
      }}
    />
  );
};
