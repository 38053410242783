import { ApolloQueryResult, useQuery } from '@apollo/client';
import { union } from 'lodash-es';
import { useMemo, useState } from 'react';
import type { ErrorHandler } from '../useErrorHandler.js';
import { ProductItemPicklistDocument, ProductItemPicklistQuery } from './queries.generated.js';

type ProductItems = {
  productItems: string[];
  loading: boolean;
  onAddCustomItem: (customItem: string) => void;
  refetch: () => Promise<ApolloQueryResult<ProductItemPicklistQuery>>;
};

export const useProductItemPicklist = (
  platformId: string,
  onError: ErrorHandler['onError'],
): ProductItems => {
  const { data, loading, refetch } = useQuery(ProductItemPicklistDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
    },
  });
  const [customAddedItems, setCustomAddedItems] = useState<string[]>([]);

  const productItems = useMemo(
    () => union(data?.productItemPicklist.productItems ?? [], customAddedItems).sort(),
    [data?.productItemPicklist.productItems, customAddedItems],
  );

  const onAddCustomItem = (customItem: string) => {
    setCustomAddedItems((customAddedItems) => union(customAddedItems, [customItem]));
  };

  return {
    productItems,
    loading,
    onAddCustomItem,
    refetch,
  };
};
