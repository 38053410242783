import { Checkbox } from 'primereact/checkbox';
import { Fragment } from 'react';
import type { EditComponent } from '../../editComponentByType.js';

export const RequiredTab: EditComponent = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field flex align-items-center gap-3">
        <Checkbox
          inputId="editRequiredTabRequired"
          checked={component.required || false}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              required: e.checked,
            }));
          }}
        />
        <label htmlFor="editRequiredTabRequired">Required</label>
      </div>
    </Fragment>
  );
};
