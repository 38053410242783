import { isNumber, isString } from 'lodash-es';
import { Column } from 'primereact/column';
import { useMemo, useState } from 'react';
import { useDisplayFormEntryDialog } from '../../../../../../../../hooks/use-display-form-entry-dialog/use-display-form-entry-dialog.js';
import { useFormEntries } from '../../../../../../../../hooks/use-form-entries/use-form-entries';
import {
  ParsedFormEntry,
  useParsedFormEntries,
} from '../../../../../../../../hooks/use-parsed-form-entries/use-parsed-form-entries.js';
import { useErrorHandler } from '../../../../../../../../hooks/useErrorHandler.js';
import { clickableRowClassName } from '../../../../../../../../util/clickableRowClassName.js';
import { extractFieldsFromEntries } from '../../../../../../../../util/formEntries.js';
import { DownloadCsv } from '../../../../../../../DownloadCsv.js';
import { FormEntryDatesFilter } from '../../../../../../../FormEntryDatesFilter/FormEntryDatesFilter.js';
import { DataTableWithPaginator } from '../../../../../Users/UserDetails/DataTableWithPaginator.js';
import { withEditFormContext } from '../edit-form-context.js';

type Row = ParsedFormEntry;

export const Entries = withEditFormContext(({ form, site: { site } }) => {
  const { onError, ErrorMessage } = useErrorHandler();

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [tableRowsLimit, setTableRowsLimit] = useState(20);

  const { entries, totalCount, loading } = useFormEntries(
    {
      siteId: site.id,
      formId: form.id,
      fromDate,
      toDate,
      page: tablePageIndex,
      limit: tableRowsLimit,
    },
    onError,
  );
  const rows = useParsedFormEntries(entries);
  const displayEntry = useDisplayFormEntryDialog();

  const extractedFields = useMemo(() => (rows ? extractFieldsFromEntries(rows) : []), [rows]);

  return (
    <div className="mt-3">
      <ErrorMessage />
      <div className="flex flex-column md:flex-row gap-4 align-items-center mb-4">
        <FormEntryDatesFilter
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        <DownloadCsv siteId={site.id} formId={form.id} fromDate={fromDate} toDate={toDate} />
      </div>
      <div className="overflow-x-auto">
        <DataTableWithPaginator<Row[]>
          value={rows || []}
          loading={loading}
          rowClassName={clickableRowClassName}
          emptyMessage={
            fromDate || toDate ? 'No entries found with these filters' : 'No entries found'
          }
          onRowClick={(e) => {
            displayEntry(e.data as Row);
          }}
          page={tablePageIndex}
          rows={tableRowsLimit}
          totalRecords={totalCount ?? 0}
          setPage={setTablePageIndex}
          setRows={setTableRowsLimit}
        >
          <Column field="createdAtTimestamp" header="Timestamp" />
          {extractedFields.map(({ name, label }) => (
            <Column
              key={name}
              field={`parsedFormData.${name}`}
              header={label}
              body={(row: Row) => {
                const value = row.parsedFormData[name];
                if (isString(value) || isNumber(value)) {
                  return value;
                }
                if (value === null) {
                  return '(None)';
                }
                return JSON.stringify(value);
              }}
            />
          ))}
        </DataTableWithPaginator>
      </div>
    </div>
  );
});
