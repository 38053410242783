import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.js';
import { CurrentUserPlanDocument, CurrentUserPlanQuery } from './queries.generated.js';

type UserPlan = NonNullable<CurrentUserPlanQuery['me']>['plan'];

type CurrentUserPlan = {
  userPlan: UserPlan | null;
  loading: boolean;
};

export const useCurrentUserPlan = (
  planId: string,
  onError: ErrorHandler['onError'],
): CurrentUserPlan => {
  const { data, loading } = useQuery(CurrentUserPlanDocument, {
    onError,
    variables: { planId },
  });

  return {
    userPlan: data?.me?.plan ?? null,
    loading,
  };
};
