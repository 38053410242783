import { useMutation } from '@apollo/client';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents } from '@wirechunk/lib/mixer/utils.js';
import { Button } from 'primereact/button';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withEditPageContext } from '../../../../../../../../contexts/admin/edit-page-context/edit-page-context.js';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../../../../contexts/PageContext/PageContext.js';
import { useToast } from '../../../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../../../../hooks/useHasUnsavedChanges.js';
import { VisualBuilder } from '../../../../../../../VisualBuilder/VisualBuilder.js';
import { EditPageDocument } from '../mutations.generated.js';

export const PageDesign = withEditPageContext(({ page, site }) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [editPage, { loading: savingEdits }] = useMutation(EditPageDocument, {
    onError,
  });
  const pageContext = useMemo<PageContext>(
    () => ({
      id: page.id,
      title: page.title,
      viewMode: ViewMode.Live,
    }),
    [page.id, page.title],
  );

  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();
  const [components, setComponents] = useState<Component[]>([]);

  useEffect(() => {
    setComponents(parseComponents(page.components));
  }, [page]);

  const setComponentsWrapped = useCallback<Dispatch<SetStateAction<Component[]>>>(
    (components) => {
      setComponents(components);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  return (
    <Fragment>
      <ErrorMessage />
      <div className="flex justify-content-end py-3">
        <Button
          className="p-button-success"
          label="Save page"
          disabled={!hasUnsavedChanges || savingEdits}
          onClick={() => {
            clearMessages();
            void editPage({
              variables: {
                pageId: page.id,
                path: page.path,
                title: page.title,
                metaTitle: page.metaTitle,
                metaDescription: page.metaDescription,
                metaRobots: page.metaRobots,
                status: page.status,
                pagePurposeId: page.purpose.id,
                components: JSON.stringify(components),
                bodyStyles: page.bodyStyles,
                public: page.public,
                roles: page.roles,
                featuredImageUrl: page.featuredImageUrl,
                caption: page.caption,
              },
              onCompleted: () => {
                toastSuccess('Page saved.');
                resetHasUnsavedChanges();
              },
            });
          }}
        />
      </div>
      <VisualBuilder
        siteContext={site.site}
        components={components}
        setComponents={setComponentsWrapped}
        onPreview={(children) => (
          <PageContextProvider value={pageContext}>{children}</PageContextProvider>
        )}
      />
    </Fragment>
  );
});
