import {
  DataSource,
  PhoneLinkComponent,
  PhoneNumber,
} from '@wirechunk/lib/mixer/types/components.js';
import { isDataSource } from '@wirechunk/lib/mixer/utils.js';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { SelectItem } from '../../../types.js';
import { dataSourceOptions } from '../../../util/mixer/util.js';
import type { EditComponentContentProps } from './shared/types.js';

const phoneNumberSourceTypeOptions: Array<SelectItem<Exclude<PhoneNumber['type'], 'custom'>>> = [
  ...dataSourceOptions,
  { label: 'Agency primary phone', value: 'agencyPrimaryPhone' },
];

const isPhoneNumberType = (type: unknown): type is Exclude<PhoneNumber['type'], 'custom'> =>
  isDataSource(type) || type === 'agencyPrimaryPhone';

export const EditPhoneLink: FunctionComponent<EditComponentContentProps<PhoneLinkComponent>> = ({
  component,
  setComponent,
}) => {
  const phoneNumberSourceType: PhoneNumber['type'] =
    !component.phoneNumber ||
    component.phoneNumber.type === 'custom' ||
    component.phoneNumber.type === DataSource.Direct
      ? DataSource.Direct
      : component.phoneNumber.type === DataSource.Prop
        ? DataSource.Prop
        : 'agencyPrimaryPhone';

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editPhoneLinkSource">Phone number source</label>
        <Dropdown
          inputId="editPhoneLinkSource"
          className="w-full"
          options={phoneNumberSourceTypeOptions}
          value={phoneNumberSourceType}
          onChange={({ value: type }) => {
            if (isPhoneNumberType(type)) {
              setComponent(
                (c): PhoneLinkComponent => ({
                  ...c,
                  phoneNumber:
                    type === DataSource.Direct
                      ? { type, phoneNumber: '' }
                      : type === DataSource.Prop
                        ? { type, name: '' }
                        : { type },
                }),
              );
            }
          }}
        />
      </div>
      {(!component.phoneNumber ||
        component.phoneNumber.type === DataSource.Direct ||
        component.phoneNumber.type === 'custom') && (
        <div className="input-field">
          <label htmlFor="editPhoneLinkText">Phone number</label>
          <InputText
            id="editPhoneLinkText"
            className="w-full"
            value={
              !component.phoneNumber
                ? ''
                : component.phoneNumber.type === 'custom'
                  ? component.phoneNumber.text
                  : component.phoneNumber.phoneNumber || ''
            }
            onChange={(e) => {
              setComponent(
                (c): PhoneLinkComponent => ({
                  ...c,
                  phoneNumber: {
                    type: DataSource.Direct,
                    phoneNumber: e.target.value,
                  },
                }),
              );
            }}
          />
        </div>
      )}
      {component.phoneNumber?.type === DataSource.Prop && (
        <div className="input-field">
          <label htmlFor="editPlainTextPropName">Prop name</label>
          <InputText
            id="editPlainTextPropName"
            className="w-full"
            value={component.phoneNumber.name}
            onChange={(e) => {
              const name = e.target.value.trim();
              setComponent((c): PhoneLinkComponent => {
                return {
                  ...c,
                  // Keep the existing phoneNumber properties around (specifically, the direct phone number) in case a user toggles
                  // the different source types.
                  phoneNumber: {
                    ...c.phoneNumber,
                    type: DataSource.Prop,
                    name,
                  } satisfies PhoneNumber,
                };
              });
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
