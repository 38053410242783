import { currentDate } from '@wirechunk/lib/dates.js';
import { useEffect, useState } from 'react';

// This hook gets you the current date in YYYY-MM-DD format and tries to keep it reasonably real-time.
export const useCurrentDate = (): string => {
  const [date, setDate] = useState(currentDate);
  useEffect(() => {
    const vizListener = () => {
      if (document.visibilityState === 'visible') {
        setDate(currentDate());
      }
    };
    document.addEventListener('visibilitychange', vizListener);

    const twoHoursMs = 2 * 60 * 60 * 1000;
    const int = setInterval(() => {
      setDate(currentDate());
    }, twoHoursMs);

    return () => {
      document.removeEventListener('visibilitychange', vizListener);
      clearInterval(int);
    };
  }, []);

  return date;
};
