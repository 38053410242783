import type { ApolloClient } from '@apollo/client';
import { InputComponent, isInputComponent } from '@wirechunk/lib/mixer/types/categories.js';
import {
  Component,
  ComponentType,
  DataSource,
  Name,
} from '@wirechunk/lib/mixer/types/components.js';
import {
  componentHasChildren,
  isValidInputComponent,
  parseComponents,
  ValidInputComponent,
} from '@wirechunk/lib/mixer/utils.js';
import type { SelectItem } from '../../types.js';
import { ComponentComponentsDocument } from './queries.generated.js';

export type ValidInputSource<T extends InputComponent = InputComponent> = T & {
  name: NonNullable<Name['name']>;
  inputChildren?: Map<string, ValidInputComponent> | null;
};

export const extractValidInputComponents = async (
  components: Component[],
  apolloClient: ApolloClient<object>,
  abortController?: AbortController,
  filter?: (component: Component) => boolean,
): Promise<Map<string, ValidInputSource>> => {
  const inputComponents = new Map<string, ValidInputSource>();
  for (const component of components) {
    if (filter && !filter(component)) {
      continue;
    }
    if (isValidInputComponent(component)) {
      if (componentHasChildren(component)) {
        // For an input component with children, we don't include its children inputs in the top-level array.
        // Instead, we extract the valid input components and add them as inputChildren.
        const inputChildren = await extractValidInputComponents(
          component.children,
          apolloClient,
          abortController,
          filter,
        );
        inputComponents.set(component.name, {
          ...component,
          inputChildren,
        });
      } else {
        inputComponents.set(component.name, component);
      }
    } else if (!isInputComponent(component) && componentHasChildren(component)) {
      // Here we're drilling into the children of a non-input component. The extracted valid input components are
      // pushed into the top-level array that we're returning.
      const childrenInputComponents = await extractValidInputComponents(
        component.children,
        apolloClient,
        abortController,
        filter,
      );
      childrenInputComponents.forEach((childInputComponent, key) => {
        inputComponents.set(key, childInputComponent);
      });
    }

    if (component.type === ComponentType.Design) {
      if (!component.designId) {
        continue;
      }
      const { data } = await apolloClient.query({
        query: ComponentComponentsDocument,
        variables: { id: component.designId },
        context: { fetchOptions: abortController ? { signal: abortController.signal } : undefined },
      });
      const designInputComponents = await extractValidInputComponents(
        parseComponents(data.component.components),
        apolloClient,
        abortController,
        filter,
      );
      designInputComponents.forEach((designInputComponent, key) => {
        inputComponents.set(key, designInputComponent);
      });
    }
  }

  return inputComponents;
};

export const dataSourceOptions: Array<SelectItem<DataSource>> = [
  { label: 'Direct', value: DataSource.Direct },
  { label: 'Prop', value: DataSource.Prop },
];
