import { Permission } from '@wirechunk/lib/api.js';
import { FunctionComponent } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { PendingUsers } from './PendingUsers/PendingUsers.js';

export const Home: FunctionComponent = () => {
  const { user } = useCurrentUser();
  const { name, permissions } = usePlatformContext();

  const hasViewPermission = permissions.includes(Permission.View);
  const hasEditUserStatusPermission = permissions.includes(Permission.EditUserStatus);

  return (
    <PageContainer title="" pageMetaTitle={`${name} Admin portal - Dashboard`}>
      <h1 className="text-center text-2xl mt-2">
        Welcome to the {name} admin portal, {user.displayName}!
      </h1>
      {hasViewPermission && hasEditUserStatusPermission && <PendingUsers />}
    </PageContainer>
  );
};
