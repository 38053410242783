import { cleanSmallId } from '@wirechunk/lib/clean-small-id.js';
import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import { Component, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { componentTypes, isComponentType } from '@wirechunk/lib/mixer/utils.js';
import type { RemapPropertiesToUnknown } from '@wirechunk/lib/util-types.js';
import { isPlainObject } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { FunctionComponent, useMemo } from 'react';
import { usePlatformContext } from '../../contexts/admin/platform-context/platform-context.js';
import {
  ComponentName,
  useComponentNames,
} from '../../hooks/use-component-names/use-component-names.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import type { SelectItem } from '../../types.js';

const excludedComponentTypes = new Set([
  ComponentType.BackButton,
  ComponentType.BodyContainer,
  // The dropdown doesn't have a Design option. Instead a specific component is selected.
  ComponentType.Design,
  ComponentType.OrganizationLogo,
  ComponentType.OrganizationName,
  ComponentType.OrganizationTermsOfUse,
  ComponentType.OrganizationPrivacyPolicy,
  ComponentType.Resource,
]);

export const builtInComponentOptions: Array<SelectItem<ComponentType>> = componentTypes
  .filter((t) => !excludedComponentTypes.has(t))
  .map((t) => ({
    label: componentTypeHumanReadable(t),
    value: t,
  }));

const isComponentName = (value: unknown): value is ComponentName =>
  isPlainObject(value) &&
  (value as RemapPropertiesToUnknown<ComponentName>).__typename === 'Component' &&
  !!(value as RemapPropertiesToUnknown<ComponentName>).id;

type Option = SelectItem<ComponentType | ComponentName>;

type AddComponentButtonProps = {
  onSelect: (component: Component) => void;
  className?: string;
};

export const AddComponentButton: FunctionComponent<AddComponentButtonProps> = ({
  onSelect,
  className,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError } = useErrorHandler();
  const { componentNames, loading } = useComponentNames({ platformId, onError });

  const options = useMemo<Option[]>(
    () =>
      [
        ...builtInComponentOptions,
        ...(componentNames ?? []).map((component) => ({
          label: component.name,
          value: component,
        })),
      ].sort((a, b) => a.label.localeCompare(b.label)),
    [componentNames],
  );

  return (
    <Dropdown
      loading={loading}
      placeholder="Add component"
      className={className || 'w-12rem'}
      panelClassName="w-16rem"
      filter
      options={options}
      onChange={({ value }) => {
        if (isComponentType(value)) {
          // We assert this object as a Component because we know that every component has a "type" and an "id" property
          // and that these are the only two required properties.
          onSelect({
            type: value,
            id: cleanSmallId(),
          } as Component);
        } else if (isComponentName(value)) {
          onSelect({
            type: ComponentType.Design,
            id: cleanSmallId(),
            customComponentId: value.id,
          });
        }
      }}
    />
  );
};
