import { useMutation } from '@apollo/client';
import { serializeAddress } from '@wirechunk/lib/addresses.js';
import { SiteOptionKey } from '@wirechunk/lib/site-options.js';
import { intersection } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { BasicIconButton } from '../../../../../BasicIconButton/BasicIconButton.js';
import styles from '../../../../../GoogleMap/google-map.module.css';
import { OptionsSection } from '../../../../../OptionsSection.js';
import {
  SelectGooglePlace,
  SelectGooglePlaceProps,
} from '../../../../../SelectGooglePlace/SelectGooglePlace.js';
import { placeToDescription } from '../../../../../SelectGooglePlace/utils.js';
import { EditSiteDomainDocument } from './mutations.generated.js';
import type { SiteSettingsSectionProps } from './types.js';

export const GeneralSection: FunctionComponent<SiteSettingsSectionProps> = ({
  site,
  savingKeys,
  saveOptions,
}) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();

  const [name, setName] = useState(site.name);
  const [domain, setDomain] = useState(site.domain);
  const [addressLine1, setAddressLine1] = useState(site.addressLine1 || null);
  const [addressLine2, setAddressLine2] = useState(site.addressLine2 || null);
  const [addressCity, setAddressCity] = useState(site.addressCity || null);
  const [addressState, setAddressState] = useState(site.addressState || null);
  const [addressZip, setAddressZip] = useState(site.addressZip || null);
  const [googlePlaceId, setGooglePlaceId] = useState<string | null>(site.googlePlaceId || null);
  const [googleTagManagerId, setGoogleTagManagerId] = useState(site.googleTagManagerId || null);
  const [metaPixelId, setMetaPixelId] = useState(site.metaPixelId || null);
  const [mixpanelToken, setMixpanelToken] = useState(site.mixpanelToken || null);
  const [zendeskChatKey, setZendeskChatKey] = useState(site.zendeskChatKey || null);
  const [termsOfUseUrl, setTermsOfUseUrl] = useState(site.termsOfUseUrl || null);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(site.privacyPolicyUrl || null);

  const [enableEditDomain, setEnableEditDomain] = useState(false);
  const [editSiteDomain, { loading: savingEditDomain }] = useMutation(EditSiteDomainDocument, {
    onError,
  });

  const [googlePlaceDescription, setGooglePlaceDescription] = useState('');

  const setGooglePlace = useCallback<SelectGooglePlaceProps['setPlace']>((place) => {
    setGooglePlaceId(place.place_id);
    setGooglePlaceDescription(placeToDescription(place));
  }, []);

  useEffect(() => {
    setName(site.name);
    setDomain(site.domain);
    setAddressLine1(site.addressLine1 || null);
    setAddressLine2(site.addressLine2 || null);
    setAddressCity(site.addressCity || null);
    setAddressState(site.addressState || null);
    setAddressZip(site.addressZip || null);
    setGooglePlaceId(site.googlePlaceId || null);
    setGoogleTagManagerId(site.googleTagManagerId || null);
    setMetaPixelId(site.metaPixelId || null);
    setMixpanelToken(site.mixpanelToken || null);
    setZendeskChatKey(site.zendeskChatKey || null);
    setTermsOfUseUrl(site.termsOfUseUrl || null);
    setPrivacyPolicyUrl(site.privacyPolicyUrl || null);
  }, [
    site.name,
    site.domain,
    site.addressLine1,
    site.addressLine2,
    site.addressCity,
    site.addressState,
    site.addressZip,
    site.googlePlaceId,
    site.googleTagManagerId,
    site.metaPixelId,
    site.mixpanelToken,
    site.zendeskChatKey,
    site.termsOfUseUrl,
    site.privacyPolicyUrl,
  ]);

  return (
    <OptionsSection
      title="General"
      // Note that we don't use this system of allowSave, getOptions, and saveOptions for the domain.
      allowSave={
        intersection(savingKeys, [
          SiteOptionKey.Name,
          SiteOptionKey.AddressLine1,
          SiteOptionKey.AddressLine2,
          SiteOptionKey.AddressCity,
          SiteOptionKey.AddressState,
          SiteOptionKey.AddressZip,
          SiteOptionKey.GooglePlaceId,
          SiteOptionKey.GoogleTagManagerId,
          SiteOptionKey.MetaPixelId,
          SiteOptionKey.MixpanelToken,
          SiteOptionKey.ZendeskChatKey,
          SiteOptionKey.TermsOfUseUrl,
          SiteOptionKey.PrivacyPolicyUrl,
        ]).length === 0 &&
        (name !== site.name ||
          addressLine1 !== (site.addressLine1 || null) ||
          addressLine2 !== (site.addressLine2 || null) ||
          addressCity !== (site.addressCity || null) ||
          addressState !== (site.addressState || null) ||
          addressZip !== (site.addressZip || null) ||
          googlePlaceId !== (site.googlePlaceId || null) ||
          googleTagManagerId !== (site.googleTagManagerId || null) ||
          metaPixelId !== (site.metaPixelId || null) ||
          mixpanelToken !== (site.mixpanelToken || null) ||
          zendeskChatKey !== (site.zendeskChatKey || null) ||
          termsOfUseUrl !== (site.termsOfUseUrl || null) ||
          privacyPolicyUrl !== (site.privacyPolicyUrl || null))
      }
      getOptions={() => [
        { key: SiteOptionKey.Name, value: name },
        { key: SiteOptionKey.AddressLine1, value: addressLine1 },
        { key: SiteOptionKey.AddressLine2, value: addressLine2 },
        { key: SiteOptionKey.AddressCity, value: addressCity },
        { key: SiteOptionKey.AddressState, value: addressState },
        { key: SiteOptionKey.AddressZip, value: addressZip },
        { key: SiteOptionKey.GooglePlaceId, value: googlePlaceId },
        { key: SiteOptionKey.GoogleTagManagerId, value: googleTagManagerId },
        { key: SiteOptionKey.MetaPixelId, value: metaPixelId },
        { key: SiteOptionKey.MixpanelToken, value: mixpanelToken },
        { key: SiteOptionKey.ZendeskChatKey, value: zendeskChatKey },
        { key: SiteOptionKey.TermsOfUseUrl, value: termsOfUseUrl },
        { key: SiteOptionKey.PrivacyPolicyUrl, value: privacyPolicyUrl },
      ]}
      saveOptions={saveOptions}
    >
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="siteDomain">Domain</label>
        <div className="flex flex-column md:flex-row gap-2">
          <InputText
            id="siteDomain"
            className="w-25rem max-w-full"
            value={domain}
            disabled={!enableEditDomain}
            onChange={(e) => {
              setDomain(e.target.value);
            }}
          />
          {enableEditDomain ? (
            <Fragment>
              <Button
                className="p-button-sm"
                label="Save"
                loading={savingEditDomain}
                disabled={!domain || domain.trim() === site.domain || savingEditDomain}
                onClick={() => {
                  dialog({
                    confirm: `Are you sure you want to change this domain to “${domain}”?`,
                    props: {
                      onAccept: () => {
                        void editSiteDomain({
                          variables: { siteId: site.id, domain },
                          onCompleted: () => {
                            setEnableEditDomain(false);
                            toastSuccess('Domain changed successfully');
                          },
                        });
                      },
                    },
                  });
                }}
              />
              <Button
                className="p-button-secondary p-button-sm"
                label="Cancel"
                disabled={savingEditDomain}
                onClick={() => {
                  setEnableEditDomain(false);
                  setDomain(site.domain);
                }}
              />
            </Fragment>
          ) : (
            <BasicIconButton
              icon={PrimeIcons.PENCIL}
              className="ml-2 text-color-primary"
              onClick={() => {
                setEnableEditDomain(true);
              }}
            />
          )}
        </div>
        {!enableEditDomain && (
          <a
            className="block mt-1"
            href={`${import.meta.env.PROD ? 'https' : 'http'}://${site.domain}`}
            target="_blank"
            rel="noreferrer"
          >
            {site.domain} <i className={PrimeIcons.EXTERNAL_LINK} />
          </a>
        )}
      </div>
      <div className="input-field">
        <label htmlFor="siteName">Name</label>
        <InputText
          id="siteName"
          className="w-25rem max-w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteAddressLine1">Address Line 1</label>
        <InputText
          id="siteAddressLine1"
          className="w-25rem max-w-full"
          value={addressLine1 || ''}
          onChange={(e) => {
            setAddressLine1(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteAddressLine2">Address Line 2</label>
        <InputText
          id="siteAddressLine2"
          className="w-25rem max-w-full"
          value={addressLine2 || ''}
          onChange={(e) => {
            setAddressLine2(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteAddressCity">City</label>
        <InputText
          id="siteAddressCity"
          className="w-25rem max-w-full"
          value={addressCity || ''}
          onChange={(e) => {
            setAddressCity(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteAddressState">State</label>
        <InputText
          id="siteAddressState"
          className="w-25rem max-w-full"
          value={addressState || ''}
          onChange={(e) => {
            setAddressState(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteAddressZip">Zip</label>
        <InputText
          id="siteAddressZip"
          className="w-25rem max-w-full"
          value={addressZip || ''}
          onChange={(e) => {
            setAddressZip(e.target.value);
          }}
        />
      </div>
      <div>
        <span className="font-medium">Google Place: </span>
        {googlePlaceDescription && <span>{googlePlaceDescription}</span>}
      </div>
      {googlePlaceId && (
        <Button
          className="p-button-outlined p-button-sm my-1"
          label="Clear"
          onClick={() => {
            setGooglePlaceId(null);
            setGooglePlaceDescription('');
          }}
        />
      )}
      <SelectGooglePlace
        placeId={googlePlaceId}
        setPlace={setGooglePlace}
        initialFindPlaceAddress={serializeAddress({
          addressLine1: site.addressLine1,
          addressLine2: site.addressLine2,
          addressCity: site.addressCity,
          addressState: site.addressState,
          addressZip: site.addressZip,
        })}
        mapClassName={styles.mediumSize}
      />
      <div className="input-field">
        <label htmlFor="siteGoogleTagManagerId">Google Tag Manager ID</label>
        <InputText
          id="siteGoogleTagManagerId"
          className="w-25rem max-w-full"
          value={googleTagManagerId || ''}
          onChange={(e) => {
            setGoogleTagManagerId(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteMetaPixelId">Meta Pixel ID</label>
        <InputText
          id="siteMetaPixelId"
          className="w-25rem max-w-full"
          value={metaPixelId || ''}
          onChange={(e) => {
            setMetaPixelId(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteMixpanelToken">Mixpanel token</label>
        <InputText
          id="siteMixpanelToken"
          className="w-25rem max-w-full"
          value={mixpanelToken || ''}
          onChange={(e) => {
            setMixpanelToken(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteZendeskChatKey">Zendesk chat key</label>
        <InputText
          id="siteZendeskChatKey"
          className="w-25rem max-w-full"
          value={zendeskChatKey || ''}
          onChange={(e) => {
            setZendeskChatKey(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="siteTermsOfUseUrl">Terms of use URL</label>
        <InputText
          id="siteTermsOfUseUrl"
          className="w-25rem max-w-full"
          value={termsOfUseUrl || ''}
          onChange={(e) => {
            setTermsOfUseUrl(e.target.value);
          }}
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="sitePrivacyPolicyUrl">Privacy policy URL</label>
        <InputText
          id="sitePrivacyPolicyUrl"
          className="w-25rem max-w-full"
          value={privacyPolicyUrl || ''}
          onChange={(e) => {
            setPrivacyPolicyUrl(e.target.value);
          }}
        />
      </div>
    </OptionsSection>
  );
};
