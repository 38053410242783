import { PlatformOptionKey } from '@wirechunk/lib/platform-options.js';
import { intersection } from 'lodash-es';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FunctionComponent, useEffect, useState } from 'react';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { OptionsSection } from '../../../OptionsSection.js';
import type { PlatformSettingsOptionsSectionProps } from './types.js';

export const WebhooksSection: FunctionComponent<PlatformSettingsOptionsSectionProps> = ({
  platform,
  savingKeys,
  saveOptions,
}) => {
  const [webhookRegisterUserSecret, setWebhookRegisterUserSecret] = useState<string | null>(
    platform.webhookRegisterUserSecret || null,
  );
  const [webhookHelpTicketCreatedUrl, setWebhookHelpTicketCreatedUrl] = useState<string | null>(
    platform.webhookHelpTicketCreatedUrl || null,
  );
  const [webhookOrganizationPeerUserCreatedUrl, setWebhookOrganizationPeerUserCreatedUrl] =
    useState<string | null>(platform.webhookOrganizationPeerUserCreatedUrl || null);
  const [webhookSubscriptionActivatedUrl, setWebhookSubscriptionActivatedUrl] = useState<
    string | null
  >(platform.webhookSubscriptionActivatedUrl || null);

  useEffect(() => {
    setWebhookRegisterUserSecret(platform.webhookRegisterUserSecret || null);
    setWebhookHelpTicketCreatedUrl(platform.webhookHelpTicketCreatedUrl || null);
    setWebhookOrganizationPeerUserCreatedUrl(
      platform.webhookOrganizationPeerUserCreatedUrl || null,
    );
    setWebhookSubscriptionActivatedUrl(platform.webhookSubscriptionActivatedUrl || null);
  }, [
    platform.webhookRegisterUserSecret,
    platform.webhookHelpTicketCreatedUrl,
    platform.webhookOrganizationPeerUserCreatedUrl,
    platform.webhookSubscriptionActivatedUrl,
  ]);

  return (
    <OptionsSection
      title="Webhooks"
      allowSave={
        intersection(savingKeys, [
          PlatformOptionKey.WebhookRegisterUserSecret,
          PlatformOptionKey.WebhookHelpTicketCreatedUrl,
          PlatformOptionKey.WebhookOrganizationPeerUserCreatedUrl,
          PlatformOptionKey.WebhookSubscriptionActivatedUrl,
        ]).length === 0 &&
        ((webhookRegisterUserSecret || null) !== (platform.webhookRegisterUserSecret || null) ||
          (webhookHelpTicketCreatedUrl || null) !==
            (platform.webhookHelpTicketCreatedUrl || null) ||
          (webhookSubscriptionActivatedUrl || null) !==
            (platform.webhookSubscriptionActivatedUrl || null) ||
          (webhookOrganizationPeerUserCreatedUrl || null) !==
            (platform.webhookOrganizationPeerUserCreatedUrl || null))
      }
      getOptions={() => [
        { key: PlatformOptionKey.WebhookRegisterUserSecret, value: webhookRegisterUserSecret },
        { key: PlatformOptionKey.WebhookHelpTicketCreatedUrl, value: webhookHelpTicketCreatedUrl },
        {
          key: PlatformOptionKey.WebhookOrganizationPeerUserCreatedUrl,
          value: webhookOrganizationPeerUserCreatedUrl,
        },
        {
          key: PlatformOptionKey.WebhookSubscriptionActivatedUrl,
          value: webhookSubscriptionActivatedUrl,
        },
      ]}
      saveOptions={saveOptions}
    >
      <div className="input-field">
        <label htmlFor="platformWebhookRegisterUserSecret">
          Secret for inbound user registration webhook{' '}
          <span className="text-color-muted">(POST /api/webhooks/register-user)</span>
        </label>
        <Password
          inputId="platformWebhookRegisterUserSecret"
          className="w-full"
          inputClassName="w-full"
          value={webhookRegisterUserSecret || ''}
          onChange={(e) => {
            setWebhookRegisterUserSecret(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
        <InputNotice>
          This is used to verify that the webhook request came from an authorized source.
        </InputNotice>
      </div>
      <div className="input-field">
        <label htmlFor="platformWebhookHelpTicketCreatedUrl">
          URL for help ticket created webhook
        </label>
        <InputText
          id="platformWebhookHelpTicketCreatedUrl"
          className="w-full"
          value={webhookHelpTicketCreatedUrl || ''}
          onChange={(e) => {
            setWebhookHelpTicketCreatedUrl(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformWebhookOrganizationPeerUserCreatedUrl">
          URL for organization peer user created webhook
        </label>
        <InputText
          id="platformWebhookOrganizationPeerUserCreatedUrl"
          className="w-full"
          value={webhookOrganizationPeerUserCreatedUrl || ''}
          onChange={(e) => {
            setWebhookOrganizationPeerUserCreatedUrl(e.target.value);
          }}
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="platformWebhookSubscriptionActivatedUrl">
          URL for subscription activated webhook
        </label>
        <InputText
          id="platformWebhookSubscriptionActivatedUrl"
          className="w-full"
          value={webhookSubscriptionActivatedUrl || ''}
          onChange={(e) => {
            setWebhookSubscriptionActivatedUrl(e.target.value);
          }}
        />
      </div>
    </OptionsSection>
  );
};
