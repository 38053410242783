import { Permission } from '@wirechunk/lib/api.js';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../contexts/admin/platform-context/platform-context.js';
import { MenuItem } from '../../DashboardLayout/Sidebar/MenuItem/MenuItem.js';

type SidebarInnerProps = {
  onHide: () => void;
};

export const SidebarInner: FunctionComponent<SidebarInnerProps> = ({ onHide }) => {
  const { permissions, handle } = usePlatformContext();

  const hasEditPermission = permissions.includes(Permission.Edit);
  const hasCreateApiTokenPermission = permissions.includes(Permission.CreateApiToken);
  const canView = permissions.includes(Permission.View);
  const canViewSite =
    permissions.includes(Permission.ViewCustomerSite) ||
    permissions.includes(Permission.ViewPlatformSite);
  const canViewCustomComponents = permissions.includes(Permission.ViewCustomComponent);

  const to = (path: string) => `/dashboard/${handle}${path}`;

  return (
    <Fragment>
      <MenuItem title="Dashboard" to={to('')} onHide={onHide} end />
      {canView && (
        <Fragment>
          <MenuItem title="Users" to={to('/users')} onHide={onHide} />
          <MenuItem title="Orgs" to={to('/organizations')} onHide={onHide} />
          <MenuItem title="Help Tickets" to={to('/help-tickets')} onHide={onHide} />
          <MenuItem title="Score My Call Entries" to={to('/score-my-call')} onHide={onHide} />
        </Fragment>
      )}
      {canViewSite && <MenuItem title="Sites" to={to('/sites')} onHide={onHide} />}
      {canViewCustomComponents && (
        <MenuItem title="Components" to={to('/components')} onHide={onHide} />
      )}
      {hasEditPermission && (
        <Fragment>
          <MenuItem title="Content" to={to('/content/courses')} onHide={onHide} />
          <MenuItem title="Products" to={to('/products')} onHide={onHide} />
        </Fragment>
      )}
      {canView && <MenuItem title="Subscriptions" to={to('/subscriptions')} onHide={onHide} />}
      {hasEditPermission && (
        <MenuItem title="Admin Users" to={to('/admin-users')} onHide={onHide} />
      )}
      {hasCreateApiTokenPermission && (
        <MenuItem title="API Tokens" to={to('/api-tokens')} onHide={onHide} />
      )}
      {canView && (
        <Fragment>
          <MenuItem title="Settings" to={to('/settings')} onHide={onHide} />
          <MenuItem title="Audit Logs" to={to('/audit-logs')} onHide={onHide} />
        </Fragment>
      )}
    </Fragment>
  );
};
