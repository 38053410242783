import { useMutation, useQuery } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { roundToDayEnd } from '@wirechunk/lib/dates.js';
import { Calendar } from 'primereact/calendar';
import { FunctionComponent, useEffect, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { EditableMode } from '../../../../EditableMode/EditableMode.js';
import { NoneLabel } from '../../../../NoneLabel/NoneLabel.js';
import { Spinner } from '../../../../Spinner.js';
import { UserTrainingDetails } from '../../../../UserTrainingDetails/UserTrainingDetails.js';
import type { UserRow } from '../types.js';
import { EditUserDocument } from './mutations.generated.js';
import { UserDetailsDocument } from './queries.generated.js';
import { UserPlanPositions } from './UserPlanPositions.js';

const dateFmt = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

type UserDetailsProps = {
  user: UserRow;
  contentPlanIdsToShowProgress: string[];
};

export const UserDetails: FunctionComponent<UserDetailsProps> = ({
  user,
  contentPlanIdsToShowProgress,
}) => {
  const { toastSuccess } = useToast();
  const { onError, ErrorMessage } = useErrorHandler();
  const { permissions } = usePlatformContext();
  const { data, loading } = useQuery(UserDetailsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { id: user.id },
  });
  const [editUser, { loading: editUserLoading }] = useMutation(EditUserDocument, {
    onError,
  });

  const [expiresAtEditInstanceKey, setExpiresAtEditInstanceKey] = useState(
    data?.user.expiresAt ?? '',
  );
  const [expiresAt, setExpiresAt] = useState<Date | null | undefined>(
    data?.user.expiresAt ? new Date(data.user.expiresAt) : null,
  );

  useEffect(() => {
    setExpiresAt(data?.user.expiresAt ? new Date(data.user.expiresAt) : null);
    setExpiresAtEditInstanceKey(data?.user.expiresAt ?? '');
  }, [data?.user.expiresAt]);

  const handleCancelEditExpiresAt = () => {
    setExpiresAt(data?.user.expiresAt ? new Date(data.user.expiresAt) : null);
  };

  return (
    <div>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        data && (
          <div className="flex flex-column gap-3">
            <div>
              <span>Product items:</span>{' '}
              {data.user.productItems.length ? data.user.productItems.join(', ') : <NoneLabel />}
            </div>
            <div className="flex align-items-center gap-1">
              <span>Expiration:</span>
              <EditableMode
                key={expiresAtEditInstanceKey}
                editMode={() => (
                  <Calendar
                    disabled={editUserLoading}
                    value={expiresAt}
                    onChange={({ value }) => {
                      if (value) {
                        setExpiresAt(roundToDayEnd(value));
                      } else {
                        setExpiresAt(null);
                      }
                    }}
                  />
                )}
                onSave={() => {
                  if (editUserLoading) {
                    return;
                  }
                  void editUser({
                    variables: {
                      input: {
                        id: data.user.id,
                        expiresAt: expiresAt ? { value: expiresAt } : { clear: true },
                      },
                    },
                    onCompleted: (data) => {
                      if (data.editUser.__typename === 'GenericUserError') {
                        onError(data.editUser.message);
                      } else {
                        toastSuccess('Expiration updated.');
                      }
                    },
                  });
                }}
                onCancel={handleCancelEditExpiresAt}
              >
                {data.user.expiresAt ? (
                  <span className="font-medium">
                    {dateFmt.format(new Date(data.user.expiresAt))}
                  </span>
                ) : (
                  <NoneLabel />
                )}
              </EditableMode>
            </div>
            <div>
              <span>Password status:</span>{' '}
              <span className="font-medium">{data.user.passwordStatus}</span>
            </div>
            <div>
              <span>Password expiration:</span>{' '}
              {data.user.passwordExpiresAt ? (
                <span className="font-medium">
                  {dateFmt.format(new Date(data.user.passwordExpiresAt))}
                </span>
              ) : (
                <NoneLabel />
              )}
            </div>
            <UserTrainingDetails
              userId={data.user.id}
              contentPlanIdsToShowProgress={contentPlanIdsToShowProgress}
            />
            <UserPlanPositions
              user={data.user}
              canEdit={permissions.includes(Permission.EditUserPlanStageBlueprintId)}
            />
          </div>
        )
      )}
    </div>
  );
};
