import { useQuery } from '@apollo/client';
import { setUser as setSentryUser } from '@sentry/browser';
import { FunctionComponent, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { apolloClient } from '../apollo-client.js';
import { CurrentUserProvider } from '../contexts/CurrentUserContext/CurrentUserContext.js';
import { MeDocument } from '../contexts/CurrentUserContext/queries.generated.js';
import {
  ErrorCollectorContext,
  ErrorCollectorContextProviderDirect,
  useErrorCollector,
} from '../contexts/error-collector-context.js';
import {
  SessionStatus,
  SessionStatusContext,
  SessionStatusContextProvider,
} from '../contexts/SessionStatusContext/SessionStatusContext.js';
import { useErrorHandler } from '../hooks/useErrorHandler.js';
import { useRefreshSession } from '../hooks/useRefreshSession/useRefreshSession.js';
import { triggerGoogleTagManagerEvent } from '../util/analyticsScripts.js';
import { unauthenticatedErrorHandler } from '../util/errors.js';
import { authenticateMessenger } from '../util/zendesk.js';

export const SessionUserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onErrorToast } = useErrorHandler();
  const { data: meData, loading } = useQuery(MeDocument, { onError: onErrorToast });
  useRefreshSession();

  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.SignedOut);
  const [sessionErrorMessage, setSessionErrorMessage] = useState<string | null>(null);

  const sessionStatusContext = useMemo<SessionStatusContext>(
    () => ({
      status: sessionStatus,
      setStatus: setSessionStatus,
      errorMessage: sessionErrorMessage,
      setErrorMessage: setSessionErrorMessage,
    }),
    [sessionErrorMessage, sessionStatus],
  );

  // Handle error when a user makes a request when they are not signed in, including when their session has expired.
  const { onError: contextOnError } = useErrorCollector();
  const onError = useMemo<ErrorCollectorContext>(
    () => ({
      onError: (error) => {
        // If we get a non-empty value from offlineErrorHandler, then we still have an error to handle.
        const messageNotOfflineError = unauthenticatedErrorHandler(error, sessionStatusContext);
        if (messageNotOfflineError) {
          contextOnError(messageNotOfflineError);
        }
      },
    }),
    [contextOnError, sessionStatusContext],
  );

  const me = meData?.me || null;

  useEffect(() => {
    if (me) {
      authenticateMessenger(apolloClient);
      triggerGoogleTagManagerEvent({
        user_id: me.id,
      });
      triggerGoogleTagManagerEvent({
        event: 'user_authenticated',
      });
      setSentryUser({
        id: me.id,
        email: me.email,
      });
      setSessionStatus(SessionStatus.Active);
    }
  }, [me]);

  return (
    <SessionStatusContextProvider value={sessionStatusContext}>
      <CurrentUserProvider user={me} loadingUser={loading}>
        <ErrorCollectorContextProviderDirect value={onError}>
          {children}
        </ErrorCollectorContextProviderDirect>
      </CurrentUserProvider>
    </SessionStatusContextProvider>
  );
};
