export const defaultFormattedDataTemplate = `{{#each .}}{{@key}}: {{{.}}}
{{/each}}`;

export const siteDomainKey = 'siteDomain';
export const formattedDataKey = 'formattedData';

// Also provided for email templates:
export const entryIdKey = 'entryId';

// Required properties:
// - siteDomain
// - formattedData
export const defaultNotificationEmailBodyTemplate = `Form entry from your site {{${siteDomainKey}}}:

{{{${formattedDataKey}}}}
`;
