import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useState } from 'react';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { DuplicateComponentDocument } from './mutations.generated.js';

type DuplicateComponentProps = {
  id: string;
  onDuplicated: (data: {
    __typename: 'Component';
    id: string;
    name: string;
    components: string;
  }) => void;
};

export const DuplicateComponent: FunctionComponent<DuplicateComponentProps> = ({
  id,
  onDuplicated,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [duplicateComponent, { loading: duplicateLoading }] = useMutation(
    DuplicateComponentDocument,
    {
      onError,
      onCompleted: (data) => {
        if (data.duplicateComponent.__typename === 'DuplicateComponentSuccessResult') {
          onDuplicated(data.duplicateComponent.component);
        } else {
          onError(data.duplicateComponent.message);
        }
      },
    },
  );
  const [name, setName] = useState('');

  return (
    <Fragment>
      <ErrorMessage />
      <p>Provide a name for the new component.</p>
      <div className="flex gap-3 align-items-end">
        <div className="input-field my-0 flex-grow-1">
          <label htmlFor="duplicateComponentName">Name</label>
          <InputText
            id="duplicateComponentName"
            className="w-full"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <Button
          className="w-max min-w-max"
          label="Duplicate"
          disabled={!name.trim() || duplicateLoading}
          onClick={() => {
            clearMessages();
            void duplicateComponent({
              variables: { input: { id, name } },
            });
          }}
        />
      </div>
    </Fragment>
  );
};
