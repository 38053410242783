import type { CssProperties } from '@wirechunk/lib/mixer/cssProperties.js';
import type { BaseStyles } from '@wirechunk/lib/mixer/types/components.js';
import { useCallback, useMemo } from 'react';

export type StylesEdit = {
  setProperty: (scope: keyof BaseStyles, property: keyof CssProperties, value: string) => void;
  removeProperty: (scope: keyof BaseStyles, property: keyof CssProperties) => void;
};

export const useStylesEdit = <C extends BaseStyles>(
  setStyles: (setStyles: (styles: C) => C) => void,
): StylesEdit => {
  const setProperty = useCallback<StylesEdit['setProperty']>(
    (scope, property, value) => {
      setStyles(
        (prevStyles): C => ({
          ...prevStyles,
          [scope]: {
            ...prevStyles[scope],
            [property]: value,
          },
        }),
      );
    },
    [setStyles],
  );

  const removeProperty = useCallback<StylesEdit['removeProperty']>(
    (scope, property) => {
      setStyles(
        (prevStyles): C => ({
          ...prevStyles,
          [scope]: {
            ...prevStyles[scope],
            [property]: undefined,
          },
        }),
      );
    },
    [setStyles],
  );

  return useMemo<StylesEdit>(
    () => ({
      setProperty,
      removeProperty,
    }),
    [setProperty, removeProperty],
  );
};
