import { DataSource, ImageComponent, ImageSource } from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { SelectItem } from '../../../types.js';
import { dataSourceOptions } from '../../../util/mixer/util.js';
import type { EditComponentContentProps } from './shared/types.js';

const srcTypeDropdownOptions: Array<SelectItem<Exclude<ImageSource['type'], 'URL'>>> = [
  ...dataSourceOptions,
  { label: 'Site Logo', value: 'OrganizationLogoUrl' },
  { label: 'Agent Photo', value: 'OrganizationAgentPhotoUrl' },
];

export const EditImage: FunctionComponent<EditComponentContentProps<ImageComponent>> = ({
  component,
  setComponent,
}) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editImageSourceType">Source type</label>
      <Dropdown
        inputId="editImageSourceType"
        className="w-full"
        options={srcTypeDropdownOptions}
        value={
          component.src?.type === 'URL'
            ? DataSource.Direct
            : component.src?.type || DataSource.Direct
        }
        onChange={(e) => {
          const type = e.value as Exclude<ImageSource['type'], 'URL'>;
          setComponent(
            (c): ImageComponent => ({
              ...c,
              src:
                type === DataSource.Direct
                  ? ({ type, url: '' } satisfies ImageSource)
                  : type === DataSource.Prop
                    ? ({ type, name: '' } satisfies ImageSource)
                    : ({ type } satisfies ImageSource),
            }),
          );
        }}
      />
    </div>
    {(!component.src ||
      component.src.type === 'URL' ||
      component.src.type === DataSource.Direct) && (
      <div className="input-field">
        <label htmlFor="editImageUrl">URL</label>
        <InputText
          id="editImageUrl"
          className="w-full"
          value={component.src?.url || ''}
          onChange={(e) => {
            setComponent(
              (c): ImageComponent => ({
                ...c,
                src: { type: DataSource.Direct, url: e.target.value } satisfies ImageSource,
              }),
            );
          }}
        />
      </div>
    )}
    {component.src?.type === DataSource.Prop && (
      <div className="input-field">
        <label htmlFor="editImagePropName">Prop name</label>
        <InputText
          id="editImagePropName"
          className="w-full"
          value={component.src.name || ''}
          onChange={(e) => {
            setComponent(
              (c): ImageComponent => ({
                ...c,
                src: {
                  ...c.src,
                  type: DataSource.Prop,
                  name: e.target.value,
                } satisfies ImageSource,
              }),
            );
          }}
        />
      </div>
    )}
    <div className="input-field">
      <label htmlFor="editImageAlt">Alt text</label>
      <InputText
        id="editImageAlt"
        className="w-full"
        value={component.alt || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, alt: e.target.value }));
        }}
      />
    </div>
  </Fragment>
);
