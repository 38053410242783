/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type SubscriptionAuditLogsQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  actionByUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  subscriptionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  organizationId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
}>;

export type SubscriptionAuditLogsQuery = {
  subscriptionAuditLogs: {
    __typename: 'SubscriptionAuditLogsList';
    totalCount: number;
    logs: Array<{
      __typename: 'SubscriptionAuditLog';
      id: string;
      action: string;
      createdAt: string;
      subscription: {
        __typename: 'Sub';
        id: string;
        plan: { __typename: 'SubscriptionPlan'; id: string; name: string };
      };
      organization?: {
        __typename: 'Organization';
        id: string;
        name?: string | null;
        primaryUser?: { __typename: 'User'; id: string; displayName: string; email: string } | null;
      } | null;
      actionByUser: { __typename: 'UserProfile'; id: string; displayName: string; email: string };
    }>;
  };
};

export type UserAuditLogsQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  affectedUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  actionByUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
}>;

export type UserAuditLogsQuery = {
  userAuditLogs: {
    __typename: 'UserAuditLogsList';
    totalCount: number;
    logs: Array<{
      __typename: 'UserAuditLog';
      id: string;
      action: string;
      notes?: string | null;
      createdAt: string;
      user: { __typename: 'UserProfile'; id: string; email: string; displayName: string };
      actionByUser: { __typename: 'UserProfile'; id: string; email: string; displayName: string };
    }>;
  };
};

export const SubscriptionAuditLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubscriptionAuditLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionByUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionAuditLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actionByUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionByUserId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscription' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'plan' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actionByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionAuditLogsQuery, SubscriptionAuditLogsQueryVariables>;
export const UserAuditLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserAuditLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'affectedUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionByUserId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAuditLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'affectedUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'affectedUserId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actionByUserId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionByUserId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actionByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAuditLogsQuery, UserAuditLogsQueryVariables>;
