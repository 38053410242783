import { useQuery } from '@apollo/client';
import { formatDateTime } from '@wirechunk/lib/dates.js';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent, useState } from 'react';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useAdminUsers } from '../../../../hooks/useAdminUsers/useAdminUsers.js';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { UserSelector } from '../../../UserSelector/UserSelector.js';
import { DataTableWithPaginator } from '../Users/UserDetails/DataTableWithPaginator.js';
import { UserAuditLogsDocument, UserAuditLogsQuery } from './queries.generated.js';

type Row = UserAuditLogsQuery['userAuditLogs']['logs'][number];

type UserAuditLogsProps = {
  platformId: string;
  onError: ErrorHandler['onError'];
};

export const UserAuditLogs: FunctionComponent<UserAuditLogsProps> = ({ platformId, onError }) => {
  const { user } = useCurrentUser();
  const [adminUserId, setAdminUserId] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const { data, loading: isLoadingAuditLogs } = useQuery(UserAuditLogsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      affectedUserId: userId,
      actionByUserId: adminUserId,
      limit: rows,
      page,
    },
  });
  const { selectItems: adminUserSelectItems } = useAdminUsers(user.organization.id, onError);

  const parseActingUser = (row: Row) => (
    <Fragment>
      <div>{row.actionByUser.displayName}</div>
      <div className="text-sm">({row.actionByUser.email})</div>
    </Fragment>
  );

  return (
    <Fragment>
      <div className="surface-ground p-3">
        <div className="flex flex-column lg:flex-row lg:justify-content-start lg:align-items-center gap-3">
          <UserSelector
            platformId={platformId}
            onError={onError}
            placeholder="Select affected user"
            userId={userId}
            setUserId={setUserId}
          />
          <Dropdown
            className="w-17rem"
            value={adminUserId}
            options={adminUserSelectItems}
            placeholder="Select acting admin user"
            onChange={(e) => {
              setAdminUserId(e.value as string | null);
              setPage(0);
            }}
            showClear
          />
        </div>
      </div>
      <DataTableWithPaginator
        value={data?.userAuditLogs.logs || []}
        loading={isLoadingAuditLogs}
        page={page}
        rows={rows}
        totalRecords={data?.userAuditLogs.totalCount || 0}
        setPage={setPage}
        setRows={setRows}
        emptyMessage="No matching audit logs found"
      >
        <Column
          header="Timestamp"
          className="lg:w-13rem lg:max-w-13rem"
          body={(row: Row) => <span>{formatDateTime(row.createdAt)}</span>}
        />
        <Column header="Acting user" body={(row: Row) => parseActingUser(row)} />
        <Column
          header="Affected user"
          body={(row: Row) => (
            <Fragment>
              <div>{row.user.displayName}</div>
              <div className="text-sm">({row.user.email})</div>
            </Fragment>
          )}
        />
        <Column
          header="Action"
          body={(row: Row) => (
            <div className="flex flex-column gap-1">
              <span>{row.action}</span>
              {row.notes && (
                <span className="p-1 bg-gray-100 white-space-pre-line border-round-sm">
                  {row.notes}
                </span>
              )}
            </div>
          )}
        />
      </DataTableWithPaginator>
    </Fragment>
  );
};
