import { useQuery } from '@apollo/client';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../contexts/PageContext/PageContext.js';
import { PropsContextProvider } from '../../contexts/props-context.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { tryParseObject } from '../../util/json.js';
import { ParseAndRenderPage } from '../ParseAndRenderPage.js';
import { Spinner } from '../Spinner.js';
import { CurrentSitePageDocument } from './queries.generated.js';

export const Page: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(CurrentSitePageDocument, {
    onError,
    variables: {
      path: pathname,
    },
  });

  const pageContext = useMemo<PageContext | null>(
    () =>
      data?.currentSite.page
        ? {
            id: data.currentSite.page.id,
            title: data.currentSite.page.title,
            viewMode: ViewMode.Live,
          }
        : null,
    [data],
  );
  const propsContext = useMemo<ContextData>(
    () =>
      data?.currentSite.page?.props
        ? (tryParseObject(data.currentSite.page.props) as ContextData)
        : {},
    [data],
  );

  const page = data?.currentSite.page;

  return (
    <Fragment>
      <Helmet>
        <title>{page?.metaTitle || page?.title || ''}</title>
        {page?.metaDescription ? <meta name="description" content={page.metaDescription} /> : null}
        {page?.metaRobots?.length ? (
          <meta name="robots" content={page.metaRobots.join(', ')} />
        ) : null}
      </Helmet>
      <ErrorMessage />
      {page && pageContext ? (
        <PageContextProvider value={pageContext}>
          <PropsContextProvider value={propsContext}>
            <ParseAndRenderPage componentsJSON={page.components} bodyStylesJSON={page.bodyStyles} />
          </PropsContextProvider>
        </PageContextProvider>
      ) : loading ? (
        <Spinner />
      ) : (
        <div className="p-3 xl:p-4">
          No page was found here. It’s possible that you don’t have permission to view this page.
        </div>
      )}
    </Fragment>
  );
};
