import { collectAllCss } from '@wirechunk/lib/mixer/styles.js';
import type { Component } from '@wirechunk/lib/mixer/types/components.js';
import { Fragment, FunctionComponent, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { RenderMixerChildren } from './RenderMixerChildren.js';

type RenderComponentsStyledProps = {
  components: Component[];
};

const RCS: FunctionComponent<RenderComponentsStyledProps> = ({ components }) => {
  const css = useMemo(() => components.reduce(collectAllCss, ''), [components]);

  return (
    <Fragment>
      <Helmet>
        <style>{css}</style>
      </Helmet>
      <RenderMixerChildren>{components}</RenderMixerChildren>
    </Fragment>
  );
};

export const RenderComponentsStyled = memo<RenderComponentsStyledProps>(RCS);
