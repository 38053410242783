import { useMutation } from '@apollo/client';
import { Button } from '@radix-ui/themes';
import type { PageTemplate } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { usePagePurposes } from '../../../../../hooks/use-page-purposes/use-page-purposes.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { CreatePageTemplateDocument } from './mutations.generated.js';

type CreatePageTemplateProps = {
  className?: string;
  onCreated: (pageTemplate: Pick<PageTemplate, 'id'>) => void;
  onCancel: () => void;
};

export const CreatePageTemplate: FunctionComponent<CreatePageTemplateProps> = ({
  className,
  onCreated,
  onCancel,
}) => {
  const { id: platformId } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { pagePurposes, loading: loadingPagePurposes } = usePagePurposes(platformId, onError);
  const [createPageTemplate, { loading: creating }] = useMutation(CreatePageTemplateDocument, {
    onError,
  });

  const [title, setTitle] = useState('');
  const [path, setPath] = useState('');
  const [pagePurposeId, setPagePurposeId] = useState<string | null>(null);

  const onAdd = () => {
    clearMessages();
    if (!pagePurposeId) {
      onError('Please select a purpose for the page template.');
      return;
    }
    void createPageTemplate({
      variables: {
        platformId,
        title,
        path,
        pagePurposeId,
      },
      onCompleted: (data) => {
        onCreated(data.createPageTemplate);
      },
    });
  };

  return (
    <div className={clsx('border-1 border-round p-4', className)}>
      <h3 className="mt-1">Create a Page Template</h3>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          className="w-18rem"
          autoFocus
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="path">URL path</label>
        <InputText
          id="path"
          className="w-18rem"
          value={path}
          onChange={(e) => {
            setPath(e.target.value);
          }}
        />
      </div>
      <div className="input-field mb-1">
        <label htmlFor="pageTemplatePurposeId">Purpose</label>
        <Dropdown
          className="w-full"
          loading={loadingPagePurposes}
          inputId="pageTemplatePurposeId"
          value={pagePurposeId}
          options={pagePurposes || []}
          optionLabel="name"
          optionValue="id"
          onChange={({ value }) => {
            if (isString(value)) {
              setPagePurposeId(value);
            }
          }}
          showClear={false}
        />
      </div>
      <div className="flex gap-3 mt-3">
        <Button disabled={creating || !title.trim() || !pagePurposeId} onClick={onAdd}>
          Create
        </Button>
        <Button variant="soft" color="gray" disabled={creating} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
