import { ComponentType, createContext, FunctionComponent, useContext } from 'react';
import type { SiteContext } from '../../SiteContext/SiteContext.js';

export type EditSiteContext = {
  site: SiteContext;
  refetchSite: () => void;
};

const context = createContext<EditSiteContext | null>(null);

export const EditSiteContextProvider = context.Provider;

export const useEditSiteContext = (): EditSiteContext | null => useContext(context);

export const withEditSiteContext =
  <P,>(Component: ComponentType<P & EditSiteContext>): FunctionComponent<P> =>
  (props: P) => {
    const value = useEditSiteContext();
    if (!value) {
      throw new Error('No EditSiteContext value is available');
    }
    return <Component {...props} {...value} />;
  };
