import { useMutation } from '@apollo/client';
import { Permission } from '@wirechunk/lib/api.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Fragment, FunctionComponent, MouseEventHandler, useState } from 'react';
import { withEditSiteContext } from '../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import {
  Form,
  useFormsMinimal,
} from '../../../../../../hooks/use-forms-minimal/use-forms-minimal.js';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { useMenuSetup } from '../../../../../../hooks/useMenuSetup.js';
import { clickableRowClassName } from '../../../../../../util/clickableRowClassName.js';
import { PageContainer } from '../../../../../PageContainer/PageContainer.js';
import { ThreeDotMenuButton } from '../../../../../ThreeDotMenuButton/ThreeDotMenuButton.js';
import { navItems } from '../../nav.js';
import { SiteTabs } from '../site-tabs.js';
import {
  CreateFormDocument,
  CreateFormMutation,
  DuplicateFormDocument,
  DuplicateFormMutation,
} from './mutations.generated.js';

type CreateFormProps = {
  siteId: string;
  onCreated: (data: CreateFormMutation['createForm']) => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
};

const CreateForm: FunctionComponent<CreateFormProps> = ({ siteId, onCreated, onCancel }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [createForm, { loading: creating }] = useMutation(CreateFormDocument, {
    onError,
  });
  const [title, setTitle] = useState('');

  return (
    <Fragment>
      <h3 className="mt-1">Create a Form</h3>
      <ErrorMessage />
      <div className="input-field">
        <label htmlFor="newFormTitle">Title</label>
        <InputText
          id="newFormTitle"
          className="w-25rem max-w-full"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-3 mt-3">
        <Button
          className="w-max min-w-max"
          label="Create"
          disabled={!title.trim() || creating}
          onClick={() => {
            void createForm({
              variables: { siteId, title },
              onCompleted: (data) => {
                onCreated(data.createForm);
              },
            });
          }}
        />
        <Button className="p-button-secondary w-max min-w-max" label="Cancel" onClick={onCancel} />
      </div>
    </Fragment>
  );
};

type DuplicateFormProps = {
  id: string;
  onDuplicated: (data: DuplicateFormMutation['duplicateForm']) => void;
};

const DuplicateForm: FunctionComponent<DuplicateFormProps> = ({ id, onDuplicated }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [duplicateForm, { loading: duplicating }] = useMutation(DuplicateFormDocument, {
    onError,
  });
  const [title, setTitle] = useState('');

  return (
    <Fragment>
      <ErrorMessage />
      <p>Provide a title for the new form.</p>
      <div className="flex gap-3 align-items-end">
        <div className="input-field my-0 flex-grow-1">
          <label htmlFor="duplicateFormTitle">Title</label>
          <InputText
            id="duplicateFormTitle"
            className="w-full"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <Button
          className="w-max min-w-max"
          label="Duplicate"
          disabled={!title.trim() || duplicating}
          onClick={() => {
            void duplicateForm({
              variables: { id, title },
              onCompleted: (data) => {
                onDuplicated(data.duplicateForm);
              },
            });
          }}
        />
      </div>
    </Fragment>
  );
};

type Row = Form;

export const Forms = withEditSiteContext(({ site }) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { permissions, handle, navigate } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { forms, loading } = useFormsMinimal(site.id, onError);
  const { menu, menuRow, onSelectMenuRow } = useMenuSetup<Row>();

  const [showCreateForm, setShowCreateForm] = useState(false);
  const canEditSite = permissions.includes(
    site.organizationId ? Permission.EditCustomerSite : Permission.EditPlatformSite,
  );

  return (
    <PageContainer
      title={`Site: ${site.name}`}
      pageMetaTitle={`Site: ${site.name} Forms`}
      nav={navItems(handle)}
    >
      <ErrorMessage />
      <SiteTabs siteId={site.id} activeTab="forms">
        <div className="surface-ground p-3">
          <div className="flex flex-column lg:flex-row lg:align-items-center gap-3">
            {canEditSite && (
              <Button
                label="Create form"
                onClick={() => {
                  setShowCreateForm(true);
                }}
                disabled={showCreateForm}
              />
            )}
          </div>
          {showCreateForm && (
            <div className="border-1 border-round p-4 mb-1 mt-3 background-white">
              <CreateForm
                siteId={site.id}
                onCreated={(data) => {
                  setShowCreateForm(false);
                  toastSuccess('Form created.');
                  navigate(`/sites/${site.id}/forms/${data.id}`);
                }}
                onCancel={() => {
                  setShowCreateForm(false);
                }}
              />
            </div>
          )}
        </div>
        <DataTable
          value={forms || []}
          emptyMessage="No forms found"
          rowClassName={clickableRowClassName}
          loading={loading}
          loadingIcon="pi pi-spinner"
          onRowClick={(e) => {
            navigate(`/sites/${site.id}/forms/${(e.data as Row).id}`);
          }}
        >
          <Column field="title" header="Title" />
          <Column
            header=""
            align="right"
            body={(row: Row) => <ThreeDotMenuButton onClick={onSelectMenuRow(row)} />}
          />
        </DataTable>
      </SiteTabs>
      <Menu
        ref={menu}
        model={[
          {
            label: 'Duplicate',
            disabled: !canEditSite,
            command: () => {
              if (menuRow) {
                dialog({
                  content: (
                    <DuplicateForm
                      id={menuRow.id}
                      onDuplicated={(newForm) => {
                        dialog(null);
                        toastSuccess('Form duplicated.');
                        navigate(`/sites/${site.id}/forms/${newForm.id}`);
                      }}
                    />
                  ),
                  props: {
                    header: `Duplicate the “${menuRow.title}” form`,
                  },
                });
              }
            },
          },
        ]}
        popup
      />
    </PageContainer>
  );
});
