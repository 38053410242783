import { cleanSmallId } from '@wirechunk/lib/clean-small-id.js';
import { componentTypeHumanReadable } from '@wirechunk/lib/mixer/component-header.js';
import { Component, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useState } from 'react';
import { useCreateComponent } from '../../../hooks/use-create-custom-component/use-create-component.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label.js';

type CreateComponentDialogProps = {
  platformId: string;
  component: Component;
  setComponent: (component: Component) => void;
  onCreated: (data: { id: string }) => void;
  onCancel: () => void;
};

export const CreateComponentDialog: FunctionComponent<CreateComponentDialogProps> = ({
  platformId,
  component,
  setComponent,
  onCreated,
  onCancel,
}) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [name, setName] = useState('');
  const [replaceComponent, setReplaceComponent] = useState(false);
  const { createComponent, loading: createLoading } = useCreateComponent(onError, ({ id }) => {
    if (replaceComponent) {
      setComponent({
        id: component.id,
        type: ComponentType.Design,
        customComponentId: id,
      });
    }
    onCreated({ id });
  });

  const onCreate = () => {
    clearMessages();
    void createComponent({
      platformId,
      name,
      components: JSON.stringify([
        // We must give this custom component a new ID because this tree component will keep its ID. If we do not
        // create a new ID, then inlining the custom component will fail because of the conflicting IDs.
        {
          ...component,
          id: cleanSmallId(),
        },
      ]),
    });
  };

  return (
    <Fragment>
      <ErrorMessage />
      <div className="text-sm mb-2">
        You’ve selected a {componentTypeHumanReadable(component.type)} component.
      </div>
      <div className="input-field">
        <label htmlFor="newCustomComponentName">Name</label>
        <InputText
          id="newCustomComponentName"
          className="w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <CheckboxWithLabel
        id="replaceComponent"
        label="Replace the current component"
        checked={replaceComponent}
        onChange={(e) => {
          setReplaceComponent(e.checked);
        }}
      />
      <div className="flex gap-3 justify-content-end">
        <Button
          className="p-button-secondary"
          label="Cancel"
          disabled={createLoading}
          onClick={onCancel}
        />
        <Button label="Create" disabled={!name.trim() || createLoading} onClick={onCreate} />
      </div>
    </Fragment>
  );
};
