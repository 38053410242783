import { breakpoints } from '@wirechunk/lib/mixer/breakpoints.js';
import { CssProperties, propertyGroups } from '@wirechunk/lib/mixer/cssProperties.js';
import type { BaseStyles } from '@wirechunk/lib/mixer/types/components.js';
import { clsx } from 'clsx';
import { isString } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, ReactElement, useState } from 'react';
import type { SelectItem } from '../../types.js';
import { BasicIconButton } from '../BasicIconButton/BasicIconButton.js';
import { useStylesEdit } from './hooks/useStylesEdit.js';

const scopeDropdownOptions: Array<{ label: string; value: keyof BaseStyles }> = [
  { label: 'Default', value: 'styles' },
  { label: `Small (min-width: ${breakpoints.sm}px)`, value: 'smStyles' },
  { label: `Medium (min-width: ${breakpoints.md}px)`, value: 'mdStyles' },
  { label: `Large (min-width: ${breakpoints.lg}px)`, value: 'lgStyles' },
  { label: `Extra large (min-width: ${breakpoints.xl}px)`, value: 'xlStyles' },
  { label: `Extra extra large (min-width: ${breakpoints.xxl}px)`, value: 'xxlStyles' },
];

type StylesEditorProps<C extends BaseStyles> = {
  styles: C;
  setStyles: (setStyles: (component: C) => C) => void;
};

export const StylesEditor = <C extends BaseStyles>({
  styles,
  setStyles,
}: StylesEditorProps<C>): ReactElement => {
  const { setProperty, removeProperty } = useStylesEdit(setStyles);
  const [scope, setScope] = useState<keyof BaseStyles>('styles');

  const getProperty = (property: keyof CssProperties) => styles[scope]?.[property];

  return (
    <Fragment>
      <Dropdown
        className="w-full mb-3"
        valueTemplate={(option: SelectItem<keyof BaseStyles>) => (
          <div>
            Screen width: <span className="font-medium">{option.label}</span>
          </div>
        )}
        value={scope}
        options={scopeDropdownOptions}
        onChange={(e) => {
          setScope(e.value as keyof BaseStyles);
        }}
      />
      <div className="flex flex-column gap-3">
        {propertyGroups.map((group) => (
          <div key={group.name}>
            <Dropdown
              className="w-18rem"
              placeholder={group.name}
              options={group.dropdownOptions.map((property) => ({
                label: property,
                value: property,
                // We check isString for the property value because newly added properties are empty.
                disabled: isString(getProperty(property)),
              }))}
              onChange={(e) => {
                setProperty(scope, e.value as keyof CssProperties, '');
              }}
            />
            <div className="flex flex-column gap-2">
              {/* We check isString for the property value because newly added properties are empty. */}
              {group.properties
                .filter((prop) => isString(getProperty(prop)))
                .map((property, i) => (
                  <div
                    key={property}
                    className={clsx('flex gap-2 align-items-center', i === 0 && 'mt-2')}
                  >
                    <label htmlFor={`${scope}${property}`} className="w-12rem font-medium">
                      {property}
                    </label>
                    <InputText
                      id={`${scope}${property}`}
                      className="flex-grow-1"
                      value={getProperty(property) || ''}
                      onChange={(e) => {
                        setProperty(scope, property, e.target.value);
                      }}
                    />
                    <BasicIconButton
                      icon={PrimeIcons.TRASH}
                      onClick={() => {
                        removeProperty(scope, property);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
