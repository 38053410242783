import { formatDateTime } from '@wirechunk/lib/dates.js';
import { useMemo } from 'react';
import { tryParseObject } from '../../util/json.js';
import type { FormEntry } from '../use-form-entries/use-form-entries.js';

export type ParsedFormEntry = FormEntry & {
  createdAtTimestamp: string;
  parsedFormData: Record<string, unknown>;
};

type ParsedFormEntries = ParsedFormEntry[];

export const useParsedFormEntries = (entries: FormEntry[] | null): ParsedFormEntries | null =>
  useMemo(
    () =>
      entries
        ? entries.map(
            (entry): ParsedFormEntry => ({
              ...entry,
              createdAtTimestamp: formatDateTime(entry.createdAt),
              parsedFormData: tryParseObject(entry.formData),
            }),
          )
        : null,
    [entries],
  );
