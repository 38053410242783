import { useQuery } from '@apollo/client';
import { formatDateTime } from '@wirechunk/lib/dates.js';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useCurrentUser } from '../../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useAdminUsers } from '../../../../hooks/useAdminUsers/useAdminUsers.js';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { OrganizationSelector } from '../../../OrganizationSelector/OrganizationSelector.js';
import { DataTableWithPaginator } from '../Users/UserDetails/DataTableWithPaginator.js';
import { SubscriptionAuditLogsDocument, SubscriptionAuditLogsQuery } from './queries.generated.js';

type Row = SubscriptionAuditLogsQuery['subscriptionAuditLogs']['logs'][number];

type SubscriptionAuditLogsProps = {
  platformId: string;
  onError: ErrorHandler['onError'];
};

export const SubscriptionAuditLogs: FunctionComponent<SubscriptionAuditLogsProps> = ({
  platformId,
  onError,
}) => {
  const { user } = useCurrentUser();
  const tooltip = useRef<Tooltip>(null);
  const [actionByUserId, setActionByUserId] = useState<string | null>(null);
  const [subscriptionId] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const { data, loading: isLoadingAuditLogs } = useQuery(SubscriptionAuditLogsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      actionByUserId,
      subscriptionId,
      organizationId,
      limit: rows,
      page,
    },
  });
  const { selectItems: adminUserSelectItems } = useAdminUsers(user.organization.id, onError);

  useEffect(() => {
    tooltip.current?.updateTargetEvents(undefined);
  }, [data?.subscriptionAuditLogs.logs]);

  return (
    <Fragment>
      <div className="surface-ground p-3">
        <div className="flex flex-column lg:flex-row lg:justify-content-start lg:align-items-center gap-3">
          <OrganizationSelector
            platformId={platformId}
            inputId="newUserOrganization"
            className="w-24rem max-w-full"
            showClear
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
            onError={onError}
          />
          <Dropdown
            className="w-17rem"
            value={actionByUserId}
            options={adminUserSelectItems}
            placeholder="Select acting admin user"
            onChange={(e) => {
              setActionByUserId(e.value as string | null);
              setPage(0);
            }}
            showClear
          />
        </div>
      </div>
      <DataTableWithPaginator
        value={data?.subscriptionAuditLogs.logs || []}
        loading={isLoadingAuditLogs}
        page={page}
        rows={rows}
        totalRecords={data?.subscriptionAuditLogs.totalCount || 0}
        setPage={setPage}
        setRows={setRows}
        emptyMessage="No matching audit logs found"
      >
        <Column
          header="Timestamp"
          className="lg:w-13rem lg:max-w-13rem"
          body={(row: Row) => <span>{formatDateTime(row.createdAt)}</span>}
        />
        <Column
          header="Acting user"
          body={({ actionByUser }: Row) => (
            <div>
              <div>{actionByUser.displayName}</div>
              <div className="text-sm">({actionByUser.email})</div>
            </div>
          )}
        />
        <Column
          header="Subscription plan"
          body={(row: Row) => <span>{row.subscription.plan.name}</span>}
        />
        <Column
          header="Action"
          body={({ action, organization }: Row) => (
            <div className="flex flex-column gap-2">
              <div>{action}</div>
              {organization && (
                <div>
                  {organization.name && <div>Organization: {organization.name}</div>}
                  {organization.primaryUser && (
                    <Fragment>
                      {organization.name ? 'Owner: ' : 'Organization owner: '}
                      <span
                        className="subscription-audit-logs-action-primary-user"
                        data-pr-tooltip={organization.primaryUser.email}
                        data-pr-position="top"
                      >
                        {organization.primaryUser.displayName}
                      </span>
                    </Fragment>
                  )}
                </div>
              )}
            </div>
          )}
        />
      </DataTableWithPaginator>
      <Tooltip ref={tooltip} target=".subscription-audit-logs-action-primary-user" />
    </Fragment>
  );
};
