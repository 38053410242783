import { useMutation } from '@apollo/client';
import { FileStatus } from '@wirechunk/lib/api.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useEffect, useState } from 'react';
import { useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { useFileGetUrl } from '../../../../../../hooks/useFileGetUrl/useFileGetUrl.js';
import { BasicIconButton } from '../../../../../BasicIconButton/BasicIconButton.js';
import { EditDocumentCurrentVersionDocument, EditFileDocument } from './mutations.generated.js';
import { DocumentQuery } from './queries.generated.js';

const timestampFormat = new Intl.DateTimeFormat('en-US', {
  // month: 'numeric',
  // day: 'numeric',
  // year: 'numeric',
});

type QueryDocumentVersion = DocumentQuery['document']['versions'][number];

type DocumentVersionProps = {
  className?: string;
  documentId: string;
  version: QueryDocumentVersion;
  current: boolean;
};

export const DocumentVersion: FunctionComponent<DocumentVersionProps> = ({
  className,
  documentId,
  version,
  current,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const [editFile, { loading: savingEdits }] = useMutation(EditFileDocument, {
    onError,
  });
  const { getUrl, isLoading: loadingGetUrl } = useFileGetUrl(onError);
  const [editDocumentCurrentVersion] = useMutation(EditDocumentCurrentVersionDocument, { onError });

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [downloadName, setDownloadName] = useState(version.downloadName);

  useEffect(() => {
    setDownloadName(version.downloadName);
  }, [version.downloadName]);

  return (
    <div className={className}>
      <ErrorMessage />
      <div className="flex justify-content-between align-items-center">
        <div className="flex align-items-center gap-2 font-medium mb-3">
          Version from {timestampFormat.format(new Date(version.createdAt))}
          {current ? (
            <span className="font-bold text-color-success-dark">Current version</span>
          ) : (
            <Button
              label="Set as current"
              className="p-button-sm p-button-secondary"
              onClick={() => {
                void editDocumentCurrentVersion({
                  variables: {
                    id: documentId,
                    fileId: version.id,
                  },
                });
              }}
            />
          )}
        </div>
        <BasicIconButton
          icon={PrimeIcons.EXTERNAL_LINK}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            e.stopPropagation();
            const url = await getUrl(version.id, false);
            window.open(url, '_blank');
          }}
          disabled={loadingGetUrl}
        />
      </div>
      {version.status !== FileStatus.Uploaded && (
        <p>
          Upload status: <strong>{version.status}</strong>
        </p>
      )}
      <div className="flex gap-3 align-items-end">
        <div className="input-field mb-0">
          <label htmlFor={`documentDownloadName-${version.id}`}>Download file name</label>
          <InputText
            id={`documentDownloadName-${version.id}`}
            className="w-25rem max-w-full"
            value={downloadName}
            onChange={(e) => {
              setDownloadName(e.target.value);
              setHasUnsavedChanges(true);
            }}
          />
        </div>
        <Button
          label="Save"
          disabled={!hasUnsavedChanges || savingEdits}
          onClick={() => {
            void editFile({
              variables: {
                id: version.id,
                downloadName,
              },
              onCompleted: () => {
                setHasUnsavedChanges(false);
              },
            });
          }}
        />
      </div>
    </div>
  );
};
