/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type CurrentSitePagesQueryVariables = Types.Exact<{
  pathPrefix?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CurrentSitePagesQuery = {
  currentSite: {
    __typename: 'Site';
    id: string;
    pages: Array<{
      __typename: 'Page';
      id: string;
      title: string;
      path: string;
      featuredImageUrl?: string | null;
      caption?: string | null;
    }>;
  };
};

export const CurrentSitePagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentSitePages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pathPrefix' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pathPrefix' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pathPrefix' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'featuredImageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentSitePagesQuery, CurrentSitePagesQueryVariables>;
