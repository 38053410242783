import { MultiSelectInputComponent } from '@wirechunk/lib/mixer/types/components.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { EditComponentContentProps } from '../shared/types.js';

export const EditMultiSelectInput: FunctionComponent<
  EditComponentContentProps<MultiSelectInputComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editMultiSelectQuestion">Question</label>
      <InputText
        id="editMultiSelectQuestion"
        className="w-full"
        value={component.question || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, question: e.target.value }));
        }}
      />
    </div>
    <div className="font-medium mb-2">Choices and Answer</div>
    <div className="flex flex-column gap-3">
      {component.choices?.map((choice, index) => (
        <div key={index} className="flex flex-row gap-2 align-items-center">
          <Checkbox
            id={`editMultiSelectAnswer${index}`}
            checked={!!component.answerIndices?.includes(index)}
            onChange={() => {
              setComponent((c) => ({
                ...c,
                answerIndices: !c.answerIndices?.includes(index)
                  ? [...(c.answerIndices || []), index]
                  : c.answerIndices.filter((i) => i !== index),
              }));
            }}
          />
          <InputText
            id={`editMultiSelectChoices${index}`}
            className="w-full"
            value={choice}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                choices: c.choices?.map((ch, i) => (i === index ? e.target.value : ch)),
              }));
            }}
          />
          <Button
            icon={PrimeIcons.TRASH}
            onClick={() => {
              setComponent((c) => ({
                ...c,
                choices: c.choices?.filter((_, i) => i !== index),
              }));
            }}
          />
        </div>
      ))}
    </div>
    <Button
      label="Add choice"
      className="mt-3"
      onClick={() => {
        setComponent((c) => ({
          ...c,
          choices: [...(c.choices || []), ''],
        }));
      }}
    />
  </Fragment>
);
