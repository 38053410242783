import { useMutation, useQuery } from '@apollo/client';
import type { Component as MixerComponent } from '@wirechunk/lib/mixer/types/components.js';
import { parseComponents, parseOptionalComponents } from '@wirechunk/lib/mixer/utils.js';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../../../../contexts/PageContext/PageContext.js';
import { PropsContextProvider } from '../../../../../contexts/props-context.js';
import { useStateRef } from '../../../../../hooks/use-state-ref.js';
import { ErrorHandler, useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useHasUnsavedChanges } from '../../../../../hooks/useHasUnsavedChanges.js';
import { useSiteContextSelector } from '../../../../../hooks/useSiteContextSelector/useSiteContextSelector.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../Spinner.js';
import {
  PropSpecsOverview,
  PropSpecsOverviewProps,
} from '../../../../VisualBuilder/prop-specs-overview.js';
import { VisualBuilder, VisualBuilderProps } from '../../../../VisualBuilder/VisualBuilder.js';
import { EditComponentDocument } from './mutations.generated.js';
import { ComponentDocument, ComponentQuery } from './queries.generated.js';

const pageContext: PageContext = {
  title: '',
  viewMode: ViewMode.Preview,
};

// This constant is declared here to stay referentially consistent.
const defaultPreviewProps: ContextData = {};

enum Tab {
  Design,
  PropsSetup,
}

type ComponentBodyProps = {
  component: ComponentQuery['component'];
  onError: ErrorHandler['onError'];
  clearErrorMessages: () => void;
};

const ComponentBody: FunctionComponent<ComponentBodyProps> = ({
  component,
  onError,
  clearErrorMessages,
}) => {
  const { hasUnsavedChanges, triggerHasUnsavedChanges, resetHasUnsavedChanges } =
    useHasUnsavedChanges();
  const [editComponent, { loading: isSavingEdits }] = useMutation(EditComponentDocument, {
    onError,
    onCompleted: (data) => {
      if (data.editComponent.__typename === 'EditComponentSuccessResult') {
        resetHasUnsavedChanges();
      } else {
        onError(data.editComponent.message);
      }
    },
  });
  const siteContextSelector = useSiteContextSelector({ onError });

  const [tab, setTab] = useState<Tab>(Tab.Design);
  const propsSetupComponentsJSON = component.propsSetupComponents;
  const [name, setName] = useState(component.name || '');
  const [components, setComponents] = useState<MixerComponent[]>(() =>
    parseComponents(component.components),
  );
  // A StateRef is used to keep track of the current props setup components so that we can check if they've changed
  // while fetching all input components below.
  const [propsSetupComponentsRef, setPropsSetupComponents] = useStateRef<MixerComponent[]>(
    () => parseOptionalComponents(propsSetupComponentsJSON) ?? [],
  );
  const [previewProps, setPreviewProps] = useState<ContextData | null>(
    component.previewProps ? (JSON.parse(component.previewProps) as ContextData) : null,
  );

  const setComponentsWrapped = useCallback<VisualBuilderProps['setComponents']>(
    (components) => {
      setComponents(components);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );
  const setPropsSetupComponentsWrapped = useCallback<VisualBuilderProps['setComponents']>(
    (updater) => {
      setPropsSetupComponents(updater);
      triggerHasUnsavedChanges();
    },
    [setPropsSetupComponents, triggerHasUnsavedChanges],
  );
  const setPreviewPropsWrapped = useCallback<PropSpecsOverviewProps['setProps']>(
    (props) => {
      setPreviewProps(props);
      triggerHasUnsavedChanges();
    },
    [triggerHasUnsavedChanges],
  );

  useEffect(() => {
    setName(component.name);
    setComponents(parseComponents(component.components));
    setPropsSetupComponents(() => parseOptionalComponents(propsSetupComponentsJSON) ?? []);
    setPreviewProps(
      component.previewProps ? (JSON.parse(component.previewProps) as ContextData) : null,
    );
  }, [
    component.name,
    component.components,
    propsSetupComponentsJSON,
    setPropsSetupComponents,
    component.previewProps,
  ]);

  return (
    <Fragment>
      <div className="flex justify-content-between align-items-start">
        <div className="input-field">
          <label htmlFor="componentName">Name</label>
          <InputText
            id="componentName"
            className="w-20rem max-w-full"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              triggerHasUnsavedChanges();
            }}
          />
        </div>
        <Button
          label="Save"
          className="mb-2"
          disabled={!hasUnsavedChanges || isSavingEdits}
          onClick={() => {
            clearErrorMessages();
            void editComponent({
              variables: {
                input: {
                  id: component.id,
                  name: { value: name },
                  components: { value: JSON.stringify(components) },
                  propsSetupComponents: { value: JSON.stringify(propsSetupComponentsRef.current) },
                  previewProps: previewProps
                    ? { value: JSON.stringify(previewProps) }
                    : { clear: true },
                },
              },
            });
          }}
        />
      </div>
      {siteContextSelector.siteContext && propsSetupComponentsRef.current.length > 0 && (
        <PropSpecsOverview
          propsSetupComponentsRef={propsSetupComponentsRef}
          props={previewProps}
          setProps={setPreviewPropsWrapped}
          siteContext={siteContextSelector.siteContext}
          className="border-1 border-round mb-3"
          containerType="component"
        />
      )}
      <div className="mt-3">
        <TabView
          activeIndex={tab}
          onTabChange={({ index }) => {
            if (index === Tab.Design.valueOf() || index === Tab.PropsSetup.valueOf()) {
              setTab(index);
            }
          }}
          panelContainerClassName="px-0"
        >
          <TabPanel header="Design">
            <VisualBuilder
              siteContext={siteContextSelector}
              components={components}
              setComponents={setComponentsWrapped}
              // Preview needs a PageContext in preview mode.
              onPreview={(children) => (
                <PageContextProvider value={pageContext}>
                  <PropsContextProvider value={previewProps ?? defaultPreviewProps}>
                    {children}
                  </PropsContextProvider>
                </PageContextProvider>
              )}
            />
          </TabPanel>
          <TabPanel header="Props setup">
            <VisualBuilder
              siteContext={siteContextSelector}
              components={propsSetupComponentsRef.current}
              setComponents={setPropsSetupComponentsWrapped}
              // Preview needs a PageContext in preview mode.
              onPreview={(children) => (
                <PageContextProvider value={pageContext}>
                  <PropsContextProvider value={previewProps ?? defaultPreviewProps}>
                    {children}
                  </PropsContextProvider>
                </PageContextProvider>
              )}
            />
          </TabPanel>
        </TabView>
      </div>
    </Fragment>
  );
};

export const Component: FunctionComponent = () => {
  const { componentId } = useParams<{ componentId: string }>();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(
    ComponentDocument,
    componentId
      ? {
          onError,
          variables: { id: componentId },
        }
      : {
          skip: true,
        },
  );

  if (!componentId) {
    return (
      <PageContainer>
        <p>Invalid page.</p>
      </PageContainer>
    );
  }

  return (
    <PageContainer title="Component">
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        data && (
          <ComponentBody
            component={data.component}
            onError={onError}
            clearErrorMessages={clearMessages}
          />
        )
      )}
    </PageContainer>
  );
};
