import { CreateApiTokenInput } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';

type CreateApiTokenProps = {
  className?: string;
  creating: boolean;
  onCreate: (data: CreateApiTokenInput) => void;
  onCancel: () => void;
};

export const CreateApiToken: FunctionComponent<CreateApiTokenProps> = ({
  className,
  creating,
  onCreate,
  onCancel,
}) => {
  const [name, setName] = useState('');

  return (
    <div className={clsx('border-1 border-round p-3', className)}>
      <h3 className="mt-1">Create an API token</h3>
      <div className="input-field">
        <label htmlFor="create-api-token-name">Token name</label>
        <InputText
          id="create-api-token-name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-2">
        <Button
          label="Create"
          disabled={creating}
          onClick={() => {
            onCreate({ name });
          }}
        />
        <Button
          label="Cancel"
          className="p-button-secondary"
          disabled={creating}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
