import { FunctionComponent, PropsWithChildren } from 'react';
import { usePlatformContext } from '../../../../../../../contexts/admin/platform-context/platform-context.js';
import { NavTabs, NavTabsProps } from '../../../../../../nav-tabs.js';

const tabs = ['', 'design', 'history'] as const;

export type PageTab = (typeof tabs)[number];

export const activeTab = (pageId: string, pathname: string): PageTab => {
  if (pathname.includes(`/${pageId}/design`)) {
    return 'design';
  }
  if (pathname.includes(`/${pageId}/history`)) {
    return 'history';
  }
  return '';
};

const tabHeader = (tab: PageTab) => {
  switch (tab) {
    case '':
      return 'Settings and metadata';
    case 'design':
      return 'Design';
    case 'history':
      return 'History';
  }
};

type PageTabsProps = PropsWithChildren<
  Pick<NavTabsProps<PageTab>, 'panelClassName'> & {
    siteId: string;
    pageId: string;
    activeTab: PageTab;
  }
>;

export const PageTabs: FunctionComponent<PageTabsProps> = ({
  siteId,
  pageId,
  activeTab,
  children,
}) => {
  const { handle } = usePlatformContext();

  return (
    <NavTabs
      rootPath={`/dashboard/${handle}/sites/${siteId}/pages/${pageId}`}
      tabs={tabs}
      activeTab={activeTab}
      tabHeader={tabHeader}
    >
      {children}
    </NavTabs>
  );
};
