import { InputText } from 'primereact/inputtext';
import { Fragment } from 'react';
import type { EditComponent } from '../../editComponentByType.js';

export const ClassNameTab: EditComponent = ({ component, setComponent }) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editClassNameTabClassName">Classes</label>
        <InputText
          id="editClassNameTabClassName"
          className="w-full"
          value={component.className || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              className: e.target.value,
            }));
          }}
        />
      </div>
    </Fragment>
  );
};
