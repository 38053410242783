import {
  ButtonComponent,
  ButtonIconPosition,
  ButtonOnClick,
} from '@wirechunk/lib/mixer/types/components.js';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { iconOptions } from './shared/icons.js';
import type { EditComponentContentProps } from './shared/types.js';

const onClickOptions: Array<{ label: string; value: ButtonOnClick['type'] }> = [
  { label: 'Do nothing', value: 'doNothing' },
  { label: 'Navigate to page', value: 'navigate' },
  { label: 'Submit form', value: 'submitForm' },
  // TODO
  // { label: 'Reset form', value: 'resetForm' },
];

const iconPositionOptions = Object.values(ButtonIconPosition);

export const EditButton: FunctionComponent<EditComponentContentProps<ButtonComponent>> = ({
  component,
  setComponent,
}) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editButtonOnClick">On click action</label>
      <Dropdown
        inputId="editButtonOnClick"
        className="w-full"
        options={onClickOptions}
        value={component.onClick?.type || ('doNothing' satisfies ButtonOnClick['type'])}
        onChange={(e) => {
          const value = e.value as ButtonOnClick['type'] | null;
          switch (value) {
            case 'navigate':
              setComponent((c) => ({
                ...c,
                onClick: { type: value, url: '', isExternal: false },
              }));
              break;
            case 'submitForm':
            case 'resetForm':
              setComponent((c) => ({ ...c, onClick: { type: value } }));
              break;
            default:
              setComponent((c) => ({ ...c, onClick: { type: 'doNothing' } }));
          }
        }}
      />
    </div>
    {component.onClick?.type === 'navigate' && (
      <Fragment>
        <div className="input-field">
          <label htmlFor="editButtonOnClickUrl">URL or page path</label>
          <InputText
            id="editButtonOnClickUrl"
            className="w-full"
            value={component.onClick.url}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                onClick:
                  c.onClick?.type === 'navigate'
                    ? {
                        ...c.onClick,
                        url: e.target.value,
                      }
                    : c.onClick,
              }));
            }}
          />
        </div>
        <div className="input-field flex align-items-center gap-3">
          <Checkbox
            inputId="editButtonOnClickIsExternal"
            checked={component.onClick.isExternal || false}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                onClick:
                  c.onClick?.type === 'navigate'
                    ? {
                        ...c.onClick,
                        isExternal: e.checked,
                      }
                    : c.onClick,
              }));
            }}
          />
          <label htmlFor="editButtonOnClickIsExternal">Link is external</label>
        </div>
        <div className="input-field flex align-items-center gap-3">
          <Checkbox
            inputId="editButtonOnClickOpenInNewTab"
            checked={component.onClick.openInNewTab || false}
            onChange={(e) => {
              setComponent((c) => ({
                ...c,
                onClick:
                  c.onClick?.type === 'navigate'
                    ? {
                        ...c.onClick,
                        openInNewTab: e.checked,
                      }
                    : c.onClick,
              }));
            }}
          />
          <label htmlFor="editButtonOnClickOpenInNewTab">Open in new tab</label>
        </div>
      </Fragment>
    )}
    <div className="input-field">
      <label htmlFor="editButtonIcon">Icon</label>
      <Dropdown
        inputId="editButtonIcon"
        className="w-full"
        options={iconOptions}
        value={component.icon}
        onChange={(e) => {
          setComponent((c) => ({ ...c, icon: e.value as string }));
        }}
        filter
        showClear
      />
    </div>
    {component.icon && (
      <div className="input-field">
        <label htmlFor="editButtonIconPosition">Icon position</label>
        <Dropdown
          inputId="editButtonIconPosition"
          className="w-full"
          options={iconPositionOptions}
          value={component.iconPosition}
          onChange={(e) => {
            setComponent((c) => ({ ...c, iconPosition: e.value as ButtonIconPosition }));
          }}
          showClear
        />
      </div>
    )}
  </Fragment>
);
