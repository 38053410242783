import { ApolloQueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ErrorHandler } from '../../../hooks/useErrorHandler.js';
import { PageTemplatesDocument, PageTemplatesQuery } from './queries.generated.js';

export type PageTemplate = PageTemplatesQuery['pageTemplates'][number];

type PagesTableData = {
  pageTemplates: PageTemplate[] | null;
  loading: boolean;
  refetchPageTemplates: () => Promise<ApolloQueryResult<PageTemplatesQuery>>;
};

export const usePageTemplatesData = (
  platformId: string,
  siteId: string,
  onError: ErrorHandler['onError'],
): PagesTableData => {
  const {
    data: pageTemplatesData,
    loading,
    refetch: refetchPageTemplates,
  } = useQuery(PageTemplatesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId, siteId },
  });

  return useMemo<PagesTableData>(
    () => ({
      pageTemplates: pageTemplatesData?.pageTemplates || null,
      loading,
      refetchPageTemplates,
    }),
    [pageTemplatesData, loading, refetchPageTemplates],
  );
};
