import {
  ComponentType,
  ConditionalLogicSlotKey,
  SlotKey,
  SubscriptionTagGuardSlotKey,
} from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, useMemo } from 'react';
import type { SelectItem } from '../../../../types.js';
import type { EditComponent } from '../../editComponentByType.js';

const optionsByParentType: Partial<Record<ComponentType, Array<SelectItem<SlotKey>>>> = {
  [ComponentType.ConditionalLogic]: [
    { label: 'True', value: ConditionalLogicSlotKey.True },
    { label: 'False', value: ConditionalLogicSlotKey.False },
  ],
  [ComponentType.SubscriptionTagGuard]: [
    { label: 'Access allowed', value: SubscriptionTagGuardSlotKey.AccessAllowed },
    { label: 'Access denied', value: SubscriptionTagGuardSlotKey.AccessDenied },
  ],
};

const slotOptions = (parentType: ComponentType): Array<SelectItem<SlotKey>> | null =>
  optionsByParentType[parentType] ?? null;

export const isSlotKey = (value: unknown): value is SlotKey =>
  Object.values(optionsByParentType).some((options) =>
    options.some((option) => option.value === value),
  );

export const SlotTab: EditComponent = ({ component, setComponent, parent }) => {
  const parentType = parent?.type;
  const options = useMemo(() => (parentType ? slotOptions(parentType) : []), [parentType]);

  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editSlotTabSlot">Slot</label>
        {options ? (
          <Dropdown
            id="editSlotTabSlot"
            className="w-full"
            options={options}
            value={component.slot || null}
            onChange={({ value }) => {
              if (isSlotKey(value)) {
                setComponent((c) => ({
                  ...c,
                  slot: value,
                }));
              }
            }}
          />
        ) : (
          'Unexpected component type'
        )}
      </div>
    </Fragment>
  );
};
