import { intersection } from 'lodash-es';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FunctionComponent, useEffect, useState } from 'react';
import { OptionsSection } from '../../../OptionsSection.js';
import { PlatformSettingsSectionProps } from './types.js';

export const EmailSection: FunctionComponent<PlatformSettingsSectionProps> = ({
  platform,
  savingKeys,
  savePlatform,
}) => {
  const [emailReplyToAddress, setEmailReplyToAddress] = useState<string | null>(
    platform.emailReplyToAddress ?? null,
  );
  const [emailSendFromAddress, setEmailSendFromAddress] = useState<string>(
    platform.emailSendFromAddress,
  );
  const [sendGridApiKey, setSendGridApiKey] = useState<string | null>(
    platform.sendGridApiKey || null,
  );

  useEffect(() => {
    setEmailReplyToAddress(platform.emailReplyToAddress ?? null);
    setEmailSendFromAddress(platform.emailSendFromAddress);
    setSendGridApiKey(platform.sendGridApiKey || null);
  }, [platform.emailReplyToAddress, platform.emailSendFromAddress, platform.sendGridApiKey]);

  return (
    <OptionsSection
      title="Email"
      allowSave={
        !intersection(savingKeys, ['emailReplyToAddress', 'emailSendFromAddress', 'sendGridApiKey'])
          .length &&
        !!emailSendFromAddress.length &&
        // For now we require a SendGrid API key. In the future we may not.
        !!sendGridApiKey &&
        ((emailReplyToAddress || null) !== (platform.emailReplyToAddress || null) ||
          emailSendFromAddress !== platform.emailSendFromAddress ||
          (sendGridApiKey || null) !== (platform.sendGridApiKey || null))
      }
      onSave={() => {
        savePlatform({
          emailSendFromAddress: {
            value: emailSendFromAddress,
          },
          emailReplyToAddress: emailReplyToAddress
            ? {
                value: emailReplyToAddress,
              }
            : {
                clear: true,
              },
          sendGridApiKey: sendGridApiKey
            ? {
                value: sendGridApiKey,
              }
            : {
                clear: true,
              },
        });
      }}
    >
      <div className="input-field">
        <label htmlFor="platformEmailSendFromAddress">Send-from address</label>
        <InputText
          id="platformEmailSendFromAddress"
          className="w-full"
          value={emailSendFromAddress}
          onChange={(e) => {
            setEmailSendFromAddress(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformEmailReplyToAddress">
          Reply-to address (defaults to send-from address)
        </label>
        <InputText
          id="platformEmailReplyToAddress"
          className="w-full"
          value={emailReplyToAddress || ''}
          onChange={(e) => {
            setEmailReplyToAddress(e.target.value);
          }}
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="platformSendGridApiKey">SendGrid API key</label>
        <Password
          inputId="platformSendGridApiKey"
          className="w-full"
          inputClassName="w-full"
          value={sendGridApiKey || ''}
          onChange={(e) => {
            setSendGridApiKey(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
      </div>
    </OptionsSection>
  );
};
