import { useQuery } from '@apollo/client';
import { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
  PageContext,
  PageContextProvider,
  ViewMode,
} from '../../contexts/PageContext/PageContext.js';
import { PropsContextProvider } from '../../contexts/props-context.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { tryParseObject } from '../../util/json.js';
import { ParseAndRenderPage } from '../ParseAndRenderPage.js';
import { Spinner } from '../Spinner.js';
import { PreviewPageTemplateDocument } from './queries.generated.js';

export const PreviewPageTemplate: FunctionComponent = () => {
  const { templateId } = useParams<{
    templateId: string;
  }>();
  const { onError, ErrorMessage } = useErrorHandler();

  const { data, loading } = useQuery(PreviewPageTemplateDocument, {
    onError,
    variables: {
      id: templateId as string,
    },
  });

  const pageContext = useMemo<PageContext | null>(
    () =>
      data
        ? {
            title: data.pageTemplate.title,
            viewMode: ViewMode.Preview,
          }
        : null,
    [data],
  );
  const propsContext = useMemo<ContextData>(
    () =>
      data?.pageTemplate.previewProps
        ? (tryParseObject(data.pageTemplate.previewProps) as ContextData)
        : {},
    [data],
  );

  if (loading) {
    return <Spinner />;
  }

  const page = data?.pageTemplate;

  // We default to the page title for a SitePage, so we do the same here for consistency.
  const metaTitle = page?.metaTitle || page?.title || '';

  return (
    <Fragment>
      <Helmet>
        <title>{metaTitle} - Page Template Preview</title>
      </Helmet>
      <ErrorMessage />
      {pageContext && (
        <PageContextProvider value={pageContext}>
          <PropsContextProvider value={propsContext}>
            <ParseAndRenderPage
              componentsJSON={page?.components}
              bodyStylesJSON={page?.bodyStyles}
            />
          </PropsContextProvider>
        </PageContextProvider>
      )}
    </Fragment>
  );
};
