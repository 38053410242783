import { PlatformOptionKey } from '@wirechunk/lib/platform-options.js';
import { intersection } from 'lodash-es';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FunctionComponent, useEffect, useState } from 'react';
import { InputNotice } from '../../../InputNotice/InputNotice.js';
import { OptionsSection } from '../../../OptionsSection.js';
import { PlatformSettingsOptionsSectionProps } from './types.js';

export const StripeSection: FunctionComponent<PlatformSettingsOptionsSectionProps> = ({
  platform,
  savingKeys,
  saveOptions,
}) => {
  const [stripePublishableKey, setStripePublishableKey] = useState<string | null>(
    platform.stripePublishableKey || null,
  );
  const [stripeSecretKey, setStripeSecretKey] = useState<string | null>(
    platform.stripeSecretKey || null,
  );
  const [stripeWebhookSigningSecret, setStripeWebhookSigningSecret] = useState<string | null>(
    platform.stripeWebhookSigningSecret || null,
  );

  useEffect(() => {
    setStripePublishableKey(platform.stripePublishableKey || null);
    setStripeSecretKey(platform.stripeSecretKey || null);
    setStripeWebhookSigningSecret(platform.stripeWebhookSigningSecret || null);
  }, [
    platform.stripePublishableKey,
    platform.stripeSecretKey,
    platform.stripeWebhookSigningSecret,
  ]);

  return (
    <OptionsSection
      title="Stripe"
      allowSave={
        intersection(savingKeys, [
          PlatformOptionKey.StripePublishableKey,
          PlatformOptionKey.StripeSecretKey,
          PlatformOptionKey.StripeWebhookSigningSecret,
        ]).length === 0 &&
        ((stripePublishableKey || null) !== (platform.stripePublishableKey || null) ||
          (stripeSecretKey || null) !== (platform.stripeSecretKey || null) ||
          (stripeWebhookSigningSecret || null) !== (platform.stripeWebhookSigningSecret || null))
      }
      getOptions={() => [
        { key: PlatformOptionKey.StripePublishableKey, value: stripePublishableKey },
        { key: PlatformOptionKey.StripeSecretKey, value: stripeSecretKey },
        { key: PlatformOptionKey.StripeWebhookSigningSecret, value: stripeWebhookSigningSecret },
      ]}
      saveOptions={saveOptions}
    >
      <div className="input-field">
        <label htmlFor="platformStripePublishableKey">Stripe publishable key</label>
        <InputText
          id="platformStripePublishableKey"
          className="w-full"
          value={stripePublishableKey || ''}
          onChange={(e) => {
            setStripePublishableKey(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="platformStripeSecretKey">Stripe secret key</label>
        <Password
          inputId="platformStripeSecretKey"
          className="w-full"
          inputClassName="w-full"
          value={stripeSecretKey || ''}
          onChange={(e) => {
            setStripeSecretKey(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="platformStripeWebhookSigningSecret">Stripe webhook signing secret</label>
        <Password
          inputId="platformStripeWebhookSigningSecret"
          className="w-full"
          inputClassName="w-full"
          value={stripeWebhookSigningSecret || ''}
          onChange={(e) => {
            setStripeWebhookSigningSecret(e.target.value);
          }}
          feedback={false}
          toggleMask
        />
        <InputNotice>
          This is required for Stripe webhooks to be processed. You can find your webhook signing
          secret on the{' '}
          <a href="https://dashboard.stripe.com/webhooks" target="_blank" rel="noopener noreferrer">
            Stripe dashboard
          </a>
          .
        </InputNotice>
      </div>
    </OptionsSection>
  );
};
