import {
  SubscriptionTagGuardComponent,
  SubscriptionTagGuardAccessDeniedMode,
} from '@wirechunk/lib/mixer/types/components.js';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useProductItemPicklist } from '../../../../hooks/use-product-item-picklist/use-product-item-picklist.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { Spinner } from '../../../Spinner.js';
import { EditComponentContentProps } from '../shared/types.js';

const accessDeniedModeOptions = Object.values(SubscriptionTagGuardAccessDeniedMode);

const isMissingTagMode = (value: unknown): value is SubscriptionTagGuardAccessDeniedMode =>
  accessDeniedModeOptions.includes(value as never);

export const EditSubscriptionTagGuard: FunctionComponent<
  EditComponentContentProps<SubscriptionTagGuardComponent>
> = ({ component, setComponent }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { id: platformId } = usePlatformContext();
  const { productItems, loading } = useProductItemPicklist(platformId, onError);

  return (
    <Fragment>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        <div className="input-field">
          <label htmlFor="editSubscriptionTagGuardTag">Product item</label>
          <Dropdown
            inputId="editSubscriptionTagGuardTag"
            className="w-full"
            filter
            value={component.tag || null}
            options={productItems}
            onChange={({ value }) => {
              if (isString(value)) {
                setComponent((c) => ({ ...c, tag: value }));
              }
            }}
          />
        </div>
      )}
      <div className="input-field">
        <label htmlFor="editSubscriptionTagGuardMissingTagMode">Message</label>
        <Dropdown
          inputId="editSubscriptionTagGuardMissingTagMode"
          className="w-full"
          value={component.accessDeniedMode ?? SubscriptionTagGuardAccessDeniedMode.DefaultMessage}
          options={accessDeniedModeOptions}
          onChange={({ value }) => {
            if (isMissingTagMode(value)) {
              setComponent(
                (c): SubscriptionTagGuardComponent => ({ ...c, accessDeniedMode: value }),
              );
            }
          }}
        />
      </div>
    </Fragment>
  );
};
