import type { MoveUserPlanComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import {
  defaultNextLabel,
  defaultPreviousLabel,
} from '../../../mixer/MoveUserPlan/MoveUserPlan.js';
import type { EditComponentContentProps } from '../shared/types.js';

export const EditMoveUserPlan: FunctionComponent<
  EditComponentContentProps<MoveUserPlanComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editMoveUserPlanPreviousLabel">Previous button label</label>
      <InputText
        id="editMoveUserPlanPreviousLabel"
        className="w-full"
        value={component.previousButtonLabel || ''}
        placeholder={defaultPreviousLabel}
        onChange={(e) => {
          setComponent(
            (c): MoveUserPlanComponent => ({ ...c, previousButtonLabel: e.target.value }),
          );
        }}
      />
    </div>
    <div className="input-field">
      <label htmlFor="editMoveUserPlanNextLabel">Next button label</label>
      <InputText
        id="editMoveUserPlanNextLabel"
        className="w-full"
        value={component.nextButtonLabel || ''}
        placeholder={defaultNextLabel}
        onChange={(e) => {
          setComponent((c): MoveUserPlanComponent => ({ ...c, nextButtonLabel: e.target.value }));
        }}
      />
    </div>
  </Fragment>
);
