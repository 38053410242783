import { CourseSubSectionComponent } from '@wirechunk/lib/mixer/types/components.js';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import { EditComponentContentProps } from '../shared/types.js';

export const EditCourseSubSection: FunctionComponent<
  EditComponentContentProps<CourseSubSectionComponent>
> = ({ component, setComponent }) => (
  <Fragment>
    <div className="input-field">
      <label htmlFor="editCourseSubSectionHeading">Heading</label>
      <InputText
        id="editCourseSubSectionHeading"
        value={component.heading || ''}
        onChange={(e) => {
          setComponent((c) => ({ ...c, heading: e.target.value }));
        }}
      />
    </div>
  </Fragment>
);
