import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const OrganizationAgentLastName: FunctionComponent<Component> = (props) => {
  const { agentLastName } = useSiteContext();

  return <span className={componentClassName(props)}>{agentLastName || ''}</span>;
};
