import type { ApolloClient } from '@apollo/client';
import { Component, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import type { FunctionComponent } from 'react';
import { EditAppPageTiles } from './components/AppPageTiles/AppPageTiles.js';
import { EditBodyContainer } from './components/BodyContainer/BodyContainer.js';
import { EditWidthContainer } from './components/BodyContainer/width-container.js';
import { EditBox } from './components/Box.js';
import { EditButton } from './components/Button.js';
import { EditCalendlyEmbed } from './components/calendly-embed/calendly-embed.js';
import { EditCollapsiblePanel } from './components/CollapsiblePanel.js';
import { EditCompletableVideoInput } from './components/CompletableVideoInput/CompletableVideoInput.js';
import { EditConditionalLogic } from './components/conditional-logic/conditional-logic.js';
import { EditCourse } from './components/course/course.js';
import { EditCourseSubSection } from './components/CourseSubSection/CourseSubSection.js';
import { EditDateInput } from './components/DateInput.js';
import { EditDocument } from './components/document.js';
import { EditDropdownInput } from './components/DropdownInput.js';
import { EditForm } from './components/Form/Form.js';
import { EditHTML } from './components/HTML.js';
import { EditIcon } from './components/Icon.js';
import { EditImage } from './components/Image.js';
import { EditLink } from './components/Link.js';
import { EditMap } from './components/Map.js';
import { EditMoveUserPlan } from './components/MoveUserPlan/MoveUserPlan.js';
import { EditMultiOrgView } from './components/multi-org-view.js';
import { EditMultipleChoiceInput } from './components/MultipleChoiceInput/MultipleChoiceInput.js';
import { EditMultiSelectInput } from './components/MultiSelectInput/MultiSelectInput.js';
import { EditNumberInput } from './components/NumberInput.js';
import { EditOrderPage } from './components/order-form/order-form.js';
import { EditOrgTeam } from './components/org-team.js';
import { EditOrganizationAgencyAddressState } from './components/OrganizationAgencyAddressState.js';
import { EditOrganizationAgencyPrimaryPhone } from './components/OrganizationAgencyPrimaryPhone.js';
import { EditOrganizationLogo } from './components/OrganizationLogo.js';
import { EditPhoneLink } from './components/PhoneLink.js';
import { EditPlainText } from './components/PlainText.js';
import { EditPopover } from './components/popover.js';
import { EditProvideProps } from './components/provide-props/provide-props.js';
import { EditResetPassword } from './components/ResetPassword/ResetPassword.js';
import { EditResource } from './components/resource.js';
import { EditReview } from './components/Review.js';
import type { EditComponentContentProps } from './components/shared/types.js';
import { EditSiteLogo } from './components/site-logo.js';
import { EditStage } from './components/Stage/Stage.js';
import { EditStageFileInput } from './components/StageFileInput/StageFileInput.js';
import { EditSubscriptionTagGuard } from './components/SubscriptionTagGuard/SubscriptionTagGuard.js';
import { EditText } from './components/Text.js';
import { EditTextareaInput } from './components/TextareaInput.js';
import { EditTextInput } from './components/TextInput.js';
import { EditTypeform } from './components/Typeform.js';
import { EditVideo } from './components/Video.js';
import { EditViewPlanStagesButton } from './components/ViewPlanStagesButton/ViewPlanStagesButton.js';
import { withContentTab } from './EditComponentDialog/tabs/content-tab.js';
import { ComponentForTreeDocument } from './tree-component/queries.generated.js';

export type EditComponent<C extends Component = Component> = FunctionComponent<
  EditComponentContentProps<C>
>;

export const editComponentByType = (
  component: Component,
  client: ApolloClient<object>,
): /* eslint-disable-line @typescript-eslint/no-explicit-any */ EditComponent<any> | null => {
  switch (component.type) {
    case ComponentType.AppPageTiles:
      return EditAppPageTiles;
    case ComponentType.BodyContainer:
      return EditBodyContainer;
    case ComponentType.Box:
      return EditBox;
    case ComponentType.Button:
      return EditButton;
    case ComponentType.CalendlyEmbed:
      return EditCalendlyEmbed;
    case ComponentType.CollapsiblePanel:
      return EditCollapsiblePanel;
    case ComponentType.CompletableVideoInput:
      return EditCompletableVideoInput;
    case ComponentType.ConditionalLogic:
      return EditConditionalLogic;
    case ComponentType.Course:
      return EditCourse;
    case ComponentType.CourseSubSection:
      return EditCourseSubSection;
    case ComponentType.DateInput:
      return EditDateInput;
    case ComponentType.Design: {
      const dynamicComponentId = component.customComponentId || component.designId;
      if (dynamicComponentId) {
        const dynamicComponent = client.cache.readQuery({
          query: ComponentForTreeDocument,
          variables: { id: dynamicComponentId },
        });
        if (dynamicComponent) {
          const { propsSetupComponents } = dynamicComponent.component;
          if (propsSetupComponents) {
            return withContentTab({
              dynamicComponentId,
              propsSetupComponents,
            });
          }
        }
      }
      return null;
    }
    case ComponentType.Document:
      return EditDocument;
    case ComponentType.DropdownInput:
      return EditDropdownInput;
    case ComponentType.Form:
      return EditForm;
    case ComponentType.HTML:
      return EditHTML;
    case ComponentType.Icon:
      return EditIcon;
    case ComponentType.Image:
      return EditImage;
    case ComponentType.Link:
      return EditLink;
    case ComponentType.Map:
      return EditMap;
    case ComponentType.MoveUserPlan:
      return EditMoveUserPlan;
    case ComponentType.MultiOrgView:
      return EditMultiOrgView;
    case ComponentType.MultipleChoiceInput:
      return EditMultipleChoiceInput;
    case ComponentType.MultiSelectInput:
      return EditMultiSelectInput;
    case ComponentType.NumberInput:
      return EditNumberInput;
    case ComponentType.OrderPage:
      return EditOrderPage;
    case ComponentType.OrganizationAgencyAddressState:
      return EditOrganizationAgencyAddressState;
    case ComponentType.OrganizationAgencyPrimaryPhone:
      return EditOrganizationAgencyPrimaryPhone;
    case ComponentType.OrganizationLogo:
      return EditOrganizationLogo;
    case ComponentType.OrganizationTeam:
      return EditOrgTeam;
    case ComponentType.PhoneLink:
      return EditPhoneLink;
    case ComponentType.PlainText:
      return EditPlainText;
    case ComponentType.Popover:
      return EditPopover;
    case ComponentType.ProvideProps:
      return EditProvideProps;
    case ComponentType.ResetPassword:
      return EditResetPassword;
    case ComponentType.Resource:
      return EditResource;
    case ComponentType.Review:
      return EditReview;
    case ComponentType.SiteLogo:
      return EditSiteLogo;
    case ComponentType.Stage:
      return EditStage;
    case ComponentType.StageFileInput:
      return EditStageFileInput;
    case ComponentType.SubscriptionTagGuard:
      return EditSubscriptionTagGuard;
    case ComponentType.Text:
      return EditText;
    case ComponentType.TextInput:
      return EditTextInput;
    case ComponentType.TextareaInput:
      return EditTextareaInput;
    case ComponentType.Typeform:
      return EditTypeform;
    case ComponentType.Video:
      return EditVideo;
    case ComponentType.ViewPlanStagesButton:
      return EditViewPlanStagesButton;
    case ComponentType.WidthContainer:
      return EditWidthContainer;
    default:
      return null;
  }
};
