import { useMutation, useQuery } from '@apollo/client';
import { EditPlatformInput, OptionInput } from '@wirechunk/lib/api.js';
import { difference } from 'lodash-es';
import { FunctionComponent, useCallback, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../Spinner.js';
import { ActiveCampaignSection } from './ActiveCampaignSection.js';
import { EmailSection } from './EmailSection.js';
import { EditPlatformDocument, EditPlatformOptionsDocument } from './mutations.generated.js';
import { ProductAnalyticsSection } from './ProductAnalyticsSection.js';
import { PlatformDocument } from './queries.generated.js';
import { StripeSection } from './StripeSection.js';
import { WebhooksSection } from './WebhooksSection.js';
import { ZendeskSection } from './ZendeskSection.js';

export const Settings: FunctionComponent = () => {
  const { id, name } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(PlatformDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });
  const [editOptions] = useMutation(EditPlatformOptionsDocument, {
    onError,
  });
  const [editPlatform] = useMutation(EditPlatformDocument, {
    onError,
  });

  const [savingKeys, setSavingKeys] = useState<Array<keyof Omit<EditPlatformInput, 'id'>>>([]);
  const [savingOptionKeys, setSavingOptionKeys] = useState<string[]>([]);

  const saveOptions = useCallback(
    (options: OptionInput[]) => {
      clearMessages();
      const optionsKeys = options.map((opt) => opt.key);
      setSavingOptionKeys((keys) => [...keys, ...optionsKeys]);
      void editOptions({
        variables: {
          platformId: id,
          options,
        },
        onCompleted: () => {
          setSavingOptionKeys((keys) => difference(keys, optionsKeys));
        },
        onError: (error) => {
          onError(error);
          setSavingOptionKeys((keys) => difference(keys, optionsKeys));
        },
      });
    },
    [clearMessages, editOptions, id, onError],
  );

  const savePlatform = useCallback(
    (input: Omit<EditPlatformInput, 'id'>) => {
      clearMessages();
      const optionsKeys = Object.keys(input) as Array<keyof Omit<EditPlatformInput, 'id'>>;
      setSavingKeys((keys) => [...keys, ...optionsKeys]);
      void editPlatform({
        variables: {
          input: {
            id,
            ...input,
          },
        },
        onCompleted: (data) => {
          setSavingKeys((keys) => difference(keys, optionsKeys));
          if (data.editPlatform.__typename === 'GenericUserError') {
            onError(data.editPlatform.message);
          }
        },
        onError: (error) => {
          onError(error);
          setSavingKeys((keys) => difference(keys, optionsKeys));
        },
      });
    },
    [clearMessages, editPlatform, id, onError],
  );

  return (
    <PageContainer title={`${name} Platform settings`}>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        data && (
          <div className="flex flex-column gap-4">
            <EmailSection
              platform={data.platform}
              savingKeys={savingKeys}
              savePlatform={savePlatform}
            />
            <WebhooksSection
              platform={data.platform}
              savingKeys={savingOptionKeys}
              saveOptions={saveOptions}
            />
            <StripeSection
              platform={data.platform}
              savingKeys={savingOptionKeys}
              saveOptions={saveOptions}
            />
            <ActiveCampaignSection
              platform={data.platform}
              savingKeys={savingOptionKeys}
              saveOptions={saveOptions}
            />
            <ZendeskSection
              platform={data.platform}
              savingKeys={savingOptionKeys}
              saveOptions={saveOptions}
            />
            <ProductAnalyticsSection
              platform={data.platform}
              savingKeys={savingOptionKeys}
              saveOptions={saveOptions}
            />
          </div>
        )
      )}
    </PageContainer>
  );
};
