/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type SubscriptionsQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
  status?: Types.InputMaybe<Types.SubscriptionStatus>;
  planId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endAtFrom?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  endAtTo?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  promoCode?: Types.InputMaybe<Types.OptionalStringFilter>;
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
}>;

export type SubscriptionsQuery = {
  subscriptions: {
    __typename: 'SubscriptionsList';
    totalCount: number;
    subscriptions: Array<{
      __typename: 'Sub';
      id: string;
      status: Types.SubscriptionStatus;
      startAt: string;
      endAt?: string | null;
      plan: { __typename: 'SubscriptionPlan'; id: string; name: string };
      subscriptionOrganizations: Array<{ __typename: 'SubscriptionOrganization'; id: string }>;
      managerOrganization?: {
        __typename: 'OrganizationProfile';
        id: string;
        name?: string | null;
        primaryUser?: {
          __typename: 'UserProfile';
          id: string;
          displayName: string;
          email: string;
        } | null;
      } | null;
    }>;
  };
};

export type SubscriptionQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type SubscriptionQuery = {
  subscription: {
    __typename: 'Sub';
    id: string;
    status: Types.SubscriptionStatus;
    startAt: string;
    endAt?: string | null;
    price: string;
    invoiceFrequency?: Types.InvoiceFrequency | null;
    plan: { __typename: 'SubscriptionPlan'; id: string; name: string };
    notes: Array<{
      __typename: 'SubscriptionNote';
      id: string;
      createdAt: string;
      notes: string;
      byUser: { __typename: 'UserProfile'; id: string; displayName: string };
    }>;
    subscriptionOrganizations: Array<{
      __typename: 'SubscriptionOrganization';
      id: string;
      organization: {
        __typename: 'Organization';
        id: string;
        name?: string | null;
        primaryUser?: { __typename: 'User'; id: string; displayName: string; email: string } | null;
      };
    }>;
  };
};

export type PromoCodePicklistQueryVariables = Types.Exact<{
  platformId: Types.Scalars['String']['input'];
}>;

export type PromoCodePicklistQuery = {
  promoCodePicklist: { __typename: 'PromoCodePicklist'; promoCodes: Array<string> };
};

export const SubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Subscriptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionStatus' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAtFrom' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAtTo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OptionalStringFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAtFrom' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAtFrom' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAtTo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAtTo' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'promoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionOrganizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const SubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Subscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceFrequency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'byUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionOrganizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionQuery, SubscriptionQueryVariables>;
export const PromoCodePicklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PromoCodePicklist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promoCodePicklist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platformId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'promoCodes' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PromoCodePicklistQuery, PromoCodePicklistQueryVariables>;
