import { useQuery } from '@apollo/client';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { withEditSiteContext } from '../../../../../../../contexts/admin/edit-site-context/edit-site-context.js';
import { useErrorHandler } from '../../../../../../../hooks/useErrorHandler.js';
import { PageContainer } from '../../../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../../../Spinner.js';
import { SiteHeader } from '../../site-header.js';
import { EditFormContext, EditFormContextProvider } from './edit-form-context.js';
import { activeTab, FormBreadcrumbs, FormTabs } from './form-tabs.js';
import { FormDocument } from './queries.generated.js';

type GuardedFormOwnProps = {
  id: string;
};

const GuardedForm = withEditSiteContext<GuardedFormOwnProps>(({ id, site, refetchSite }) => {
  const { pathname } = useLocation();
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading, refetch } = useQuery(FormDocument, {
    onError,
    variables: { id },
  });

  const form = data?.form;

  const editFormContext = useMemo<EditFormContext | null>(
    () =>
      form
        ? {
            form,
            formContext: {
              id: form.id,
              currentStep: null,
              confirmationMessageComponents: null,
              submitting: false,
              submitted: false,
            },
            site: {
              site,
              refetchSite,
            },
            refetchForm: refetch,
          }
        : null,
    [form, refetch, refetchSite, site],
  );

  return (
    <Fragment>
      <SiteHeader domain={site.domain} name={site.name} />
      <PageContainer pageMetaTitle={form ? `Form: ${form.title}` : 'Form'}>
        <FormBreadcrumbs className="mb-2" site={site} form={form} />
        <ErrorMessage />
        {editFormContext ? (
          <EditFormContextProvider value={editFormContext}>
            <FormTabs siteId={site.id} formId={id} activeTab={activeTab(id, pathname)}>
              <Outlet />
            </FormTabs>
          </EditFormContextProvider>
        ) : (
          loading && <Spinner />
        )}
      </PageContainer>
    </Fragment>
  );
});

export const Form: FunctionComponent = () => {
  const { formId } = useParams<{ formId: string }>();

  return formId ? <GuardedForm id={formId} /> : <p>Invalid page: missing formId parameter.</p>;
};
