import {
  ExpressionType,
  isIncompleteArrayExpression,
  isIncompleteBooleanExpression,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import type { Filter as FilterType } from '@wirechunk/schemas/expressions/expression';
import { Fragment, FunctionComponent } from 'react';
import { ExpressionBuilder } from '../ExpressionBuilder.js';
import type { BuilderProps } from './shared.js';

const arrayExpressionTypes: ExpressionType[] = [ExpressionType.Array];
const filterExpressionTypes: ExpressionType[] = [ExpressionType.Boolean];

export const Filter: FunctionComponent<BuilderProps<FilterType>> = ({
  id,
  expression,
  setExpression,
}) => {
  return (
    <Fragment>
      <div>
        <div className="font-medium mb-1">Array expression</div>
        <ExpressionBuilder
          id={`${id}-array-expression`}
          expression={expression.arrayExpression}
          setExpression={(arrayExpression) => {
            if (isIncompleteArrayExpression(arrayExpression)) {
              setExpression({
                ...expression,
                arrayExpression,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={arrayExpressionTypes}
        />
      </div>
      <div>
        <div className="font-medium mb-1">Condition (elements to count)</div>
        <ExpressionBuilder
          id={`${id}-filterExpression`}
          expression={expression.filterExpression}
          setExpression={(filterExpression) => {
            if (isIncompleteBooleanExpression(filterExpression)) {
              setExpression({
                ...expression,
                filterExpression,
              });
            }
          }}
          // TODO: Local validation.
          validationMessages={[]}
          types={filterExpressionTypes}
        />
      </div>
    </Fragment>
  );
};
