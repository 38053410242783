/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type PageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PageQuery = {
  page: {
    __typename: 'Page';
    id: string;
    path: string;
    title: string;
    components: string;
    bodyStyles: string;
    metaTitle?: string | null;
    metaDescription?: string | null;
    metaRobots?: Array<string> | null;
    status: Types.PublishStatus;
    public: boolean;
    productItem?: string | null;
    roles: Array<Types.Role>;
    featuredImageUrl?: string | null;
    caption?: string | null;
    edited: boolean;
    template?: { __typename: 'PageTemplate'; id: string; title: string } | null;
    purpose: { __typename: 'PagePurpose'; id: string };
  };
};

export const PageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Page' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bodyStyles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaRobots' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purpose' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'public' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productItem' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edited' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageQuery, PageQueryVariables>;
