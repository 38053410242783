import {
  TextInputComponent,
  TextInputComponentFormat,
} from '@wirechunk/lib/mixer/types/components.js';
import { isTextInputComponentFormat } from '@wirechunk/lib/mixer/utils.js';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent } from 'react';
import type { SelectItem } from '../../../types.js';
import type { EditComponentContentProps } from './shared/types.js';

const formatOptions: Array<SelectItem<TextInputComponentFormat>> = [
  { label: 'None', value: TextInputComponentFormat.None },
  { label: 'Email address', value: TextInputComponentFormat.Email },
  { label: 'Phone number', value: TextInputComponentFormat.Phone },
  { label: 'URL', value: TextInputComponentFormat.Url },
];

export const EditTextInput: FunctionComponent<EditComponentContentProps<TextInputComponent>> = ({
  component,
  setComponent,
}) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editTextInputFormat">Format</label>
        <Dropdown
          inputId="editTextInputFormat"
          className="w-full"
          options={formatOptions}
          value={component.format || TextInputComponentFormat.None}
          onChange={({ value }) => {
            if (isTextInputComponentFormat(value)) {
              setComponent(
                (c): TextInputComponent => ({
                  ...c,
                  format: value,
                }),
              );
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="editTextInputPlaceholder">Placeholder</label>
        <InputText
          id="editTextInputPlaceholder"
          className="w-full"
          value={component.placeholder || ''}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              placeholder: e.target.value,
            }));
          }}
        />
      </div>
    </Fragment>
  );
};
