/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type CreateSubscriptionMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['String']['input'];
  subscriptionPlanId: Types.Scalars['String']['input'];
  stripeSubscriptionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  startAt: Types.Scalars['Date']['input'];
  status: Types.SubscriptionStatus;
  notes: Types.Scalars['String']['input'];
}>;

export type CreateSubscriptionMutation = {
  createSubscription: {
    __typename: 'SubscriptionOrganization';
    id: string;
    isSubscriptionManager: boolean;
    subscription: {
      __typename: 'Sub';
      id: string;
      status: Types.SubscriptionStatus;
      startAt: string;
      endAt?: string | null;
      stripeSubscriptionId?: string | null;
      price: string;
      invoiceFrequency?: Types.InvoiceFrequency | null;
      plan: { __typename: 'SubscriptionPlan'; id: string; name: string };
    };
  };
};

export const CreateSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stripeSubscriptionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionStatus' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionPlanId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionPlanId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripeSubscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stripeSubscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSubscriptionManager' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stripeSubscriptionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceFrequency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
