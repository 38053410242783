import { useQuery } from '@apollo/client';
import { Fragment, FunctionComponent } from 'react';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { Spinner } from '../../Spinner.js';
import { CourseNotesDocument } from './queries.generated.js';

type CourseTakeawaysProps = {
  userId: string;
  plan: {
    id: string;
  };
  emptyNotesMessage: string;
};

export const CourseTakeaways: FunctionComponent<CourseTakeawaysProps> = ({
  userId,
  plan,
  emptyNotesMessage,
}) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(CourseNotesDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: plan.id,
      userId,
    },
  });

  const notes = data?.course.notes ?? null;

  return (
    <Fragment>
      <ErrorMessage />
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-column gap-2">
          {notes && notes.length > 0 ? (
            notes.map((note, i) => (
              <div key={i} className="flex flex-column border-1 border-round">
                <div className="font-medium border-bottom-1 px-3 py-2">
                  {note.stageBlueprintName}
                </div>
                <div className="px-3 py-2 overflow-x-hidden white-space-pre-line">
                  {note.takeaways}
                </div>
              </div>
            ))
          ) : (
            <div>{emptyNotesMessage}</div>
          )}
        </div>
      )}
    </Fragment>
  );
};
