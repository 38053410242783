import { FetchResult, useMutation } from '@apollo/client';
import type { OptionInput } from '@wirechunk/lib/api.js';
import type { ErrorHandler } from '../useErrorHandler.js';
import { EditSiteOptionsDocument, EditSiteOptionsMutation } from './mutations.generated.js';

type EditSiteOptions = {
  editOptions: (
    options: OptionInput[],
    onCompleted?: (data: EditSiteOptionsMutation) => void,
  ) => Promise<FetchResult<EditSiteOptionsMutation>>;
  saving: boolean;
};

export const useEditSiteOptions = (
  siteId: string,
  onError: ErrorHandler['onError'],
): EditSiteOptions => {
  const [editOptions, { loading: saving }] = useMutation(EditSiteOptionsDocument, {
    onError,
  });

  return {
    editOptions: (options, onCompleted) =>
      editOptions({ variables: { siteId, options }, onCompleted }),
    saving,
  };
};
