import { useMutation, useQuery } from '@apollo/client';
import { GCloudCertificateState, Permission } from '@wirechunk/lib/api.js';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import { usePlatformContext } from '../../../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../../../contexts/ToastContext.js';
import { ErrorHandlerOptions, useErrorHandler } from '../../../../../../hooks/useErrorHandler.js';
import { BasicIconButton } from '../../../../../BasicIconButton/BasicIconButton.js';
import { NoneLabel } from '../../../../../NoneLabel/NoneLabel.js';
import { OptionsSection } from '../../../../../OptionsSection.js';
import { CertificateStateView } from '../../../../../OrganizationDetails/CertificateStateView.js';
import { Spinner } from '../../../../../Spinner.js';
import {
  CreateTlsCertificateDocument,
  CreateTlsCertificateMapEntryDocument,
  DeleteTlsCertificateDocument,
} from './mutations.generated.js';
import { SiteTlsCertificatesDocument } from './queries.generated.js';
import type { SiteSettingsSectionProps } from './types.js';

const certificateNameTooltipTargetClassName = 'certificate-name-click-copy';

// Take only the segment of the name following the last slash.
const cleanCertificateName = (name: string) => name.split('/').pop() ?? name;

const errorHandlerOptions: ErrorHandlerOptions = { noScrollToMessage: true };

export const TlsCertificatesSection: FunctionComponent<SiteSettingsSectionProps> = ({
  site,
  saveOptions,
}) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { onError, onErrorToast, ErrorMessage } = useErrorHandler(errorHandlerOptions);
  const { permissions } = usePlatformContext();
  const certNameTooltip = useRef<Tooltip>(null);

  const {
    data,
    loading,
    refetch: refetchCertificates,
  } = useQuery(SiteTlsCertificatesDocument, {
    onError,
    variables: {
      id: site.id,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 15,
  });

  const [createCertificate, { loading: creatingCertificate }] = useMutation(
    CreateTlsCertificateDocument,
    {
      onError,
    },
  );

  const [createCertificateMapEntry, { loading: creatingCertificateMapEntry }] = useMutation(
    CreateTlsCertificateMapEntryDocument,
    {
      onError,
    },
  );

  const [deleteCertificate, { loading: deletingCertificate }] = useMutation(
    DeleteTlsCertificateDocument,
    {
      onError,
    },
  );

  // Refetch certificates anytime the domain changes.
  useEffect(() => {
    void refetchCertificates();
  }, [site.domain, refetchCertificates]);

  useEffect(() => {
    if (data) {
      certNameTooltip.current?.updateTargetEvents(undefined);
    }
  }, [data]);

  const onCreateCertificate = () => {
    dialog({
      confirm: `Are you sure you want to create a certificate for “${site.domain}”?`,
      props: {
        onAccept: () => {
          void createCertificate({
            variables: { siteId: site.id },
            onCompleted: () => {
              toastSuccess('Certificate created successfully');
              void refetchCertificates();
            },
          });
        },
      },
    });
  };

  const onDeleteCertificate = (certificateName: string) => {
    dialog({
      confirm: 'Are you sure you want to delete this certificate?',
      props: {
        onAccept: () => {
          void deleteCertificate({
            variables: { certificateName },
            onCompleted: () => {
              toastSuccess('Certificate deleted successfully');
              void refetchCertificates();
            },
          });
        },
      },
    });
  };

  const hasEditPermission = permissions.includes(Permission.EditSiteTlsCertificate);

  return (
    <OptionsSection
      title="TLS certificates"
      allowSave={false}
      getOptions={() => []}
      saveOptions={saveOptions}
      showSaveButton={false}
    >
      <ErrorMessage />
      {data ? (
        <Fragment>
          {data.site.tlsCertificates.length > 0 ? (
            data.site.tlsCertificates.map((certificate) => (
              <div key={certificate.id} className="flex gap-2 align-items-center">
                <div>
                  Certificate{' '}
                  <span
                    className={`cursor-pointer ${certificateNameTooltipTargetClassName}`}
                    data-pr-tooltip="Copy certificate name"
                    data-pr-position="top"
                    onClick={() => {
                      void navigator.clipboard.writeText(certificate.name);
                      toastSuccess('Certificate name copied to clipboard');
                    }}
                  >
                    {cleanCertificateName(certificate.name)}
                  </span>
                </div>
                <CertificateStateView state={certificate.state} />
                {hasEditPermission && (
                  <BasicIconButton
                    className="text-color-danger ml-1"
                    icon={PrimeIcons.TRASH}
                    onClick={() => {
                      onDeleteCertificate(certificate.name);
                    }}
                    disabled={deletingCertificate}
                  />
                )}
              </div>
            ))
          ) : (
            <NoneLabel />
          )}
          {!data.site.tlsCertificates.length && hasEditPermission && (
            <Button
              label={creatingCertificate ? 'Creating…' : 'Create'}
              className="p-button-text ml-2 p-0"
              disabled={creatingCertificate}
              onClick={onCreateCertificate}
            />
          )}
          {data.site.tlsCertificates.length > 0 &&
            !data.site.tlsCertificates.some(
              (cert) => cert.domain === site.domain && cert.mapEntryState,
            ) &&
            hasEditPermission && (
              <div className="mt-2">
                <p>
                  It appears that a certificate map entry for the domain {site.domain} has not been
                  created.
                </p>
                <Button
                  label="Create certificate map entry"
                  disabled={creatingCertificateMapEntry}
                  onClick={() => {
                    const cert =
                      // Try to find an active certificate first.
                      data.site.tlsCertificates.find(
                        (cert) =>
                          cert.domain === site.domain &&
                          cert.state === GCloudCertificateState.Active,
                      ) ??
                      // Otherwise, try to find a certificate that's provisioning.
                      data.site.tlsCertificates.find(
                        (cert) =>
                          cert.domain === site.domain &&
                          cert.state === GCloudCertificateState.Provisioning,
                      ) ??
                      // Otherwise, just use any certificate.
                      data.site.tlsCertificates[0];
                    if (cert) {
                      void createCertificateMapEntry({
                        variables: {
                          certificateName: cert.name,
                          domain: site.domain,
                        },
                      });
                    } else {
                      onErrorToast('No certificate was found');
                    }
                  }}
                />
              </div>
            )}
        </Fragment>
      ) : (
        loading && <Spinner />
      )}
      <Tooltip ref={certNameTooltip} target={`.${certificateNameTooltipTargetClassName}`} />
    </OptionsSection>
  );
};
