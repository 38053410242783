/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type CurrentSiteFormQueryVariables = Types.Exact<{
  formId: Types.Scalars['String']['input'];
}>;

export type CurrentSiteFormQuery = {
  currentSite: {
    __typename: 'Site';
    id: string;
    form: {
      __typename: 'SiteForm';
      id: string;
      components: string;
      confirmationAction: Types.ConfirmationAction;
      confirmationMessageComponents?: string | null;
      confirmationRedirectUrl?: string | null;
      confirmationRedirectParameters: Array<{
        __typename: 'FormConfirmationRedirectUrlParameter';
        id: string;
        parameter: string;
        componentName: string;
      }>;
      steps: Array<{ __typename: 'FormStep'; id: string; components: string }>;
    };
  };
};

export type FormTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type FormTemplateQuery = {
  formTemplate: {
    __typename: 'FormTemplate';
    id: string;
    components: string;
    confirmationAction: Types.ConfirmationAction;
    confirmationMessageComponents?: string | null;
    confirmationRedirectUrl?: string | null;
    confirmationRedirectParameters: Array<{
      __typename: 'FormConfirmationRedirectUrlParameter';
      id: string;
      parameter: string;
      componentName: string;
    }>;
    steps: Array<{ __typename: 'FormTemplateStep'; id: string; components: string }>;
  };
};

export type InitialFormDataQueryVariables = Types.Exact<{
  formId: Types.Scalars['String']['input'];
  pageId: Types.Scalars['String']['input'];
}>;

export type InitialFormDataQuery = {
  initialFormData: { __typename: 'InitialFormData'; formData?: string | null };
};

export const CurrentSiteFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentSiteForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'form' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmationAction' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationMessageComponents' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmationRedirectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationRedirectParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'steps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentSiteFormQuery, CurrentSiteFormQueryVariables>;
export const FormTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FormTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationMessageComponents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmationRedirectUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmationRedirectParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'componentName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'components' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormTemplateQuery, FormTemplateQueryVariables>;
export const InitialFormDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InitialFormData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initialFormData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'formData' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitialFormDataQuery, InitialFormDataQueryVariables>;
