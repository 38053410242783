import { useQuery } from '@apollo/client';
import { SubscriptionStatus } from '@wirechunk/lib/api.js';
import { invoiceFrequencyHumanInterval } from '@wirechunk/lib/invoices.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { trimTrailingZeroes } from '@wirechunk/lib/strings.js';
import { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ErrorHandler } from '../../../hooks/useErrorHandler.js';
import { subscriptionStatusToHumanReadable } from '../../../util/subscriptions.js';
import { Spinner } from '../../Spinner.js';
import styles from '../order-form/order-form.module.css';
import { MeSubscriptionsDocument } from './queries.generated.js';

type UserSubscriptionsProps = {
  onError: ErrorHandler['onError'];
};

export const UserSubscriptions: FunctionComponent<UserSubscriptionsProps> = ({ onError }) => {
  const { data, loading } = useQuery(MeSubscriptionsDocument, { onError });

  if (loading) {
    return <Spinner />;
  }

  if (!data?.me) {
    return null;
  }

  // Sorted by price descending.
  const subscriptionOrgsSorted = data.me.organization.subscriptionOrganizations.toSorted(
    ({ subscription: subA }, { subscription: subB }) => Number(subA.price) - Number(subB.price),
  );

  const activeSubscriptions = subscriptionOrgsSorted.filter(
    (so) => so.subscription.status === SubscriptionStatus.Active,
  );

  const pastDueSubscriptions = subscriptionOrgsSorted.filter(
    (so) => so.subscription.status === SubscriptionStatus.PastDue,
  );

  const unpaidSubscriptions = subscriptionOrgsSorted.filter(
    (so) => so.subscription.status === SubscriptionStatus.Unpaid,
  );

  if (activeSubscriptions.length || pastDueSubscriptions.length || unpaidSubscriptions.length) {
    return (
      <Fragment>
        <p className="font-medium">
          You have {activeSubscriptions.length || 'no'} active{' '}
          {pluralize(activeSubscriptions.length || 0, 'subscription')}
        </p>
        <div className="flex flex-column gap-2 lg:max-w-30rem">
          {activeSubscriptions.map(({ subscription }) => (
            <div key={subscription.id} className="p-3 surface-ground border-round">
              <span className="font-medium">
                {subscription.planProfile.displayName || subscription.planProfile.name}
              </span>
              :
              <span className="pl-2">
                ${trimTrailingZeroes(subscription.price)}
                {subscription.invoiceFrequency && (
                  <span className={styles.priceFrequency}>
                    /{invoiceFrequencyHumanInterval(subscription.invoiceFrequency)}
                  </span>
                )}
              </span>
            </div>
          ))}
        </div>
        {(!!pastDueSubscriptions.length || !!unpaidSubscriptions.length) && (
          <Fragment>
            <p className="mt-4 font-medium">
              You have {pastDueSubscriptions.length + unpaidSubscriptions.length} past due or unpaid{' '}
              {pluralize(pastDueSubscriptions.length + unpaidSubscriptions.length, 'subscription')}
            </p>
            <div className="flex flex-column gap-2 lg:max-w-30rem">
              {[...pastDueSubscriptions, ...unpaidSubscriptions].map(({ subscription }) => (
                <div key={subscription.id} className="p-3 surface-ground border-round">
                  <span className="font-medium">
                    <span className="font-bold">
                      [{subscriptionStatusToHumanReadable(subscription.status)}]
                    </span>{' '}
                    {subscription.planProfile.displayName || subscription.planProfile.name}
                  </span>
                  :
                  <span className="pl-2">
                    ${trimTrailingZeroes(subscription.price)}
                    {subscription.invoiceFrequency && (
                      <span className={styles.priceFrequency}>
                        /{invoiceFrequencyHumanInterval(subscription.invoiceFrequency)}
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
            <Link to="/contact" className="p-button text-white mt-3 flex w-max">
              Contact support
            </Link>
          </Fragment>
        )}
      </Fragment>
    );
  }

  return <p className="font-medium">You have no active subscriptions</p>;
};
