import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { serializeAddress } from '@wirechunk/lib/addresses.js';
import { FileUploadFeature } from '@wirechunk/lib/api.js';
import { HelpTicketType } from '@wirechunk/lib/help-tickets.js';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { Component, SetUpSiteGuardComponent } from '@wirechunk/lib/mixer/types/components.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { SiteOptionKey } from '@wirechunk/lib/site-options.js';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import { useEditSiteOptions } from '../../../hooks/use-edit-site-options/use-edit-site-options.js';
import { useCustomDomainSetupTickets } from '../../../hooks/useCustomDomainSetupTickets/useCustomDomainSetupTickets.js';
import { ErrorHandler, useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { useUploadFile } from '../../../hooks/useUploadFile/useUploadFile.js';
import { RenderMixerChildren } from '../../RenderMixerChildren.js';
import {
  SelectGooglePlace,
  SelectGooglePlaceProps,
} from '../../SelectGooglePlace/SelectGooglePlace.js';
import { Spinner } from '../../Spinner.js';
import { CreateHelpTicketDocument } from '../HelpTicketsPortal/mutations.generated.js';
import type { SiteSetupPropertiesFragment } from './fragments.generated.js';
import { CreateSiteDocument } from './mutations.generated.js';
import {
  OrganizationSiteDocument,
  OrganizationSiteQuery,
  OrganizationSiteQueryVariables,
} from './queries.generated.js';
import styles from './SetUpSiteGuard.module.css';

enum Stage {
  Basic,
  Address,
  GooglePlace,
  AgentPhoto,
  Domain,
}

const allStages = [Stage.Basic, Stage.Address, Stage.GooglePlace, Stage.AgentPhoto, Stage.Domain];

type GuardedSetUpSiteGuardProps = Pick<SetUpSiteGuardComponent, 'id' | 'className'> & {
  site: SiteSetupPropertiesFragment;
  refetchSite: () => Promise<ApolloQueryResult<OrganizationSiteQuery>>;
  organizationId: string;
  onError: ErrorHandler['onError'];
  clearMessages: ErrorHandler['clearMessages'];
  ErrorMessage: ErrorHandler['ErrorMessage'];
  children: Component[] | null | undefined;
};

const GuardedSetUpSiteGuard: FunctionComponent<GuardedSetUpSiteGuardProps> = ({
  site,
  refetchSite,
  organizationId,
  onError,
  clearMessages,
  ErrorMessage,
  children,
  ...props
}) => {
  const { platformId } = useSiteContext();
  const dialog = useDialog();
  const { user } = useCurrentUser();
  const [stage, setStage] = useState(Stage.Basic);

  const { tickets: customDomainSetupTickets } = useCustomDomainSetupTickets(
    platformId,
    organizationId,
    site.domain,
    onError,
  );

  const { editOptions, saving: savingOptions } = useEditSiteOptions(site.id, onError);

  const [createHelpTicket, { loading: creatingHelpTicket }] = useMutation(
    CreateHelpTicketDocument,
    {
      onError,
    },
  );

  const { upload: uploadAgentPhoto, isUploading: isUploadingAgentPhoto } = useUploadFile(onError);

  const [stayOnAgentPhoto, setStayOnAgentPhoto] = useState(false);

  const [name, setName] = useState(site.name);
  const [agentFirstName, setAgentFirstName] = useState(site.agentFirstName || '');
  const [agentLastName, setAgentLastName] = useState(site.agentLastName || '');
  const [addressLine1, setAddressLine1] = useState(site.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(site.addressLine2 || '');
  const [addressCity, setAddressCity] = useState(site.addressCity || '');
  const [addressState, setAddressState] = useState(site.addressState || '');
  const [addressZip, setAddressZip] = useState(site.addressZip || '');
  const [agencyPrimaryPhone, setAgencyPrimaryPhone] = useState(site.agencyPrimaryPhone || '');
  const [googlePlaceId, setGooglePlaceId] = useState<string | null | undefined>(site.googlePlaceId);

  const [googlePlaceDescription, setGooglePlaceDescription] = useState('');

  const setGooglePlace = useCallback<SelectGooglePlaceProps['setPlace']>((place) => {
    setGooglePlaceId(place.place_id);
    const description = place.name || place.formatted_address;
    if (description) {
      setGooglePlaceDescription(description);
    }
  }, []);

  const [agentPhotoFile, setAgentPhotoFile] = useState<File | null>(null);

  const agentPhotoFileInput = useRef<HTMLInputElement>(null);

  const [newCustomDomainToRequest, setNewCustomDomainToRequest] = useState('');

  const [skippedGooglePlaceId, setSkippedGooglePlaceId] = useState(false);
  const [skippedAgentPhoto, setSkippedAgentPhoto] = useState(false);

  const [skipSetup, setSkipSetup] = useState(false);

  const initialLoadDone = useRef(false);

  const completedBasicForm =
    !!name.trim() &&
    !!agentFirstName.trim() &&
    !!agentLastName.trim() &&
    !!agencyPrimaryPhone.trim();
  const completedAddressForm =
    !!addressLine1.trim() && !!addressCity.trim() && !!addressState.trim() && !!addressZip.trim();

  useEffect(() => {
    setName(site.name);

    let newAgentFirstName = site.agentFirstName;
    if (!newAgentFirstName) {
      newAgentFirstName = user.firstName;
    }

    let newAgentLastName = site.agentLastName;
    if (!newAgentLastName) {
      newAgentLastName = user.lastName;
    }

    setAgentFirstName(newAgentFirstName);
    setAgentLastName(newAgentLastName);
    setAddressLine1(site.addressLine1 || '');
    setAddressLine2(site.addressLine2 || '');
    setAddressCity(site.addressCity || '');
    setAddressState(site.addressState || '');
    setAddressZip(site.addressZip || '');
    setAgencyPrimaryPhone(site.agencyPrimaryPhone || '');
    setGooglePlaceId(site.googlePlaceId);
  }, [site, user]);

  useEffect(() => {
    const completedBasic =
      !!site.name.trim() && !!site.agentFirstName?.trim() && !!site.agentLastName?.trim();
    const completedAddress =
      !!site.addressLine1?.trim() &&
      !!site.addressCity?.trim() &&
      !!site.addressState?.trim() &&
      !!site.addressZip?.trim();
    const completedGooglePlace = !!site.googlePlaceId;
    const completedAgentPhoto = !!site.agentPhotoUrl;

    if (completedBasic) {
      setStage(Stage.Address);
      if (completedAddress) {
        setStage(Stage.GooglePlace);
        if (!initialLoadDone.current) {
          // At this point we know that this is the first time this effect is running with `org` defined.
          // Because the required stages are complete, we can skip setup entirely.
          setSkipSetup(true);
        }
        if (skippedGooglePlaceId || completedGooglePlace) {
          setStage(Stage.AgentPhoto);
          if (!stayOnAgentPhoto && (skippedAgentPhoto || completedAgentPhoto)) {
            // In this effect, we do not set `skipSetup` so that we don't skip the last step automatically.
            setStage(Stage.Domain);
          }
        }
      }
    }
    initialLoadDone.current = true;
  }, [site, skippedGooglePlaceId, skippedAgentPhoto, stayOnAgentPhoto]);

  if (skipSetup) {
    return <RenderMixerChildren>{children}</RenderMixerChildren>;
  }

  const save = () => {
    clearMessages();

    if (stage === Stage.Basic) {
      void editOptions([
        {
          key: SiteOptionKey.Name,
          value: JSON.stringify(name),
        },
        {
          key: SiteOptionKey.PrimaryPhone,
          value: JSON.stringify(agencyPrimaryPhone),
        },
        {
          key: SiteOptionKey.AgentFirstName,
          value: JSON.stringify(agentFirstName),
        },
        {
          key: SiteOptionKey.AgentLastName,
          value: JSON.stringify(agentLastName),
        },
      ]);
      return;
    }

    if (stage === Stage.Address) {
      void editOptions([
        {
          key: SiteOptionKey.AddressLine1,
          value: JSON.stringify(addressLine1),
        },
        {
          key: SiteOptionKey.AddressLine2,
          value: JSON.stringify(addressLine2),
        },
        {
          key: SiteOptionKey.AddressCity,
          value: JSON.stringify(addressCity),
        },
        {
          key: SiteOptionKey.AddressState,
          value: JSON.stringify(addressState),
        },
        {
          key: SiteOptionKey.AddressZip,
          value: JSON.stringify(addressZip),
        },
      ]);
      return;
    }

    if (stage === Stage.GooglePlace) {
      if (googlePlaceId) {
        void editOptions([
          {
            key: SiteOptionKey.GooglePlaceId,
            value: JSON.stringify(googlePlaceId),
          },
        ]);
      } else {
        // There's nothing to save in this case.
        setSkippedGooglePlaceId(true);
      }
      return;
    }

    if (stage === Stage.AgentPhoto) {
      if (stayOnAgentPhoto && !agentPhotoFile) {
        // We just uploaded an image. Carry on to the next step.
        // Here we check !agentPhotoFile in case the user selects a different photo after the first upload.
        setStayOnAgentPhoto(false);
        setSkippedAgentPhoto(true);
        return;
      }
      if (agentPhotoFile) {
        void uploadAgentPhoto(
          agentPhotoFile,
          {
            feature: FileUploadFeature.SiteAgentPhotoUrl,
            siteId: site.id,
          },
          () => {
            // File uploaded.
            const { current: input } = agentPhotoFileInput;
            if (input) {
              input.value = '';
            }
            setAgentPhotoFile(null);
          },
          () => {
            // Done polling for file (it either uploaded and the File in the database is updated, or the upload failed).
            setStayOnAgentPhoto(true);
            void refetchSite();
          },
        );
        return;
      }
      // There's nothing to save in this case.
      setSkippedAgentPhoto(true);
      return;
    }

    if (newCustomDomainToRequest) {
      void createHelpTicket({
        variables: {
          type: HelpTicketType.SiteCustomDomain,
          message: '',
          customDomain: newCustomDomainToRequest,
        },
        onCompleted: () => {
          // Domain is the last step, so at this point we are done with setup.
          setSkipSetup(true);
        },
      });
      return;
    }
    // Domain is the last step, so at this point we are done with setup.
    setSkipSetup(true);
  };

  let agentName = site.agentFirstName;
  if (agentName && site.agentLastName) {
    agentName += ` ${site.agentLastName}`;
  }
  if (!agentName) {
    agentName = user.firstName;
    if (agentName && user.lastName) {
      agentName += ` ${user.lastName}`;
    }
  }

  const agencyNamePlaceholder = agentName
    ? `e.g., ${agentName}’s Insurance Agency`
    : 'e.g., John Doe’s Insurance Agency';

  const saveButton = (
    <div className="flex justify-content-end">
      <Button
        label="Next"
        icon={PrimeIcons.ARROW_CIRCLE_RIGHT}
        iconPos="right"
        className="mt-3"
        disabled={
          savingOptions ||
          (stage === Stage.Basic && !completedBasicForm) ||
          (stage === Stage.Address && !completedAddressForm) ||
          (stage === Stage.AgentPhoto && isUploadingAgentPhoto) ||
          (stage === Stage.Domain && creatingHelpTicket)
        }
        onClick={save}
      />
    </div>
  );

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <h1 className="text-4xl text-center">Welcome to the start of something great!</h1>
      <div className={`${styles.bodyContainer} mt-3`}>
        <div className="my-5 mx-auto py-4 px-3 surface-ground border-round-lg w-max text-center">
          <div className="mb-3 font-bold">SETUP PROGRESS</div>
          <div className="flex gap-3 align-items-center justify-content-center">
            {allStages.map((s) => (
              <div
                key={s}
                className={clsx(
                  styles.stageCircle,
                  stage > s && styles.completed,
                  stage === s && styles.current,
                  stage < s && styles.future,
                )}
              >
                {s + 1}
              </div>
            ))}
          </div>
        </div>
      </div>
      {stage === Stage.Basic ? (
        <div className={styles.bodyContainer}>
          <p className="text-lg">
            First, let’s start with some basics about your agency. You’ll be able to edit this
            later.
          </p>
          <div className="input-field">
            <label htmlFor="setUpOrgAgentFirstName">Agent’s first name</label>
            <InputText
              id="setUpOrgAgentFirstName"
              className="w-full"
              value={agentFirstName}
              onChange={(e) => {
                setAgentFirstName(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgAgentLastName">Agent’s last name</label>
            <InputText
              id="setUpOrgAgentLastName"
              className="w-full"
              value={agentLastName}
              onChange={(e) => {
                setAgentLastName(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgName">Agency name</label>
            <InputText
              id="setUpOrgName"
              className="w-full"
              placeholder={agencyNamePlaceholder}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgAgencyPrimaryPhone">Agency primary phone number</label>
            <InputText
              id="setUpOrgAgencyPrimaryPhone"
              className="w-full"
              value={agencyPrimaryPhone}
              onChange={(e) => {
                setAgencyPrimaryPhone(e.target.value);
              }}
            />
          </div>
          {saveButton}
        </div>
      ) : stage === Stage.Address ? (
        <div className={styles.bodyContainer}>
          <p className="text-lg">
            Let’s add your agency’s address. This is used to show people who visit your site where
            your office is located.
          </p>
          <div className="input-field">
            <label htmlFor="setUpOrgAddressLine1">Agency address line 1</label>
            <InputText
              id="setUpOrgAddressLine1"
              className="w-full"
              value={addressLine1}
              onChange={(e) => {
                setAddressLine1(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgAddressLine2">Agency address line 2</label>
            <InputText
              id="setUpOrgAddressLine2"
              className="w-full"
              value={addressLine2}
              onChange={(e) => {
                setAddressLine2(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgAddressCity">Agency city</label>
            <InputText
              id="setUpOrgAddressCity"
              className="w-full"
              value={addressCity}
              onChange={(e) => {
                setAddressCity(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgAddressState">Agency state</label>
            <InputText
              id="setUpOrgAddressState"
              className="w-full"
              value={addressState}
              onChange={(e) => {
                setAddressState(e.target.value);
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="setUpOrgAddressZip">Agency zip code</label>
            <InputText
              id="setUpOrgAddressZip"
              className="w-full"
              value={addressZip}
              onChange={(e) => {
                setAddressZip(e.target.value);
              }}
            />
          </div>
          {saveButton}
        </div>
      ) : stage === Stage.GooglePlace ? (
        // Notice that this div at the outer level intentionally does not have a `bodyContainer` class.
        <div>
          <div className={styles.bodyContainer}>
            <p className="text-lg">
              Let’s find your agency on Google. This is used to show people who visit your site
              where your office is located and is used for the Google Reviews feature.
            </p>
            <p>This step is optional.</p>
            <div className="flex flex-row align-items-center my-3 line-height-4">
              <span className="font-medium mr-2 min-w-max">Selected business on Google:</span>
              <span>{(googlePlaceId && googlePlaceDescription) || '(None)'}</span>
              {googlePlaceId && (
                <Button
                  className="ml-3 p-button-sm p-button-outlined py-1 min-w-max h-min"
                  label="Clear"
                  onClick={() => {
                    setGooglePlaceId(null);
                    setGooglePlaceDescription('');
                  }}
                />
              )}
            </div>
          </div>
          <SelectGooglePlace
            placeId={googlePlaceId}
            setPlace={setGooglePlace}
            initialFindPlaceAddress={serializeAddress({
              addressLine1,
              addressLine2,
              addressCity,
              addressState,
              addressZip,
            })}
            searchFieldContainerClassName={styles.bodyContainer}
            mapClassName={styles.map}
          />
          <div className={`${styles.bodyContainer} mt-2`}>{saveButton}</div>
        </div>
      ) : stage === Stage.AgentPhoto ? (
        <div className={styles.bodyContainer}>
          <p className="text-lg">
            We encourage you to add a photo of yourself (if you are the agency owner). Displaying a
            real photo helps build trust with people visiting your site.
          </p>
          <p>The best photos for this are square images with a minimum size of 200x200 pixels.</p>
          <Button
            className="mb-3 p-button-outlined p-button-xs font-normal"
            label="See example photo"
            onClick={() => {
              dialog({
                content: (
                  <Fragment>
                    <div className="flex justify-content-center pt-3 pb-4">
                      <img
                        src="https://storage.googleapis.com/wirechunk-core-public/professional-portrait-example.jpeg"
                        alt="Example portrait photo"
                        className="w-20rem h-20rem"
                      />
                    </div>
                    <p className="text-center font-bold">Tips for a great photo:</p>
                    <div className="flex align-items-start gap-2 ml-2">
                      <i className={`${PrimeIcons.CHECK} text-color-success mt-px`} />
                      <span>Use a square-dimensional image (e.g., 400 pixels by 400 pixels).</span>
                    </div>
                    <div className="flex align-items-start gap-2 ml-2">
                      <i className={`${PrimeIcons.CHECK} text-color-success mt-px`} />
                      <span>Use a high resolution image so that it is not blurry.</span>
                    </div>
                    <div className="flex align-items-start gap-2 ml-2 mb-2">
                      <i className={`${PrimeIcons.CHECK} text-color-success mt-px`} />
                      <span>Use a photo of your beautiful self!</span>
                    </div>
                  </Fragment>
                ),
                props: {
                  header: 'Example agent photo',
                },
              });
            }}
          />
          <p>This step is optional.</p>
          {site.agentPhotoUrl && (
            <div className="flex justify-content-center">
              <img src={site.agentPhotoUrl} alt="Agent" className="block w-16rem h-auto" />
            </div>
          )}
          {!isUploadingAgentPhoto && (
            <label
              htmlFor="setUpSiteAgentPhoto"
              className="max-w-14rem mt-3 flex gap-2 p-button p-button-outlined"
            >
              <i className={PrimeIcons.UPLOAD} />
              <span>Select {site.agentPhotoUrl ? ' new ' : ''}photo</span>
            </label>
          )}
          {agentPhotoFile && (
            <Fragment>
              <div className="mt-2 text-gray-700 max-w-full overflow-x-hidden text-overflow-ellipsis">
                Selected: {agentPhotoFile.name}
              </div>
              <Button
                label="Remove selected photo"
                className="mt-1 py-1 p-button-outlined p-button-sm"
                disabled={isUploadingAgentPhoto}
                onClick={() => {
                  setAgentPhotoFile(null);
                  if (agentPhotoFileInput.current) {
                    agentPhotoFileInput.current.value = '';
                  }
                }}
              />
            </Fragment>
          )}
          {isUploadingAgentPhoto && <Spinner />}
          <input
            ref={agentPhotoFileInput}
            id="setUpSiteAgentPhoto"
            className="hidden"
            type="file"
            accept="image/*"
            disabled={isUploadingAgentPhoto}
            onChange={(e) => {
              setAgentPhotoFile(e.target.files?.[0] ?? null);
            }}
          />
          {saveButton}
        </div>
      ) : (
        <div className={styles.bodyContainer}>
          <p className="text-lg">
            Your landing pages can be hosted under a custom website domain name. We encourage you to
            use a custom domain to fully personalize your site.
          </p>
          <p>This step is optional.</p>
          <p className="font-medium">Your current domain: {site.domain}</p>
          {customDomainSetupTickets.length > 0 ? (
            <div>
              <p className="text-lg">
                You have requested a custom domain to be set up for your website. Please hang tight
                as we work on this.
              </p>
              <p>
                Pending {pluralize(customDomainSetupTickets.length, 'domain')}:{' '}
                {customDomainSetupTickets.map((t) => (
                  <span key={t.id} className="ml-2 surface-ground border-round py-1 px-2">
                    {t.domain}
                  </span>
                ))}
              </p>
            </div>
          ) : (
            <div className="input-field">
              <label htmlFor="setUpSiteDomain">New domain</label>
              <InputText
                id="setUpSiteDomain"
                className="w-full"
                value={newCustomDomainToRequest}
                onChange={(e) => {
                  setNewCustomDomainToRequest(e.target.value);
                }}
              />
            </div>
          )}
          {saveButton}
        </div>
      )}
    </div>
  );
};

export const SetUpSiteGuard: FunctionComponent<SetUpSiteGuardComponent> = (props) => {
  const { platformId } = useSiteContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { user } = useCurrentUser();
  const organizationId = user.organization.id;

  const { data, loading, refetch } = useQuery(OrganizationSiteDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { organizationId },
  });

  const [createSite, { loading: creating, data: createSiteData }] = useMutation(
    CreateSiteDocument,
    {
      onError,
      update: (cache, { data: createSiteMutationResult }) => {
        if (data && createSiteMutationResult) {
          cache.writeQuery<OrganizationSiteQuery, OrganizationSiteQueryVariables>({
            query: OrganizationSiteDocument,
            variables: { organizationId },
            data: {
              organization: {
                ...data.organization,
                site: createSiteMutationResult.createSite,
              },
            },
          });
        }
      },
    },
  );

  const hasOrgButNotSite = !!data?.organization && !data.organization.site;

  useEffect(() => {
    if (hasOrgButNotSite) {
      void createSite({ variables: { platformId, organizationId } });
    }
  }, [createSite, hasOrgButNotSite, organizationId, platformId]);

  const site = data?.organization.site || createSiteData?.createSite;

  if (!site) {
    return (
      <div className={componentClassName(props)}>
        <ErrorMessage />
        {(loading || creating) && <Spinner />}
      </div>
    );
  }

  return (
    <GuardedSetUpSiteGuard
      id={props.id}
      className={props.className}
      site={site}
      refetchSite={refetch}
      organizationId={organizationId}
      onError={onError}
      clearMessages={clearMessages}
      ErrorMessage={ErrorMessage}
    >
      {props.children}
    </GuardedSetUpSiteGuard>
  );
};
