import { useQuery } from '@apollo/client';
import { StringFilterOperator, SubscriptionStatus } from '@wirechunk/lib/api.js';
import {
  dateIsInThePast,
  formatDateTime,
  roundToDayEnd,
  roundToDayStart,
} from '@wirechunk/lib/dates.js';
import { isDate, isString } from 'lodash-es';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { usePlatformContext } from '../../../../contexts/admin/platform-context/platform-context.js';
import { useSubscriptionPlanNames } from '../../../../hooks/use-subscription-plan-names/use-subscription-plan-names.js';
import { useErrorHandler } from '../../../../hooks/useErrorHandler.js';
import { useTableRowExpansion } from '../../../../hooks/useTableRowExpansion.js';
import {
  subscriptionStatusOptions,
  subscriptionStatusToHumanReadable,
} from '../../../../util/subscriptions.js';
import { PageContainer } from '../../../PageContainer/PageContainer.js';
import { SubscriptionDetails } from '../../../SubscriptionDetails/SubscriptionDetails.js';
import { DataTableWithPaginator } from '../Users/UserDetails/DataTableWithPaginator.js';
import { PromoCodePicklistDocument, SubscriptionsDocument } from './queries.generated.js';
import { SubscriptionOrganizationCell } from './SubscriptionOrganizationCell.js';
import type { Subscription } from './types.js';

export const Subscriptions: FunctionComponent = () => {
  const { id: platformId } = usePlatformContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data: promoCodePicklistData, loading: loadingPromoCodePicklist } = useQuery(
    PromoCodePicklistDocument,
    {
      onError,
      variables: { platformId },
    },
  );
  const [page, setPage] = useState(0);
  const [tableRowsLimit, setTableRowsLimit] = useState(20);
  const [name, setName] = useState('');
  const [status, setStatus] = useState<SubscriptionStatus | null>(null);
  const [planId, setPlanId] = useState<string | null>(null);
  const [endAtFrom, setEndAtFrom] = useState<Date | null>(null);
  const [endAtTo, setEndAtTo] = useState<Date | null>(null);
  const [promoCode, setPromoCode] = useState<string | null>(null);

  const searchInput = useRef<HTMLInputElement>(null);
  const lastSearchValue = useRef<string>(name);

  const { expandedRows, onRowToggle } = useTableRowExpansion();

  // Update the search state variable on an interval.
  useEffect(() => {
    const interval = setInterval(() => {
      const { current: input } = searchInput;
      if (input && input.value !== lastSearchValue.current) {
        // If the value is just spaces, clear search.
        const newValue = input.value.trim() ? input.value : '';
        setName(newValue);
        lastSearchValue.current = newValue;
        setPage(0);
      }
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { data, loading } = useQuery(SubscriptionsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      platformId,
      status,
      planId,
      endAtFrom,
      endAtTo,
      promoCode: promoCode
        ? {
            filter: {
              operator: StringFilterOperator.Equals,
              value: promoCode,
            },
          }
        : undefined,
      limit: tableRowsLimit,
      page,
    },
  });
  const { subscriptionPlans, loading: loadingSubscriptionPlans } = useSubscriptionPlanNames(
    platformId,
    onError,
  );

  return (
    <PageContainer title="Subscriptions">
      <ErrorMessage />
      <div className="surface-ground p-3 flex flex-column lg:flex-row lg:justify-content-start lg:align-items-center gap-3">
        <Dropdown
          className="w-18rem"
          loading={loadingSubscriptionPlans}
          options={subscriptionPlans?.products ?? []}
          optionLabel="name"
          optionValue="id"
          value={planId}
          placeholder="Plan"
          onChange={(e) => {
            setPlanId(e.value as string);
          }}
          showClear
        />
        <Dropdown
          className="w-19rem"
          value={status}
          options={subscriptionStatusOptions}
          placeholder="Status"
          onChange={(e) => {
            setStatus(e.value as SubscriptionStatus | null);
            setPage(0);
          }}
          showClear
        />
        <Calendar
          placeholder="End date from"
          className="w-14rem"
          value={endAtFrom || undefined}
          showButtonBar
          formatDateTime={(date) => `End date from: ${date.toLocaleDateString()}`}
          onChange={({ value }) => {
            if (isDate(value)) {
              const newEndAtFrom = roundToDayStart(value);
              if (endAtTo && newEndAtFrom.getTime() > endAtTo.getTime()) {
                setEndAtTo(null);
              }
              setEndAtFrom(newEndAtFrom);
            } else {
              setEndAtFrom(null);
            }
          }}
        />
        <Calendar
          placeholder="End date to"
          className="w-14rem"
          value={endAtTo || undefined}
          showButtonBar
          formatDateTime={(date) => `End date to: ${date.toLocaleDateString()}`}
          onChange={({ value }) => {
            if (isDate(value)) {
              const newEndAtTo = roundToDayEnd(value);
              if (endAtFrom && newEndAtTo.getTime() < endAtFrom.getTime()) {
                setEndAtFrom(null);
              }
              setEndAtTo(newEndAtTo);
            } else {
              setEndAtTo(null);
            }
          }}
        />
        <Dropdown
          placeholder="Promo code"
          className="w-10rem"
          loading={loadingPromoCodePicklist}
          options={promoCodePicklistData?.promoCodePicklist.promoCodes || []}
          value={promoCode}
          onChange={({ value }) => {
            if (isString(value)) {
              setPromoCode(value);
              setPage(0);
            }
          }}
        />
      </div>
      <DataTableWithPaginator
        value={data?.subscriptions.subscriptions || []}
        loading={loading}
        emptyMessage="No matching subscriptions found"
        totalRecords={data?.subscriptions.totalCount || 0}
        page={page}
        rows={tableRowsLimit}
        rowsMinimum={20}
        setPage={setPage}
        setRows={setTableRowsLimit}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        rowExpansionTemplate={(subscription: Subscription) => (
          <SubscriptionDetails
            subscriptionId={subscription.id}
            onError={onError}
            clearMessages={clearMessages}
          />
        )}
      >
        <Column expander className="table-left-action-column" />
        <Column header="Plan name" field="plan.name" />
        <Column
          header="Status"
          body={(sub: Subscription) => subscriptionStatusToHumanReadable(sub.status)}
        />
        <Column header="Start date" body={(sub: Subscription) => formatDateTime(sub.startAt)} />
        <Column
          header="End date"
          body={(sub: Subscription) => (
            <div>
              {sub.endAt ? (
                <Fragment>
                  {formatDateTime(sub.endAt)}
                  {dateIsInThePast(new Date(sub.endAt)) && (
                    <span className="ml-3 py-1 px-2 border-round border-1 text-sm">Ended</span>
                  )}
                </Fragment>
              ) : (
                'Never'
              )}
            </div>
          )}
        />
        <Column
          header="Orgs"
          body={(sub: Subscription) => (
            <SubscriptionOrganizationCell
              subscriptionOrganizations={sub.subscriptionOrganizations}
              managerOrganization={sub.managerOrganization}
            />
          )}
        />
      </DataTableWithPaginator>
    </PageContainer>
  );
};
