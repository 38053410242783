import { useMutation } from '@apollo/client';
import { PublishStatus } from '@wirechunk/lib/api.js';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useDocuments } from '../../../../../hooks/use-documents/use-documents.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { useFileGetUrl } from '../../../../../hooks/useFileGetUrl/useFileGetUrl.js';
import { BasicIconButton } from '../../../../BasicIconButton/BasicIconButton.js';
import { PageContainer } from '../../../../PageContainer/PageContainer.js';
import { Spinner } from '../../../../Spinner.js';
import { navItems } from '../nav.js';
import { CreateDocumentDocument } from './mutations.generated.js';

export const Documents: FunctionComponent = () => {
  const { id: platformId, handle, navigate } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const { documents, loading } = useDocuments(platformId, onError);
  const [createDocument, { loading: isCreating }] = useMutation(CreateDocumentDocument, {
    onError,
    onCompleted: (data) => {
      navigate(`/content/documents/${data.createDocument.id}`);
    },
  });
  const { getUrl } = useFileGetUrl(onError);

  const [showCreateDocument, setShowCreateDocument] = useState(false);
  const [newDocumentName, setNewDocumentName] = useState('');

  return (
    <PageContainer title="Documents" nav={navItems(handle)}>
      <ErrorMessage />
      {showCreateDocument ? (
        <div className="mb-3 pt-1 px-3 pb-3 border-1 border-round">
          <h3>Add a Document</h3>
          <div className="input-field">
            <label htmlFor="newDocumentName">Name</label>
            <InputText
              id="newDocumentName"
              className="w-full"
              value={newDocumentName}
              onChange={(e) => {
                setNewDocumentName(e.target.value);
              }}
            />
          </div>
          <div className="flex gap-3">
            <Button
              label="Create"
              disabled={!newDocumentName || isCreating}
              onClick={() => {
                void createDocument({
                  variables: {
                    platformId,
                    name: newDocumentName,
                  },
                });
              }}
            />
            <Button
              label="Cancel"
              className="p-button-outlined"
              onClick={() => {
                setShowCreateDocument(false);
                setNewDocumentName('');
              }}
            />
          </div>
        </div>
      ) : (
        <Button
          label="Create document"
          className="mb-3"
          onClick={() => {
            setShowCreateDocument(true);
          }}
        />
      )}
      {loading ? (
        <Spinner />
      ) : documents?.length ? (
        documents.map((doc, i) => (
          <div
            key={doc.id}
            className={clsx(
              'flex justify-content-between align-items-center cursor-pointer border-bottom-1 p-3',
              i === 0 && 'border-top-1',
            )}
            onClick={() => {
              navigate(`/content/documents/${doc.id}`);
            }}
          >
            <div className="flex flex-row align-items-center gap-2">
              <span>{doc.name}</span>
              {doc.status !== PublishStatus.Published && (
                <span className="text-sm text-color-muted font-medium">{doc.status}</span>
              )}
            </div>
            <BasicIconButton
              icon={PrimeIcons.EXTERNAL_LINK}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async (e) => {
                e.stopPropagation();
                const { currentVersion } = doc;
                if (currentVersion) {
                  const url = await getUrl(currentVersion.id, false);
                  window.open(url, '_blank');
                }
              }}
              disabled={!doc.currentVersion}
            />
          </div>
        ))
      ) : (
        <p>No documents yet.</p>
      )}
    </PageContainer>
  );
};
