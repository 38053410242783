import {
  expressionOperatorToHumanReadable,
  ExpressionType,
  IncompleteExpression,
  isOperatorWithOperands,
  newExpressionForOperator,
} from '@wirechunk/lib/expression-builder/evaluator.js';
import { Expression } from '@wirechunk/schemas/expressions/expression';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import styles from './ExpressionBuilder.module.css';
import { expressionBuilderByOperator } from './expressionBuilderByOperator.js';
import { SelectExpressionButton } from './SelectExpressionButton.js';

type ExpressionBuilderProps = {
  id: string;
  expression: IncompleteExpression<Expression> | null;
  setExpression: (expression: IncompleteExpression<Expression>) => void;
  validationMessages: string[];
  // The types of expressions to allow to be built.
  types: ExpressionType | ExpressionType[];
  // If onDelete is defined, a delete button is shown.
  onDelete?: () => void;
  className?: string;
};

export const ExpressionBuilder: FunctionComponent<ExpressionBuilderProps> = ({
  id,
  expression,
  setExpression,
  validationMessages,
  types,
  className,
  onDelete,
}) => {
  const [isEditingOperator, setIsEditingOperator] = useState(false);

  return (
    <div
      className={clsx('border-1 border-round overflow-hidden', styles.expressionBuilder, className)}
    >
      <div className="p-3 surface-ground">
        {expression && !isEditingOperator ? (
          <div className="flex gap-1 align-items-center">
            <div className="font-medium uppercase mr-1">
              {expressionOperatorToHumanReadable(expression.operator)}
            </div>
            <button
              type="button"
              className={`${styles.headerActionButton} button-not-styled`}
              onClick={() => {
                setIsEditingOperator(true);
              }}
            >
              <i className={`${PrimeIcons.PENCIL} text-sm`} />
            </button>
            {onDelete && (
              <button
                type="button"
                className={`${styles.headerActionButton} button-not-styled`}
                onClick={onDelete}
              >
                <i className={`${PrimeIcons.TRASH} text-sm`} />
              </button>
            )}
          </div>
        ) : (
          <SelectExpressionButton
            autoFocus
            types={types}
            value={expression?.operator}
            onAdd={(operator) => {
              setExpression(newExpressionForOperator(operator));
              setIsEditingOperator(false);
            }}
          />
        )}
      </div>
      {(validationMessages.length > 0 ||
        (expression && isOperatorWithOperands(expression.operator))) && (
        <div className="p-3 flex flex-column gap-2">
          {validationMessages.length > 0 && (
            <div className="flex flex-column gap-1">
              {validationMessages.map((message, i) => (
                <div key={i} className="text-color-danger text-sm">
                  {message}
                </div>
              ))}
            </div>
          )}
          {expression && expressionBuilderByOperator(id, expression, setExpression)}
        </div>
      )}
    </div>
  );
};
