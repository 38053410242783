import { useQuery } from '@apollo/client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { FormNotifications } from '../../form-notifications/form-notifications.js';
import { NoneLabel } from '../../NoneLabel/NoneLabel.js';
import { withOrganizationSite } from '../../with-organization-site/with-organization-site.js';
import { FormsDocument, FormsQuery } from './queries.generated.js';

type FormsTableRow = FormsQuery['forms'][number];

export const OrganizationSiteForms: FunctionComponent<
  ComponentSpec<ComponentType.OrganizationSiteForms>
> = withOrganizationSite(({ site, ...props }) => {
  const { onError, ErrorMessage } = useErrorHandler();
  const { data: formsData, loading: isLoading } = useQuery(FormsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      siteId: site.id,
    },
  });
  const dialog = useDialog();

  const data = useMemo<FormsTableRow[]>(() => formsData?.forms || [], [formsData]);

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      <DataTable
        dataKey="id"
        value={data}
        loading={isLoading}
        emptyMessage="No forms yet. Activate a page template to create a form."
        lazy
      >
        <Column
          header="Title"
          field="title"
          body={(row: FormsTableRow) => (
            <span className="flex flex-row align-items-center">{row.title}</span>
          )}
        />
        <Column
          header="Leads"
          field="entries"
          body={(row: FormsTableRow) =>
            row.entriesTotalCount ? (
              <Link to={`/marketing/leads?formId=${row.id}`}>
                {row.entriesTotalCount} {pluralize(row.entriesTotalCount, 'lead')}
              </Link>
            ) : (
              <NoneLabel />
            )
          }
        />
        <Column
          header=""
          align="right"
          body={(row: FormsTableRow) => (
            <Button
              label="Edit notifications"
              className="p-button-sm"
              onClick={() => {
                dialog({
                  content: <FormNotifications form={row} />,
                  props: {
                    header: `Edit notifications for ${row.title}`,
                    className: 'dialog-width-lg',
                  },
                });
              }}
            />
          )}
        />
      </DataTable>
    </div>
  );
});
