import { IncompleteExpression } from '@wirechunk/lib/expression-builder/evaluator.js';
import type { Expression } from '@wirechunk/schemas/expressions/expression';
import type { ReactNode } from 'react';
import { All } from './expressions/All.js';
import { ArrayIncludes } from './expressions/ArrayIncludes.js';
import { ArrayLiteral } from './expressions/ArrayLiteral.js';
import { BooleanLiteral } from './expressions/BooleanLiteral.js';
import { BooleanValue } from './expressions/BooleanValue.js';
import { Both } from './expressions/Both.js';
import { Count } from './expressions/Count.js';
import { Either } from './expressions/Either.js';
import { Equals } from './expressions/Equals.js';
import { Exists } from './expressions/Exists.js';
import { FeatureTag } from './expressions/FeatureTag.js';
import { Filter } from './expressions/Filter.js';
import { GreaterThan } from './expressions/GreaterThan.js';
import { InputDataArray } from './expressions/input-data-array.js';
import { InputDataBoolean } from './expressions/input-data-boolean.js';
import { InputDataNumber } from './expressions/input-data-number.js';
import { InputDataString } from './expressions/input-data-string.js';
import { Map } from './expressions/Map.js';
import { Not } from './expressions/Not.js';
import { NumberLiteral } from './expressions/NumberLiteral.js';
import { PropsField } from './expressions/props-field.js';
import { Some } from './expressions/Some.js';
import { StringLiteral } from './expressions/StringLiteral.js';
import { Sum } from './expressions/Sum.js';
import { SumArray } from './expressions/SumArray.js';
import { TimeLiteral } from './expressions/time-literal.js';

export const expressionBuilderByOperator = (
  parentId: string,
  expression: IncompleteExpression<Expression>,
  setExpression: (expression: IncompleteExpression<Expression>) => void,
): ReactNode => {
  const id = `${parentId}-${expression.operator}`;
  switch (expression.operator) {
    case 'featureTag':
      return <FeatureTag id={id} expression={expression} setExpression={setExpression} />;
    case 'all':
      return <All id={id} expression={expression} setExpression={setExpression} />;
    case 'arrayIncludes':
      return <ArrayIncludes id={id} expression={expression} setExpression={setExpression} />;
    case 'arrayLiteral':
      return <ArrayLiteral id={id} expression={expression} setExpression={setExpression} />;
    case 'greaterThan':
      return <GreaterThan id={id} expression={expression} setExpression={setExpression} />;
    case 'booleanLiteral':
      return <BooleanLiteral id={id} expression={expression} setExpression={setExpression} />;
    case 'booleanValue':
      return <BooleanValue id={id} expression={expression} setExpression={setExpression} />;
    case 'both':
      return <Both id={id} expression={expression} setExpression={setExpression} />;
    case 'count':
      return <Count id={id} expression={expression} setExpression={setExpression} />;
    case 'currentTime':
      return null;
    case 'either':
      return <Either id={id} expression={expression} setExpression={setExpression} />;
    case 'eventSourceComponentName':
    case 'eventSourceComponentType':
      return null;
    case 'equals':
      return <Equals id={id} expression={expression} setExpression={setExpression} />;
    case 'exists':
      return <Exists id={id} expression={expression} setExpression={setExpression} />;
    case 'filter':
      return <Filter id={id} expression={expression} setExpression={setExpression} />;
    case 'inputChangeEventArrayValue':
    case 'inputChangeEventBooleanValue':
    case 'inputChangeEventNumberValue':
    case 'inputChangeEventStringValue':
      return null;
    case 'inputDataArray':
      return <InputDataArray id={id} expression={expression} setExpression={setExpression} />;
    case 'inputDataBoolean':
      return <InputDataBoolean id={id} expression={expression} setExpression={setExpression} />;
    case 'inputDataNumber':
      return <InputDataNumber id={id} expression={expression} setExpression={setExpression} />;
    case 'inputDataString':
      return <InputDataString id={id} expression={expression} setExpression={setExpression} />;
    case 'map':
      return <Map id={id} expression={expression} setExpression={setExpression} />;
    case 'not':
      return <Not id={id} expression={expression} setExpression={setExpression} />;
    case 'numberLiteral':
      return <NumberLiteral id={id} expression={expression} setExpression={setExpression} />;
    case 'propsArray':
      return (
        <PropsField
          id={id}
          expression={expression}
          setExpression={setExpression}
          label="Array prop name"
        />
      );
    case 'propsBoolean':
      return (
        <PropsField
          id={id}
          expression={expression}
          setExpression={setExpression}
          label="Boolean prop name"
        />
      );
    case 'propsNumber':
      return (
        <PropsField
          id={id}
          expression={expression}
          setExpression={setExpression}
          label="Number prop name"
        />
      );
    case 'propsString':
      return (
        <PropsField
          id={id}
          expression={expression}
          setExpression={setExpression}
          label="String prop name"
        />
      );
    case 'some':
      return <Some id={id} expression={expression} setExpression={setExpression} />;
    case 'stringLiteral':
      return <StringLiteral id={id} expression={expression} setExpression={setExpression} />;
    case 'sum':
      return <Sum id={id} expression={expression} setExpression={setExpression} />;
    case 'sumArray':
      return <SumArray id={id} expression={expression} setExpression={setExpression} />;
    case 'timeLiteral':
      return <TimeLiteral id={id} expression={expression} setExpression={setExpression} />;
    case 'userEmail':
    case 'userFeatureTags':
    case 'userRole':
      return null;
  }
};
