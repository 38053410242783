import { useMutation, useQuery } from '@apollo/client';
import { pluralize } from '@wirechunk/lib/pluralize.js';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fragment, FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePlatformContext } from '../../../../../contexts/admin/platform-context/platform-context.js';
import { useDialog } from '../../../../../contexts/DialogContext/DialogContext.js';
import { useToast } from '../../../../../contexts/ToastContext.js';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler.js';
import { roleToHumanReadable } from '../../../../../util/roles.js';
import { OrganizationCell } from '../../Users/OrganizationCell.js';
import { ActivateUserDocument } from '../mutations.generated.js';
import { PendingUsersDocument, PendingUsersQuery } from '../queries.generated.js';

type UserRow = PendingUsersQuery['users']['users'][number];

export const PendingUsers: FunctionComponent = () => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { id: platformId, handle } = usePlatformContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const {
    data,
    loading: isLoadingUsers,
    refetch: refetchPendingUsers,
  } = useQuery(PendingUsersDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { platformId },
  });

  const [activateUser] = useMutation(ActivateUserDocument, {
    onError,
  });

  const [editingUserId, setEditingUserId] = useState<string | null>(null);

  const pendingUsersCount = data?.users.totalCount || 0;

  const onActivateUser = (user: UserRow) => {
    dialog({
      confirm: `Are you sure you want to activate ${user.displayName}’s account?`,
      props: {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAccept: async () => {
          setEditingUserId(user.id);
          try {
            await activateUser({
              variables: { userId: user.id },
            });
            void refetchPendingUsers();
            toastSuccess('User activated.');
          } finally {
            setEditingUserId(null);
          }
        },
      },
    });
  };

  return (
    <Fragment>
      <ErrorMessage />
      {pendingUsersCount > 0 && (
        <div className="mt-4 p-3 border-1 border-round-lg">
          <div className="font-medium text-lg mb-3">
            There {pluralize(pendingUsersCount, 'is', 'are')} {pendingUsersCount} pending{' '}
            {pluralize(pendingUsersCount, 'user')}.
            {pendingUsersCount > 10 && (
              <Link to={`/dashboard/${handle}/users`} className="ml-2">
                <span className="pr-1">See all</span>
                <i className={PrimeIcons.ANGLE_RIGHT} />
              </Link>
            )}
          </div>
          <DataTable dataKey="id" value={data?.users.users || []} loading={isLoadingUsers}>
            <Column
              header="First name"
              body={(row: UserRow) => (
                <span className={row.id === editingUserId ? 'text-color-muted' : undefined}>
                  {row.firstName}
                </span>
              )}
            />
            <Column
              header="Last name"
              body={(row: UserRow) => (
                <span className={row.id === editingUserId ? 'text-color-muted' : undefined}>
                  {row.lastName}
                </span>
              )}
            />
            <Column
              header="Email"
              body={(row: UserRow) => (
                <span
                  className={clsx(
                    'text-overflow-ellipsis overflow-x-hidden cursor-pointer',
                    row.id === editingUserId && 'text-color-muted',
                  )}
                  onClick={() => {
                    // Copy the email address to the clipboard.
                    void navigator.clipboard.writeText(row.email);
                    toastSuccess('Copied email address to clipboard.');
                  }}
                >
                  {row.email}
                </span>
              )}
            />
            <Column
              header="Role"
              body={(row: UserRow) => (
                <span className={row.id === editingUserId ? 'text-color-muted' : undefined}>
                  {roleToHumanReadable(row.role)}
                </span>
              )}
            />
            <Column
              header="Organization"
              body={(row: UserRow) => <OrganizationCell org={row.organization} />}
              style={{ width: '17rem' }}
            />
            <Column
              align="right"
              body={(row: UserRow) => (
                <Button
                  className="p-button-sm p-button-success"
                  label="Activate"
                  onClick={() => {
                    onActivateUser(row);
                  }}
                />
              )}
            />
          </DataTable>
        </div>
      )}
    </Fragment>
  );
};
