import { FetchResult, useMutation } from '@apollo/client';
import { useState } from 'react';
import type { ErrorHandler } from '../useErrorHandler.js';
import {
  SendVerificationEmailDocument,
  SendVerificationEmailMutation,
} from './mutations.generated.js';

type SendVerificationEmail = {
  sendVerificationEmail: () => Promise<FetchResult<SendVerificationEmailMutation>>;
  isSending: boolean;
  verificationEmailSent: boolean;
};

export const useSendVerificationEmail = (
  onError: ErrorHandler['onError'],
  onSent?: () => void,
): SendVerificationEmail => {
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [sendVerificationEmail, { loading }] = useMutation(SendVerificationEmailDocument, {
    onError,
    onCompleted: (data) => {
      if (data.sendVerificationEmail) {
        setVerificationEmailSent(true);
        onSent?.();
      }
    },
  });

  return {
    sendVerificationEmail,
    isSending: loading,
    verificationEmailSent,
  };
};
