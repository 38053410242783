import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fragment, FunctionComponent, useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { InputNotice } from '../../InputNotice/InputNotice.js';
import { DeleteSubscriptionOrganizationDocument } from './mutations.generated.js';

type DeleteSubscriptionOrganizationProps = {
  id: string;
  planName: string;
  organization: {
    id: string;
    name?: string | null;
    primaryUser?: {
      displayName: string;
    } | null;
  };
  onDeleted: () => void;
};

export const DeleteSubscriptionOrganization: FunctionComponent<
  DeleteSubscriptionOrganizationProps
> = ({ id, planName, organization, onDeleted }) => {
  const dialog = useDialog();
  const { onError, ErrorMessage } = useErrorHandler();
  const [notes, setNotes] = useState('');
  const [deleteSubscriptionOrganization, { loading: isDeletingSubscriptionOrganization }] =
    useMutation(DeleteSubscriptionOrganizationDocument, { onError });

  return (
    <Fragment>
      <ErrorMessage />
      <p>
        You are about to remove{' '}
        {organization.primaryUser ? (
          <Fragment>
            <span className="font-medium">{organization.primaryUser.displayName}</span>’s
          </Fragment>
        ) : organization.name ? (
          <Fragment>
            the “<span className="font-medium">{organization.name}</span>”
          </Fragment>
        ) : (
          'this'
        )}{' '}
        organization from this “<span className="font-medium">{planName}</span>” subscription.
      </p>
      <div className="input-field">
        <label htmlFor="deletingSubOrgNotes">Notes</label>
        <InputTextarea
          id="deletingSubOrgNotes"
          className="block w-full max-w-full"
          rows={5}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
        <InputNotice>Explain the context of this change.</InputNotice>
      </div>
      <div className="flex justify-content-end gap-3 mt-4">
        <Button
          label="Cancel"
          className="p-button-secondary"
          onClick={() => {
            dialog(null);
          }}
        />
        <Button
          label="Continue"
          className="p-button-danger"
          disabled={isDeletingSubscriptionOrganization || !notes.trim()}
          onClick={() => {
            dialog({
              confirm: 'Are you sure you want to remove this subscription?',
              props: {
                onAccept: () => {
                  void deleteSubscriptionOrganization({
                    variables: {
                      id,
                      notes,
                    },
                    onCompleted: () => {
                      onDeleted();
                      dialog(null);
                    },
                  });
                },
              },
            });
          }}
        />
      </div>
    </Fragment>
  );
};
