import {
  BoxComponent,
  ThreeColumnWidths,
  TwoColumnWidths,
} from '@wirechunk/lib/mixer/types/components.js';
import { Dropdown } from 'primereact/dropdown';
import { Fragment, FunctionComponent } from 'react';
import { isTag, tagOptions } from '../tag-options.js';
import type { EditComponentContentProps } from './shared/types.js';

const columnWidthsHumanReadable = (w: TwoColumnWidths | ThreeColumnWidths): string => {
  switch (w) {
    case TwoColumnWidths.OneHalfOneHalf:
      return '1/2 1/2';
    case TwoColumnWidths.OneQuarterThreeQuarters:
      return '1/4 3/4';
    case TwoColumnWidths.OneThirdTwoThirds:
      return '1/3 2/3';
    case TwoColumnWidths.ThreeQuartersOneQuarter:
      return '3/4 1/4';
    case TwoColumnWidths.TwoThirdsOneThird:
      return '2/3 1/3';
    case TwoColumnWidths.FullFull:
      return '100% 100%';
    case ThreeColumnWidths.OneHalfOneQuarterOneQuarter:
      return '1/2 1/4 1/4';
    case ThreeColumnWidths.OneQuarterOneHalfOneQuarter:
      return '1/4 1/2 1/4';
    case ThreeColumnWidths.OneQuarterOneQuarterOneHalf:
      return '1/4 1/4 1/2';
    case ThreeColumnWidths.OneThirdOneThirdOneThird:
      return '1/3 1/3 1/3';
    case ThreeColumnWidths.FullFullFull:
      return '100% 100% 100%';
  }
};

const columnWidthsOptions = [
  ...Object.values(TwoColumnWidths),
  ...Object.values(ThreeColumnWidths),
].map((w) => ({
  label: columnWidthsHumanReadable(w),
  value: w,
}));

export const EditBox: FunctionComponent<EditComponentContentProps<BoxComponent>> = ({
  component,
  setComponent,
}) => {
  return (
    <Fragment>
      <div className="input-field">
        <label htmlFor="editBoxTag">HTML element (tag)</label>
        <Dropdown
          inputId="editBoxTag"
          value={component.tag || ''}
          options={tagOptions}
          placeholder="div"
          onChange={({ value }) => {
            if (isTag(value)) {
              setComponent((c): BoxComponent => ({ ...c, tag: value }));
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor="editBoxColumnWidths">Default column widths</label>
        <Dropdown
          inputId="editBoxColumnWidths"
          className="w-full"
          value={component.columnWidths || null}
          options={columnWidthsOptions}
          onChange={(e) => {
            setComponent((c) => ({ ...c, columnWidths: e.value as BoxComponent['columnWidths'] }));
          }}
          showClear
        />
      </div>
      <div className="input-field">
        <label htmlFor="editBoxSmColumnWidths">Small screen column widths</label>
        <Dropdown
          inputId="editBoxSmColumnWidths"
          className="w-full"
          value={component.smColumnWidths || null}
          options={columnWidthsOptions}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              smColumnWidths: e.value as BoxComponent['smColumnWidths'],
            }));
          }}
          showClear
        />
      </div>
      <div className="input-field">
        <label htmlFor="editBoxMdColumnWidths">Medium screen column widths</label>
        <Dropdown
          inputId="editBoxMdColumnWidths"
          className="w-full"
          value={component.mdColumnWidths || null}
          options={columnWidthsOptions}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              mdColumnWidths: e.value as BoxComponent['mdColumnWidths'],
            }));
          }}
          showClear
        />
      </div>
      <div className="input-field">
        <label htmlFor="editBoxLgColumnWidths">Large screen column widths</label>
        <Dropdown
          inputId="editBoxLgColumnWidths"
          className="w-full"
          value={component.lgColumnWidths || null}
          options={columnWidthsOptions}
          onChange={(e) => {
            setComponent((c) => ({
              ...c,
              lgColumnWidths: e.value as BoxComponent['lgColumnWidths'],
            }));
          }}
          showClear
        />
      </div>
    </Fragment>
  );
};
