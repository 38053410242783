import { customAlphabet } from 'nanoid';

// Don't have uppercase letters because data attributes are case-insensitive.
const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';

// Returns a clean unique string that's 25 characters long. Clean means only numbers and lowercase letters.
export const cleanSmallId = customAlphabet(alphabet, 25);

// Like alphabet but without 0, 1, and l.
const tinyAlphabet = '23456789abcdefghijkmnopqrstuvwxyz';

const startWithLowercaseLetterPattern = /^[a-z]/;

const startWithLowercaseLetter = (s: string): boolean => startWithLowercaseLetterPattern.test(s);

// This excludes l.
const cleanLowercaseLettersAlphabet = 'abcdefghijkmnopqrstuvwxyz';

// This function does not need to be secure, just at least somewhat random. It's meant only for the cleanTinyId
// function, which is guaranteed to be random for 6 characters.
const randomLetter = () =>
  cleanLowercaseLettersAlphabet[
    Math.floor(Math.random() * cleanLowercaseLettersAlphabet.length)
  ] as string;

/**
 * Returns a clean random string that's 6-7 characters long and includes only unambiguous numbers and lowercase letters.
 * The first character is always a letter (so this can be used in contexts where the first character must be a letter).
 */
export const cleanTinyId = (): string => {
  const id = customAlphabet(tinyAlphabet, 6)();
  if (startWithLowercaseLetter(id)) {
    return id;
  }
  return `${randomLetter()}${id}`;
};
