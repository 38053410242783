import { Component } from '@wirechunk/lib/mixer/types/components.js';
import { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Fragment, FunctionComponent, MutableRefObject, useEffect, useRef } from 'react';
import {
  CurrentUserProvider,
  useCurrentUser,
} from '../../contexts/CurrentUserContext/CurrentUserContext.js';
import { useDialog } from '../../contexts/DialogContext/DialogContext.js';
import { SiteContext, SiteContextProvider } from '../../contexts/SiteContext/SiteContext.js';
import { useToast } from '../../contexts/ToastContext.js';
import {
  PropType,
  usePropSpecs,
} from '../admin/pages/Sites/PageTemplates/PageTemplate/use-prop-specs.js';
import { EditProps } from '../edit-props.js';

const propTypeIconClassName = 'prop-type-icon';
const propTypeIconFullClassName = `material-symbols-outlined text-3xl ${propTypeIconClassName}`;

export type PropSpecsOverviewProps = {
  propsSetupComponentsRef: MutableRefObject<Component[]>;
  props: ContextData | null;
  setProps: (props: ContextData) => void;
  siteContext: SiteContext;
  className?: string;
  // A human-readable name for the type of container or entity that takes these props.
  // For example, 'page template' or 'component'.
  containerType: string;
};

export const PropSpecsOverview: FunctionComponent<PropSpecsOverviewProps> = ({
  propsSetupComponentsRef,
  props,
  setProps,
  siteContext,
  className,
  containerType,
}) => {
  const dialog = useDialog();
  const { toastSuccess } = useToast();
  const { user } = useCurrentUser();
  const propTypeIconTooltip = useRef<Tooltip>(null);
  const propSpecs = usePropSpecs(propsSetupComponentsRef);
  useEffect(() => {
    if (propTypeIconTooltip.current) {
      propTypeIconTooltip.current.updateTargetEvents(undefined);
    }
  }, [propSpecs]);

  return (
    <Fragment>
      <div className={className}>
        <div className="flex justify-content-between align-items-center py-2 px-3 surface-ground">
          <h3 className="my-0 text-lg">Props</h3>
          <Button
            className="p-button-sm"
            label="Provide preview data"
            disabled={!propSpecs.length}
            onClick={() => {
              dialog({
                content: (
                  <SiteContextProvider value={siteContext}>
                    <CurrentUserProvider user={user} loadingUser={false}>
                      <p>
                        Define the input values to use when someone previews this {containerType}.
                      </p>
                      <EditProps
                        setupComponents={propsSetupComponentsRef.current}
                        existingProps={props}
                        submitButtonLabel="Set values"
                        onSubmit={(data) => {
                          setProps(data);
                          dialog(null);
                        }}
                      />
                    </CurrentUserProvider>
                  </SiteContextProvider>
                ),
                props: {
                  header: 'Prop values for template preview',
                  className: 'dialog-width-lg',
                },
              });
            }}
          />
        </div>
        <div className="border-top-1 px-3 py-2 flex flex-wrap gap-3 align-items-center">
          {propSpecs.map(({ name, type }) => (
            <div
              key={name}
              className="surface-ground border-round inline-flex px-3 py-1 gap-2 align-items-center"
            >
              {type === PropType.Boolean ? (
                <span className={propTypeIconFullClassName} data-pr-tooltip="Boolean (true/false)">
                  toggle_on
                </span>
              ) : type === PropType.Float ? (
                <span className={propTypeIconFullClassName} data-pr-tooltip="Number">
                  123
                </span>
              ) : type === PropType.String ? (
                <span className={propTypeIconFullClassName} data-pr-tooltip="String (any text)">
                  text_fields
                </span>
              ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              type === PropType.Url ? (
                <span className={propTypeIconFullClassName} data-pr-tooltip="URL">
                  link
                </span>
              ) : (
                <span
                  className={propTypeIconFullClassName}
                  data-pr-tooltip="Unrecognized prop type"
                >
                  question_mark
                </span>
              )}
              <div className="flex-grow-1">
                <span
                  className="font-bold cursor-pointer text-sm"
                  onClick={() => {
                    void window.navigator.clipboard.writeText(name);
                    toastSuccess(`Copied prop name to clipboard: ${name}`);
                  }}
                >
                  {name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Tooltip ref={propTypeIconTooltip} target={`.${propTypeIconClassName}`} />
    </Fragment>
  );
};
