import { SiteOptionKey } from '@wirechunk/lib/site-options.js';
import { intersection } from 'lodash-es';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useEffect, useState } from 'react';
import { OptionsSection } from '../../../../../OptionsSection.js';
import type { SiteSettingsSectionProps } from './types.js';

export const BrandingSection: FunctionComponent<SiteSettingsSectionProps> = ({
  site,
  savingKeys,
  saveOptions,
}) => {
  const [logoUrl, setLogoUrl] = useState(site.logoUrl || null);
  const [logoIconUrl, setLogoIconUrl] = useState(site.logoIconUrl || null);

  useEffect(() => {
    setLogoUrl(site.logoUrl || null);
    setLogoIconUrl(site.logoIconUrl || null);
  }, [site.logoUrl, site.logoIconUrl]);

  return (
    <OptionsSection
      title="Branding"
      allowSave={
        intersection(savingKeys, [SiteOptionKey.LogoIconUrl, SiteOptionKey.LogoUrl]).length === 0 &&
        (logoUrl !== site.logoUrl || logoIconUrl !== site.logoIconUrl)
      }
      getOptions={() => [
        { key: SiteOptionKey.LogoIconUrl, value: logoIconUrl },
        { key: SiteOptionKey.LogoUrl, value: logoUrl },
      ]}
      saveOptions={saveOptions}
    >
      <div className="input-field">
        <label htmlFor="siteLogoUrl">Logo URL</label>
        <InputText
          id="siteLogoUrl"
          className="w-25rem max-w-full"
          value={logoUrl || ''}
          onChange={(e) => {
            setLogoUrl(e.target.value);
          }}
        />
      </div>
      <div className="input-field mb-0">
        <label htmlFor="siteLogoIconUrl">Logo icon URL</label>
        <InputText
          id="siteLogoIconUrl"
          className="w-25rem max-w-full"
          value={logoIconUrl || ''}
          onChange={(e) => {
            setLogoIconUrl(e.target.value);
          }}
        />
      </div>
    </OptionsSection>
  );
};
