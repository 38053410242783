import { useCallback, useEffect, useState } from 'react';
import { useOptionalCurrentUser } from '../contexts/CurrentUserContext/CurrentUserContext.js';
import { useReadonlyInputDataContext } from '../contexts/InputDataContext.js';
import { usePropsContext } from '../contexts/props-context.js';
import { buildContextData, MixerContextData } from '../util/mixer/context-data.js';
import { useInterval } from './useInterval.js';

export const useMixerContextData = (): MixerContextData => {
  const { user } = useOptionalCurrentUser();
  const { data: inputData } = useReadonlyInputDataContext();
  const propsContext = usePropsContext();
  const [contextData, setContextData] = useState<MixerContextData>(() =>
    buildContextData({
      user,
      inputData: inputData.visible,
      props: propsContext,
    }),
  );
  const refreshContextData = useCallback(() => {
    setContextData(
      buildContextData({
        user,
        inputData: inputData.visible,
        props: propsContext,
      }),
    );
  }, [user, inputData.visible, propsContext]);
  useInterval(refreshContextData, 30_000);
  useEffect(() => {
    setContextData(
      buildContextData({
        user,
        inputData: inputData.visible,
        props: propsContext,
      }),
    );
  }, [user, inputData.visible, propsContext]);

  return contextData;
};
