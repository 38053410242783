import { useMutation } from '@apollo/client';
import { SubscriptionStatus } from '@wirechunk/lib/api.js';
import { roundToDayStart } from '@wirechunk/lib/dates.js';
import { clsx } from 'clsx';
import { isDate } from 'lodash-es';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FunctionComponent, useMemo, useState } from 'react';
import { usePlatformContext } from '../../../contexts/admin/platform-context/platform-context.js';
import { useSubscriptionPlanNames } from '../../../hooks/use-subscription-plan-names/use-subscription-plan-names.js';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.js';
import type { SelectItem } from '../../../types.js';
import { subscriptionStatusOptions } from '../../../util/subscriptions.js';
import { ProductDetails } from '../../admin/pages/SubscriptionPlans/product-details/product-details.js';
import { InputNotice } from '../../InputNotice/InputNotice.js';
import type { OrganizationQuery } from '../queries.generated.js';
import { OrganizationDocument } from '../queries.generated.js';
import { CreateSubscriptionDocument } from './mutations.generated.js';

type AddSubscriptionPlanProps = {
  className?: string;
  organization: OrganizationQuery['organization'];
  onError: ErrorHandler['onError'];
  onAdded: () => void;
  onCancel: () => void;
};

export const CreateSubscription: FunctionComponent<AddSubscriptionPlanProps> = ({
  className,
  onError,
  organization,
  onAdded,
  onCancel,
}) => {
  const { id: platformId } = usePlatformContext();
  const { subscriptionPlans } = useSubscriptionPlanNames(platformId, onError);
  const [addPlan, { loading: isAddingPlan }] = useMutation(CreateSubscriptionDocument, {
    onError,
  });
  const [planId, setPlanId] = useState<string | null>(null);
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');
  const [startAt, setStartAt] = useState<Date>(roundToDayStart(new Date()));
  const [status, setStatus] = useState<SubscriptionStatus | null>(null);
  const [notes, setNotes] = useState('');

  const onAdd = () => {
    if (!planId || !status) {
      return;
    }
    void addPlan({
      variables: {
        organizationId: organization.id,
        subscriptionPlanId: planId,
        stripeSubscriptionId: stripeSubscriptionId || null,
        startAt,
        status,
        notes,
      },
      onCompleted: onAdded,
      refetchQueries: [{ query: OrganizationDocument, variables: { id: organization.id } }],
    });
  };

  const selectedPlan = subscriptionPlans?.products.find((plan) => plan.id === planId);

  const planOptions = useMemo<Array<SelectItem<string>>>(
    () =>
      subscriptionPlans?.products.map((plan) => ({
        label: plan.name,
        value: plan.id,
      })) || [],
    [subscriptionPlans],
  );

  return (
    <div className={clsx('border-1 border-round p-3', className)}>
      <h3 className="mt-1 text-lg">Add subscription</h3>
      <div className="input-field">
        <label htmlFor={`addingPlanId-${organization.id}`}>Plan</label>
        <Dropdown
          inputId={`addingPlanId-${organization.id}`}
          className="w-20rem"
          options={planOptions}
          value={planId}
          onChange={(e) => {
            setPlanId(e.value as string);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`addingPlanStripeSubscriptionId-${organization.id}`}>
          Stripe subscription ID
        </label>
        <InputText
          id={`addingPlanStripeSubscriptionId-${organization.id}`}
          className="w-20rem"
          value={stripeSubscriptionId}
          onChange={(e) => {
            setStripeSubscriptionId(e.target.value);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`addingPlanStartAt-${organization.id}`}>Start date</label>
        <Calendar
          inputId={`addingPlanStartAt-${organization.id}`}
          value={startAt}
          className="w-9rem"
          onChange={(e) => {
            if (isDate(e.value)) {
              setStartAt(roundToDayStart(e.value));
            }
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`addingPlanStatus-${organization.id}`}>Subscription status</label>
        <Dropdown
          inputId={`addingPlanStatus-${organization.id}`}
          className="w-20rem"
          options={subscriptionStatusOptions}
          value={status}
          onChange={(e) => {
            setStatus(e.value as SubscriptionStatus);
          }}
        />
      </div>
      <div className="input-field">
        <label htmlFor={`addingPlanNotes-${organization.id}`}>Notes</label>
        <InputTextarea
          id={`addingPlanNotes-${organization.id}`}
          className="block w-full max-w-full"
          rows={5}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
        <InputNotice>Explain the context of this change in detail.</InputNotice>
      </div>
      {selectedPlan && (
        <div className="surface-ground border-round p-4 mb-3">
          <h4 className="mt-0 mb-2">Selected plan</h4>
          <ProductDetails subscriptionPlan={selectedPlan} enableEdit={false} />
        </div>
      )}
      <div className="flex gap-3">
        <Button
          label="Add"
          disabled={!planId || !status || isAddingPlan || !notes}
          onClick={onAdd}
        />
        <Button
          className="p-button-outlined"
          label="Cancel"
          disabled={isAddingPlan}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
