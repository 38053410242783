import { Component, MixerEventType } from '@wirechunk/lib/mixer/types/components.js';
import type { DataValue } from '@wirechunk/schemas/context-data/context-data';

export type MixerQualifiedEventType<T extends MixerEventType> = `mixer:${T}`;

export const mixerQualifiedEventType = <T extends MixerEventType>(
  type: T,
): MixerQualifiedEventType<T> => `mixer:${type}`;

type MixerEventDetailSpec<T extends MixerEventType> = {
  type: T;
  // The direct Mixer component in which the event originated.
  component: Component;
  // A value associated with the event, depends on the event and component type. For InputChange events, this
  // is the new value of the input component.
  value?: DataValue;
};

export type MixerEvent = CustomEvent<MixerEventDetailSpec<MixerEventType>>;

export type MixerEventDetailForType<T extends MixerEventType> = T extends MixerEventType.Click
  ? MixerEventDetailSpec<MixerEventType.Click>
  : T extends MixerEventType.InputChange
    ? MixerEventDetailSpec<MixerEventType.InputChange>
    : never;

export const isMixerClickEvent = (
  event: Event,
): event is CustomEvent<MixerEventDetailSpec<MixerEventType.Click>> =>
  event.type === `mixer:${MixerEventType.Click}`;

export const isMixerInputChangeEvent = (
  event: Event,
): event is CustomEvent<MixerEventDetailSpec<MixerEventType.InputChange>> =>
  event.type === `mixer:${MixerEventType.InputChange}`;

export const isMixerEvent = (event: Event): event is MixerEvent =>
  isMixerClickEvent(event) || isMixerInputChangeEvent(event);

export const isMixerEventForType = <T extends MixerEventType>(
  event: Event,
  type: T,
): event is CustomEvent<MixerEventDetailForType<T>> => event.type === mixerQualifiedEventType(type);

const mixerEventTypes = Object.values(MixerEventType);

export const isMixerEventType = (type: unknown): type is MixerEventType =>
  mixerEventTypes.includes(type as never);
