import { isNumber, isString } from 'lodash-es';
import { Fragment, FunctionComponent, useMemo } from 'react';
import type { ParsedFormEntry } from '../../hooks/use-parsed-form-entries/use-parsed-form-entries.js';
import { NoneLabel } from '../NoneLabel/NoneLabel.js';

const ValueDisplay: FunctionComponent<{ value: unknown }> = ({ value }) =>
  (isString(value) && value) || isNumber(value) ? (
    <Fragment>{value}</Fragment>
  ) : value === null || value === '' ? (
    <NoneLabel />
  ) : (
    <pre>{JSON.stringify(value, null, 2)}</pre>
  );

const KeyValueDisplay: FunctionComponent<{ fieldKey: string; value: unknown }> = ({
  fieldKey,
  value,
}) => (
  <div className="flex flex-row align-items-center gap-2">
    <div className="font-medium">{fieldKey}:</div>
    <div>
      <ValueDisplay value={value} />
    </div>
  </div>
);

type DisplayFormEntryProps = {
  entry: ParsedFormEntry;
};

export const DisplayFormEntry: FunctionComponent<DisplayFormEntryProps> = ({ entry }) => {
  const dataEntries = useMemo(() => Object.entries(entry.parsedFormData), [entry.parsedFormData]);
  const sourcePageEntries = useMemo<Array<[string, string]>>(() => {
    if (entry.submissionPageUrl) {
      const url = new URL(entry.submissionPageUrl);
      return [
        ['URL', entry.submissionPageUrl],
        ['Path', url.pathname],
      ];
    }
    return [];
  }, [entry.submissionPageUrl]);

  return (
    <Fragment>
      <div className="text-lg font-medium mb-2">Entry data</div>
      {dataEntries.length ? (
        <div className="flex flex-column gap-2">
          {dataEntries.map(([key, value]) => (
            <KeyValueDisplay key={key} fieldKey={key} value={value} />
          ))}
        </div>
      ) : (
        <NoneLabel />
      )}
      <div className="mt-3 text-lg font-medium mb-2">Submission source page</div>
      {sourcePageEntries.length ? (
        <div className="flex flex-column gap-2">
          {sourcePageEntries.map(([key, value]) => (
            <KeyValueDisplay key={key} fieldKey={key} value={value} />
          ))}
        </div>
      ) : (
        <NoneLabel />
      )}
    </Fragment>
  );
};
