import { FormStepComponent } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useFormContext } from '../../../contexts/FormContext/form-context.js';
import { ParseAndRenderComponents } from '../../ParseAndRenderComponents.js';

export const FormStep: FunctionComponent<FormStepComponent> = () => {
  const form = useFormContext();

  if (!form?.currentStep) {
    return null;
  }

  return <ParseAndRenderComponents componentsJSON={form.currentStep.components} />;
};
