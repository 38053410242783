import { useMutation } from '@apollo/client';
import { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { Button } from 'primereact/button';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.js';
import { useErrorHandler } from '../../../hooks/useErrorHandler.js';
import { Logo } from '../../Logo.js';
import { VerifyEmailAddressDocument } from './mutations.generated.js';

export const VerifyEmailAddress: FunctionComponent<
  ComponentSpec<ComponentType.VerifyEmailAddress>
> = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { name: siteName } = useSiteContext();
  const { onError, ErrorMessage } = useErrorHandler();
  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);
  const [verify, { loading }] = useMutation(VerifyEmailAddressDocument, {
    onError: (err) => {
      setHasError(true);
      onError(err);
    },
    onCompleted: (data) => {
      setName(data.verifyEmailAddress.firstName);
    },
  });

  const key = params.get('key');

  useEffect(() => {
    if (key) {
      void verify({ variables: { key } });
    }
  }, [key, verify]);

  return (
    <div className="h-screen flex justify-content-center align-items-center surface-ground">
      <div className="w-22rem max-body-width-contained bg-white p-4 border-1 border-round mb-2">
        <div className="flex align-items-center justify-content-center gap-2 mb-4">
          <Logo />
        </div>
        <ErrorMessage />
        {key ? (
          <div className="text-center">
            {loading ? (
              <Fragment>
                <div className="font-medium mb-3">Verifying your email address&hellip;</div>
                <i className="pi pi-spinner pi-spin text-xl text-color-primary" />
              </Fragment>
            ) : (
              !hasError && (
                <p className="font-medium mb-4">
                  {name ? `${name}! ` : ''}Your email address has been verified.
                </p>
              )
            )}
          </div>
        ) : (
          <p>This link is not valid.</p>
        )}
        <div className="text-center mt-3">
          <Button
            label={`Go to ${siteName}`}
            disabled={loading}
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};
