import { useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Fragment, FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { MeDocument, MeQuery } from '../../contexts/CurrentUserContext/queries.generated.js';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../../contexts/SessionStatusContext/SessionStatusContext.js';
import { useErrorHandler } from '../../hooks/useErrorHandler.js';
import { Logo } from '../Logo.js';
import { SignInDocument } from './mutations.generated.js';

export type AuthFormProps = {
  loadingUser: boolean;
};

export const AuthForm: FunctionComponent<AuthFormProps> = ({ loadingUser }) => {
  const sessionStatus = useSessionStatusContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const [signIn, { loading }] = useMutation(SignInDocument, {
    onError,
    update: (cache, { data }) => {
      if (data) {
        cache.writeQuery<MeQuery>({
          query: MeDocument,
          data: { me: data.signIn },
        });
      }
    },
    onCompleted: () => {
      sessionStatus.setStatus(SessionStatus.Active);
      sessionStatus.setErrorMessage(null);
    },
  });

  const onSubmit = () => {
    clearMessages();
    void signIn({
      variables: { email, password },
    });
  };

  return (
    <div className="h-screen flex justify-content-center align-items-center surface-ground">
      <div className="w-22rem max-body-width-contained bg-white p-4 border-1 border-round mb-2">
        <div className="flex align-items-center justify-content-center gap-2 mb-4">
          <Logo />
        </div>
        {sessionStatus.errorMessage && !loadingUser && (
          <Message
            severity="error"
            icon={null}
            className="block message-wrapper-padding mb-3"
            content={sessionStatus.errorMessage}
          />
        )}
        <ErrorMessage />
        {loadingUser ? (
          <div className="text-center">
            <div className="font-medium mb-3">Loading your user account&hellip;</div>
            <i className="pi pi-spinner pi-spin text-xl text-color-primary" />
          </div>
        ) : (
          <Fragment>
            <div className="input-field">
              <label htmlFor="email" className="text-left">
                Email
              </label>
              <InputText
                id="email"
                className="w-full"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="input-field">
              <label htmlFor="password" className="text-left">
                Password
              </label>
              <InputText
                id="password"
                className="w-full"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </div>
            <Button
              className="mt-2 w-full"
              label="Sign in"
              disabled={!email.trim() || !password || loading}
              onClick={onSubmit}
            />
            <div className="mt-3">
              <Link to="/reset-password/request" className="text-sm font-medium">
                Reset password
              </Link>
            </div>
            <div className="mt-4 text-center">
              <span className="text-sm font-medium mr-2">No account yet?</span>
              <Link
                to="/order"
                className="p-button p-button-sm p-button-outlined vertical-align-baseline"
              >
                Sign up
              </Link>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
