import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.js';
import { ComponentSpec, ComponentType } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.js';

export const SiteName: FunctionComponent<ComponentSpec<ComponentType.SiteName>> = (props) => {
  const { name } = useSiteContext();

  return <span className={componentClassName(props)}>{name || ''}</span>;
};
