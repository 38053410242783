/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.js';

export type PageTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PageTemplateQuery = {
  pageTemplate: {
    __typename: 'PageTemplate';
    previewImageUrl?: string | null;
    description?: string | null;
    propsSetupComponents?: string | null;
    previewProps?: string | null;
    id: string;
    status: Types.PublishStatus;
    path: string;
    title: string;
    metaTitle?: string | null;
    components: string;
    bodyStyles: string;
    purpose: { __typename: 'PagePurpose'; id: string; name: string };
  };
};

export const PageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageTemplateInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propsSetupComponents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewProps' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTemplateInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'metaTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'components' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bodyStyles' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageTemplateQuery, PageTemplateQueryVariables>;
