import { ResetPasswordComponent } from '@wirechunk/lib/mixer/types/components.js';
import { isString } from 'lodash-es';
import { Dropdown } from 'primereact/dropdown';
import { FunctionComponent } from 'react';
import { EditComponentContentProps } from '../shared/types.js';

const modes: ResetPasswordComponent['mode'][] = ['create', 'reset'];

const isMode = (value: unknown): value is ResetPasswordComponent['mode'] =>
  isString(value) && modes.includes(value as never);

export const EditResetPassword: FunctionComponent<
  EditComponentContentProps<ResetPasswordComponent>
> = ({ component, setComponent }) => (
  <div className="input-field">
    <label htmlFor="editResetPasswordMode">Mode</label>
    <Dropdown
      inputId="editResetPasswordMode"
      options={modes}
      value={component.mode}
      onChange={({ value }) => {
        if (isMode(value)) {
          setComponent((c) => ({ ...c, mode: value }));
        }
      }}
    />
  </div>
);
