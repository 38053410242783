import { FormConfirmationMessageComponent } from '@wirechunk/lib/mixer/types/components.js';
import { FunctionComponent } from 'react';
import { useFormContext } from '../../contexts/FormContext/form-context.js';
import { ParseAndRenderComponents } from '../ParseAndRenderComponents.js';
import { RenderComponentsStyled } from '../RenderComponentsStyled.js';
import { defaultConfirmationMessageComponents } from './form/default-confirmation-message-components.js';

export const FormConfirmationMessage: FunctionComponent<FormConfirmationMessageComponent> = () => {
  const formContext = useFormContext();

  if (!formContext?.submitted) {
    return null;
  }

  return formContext.confirmationMessageComponents &&
    formContext.confirmationMessageComponents !== '[]' ? (
    <ParseAndRenderComponents componentsJSON={formContext.confirmationMessageComponents} />
  ) : (
    <RenderComponentsStyled components={defaultConfirmationMessageComponents} />
  );
};
